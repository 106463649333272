
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { THIS_URL, URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import { MdReply } from 'react-icons/md';
import { maskCep, maskCnpj, maskCpf, maskNumber } from '../../../Auxiliar/Masks';

class EditarMotorista extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/motoristas',
            loading_screen: false,
            Transportadoras: [],

            nome: '',
            cep: '',
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            estado: '',
            cidade: '',
            cnpj: '',
            razao: '',
            fantasia: '',
            cnh: '',
            doc: '',
            categoria: '',
            transportadora_id: '',
            descricao: '',
            loading_save: false,
            msgErro: '',
            Veiculos: [],
            VeiculoId: '',
            email:'',
            password:'',
            confirm_password:''

        };
    }



    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/update_motoristas`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                nome: this.state.nome,
                cep: this.state.cep,
                logradouro: this.state.logradouro,
                numero: this.state.numero,
                complemento: this.state.complemento,
                bairro: this.state.bairro,
                estado: this.state.estado,
                cidade: this.state.cidade,
                cnpj: this.state.cnpj,
                razao: this.state.razao,
                fantasia: this.state.fantasia,
                cnh: this.state.cnh,
                doc: this.state.doc,
                categoria: this.state.categoria,
                transportadora_id: this.state.transportadora_id,
                descricao: this.state.descricao,
                motorista_id: this.props.match.params.id,
                veiculo_id: this.state.VeiculoId,
                email:this.state.email,
                password:this.state.password,
                password_confirmation:this.state.confirm_password,
                url:THIS_URL
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {

        this.get_motoristas(this.props.match.params.id);
    }

    get_motoristas(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_motorista/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    if (resp.motorista.categoria == 'Transportadora') {
                        this.setState({
                            loading_screen: false,
                            Transportadoras: resp.transportadoras,
                            nome: resp.motorista.nome,
                            cnh: resp.motorista.cnh,
                            doc: resp.motorista.doc,
                            categoria: resp.motorista.categoria,
                            transportadora_id: resp.motorista.transportadora_id,
                            descricao: resp.motorista.descricao,
                            Veiculos: resp.veiculos,
                            email: resp.motorista.user?.email?? '',

                        });

                    } else {
                        this.setState({
                            loading_screen: false,
                            Transportadoras: resp.transportadoras,
                            nome: resp.motorista.nome,
                            cep: resp.motorista.cep,
                            logradouro: resp.motorista.logradouro,
                            numero: resp.motorista.numero == null ? '' : resp.motorista.numero,
                            complemento: resp.motorista.complemento,
                            bairro: resp.motorista.bairro,
                            estado: resp.motorista.estado,
                            cidade: resp.motorista.cidade,
                            cnpj: resp.motorista.cnpj,
                            razao: resp.motorista.razao,
                            fantasia: resp.motorista.fantasia,
                            cnh: resp.motorista.cnh,
                            doc: resp.motorista.doc,
                            categoria: resp.motorista.categoria,
                            descricao: resp.motorista.descricao,
                            VeiculoId: resp.motorista.veiculo_id == null ? '' : resp.motorista.veiculo_id,

                            Veiculos: resp.veiculos,
                            email: resp.motorista.user?.email?? '',


                        });
                    }


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }


    buscar_endereco(cep) {
        this.setState({ loading_cep: true });
        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`, {
            method: 'GET',
            headers: {
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                this.setState({
                    logradouro: resp.logradouro,
                    bairro: resp.bairro,
                    cidade: resp.localidade,
                    estado: resp.uf,
                    numero: '',
                    loading_cep: false
                }, () => {



                    if (this.state.logradouro == '') {
                        document.getElementById('rua').focus();
                    } else {
                        document.getElementById('numero').focus();
                    }
                    if (resp.hasOwnProperty("erro")) {
                        window.scrollTo(500, 0);
                        this.setState({ loading: false, cep: '', msgErro: 'O CEP digitado não foi encontrado, tente novamente.', loading_cep: false });
                        document.getElementById('cep').focus();
                    }
                });
            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Algo deu errado. Seu CEP está correto?', loading_cep: false });
            }
        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: `Erro ao pegar resposta do servidor. Você está conectado a internet?`, loading_cep: false });
            });
    }


    render() {

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar motorista</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um motorista do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Nome do motorista" type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} />
                                            </div>
                                        </div>
                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <label className="col-sm-12 col-form-label">CPF <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Número do CPF" type="text" onChange={(e) => this.setState({ doc: maskCpf(e.target.value) })} value={this.state.doc} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-12">
                                                        <label className="col-sm-12 col-form-label">CNH <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" max={11} placeholder="Número da CNH" type="text" onChange={(e) => this.setState({ cnh: e.target.value })} value={this.state.cnh} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Categoria <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.categoria} className="form-control" onChange={(e) => {
                                                    this.setState({
                                                        cep: '',
                                                        logradouro: '',
                                                        numero: '',
                                                        complemento: '',
                                                        bairro: '',
                                                        estado: '',
                                                        cidade: '',
                                                        cnpj: '',
                                                        razao: '',
                                                        fantasia: '',
                                                        transportadora_id: '',
                                                        categoria: e.target.value
                                                    })
                                                }}>
                                                    <option value={''}>Selecione uma opção</option>
                                                    <option value={'Pessoa Jurídica'}>Pessoa Jurídica</option>
                                                    <option value={'Transportadora'}>Transportadora</option>
                                                </select>
                                            </div>
                                        </div>


                                        {this.state.categoria == 'Transportadora' &&
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Transportadora <b style={{ color: 'red' }}>*</b></label>
                                                <div className="col-sm-12">
                                                    <select value={this.state.transportadora_id} className="form-control" id="cameras" onChange={(e) => {

                                                        this.setState({ transportadora_id: e.target.value })
                                                    }}>
                                                        <option value={''}>Selecione uma transportadora</option>

                                                        {this.state.Transportadoras.map((item, id) => (
                                                            <option key={id} value={item.id}>{item.nome_fantasia}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>}

                                        {this.state.categoria == 'Pessoa Jurídica' && <>

                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Veículo <b style={{ color: 'red' }}>*</b></label>
                                                <div className="col-sm-12">
                                                    <select value={this.state.VeiculoId} className="form-control" id="cameras" onChange={(e) => {

                                                        this.setState({ VeiculoId: e.target.value })
                                                    }}>
                                                        <option value={''}>Selecione um veículo</option>

                                                        {this.state.Veiculos.map((item, id) => (
                                                            <option key={id} value={item.id}>{item.modelo.nome}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">CNPJ <b style={{ color: 'red' }}>*</b></label>
                                                <div className="col-sm-12">
                                                    <input className="form-control" placeholder="Número do CNPJ" type="text" onChange={(e) => this.setState({ cnpj: maskCnpj(e.target.value) })} value={this.state.cnpj} />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label">Razão social <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Razão social" type="text" onChange={(e) => this.setState({ razao: e.target.value })} value={this.state.razao} />
                                                        </div>
                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label">Nome fantasia<b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Nome fantasia" type="text" onChange={(e) => this.setState({ fantasia: e.target.value })} value={this.state.fantasia} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label">CEP <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" id="cep" placeholder="CEP" value={this.state.cep} type="text" onChange={(e) => {
                                                                this.setState({ cep: maskCep(e.target.value) });
                                                                let text = e.target.value.replace('-', '');
                                                                if (text.length == 8) {
                                                                    this.buscar_endereco(text);
                                                                }
                                                            }} />
                                                        </div>
                                                    </div>

                                                    <div className="form-group  row">
                                                        <div className=" col-sm-12">
                                                            <div className="row">

                                                                <div className="col-md-8 col-12">
                                                                    <label className="col-sm-12 col-form-label">Logradouro <b style={{ color: 'red' }}>*</b></label>
                                                                    <div className="col-sm-12">
                                                                        <input className="form-control" id="rua" placeholder="Logradouro" type="text" onChange={(e) => this.setState({ logradouro: e.target.value })} value={this.state.logradouro} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3 col-12">
                                                                    <label className="col-sm-12 col-form-label">Número </label>
                                                                    <div className="col-sm-12">
                                                                        <input className="form-control" id="numero" placeholder="Número do endereço" type="text" onChange={(e) => this.setState({ numero: e.target.value })} value={this.state.numero} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-1 col-12" >
                                                                        <label className="col-form-label col-sm-12" htmlFor="select-all"  >S/N</label>
                                                                        <div className="form-check form-switch col-sm-12 " >
                                                                            <input className="form-check-input" checked={this.state.numero == 'S/N' ? true : false} type="checkbox" id={'select-all'} onChange={(e) => { this.setState({ numero: e.target.checked ? 'S/N' : '' }) }} />
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group  row">
                                                        <div className=" col-sm-12">
                                                            <div className="row">
                                                                <div className="col-md-8 col-12">
                                                                    <label className="col-sm-12 col-form-label">Bairro <b style={{ color: 'red' }}>*</b></label>
                                                                    <div className="col-sm-12">
                                                                        <input className="form-control" placeholder="Nome do bairro" type="text" onChange={(e) => this.setState({ bairro: e.target.value })} value={this.state.bairro} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4 col-12">
                                                                    <label className="col-sm-12 col-form-label">Complemento</label>
                                                                    <div className="col-sm-12">
                                                                        <input className="form-control" placeholder="Complemento" type="text" onChange={(e) => this.setState({ complemento: e.target.value })} value={this.state.complemento} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-10 col-12">
                                                            <label className="col-sm-12 col-form-label">Cidade <b style={{ color: 'red' }}>*</b></label>
                                                            <div className="col-sm-12">
                                                                <input className="form-control" disabled placeholder="Nome da cidade" type="text" onChange={(e) => this.setState({ cidade: e.target.value })} value={this.state.cidade} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 col-12">
                                                            <label className="col-sm-12 col-form-label">Estado<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="col-sm-12">
                                                                <input className="form-control" disabled placeholder="UF" type="text" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </>}
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Descrição </label>
                                            <div className="col-sm-12">
                                                <textarea className="form-control" placeholder="Descrição " type="text" onChange={(e) => this.setState({ descricao: e.target.value })} value={this.state.descricao}></textarea>

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">E-mail do usuário<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input autoComplete='off'  className="form-control" placeholder="E-mail do usuário" type="email" onChange={(e) => this.setState({ email: e.target.value })}  value={this.state.email} />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Senha {this.state.changePassword && <b style={{ color: 'red' }}></b>}</label>

                                                <div className="col-sm-12">
                                                    <input className="form-control" placeholder="Senha" type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />

                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Confirmar senha {this.state.changePassword && <b style={{ color: 'red' }}></b>}</label>

                                                <div className="col-sm-12">
                                                    <input className="form-control" placeholder="Confirmar senha" value={this.state.confirm_password} type="password" onChange={(e) => this.setState({ confirm_password: e.target.value })} />
                                                </div>
                                            </div>

                                        <div className="col-12">

                                            {this.state.loading_save == false && <div className="row mt-3">
                                                <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                                    style={{ marginBottom: '0.5rem' }}>
                                                    <Link to="/motoristas"> <span
                                                        className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                                </div>
                                                <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                                    style={{ marginBottom: '0.5rem' }}>
                                                    <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                                        style={{ backgorundColor: '#f2f2f2' }}>Salvar motorista</button>
                                                </div>
                                            </div>}
                                            {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                                <div className="spinner-border text-primary" role="status"  >
                                                </div>
                                            </div></div>}
                                        </div>

                                    </div>
                                </div>

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(EditarMotorista);


