import { MdSearch } from "react-icons/md";

export default function BuscarMTR({ handleMTRNumber, findMTR, searchMtr }) {
    return (
        <div className="d-flex flex-column">
            <div className="input-group" style={{ maxWidth: '400px' }}>
                <input type="text" className="form-control" placeholder="Número do MTR" onChange={handleMTRNumber} value={searchMtr.value} />
                <div className="input-group-append">
                    <button className="btn btn-primary" type="button" onClick={findMTR} disabled={searchMtr.loading}>
                        <MdSearch /> Buscar MTR
                    </button>
                </div>
            </div>
            {searchMtr.error && <span className="text-danger small">{searchMtr?.error}</span>}
        </div>
    )
}