import * as React from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";

const style = {
  position: "absolute",
  left: "50%",
  transform: "translate(0, 0, 0, 50%)",
  width: "50%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid transparent",
  boxShadow: 24,
  p: 4,

  overflowY: "auto",
  "@media(max-width: 1000px)": {
    width: "90%",
    left: "5%",
  },
};

export default function SmoothModal({ onOpenCallback, onCloseCallback, buttonLabel, close, children }) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => handleClose(), [close])

  const handleOpen = () => {
    onOpenCallback()
    setOpen(true)
  }

  const handleClose = () => {
    onCloseCallback()
    setOpen(false)
  }

  return (
    <div>
      <button className="btn btn-primary btn-lg waves-effect waves-light mx-2 mt-1" onClick={handleOpen}>
        {buttonLabel}
      </button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {children}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

SmoothModal.defaultProps = {
  onOpenCallback: () => { },
  onCloseCallback: () => { },
  buttonLabel: 'Criar',
  children: <></>,
  close: false
}