//* React
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

//* Components
import { Tooltip } from "@mui/material";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdReply } from "react-icons/md";

//* Utils
import { NAME, URLadmin } from "../../../../variables";
import { maskNumberBr } from "../../../../Auxiliar/Masks";

export default function AcompanharDestinacaoColeta() {
  const { destination_id, id } = useParams();
  const [collect, setCollect] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    fetch(
      `${URLadmin}api/auth/admin/internal_relationship/destination_collect/show/${destination_id}/${id}?connection=${NAME}`,
      {
        method: "GET",
        headers: { Accept: "application/json" },
      }
    )
      .then(async (responseLog) => {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.response) {
          setCollect(resp.data);
          setLoading(false);
          return;
        }

        console.error("err", resp.message);
      })
      .catch((err) => {
        console.error("err", err);
      });
  }

  return (
    <div className="row">
      {!loading ? (
        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
              <h4
                className="mt-0 header-title"
                style={{ fontSize: "1.5rem", color: "black" }}
              >
                Destinação
              </h4>
              <p style={{ fontSize: "1rem", color: "black" }}>
                Destinação em andamento{" "}
                <Tooltip title="Os dados podem ser alterados, pois a destinação ainda está em andamento">
                  <a
                    className="text-secondary"
                    style={{ padding: 0, margin: 0 }}
                  >
                    <BsInfoCircleFill />
                  </a>
                </Tooltip>
              </p>
              <p style={{ fontSize: "1rem" }}>
                Essa destinação é uma solicitação de coleta no ambiente do
                destino selecionado, pois se trata da uma função de
                Relacionamentos Internos.
                <Tooltip
                  title=" Observação: Algumas informações podem ter base em dados do
                  ambiente relacionado, como peso, total, etc."
                >
                  <a
                    className="text-secondary"
                    style={{ padding: 0, margin: 0 }}
                  >
                    <BsInfoCircleFill />
                  </a>
                </Tooltip>
              </p>
              <br />
              <div className="row">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Saindo de (estoque):{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {collect?.stock}
                      </span>
                    </label>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Destino:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {collect?.destination}
                      </span>
                    </label>
                  </div>
                  <hr />
                  <label
                    className="col-sm-12 col-form-label"
                    style={{ color: "black", fontSize: "1.5rem" }}
                  >
                    Dados do estoque:
                  </label>
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Estoque destinado:{" "}
                      <span style={{ fontWeight: "normal" }}>
                        {collect?.collectStock || "Não informado"}
                      </span>
                    </label>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-sm-12 col-form-label"
                      style={{ color: "black" }}
                    >
                      Materiais selecionados:
                    </label>

                    <table
                      id="tech-companies-1"
                      className="table table-striped table-hover"
                    >
                      <thead>
                        <tr>
                          <th>Material</th>
                          <th>Peso</th>
                          <th>Volume</th>
                        </tr>
                      </thead>
                      <tbody>
                        {collect?.wasteRejects.map((item, index) => (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{maskNumberBr(item.weightKg)} Kg</td>
                            <td>{maskNumberBr(item.volumeLt)} L</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div id="despacho" className="col-12">
                    <h4>
                      <strong>Dados do Despacho</strong>
                    </h4>
                    <div id="situation" className="mt-4">
                      <div className="form-group row">
                        <div className="col-12">
                          <label className="col-4 col-form-label fw-bold">
                            Status:
                          </label>
                          {collect?.status || "Não informado"}
                        </div>
                        <div className="col-12">
                          <label className="col-4 col-form-label fw-bold">
                            Data de Coleta:
                          </label>
                          {collect?.pickupDate || "Não informado"}
                        </div>
                        <div className="col-12">
                          <label className="col-4 col-form-label fw-bold">
                            Peso total (Kg):
                          </label>
                          {maskNumberBr(collect?.totalWeightKg)} Kg
                        </div>
                        <div className="col-12">
                          <label className="col-4 col-form-label fw-bold">
                            Volume total (L):
                          </label>
                          {maskNumberBr(collect?.totalVolumeLt)} L
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div
                    className="col-12 col-sm-12 d-flex justify-content-center justify-content-sm-start"
                    style={{ marginBottom: "0.5rem" }}
                  >
                    <Link to="/destinacao_interna">
                      <span className="btn btn-secondary btn-lg waves-effect waves-light">
                        <MdReply /> Voltar
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>Loading...</>
      )}
    </div>
  );
}
