import { CircularProgress } from "@mui/material";
import React, { Component } from "react";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import {
  MdDelete,
  MdModeEdit,
  MdReply,
  MdSearch,
  MdVisibility,
} from "react-icons/md";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Tooltip from "react-tooltip";

import { logout } from "../../../actions/AppActions";
import { NAME, URL, URLopen } from "../../../variables";

class HorariosFuncionamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: true,
      path: "/horarios_funcionamento",
      loading_screen: false, //alterar para true
      loading_save: false, //alterar para true
      reload: false,
      related: [],

      dia_semana: {
        dom: false,
        seg: false,
        ter: false,
        qua: false,
        qui: false,
        sex: false,
        sab: false,
      },
      start_time: "",
      closing_time: "",

      horarios_funcionamento: [],
      novos_horarios: [],
      horarios_to_delete: [],

      msgErro: "",
    };
  }

  componentDidMount() {
    this.get_org_expedient();
    let newDay = new Date();
    console.log(newDay)
  }

  componentDidUpdate() {
    if (this.props.search) {
      this.props.setSearch(false);
      this.get_org_expedient();
    }
  }

  get_org_expedient() {
    if (this.state.loading_screen === false) {
      this.setState({ loading_screen: true });
    }
    fetch(`${URLopen}api/auth/blueen/expedient/show`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ connection: NAME }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            console.log(erro);
            this.setState({ loading_screen: false });
          } else {
            this.setState({
              loading_screen: false,
              horarios_funcionamento: resp.expedient,
            });
          }
        } catch (err) {
          this.setState({ loading_screen: false });
          console.log(err);
        }
      })
      .catch((err) => {
        this.setState({ loading_screen: false });
        console.log(err);
      });
  }

  handleDay(day) {
    let dia_semana = { ...this.state.dia_semana };
    dia_semana[day] = !dia_semana[day];
    this.setState({ dia_semana });
  }

  salvar() {
    let novos_horarios = [...this.state.novos_horarios];
    let horarios_existentes = [...this.state.horarios_funcionamento];
    let funcionamento = [];

    novos_horarios?.forEach((element) => {
      element.dia.forEach((dias) => {
        // console.log('element1', element)
        let obj = {
          dia: dias,
          start_time: element.start_time,
          closing_time: element.closing_time,
        };
        funcionamento.push(obj);
      });
    });

    horarios_existentes?.forEach((element) => {
      element.period.forEach((dias) => {
        console.log('element2', element)
        let obj = {
          id: element.weekday_id,
          dia: element.weekday,
          start_time: dias.start_time,
          closing_time: dias.closing_time,
        };
        funcionamento.push(obj);
      });
    });

    if (funcionamento.length <= 0) {
      this.setState({ msgErro: "Não é possível ficar sem um expediente." })
      return
    }
    this.setState({ loading_save: true });

    toast.loading("Salvando dados...", {
      toastId: "toastpromisse" + funcionamento,
      hideProgressBar: false,
    });

    fetch(`${URLopen}api/auth/blueen/expedient/store`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        connection: NAME,
        expedient: funcionamento,
        expedient_to_delete: this.state.horarios_to_delete
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            console.log(erro);
            window.scrollTo(500, 0);

            this.setState({ loading_save: false, msgErro: erro });

            toast.update("toastpromisse" + funcionamento, {
              render: "Erro",
              type: "error",
              isLoading: false,
              autoClose: 1000,
            });
          } else {
            toast.update("toastpromisse" + funcionamento, {
              render: "Expediente salvo com sucesso!",
              type: "success",
              isLoading: false,
              autoClose: 2000,
            });
            this.get_org_expedient();
            this.setState({ loading_save: false, redirect: true, novos_horarios: [], horarios_to_delete: [] });
          }
        } catch (err) {
          console.log(err);
          toast.update("toastpromisse" + funcionamento, {
            render: "Erro",
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });
          this.setState({
            loading_save: false,
            msgErro: "Erro ao pegar resposta do servidor",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.update("toastpromisse" + funcionamento, {
          render: "Erro",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        this.setState({
          loading_save: false,
          msgErro:
            "Erro ao pegar resposta do servidor. Você está conectado a internet?",
        });
      });
  }

  render() {
    return (
      <>
        {this.state.redirect == true && <Redirect to={this.state.path} />}
        {this.state.loading_screen == false && (
          <div>
            <div className="form-group row mt-3">
              <div className=" col-sm-12">
                <div className="row">
                  <div className="col-12">
                    <label className="col-sm-12 col-form-label">
                      Dias de funcionamento <b style={{ color: "red" }}>*</b>
                    </label>
                    <div
                      className="d-flex justify-content-evenly align-items-center"
                      id="dayselector"
                    >
                      {Object.keys(this.state.dia_semana).map((item, id) => (
                        <>
                          {this.state.dia_semana[item] == false && (
                            <div key={id} onClick={() => this.handleDay(item)}>
                              {item.substring(0, 1).toUpperCase()}
                            </div>
                          )}
                          {this.state.dia_semana[item] == true && (
                            <div
                              className="selectedDay"
                              key={id}
                              onClick={() => this.handleDay(item)}
                            >
                              {item.substring(0, 1).toUpperCase()}
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="col-12 col-form-label">
                      Hora Início<b style={{ color: "red" }}>*</b>
                    </label>
                    <div className="col-sm-12">
                      <input
                        className="form-control"
                        placeholder="Hora Início"
                        type="time"
                        onChange={(e) => {
                          this.setState({ start_time: e.target.value });
                        }}
                        value={this.state.start_time}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <label className="col-12 col-form-label">
                      Hora Fim<b style={{ color: "red" }}>*</b>
                    </label>
                    <div className="col-sm-12">
                      <input
                        className="form-control"
                        placeholder="Hora Fim"
                        type="time"
                        onChange={(e) => {
                          this.setState({ closing_time: e.target.value });
                        }}
                        value={this.state.closing_time}
                      />
                    </div>
                  </div>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-between mt-3">
                    {this.state.msgErro != "" && (
                      <b
                        className="mt-2"
                        style={{ color: "red", fontSize: "14px" }}
                      >
                        {this.state.msgErro}
                      </b>
                    )}
                    <div></div>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-between mt-3">
                      <div></div>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => {
                          if (
                            this.state.start_time == "" ||
                            this.state.closing_time == ""
                          ) {
                            this.setState({
                              msgErro: "Preencha todos os campos",
                            });
                            return;
                          }
                          if (this.state.start_time >= this.state.closing_time) {
                            this.setState({ msgErro: "Horários conflitantes" });
                            return;
                          }
                          if (
                            this.state.dia_semana.dom == false &&
                            this.state.dia_semana.seg == false &&
                            this.state.dia_semana.ter == false &&
                            this.state.dia_semana.qua == false &&
                            this.state.dia_semana.qui == false &&
                            this.state.dia_semana.sex == false &&
                            this.state.dia_semana.sab == false
                          ) {
                            this.setState({
                              msgErro: "Escolha pelo menos um dia da semana",
                            });
                          } else {
                            let novos_horarios = [...this.state.novos_horarios];
                            let obj = {
                              dia: Object.values(this.state.dia_semana)
                                .map((item, id) => {
                                  if (item == true) {
                                    return Object.keys(this.state.dia_semana)[
                                      id
                                    ];
                                  }
                                })
                                .filter(
                                  (item, id) =>
                                    item != null && item != undefined
                                ),
                              start_time: this.state.start_time,
                              closing_time: this.state.closing_time,
                            };

                            let msgErro = "";
                            for (let i = 0; i < this.state.novos_horarios?.length; i++) {
                              for (let j = 0; j < this.state.novos_horarios[i].dia?.length; j++) {
                                Object.values(obj.dia).map((item2, id2) => {
                                  if (this.state.novos_horarios[i].dia[j] == item2) {
                                    if ((this.state.novos_horarios[i].closing_time >= obj.start_time &&
                                      obj.start_time >= this.state.novos_horarios[i].start_time) ||
                                      (this.state.novos_horarios[i].closing_time >= obj.closing_time &&
                                        obj.closing_time >= this.state.novos_horarios[i].start_time) ||
                                      (this.state.novos_horarios[i].closing_time <= obj.closing_time &&
                                        obj.start_time <= this.state.novos_horarios[i].start_time)) {
                                      msgErro = "Horários conflitantes";
                                    }
                                  }
                                });
                              }
                            }
                            for (let i = 0; i < this.state.horarios_funcionamento.length; i++) {
                              for (let j = 0; j < this.state.horarios_funcionamento[i].period.length; j++) {
                                Object.values(obj.dia).map((item2, id2) => {
                                  if (this.state.horarios_funcionamento[i].weekday == item2) {
                                    if ((this.state.horarios_funcionamento[i].period[j].closing_time >= obj.start_time &&
                                      obj.start_time >= this.state.horarios_funcionamento[i].period[j].start_time) ||
                                      (this.state.horarios_funcionamento[i].period[j].closing_time >= obj.closing_time &&
                                        obj.closing_time >= this.state.horarios_funcionamento[i].period[j].start_time) ||
                                      (this.state.horarios_funcionamento[i].period[j].closing_time <= obj.closing_time &&
                                        obj.start_time <= this.state.horarios_funcionamento[i].period[j].start_time)
                                    ) {
                                      msgErro = "Horários conflitantes";
                                    }
                                  }
                                });
                              }
                            }

                            if (msgErro === "") {
                              novos_horarios.push(obj);
                              this.setState({
                                novos_horarios,
                                msgErro: "",
                                start_time: "",
                                closing_time: "",
                              });
                            } else {
                              this.setState({
                                msgErro: "O novo horário está em conflito com algum horário já cadastrado ",
                              });
                            }
                          }
                        }}
                      >
                        Inserir
                      </button>
                    </div>
                  </div>
                  {this.state.novos_horarios?.length > 0 && (
                    <div className="mt-3">
                      <ul className="list-group text-capitalize">
                        <li className="list-group-item bg-primary text-light">
                          <b>Novos dias e horários de funcionamento</b>
                        </li>
                        {this.state.novos_horarios?.map((item, id) => (
                          <li
                            key={id}
                            className="list-group-item d-flex justify-content-between"
                          >
                            <p className="pt-3">
                              <b>
                                {item.dia.map(
                                  (item2, id2) =>
                                    (id2 == 0 ? "" : " - ") + item2
                                )}
                              </b>
                              : {item.start_time} - {item.closing_time}
                            </p>
                            <button
                              onClick={() => {
                                let novos_horarios = [
                                  ...this.state.novos_horarios,
                                ];
                                novos_horarios = novos_horarios.filter(
                                  (item2, id2) => id2 != id
                                );
                                this.setState({ novos_horarios });
                              }}
                              className="btn btn-danger btn-outline btn-circle me-1"
                              style={{
                                background: "white",
                                border: "0px solid red",
                              }}
                            >
                              <FaTrash color="red" />
                            </button>
                          </li>
                        ))}
                      </ul>
                      <hr />
                    </div>
                  )}
                  {this.state.horarios_funcionamento?.length > 0 && (
                    <div className="mt-3">
                      <ul className="list-group text-capitalize">
                        <li className="list-group-item bg-primary text-light">
                          <b>Dias e horários de funcionamento</b>
                        </li>
                        {this.state.horarios_funcionamento?.map((item, id) => (
                          <li
                            key={id}
                            className="list-group-item d-flex justify-content-between"
                          >
                            <p className="pt-3">
                              <b>{item.weekday}: </b>
                              {item.period?.map((item, id, array) => (
                                <span key={id}>
                                  {item.start_time} - {item.closing_time} {id < array.length - 1 && " | "}
                                </span>
                              ))}
                            </p>
                            <button
                              onClick={() => {
                                let horarios_funcionamento = [
                                  ...this.state.horarios_funcionamento,
                                ];
                                horarios_funcionamento =
                                  horarios_funcionamento.filter(
                                    (item2, id2) => id2 != id
                                  );

                                let horarios_to_delete = [...this.state.horarios_to_delete]
                                horarios_to_delete.push(item)

                                this.setState({ horarios_funcionamento, horarios_to_delete })
                              }}
                              className="btn btn-danger btn-outline btn-circle me-1 bg-light"
                              style={{ border: "0px solid red" }}
                            >
                              <FaTrash className="text-danger" />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {this.state.loading_save == false && (
              <div className="row mt-3">
                <div
                  className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                  style={{ marginBottom: "0.5rem" }}
                ></div>
                <div
                  className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                  style={{ marginBottom: "0.5rem" }}
                >
                  <button
                    type="button"
                    onClick={() => this.salvar()}
                    className="btn btn-success btn-lg waves-effect waves-light"
                    style={{ backgorundColor: "#f2f2f2" }}
                  >
                    Salvar
                  </button>
                </div>
              </div>
            )}

            {this.state.loading_save == true && (
              <div className="row">
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
          </div>
        )}

        {
          //loading
          this.state.loading_screen == true && (
            <div className="row">
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ marginTop: "2rem" }}
              >
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              </div>
            </div>
          )
        }
        <ToastContainer
          position="bottom-right"
          toastStyle={{ border: "1px solid gray", borderRadius: 4 }}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={true}
          pauseOnHover
        />
      </>
    );
  }
}

const mapsStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(HorariosFuncionamento);
