
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import Chart from "react-apexcharts";

// Registros
import moment from 'moment';
import { URL } from '../../../variables';
import { MdTrendingUp, MdTrendingDown, MdTrendingFlat } from 'react-icons/md';
import { maskNumberBr, maskPrice, moneyMask, moneyMask2 } from '../../../Auxiliar/Masks';
import DashboardFilter from '../../../Auxiliar/DashboardFilter';
import ReactTooltip from 'react-tooltip';
import { IconButton } from '@mui/material';
import { BsArrowDownUp } from 'react-icons/bs';


function generateDayWiseTimeSeries(baseval, count, yrange) {
    var i = 0;
    var series = [];
    while (i < count) {
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

        series.push([baseval, y]);
        baseval += 86400000;
        i++;
    }
    return series;
}
var randomizeArray = function (arg) {
    var array = arg.slice();
    var currentIndex = array.length, temporaryValue, randomIndex;

    while (0 !== currentIndex) {

        randomIndex = Math.abs(Math.floor(Math.random() * currentIndex));
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

const formatTooltip = (value, index) => {
    switch (index) {
        case 0:
            return moneyMask2(value)
        case 1:
            return value
        case 2:
            return value + ' Solicitações'
        case 3:
            return value + ' Pontos de Coleta'
        default:
            return value
    }
}

const multiTypeOptions = {
    chart: {
        height: 400,
        type: "line",
        stacked: false
    },
    dataLabels: {
        enabled: false
    },
    markers: {
        size: 7,
    },
    colors: ['#46c2e1', '#32a5d4', '#f76269', '#f8bd13'],
    series: [

        {
            name: 'Evolução em R$',
            type: 'column',
            data: []
        },
        {
            name: "Evolução em KG",
            type: 'column',
            data: []
        },
        {
            name: "Solicitações",
            type: 'area',
            data: []
        },
        {
            name: "Ponto de coleta",
            type: 'area',
            data: []
        },
    ],
    stroke: {
        width: [4, 4, 1, 1],
        curve: 'smooth'
    },
    plotOptions: {
        bar: {
            columnWidth: "50%"
        }
    },
    xaxis: {
        categories: []
    },
    yaxis: [
        {
            seriesName: 'Evolução em R$',
            axisTicks: {
                show: true
            },
            axisBorder: {
                show: true,
            },
            title: {
                text: "Evolução em R$"
            },
            labels: {
                formatter: (value) => {
                    return moneyMask2(value)
                }
            }
        },
        {
            seriesName: 'Evolução em KG',
            axisTicks: {
                show: true
            },
            axisBorder: {
                show: true,
            },
            title: {
                text: "Evolução em KG"
            }
        },
        {
            opposite: true,
            seriesName: 'Solicitações',
            axisTicks: {
                show: true
            },
            axisBorder: {
                show: true,
            },
            title: {
                text: "Solicitações"
            }
        },
        {
            opposite: true,
            seriesName: 'Ponto de coleta',
            axisTicks: {
                show: true
            },
            axisBorder: {
                show: true,
            },
            title: {
                text: "Ponto de coleta"
            }
        }
    ],
    tooltip: {
        shared: true,
        intersect: false,
        y: {
            formatter: function (y, { seriesIndex }) {
                if (typeof y !== "undefined") {
                    y.toFixed(0);
                }

                const result = formatTooltip(y, seriesIndex)

                return result;
            }
        }
    },
    fill: {
        opacity: [1, 1, 0.4, 0.4],
        gradient: {
            inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
        }
    },
    legend: {
        horizontalAlign: "left",
        offsetX: 40
    }
}

const barOptions = {
    chart: {
        height: 400,
        type: "bar",
        stacked: false
    },
    dataLabels: {
        enabled: false
    },
    colors: ['#46c2e1', '#32a5d4', '#f76269', '#f8bd13'],
    series: [

        {
            name: 'Evolução em R$',
            data: []
        },
        {
            name: "Evolução em KG",
            data: []
        },
        {
            name: "Solicitações",
            data: []
        },
        {
            name: "Ponto de coleta",
            data: []
        },
    ],
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '75%',
            endingShape: 'rounded'
        },
    },
    xaxis: {
        categories: []
    },
    yaxis: [
        {
            seriesName: 'Evolução em R$',
            axisTicks: {
                show: true
            },
            axisBorder: {
                show: true,
            },
            title: {
                text: "Evolução em R$"
            },
            labels: {
                formatter: (value) => {
                    return moneyMask2(value)
                }
            }
        },
        {
            seriesName: 'Evolução em KG',
            axisTicks: {
                show: true
            },
            axisBorder: {
                show: true,
            },
            title: {
                text: "Evolução em KG"
            }
        },
        {
            opposite: true,
            seriesName: 'Solicitações',
            axisTicks: {
                show: true
            },
            axisBorder: {
                show: true,
            },
            title: {
                text: "Solicitações"
            }
        },
        {
            opposite: true,
            seriesName: 'Ponto de coleta',
            axisTicks: {
                show: true
            },
            axisBorder: {
                show: true,
            },
            title: {
                text: "Ponto de coleta"
            }
        }
    ],
    tooltip: {
        shared: true,
        intersect: false,
        y: {
            formatter: function (y, { seriesIndex }) {
                if (typeof y !== "undefined") {
                    y.toFixed(0);
                }

                return formatTooltip(y, seriesIndex)
            }
        }
    },
    legend: {
        horizontalAlign: "left",
        offsetX: 40
    }
}

// data for the sparklines that appear below header area
var sparklineData = [47, 45, 54, 38, 56, 24, 65, 31, 37, 39, 62, 51, 35, 41, 35, 27, 93, 53, 61, 27, 54, 43, 19, 46];
var spark1 = {
    tooltip: {
        enabled: false
    },
    chart: {
        id: 'sparkline3',
        group: 'sparklines',
        type: 'area',
        height: 50,
        sparkline: {
            enabled: true
        },
    },
    stroke: {
        curve: 'straight'
    },
    fill: {
        opacity: 1,
    },
    series: [{
        data: randomizeArray(sparklineData)
    }],
    labels: [...Array(24).keys()].map(n => `2018-09-0${n + 1}`),
    xaxis: {
        type: 'datetime',

    },
    yaxis: {
        min: 0
    },
    colors: ['#3ABBE2'],
    //colors: ['#5564BE'],

}

var spark2 = {
    tooltip: {
        enabled: false
    },
    chart: {
        id: 'sparkline2',
        group: 'sparklines2',
        type: 'area',
        height: 350,
        sparkline: {
            enabled: true
        },
    },
    stroke: {
        curve: 'straight'
    },
    fill: {
        opacity: 1,
    },
    series: [{
        data: randomizeArray(sparklineData)
    }],
    labels: [...Array(24).keys()].map(n => `2018-09-0${n + 1}`),
    xaxis: {
        type: 'datetime',
    },
    yaxis: {
        min: 0
    },
    colors: ['#81D6EB'],
    //colors: ['#5564BE'],

}

var spark3 = {
    tooltip: {
        enabled: false
    },
    chart: {
        id: 'sparkline2',
        group: 'sparklines2',
        type: 'area',
        height: 350,
        sparkline: {
            enabled: true
        },
    },
    stroke: {
        curve: 'straight'
    },
    fill: {
        opacity: 1,
    },
    series: [{
        data: randomizeArray(sparklineData)
    }],
    labels: [...Array(24).keys()].map(n => `2018-09-0${n + 1}`),
    xaxis: {
        type: 'datetime',
    },
    yaxis: {
        min: 0
    },
    colors: ['#FDE07E'],
    //colors: ['#5564BE'],

}

var spark4 = {
    tooltip: {
        enabled: false
    },
    chart: {
        id: 'sparkline2',
        group: 'sparklines2',
        type: 'area',
        height: 350,
        sparkline: {
            enabled: true
        },
    },
    stroke: {
        curve: 'straight'
    },
    fill: {
        opacity: 1,
    },
    series: [{
        data: randomizeArray(sparklineData)
    }],
    labels: [...Array(24).keys()].map(n => `2018-09-0${n + 1}`),
    xaxis: {
        type: 'datetime',

    },
    yaxis: {
        min: 0
    },
    colors: ['#F89194'],
    //colors: ['#5564BE'],

}
class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.last_param = 'soma_kg'
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            RegistrosColeta: {},
            dados_contato: [],
            page: 1,
            total_pages: 1,
            msgErro: '',
            show_warning: false,
            show_erro: false,
            registro_to_delete: '',
            search: '',

            lista_rr: [],
            num_ecopontos_ativos: 0,
            pontos_coleta: [],
            quantidade_residuos_coletados: 0,
            residuo_maior_kg: [],
            residuo_maior_valor: [],
            saldo_financeiro: 0,

            solicitacoes_coleta_aguardando: 0,
            solicitacoes_coleta_andamento: 0,
            solicitacoes_coleta_finalizado: 0,
            solicitacoes_coleta_total: 0,
            total_kg: 1,
            total_valor: [],
            escala: 'mes',

            loading_graph: true,
            first_access: true,

            saldos_graficos: [],
            datas_grafico: [],
            pesos_grafico: [],
            solicitacoes_grafico: [],
            pontos_coleta_grafico: [],

            saldos_graficos_difference: {},
            pesos_grafico_difference: {},
            solicitacoes_grafico_difference: {},
            pontos_coleta_grafico_difference: {},

            options_: barOptions,

            date_from: moment().subtract(1, 'month').format('YYYY-MM'),
            date_to: moment().format('YYYY-MM'),
            filter_rrs: [],
            filter_unity: 'peso',

            options_rr: [],
            selected_rr: '',
            rr_error: '',

            options_pc: [],
            selected_pc: '',
            pc_error: '',
        };
    }

    componentDidMount() {
        this.get_dados_dashboard(this.state.page);

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.apply !== this.state.apply) {
            this.get_dados_dashboard();
        }
    }

    get_dados_dashboard() {
        if (this.state.loading_screen === false) {
            this.setState({ loading_screen: true });
        }

        const residuo_rejeito_ids = this.state.filter_rrs.length > 0 ? encodeURIComponent(JSON.stringify(this.state.filter_rrs)) : ''

        const { date_to, date_from, filter_unity } = this.state;
        const formattedMonth = date_from.toString().padStart(2, '0');
        const period = `${date_to}-${formattedMonth}`;

        fetch(`${URL}api/get_dados_dashboard?residuo_rejeito_ids=${residuo_rejeito_ids}&period=${date_from}&period_end=${date_to}&unity=${filter_unity}&ponto_coleta_id=${this.state.selected_pc}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    this.setState({ redirect: true });
                }
                else {

                    const biggerPoint = resp.pontos_coleta.reduce((maxItem, currentItem) => {
                        if (!currentItem.total_peso) return maxItem
                        if (!maxItem || currentItem.total_peso > maxItem.total_peso) return currentItem;
                        else return maxItem;
                    }, null);

                    this.setState({
                        quantidade_residuos_coletados: resp.quantidade_residuos_coletados,
                        pontos_coleta: resp.pontos_coleta,
                        num_ecopontos_ativos: resp.num_ecopontos_ativos,
                        lista_rr: resp.lista_rr,
                        residuo_maior_kg: resp.residuo_maior_kg,
                        residuo_maior_valor: resp.residuo_maior_valor,
                        ponto_coleta_maior_valor: biggerPoint,
                        saldo_financeiro: resp.saldo_financeiro,
                        solicitacoes_coleta_aguardando: resp.solicitacoes_coleta_aguardando,
                        solicitacoes_coleta_andamento: resp.solicitacoes_coleta_andamento,
                        solicitacoes_coleta_finalizado: resp.solicitacoes_coleta_finalizado,
                        solicitacoes_coleta_total: resp.solicitacoes_coleta_total,
                        total_valor: resp.total_valor,
                        loading_screen: false,
                        options_rr: resp.residuos,
                        options_pc: resp.pontos_coleta_disponiveis,
                        unity: resp.unidade,
                    }, () => {
                        this.get_dados_grafico(this.state.escala, residuo_rejeito_ids, date_from, date_to, filter_unity);
                    });

                    this.last_param = 'soma_kg'
                }
            } catch (err) {
                this.setState({ redirect: true });
            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                // this.props.mudarLoadingHome(false); 
            });
    }


    get_dados_grafico(escala, residuo_rejeito_ids, date_from, date_to, filter_unity) {
        if (this.state.loading_graph === false) {
            this.setState({ loading_graph: true });
        }
        fetch(`${URL}api/get_dados_grafico/${escala}?residuo_rejeito_ids=${residuo_rejeito_ids}&period=${date_from}&period_end=${date_to}&unity=${filter_unity}&first_access=${this.state.first_access}&ponto_coleta_id=${this.state.selected_pc}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    this.setState({ loading_graph: true });
                }
                else {
                    let options_ = { ...this.state.options_ };

                    options_.series[0].data = resp.saldos_graficos.reverse();
                    options_.series[1].data = resp.pesos_grafico.reverse();
                    options_.series[2].data = resp.solicitacoes_grafico.reverse();
                    options_.series[3].data = resp.pontos_coleta_grafico.reverse();

                    options_.xaxis.categories = resp.datas_grafico.map(item => {
                        const [year, month] = item.split('-')
                        return `${month}/${year}`
                    }).reverse()

                    options_.series[1].name = `Evolução em ${this.state.unity}`
                    options_.yaxis[1].title = `Evolução em ${this.state.unity}`
                    options_.yaxis[1].seriesName = `Evolução em ${this.state.unity}`

                    this.setState({
                        saldos_graficos: resp.saldos_graficos,
                        datas_grafico: resp.datas_grafico,
                        pesos_grafico: resp.pesos_grafico,
                        solicitacoes_grafico: resp.solicitacoes_grafico,
                        pontos_coleta_grafico: resp.pontos_coleta_grafico,

                        saldos_graficos_difference: this.calculateDifference(resp.saldos_graficos),
                        pesos_grafico_difference: this.calculateDifference(resp.pesos_grafico),
                        solicitacoes_grafico_difference: this.calculateDifference(resp.solicitacoes_grafico),
                        pontos_coleta_grafico_difference: this.calculateDifference(resp.pontos_coleta_grafico),

                        options_: options_,
                        loading_graph: false,
                        first_access: false,
                    });
                }
            } catch (err) {
                this.setState({ loading_graph: true });
            }

        })
            .catch((err) => {
                this.setState({ loading_graph: true });

                // this.props.mudarLoadingHome(false); 
            });
    }

    changeState = (args) => {
        this.setSate(args)
    }

    calculateDifference = (array) => {
        const dataArray = [...array]
        const firstItem = dataArray[0]
        const lastItem = dataArray.pop()

        if (lastItem == 0 || firstItem == 0) {
            return {
                label: 'Sem dados para comparação',
                icon: <MdTrendingFlat color="#D8B900" size={20} className='ms-1' />
            }
        }

        const percentualDiff = ((lastItem - firstItem) / Math.abs(firstItem)) * 100

        return {
            label: maskNumberBr(Math.abs(percentualDiff).toFixed(2)) + '%',
            icon: percentualDiff === 0
                ? <MdTrendingFlat size={20} color="#D8B900" className='ms-1' />
                : (percentualDiff > 0
                    ? <MdTrendingUp color='#008811' size={20} className='ms-1' />
                    : <MdTrendingDown color="#AC3B45" size={20} className='ms-1' />)
        }
    }

    sortRrBy = (param) => {
        const sortedRrArray = this.state.lista_rr.sort((a, b) => {
            const totalA = parseFloat(a[param])
            const totalB = parseFloat(b[param])
            if (totalA > totalB) return -1;
            else if (totalA < totalB) return 1;
            else return 0;
        })

        this.last_param = param
        this.setState({ lista_rr: sortedRrArray })
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}



                {this.state.loading_screen == false && <div className="col-12">
                    <div>
                        <h1 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Indicadores</h1>
                        <p>Destinação por período</p>
                    </div>

                    <DashboardFilter
                        dateFrom={this.state.date_from}
                        dateTo={this.state.date_to}
                        moment={() => moment()}
                        filterUnity={this.state.filter_unity}
                        filterRrs={this.state.filter_rrs}
                        optionsRr={this.state.options_rr}
                        selectedRr={this.state.selected_rr}
                        optionsPc={this.state.options_pc}
                        selectedPc={this.state.selected_pc}
                        getData={() => this.get_dados_dashboard()}
                        setState={(args) => this.setState(args)}
                    />

                    <div className="row mb-2">
                        <div className="col-12 col-md-6 col-sm-6 col-lg-3 mt-2">
                            <div style={{ width: '100%', minHeight: 190, height: '100%', borderRadius: 10, boxShadow: '0px 1px 5px #888888', backgroundImage: 'linear-gradient(to top right, #36B2E2, #2D98C6)', padding: 10 }}>
                                <div style={{ padding: 15, paddingBottom: 0 }}>
                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <h3 style={{ color: "white", fontFamily: 'sans-serif', fontWeight: "bold", fontSize: 28 }}>{maskNumberBr(this.state.quantidade_residuos_coletados)} {this.state.unity}</h3>
                                    </div>
                                    <div>
                                        <p style={{ color: 'white', fontSize: 15 }}>Quantidade de resíduos coletados</p>
                                    </div>
                                </div>

                                <div>
                                    <Chart
                                        options={spark1}
                                        series={spark1.series}

                                        type="line"
                                        height="70"
                                        style={{ overflow: 'hidden' }}
                                    // width="500"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-sm-6 col-lg-3 mt-2">
                            <div style={{ width: '100%', minHeight: 190, height: '100%', borderRadius: 10, boxShadow: '0px 1px 5px #888888', backgroundImage: 'linear-gradient(to top right, #57CAE5, #3ABCDE)', padding: 10 }}>
                                <div style={{ padding: 15, paddingBottom: 0 }}>

                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <h3 style={{ color: "white", fontFamily: 'sans-serif', fontWeight: "bold", fontSize: 28 }}>{moneyMask2(parseFloat(this.state.saldo_financeiro).toFixed(2))}</h3>
                                    </div>
                                    <div>
                                        <p style={{ color: 'white', fontSize: 15 }}>Valor financeiro</p>
                                    </div>
                                </div>

                                <div>
                                    <Chart
                                        options={spark2}
                                        series={spark2.series}

                                        type="line"
                                        height="70"
                                        style={{ overflow: 'hidden' }}
                                    // width="500"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-sm-6 col-lg-3 mt-2">
                            <div style={{ width: '100%', minHeight: 190, height: '100%', borderRadius: 10, boxShadow: '0px 1px 5px #888888', backgroundImage: 'linear-gradient(to top right, #FAC924, #F6B000)', padding: 10 }}>
                                <div style={{ padding: 15, paddingBottom: 0 }}>

                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <h3 style={{ color: "white", fontFamily: 'sans-serif', fontWeight: "bold", fontSize: 28 }}>{maskNumberBr(this.state.num_ecopontos_ativos)}</h3>
                                    </div>
                                    <div>
                                        <p style={{ color: 'white', fontSize: 15 }}>Número de ecopontos ativos</p>
                                    </div>
                                </div>

                                <div>
                                    <Chart
                                        options={spark3}
                                        series={spark3.series}

                                        type="area"
                                        height="80"
                                        style={{ overflow: 'hidden' }}
                                    // width="500"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-sm-6 col-lg-3 mt-2">
                            <div style={{ width: '100%', minHeight: 190, height: '100%', borderRadius: 10, boxShadow: '0px 1px 5px #888888', backgroundImage: 'linear-gradient(to top right, #F7777B, #F5424D)', padding: 10 }}>
                                <div style={{ padding: 15, paddingBottom: 0 }}>

                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <h3 style={{ color: "white", fontFamily: 'sans-serif', fontWeight: "bold", fontSize: 28 }}>{maskNumberBr(this.state.solicitacoes_coleta_total)}</h3>
                                    </div>
                                    <div>
                                        <p style={{ color: 'white', fontSize: 15 }}>Número de solicitações de coleta</p>
                                    </div>
                                </div>

                                <div>
                                    <Chart
                                        options={spark4}
                                        series={spark4.series}

                                        type="bar"
                                        height="80"
                                        style={{ overflow: 'hidden' }}
                                    // width="500"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="card ">
                        <div className="card-body" id="card" style={{ paddingBottom: 0 }}>



                            {/* <hr /> */}
                            <div>

                                <div className="row mb-2">
                                    <div className="col-12 col-md-12">
                                        <h3>Resumo Financeiro</h3>

                                    </div>
                                    {this.state.loading_graph == false && <div className="col-12 col-md-12 custom_scrollbar">
                                        {/* <LineAreaChart /> */}
                                        <Chart
                                            options={this.state.options_}
                                            series={this.state.options_.series}
                                            type="bar"
                                            height="400"
                                        // width="500"
                                        />
                                    </div>}
                                    {this.state.loading_graph == true && <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div>}



                                </div>


                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>



                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => { this.delete_entrada_coleta(this.state.registro_to_delete); }}
                                onCancel={() => { this.setState({ show_warning: false }); }}
                                show={this.state.show_warning}
                                confirmBtnText='Sim, desejo apagar'
                                cancelBtnText='Cancelar'
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                showCancel={true}
                            >
                                Ao deletar este registro de entrada ele não estará mais disponível no sistema

                            </SweetAlert>
                            <SweetAlert
                                danger
                                title={"Erro"}
                                onConfirm={() => {
                                    this.setState({ show_erro: false });
                                }}
                                show={this.state.show_erro}
                                confirmBtnText='OK'
                                confirmBtnBsStyle="primary"

                            >
                                {this.state.msgErro}

                            </SweetAlert>

                        </div>

                        {!this.state.loading_graph &&
                            <div className='d-flex' style={{ padding: 10, backgroundColor: '#F0F3F6' }}>
                                <div className="col-6">
                                    <div className='py-2' style={{ borderBottom: '1px solid lightgrey' }}>
                                        <p style={{ color: 'grey', marginBottom: 2, fontSize: 13 }}>Financeiro</p>
                                        <div className="d-flex">
                                            <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold' }}>EVOLUÇÃO EM R$</p>

                                            <div className="d-flex m-auto">
                                                <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold' }}>
                                                    {this.state.saldos_graficos_difference.label}
                                                </p>

                                                {this.state.saldos_graficos_difference.icon}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="my-2">
                                        <p style={{ color: 'grey', marginBottom: 2, fontSize: 13 }}>Resíduos</p>
                                        <div className="d-flex">
                                            <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold' }}>EVOLUÇÃO EM {this.state.unity}</p>

                                            <div className="d-flex m-auto">
                                                <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold' }}>
                                                    {this.state.pesos_grafico_difference.label}
                                                </p>

                                                {this.state.pesos_grafico_difference.icon}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className='py-2' style={{ borderBottom: '1px solid lightgrey' }}>
                                        <p style={{ color: 'grey', marginBottom: 2, fontSize: 13 }}>Ecopontos ativos</p>
                                        <div className="d-flex">
                                            <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold' }}>EVOLUÇÃO EM UNIDADES ATIVAS</p>

                                            <div className="d-flex m-auto">
                                                <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold', marginLeft: 35 }}>
                                                    {this.state.pontos_coleta_grafico_difference.label}
                                                </p>

                                                {this.state.pontos_coleta_grafico_difference.icon}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="my-2">
                                        <p style={{ color: 'grey', marginBottom: 2, fontSize: 13 }}>Solicitação de coleta</p>
                                        <div className="d-flex">
                                            <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold' }}>EVOLUÇÃO EM DADOS QUANTITATIVOS</p>

                                            <div className="d-flex m-auto">
                                                <p style={{ marginBottom: 0, fontSize: 13, fontWeight: 'bold', textAlign: 'end', marginStart: 2 }}>
                                                    {this.state.solicitacoes_grafico_difference.label}
                                                </p>

                                                {this.state.solicitacoes_grafico_difference.icon}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                    </div>
                    <br />
                    <div className="row " >
                        <div className="col-12 col-sm-6 col-md-4 mt-2" >
                            <div className="card" >

                                <div style={{ height: 80, backgroundColor: '#35589D', borderTopLeftRadius: 5, borderTopRightRadius: 5, display: "flex", justifyContent: 'center', alignItems: "center" }}>
                                    <h2 style={{ textAlign: "center", color: 'white', fontSize: 16 }}>MAIORES QUANTIDADES ({this.state.unity})</h2>
                                </div>
                                <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", paddingBottom: 10, paddingTop: 10 }}>
                                    <div style={{ borderRight: '1px solid lightgrey', width: '50%', paddingRight: 10 }}>
                                        <p style={{ marginBottom: 3, textAlign: 'end', fontWeight: 'bold' }}>{this.state.residuo_maior_kg.length == 0 ? 0 : maskNumberBr(this.state.residuo_maior_kg[0].soma)} {this.state.unity}</p>
                                        <p style={{ fontSize: 13, textAlign: 'end', fontWeight: 'bold', marginBottom: 0 }}>{this.state.residuo_maior_kg.length == 0 ? '-' : this.state.residuo_maior_kg[0].nome}</p>

                                    </div>
                                    <div style={{ width: '50%', paddingLeft: 10 }}>
                                        <p style={{ marginBottom: 3, textAlign: 'start', fontWeight: 'bold' }}>{this.state.residuo_maior_kg.length == 0 ? '0%' : maskNumberBr(parseFloat((parseFloat(this.state.residuo_maior_kg[0].soma) / parseFloat(this.state.quantidade_residuos_coletados)) * 100).toFixed(2)) + '%'}</p>

                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className="col-12 col-sm-6 col-md-4 mt-2" >
                            <div className="card" >
                                <div
                                    className="d-flex flex-column justify-content-center align-items-center rounded-top"
                                    style={{ height: '100px', backgroundColor: '#33AEF3' }}
                                >
                                    <h2 className="text-white mb-0" style={{ fontSize: '16px' }}>MAIORES VALORES (R$)</h2>
                                    {this.state.residuo_maior_valor.length > 0 && <span className="text-white" style={{ fontSize: '12px' }}>({this.state.residuo_maior_valor[0].movimentacao})</span>}
                                </div>
                                <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", paddingBottom: 10, paddingTop: 10 }}>
                                    <div style={{ borderRight: '1px solid lightgrey', width: '50%', paddingRight: 10 }}>
                                        <p style={{ marginBottom: 3, textAlign: 'end', fontWeight: 'bold' }}>{this.state.residuo_maior_valor.length == 0
                                            ? moneyMask2('0.00')
                                            : "R$" + (this.state.residuo_maior_valor[0].movimentacao == "Despesa" ? " -" : " ") + maskNumberBr(parseFloat(this.state.residuo_maior_valor[0].soma).toFixed(2))
                                        }</p>
                                        <p style={{ fontSize: 13, textAlign: 'end', fontWeight: 'bold', marginBottom: 0 }}>{this.state.residuo_maior_valor.length == 0 ? '-' : this.state.residuo_maior_valor[0].nome}</p>
                                    </div>
                                    <div style={{ width: '50%', paddingLeft: 10 }}>
                                        <p style={{ marginBottom: 3, textAlign: 'start', fontWeight: 'bold' }}>{this.state.residuo_maior_valor.length == 0 ? '0%' : maskNumberBr(parseFloat(((parseFloat(this.state.residuo_maior_valor[0].soma) / parseFloat(this.state.total_valor)) || 0) * 100).toFixed(2)) + '%'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-4 mt-2" >
                            <div className="card" >
                                <div style={{ height: 80, backgroundColor: '#45B26B', borderTopLeftRadius: 5, borderTopRightRadius: 5, display: "flex", justifyContent: 'center', alignItems: "center" }}>
                                    <h2 style={{ textAlign: "center", color: 'white', fontSize: 16 }}>PONTO DE COLETA MAIS ATIVO</h2>
                                </div>
                                <div style={{ display: "flex", justifyContent: 'center', alignItems: "center", paddingBottom: 10, paddingTop: 10 }}>
                                    <div style={{ borderRight: '1px solid lightgrey', width: '50%', paddingRight: 10 }}>
                                        <p style={{ marginBottom: 3, textAlign: 'end', fontWeight: 'bold' }}>{!this.state.ponto_coleta_maior_valor ? `0 ${this.state.unity}` : `${maskNumberBr(parseFloat(this.state.ponto_coleta_maior_valor.total_peso).toFixed(2))} ${this.state.unity}`}</p>
                                        <p style={{ fontSize: 13, textAlign: 'end', fontWeight: 'bold', marginBottom: 0 }}>{!this.state.ponto_coleta_maior_valor ? '-' : this.state.ponto_coleta_maior_valor.nome_fantasia}</p>
                                    </div>
                                    <div style={{ width: '50%', paddingLeft: 10 }}>
                                        <p style={{ marginBottom: 3, textAlign: 'start', fontWeight: 'bold' }}>{!this.state.ponto_coleta_maior_valor ? '0%' : maskNumberBr(parseFloat(((parseFloat(this.state.ponto_coleta_maior_valor.total_peso) / parseFloat(this.state.quantidade_residuos_coletados)) || 0) * 100).toFixed(2)) + '%'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br />
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-6 ">


                                    <div className="row">
                                        <div className="col-6 d-flex align-items-end">
                                            <div style={{ width: 5, height: 60, backgroundColor: '#3CBFDF', borderRadius: 3, marginRight: 10 }}>
                                            </div>
                                            <div>
                                                <p style={{ fontSize: 15, color: "grey", marginBottom: 5 }}>Quantidade de resíduos no período</p>
                                                <h4 style={{ marginBottom: 0 }}>{maskNumberBr(this.state.quantidade_residuos_coletados)} {this.state.unity}</h4>
                                            </div>

                                        </div>

                                        <div className="col-6 d-flex align-items-end">
                                            <div style={{ width: 5, height: 60, backgroundColor: '#F5545B', borderRadius: 3, marginRight: 10 }}>
                                            </div>
                                            <div>
                                                <p style={{ fontSize: 15, color: "grey", marginBottom: 5 }}>Valor movimentado (R$) no período</p>
                                                <h4 style={{ marginBottom: 0 }}>{maskPrice(parseFloat(this.state.saldo_financeiro).toFixed(2))}</h4>
                                            </div>

                                        </div>
                                        <div className="col-12 ">
                                            <hr className="mt-3 mb-1" />
                                        </div>

                                        <div className="col-12">
                                            <div className="d-flex align-items-center">
                                                <p style={{ margin: 0 }}>Ordenar por - {this.last_param === 'soma_kg' ? 'quantidade' : 'valor movimentado'}</p>
                                                <div className="ms-auto">
                                                    <IconButton onClick={() => this.sortRrBy(this.last_param === 'soma_kg' ? 'soma_valor' : 'soma_kg')}>
                                                        <BsArrowDownUp size={20} color={this.last_param === 'soma_kg' ? '#3CBFDF' : '#F5545B'} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                            {this.state.lista_rr.map(item => (
                                                <div key={item.id} className="row mt-3">
                                                    <div className="col-2" style={{ margin: 'auto auto' }}>
                                                        <p style={{ fontSize: 15, color: "grey", marginBottom: 0 }}>{item.nome}</p>
                                                    </div>
                                                    <div className="col-10" style={{ margin: 'auto auto' }}>
                                                        <div className="d-flex align-items-center">
                                                            <div className='col-10' style={{ height: 5, backgroundColor: '#f2f2f2', borderRadius: 2, marginTop: 2, cursor: 'pointer' }}
                                                                data-tip={maskNumberBr(parseFloat(((parseFloat(item.soma_kg) / parseFloat(this.state.quantidade_residuos_coletados)) || 0) * 100).toFixed(2)) + '%'}>

                                                                <div style={{
                                                                    width: parseFloat(((Math.abs(parseFloat(item.soma_kg)) / parseFloat(this.state.quantidade_residuos_coletados)) || 0) * 100).toFixed(2) + '%',
                                                                    height: 5,
                                                                    backgroundColor: '#3CBFDF',
                                                                    borderRadius: 2
                                                                }}>
                                                                </div>
                                                            </div>
                                                            <span className='col-2 ms-2' style={{ fontSize: '.85em', whiteSpace: 'nowrap' }}>
                                                                {item.soma_kg} {this.state.unity}
                                                            </span>
                                                        </div>

                                                        <div className="d-flex align-items-center">
                                                            <div className='col-10' style={{ height: 5, backgroundColor: '#f2f2f2', borderRadius: 2, cursor: 'pointer' }}
                                                                data-tip={maskNumberBr(parseFloat(((Math.abs(parseFloat(item.soma_valor)) / parseFloat(this.state.total_valor)) || 0) * 100).toFixed(2)) + '%'}>
                                                                <div style={{
                                                                    width: parseFloat(((Math.abs(parseFloat(item.soma_valor)) / parseFloat(this.state.total_valor)) || 0) * 100).toFixed(2) + '%',
                                                                    height: 5,
                                                                    backgroundColor: '#F5545B',
                                                                    borderRadius: 2
                                                                }}>
                                                                </div>
                                                            </div>
                                                            <span className='col-2 ms-2' style={{ fontSize: '.85em', whiteSpace: 'nowrap' }}>
                                                                {moneyMask2(item.soma_valor)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>



                                    </div>
                                </div>



                                <div className="col-12 col-md-6 ">
                                    <br className="d-block d-md-none" />
                                    <br className="d-block d-md-none" />

                                    <div className="row">
                                        <div className="col-6 d-flex align-items-end">
                                            <div style={{ width: 5, height: 60, backgroundColor: '#FABB00', borderRadius: 3, marginRight: 10 }}>
                                            </div>
                                            <div>
                                                <p style={{ fontSize: 15, color: "grey", marginBottom: 5 }}>Solicitações de coleta no período</p>
                                                <h4 style={{ marginBottom: 0 }}>{maskNumberBr(this.state.solicitacoes_coleta_total)}</h4>
                                            </div>

                                        </div>
                                        <div className="col-6 d-flex align-items-end">
                                            <div style={{ width: 5, height: 60, backgroundColor: '#3FBE64', borderRadius: 3, marginRight: 10 }}>
                                            </div>
                                            <div>
                                                <p style={{ fontSize: 15, color: "grey", marginBottom: 5 }}>Ecopontos ativos</p>
                                                <h4 style={{ marginBottom: 0 }}>{maskNumberBr(this.state.num_ecopontos_ativos)}</h4>
                                            </div>

                                        </div>
                                        <div className="col-12 ">

                                            <hr className="mt-3 mb-1" />
                                        </div>

                                        <div className="col-12">
                                            <div className="row mt-3">
                                                <div className="col-12 pointer" style={{ margin: 'auto auto' }} data-tip={this.state.solicitacoes_coleta_aguardando + ' solicitações'}>
                                                    <div className="d-flex justify-content-between">
                                                        <p style={{ fontSize: 15, color: "grey", marginBottom: 3, marginLeft: 5 }}>Aguardando</p>
                                                        <p style={{ fontSize: '.85em', color: "black", marginBottom: 3, marginLeft: 5 }}>{maskNumberBr(parseFloat(((this.state.solicitacoes_coleta_aguardando / this.state.solicitacoes_coleta_total) || 0) * 100).toFixed(2)) + '%'}</p>

                                                    </div>
                                                    <div style={{ width: '100%', height: 5, backgroundColor: '#f2f2f2', borderRadius: 2 }}>
                                                        <div style={{ width: parseFloat(((this.state.solicitacoes_coleta_aguardando / this.state.solicitacoes_coleta_total) || 0) * 100).toFixed(2) + '%', height: 5, backgroundColor: '#FABB00', borderRadius: 2 }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-12 pointer" style={{ margin: 'auto auto' }} data-tip={this.state.solicitacoes_coleta_andamento + ' solicitações'}>
                                                    <div className="d-flex justify-content-between">
                                                        <p style={{ fontSize: 15, color: "grey", marginBottom: 3, marginLeft: 5 }}>Iniciado</p>
                                                        <p style={{ fontSize: '.85em', color: "black", marginBottom: 3, marginLeft: 5 }}>{maskNumberBr(parseFloat(((this.state.solicitacoes_coleta_andamento / this.state.solicitacoes_coleta_total) || 0) * 100).toFixed(2)) + '%'}</p>

                                                    </div>
                                                    <div style={{ width: '100%', height: 5, backgroundColor: '#f2f2f2', borderRadius: 2 }}>
                                                        <div style={{ width: parseFloat(((this.state.solicitacoes_coleta_andamento / this.state.solicitacoes_coleta_total) || 0) * 100).toFixed(2) + '%', height: 5, backgroundColor: '#FABB00', borderRadius: 2 }}>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-12 pointer" style={{ margin: 'auto auto' }} data-tip={this.state.solicitacoes_coleta_finalizado + ' solicitações'}>
                                                    <div className="d-flex justify-content-between">
                                                        <p style={{ fontSize: 15, color: "grey", marginBottom: 3, marginLeft: 5 }}>Finalizado</p>
                                                        <p style={{ fontSize: '.85em', color: "black", marginBottom: 3, marginLeft: 5 }}>{maskNumberBr(parseFloat(((this.state.solicitacoes_coleta_finalizado / this.state.solicitacoes_coleta_total) || 0) * 100).toFixed(2)) + '%'}</p>

                                                    </div>
                                                    <div style={{ width: '100%', height: 5, backgroundColor: '#f2f2f2', borderRadius: 2 }}>
                                                        <div style={{ width: parseFloat(((this.state.solicitacoes_coleta_finalizado / this.state.solicitacoes_coleta_total) || 0) * 100).toFixed(2) + '%', height: 5, backgroundColor: '#FABB00', borderRadius: 2 }}>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <hr className="mt-3 mb-1" />
                                            {this.state.pontos_coleta.sort((a, b) => {
                                                const totalKgA = a?.total_peso || 0;
                                                const totalKgB = b?.total_peso || 0;

                                                if (totalKgA > totalKgB) {
                                                    return -1;
                                                } else if (totalKgA < totalKgB) {
                                                    return 1;
                                                } else {
                                                    return 0;
                                                }
                                            }).map((item, id) => {
                                                const totalSum = item?.total_peso || null

                                                return (
                                                    <div key={id} className="row mt-3">
                                                        <div className="col-12" style={{ margin: 'auto auto' }}>
                                                            <div className="d-flex justify-content-between">
                                                                <p style={{ fontSize: 15, color: "grey", marginBottom: 3, marginLeft: 5 }}>{item.nome_fantasia}</p>
                                                                <p style={{ fontSize: '.85em', color: "black", marginBottom: 3, marginLeft: 5 }}>{totalSum ? maskNumberBr(item.total_peso) + ' ' + this.state.unity : ''}  <span style={{ color: "grey", fontSize: '.85em' }}>({totalSum ? maskNumberBr(parseFloat(100 * ((parseFloat(item.total_peso) / this.state.quantidade_residuos_coletados) || 0)).toFixed(2)) + '%' : ' Coleta não finalizada '})</span></p>
                                                            </div>

                                                            <div style={{ width: '100%', height: 5, backgroundColor: '#f2f2f2', borderRadius: 2 }}>
                                                                <div style={{ width: totalSum ? parseFloat(100 * ((parseFloat(item.total_peso) / this.state.quantidade_residuos_coletados) || 0)).toFixed(2) + '%' : '0', height: 5, backgroundColor: '#3FBE64', borderRadius: 2 }}>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ReactTooltip place="right" effect="solid" />
                </div>}

                {this.state.loading_screen &&
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                            <div className="spinner-border text-primary" role="status"></div>
                        </div>
                    </div>}
            </div>
        );
    }

    handlePageClick(page) {
        if (page.selected + 1 != this.state.page) {
            this.setState({ page: page + 1 });
            this.get_dados_dashboard(page.selected + 1);

        }
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(Dashboard);


