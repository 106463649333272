import React, { useCallback, useEffect, useState } from 'react';
import Routes from './routes';
import { useSelector, useDispatch } from 'react-redux'
import { login, logout, mudarDados } from './actions/AppActions';
import { URL, LOGO, URLadmin, NAME } from './variables';
import Theme from './Home/Theme';
import { ThemeProvider } from '@mui/material';
import { ToastContent } from './Auxiliar/Alerts';


function App() {
    useEffect(() => {
        window.process = {
            ...window.process,
        };
    }, []);
    const dispatch = useDispatch();
    let token = localStorage.getItem('token');
    let user = localStorage.getItem('user');

    const get_logo = useCallback(() => {
        fetch(`${URLadmin}api/auth/admin/blueen_get_ambiente?connection=${NAME}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();

                try {
                    dispatch(mudarDados({ logo: URLadmin + resp.organization.logo ?? '' }));
                } catch {
                    dispatch(mudarDados({ logo: LOGO }));
                }

                if (!token) {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    dispatch(logout());
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    setTimeout(() => {
                        get_logo();
                    }, 5000);
                }
            } catch (err) {
                setTimeout(() => {
                    get_logo();
                }, 5000);
                console.log(err);
            }

        })
            .catch((err) => {
                setTimeout(() => {
                    get_logo();
                }, 5000);
            });
    }, [token])

    useEffect(() => {
        const event = (e) => {
            if (window.innerWidth <= 768) {
                dispatch(mudarDados({ toggled: false, collapsed: false }));

            }
            else {

                dispatch(mudarDados({ toggled: true, collapsed: false }));

            }
        };
        window.addEventListener('resize', event)
        if (window.innerWidth <= 768) {
            dispatch(mudarDados({ toggled: false, collapsed: false }));
        }
        else {
            dispatch(mudarDados({ toggled: true, collapsed: false }));
        }
        get_logo();

        return () => {
            window.removeEventListener('resize', event);

            // Anything in here is fired on component unmount.
        }
    }, [])


    if (user == null || user == undefined) {
        user = {};
    } else {
        user = JSON.parse(user);
    }
    console.log(token);
    dispatch({ type: 'login', payload: { token: token, user: user } });



    return (
        <div className="App">
            {/* {handleIcon()} */}
            <ThemeProvider theme={Theme}>
                <Routes />
                <ToastContent />
            </ThemeProvider>
        </div>
    );
}

export default App;
