import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Link, Redirect, useParams } from 'react-router-dom'
import { URL } from '../../../variables';
import moment from 'moment';
import { FaTrash, FaPlus } from 'react-icons/fa';
import { MdReply } from 'react-icons/md';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Alert } from '@mui/material';

function EditRelatorio() {

  const [state, setState] = React.useState({
    relatorio: {},
    submateriais: [],
    token: '',
    loading: true,
    loading_save: false,
    show_warning: false,
    redirect: false,
    msgErro: '',
    sub_to_delete: '',
    submateriais_excluidos: [],
  })
  const params = useParams();
  let id = params.id;
  state.token = useSelector(state => state.AppReducer.token);

  useEffect(() => {
    fetch(`${URL}api/get_view_relatorio/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        console.log('json', json)
        setState({
          ...state,
          relatorio: json.relatorio,
          submateriais: json.submateriais,
          loading: false,
        })
      })
  }, [])

  function delete_submaterial_relatorio() {
    let submateriais_remover = state.submateriais_excluidos

    fetch(`${URL}api/update_relatoriosub`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${state.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        submateriais_remover
      })

    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token'); localStorage.removeItem('user');
          state.props.logout();
          return;
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);
          setState({ ...state, msgErro: erro });
        }
        else {
          setState({ ...state, loading_save: true });
        }
      } catch (err) {
        setState({ ...state, loading_save: true });
        console.log(err);
      }
    })
      .catch((err) => {
        setState({ ...state, loading_save: true });
        console.log(err);
      });
  }

  function submateriais_selecionados() {
    return (
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Submateriais excluídos:</label>
          <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }}>
            <thead>
              <tr>
                <th>Sub-material</th>
                <th>Resíduo/Rejeito</th>
                <th>Peso</th>
                <th>Unidade</th>
                <th>Inserir</th>
              </tr>
            </thead>
            <tbody>
              {state.submateriais_excluidos.map((item, id) => (
                <tr key={id}>
                  <td>{item.sub_material.nome}</td>
                  <td>{item.separacao_residuo_rejeito.nome}</td>
                  <td>{item.quantidade}</td>
                  <td>{item.unidade.sigla}</td>
                  <td><button className="btn btn-primary btn-outline btn-circle m-r-5" style={{ background: 'white', }}
                    onClick={() => {
                      let state_ = { ...state }
                      state_.submateriais.push(item)
                      state_.submateriais_excluidos = state_.submateriais_excluidos.filter((item2, id) => item2.id != item.id)
                      setState(state_)
                    }}><FaPlus color='green' /></button></td >
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  function delete_relatoriosub(id) {
    fetch(`${URL}api/delete_relatoriosub/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token'); localStorage.removeItem('user');
          // this.setState({redirect:true,path:'/'});
          state.props.logout();
          return;
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);
          setState({ ...state, msgErro: erro, show_warning: true, show_erro: true });
        }
        else {
          setState({ ...state, show_warning: true });

        }
      } catch (err) {
        setState({ ...state, show_warning: true });
        console.log(err);
      }
    })
      .catch((err) => {
        setState({ redirect: true });
        console.log(err);
      });
  }

  var validate = function(event) {
    var t = event.target.value;
    event.target.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
    console.log(event.target.value)
  }

  return (
    <>
      {state.redirect == true && <Redirect to={'/relatorio'} />}
      {state.loading == false && <div>
        <div className='row'>
          {state.loading == false && <div>
            <div className='col-12'>
              <div className='card'>
                <div className='card-body'>
                  <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Relatório de Submateriais</h4>
                  <hr />

                  <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados do relatório:</label>
                  <div className="form-group row">
                    <div className="col-sm-12"><label className="col-sm-12 col-form-label" >Código do Relatório: <span style={{ fontWeight: 'normal' }}>{state.relatorio.codigo_relatorio}</span></label></div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12"><label className="col-sm-12 col-form-label" >Data de Expedição: <span style={{ fontWeight: 'normal' }}>{moment(state.relatorio.created_at).format('DD/MM/YYYY')}</span></label></div>
                  </div>
                  {state.submateriais.length == 0 && <Alert className='my-3' severity='info'>Esse relatório não possui sub-materiais adicionados</Alert>}

                  {state.submateriais.length > 0 && <div className="table-rep-plugin">
                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                      <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Submateriais selecionados:</label>
                      <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }}>
                        <thead>
                          <tr>
                            <th>Sub-material</th>
                            <th>Resíduo/Rejeito</th>
                            <th>Peso</th>
                            <th>Unidade</th>
                            <th>Excluir</th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.loading == false && state.submateriais.map((item, id) => (
                            <tr key={id}>
                              <td>{item.sub_material.nome}</td>
                              <td>{item.separacao_residuo_rejeito.nome}</td>
                              <td>{item.quantidade}</td>
                              <td>{item.unidade.sigla}</td>
                              <td><button className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white', }}
                                onClick={() => {
                                  let state_ = { ...state }
                                  state_.submateriais_excluidos.push(item)
                                  state_.submateriais = state_.submateriais.filter((item2, id) => item2.id != item.id)
                                  setState(state_)
                                }}><FaTrash color='red' /></button></td >
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>}
                  {/*tabela de submateriais a serem excluídos*/}

                  <div className="d-grid gap-2 d-flex justify-content-between">
                    <Link to={'/relatorio'}>
                      <button type="button" className="btn btn-secondary waves-effect waves-light" style={{ backgrundColor: '#f2f2f2' }}><MdReply />Voltar</button>
                    </Link>
                    {state.submateriais.length == 0 && state.submateriais_excluidos == 0 &&
                      <button type='button' className='btn btn-danger' onClick={() => { delete_relatoriosub(state.relatorio.id) }}>
                        Excluir Relatório
                      </button>}
                    < button type="button" className="btn btn-success" style={{ backgrundColor: '#f2f2f2' }}
                      onClick={delete_submaterial_relatorio}
                    > Salvar</button >
                  </div>

                </div>
              </div>
            </div>
          </div>}
          {state.loading_save &&
            <SweetAlert
              success
              title={"Sucesso"}
              onConfirm={() => {
                setState({ loading_save: false, redirect: true })
              }}
              confirmBtnText='OK'
              confirmBtnBsStyle="success"
            >
              As alterações foram salvas com sucesso!
            </SweetAlert>}

          {state.show_warning &&
            <SweetAlert
              success
              title={"Sucesso"}
              onConfirm={() => {
                setState({ show_warning: false, redirect: true })
              }}
              confirmBtnText='OK'
              confirmBtnBsStyle="success"
            >
              O relatório foi deletado com sucesso!
            </SweetAlert>}

          {state.loading == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
            <div className="spinner-border text-primary" role="status"  >
            </div>
          </div></div>}
        </div>
      </div>
      }
    </>
  )
}

export default EditRelatorio