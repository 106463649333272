import React from 'react';
import Acondicionamentos from './Acondicionamento';
import AcondicionamentosPendentes from './AcondicionamentoSigor';
import Filter from '../../../Auxiliar/Filter';
import { useSelector } from 'react-redux';

const SelecionarJsAcondicionamento = () => {
    const permission = useSelector(state => state.AppReducer.permissoes.sigor.acesso)

    const [search, setSearch] = React.useState(false)
    const [dateOf, setDateOf] = React.useState('')
    const [dateFor, setDateFor] = React.useState('')
    const [options, setOptions] = React.useState({
        acondicionamento: { value: true, label: 'Acondicionamentos Finalizados', checked: true },
        acondicionamentoPendente: { value: false, label: 'Acondicionamentos Pendentes(SIGOR)', checked: false, disabled: !permission },
    })

    return (
        <div className="card">
            <div className="card-body" id="card">
                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Acondicionamentos</h4>
                <Filter setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setSearch} />
                <div>
                    <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados os Acondicionamentos do sistema</p>
                </div>
                <div className="row">
                    {options.acondicionamento.value &&
                        <Acondicionamentos dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                    {options.acondicionamentoPendente.value &&
                        <AcondicionamentosPendentes dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                </div>
            </div>
        </div>
    )
}

export default SelecionarJsAcondicionamento