//react
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

//components
import { MdVisibility } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import { Alert } from "@mui/material";

//utils
import { NAME, URLadmin } from "../../../../variables";

export default function ListaDestinacaoInternaColeta({ dateOf, dateFor }) {
  const [destinations, setDestinations] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    fetch(
      `${URLadmin}api/auth/admin/internal_relationship/destination_collect/index?connection=${NAME}`,
      {
        method: "GET",
        headers: { Accept: "application/json" },
      }
    )
      .then(async (responseLog) => {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.response) {
          setDestinations(resp.data);
          return;
        }

        console.error("err", resp.message);
      })
      .catch((err) => {
        console.error("err", err);
      });
  }

  return (
    <>
      <div className="col-12 d-flex justify-content-end">
        <Link to="/destinacao_interna/criar">
          <span className="btn btn-primary btn-lg waves-effect waves-light mt-1">
            Adicionar destinação
          </span>
        </Link>
      </div>
      <div className="table-rep-plugin pt-3">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          {destinations.length > 0 ? (
            <table
              id="tech-companies-1"
              className="table table-striped table-hover"
              style={{ textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th>Destino</th>
                  <th>Data de Início</th>
                  <th>Saindo de (estoque)</th>
                  {/* <th>Observação</th> */}
                  <th data-priority="3">Ações</th>
                </tr>
              </thead>
              {/* dados da lista ----------------------------------- */}
              <tbody>
                {destinations.map((item, index) => (
                  <tr key={index}>
                    <td>{item.destination}</td>
                    <td>{item.pickupDate}</td>
                    <td>{item.stock}</td>
                    {/* <td>{item.observation || "-"}</td> */}
                    <td className="d-flex align-items-center justify-content-center">
                      <Link
                        to={`/destinacao_interna_coleta/view/${item.destinationOrganizationSuperID}/${item.collectSolicitationID}`}
                      >
                        <span
                          className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                          data-tip="Ver"
                        >
                          <MdVisibility style={{ color: "white" }} />
                          <ReactTooltip />
                        </span>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <Alert severity="info">
              Nenhuma destinação/solicitação de coleta interna encontrada
            </Alert>
          )}
        </div>
      </div>
    </>
  );
}
