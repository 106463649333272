import React from 'react';
import { URL } from '../variables';
import { Link, Redirect } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { login } from '../actions/AppActions';
import Logo from '../assets/images/logo4.png';
import BG from '../assets/images/verdaz.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Login extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            msgErro: '',
            email: '',
            password: '',
            newpassword: '',
            newpassword2: '',
            page: 0,
            redirect: false,
            path: '',
            loading_save: false,
            year: new Date(),
        }

    }
    login(event) {
        event.preventDefault();
        this.setState({ loading_save: true, msgErro: "" });

        if (this.state.page == 0) {
            fetch(`${URL}api/auth/login`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    // 'Authorization': `Bearer ${this.props.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: this.state.email,
                    password: this.state.password
                })
            }).then(async (responseLog) => {
                try {
                    let resp = await responseLog.json();
                    console.log(resp);
                    if (resp.errors != null || resp.error != null) {
                        let errors = Object.values(resp.errors);
                        let erro = '';
                        for (let i = 0; i < errors.length; i++) {
                            if (i != errors.length - 1)
                                erro += errors[i] + '\n';
                            else
                                erro += errors[i];
                        }
                        console.log(erro);

                        if (erro == 'Este usuário precisa alterar a senha.') {
                            this.setState({  page: 1 });
                        erro='';

                        }

                        this.setState({ loading: false, refresh: false, loadingMore: false, loading_save: false, msgErro: erro });
                    }
                    else {
                        this.setState({ loading_save: false });
                        let permissoes = {};
                        for (let i = 0; i < resp.user.tipo_usuario.permissoes.length; i++) {
                            permissoes[resp.user.tipo_usuario.permissoes[i].modulo] = resp.user.tipo_usuario.permissoes[i];
                        }
                        resp.user.tipo_usuario.permissoes = permissoes;
                        localStorage.setItem('token', resp.access_token);
                        localStorage.setItem('user', JSON.stringify(resp.user));
                        this.props.login({ token: resp.access_token, user: resp.user });
                    }
                } catch (err) {
                    console.log(err);
                    this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor', refresh: false, loadingMore: false, loading_save: false });
                }

            })
                .catch((err) => {
                    console.log(err);
                    this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?', refresh: false, loadingMore: false, loading_save: false });
                });
        }
        /* ********************CASO ESTEJA MARCADO QUE 'SIM' P/ USUÁRIO ALTERAR A SENHA NO PRÓXIMO ACESSO******************** */
        else if (this.state.page == 1) {
            if (this.state.newpassword == '' || this.state.newpassword2 == '') {
                this.setState({ loading_save: false, msgErro: 'Preencha os campos corretamente' })
                return;
            }
            if (this.state.newpassword != this.state.newpassword2) {
                this.setState({ loading_save: false, msgErro: 'As senhas são diferentes.' })
                return;
            }
            if (this.state.newpassword.length < 6) {
                this.setState({ loading_save: false, msgErro: 'A senha precisa ter no mínimo 6 caracteres.' })
                return;
            }
            if (this.state.newpassword.length >= 100) {
                this.setState({ loading_save: false, msgErro: 'A senha não pode ter mais que 100 caracteres.' })
                return;
            }
            else {
                toast.loading(
                    'Alterando senha...',
                    {
                        toastId:
                            'toastpromisse' + this.state.newpassword,
                        hideProgressBar: false,
                    }
                )
                fetch(`${URL}api/auth/update_password`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: this.state.email,
                        password: this.state.newpassword,
                        password_confirmation: this.state.newpassword2
                    })
                }).then(async (responseLog) => {
                    try {
                        let resp = await responseLog.json();
                        if (resp.errors != null || resp.error != null) {
                            let errors = Object.values(resp.errors);
                            let erro = '';
                            for (let i = 0; i < errors.length; i++) {
                                if (i != errors.length - 1)
                                    erro += errors[i] + '\n';
                                else
                                    erro += errors[i];
                            }
                            console.log(erro);
                            window.scrollTo(500, 0);
                            this.setState({ loading_save: false });
                            toast.update(
                                'toastpromisse' + this.state.newpassword, {
                                render: erro,
                                type: "error",
                                isLoading: false,
                                autoClose: 1000,
                            });
                        }
                        else {
                            this.setState({ loading_save: false, redirect: true });
                            /********************Alerta para o usuário saber se sua senha foi alterada com sucesso ou não.*********************/
                            toast.update(
                                'toastpromisse' +
                                this.state.newpassword,
                                {
                                    render: 'Senha alterada com sucesso',
                                    type: 'success',
                                    isLoading: false,
                                    autoClose: 1500,
                                },
                            );
                            setTimeout(() => {
                                this.props.history.push('/login')
                            }, 2500)
                        }
                    } catch (err) {
                        console.log(err);
                        this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });
                        toast.update('toastpromisse' + this.state.newpassword, {
                            render: "Erro ao alterar senha",
                            type: "error",
                            isLoading: false,
                            autoClose: 1000,
                        });
                    }
                })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
                        toast.update('toastpromisse' + this.state.newpassword, {
                            render: "Erro ao alterar senha",
                            type: "error",
                            isLoading: false,
                            autoClose: 1000,
                        });
                    });
            }
        }
    }

    componentDidMount() {
        console.log(this.props);
        const { match: { params } } = this.props;
    }

    changeEmail(event) {
        let text = event.target.value
        let final_num = '';
        final_num = text;
        this.setState({ email: final_num })
    }

    changePassword(event) {
        this.setState({ password: event.target.value })
    }

    render() {
        console.log(this.props.logo)
        return (
            <div>
                {this.state.redirect == true && <Redirect to={this.state.path} />}
                {this.state.loading && <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <div className="spinner-border text-primary" role="status"  >
                </div></div>}
                {this.state.loading == false && <div>
                    <div className="col-12 d-block" style={{ overflow: 'hidden', backgroundImage: `url(${BG})`, backgroundSize: 'cover', padding: 0, margin: 0, height: '100vh' }}>
                        <div className="row" style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="col-10 col-sm-8 col-md-6 col-xl-4">
                                <div className="card" style={{ margin: 0 }}>
                                    <div className="card-body" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>

                                        <div className="text-center">
                                            <a className="logo"><img src={this.props.logo}
                                                style={{
                                                    objectFit: 'contain',
                                                    height: '100px',
                                                    width: '90%'
                                                }} height="60" alt="logo" /></a>
                                        </div>

                                        <div style={{ padding: '1rem', paddingBottom: 0 }}>




                                            <form className="form-horizontal m-t-30" onSubmit={this.login.bind(this)}>
                                                {this.state.page == 0 && <div>
                                                    <h4 className="font-18 m-b-5 text-center header-title">Bem vindo !</h4>
                                                    <p className="text-muted text-center" >Faça login para continuar.</p>
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: 0, paddingRight: 0 }}>E-mail <b style={{ color: 'red' }}>*</b></label>

                                                        <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                            <input id="email" type="username"
                                                                className="form-control  " name="email"
                                                                value={this.state.email} onChange={this.changeEmail.bind(this)} required autoComplete="email" autoFocus />

                                                        </div>

                                                    </div>

                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: 0, paddingRight: 0 }}>Senha <b style={{ color: 'red' }}>*</b></label>

                                                        <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                            <input id="password" type="password"
                                                                value={this.state.password}
                                                                onChange={this.changePassword.bind(this)}
                                                                className="form-control" name="password"
                                                                required autoComplete="current-password" />



                                                        </div>

                                                    </div>
                                                </div>}
                                                {/* ********************CASO ESTEJA MARCADO QUE 'SIM' P/ USUÁRIO ALTERAR A SENHA NO PRÓXIMO ACESSO******************** */}
                                                {this.state.page == 1 && <div>
                                                    <h4 className="font-18 m-b-5 text-center header-title">Criar nova senha</h4>
                                                    <p className="text-muted text-center" >Crie uma nova senha com no mínimo 6 caracteres.</p>

                                                    <div className="form-group row">
                                                        <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                            <label className="col-sm-12 col-form-label" style={{ paddingLeft: 0, paddingRight: 0 }}>Insira a senha nova<b style={{ color: 'red' }}>*</b></label>
                                                            <input id="password" type="password"
                                                                className="form-control  " name="password"
                                                                value={this.state.newpassword}
                                                                onChange={(e) => this.setState({ newpassword: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                            <label className="col-sm-12 col-form-label" style={{ paddingLeft: 0, paddingRight: 0 }}>Insira a senha nova mais uma vez<b style={{ color: 'red' }}>*</b></label>
                                                            <input id="password2" type="password"
                                                                className="form-control  " name="password2"
                                                                value={this.state.newpassword2}
                                                                onChange={(e) => this.setState({ newpassword2: e.target.value })} />
                                                        </div>
                                                    </div>

                                                </div>}

                                                <div className="form-group row ">
                                                    <div className="col-sm-12" style={{ padding: 0 }}>
                                                        <span className="invalid-feedback" style={{ display: 'flex', justifyContent: 'center' }} role="alert">
                                                            <strong style={{ textAlign: 'center', fontSize: '0.8rem' }}>{this.state.msgErro}</strong>
                                                        </span>
                                                    </div>
                                                    {this.state.loading_save == false && <div className="col-sm-12 text-right" style={{ padding: 0 }}>
                                                        {this.state.page == 0 && <div> <button className="btn btn-primary w-md waves-effect waves-light" style={{ width: '100%', marginTop: '15px', height: '3rem', }} type="submit">Entrar</button>
                                                            <div className="my-2 d-flex justify-content-end align-items-center" style={{ paddingTop: "10px" }}>
                                                                <Link to={"/novasenha"}>
                                                                    <a className="auth-link text-black">Esqueci minha senha</a>
                                                                </Link>
                                                            </div> </div>}
                                                        {this.state.page == 1 && <button className="btn btn-primary w-md waves-effect waves-light" style={{ width: '100%', marginTop: '15px', height: '3rem', }} type="submit">Avançar</button>}

                                                    </div>}
                                                    {this.state.loading_save == true && <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                                        <div className="spinner-border text-primary" role="status"  >
                                                        </div>
                                                    </div>}
                                                </div>


                                                <div className="form-group m-t-10 mb-0 row">
                                                    <div className="col-12 m-t-20" style={{ textAlign: 'center' }}>

                                                        {/* <a href="{{ action('Auth\ForgotPasswordController@showLinkRequestForm',$faculdade->slug) }}">
                                            <i className="mdi mdi-lock"></i>{{ __('Esqueceu sua senha?') }}
                                        </a>  */}
                                                        {/* {this.state.faculdade == null && <a href="{{ action('Auth\ForgotPasswordController@showLinkRequestForm','admin') }}">
                                                    <i className="mdi mdi-lock"></i>Esqueceu sua senha?
                                        </a>} */}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="text-center mt-4">
                                            <p>© {this.state.year.getFullYear()} Blueen</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer
                                position="bottom-right"
                                toastStyle={{ border: '1px solid gray', borderRadius: 4 }}
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={true}
                                closeOnClick
                                rtl={true}
                                pauseOnHover
                            />
                        </div>
                    </div>
                </div >}
            </div>
        );
    }


}

const mapsStateToProps = (state) => (
    {
        logo: state.AppReducer.logo,
    }
);

export default connect(mapsStateToProps, { login })(Login);
