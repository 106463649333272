import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { MdClose } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, TextField } from '@mui/material';
import SweetAlert from 'react-bootstrap-sweetalert';
import { URL } from '../../variables';
const style = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  border: "2px solid transparent",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  "@media(max-width: 1000px)": {
    width: "90%",
    left: "50%",
    transform: "translateX(-50%)",
  },
};

const buttonStyle = {
  margin: "0 8px",
};

const vinculaMtrAoDespacho = async (manNumero, id, token, setState, state) => {
  
  if (manNumero !== '') {
    return fetch(`${URL}api/post_vincular_mtr_ao_despacho`, {
      method: 'POST',
      headers: {
        Accept: 'application/pdf',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dispachoId: id,
        mtr: manNumero
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
         
          if (resp.errors != null) {
            toast.warning('Erro ao vincular MTR!');
          
          }
          else { 
            toast.success('MTR vinculado com sucesso');
            return true;
          }

          return false;
        } catch (err) {
          console.log(err);
          toast.warning('Erro ao vincular MTR!');
          return false;
        
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Erro ao pegar resposta do servidor. Você está conectado à internet?');
      });
  }
};

export default function GerarRRModal({ open, onClose, onConfirm, token, despachoId }) {
  const [showInformarMTRContent, setShowInformarMTRContent] = useState(false);
  const [manNumero, setManNumero] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [state, setState] = useState({});

  useEffect(() => {
    const message = localStorage.getItem('toastMessage');
    if (message) {
      const type = localStorage.getItem('toastType');
      if (type === 'success') {
        toast.success(message);
      } else {
        toast.error(message);
      }
      localStorage.removeItem('toastMessage');
      localStorage.removeItem('toastType');
    }
  }, []);

  const handleProcessRR = () => {
    onConfirm();
    onClose();
  };

  const handleOpenInformarMTRModal = () => {
    setShowInformarMTRContent(true);
  };

  const handleClose = () => {
    onClose();
  };

  const handleMtrChange = (event) => {
    setManNumero(event.target.value);
  };

  const handleSubmit = () => {
    setShowWarning(true);
  };

  const handleVinculaMtrAoDespacho = async () => {
    const success = await vinculaMtrAoDespacho(manNumero, despachoId, token, setState, state);
    if (success) {
      onConfirm(manNumero);
    }
    setShowWarning(false);
    onClose();
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="d-flex justify-content-end">
              <span style={{ cursor: "pointer" }} onClick={handleClose}>
                <MdClose />
              </span>
            </div>
            {showInformarMTRContent ? (
              <>
                <h4 className="header-title" style={{ fontSize: "1.5rem", color: "black", textAlign: "left", marginBottom: "10px" }}>
                  Informar MTR
                </h4>
                <TextField
                  label="Número do MTR"
                  variant="outlined"
                  fullWidth
                  value={manNumero}
                  onChange={handleMtrChange}
                  style={{ marginBottom: '16px' }}
                />
                <div className="mt-2 d-flex justify-content-center">
                  <Button variant="contained" color="primary" onClick={handleSubmit} sx={buttonStyle}>
                    Buscar
                  </Button>
                </div>
              </>
            ) : (
              <>
                <h4 className="header-title" style={{ fontSize: "1.5rem", color: "black", textAlign: "left", marginBottom: "10px" }}>
                  Gerar RR
                </h4>
                <p style={{ textAlign: "left", marginBottom: "20px" }}>
                  Sem MTR vinculado ao processo. Deseja finalizar despacho sem MTR?
                </p>
                <div className="mt-2 d-flex justify-content-center">
                  <Button variant="contained" color="primary" onClick={handleProcessRR} sx={buttonStyle}>
                    Sim
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleClose} sx={buttonStyle}>
                    Não
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleOpenInformarMTRModal} sx={buttonStyle}>
                    Informar MTR
                  </Button>
                </div>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
      
      <ToastContainer
        position="bottom-right"
        toastStyle={{ border: "1px solid gray", borderRadius: 4 }}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnHover
      />

      <SweetAlert
        warning
        title="Atenção"
        onConfirm={handleVinculaMtrAoDespacho}
        onCancel={() => setShowWarning(false)}
        show={showWarning}
        confirmBtnText="Sim"
        cancelBtnText="Cancelar"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        showCancel={true}
      >
        Deseja vincular esse MTR ao Despacho?
      </SweetAlert>
    </>
  );
}
