import React from 'react'
import PropTypes from 'prop-types'

const SwitchInput = ({ title, handleChange, isChecked }) => {
  return (
    <div className="form-check form-switch m-0 p-0">
      <input className="form-check-input" type="checkbox" role="switch" id={title} checked={isChecked} onChange={handleChange} />
      <label className="form-check-label" htmlFor={title}>{title}</label>
    </div>
  )
}

SwitchInput.propTypes = {
  title: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool
};

SwitchInput.defaultProps = {
  title: 'Need to inherit a title',
  handleChange: () => console.log('Need to inherit a callback'),
}

export default SwitchInput