
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// Destinos
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdSearch } from 'react-icons/md';
import { maskNumberBr } from '../../../Auxiliar/Masks';

class EstoqueSubmateriais extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            DadosEstoque: [],
            page: 1,
            total_pages: 1,
            msgErro: '',
            show_warning: false,
            show_erro: false,
            loading_search: false,
            search: ''
        };
    }

    componentDidMount() {
        this.get_estoque(this.state.page);
        this.props.setSearch(false)
    }

    componentDidUpdate() {
        if (this.props.search) {
            this.props.setSearch(false)
            this.get_estoque(this.state.page);
        }
    }

    get_estoque(page, search = '', busca = false) {
        if (busca == true) {
            if (this.state.loading_search == false) {
                this.setState({ loading_search: true });
            }
        }
        else if (this.state.loading_screen === false) {
            this.setState({ loading_screen: true });
        }
        console.log(search);
        fetch(`${URL}api/get_estoque_sub_materiais?page=${page}&search=${search}&dateOf=${this.props.dateOf}&dateFor=${this.props.dateFor}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    this.setState({ DadosEstoque: resp.estoque, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page, loading_search: false });

                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false); 
            });
    }




    render() {
        console.log(this.state.total_pages);
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}




                {this.state.loading_screen == false && <div>
                    <div className="row mb-2">
                        <div className="col-12 col-md-6">
                            <form className="app-search mt-1" style={{ width: '100%' }} onSubmit={(e) => {
                                clearTimeout(this.pesquisarTimeout); this.setState({ page: 1 });
                                this.get_estoque(1, this.state.search, true)
                                e.preventDefault();
                            }}>
                                <div className="form-group mb-0" style={{ width: '100%', position: 'relative' }}>
                                    <input value={this.state.search} name="search" placeholder="Pesquisar pelo Material ou Estoque de armazenamento ..." className="form-control" style={{ width: '100%', paddingRight: 40, borderRadius: 100 }} onChange={(e) => {
                                        clearTimeout(this.pesquisarTimeout);
                                        let search = e.target.value;
                                        this.setState({ page: 1, search: search });
                                        this.pesquisarTimeout = setTimeout(() => {
                                            this.get_estoque(1, search, true)
                                        }, 500);
                                    }} />
                                    <div style={{ width: 40, position: "absolute", right: 0, top: 0, bottom: 0, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                        <MdSearch />

                                    </div>

                                </div>
                            </form>
                            {/* {this.state.search_oficial != '' && <span>Buscando por banners: {this.state.search_oficial}</span>} */}

                        </div>

                    </div>
                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }}>
                                <thead>
                                    <tr>

                                        <th>Estoque de armazenamento</th>
                                        <th>Material</th>
                                        <th>Peso total</th>
                                        <th>Volume total</th>
                                        <th>Peso disponivel</th>
                                        <th>Volume disponivel</th>
                                        <th>Total em Kg</th>
                                        <th>Total em L</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {/*this.state.loading_search == false && this.state.DadosEstoque.map((item, id) => (

                                        <tr key={id}>
                                            <td>{item.estoque.nome_fantasia}</td>
                                            <td>{item.material.nome}</td>

                                            <td>{maskNumberBr(item.peso_total)} {item.material.unidade_principal.tipo == 'Peso' ?
                                                item.material.unidade_principal.sigla : item.material.unidade_secundaria.sigla}</td>

                                            <td>{maskNumberBr(item.volume_total)} {item.material.unidade_principal.tipo == 'Volume' ?
                                                item.material.unidade_principal.sigla : item.material.unidade_secundaria.sigla}</td>

                                            <td>{maskNumberBr(item.peso_disponivel)} {item.material.unidade_principal.tipo == 'Peso' ?
                                                item.material.unidade_principal.sigla : item.material.unidade_secundaria.sigla}</td>

                                            <td>{maskNumberBr(item.volume_disponivel)} {item.material.unidade_principal.tipo == 'Volume' ?
                                                item.material.unidade_principal.sigla : item.material.unidade_secundaria.sigla}</td>

                                            <td>{maskNumberBr(item.peso_total_kg)} kg</td>
                                            <td>{maskNumberBr(item.volume_total_lt)} lt</td>
                                        </tr>
                                    ))*/}

                                </tbody>
                            </table>
                            {this.state.loading_search == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>

                            }
                        </div>
                    </div>
                    {this.state.loading_search == false && this.state.DadosEstoque.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma entrada cadastrada</p>

                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                            <ReactPaginate
                                previousLabel={'Anterior'}
                                nextLabel={'Próxima'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.total_pages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={this.handlePageClick.bind(this)}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                                initialPage={this.state.page - 1}
                            />
                        </div>
                    </div>



                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                </div>}

                {this.state.loading_screen == true &&
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                            <div className="spinner-border text-primary" role="status"  >
                            </div>
                        </div>
                    </div>}


            </div>


        );
    }

    handlePageClick(page) {
        console.log(page.selected);
        if (page.selected + 1 != this.state.page) {
            this.setState({ page: page + 1 });
            this.get_estoque(page.selected + 1,this.state.search);

        }
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(EstoqueSubmateriais);


