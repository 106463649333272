import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';
import { FaTrash } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { URL } from '../../../variables';
import 'react-toastify/dist/ReactToastify.css';
import ReactInputMask from 'react-input-mask';
import { NumericFormat } from 'react-number-format';
import { maskNumberBr } from '../../../Auxiliar/Masks';

const Separacao = ({ edit, setEdit }) => {
  console.log('edit', edit, setEdit)
  const [state, setState] = React.useState({
    material: '',
    materiais: [],
    material_selecionado: '',
    despacho: [],
    sub_materiais: [],
    sub_inicial: [],
    sub_materiais_inseridos: [],
    sub_material_selecionado: [],
    pesagem: [],
    list: [],
    sub_iniciais_inseridos: [],
    unidade: '',

    quantidade: '',
    unidade_principal: '',
    unidade_secundaria: '',
    estoque_id: '',
    separacao_id: '',
    despacho_id: '',
    sub_material: '',
    search: '',
    DataAtual: '',
    Codigo: '',
    DataSolicitacao: '',
    convert: '',
    equivalencia: '',
    tipo: 'Principal',
    peso: '',
    peso_inicial: '',
    peso_total: 0,
    peso_kg: [],

    loading_save: false,
    loading: true,
    msgErro: '',
    token: '',
    error: false,
    error_value: false,
    render: false,
    renderSelecionados: false,
    required: false,
    saving: false,
  })
  const params = useParams();
  let id = params.id;
  const history = useHistory();
  state.token = useSelector(state => state.AppReducer.token);

  {/* //----------------------------------------Effect para carregar dados----------------------------------------// */ }
  React.useEffect(() => {
    fetch(`${URL}api/get_pesagem_separacao/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        console.log('json', json)
        let materiais = json.material[0].pesagem_residuo_rejeito

        {/* //----------Criação das listas de sub-materiais alteráveis, constantes e pesagem para cada material----------// */ }
        json.material[0].pesagem_residuo_rejeito.forEach(item => {
          state.list = [...state.list, item]
          state.peso_kg = [...state.peso_kg, { id: item.residuo_rejeito_id, peso: item.peso_kg }]
          state.sub_inicial = [...state.sub_inicial, { id: item.residuo_rejeito_id, sub_materiais: item.residuo_rejeito.sub_material }]
        })
        {/* //----------------------------------------Caso a separação receba props de update, vai preencher os a lista de sub-materiais, removendo da lista, do select e suas respectivas pesagens----------------------------------------// */ }
        if (edit) {
          let residuos = [...edit.residuos]
          console.log('teste list', state.list)
          residuos.forEach(item => {
            let sub_material = {
              "residuo_rejeito_id": item.residuo_rejeito_id,
              "sub_material_id": item.sub_material.id,
              "nome": item.sub_material.nome,
              "material": item.separacao_residuo_rejeito.nome,
              "quantidade": item.quantidade,
              "unidade": item.separacao_residuo_rejeito.unidade_principal.sigla,
              "unidade_sec": item.separacao_residuo_rejeito.unidade_secundaria.sigla,
              "equival_prin": item.separacao_residuo_rejeito.unidade_principal.equivalencia,
              "equival_sec": item.separacao_residuo_rejeito.equivalencia,
              "equival_kg": item.separacao_residuo_rejeito.unidade_principal.equivalencia,
              "equival_lt": item.separacao_residuo_rejeito.unidade_principal.equivalencia,
            }
            {/* //---------------------------Adicionando cada item nos sub-materiais inseridos---------------------------// */ }
            state.sub_materiais_inseridos = [...state.sub_materiais_inseridos, sub_material];
            state.sub_iniciais_inseridos = [...state.sub_iniciais_inseridos, sub_material];

            {/* //----------------------------------------Subtraindo do select----------------------------------------// */ }
            state.list = state.list.map(item2 => {
              if (item2.residuo_rejeito_id == item.residuo_rejeito_id) {
                item2.residuo_rejeito.sub_material = item2.residuo_rejeito.sub_material.filter(item3 => item3.id != item.sub_material.id)
              }
              return item2
            })
            {/* //------------------------------------Subtraindo pesagem de cada item------------------------------------// */ }
            state.peso_kg = state.peso_kg.map(item3 => {
              if (item3.id == item.residuo_rejeito_id) {
                item3.peso = item3.peso - item.quantidade * sub_material.equival_kg
              }
              return item3
            })
          })
          state.renderSelecionados = true
        }
        setState({
          ...state,
          despacho_id: json.material[0].despacho_finalizado_id,
          materiais: materiais,
          peso_inicial: json.material[0].pesagem_residuo_rejeito[0].peso_kg,
          equivalencia: json.material[0].pesagem_residuo_rejeito[0].residuo_rejeito.equivalencia,
          estoque_id: json.material[0].despacho_finalizado_id,
          pesagem: json.material[0],
          render: true,
          loading: false,
        })
      })
  }, []);

  {/* //------------------------------Função para criar ou atualizar separação e resíduos--------------------------------// */ }
  async function storeSeparacao(tipo) {
    setState({ ...state, saving: true })
    toast.promise(async () => {
      if (!edit) {
        toast.loading('Criando Separação ', {
          toastId: 'toastid',
        });
        const resp = await fetch(`${URL}api/store_separacao`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Authorization': `Bearer ${state.token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            pesagem_id: state.pesagem.id,
          })
        })
        const json = await resp.json();
        // setState({ ...state, separacao_id: json.separacao.id })
        state.separacao_id = json.separacao.id
        toast.update('toastid', { render: "Separação criada com Sucesso!", type: "success", isLoading: false, autoClose: 1000 });
      }
      state.sub_materiais_inseridos.forEach(item => {
        toast.loading('Registrando ' + item.nome, {
          toastId: 'toastid' + item.sub_material_id,
        });
      })

      {/* //-----------------------------Verificações para montar request de store ou update-----------------------------// */ }
      let separacao_id;
      let meth;
      { edit ? separacao_id = edit.separacao_id : separacao_id = state.separacao_id }
      { edit ? meth = 'PATCH' : meth = 'POST' }
      console.log('separacao', separacao_id)
      const store = { separacao_id: separacao_id, sub_materiais: state.sub_materiais_inseridos, sub_materiais_inseridos: state.sub_iniciais_inseridos, despacho_id: state.despacho_id };
      console.log('store', store)
      await fetch(`${URL}api/${tipo}_residuo_separacao`, {
        method: (meth),
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${state.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(store)
      })
      setState({ ...state, loading_save: true })
      state.sub_materiais_inseridos.forEach(item => {
        toast.update('toastid' + item.sub_material_id, { render: "Sucesso!", type: "success", isLoading: false, autoClose: 1000 });
      })
    }, {
      //pending: "Carregando registros",
      //success: "Registros salvos com sucesso",
      error: "Falha ao salvar registros",
    });
  }

  {/* //----------------------------------------Função renderizar dados despacho----------------------------------------// */ }
  function renderMaterial() {
    if (state.material_selecionado[0]) {
      let filtrado = state.peso_kg.filter(item => item.id == state.material_selecionado[0].residuo_rejeito.id)[0]
      state.peso = filtrado.peso
      console.log(state.peso_kg)
    }

    return (
      <>
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">Código do despacho: <span style={{ fontWeight: 'normal' }}>{state.despacho}</span></label>
        </div>
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">Separação em Pendente: <span style={{ fontWeight: 'normal' }}>{state.peso ? state.peso + ' KG' : 'Selecione um material'}</span></label>
        </div>
      </>
    )
  }

  {/* //----------------------------------------Função renderizar sub-materiais----------------------------------------// */ }
  function renderSubMateriais() {
    return (
      <>
        <div className="row">
          {/* //----------------------------------------Materiais----------------------------------------// */}
          <div className="col-6 my-3">
            <label className="col-form-label" >Materiais <b style={{ color: 'red' }}>*</b></label>
            <select value={state.material} className="form-control" onChange={(e) => {
              const selecionado = state.materiais.filter((item) => item.residuo_rejeito.id == e.target.value);
              setState({
                ...state,
                required: false,
                material: e.target.value,
                material_selecionado: selecionado,
                unidade_principal: selecionado[0].residuo_rejeito.unidade_principal,
                unidade_secundaria: selecionado[0].residuo_rejeito.unidade_secundaria,
                equivalencia: selecionado[0].residuo_rejeito.equivalencia,
                sub_materiais: selecionado[0].residuo_rejeito.sub_material,
                sub_material_selecionado: [],
                convert: '',
                quantidade: '',
                render: true,
              })
            }}>
              <option value={''}>Selecione o material para separação </option>
              {state.materiais.map((item) => (<option key={item.id} value={item.residuo_rejeito.id}>{item.residuo_rejeito.nome}</option>))}
            </select>
          </div>
          {/* //----------------------------------------Sub-Materiais----------------------------------------// */}
          <div className="col-6 my-3">
            <label className="col-form-label" >Sub-materiais <b style={{ color: 'red' }}>*</b></label>
            {state.material_selecionado ? <select value={state.sub_material} className="form-control" onChange={(e) => {
              setState({
                ...state,
                required: false,
                sub_material: e.target.value,
                sub_material_selecionado: state.material_selecionado[0].residuo_rejeito.sub_material.filter((item) => item.id == e.target.value),
                equivalencia: state.material_selecionado[0].residuo_rejeito.equivalencia,
                render: true,
              })
            }}>
              {state.list.filter(item => item.id == state.material_selecionado[0].id)[0].residuo_rejeito.sub_material == 0 && <option value={''}>Sem sub materiais para separação </option>}
              {state.list.filter(item => item.id == state.material_selecionado[0].id)[0].residuo_rejeito.sub_material != 0 && <option value={''}>Selecione um ou mais sub-materiais </option>}
              {state.list.filter(item => item.id == state.material_selecionado[0].id)[0].residuo_rejeito.sub_material.map((item) => (<option key={item.id} value={item.id}>{item.nome}</option>))}
            </select> : <select className="form-control" disabled><option>Selecione um ou mais sub-materiais</option></select>}
          </div>
          {/* //----------------------------------------Tabela----------------------------------------// */}
          {state.sub_material_selecionado && (state.sub_material_selecionado.length > 0 && state.sub_material != '') &&
            <>
              <div className="col-12 my-3">
                <table className='table table-striped text-center'>
                  <thead className='table-group-divider'>
                    <tr>
                      <th>Peso</th>
                      <th>Volume</th>
                      <th>Peso Kg</th>
                      <th>Volume L</th>
                      <th>Peso Separação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{maskNumberBr(state.material_selecionado[0].peso)} {state.unidade_principal.sigla}</td>
                      <td>{maskNumberBr(state.material_selecionado[0].volume)} {state.unidade_secundaria.sigla}</td>
                      <td>{maskNumberBr(state.material_selecionado[0].peso_kg)} Kg</td>
                      <td>{maskNumberBr(state.material_selecionado[0].volume_lt)} L</td>
                      <td>{maskNumberBr(state.peso_total)} KG</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* //----------------------------------------Unidades----------------------------------------// */}
              <div className="col-4 my-3">
                <div className="col-12">
                  <label className="col-form-label" >Tipo <b style={{ color: 'red' }}>*</b></label>
                  <label className="col-form-label" style={{ color: 'red', textTransform: 'uppercase' }}>{state.sub_material_selecionado[0].nome}</label>
                </div>
                <select value={state.tipo} className="form-control" onChange={(e) => {
                  setState({
                    ...state,
                    tipo: e.target.value,
                    quantidade: state.convert,
                    convert: state.quantidade,
                    required: false,
                  })
                }}>
                  <option value={'Principal'}>{state.unidade_principal.nome}</option>
                  <option value={'Secundaria'}>{state.unidade_secundaria.nome}</option>
                </select>
              </div>
              {/* //----------------------------------------Unidade Principal----------------------------------------// */}
              <div className="col-4 my-3">
                <label className="col-sm-12 col-form-label">{state.tipo == 'Principal' ? state.unidade_principal.tipo : state.unidade_secundaria.tipo}<b style={{ color: 'red' }}>*</b></label>
                <div className="input-group mb-3">
                  <NumericFormat className="form-control " id="peso"
                    placeholder={state.tipo == 'Principal' ? state.unidade_principal.tipo : state.unidade_secundaria.tipo}
                    type="text"
                    thousandsGroupStyle='thousand'
                    thousandSeparator="."
                    decimalScale={2}
                    decimalSeparator=","
                    valueIsNumericString
                    value={state.quantidade}
                    onValueChange={(values,e) => atualizaPeso(values.value)} />
                  <span className="input-group-text" id="basic-addon3">{tipoPeso('Pri')}</span>
                </div>
                {state.error && <p className='text-danger small'>Digite um valor dentro do peso disponível</p>}
                {state.error_value && <p className='text-danger small'>Digite um valor válido</p>}
              </div>
              {/* //----------------------------------------Unidade Secundaria----------------------------------------// */}
              <div className="col-4 my-3">
                <label className="col-sm-12 col-form-label">{state.tipo == 'Principal' ? state.unidade_secundaria.tipo : state.unidade_principal.tipo}</label>
                <div className="input-group mb-3">
                  <NumericFormat className="form-control " id="peso"
                  thousandsGroupStyle='thousand'
                  thousandSeparator="."
                  decimalScale={2}
                  decimalSeparator=","
                  valueIsNumericString
                  placeholder={state.tipo == 'Principal' ? state.unidade_secundaria.tipo : state.unidade_principal.tipo}
                  type="text" 
                  value={state.convert} disabled />
                  <span className="input-group-text" id="basic-addon3">{tipoPeso('Sec')}</span>
                </div>
              </div>
            </>}
        </div>
        {/* //----------------------------------------Botão----------------------------------------// */}
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
          <button type="button" className="btn btn-success" style={{ backgorundColor: '#f2f2f2' }} onClick={() => {
            {/* //--------------------------------------Verificações para salvar--------------------------------------// */ }
            if (state.quantidade == '' || state.quantidade == 0) {
              setState({ required: true });
            }
            else {
              let quant = 0
              let nums = Array.from(state.quantidade)
              state.tipo == "Principal" ? quant = state.quantidade : quant = state.quantidade / state.equivalencia
              if (nums[0] == '.' || nums[nums.length - 1] == '.') {
                setState({ ...state, error_value: true })
              } else {
                {/* //----------------------------------------Criação de objeto----------------------------------------// */ }
                let material_selecionado = {
                  "residuo_rejeito_id": state.sub_material_selecionado[0].residuo_rejeito_id,
                  "sub_material_id": state.sub_material_selecionado[0].id,
                  "nome": state.sub_material_selecionado[0].nome,
                  "material": state.material_selecionado[0].residuo_rejeito.nome,
                  "quantidade": quant,
                  "unidade": state.material_selecionado[0].residuo_rejeito.unidade_principal.sigla,
                  "unidade_sec": state.material_selecionado[0].residuo_rejeito.unidade_secundaria.sigla,
                  "equival_prin": state.material_selecionado[0].residuo_rejeito.unidade_principal.equivalencia,
                  "equival_sec": state.material_selecionado[0].residuo_rejeito.equivalencia,
                  "equival_kg": state.material_selecionado[0].peso_kg / state.material_selecionado[0].peso,
                  "equival_lt": state.material_selecionado[0].volume_lt / state.material_selecionado[0].peso,
                }
                {/* //-------------------------------Removendo objeto da lista no select-------------------------------// */ }
                if (Number(quant * material_selecionado.equival_kg) <= Number(state.peso)) {
                  let sub_material = { ...state.list.filter(item => item.id == state.material_selecionado[0].id) }
                  sub_material[0].residuo_rejeito.sub_material = sub_material[0].residuo_rejeito.sub_material.filter((item) => item.id != material_selecionado.sub_material_id)

                  {/* //---------------------------------Decrementando peso do objeto-----------------------------------// */ }
                  state.peso_kg = state.peso_kg.map(item2 => {
                    if (item2.id == state.material_selecionado[0].residuo_rejeito.id) {
                      item2.peso = item2.peso - quant * material_selecionado.equival_kg
                    }
                    return item2
                  })
                  setState({
                    ...state,
                    sub_materiais: state.sub_materiais.filter((item) => item.nome != material_selecionado.nome),
                    sub_materiais_inseridos: [...state.sub_materiais_inseridos, material_selecionado],
                    sub_material_selecionado: [],
                    peso: state.peso - quant * material_selecionado.equival_kg,
                    peso_total: Number(state.peso_total) + Number(quant),
                    quantidade: '',
                    convert: '',
                    renderSelecionados: true,
                  })
                } else {
                  setState({ ...state, error: true });
                }
              }
            }
          }
          }>Inserir</button>
        </div>
      </>
    )
  }

  {/* //--------------------------------------Função verificar tipos de unidades--------------------------------------// */ }
  function tipoPeso(value) {
    if (value == 'Pri') {
      if (state.tipo == 'Principal') {
        return state.material_selecionado[0].residuo_rejeito.unidade_principal.sigla
      } if (state.tipo == 'Secundaria') {
        return state.material_selecionado[0].residuo_rejeito.unidade_secundaria.sigla
      }
    } else if (value == 'Sec') {
      if (state.tipo == 'Principal') {
        return state.material_selecionado[0].residuo_rejeito.unidade_secundaria.sigla
      } if (state.tipo == 'Secundaria') {
        return state.material_selecionado[0].residuo_rejeito.unidade_principal.sigla
      }
    }
  }

  {/* //-------------------------Função converter peso de unidades principais e secundarias-------------------------// */ }
  function atualizaPeso(value) {

    if (state.tipo == 'Principal') {
      setState({
        ...state,
        required: false,
        error: false,
        error_value: false,
        quantidade: value,
        convert: value * state.equivalencia,
      })
    } else {
      setState({
        ...state,
        required: false,
        error: false,
        error_value: false,
        quantidade: value,
        convert: value / state.equivalencia,
      })
    }
  }

  {/* //----------------------------Função renderizar sub-materiais inseridos na lista---------------------------------// */ }
  function renderSelecionados() {
    return (
      <div className='m-3'>
        {state.sub_materiais_inseridos && <>
          <hr />
          <div className="form-group row">
            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Sub-materiais selecionados:</label>

            <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }} >
              <thead>
                <tr>
                  <th>Material</th>
                  <th>Sub-Material</th>
                  <th>Peso</th>
                  <th>Volume</th>
                  <th>Peso Kg</th>
                  <th>Volume L</th>
                  <th data-priority="3">Excluir</th>
                </tr>
              </thead>
              <tbody>
                {state.sub_materiais_inseridos.map((item, id) => (
                  <tr key={id}>
                    <td>{item.material}</td>
                    <td>{item.nome}</td>
                    <td>{maskNumberBr(item.quantidade)} {item.unidade}</td>
                    <td>{maskNumberBr(item.quantidade * item.equival_sec)} {item.unidade_sec}</td>
                    <td>{maskNumberBr(item.quantidade * item.equival_kg)} Kg</td>
                    <td>{maskNumberBr(item.quantidade * item.equival_lt)} L</td>
                    <td>
                      <button onClick={() => {
                        {/* //---No botão é filtrado de qual material cada sub pertence de acordo com o ID do residuo e rejeito ---// */ }
                        let sub_material = state.list.filter(item2 => item2.residuo_rejeito.id == item.residuo_rejeito_id)

                        {/* //----------Filtro para carregar a lista inicial dos sub-materiais de cada material----------// */ }
                        let sub_inicial = state.sub_inicial.filter(item3 => item3.id == item.residuo_rejeito_id)

                        {/* //---------------Filtro para identificar qual sub retornará à lista------------------------// */ }
                        let devolucao = sub_inicial[0].sub_materiais.filter((item4) => item4.nome == item.nome);
                        sub_material[0].residuo_rejeito.sub_material.push(devolucao[0]);
                        let sub_materiais_inseridos = state.sub_materiais_inseridos.filter((item2) => item2.nome != item.nome);
                        let render = true;
                        if (sub_materiais_inseridos.length == 0) render = false

                        {/* //------------------------------Adição do peso de volta à lista------------------------------// */ }
                        state.peso_kg = state.peso_kg.map(item2 => {
                          if (item2.id == item.residuo_rejeito_id) {
                            item2.peso = item2.peso + item.quantidade * item.equival_kg
                          }
                          return item2
                        })

                        setState({
                          ...state,
                          sub_material: '',
                          sub_materiais: sub_material,
                          sub_materiais_inseridos: sub_materiais_inseridos,
                          peso_total: Number(state.peso_total) - Number(item.quantidade),
                          quantidade: '',
                          convert: '',
                          renderSelecionados: render,
                        })
                      }}
                        className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white', }}><FaTrash color='red' /></button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <hr />
          <button className='btn btn-large btn-success float-end' onClick={() => { edit ? storeSeparacao('update') : storeSeparacao('store') }} disabled={state.saving}>Salvar</button>
        </>}
      </div>
    )
  }

  {/* //----------------------------------------------Return do componente----------------------------------------------// */ }
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Separação</h4>
        <p className='lead'>separação dos materiais para triagem</p>
        <hr />
        {state.render && state.loading == false && renderMaterial()}
        {state.render && state.loading == false && renderSubMateriais()}
        {state.renderSelecionados && state.loading == false && renderSelecionados()}
        {state.loading && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={
          { marginTop: '2rem' }}>
          <div className="spinner-border text-primary" role="status"  >
          </div>
        </div>
        </div>}

        {state.loading_save &&
          <SweetAlert
            success
            title={"Sucesso"}
            onConfirm={() => {
              setState({ ...state, loading_save: false })
              history.push('/separacao')
            }}
            confirmBtnText='OK'
            confirmBtnBsStyle="success"
          >
            Dados inseridos com sucesso

          </SweetAlert>}

        <ToastContainer
          position="bottom-right"
          // style={{border:'1px solid gray',height:75.5, position:'absolute',
          // borderRadius:4,alignItems:'center', alignContent:'center'}}
          toastStyle={{ border: '1px solid gray', borderRadius: 4 }}
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnHover
        />
      </div>
    </div>
  )
}

export default Separacao