import Echo from "laravel-echo";
import Pusher from "pusher-js";

let echo = null;

export const wsConnection = () => {
  // window.Pusher = Pusher;
  if (echo !== null) {
    try {
      DCWebSocket();
    } catch (err) {
      console.log(err);
    }
  }
  echo = new Echo({
    broadcaster: "pusher",
    key: "2baa23c626fb460b87e1",
    cluster: "sa1",
    wsHost: "blueenopenwebsocket.enterscience.com.br",
    host: "blueenopenwebsocket.enterscience.com.br:443",
    wsPort: 443,
    transports: ["websocket"],
    enabledTransports: ["wss"],
    forceTLS: false,
    disableStats: true,
    encrypted: false,
  });
  // echo = new Echo({
  //   broadcaster: "pusher",
  //   key: "2baa23c626fb460b87e1",
  //   cluster: "sa1",
  //   wsHost: "localhost",
  //   wsPort: 7001,
  //   transports: ["websocket"],
  //   enabledTransports: ["ws"],
  //   forceTLS: false,
  //   disableStats: true,
  //   encrypted: false,
  // });

  return echo;
};

export const DCWebSocket = () => {
  echo.disconnect();
};

export const notifications = (echo, info, callback) => {
  if (info.private_notifications) {
    echo
      .channel(`organization.${info.org_name}.${info.reducer.user.id}`)
      .listen(".Notification", function (e) {
        console.log('if: ', e)
        callback(e);
      });
  } else {
    echo
      .channel(`organization.${info.org_name}`)
      .listen(".Notification", function (e) {
        console.log('else: ', e)
        callback(e);
      });
  }
};
