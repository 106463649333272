import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import ReactTooltip from 'react-tooltip';
import { MdVisibility } from 'react-icons/md';
import { CircularProgress } from '@mui/material';

const style = {
  position: 'absolute',
  // top: '50%',
  left: '50%',
  bgcolor: 'background.paper',
  width: '50%',
  height: '100%',
  boxShadow: 24,
  overflowY: 'hidden',
  overflowX: 'hidden',
  '@media(max-width: 1000px)': {
    width: '90%',
    left: '5%',
  },
};

export default function ViewMtr(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <span onClick={async () => {
        handleOpen()
        let status = await props.getMtr(props.item.codigo_manifesto)
        if (!status) handleClose()
      }} className="btn btn-warning btn-outline btn-circle me-1 mb-1"
        data-tip="Ver"><ReactTooltip /><MdVisibility style={{ color: 'white' }} /></span>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {props.state.pdf ?
              <div className="row">
                <div className="col-12 vh-100">
                  <iframe style={{ width: '100%', height: '100%' }} src={props.state.pdf} title='Manifesto'></iframe>
                </div>
              </div>
              :
              <div className='d-flex justify-content-center p-5'><CircularProgress color='success' /></div>}
          </Box>
        </Fade>
      </Modal>
    </>
  );
}