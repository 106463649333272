import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { MdInfo, MdReply } from "react-icons/md";
import { GET } from "../../../../Auxiliar/Requests";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NAME, URLadmin } from "../../../../variables";
import { FaTrash } from "react-icons/fa";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactTooltip from "react-tooltip";

import ConfirmOrganizationModal from "../../../Modais/ConfirmOrganizationModal";

const ConvidarOrganizacao = () => {
  const history = useHistory();
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [useAsDestination, setUseAsDestination] = React.useState(false);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  //* used only to show
  const [data, setData] = React.useState({
    commercial_name: "",
    company_name: "",
    cnpj: "",
    cep: "",
    street: "",
    number: "",
    neighborhood: "",
    complement: "",
    city: "",
    state: "",
  });
  //* used only to send in the request
  const [form, setForm] = React.useState({
    invited_organization_id: "",
    responsible_id: "",
    responsible_name: "",
    responsible_email: "",
    authorized_person: {},
    selected_waste: [],
    selected_rejects: [],
    blueen_destination_id: "",
  });
  const [organizations, setOrganizations] = React.useState([]);
  const [destinations, setDestinations] = React.useState([]);
  const [wasteRejects, setWasteRejects] = React.useState([]);
  const user = useSelector((state) => state.AppReducer.user);

  React.useEffect(() => {
    getRR();
    getData();
  }, []);

  //-------------------------*-------------------------
  const getData = async () => {
    fetch(`${URLadmin}api/auth/admin/index_organizations?connection=${NAME}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    })
      .then(async (responseLog) => {
        try {
          let response = await responseLog.json();
          if (response.response) {
            setOrganizations(response?.organizations);
          } else {
            history.push("/relacionamentos_internos");
          }
        } catch (err) {
          history.push("/relacionamentos_internos");
          console.error(err);
        }
      })
      .catch((err) => {
        history.push("/relacionamentos_internos");
        console.error(err);
      });
  };

  const getRR = async () => {
    const response = await GET({ url: `index_destino` });
    if (response.residuos_rejeitos) {
      setWasteRejects(response.residuos_rejeitos);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleOrganizationChange = (e) => {
    handleInputChange(e);
    fetch(`${URLadmin}api/auth/admin/show_organization/${e.target.value}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }).then(async (responseLog) => {
      try {
        let response = await responseLog.json();
        if (response.response) {
          let { organization } = response;
          setData((prevData) => ({
            ...prevData,
            company_name: organization.company_name,
            commercial_name: organization.commercial_name,
            cnpj: organization.cnpj,
            cep: organization.cep,
            city: organization.city,
            state: organization.state,
            street: organization.street,
            number: organization.number,
            neighborhood: organization.nbhd,
            complement: organization.comp,
          }));
        }
        return;
      } catch (err) {
        console.error(err);
      }
    });
  };

  //* EXISTING DESTINATION */
  const getDestinos = async () => {
    const response = await GET({ url: `index_simple_destinos` });
    if (response.response) {
      setDestinations(response.destinos);
    }
  };

  const handleUseAsChange = (e) => {
    const { checked } = e.target;
    setUseAsDestination(checked);

    if (!checked && form.responsible_id !== "") {
      setForm((prevForm) => ({
        ...prevForm,
        responsible_id: "",
        responsible_name: "",
        responsible_email: "",
        selected_waste: [],
        selected_rejects: [],
        blueen_destination_id: "",
      }));
      return;
    }
    if (checked && destinations.length <= 0) {
      getDestinos();
      return;
    }
  };

  const handleDestinationChange = async (e) => {
    setForm((prevForm) => ({
      ...prevForm,
      blueen_destination_id: e.target.value,
      selected_rejects: [],
      selected_waste: [],
    }));

    const response = await GET({ url: `get_destino/${e.target.value}` });
    if (response.destino) {
      setForm((prevForm) => ({
        ...prevForm,
        responsible_id: response.destino.user_id.id,
        responsible_name: response.destino.user_id.name,
        responsible_email: response.destino.user_id.email,
      }));

      //handling waste rejects
      response.destino.residuos?.map((residue) => {
        addWasteReject({ value: residue.id }, true);
      });
      response.destino.rejeitos?.map((reject) => {
        addWasteReject({ value: reject.id }, false);
      });
    } else {
      console.error("error", response);
    }
  };

  //* WASTE REJECT HANDLER */
  function addWasteReject({ value }, isResidue) {
    // find WR on array
    const selectedValue = wasteRejects.find((wasRej) => wasRej.id === value);

    // array is set based if the value is a waste or a reject
    const selectedWasteRejects = isResidue
      ? [...form.selected_waste]
      : [...form.selected_rejects];

    // add value to array
    selectedWasteRejects.push(selectedValue);

    // remove waste reject from array used on the selector
    const updatedWRList = wasteRejects.filter((item) => item.id !== value);
    setWasteRejects(updatedWRList);

    // set state based if is a waste or a reject
    setForm((prevForm) => ({
      ...prevForm,
      [isResidue ? "selected_waste" : "selected_rejects"]: selectedWasteRejects,
    }));
  }

  function removeWasteReject(id, isResidue) {
    const selectedWasteRejects = isResidue
      ? [...form.selected_waste]
      : [...form.selected_rejects];

    // find index based on 'id'
    const indexToRemove = selectedWasteRejects.findIndex(
      (wasRej) => wasRej.id === id
    );

    //realocating waste reject on selecting
    const material = selectedWasteRejects.find((wasRej) => wasRej.id === id);
    wasteRejects.push(material);
    setWasteRejects(wasteRejects);
    
    if (indexToRemove !== -1) {
      // remove object from array
      selectedWasteRejects.splice(indexToRemove, 1);

      // set state based if is a waste or a reject
      setForm((prevForm) => ({
        ...prevForm,
        [isResidue ? "selected_waste" : "selected_rejects"]:
          selectedWasteRejects,
      }));
    }
  }

  //* HANDLE SUBMIT */
  const sendInvitation = () => {
    let formData = { ...form };
    formData.inviter_connection = NAME;
    formData.authorized_person = {
      id: user.id,
      name: user.name,
      email: user.email,
    };
    //* validating waste rejects
    if (
      formData.selected_waste.length < 1 &&
      formData.selected_rejects.length < 1
    ) {
      setErrorMsg("Selecione pelo menos um resíduo ou rejeito.");
      window.scrollTo(500, 0);
      return;
    }

    setLoadingSave(true);
    fetch(`${URLadmin}api/auth/admin/internal_relationship/invite`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    }).then(async (responseLog) => {
      try {
        let response = await responseLog.json();
        if (response.response && response.data) {
          setLoadingSave(false);
          setShowAlert(true);
        } else {
          if (response.message === "validation_error") {
            setErrorMsg("Preencha os campos corretamente");
          } else {
            setErrorMsg("Erro ao pegar resposta do servidor.");
          }
          setLoadingSave(false);
          window.scrollTo(500, 0);
        }
        return;
      } catch (err) {
        window.scrollTo(500, 0);
        setLoadingSave(false);
        setErrorMsg(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
        console.error(err);
      }
    });
  };

  return (
    <div className="card">
      <div className="card-body" id="card">
        <h4
          className="mt-0 header-title"
          style={{ fontSize: "1.5rem", color: "black" }}
        >
          Cadastro de relacionamento
        </h4>
        <p>
          Convide outra organização para fazer parte do seu ambiente atuando
          como destino.
        </p>
        {errorMsg && (
          <div
            className="alert alert-danger alert-dismissible fade show mb-0"
            role="alert"
          >
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setErrorMsg(null)}
            ></button>

            <p style={{ marginBottom: 0, whiteSpace: "pre" }}>{errorMsg}</p>
          </div>
        )}
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">
            Organização <b style={{ color: "red" }}>*</b>
          </label>
          <div className="input-group">
            <select
              className="form-control"
              name="invited_organization_id"
              value={form.invited_organization_id}
              onChange={(e) => handleOrganizationChange(e)}
            >
              <option value={""}>Selecionar organização</option>
              {organizations.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.commercial_name}
                </option>
              ))}
            </select>
            <ConfirmOrganizationModal
              organization={data}
              disabled={
                !(
                  form.invited_organization_id &&
                  form.invited_organization_id !== ""
                )
              }
            />
          </div>
        </div>
        <div className="row ms-1 pt-2">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              role="switch"
              onChange={handleUseAsChange}
              checked={useAsDestination}
            />
            <label className="form-check-label">
              Usar como destino já existente
            </label>
          </div>
        </div>
        {useAsDestination && (
          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              Destino <b style={{ color: "red" }}>*</b>{" "}
              <span data-tip="As informações do destino serão substituídas pela organização selecionada">
                <MdInfo />
                <ReactTooltip />
              </span>
            </label>
            <div className="col-sm-12">
              <select
                className="form-control"
                name="blueen_destination_id"
                onChange={(e) => handleDestinationChange(e)}
                value={form.blueen_destination_id}
              >
                <option value={""}>Selecionar destino existente</option>
                {destinations?.map((item, index) => (
                  <option value={item?.id} key={index}>
                    {item.razao}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">
            Nome Responsável <b style={{ color: "red" }}>*</b>
          </label>
          <div className="col-sm-12">
            <input
              type="text"
              className="form-control"
              onChange={handleInputChange}
              value={form.responsible_name}
              name="responsible_name"
              placeholder="Fulano da Silva"
              disabled={useAsDestination}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">
            E-mail Responsável <b style={{ color: "red" }}>*</b>
          </label>
          <div className="col-sm-12">
            <input
              type="email"
              className="form-control"
              onChange={handleInputChange}
              value={form.responsible_email}
              name="responsible_email"
              placeholder="email@exemplo.com"
              disabled={useAsDestination}
            />
          </div>
        </div>
        <hr />

        <div className="form-group row">
          <label className="col-sm-12 col-form-label">
            Resíduos <b style={{ color: "red" }}>*</b>
          </label>
          <div className="col-sm-12">
            <select
              value={""}
              className="form-control"
              onChange={(e) => {
                addWasteReject(e.target, true);
              }}
            >
              <option value={""}>
                Selecione os resíduos que serão recebidos
              </option>
              {wasteRejects
                .filter((d) => d.tipo === "Resíduo")
                .map((item, id) => (
                  <option key={id} name={item.nome} value={item.id}>
                    {item.nome}
                  </option>
                ))}
            </select>
          </div>
        </div>
        {Object.values(form.selected_waste).length !== 0 && (
          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              Resíduos Selecionados:
            </label>

            {Object.values(form.selected_waste).map((item, id) => (
              <div
                key={id}
                className="col-sm-6 col-12 col-md-4 col-lg-3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid gray",
                  borderRadius: 10,
                  marginLeft: 5,
                }}
              >
                <span>{item.nome}</span>
                <button
                  className="btn btn-danger btn-outline btn-circle me-1"
                  style={{
                    background: "white",
                    border: "0px solid red",
                  }}
                  onClick={() => {
                    removeWasteReject(item.id, true);
                  }}
                >
                  <FaTrash color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">
            Rejeitos <b style={{ color: "red" }}>*</b>
          </label>
          <div className="col-sm-12">
            <select
              value={""}
              className="form-control"
              onChange={(e) => {
                addWasteReject(e.target, false);
              }}
            >
              <option value={""}>
                Selecione os rejeitos que serão recebidos
              </option>
              {wasteRejects
                .filter((d) => d.tipo === "Rejeito")
                .map((item, id) => (
                  <option key={id} name={item.nome} value={item.id}>
                    {item.nome}
                  </option>
                ))}
            </select>
          </div>
        </div>
        {Object.values(form.selected_rejects).length !== 0 && (
          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              Rejeitos Selecionados:
            </label>

            {Object.values(form.selected_rejects).map((item, id) => (
              <div
                key={id}
                className="col-sm-6 col-12 col-md-4 col-lg-3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid gray",
                  borderRadius: 10,
                  marginLeft: 5,
                }}
              >
                <span>{item.nome}</span>
                <button
                  onClick={() => {
                    removeWasteReject(item.id, false);
                  }}
                  className="btn btn-danger btn-outline btn-circle me-1"
                  style={{
                    background: "white",
                    border: "0px solid red",
                  }}
                >
                  <FaTrash color="red" />
                </button>
              </div>
            ))}
          </div>
        )}
        {!loadingSave ? (
          <div className="row mt-4">
            <div
              className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
              style={{ marginBottom: "0.5rem" }}
            >
              <Link to="/relacionamentos_internos">
                <span className="btn btn-secondary btn-lg waves-effect waves-light">
                  <MdReply /> Voltar
                </span>
              </Link>
            </div>
            <div
              className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
              style={{ marginBottom: "0.5rem" }}
            >
              <button
                type="button"
                onClick={() => sendInvitation()}
                disabled={loadingSave}
                className="btn btn-success btn-lg waves-effect waves-light"
                style={{ backgorundColor: "#f2f2f2" }}
              >
                Convidar pessoa
              </button>
            </div>
          </div>
        ) : (
          <div className="row">
            <div
              className="col-12 d-flex justify-content-center align-items-center"
              style={{ marginTop: "2rem" }}
            >
              <div className="spinner-border text-primary" role="status"></div>
            </div>
          </div>
        )}
      </div>
      {showAlert && (
        <SweetAlert
          success
          title={"Sucesso"}
          onConfirm={() => {
            setShowAlert(false);
            history.push("/relacionamentos_internos");
          }}
          confirmBtnText="OK"
          confirmBtnBsStyle="success"
        >
          Convite enviado com sucesso!
        </SweetAlert>
      )}
    </div>
  );
};

export default ConvidarOrganizacao;
