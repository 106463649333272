
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import UsuarioModal from '../UsuarioModal/UsuarioModal';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import { MdReply } from 'react-icons/md';
import { maskNumber, maskCep, maskCnpj, maskTelefone, validateDecimals } from '../../../Auxiliar/Masks';
import { FaTrash, FaUser } from 'react-icons/fa';
import { NumericFormat } from 'react-number-format';
import './css/style.css';

class EditarPontoColeta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/pontos_coleta',
            loading_screen: false,
            responsaveis: [],
            responsavel_id: '',

            cnpj: '',
            razao_social: '',
            nome_fantasia: '',
            numero_telefone: '',

            numero: '',
            cep: '',
            bairro: '',
            cidade: '',
            estado: '',
            logradouro: '',
            complemento: '',
            dia_semana: {
                dom: false,
                seg: false,
                ter: false,
                qua: false,
                qui: false,
                sex: false,
                sab: false,
            },
            hora_inicio: '',
            hora_fim: '',

            horarios_funcionamento: [],
            novos_horarios: [],
            requiredMsg: '',


            loading_save: false,
            msgErro: '',
            pessoas_selecionadas: {},
            pessoas: {},
            pessoa: '',

            residuos_selecionadas: {},
            residuos: {},
            residuo: '',

            rejeitos_selecionadas: {},
            rejeitos: {},
            rejeito: '',

            capacidade_maxima_peso: '',
            capacidade_maxima_volume: '',
            unidade_peso_id: '',
            unidade_volume_id: '',
            unidades_peso: [],
            unidades_volume: [],
        };
    }

    handleDay(day) {
        let dia_semana = { ...this.state.dia_semana }
        dia_semana[day] = !dia_semana[day]
        this.setState({ dia_semana })
    }

    salvar() {
        let novos_horarios = [...this.state.novos_horarios]
        let horarios_existentes = [...this.state.horarios_funcionamento]
        let funcionamento = []
        novos_horarios.forEach(element => {
            element.dia.forEach(dias => {
                let obj = {
                    dia: dias,
                    hora_inicio: element.hora_inicio,
                    hora_fim: element.hora_fim,
                }
                funcionamento.push(obj)
            });
        });
        horarios_existentes.forEach(element => {
            element.horario_funcionamento.forEach(horarios => {
                let obj2 = {
                    dia: element.dia,
                    hora_inicio: horarios.hora_inicio,
                    hora_fim: horarios.hora_fim,
                }
                funcionamento.push(obj2)
            })
        })

        this.setState({ loading_save: true, msgErro: '' });
        console.log(Object.keys(this.state.pessoas_selecionadas))
        fetch(`${URL}api/update_pontos`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                responsavel_id: this.state.responsavel_id,

                cnpj: this.state.cnpj,
                razao_social: this.state.razao_social,
                nome_fantasia: this.state.nome_fantasia,
                numero_telefone: this.state.numero_telefone,
                responsavel_id: this.state.responsavel_id,
                unidade_peso_id: this.state.unidade_peso_id,
                unidade_volume_id: this.state.unidade_volume_id,
                capacidade_maxima_peso: this.state.capacidade_maxima_peso,
                capacidade_maxima_volume: this.state.capacidade_maxima_volume,

                numero: this.state.numero,

                cep: this.state.cep,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                logradouro: this.state.logradouro,
                complemento: this.state.complemento,
                funcionamento: funcionamento,

                pessoas: Object.values(this.state.pessoas_selecionadas),
                residuos: Object.keys(this.state.residuos_selecionadas),
                rejeitos: Object.keys(this.state.rejeitos_selecionadas),
                ponto_id: this.props.match.params.id,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }




    componentDidMount() {

        this.get_ponto(this.props.match.params.id);
    }

    get_ponto(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_ponto/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);       
                    let residuos_selecionadas = {};
                    for (let i = 0; i < resp.ponto.residuos.length; i++) {
                        residuos_selecionadas[resp.ponto.residuos[i].id] = resp.ponto.residuos[i];
                    }

                    let rejeitos_selecionadas = {};
                    for (let i = 0; i < resp.ponto.rejeitos.length; i++) {
                        rejeitos_selecionadas[resp.ponto.rejeitos[i].id] = resp.ponto.rejeitos[i];
                    }

                    let pessoas_selecionadas = {};
                    for (let i = 0; i < resp.pessoas_selecionadas.length; i++) {
                        pessoas_selecionadas[resp.pessoas_selecionadas[i].user_id] = {
                            id: resp.pessoas_selecionadas[i].user_id,
                            ponto_id: resp.pessoas_selecionadas[i].ponto_id,
                            user_id: resp.pessoas_selecionadas[i].user_id,
                            name: resp.pessoas_selecionadas[i].users.name,
                            notificacoes: resp.pessoas_selecionadas[i].notificacoes
                        };
                    }

                    let pessoas = {};
                    for (let i = 0; i < resp.autorizadas.length; i++) {
                        pessoas[resp.autorizadas[i].id] = resp.autorizadas[i];
                    }

                    let residuos = {};
                    for (let i = 0; i < resp.residuos.length; i++) {
                        residuos[resp.residuos[i].id] = resp.residuos[i];
                    }
                    let rejeitos = {};
                    for (let i = 0; i < resp.rejeitos.length; i++) {
                        rejeitos[resp.rejeitos[i].id] = resp.rejeitos[i];
                    }

                    //troca de autorizadas no backend para usuários com e sem permissão, e responsáveis estou passando os mesmos valores das "autorizadas"

                    this.setState({
                        loading_screen: false,
                        responsaveis: resp.autorizadas,
                        rejeitos: rejeitos,
                        pessoas: resp.autorizadas,
                        residuos: residuos,
                        unidades_peso: resp.unidades_peso,
                        unidades_volume: resp.unidades_volume,


                        responsavel_id: resp.ponto.responsavel_id,

                        cnpj: resp.ponto.cnpj,
                        razao_social: resp.ponto.razao_social,
                        nome_fantasia: resp.ponto.nome_fantasia,
                        numero_telefone: resp.ponto.numero_telefone,
                        unidade_peso_id: resp.ponto.unidade_peso_id,
                        unidade_volume_id: resp.ponto.unidade_volume_id,
                        capacidade_maxima_peso: resp.ponto.capacidade_maxima_peso,
                        capacidade_maxima_volume: resp.ponto.capacidade_maxima_volume,

                        numero: resp.ponto.numero,

                        cep: resp.ponto.cep,
                        bairro: resp.ponto.bairro,
                        cidade: resp.ponto.cidade,
                        estado: resp.ponto.estado,
                        logradouro: resp.ponto.logradouro,
                        complemento: resp.ponto.complemento == null ? '' : resp.ponto.complemento,
                        horarios_funcionamento: resp.ponto.dias,
                        residuos_selecionadas,
                        rejeitos_selecionadas,
                        pessoas_selecionadas
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    buscar_endereco(cep) {
        this.setState({ loading_cep: true });
        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`, {
            method: 'GET',
            headers: {

            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                this.setState({
                    logradouro: resp.logradouro,
                    bairro: resp.bairro,
                    cidade: resp.localidade,
                    estado: resp.uf,
                    numero: '',
                    loading_cep: false

                }, () => {
                    if (this.state.logradouro == '') {
                        document.getElementById('rua').focus();
                    } else {
                        document.getElementById('numero').focus();
                    }
                });

                // await AsyncStorage.setItem('token', resp.access_token)
                // await AsyncStorage.setItem('user', JSON.stringify(resp.user))




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Algo deu errado. Seu CEP está correto?', loading_cep: false });
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: `Erro ao pegar resposta do servidor. Você está conectado a internet?`, loading_cep: false });
            });


    }




    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }


    render() {
        let anos = [];
        for (let i = parseInt(moment().format('YYYY')); i >= 1990; i--) {
            anos.push(i);
        }
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar ponto de coleta</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um ponto de coleta do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}

                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">CNPJ <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="CNPJ" type="text" onChange={(e) => this.setState({ cnpj: maskCnpj(e.target.value) })} value={this.state.cnpj} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Razão social <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Razão social" type="text" onChange={(e) => this.setState({ razao_social: e.target.value })} value={this.state.razao_social} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome fantasia <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Nome fantasia" type="text" onChange={(e) => this.setState({ nome_fantasia: e.target.value })} value={this.state.nome_fantasia} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Número telefone <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Número telefone" type="text" onChange={(e) => this.setState({ numero_telefone: maskTelefone(e.target.value) })} value={this.state.numero_telefone} />
                                            </div>
                                        </div>



                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Responsável <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <div className="input-group">
                                                    {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'form-control','id'=>'faculdade']) }} */}
                                                    <select value={this.state.responsavel_id} className="form-control" id="cameras" onChange={(e) => {
                                                        let [resp_selecionado] = this.state.responsaveis.filter((item) => item.id == e.target.value)
                                                        let responsavel = [];
                                                        if (e.target.value == '') {
                                                            responsavel = [];
                                                        } else {
                                                            console.log(resp_selecionado)
                                                            resp_selecionado.notificacoes = true;
                                                            resp_selecionado.user_id = e.target.value;
                                                            responsavel[e.target.value] = resp_selecionado;
                                                        }
                                                        this.setState({
                                                            pessoas_selecionadas: responsavel,
                                                            responsavel_id: e.target.value
                                                        })
                                                    }}>
                                                        <option value={''}>Selecione um responsável</option>

                                                        {this.state.responsaveis.map((item, id) => (
                                                            <option key={id} value={item.id}>{item.name}</option>
                                                        ))}

                                                    </select>
                                                    <UsuarioModal reload={(e) => this.get_ponto(e)} id={this.props.match.params.id} />
                                                </div>
                                            </div>
                                        </div>

                                        <hr />
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">CEP <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="CEP" value={this.state.cep} type="text" onChange={(e) => {
                                                    this.setState({ cep: maskCep(e.target.value) });
                                                    let text = e.target.value.replace('-', '');
                                                    if (text.length == 8) {
                                                        this.buscar_endereco(text);
                                                    }
                                                }} />
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-sm-12 col-form-label">Logradouro <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" id="rua" placeholder="Logradouro do endereço" type="text" onChange={(e) => this.setState({ logradouro: e.target.value })} value={this.state.logradouro} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 col-12">
                                                        <label className="col-sm-12 col-form-label">Número <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" id="numero" placeholder="Número do endereço" type="text" onChange={(e) => this.setState({ numero: e.target.value })} value={this.state.numero} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-1 col-12" >
                                                        <label className="col-form-label col-sm-12" htmlFor="select-all"  >S/N</label>
                                                        <div className="form-check form-switch col-sm-12 " >
                                                            <input className="form-check-input" checked={this.state.numero == 'S/N' ? true : false} type="checkbox" id={'select-all'} onChange={(e) => { this.setState({ numero: e.target.checked ? 'S/N' : '' }) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-sm-12 col-form-label">Bairro <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Nome do bairro" type="text" onChange={(e) => this.setState({ bairro: e.target.value })} value={this.state.bairro} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-sm-12 col-form-label">Complemento</label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Complemento" type="text" onChange={(e) => this.setState({ complemento: e.target.value })} value={this.state.complemento} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-10 col-12">
                                                        <label className="col-sm-12 col-form-label">Cidade <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" disabled placeholder="Nome da cidade" type="text" onChange={(e) => this.setState({ cidade: e.target.value })} value={this.state.cidade} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 col-12">
                                                        <label className="col-sm-12 col-form-label">Estado<b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" disabled placeholder="UF" type="text" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="form-group row mt-3">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-lg-6 col-12">
                                                        <label className="col-sm-12 col-form-label">Dias de funcionamento <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <div className='d-flex justify-content-evenly align-items-center' id='dayselector'>
                                                                {Object.keys(this.state.dia_semana).map((item, id) =>
                                                                    <>
                                                                        {(this.state.dia_semana[item]) == false &&
                                                                            <div key={id} onClick={() => this.handleDay(item)}>{item.substring(0, 1).toUpperCase()}</div>
                                                                        }
                                                                        {(this.state.dia_semana[item]) == true &&
                                                                            <div className='selectedDay' key={id} onClick={() => this.handleDay(item)}>{item.substring(0, 1).toUpperCase()}</div>
                                                                        }
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-6">
                                                        <label className="col-12 col-form-label">Hora Início<b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Hora Início" type="time" onChange={(e) => {
                                                                this.setState({ hora_inicio: e.target.value })
                                                            }}
                                                                value={this.state.hora_inicio} />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-3 col-sm-6">
                                                        <label className="col-12 col-form-label">Hora Fim<b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Hora Fim" type="time" onChange={(e) => {
                                                                this.setState({ hora_fim: e.target.value })
                                                            }}
                                                                value={this.state.hora_fim} />
                                                        </div>
                                                    </div>
                                                    <div className="d-grid gap-2 d-md-flex justify-content-md-between mt-3">
                                                        {this.state.requiredMsg != '' && <b className='mt-2' style={{ color: 'red', fontSize: '12px' }}>{this.state.requiredMsg}</b>}
                                                        <div></div>
                                                        <button type="button" className="btn btn-success" onClick={() => {
                                                            if (this.state.hora_inicio == '' || this.state.hora_fim == '') {
                                                                this.setState({ requiredMsg: 'Preencha todos os campos' })
                                                                return;
                                                            }
                                                            if (this.state.hora_inicio >= this.state.hora_fim) {
                                                                this.setState({ requiredMsg: 'Horários conflitantes' })
                                                                return;
                                                            }
                                                            if (this.state.dia_semana.dom == false &&
                                                                this.state.dia_semana.seg == false &&
                                                                this.state.dia_semana.ter == false &&
                                                                this.state.dia_semana.qua == false &&
                                                                this.state.dia_semana.qui == false &&
                                                                this.state.dia_semana.sex == false &&
                                                                this.state.dia_semana.sab == false
                                                            ) {
                                                                this.setState({ requiredMsg: 'Escolha pelo menos um dia da semana' })
                                                            }
                                                            else {
                                                                let novos_horarios = [...this.state.novos_horarios]
                                                                let obj = {
                                                                    dia: Object.values(this.state.dia_semana).map((item, id) => {
                                                                        if (item == true) {
                                                                            return Object.keys(this.state.dia_semana)[id]
                                                                        }
                                                                    }).filter((item, id) => item != null && item != undefined),
                                                                    hora_inicio: this.state.hora_inicio,
                                                                    hora_fim: this.state.hora_fim
                                                                }

                                                                let requiredMsg = '';
                                                                for (let i = 0; i < this.state.novos_horarios.length; i++) {
                                                                    for (let j = 0; j < this.state.novos_horarios[i].dia.length; j++) {
                                                                        Object.values(obj.dia).map((item2, id2) => {
                                                                            if (this.state.novos_horarios[i].dia[j] == item2) {
                                                                                if (this.state.novos_horarios[i].hora_fim >= obj.hora_inicio && obj.hora_inicio >= this.state.novos_horarios[i].hora_inicio ||
                                                                                    this.state.novos_horarios[i].hora_fim >= obj.hora_fim && obj.hora_fim >= this.state.novos_horarios[i].hora_inicio ||
                                                                                    this.state.novos_horarios[i].hora_fim <= obj.hora_fim && obj.hora_inicio <= this.state.novos_horarios[i].hora_inicio
                                                                                ) { requiredMsg = "Horários conflitantes"; }
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                                for (let i = 0; i < this.state.horarios_funcionamento.length; i++) {
                                                                    for (let j = 0; j < this.state.horarios_funcionamento[i].horario_funcionamento.length; j++) {
                                                                        Object.values(obj.dia).map((item2, id2) => {
                                                                            if (this.state.horarios_funcionamento[i].dia == item2) {
                                                                                if (this.state.horarios_funcionamento[i].horario_funcionamento[j].hora_fim >= obj.hora_inicio && obj.hora_inicio >= this.state.horarios_funcionamento[i].horario_funcionamento[j].hora_inicio ||
                                                                                    this.state.horarios_funcionamento[i].horario_funcionamento[j].hora_fim >= obj.hora_fim && obj.hora_fim >= this.state.horarios_funcionamento[i].horario_funcionamento[j].hora_inicio ||
                                                                                    this.state.horarios_funcionamento[i].horario_funcionamento[j].hora_fim <= obj.hora_fim && obj.hora_inicio <= this.state.horarios_funcionamento[i].horario_funcionamento[j].hora_inicio
                                                                                ) { requiredMsg = "Horários conflitantes"; }
                                                                            }
                                                                        })
                                                                    }
                                                                }

                                                                if (requiredMsg === '') {
                                                                    novos_horarios.push(obj)
                                                                    this.setState({ novos_horarios, requiredMsg: '', hora_inicio: '', hora_fim: '' })
                                                                } else {
                                                                    this.setState({ requiredMsg: "Horários conflitantes" })
                                                                }
                                                            }
                                                        }}>
                                                            Inserir
                                                        </button>
                                                    </div>
                                                    {this.state.novos_horarios.length > 0 && <div className='form-group row'>
                                                        <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Novos Horários de Funcionamento:</label>
                                                        <table id="tech-companies-1" className='table table-striped table-hover text-center'>
                                                            <thead>
                                                                <tr>
                                                                    <th>Dias</th>
                                                                    <th>Hora Início</th>
                                                                    <th>Hora Fim</th>

                                                                    <th data-priority="3">Excluir</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.novos_horarios.map((item, id) =>
                                                                    <tr key={id}>
                                                                        <td className='capitalized'>{item.dia.map((item2, id2) => (id2 == 0 ? '' : ' - ') + item2)}</td>
                                                                        <td>{item.hora_inicio}</td>
                                                                        <td>{item.hora_fim}</td>
                                                                        <td>
                                                                            <button onClick={() => {
                                                                                let novos_horarios = [...this.state.novos_horarios]
                                                                                novos_horarios = novos_horarios.filter((item2, id2) => id2 != id);
                                                                                this.setState({ novos_horarios })
                                                                            }}
                                                                                className="btn btn-danger btn-outline btn-circle me-1" style={{ background: 'white', border: '0px solid red' }}>
                                                                                <FaTrash color='red' />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    }
                                                    {this.state.horarios_funcionamento.length > 0 &&
                                                        <div className="form-group row">
                                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Horários de Funcionamento:</label>

                                                            <table id="tech-companies-1" className="table table-striped table-hover text-center" >
                                                                <thead>
                                                                    <tr>
                                                                        <th>Dias</th>
                                                                        <th>Horarios de Funcionamento</th>

                                                                        <th data-priority="3">Excluir</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {this.state.horarios_funcionamento.map((item, id) =>
                                                                        <tr key={id}>
                                                                            <td className='capitalized'>{item.dia}</td>
                                                                            <td>{item.horario_funcionamento.map((item2, id2) => (id2 == 0 ? '' : ' | ') + item2.hora_inicio + ' - ' + item2.hora_fim)}</td>

                                                                            <td>
                                                                                <button onClick={() => {
                                                                                    let horarios_funcionamento = [...this.state.horarios_funcionamento]
                                                                                    horarios_funcionamento = horarios_funcionamento.filter((item2, id2) => id2 != id);
                                                                                    this.setState({ horarios_funcionamento })
                                                                                }}
                                                                                    className="btn btn-danger btn-outline btn-circle me-1" style={{ background: 'white', border: '0px solid red' }}>
                                                                                    <FaTrash color='red' />
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>

                                                            </table>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Pessoas autorizadas <b style={{ color: 'red' }}>*</b></label>

                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'form-control','id'=>'faculdade']) }} */}
                                                <div className="input-group">
                                                    <select value={this.state.pessoa} className="form-control" id="pessoas" onChange={(event) => {
                                                        let pessoas_selecionadas = { ...this.state.pessoas_selecionadas };
                                                        if (pessoas_selecionadas[event.target.value] === undefined) {
                                                            pessoas_selecionadas[event.target.value] = this.state.pessoas.filter(item => item.id === event.target.value)[0];
                                                        }
                                                        console.log(pessoas_selecionadas);
                                                        pessoas_selecionadas[event.target.value].notificacoes = true;
                                                        pessoas_selecionadas[event.target.value].user_id = event.target.value;
                                                        this.setState({ pessoas_selecionadas: pessoas_selecionadas })
                                                    }}>
                                                        <option value={''}>Selecione uma ou mais pessoas autorizadas</option>

                                                        {Object.values(this.state.pessoas).filter(item1 => item1.id !== this.state.responsavel_id).map((item, id) => (
                                                            <option key={id} value={item.id}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                    <UsuarioModal reload={(e) => this.get_data(e)} />
                                                </div>
                                            </div>
                                        </div>


                                        {Object.values(this.state.pessoas_selecionadas).length != 0 && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Pessoas autorizadas selecionadas:</label>

                                            {Object.values(this.state.pessoas_selecionadas).map((item, id) => (
                                                <div key={id} className="col-sm-6 col-12 col-md-4 col-lg-3" style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    border: '1px solid gray',
                                                    borderRadius: 10,
                                                    marginLeft: 10,
                                                    marginTop: 10
                                                }}>
                                                    <div>
                                                        <span>{item.name}</span>

                                                        <div className=" d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="notificacao" style={{ marginRight: 10 }}>Notificações</label>
                                                            <div className="form-check form-switch ">
                                                                <input className="form-check-input" type="checkbox" id="notificacao" checked={item.notificacoes} onChange={(e) => {
                                                                    console.log(item)
                                                                    let pessoas_selecionadas = { ...this.state.pessoas_selecionadas };
                                                                    pessoas_selecionadas[item.id].notificacoes = e.target.checked;
                                                                    console.log(pessoas_selecionadas)
                                                                    this.setState({ pessoas_selecionadas: pessoas_selecionadas });
                                                                }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {item.id == this.state.responsavel_id &&
                                                        <span className=" btn-danger btn-outline btn-circle me-1" style={{ background: 'white' }}><FaUser color='green' /></span>
                                                    }
                                                    {item.id != this.state.responsavel_id && <button onClick={() => {
                                                        let pessoas_selecionadas = { ...this.state.pessoas_selecionadas };
                                                        delete (pessoas_selecionadas[item.id]);
                                                        this.setState({ pessoas_selecionadas: pessoas_selecionadas });
                                                    }}
                                                        className="btn btn-danger btn-outline btn-circle me-1" style={{ background: 'white', border: '0px solid red' }}><FaTrash color='red' /></button>}
                                                </div>
                                            ))}

                                        </div>}

                                        <hr />
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Resíduos </label>

                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'form-control','id'=>'faculdade']) }} */}
                                                <select value={this.state.residuo} className="form-control" id="residuos" onChange={(event) => {
                                                    let residuos_selecionadas = { ...this.state.residuos_selecionadas };
                                                    if (residuos_selecionadas[event.target.value] == undefined) {
                                                        residuos_selecionadas[event.target.value] = this.state.residuos[event.target.value];
                                                    }
                                                    this.setState({ residuos_selecionadas: residuos_selecionadas })
                                                }}>
                                                    <option value={''}>Selecione uma ou mais resíduos</option>

                                                    {Object.values(this.state.residuos).map((item, id) => (
                                                        <option key={id} value={item.id}>{item.nome}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>


                                        {Object.values(this.state.residuos_selecionadas).length != 0 && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Resíduos selecionadas:</label>

                                            {Object.values(this.state.residuos_selecionadas).map((item, id) => (
                                                <div key={id} className="col-sm-6 col-12 col-md-4 col-lg-3" style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    border: '1px solid gray',
                                                    borderRadius: 10,
                                                    marginLeft: 10,
                                                    marginTop: 10
                                                }}>
                                                    <span>{item.nome}</span>
                                                    <button onClick={() => {
                                                        let residuos_selecionadas = { ...this.state.residuos_selecionadas };
                                                        delete (residuos_selecionadas[item.id]);
                                                        this.setState({ residuos_selecionadas: residuos_selecionadas });
                                                    }}
                                                        className="btn btn-danger btn-outline btn-circle me-1" style={{ background: 'white', border: '0px solid red' }}><FaTrash color='red' /></button>
                                                </div>
                                            ))}

                                        </div>}


                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Rejeitos </label>

                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'form-control','id'=>'faculdade']) }} */}
                                                <select value={this.state.rejeito} className="form-control" id="residuos" onChange={(event) => {
                                                    let rejeitos_selecionadas = { ...this.state.rejeitos_selecionadas };
                                                    if (rejeitos_selecionadas[event.target.value] == undefined) {
                                                        rejeitos_selecionadas[event.target.value] = this.state.rejeitos[event.target.value];
                                                    }
                                                    this.setState({ rejeitos_selecionadas: rejeitos_selecionadas })
                                                }}>
                                                    <option value={''}>Selecione uma ou mais rejeitos</option>

                                                    {Object.values(this.state.rejeitos).map((item, id) => (
                                                        <option key={id} value={item.id}>{item.nome}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>


                                        {Object.values(this.state.rejeitos_selecionadas).length != 0 && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Rejeitos selecionadas:</label>

                                            {Object.values(this.state.rejeitos_selecionadas).map((item, id) => (
                                                <div key={id} className="col-sm-6 col-12 col-md-4 col-lg-3" style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    border: '1px solid gray',
                                                    borderRadius: 10,
                                                    marginLeft: 10,
                                                    marginTop: 10
                                                }}>
                                                    <span>{item.nome}</span>
                                                    <button onClick={() => {
                                                        let rejeitos_selecionadas = { ...this.state.rejeitos_selecionadas };
                                                        delete (rejeitos_selecionadas[item.id]);
                                                        this.setState({ rejeitos_selecionadas: rejeitos_selecionadas });
                                                    }}
                                                        className="btn btn-danger btn-outline btn-circle me-1" style={{ background: 'white', border: '0px solid red' }}><FaTrash color='red' /></button>
                                                </div>
                                            ))}

                                        </div>}
                                        <hr />

                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <label className="col-form-label">Valor da capacidade máxima de peso <b style={{ color: 'red' }}>*</b></label>
                                                        <NumericFormat type='text' className="form-control"
                                                            valueIsNumericString
                                                            thousandsGroupStyle='thousand'
                                                            thousandSeparator="."
                                                            decimalScale={2}
                                                            decimalSeparator=","
                                                            placeholder={'Valor'}
                                                            onValueChange={(values, sourceInfo) => {
                                                                this.setState({ capacidade_maxima_peso: values.value })
                                                            }}
                                                            value={this.state.capacidade_maxima_peso} />
                                                    </div>

                                                    <div className="col-md-6 col-12 ">

                                                        <label className="col-form-label">Unidade da capacidade máxima de peso <b style={{ color: 'red' }}>*</b></label>

                                                        {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                        <select value={this.state.unidade_peso_id} className="form-control" id="cameras" onChange={(e) => {
                                                            this.setState({ unidade_peso_id: e.target.value })
                                                        }}>
                                                            <option value={''}>Selecione uma unidade de peso</option>
                                                            {this.state.unidades_peso.map((item, id) => (
                                                                <option key={id} value={item.id}>{item.nome} - {item.sigla}</option>
                                                            ))}


                                                        </select>
                                                    </div>

                                                </div>

                                            </div>




                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <label className="col-form-label">Valor da capacidade máxima de volume <b style={{ color: 'red' }}>*</b></label>
                                                        <NumericFormat type='text' className="form-control"
                                                            valueIsNumericString
                                                            thousandsGroupStyle='thousand'
                                                            thousandSeparator="."
                                                            decimalScale={2}
                                                            decimalSeparator=","
                                                            placeholder={'Valor'}
                                                            onValueChange={(values, sourceInfo) => {
                                                                this.setState({ capacidade_maxima_volume: values.value })
                                                            }}
                                                            value={this.state.capacidade_maxima_volume} />
                                                    </div>

                                                    <div className="col-md-6 col-12 ">

                                                        <label className="col-form-label">Unidade da capacidade máxima de volume <b style={{ color: 'red' }}>*</b></label>

                                                        {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                        <select value={this.state.unidade_volume_id} className="form-control" id="cameras" onChange={(e) => {
                                                            this.setState({ unidade_volume_id: e.target.value })
                                                        }}>
                                                            <option value={''}>Selecione uma unidade de volume</option>
                                                            {this.state.unidades_volume.map((item, id) => (
                                                                <option key={id} value={item.id}>{item.nome} - {item.sigla}</option>
                                                            ))}


                                                        </select>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        {this.state.loading_save == false && <div className="row mt-3">
                                            <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                                style={{ marginBottom: '0.5rem' }}>
                                                <Link to="/pontos_coleta"> <span
                                                    className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                            </div>
                                            <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                                style={{ marginBottom: '0.5rem' }}>
                                                <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                                    style={{ backgorundColor: '#f2f2f2' }}>Salvar ponto de coleta</button>
                                            </div>
                                        </div>}
                                    </div>
                                </div>

                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(EditarPontoColeta);


