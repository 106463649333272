
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import { URL } from '../../../variables';
import { maskNumberBr, maskPrice } from '../../../Auxiliar/Masks';

import { MdReply } from 'react-icons/md';


class ViewDespacho extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/acompanhar_etapas',
            loading_screen: false,
            ResiduosRejeitos: [],
            Estoques: [],
            EstoqueSelecionado: [],
            Solicitacoes: [],
            solicitacoes_selecionadas: [],
            MateriaisSolicitacao: [],
            Destinacoes: [],
            Tratamentos: [],
            MateriaisInseridos: [],
            Transportadoras: [],
            Veiculos: [],
            Motoristas: [],

            Materialselecionado: {},
            SolicitacaoSelecionada: {},

            TransportadoraNome: '',
            Estoque: '',
            Motorista: '',
            Veiculo: '',
            TransportadoraId: '',
            DestinacaoId: '',
            Situacao: 'Rascunho',
            Descricao: '',
            Tratamento: '',
            ValorFinanceiro: '',
            Movimentacao: '',
            Peso: '',
            Volume: '',
            MaterialSolicitacao: '',
            Solicitacao: '',
            DataAtual: '',
            DataSolicitacao: '',
            DataAgendamento: '',
            DataInicio: '',
            DataFinalizado: '',
            DataCriacao: '',
            PesoTotal: '0',
            VolumeTotal: '0',
            PesoTotalVeiculo: '0',
            VolumeTotalVeiculo: '0',
            ReceitaTotal: '0.00',
            DespesaTotal: '0.00',
            Saldo: '0.00',
            MotoristaId: '',
            VeiculoId: '',
            loading_save: false,
            msgErro: '',
            show_warning: false,
            veiculos_motoristas: [],
            despacho: {},
            loading_rota_despacho: false
        };
    }

    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/update_despacho_iniciado`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                despacho_id: this.props.match.params.id,
                situacao: this.state.Situacao,
                data_agendamento: this.state.DataAgendamento,
                data_inicio: this.state.DataInicio,

            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);
                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }

            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });

    }


    gerar_rota() {
        this.setState({ loading_rota_despacho: true });
        fetch(`${URL}api/gerar_rota_despacho/${this.props.match.params.id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp)
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    this.timeout = setTimeout(() => {
                        this.gerar_rota();
                    }, 5000);
                }
                else {

                    console.log(resp.despacho)
                    this.setState({ loading_rota_despacho: false, despacho: resp.despacho });

                }

            } catch (err) {
                console.log(err);
                this.timeout = setTimeout(() => {
                    this.gerar_rota();
                }, 5000);
            }

        })
            .catch((err) => {
                console.log(err);
                this.timeout = setTimeout(() => {
                    this.gerar_rota();
                }, 5000);
            });

    }


    get_data(id) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_editar_despacho/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    let PesoTotalVeiculo = 0;
                    let VolumeTotalVeiculo = 0;

                    for (let i = 0; i < resp.veiculos_despacho.length; i++) {
                        let veiculo = resp.veiculos_despacho[i];
                        PesoTotalVeiculo = parseFloat(PesoTotalVeiculo) + (parseFloat(veiculo.capacidade_maxima_peso) * parseFloat(veiculo.unidade_peso.equivalencia));
                        VolumeTotalVeiculo = parseFloat(VolumeTotalVeiculo) + (parseFloat(veiculo.capacidade_maxima_volume) * parseFloat(veiculo.unidade_volume.equivalencia));

                    }

                    resp.despacho.materiais_coletados = resp.despacho.materiais.map((item, id) => {
                        let material = resp.despacho.materiais_coletados.filter((item2, id2) => item2.residuo_rejeito_id == item.residuo_rejeito_id && item.solicitacao_id == item2.solicitacao_id);
                        let obj = {}
                        if (material.length > 0) {
                            obj = { ...material[0] };
                        }
                        else {
                            obj = { ...item };
                        }
                        obj.status = material.length == 0 ? 'Proveniente da solicitação' : (material[0].peso == item.peso ? 'Confirmado pelo motorista' : 'Alterado pelo motorista');


                        return obj;
                    })

                    let PesoTotal = resp.despacho.materiais_coletados.reduce((total, item2) => total + item2.peso_kg, 0) || 0;
                    let VolumeTotal = resp.despacho.materiais_coletados.reduce((total, item2) => total + item2.volume_lt, 0) || 0;
                    let ReceitaTotal = resp.despacho.materiais_coletados.reduce((total, item2) => {
                        if (item2.movimentacao === 'Receita') {
                            return total + parseFloat(item2.residuo_rejeito.valor_saldo_financeiro) * item2.peso
                        } else {
                            return total
                        }
                    }, 0)

                    let DespesaTotal = resp.despacho.materiais_coletados.reduce((total, item2) => {
                        if (item2.movimentacao === 'Despesa') {
                            return total + parseFloat(item2.residuo_rejeito.valor_saldo_financeiro) * item2.peso
                        } else {
                            return total
                        }
                    }, 0)

                    this.setState({
                        loading_screen: false,
                        Estoques: resp.estoques,
                        Solicitacoes: resp.solicitacoes,
                        Destinacoes: resp.destinacoes,
                        ResiduosRejeitos: resp.materiais,
                        Tratamentos: resp.tratamentos,
                        Transportadoras: resp.transportadoras,
                        Veiculos: resp.veiculos,
                        Motoristas: resp.motoristas,
                        PesoTotalVeiculo,
                        VolumeTotalVeiculo,
                        TransportadoraId: resp.despacho.transportadora == null ? '' : resp.despacho.transportadora_id,
                        TransportadoraNome: resp.despacho.transportadora == null ? 'Sem transportadora' : resp.despacho.transportadora.nome_fantasia,
                        Situacao: resp.despacho.situacao,
                        Descricao: resp.despacho.descricao != null ? resp.despacho.descricao : '',
                        Solicitacao: resp.despacho.solicitacao_id,
                        Estoque: resp.despacho.estoque,
                        EstoqueNome: resp.despacho.estoque.nome_fantasia,
                        DataSolicitacao: resp.despacho.data_solicitacao,
                        DataAgendamento: resp.despacho.data_agendamento != null ? resp.despacho.data_agendamento : '',
                        DataInicio: resp.despacho.data_inicio != null ? resp.despacho.data_inicio : '',
                        // PesoTotal: resp.despacho.peso_total,
                        // VolumeTotal: resp.despacho.volume_total,
                        // ReceitaTotal: resp.despacho.receita_total,
                        // DespesaTotal: resp.despacho.despesa_total,
                        // Saldo: resp.despacho.saldo,
                        PesoTotal, VolumeTotal,
                        ReceitaTotal: parseFloat(ReceitaTotal).toFixed(2),
                        DespesaTotal: parseFloat(DespesaTotal).toFixed(2),
                        Saldo: (ReceitaTotal - DespesaTotal).toFixed(2),

                        MotoristaId: resp.despacho.motorista_id,
                        VeiculoId: resp.despacho.veiculo_id,
                        // MotoristaNome: resp.despacho.motorista.nome,
                        // VeiculoNome: resp.despacho.veiculo.modelo.nome,
                        veiculos_motoristas: resp.despacho.veiculos_motoristas,
                        MateriaisInseridos: resp.despacho.materiais,
                        // SolicitacaoSelecionada: [resp.despacho.solicitacao_coleta],
                        solicitacoes_selecionadas: resp.solicitacoes_selecionadas,
                        EstoqueSelecionado: resp.despacho.estoque,
                        DataCriacao: resp.despacho.data_criacao,
                        despacho: resp.despacho
                    });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        }).catch((err) => {
            this.setState({ redirect: true });

            console.log(err);
        });
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }



    componentDidMount() {

        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        this.setState({
            DataAtual: ano + '-' + mes + '-' + dia,
            DataSolicitacao: ano + '-' + mes + '-' + dia
        })
        this.get_data(this.props.match.params.id);
    }



    render() {
        console.log('teste caiu')

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Despacho</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Dados do despacho</p>
                            <br />

                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Estoque destino: <span style={{ fontWeight: 'normal' }}>{this.state.EstoqueNome}</span></label>
                                        </div>

                                        <hr />

                                        {<div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Dados das solicitações em aberto:</label>

                                            <table id="tech-companies-1" className="table table-striped table-hover" >
                                                <thead>
                                                    <tr>
                                                        <th>Data da solicitação</th>
                                                        <th>Ponto de coleta</th>
                                                        <th>Responsável</th>
                                                        <th>Código da solicitação</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.solicitacoes_selecionadas.length == 0 && <tr>
                                                        <td colSpan="5" style={{ textAlign: 'center' }}>Nenhuma solicitação selecionada</td>
                                                    </tr>}
                                                    {this.state.solicitacoes_selecionadas.map((item, id) => (
                                                        <tr key={id}>
                                                            <td>{item.data.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')}</td>
                                                            <td>{item.ponto_coleta.nome_fantasia}</td>

                                                            <td>{item.responsavel.name}</td>
                                                            <td>{item.codigo_solicitacao}</td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>}

                                        <hr />

                                        {this.state.MateriaisInseridos.length > 0 &&
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Materiais selecionados:</label>

                                                <table id="tech-companies-1" className="table table-striped table-hover" >
                                                    <thead>
                                                        <tr>
                                                            <th>Material</th>
                                                            <th>Peso</th>
                                                            <th>Volume</th>
                                                            <th>Ponto Coleta</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.MateriaisInseridos.map((item) => (
                                                            <tr key={item.residuo_rejeito_id + '_' + item.solicitacao_id}>
                                                                <td>{item.nome}</td>
                                                                <td>{maskNumberBr(item.peso + '')} {item.tipo_unidade_principal == undefined ?
                                                                    item.residuo_rejeito.unidade_principal.tipo === 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla :
                                                                    item.tipo_unidade_principal === 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td>
                                                                <td>{maskNumberBr(item.volume + '')} {item.tipo_unidade_principal == undefined ?
                                                                    item.residuo_rejeito.unidade_principal.tipo !== 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla :
                                                                    item.tipo_unidade_principal !== 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td>
                                                                <td>{item.ponto_name}</td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>}

                                        {this.state.despacho?.materiais_coletados?.length > 0 &&
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Materiais coletados:</label>

                                                <table id="tech-companies-1" className="table table-striped table-hover" >
                                                    <thead>
                                                        <tr>
                                                            <th>Material</th>
                                                            <th>Peso</th>
                                                            <th>Volume</th>
                                                            <th>Ponto Coleta</th>
                                                            <th>Status</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.despacho.materiais_coletados.map((item) => (
                                                            <tr key={item.residuo_rejeito_id + '_' + item.solicitacao_id}>
                                                                <td>{item.nome}</td>
                                                                <td>{maskNumberBr(item.peso + '')} {item.tipo_unidade_principal == undefined ?
                                                                    item.residuo_rejeito.unidade_principal.tipo === 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla :
                                                                    item.tipo_unidade_principal === 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td>
                                                                <td>{maskNumberBr(item.volume + '')} {item.tipo_unidade_principal == undefined ?
                                                                    item.residuo_rejeito.unidade_principal.tipo !== 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla :
                                                                    item.tipo_unidade_principal !== 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td>
                                                                <td>{item.ponto_name}</td>
                                                                <td>{item.status}</td>


                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>}

                                        <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados do despacho:</label>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Situação: </label>{this.state.Situacao}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"> <label className="col-sm-4 col-form-label">Data da criação: </label>{this.state.DataCriacao.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Agendado para: </label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.DataAgendamento} type="date" style={{ maxWidth: 168 }} min={this.state.DataCriacao} onChange={(e) => {
                                                    if (e.target.value != '' && this.state.DataInicio == '' && e.target.value < this.state.DataCriacao) {
                                                        this.setState({ Situacao: 'Agendado', DataAgendamento: this.state.DataAtual })
                                                    }
                                                    else if (e.target.value == '' && this.state.DataInicio == '') {
                                                        this.setState({ Situacao: 'Rascunho', DataAgendamento: e.target.value })
                                                    }
                                                    else if (e.target.value != '' && this.state.DataInicio != '' && e.target.value < this.state.DataCriacao) {
                                                        this.setState({ Situacao: 'Iniciado', DataAgendamento: this.state.DataAtual })
                                                    }
                                                    else if (e.target.value != '' && this.state.DataInicio != '' && e.target.value >= this.state.DataCriacao) {
                                                        this.setState({ Situacao: 'Iniciado', DataAgendamento: e.target.value })
                                                    }
                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Iniciado em:</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.DataInicio} type="date" style={{ maxWidth: 168 }} disabled={this.state.DataInicio != '' ? true : false} max={this.state.DataAtual} onChange={(e) => {
                                                    if (e.target.value != '') {
                                                        this.setState({ Situacao: 'Iniciado' })
                                                    }
                                                    if (e.target.value == '' && this.state.DataAgendamento == '') {
                                                        this.setState({ Situacao: 'Rascunho' })
                                                    }
                                                    if (e.target.value != '' && this.state.DataAgendamento != '') {
                                                        this.setState({ Situacao: 'Agendado' })
                                                    }
                                                    this.setState({ DataInicio: e.target.value })
                                                }} />
                                            </div>
                                        </div>


                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Peso total: </label>{maskNumberBr(this.state.PesoTotal)} Kg</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Volume total: </label>{maskNumberBr(this.state.VolumeTotal)} L</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Receita: </label> {maskPrice(this.state.ReceitaTotal)}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Despesas: </label> {maskPrice(this.state.DespesaTotal)}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Saldo: </label> R$ {this.state.Saldo.replace('.', ',')}</div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Transportadora: <span style={{ fontWeight: 'normal' }}>{this.state.TransportadoraNome}</span></label>
                                        </div>
                                        {/* <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Veículo:  <span style={{ fontWeight: 'normal' }}>{this.state.VeiculoNome}</span></label>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Motorista:  <span style={{ fontWeight: 'normal' }}>{this.state.MotoristaNome}</span></label>
                                        </div> */}
                                        {<div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Veículos/Motoristas selecionados:</label>

                                            <table id="tech-companies-1" className="table table-striped table-hover" >
                                                <thead>
                                                    <tr>
                                                        <th>Veículo</th>
                                                        <th>Motorista</th>
                                                        <th>Capacidade Peso</th>
                                                        <th>Capacidade Volume</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.veiculos_motoristas.length == 0 && <tr>
                                                        <td colSpan="5" style={{ textAlign: 'center' }}>Nenhum Veículo/Motorista registrado</td>
                                                    </tr>}
                                                    {this.state.veiculos_motoristas.map((item, id) => (
                                                        <tr key={id}>
                                                            <td>{item.veiculo.modelo.nome}</td>
                                                            <td>{item.motorista.nome}</td>

                                                            <td>{maskNumberBr(item.veiculo.capacidade_maxima_peso)}  {item.veiculo.unidade_peso.sigla}</td>
                                                            <td>{maskNumberBr(item.veiculo.capacidade_maxima_volume)}  {item.veiculo.unidade_volume.sigla}</td>

                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>}
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Peso total Veículo(s): </label>{maskNumberBr(parseFloat(this.state.PesoTotalVeiculo).toFixed(0))} Kg</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Volume total Veículo(s): </label>{maskNumberBr(parseFloat(this.state.VolumeTotalVeiculo).toFixed(0))} L</div>
                                        </div>
                                        {this.state.Descricao != '' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Observações:  <span style={{ fontWeight: 'normal' }}>{this.state.Descricao}</span> </label>
                                        </div>}


                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/acompanhar_etapas"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end" style={{ marginBottom: '0.5rem' }}>
                                        {this.state.despacho.rotas_path != null && <a target="_blank" href={URL + 'storage/' + this.state.despacho.rotas_path} className="btn btn-success btn-lg waves-effect waves-light justify-content-sm-end"
                                            style={{ marginLeft: '5px' }}>Visualizar Rota </a>}
                                        {this.state.loading_rota_despacho == false && this.state.despacho.rotas_path == null && <button onClick={() => this.gerar_rota()} className="btn btn-success btn-lg waves-effect waves-light justify-content-sm-end"
                                            style={{ marginLeft: '5px' }}>Gerar Rota </button>}
                                        {this.state.loading_rota_despacho == true && <div className="spinner-border text-primary" role="status"  >
                                        </div>}
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-info btn-lg waves-effect waves-light justify-content-sm-end"
                                            style={{ marginLeft: '5px' }}>Salvar </button>
                                    </div>

                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}
                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}

                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(ViewDespacho);


