import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import UsuarioModal from '../UsuarioModal/UsuarioModal';
import { logout } from '../../../actions/AppActions';

// users
import { URL } from '../../../variables';
import { maskCep, maskCnpj } from '../../../Auxiliar/Masks';
import { MdReply } from 'react-icons/md';
import { FaTrash, FaUser } from 'react-icons/fa';

class EditarDestino extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: '/destinos',
      loading_screen: false,
      contatos: [],
      destinos: [],
      residuos_rejeitos: [],
      residuos_rejeitos_selecionados: {},
      residuos_selecionados: {},
      rejeitos_selecionados: {},
      pessoas_selecionadas: {},
      pessoas: {},
      pessoa: '',
      residuos: {},
      rejeitos: '',
      nomedoc: '',
      link_pdf: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      estado: '',
      cidade: '',
      contato: '',
      cnpj: '',
      razao: '',
      fantasia: '',
      descricao: '',
      licenca: '',
      numlicenca: '',
      doclicenca: '',
      tipolicenca: '',
      loading_save: false,
      msgErro: '',
      sigor: false,
      unidade_sigor: '',
      sinir: false,
      unidade_sinir: '',
      seedTransportadora: false
    };
  }

  buscar_endereco(cep) {
    this.setState({ loading_cep: true });
    fetch(`https://viacep.com.br/ws/${cep}/json/`, {
      method: 'GET',
      headers: {
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        this.setState({
          logradouro: resp.logradouro,
          bairro: resp.bairro,
          cidade: resp.localidade,
          estado: resp.uf,
          numero: '',
          loading_cep: false,
          // pessoas_selecionadas: resp.pessoas_selecionadas
        }, () => {
          if (this.state.logradouro == '') {
            document.getElementById('rua').focus();
          } else {
            document.getElementById('numero').focus();
          }
          if (resp.hasOwnProperty("erro")) {
            this.setState({ loading: false, cep: '', msgErro: 'O CEP digitado não foi encontrado, tente novamente.', loading_cep: false });
            document.getElementById('cep').focus();
          }
        });
      } catch (err) {
        console.log(err);
        this.setState({ loading: false, msgErro: 'Algo deu errado. Seu CEP está correto?', loading_cep: false });
      }
    })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false, msgErro: `Erro ao pegar resposta do servidor. Você está conectado a internet?`, loading_cep: false });
      });
  }


  salvar() {
    this.setState({ loading_save: true, msgErro: '' });
 
    fetch(`${URL}api/update_destino`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.props.token}`,
        'Content-Type': 'application/json',
      },
      
      body: JSON.stringify({
        destino_id: this.props.match.params.id,
        cep: this.state.cep,
        logradouro: this.state.logradouro,
        numero: this.state.numero,
        complemento: this.state.complemento,
        bairro: this.state.bairro,
        estado: this.state.estado,
        cidade: this.state.cidade,
        user_id: this.state.contato,
        cnpj: this.state.cnpj,
        razao: this.state.razao,
        fantasia: this.state.fantasia,
        descricao: this.state.descricao,
        licenca: this.state.licenca,
        NumLicenca: this.state.numlicenca,
        TipoLicenca: this.state.tipolicenca,
        DocLicenca: this.state.doclicenca,
        sigor: this.state.sigor,
        num: this.state.unidade_sigor,
        sinir: this.state.sinir,
        num_sinir: this.state.unidade_sinir,
        licenca: this.state.licenca,
        seed_transportadora: this.state.seedTransportadora,
        residuos: Object.keys(this.state.residuos_selecionados),
        rejeitos: Object.keys(this.state.rejeitos_selecionados),
        pessoas: Object.values(this.state.pessoas_selecionadas)
      }),
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);

          // window.scrollTo(500, 0);
          window.scrollTo(500, 0);
          this.setState({ loading_save: false, msgErro: erro });
        }
        else {

          this.setState({ loading_save: false, redirect: true });
        }
      } catch (err) {
        console.log(err);
        this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });
      }
    })
      .catch((err) => {
        console.log(err);
        this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
      });
  }


  componentDidMount() {

    this.get_destino(this.props.match.params.id);
    console.log(this.state.tipolicenca);
  }

  get_destino(id) {
    if (this.state.loading_screen == false) {
      this.setState({ loading_screen: true });
    }
    fetch(`${URL}api/get_destino/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${this.props.token}`,
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token'); localStorage.removeItem('user');
          // this.setState({redirect:true,path:'/'});
          this.props.logout();
          return;
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);
          this.setState({ redirect: true });
        }
        else {
          if (resp.destino.is_open) {
            this.setState({ redirect: true })
            return
          };

          let residuos_selecionados = {};
          for (let i = 0; i < resp.destino.residuos.length; i++) {
            residuos_selecionados[resp.destino.residuos[i].id] = resp.destino.residuos[i];
          }

          let rejeitos_selecionados = {};
          for (let i = 0; i < resp.destino.rejeitos.length; i++) {
            rejeitos_selecionados[resp.destino.rejeitos[i].id] = resp.destino.rejeitos[i];
          }

          this.setState({
            numero: resp.destino.numero == null ? 'S/N' : resp.destino.numero,
            descricao: resp.destino.descricao == null ? '' : resp.destino.descricao,
            complemento: resp.destino.complemento == null ? '' : resp.destino.complemento,
            loading_screen: false,
            link_pdf: resp.destino.DocLicenca,
            cep: resp.destino.cep,
            logradouro: resp.destino.logradouro,
            bairro: resp.destino.bairro,
            estado: resp.destino.estado,
            cidade: resp.destino.cidade,
            contato: resp.destino.user_id.id,
            cnpj: resp.destino.cnpj,
            razao: resp.destino.razao,
            fantasia: resp.destino.fantasia,
            licenca: resp.destino.licenca,
            numlicenca: resp.destino.NumLicenca == null ? '' : resp.destino.NumLicenca,
            tipolicenca: resp.destino.TipoLicenca == null ? '' : resp.destino.TipoLicenca,
            residuos_selecionados: residuos_selecionados,
            rejeitos_selecionados: rejeitos_selecionados,
            residuos_rejeitos: resp.residuos_rejeitos,
            contatos: resp.pessoas,
            sigor: resp.destino.unidade_sigor ? true : false,
            unidade_sigor: resp.destino.unidade_sigor ? resp.destino.unidade_sigor.num : '',
            sinir: resp.destino.unidade_sinir ? true : false,
            unidade_sinir: resp.destino.unidade_sinir ? resp.destino.unidade_sinir.num : '',
            seedTransportadora: resp.has_transport,
            pessoas_selecionadas: {
              ...resp.destino.pessoas_selecionadas.reduce((acc, item) => {
                return {
                  ...acc,
                  [item.id]: { ...item, name: item.user.name, id: item.user.id }
                };
              }, {})
            }
          });

        }
      } catch (err) {
        this.setState({ redirect: true });
        console.log(err);
      }
    })
      .catch((err) => {
        this.setState({ redirect: true });
        console.log(err);
      })
  }


  render() {
    return (
      <div className="row">
        {this.state.redirect == true && <Redirect to={this.state.path} />}

        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
              <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar destino</h4>
              <p style={{ fontSize: '1rem', color: 'black' }}>Edite um destino do seu sistema</p>
              <br />

              {/* <hr /> */}
              {this.state.loading_screen == false && <div>
                {this.state.msgErro != '' && <div>
                  <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>
                  </div>
                  <br />
                </div>}

                <div className="row">
                  <div className="col-12 mt-2">
                    <div className="form-check form-switch">
                      <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={this.state.seedTransportadora} onChange={() =>
                        this.setState({ seedTransportadora: !this.state.seedTransportadora })
                      } />
                      <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Utilizar destinado como transportadora</label>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">CEP <b style={{ color: 'red' }}>*</b></label>
                      <div className="col-sm-12">
                        <input className="form-control" id="cep" placeholder="CEP do destino" value={this.state.cep} type="text" onChange={(e) => {
                          this.setState({ cep: maskCep(e.target.value) });
                          let text = e.target.value.replace('-', '');
                          if (text.length == 8) {
                            this.buscar_endereco(text);
                          }
                        }} />
                      </div>
                    </div>

                    <div className="form-group  row">
                      <div className=" col-sm-12">
                        <div className="row">
                          <div className="col-md-8 col-12">
                            <label className="col-sm-12 col-form-label">Logradouro <b style={{ color: 'red' }}>*</b></label>
                            <div className="col-sm-12">
                              <input className="form-control" id="rua" placeholder="Logradouro do destino" type="text" onChange={(e) => this.setState({ logradouro: e.target.value })} value={this.state.logradouro} />
                            </div>
                          </div>
                          <div className="col-md-3 col-12">
                            <label className="col-sm-12 col-form-label">Número </label>
                            <div className="col-sm-12">
                              <input className="form-control" id="numero" placeholder="Número do endereço de destino" type="text" onChange={(e) => this.setState({ numero: e.target.value })} value={this.state.numero} />
                            </div>
                          </div>
                          <div className="col-md-1 col-12" >
                            <label className="col-form-label col-sm-12" htmlFor="select-all"  >S/N</label>
                            <div className="form-check form-switch col-sm-12 " >
                              <input className="form-check-input" checked={this.state.numero == 'S/N' ? true : false} type="checkbox" id={'select-all'} onChange={(e) => { this.setState({ numero: e.target.checked ? 'S/N' : '' }) }} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group  row">
                      <div className=" col-sm-12">
                        <div className="row">
                          <div className="col-md-8 col-12">
                            <label className="col-sm-12 col-form-label">Bairro <b style={{ color: 'red' }}>*</b></label>
                            <div className="col-sm-12">
                              <input className="form-control" placeholder="Nome do bairro" type="text" onChange={(e) => this.setState({ bairro: e.target.value })} value={this.state.bairro} />
                            </div>
                          </div>
                          <div className="col-md-4 col-12">
                            <label className="col-sm-12 col-form-label">Complemento</label>
                            <div className="col-sm-12">
                              <input className="form-control" placeholder="Complemento" type="text" onChange={(e) => this.setState({ complemento: e.target.value })} value={this.state.complemento} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group  row">
                      <div className=" col-sm-12">
                        <div className="row">
                          <div className="col-md-10 col-12">
                            <label className="col-sm-12 col-form-label">Cidade <b style={{ color: 'red' }}>*</b></label>
                            <div className="col-sm-12">
                              <input className="form-control" disabled placeholder="Nome da cidade" type="text" onChange={(e) => this.setState({ cidade: e.target.value })} value={this.state.cidade} />
                            </div>
                          </div>
                          <div className="col-md-2 col-12">
                            <label className="col-sm-12 col-form-label">Estado<b style={{ color: 'red' }}>*</b></label>
                            <div className="col-sm-12">
                              <input className="form-control" disabled placeholder="UF" type="text" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Responsável <b style={{ color: 'red' }}>*</b></label>
                      <div className="col-sm-12">
                        <div className="input-group">
                          <select value={this.state.contato} className="form-control" onChange={(e) => {
                            this.setState({ contato: e.target.value })
                          }}>
                            <option value={''}>Selecione um contato</option>
                            {this.state.contatos.map((item, id) => (<option key={id} type={item.name} value={item.id}>{item.name}</option>))}

                          </select>
                          <UsuarioModal reload={(e) => this.get_destino(e)} id={this.props.match.params.id} />
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Pessoas autorizadas <b style={{ color: 'red' }}>*</b></label>

                      <div className="col-sm-12">
                        {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'form-control','id'=>'faculdade']) }} */}
                        <div className="input-group">
                          <select value={this.state.pessoa} className="form-control" id="pessoas" onChange={(event) => {
                            let pessoas_selecionadas = { ...this.state.pessoas_selecionadas };
                            if (pessoas_selecionadas[event.target.value] === undefined) {
                              pessoas_selecionadas[event.target.value] = this.state.contatos.filter(item => item.id === event.target.value)[0];
                            }
                            pessoas_selecionadas[event.target.value].notificacoes = true;
                            this.setState({ pessoas_selecionadas: pessoas_selecionadas })
                          }}>
                            <option value={''}>Selecione uma ou mais pessoas autorizadas</option>

                            {Object.values(this.state.contatos).filter(item1 => item1.id !== this.state.contato).map((item, id) => (
                              <option key={id} value={item.id}>{item.name}</option>
                            ))}
                          </select>
                          <UsuarioModal reload={(e) => this.get_data(e)} />
                        </div>
                      </div>
                    </div>

                    {Object.values(this.state.pessoas_selecionadas).length != 0 && <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Pessoas autorizadas selecionadas:</label>

                      {Object.values(this.state.pessoas_selecionadas).map((item, id) => (
                        <div key={id} className="col-sm-4  col-md-4 col-lg-3" style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          border: '1px solid gray',
                          borderRadius: 10,
                          marginLeft: 10,
                          marginTop: 10

                        }}>
                          <div>
                            <span>{item.name}</span>

                            <div className=" d-flex align-items-center">
                              <label className="form-check-label" htmlFor="notificacao" style={{ marginRight: 10 }}>Notificações</label>
                              <div className="form-check form-switch ">
                                <input className="form-check-input" type="checkbox" id="notificacao" checked={item.notificacoes} onChange={(e) => {
                                  console.log(item)
                                  let pessoas_selecionadas = { ...this.state.pessoas_selecionadas };
                                  console.log('pessoas', pessoas_selecionadas, pessoas_selecionadas[item.id], item.id)
                                  pessoas_selecionadas[item.id].notificacoes = e.target.checked;
                                  this.setState({ pessoas_selecionadas: pessoas_selecionadas });
                                }} />
                              </div>
                            </div>
                          </div>
                          {item.id == this.state.contato &&
                            <span className=" btn-danger btn-outline btn-circle me-1" style={{ background: 'white' }}><FaUser color='green' /></span>
                          }
                          {item.id != this.state.contato && <button onClick={() => {
                            console.log(item)
                            let pessoas_selecionadas = { ...this.state.pessoas_selecionadas };
                            delete (pessoas_selecionadas[item.id]);
                            this.setState({ pessoas_selecionadas: pessoas_selecionadas });
                          }}
                            className="btn btn-danger btn-outline btn-circle me-1" style={{ background: 'white', border: '0px solid red' }}><FaTrash color='red' /></button>}

                        </div>
                      ))}

                    </div>}

                    <hr />
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">CNPJ <b style={{ color: 'red' }}>*</b></label>
                      <div className="col-sm-12">
                        <input className="form-control" placeholder="Número do CNPJ" value={this.state.cnpj} type="text" onChange={(e) => { this.setState({ cnpj: maskCnpj(e.target.value) }) }} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Razão social <b style={{ color: 'red' }}>*</b></label>
                      <div className="col-sm-12">
                        <input className="form-control" placeholder="Nome da razão social" value={this.state.razao} type="text" onChange={(e) => { this.setState({ razao: e.target.value }) }} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Nome fantasia <b style={{ color: 'red' }}>*</b></label>
                      <div className="col-sm-12">
                        <input className="form-control" placeholder="Nome fantasia" value={this.state.fantasia} type="text" onChange={(e) => { this.setState({ fantasia: e.target.value }) }} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Descrição </label>
                      <div className="col-sm-12">
                        <textarea className="form-control" placeholder="Descrição" value={this.state.descricao} type="text" onChange={(e) => { this.setState({ descricao: e.target.value }) }} />
                      </div>
                    </div>

                    {/* <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Licença<b style={{ color: 'red' }}>*</b></label>
                      <div className="col-sm-12">
                        <select value={this.state.licenca} className="form-control"
                          onChange={(e) => {
                            if (e.target.value == 'não licenciavel' || e.target.value == 'não licenciado') {
                              this.setState({
                                numlicenca: '',
                                doclicenca: '',
                                tipolicenca: '',
                              })
                            }
                            this.setState({ licenca: e.target.value })
                          }}>
                          <option value={''}>Selecione uma opção</option>
                          <option value={'licenciado'}>Licenciado</option>
                          <option value={'não licenciado'}>Não licenciado</option>
                          <option value={'não licenciavel'}>Não licenciável</option>
                          <option value={'em andamento'}>Em andamento</option>
                        </select>
                      </div>
                    </div>


                    {(this.state.licenca == 'em andamento' || this.state.licenca == 'licenciado') &&
                      <div>
                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">Número da licença <b style={{ color: 'red' }}>*</b></label>
                          <div className="col-sm-12">
                            <input className="form-control" placeholder="Número da licença" value={this.state.numlicenca} type="text" onChange={(e) => { this.setState({ numlicenca: e.target.value }) }} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">Tipo da licença <b style={{ color: 'red' }}>*</b></label>
                          <div className="col-sm-12">
                            <select className="form-control" value={this.state.tipolicenca} type="text" onChange={(e) => { this.setState({ tipolicenca: e.target.value }) }} >
                              <option value={''}>Selecione um tipo de licença</option>
                              <option value={'LP'}>LP</option>
                              <option value={'LI'}>LI</option>
                              <option value={'LO'}>LO</option>
                            </select>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">Anexo do documento <span style={{ color: 'red', fontWeight: 'normal', fontSize: '15px' }}>.pdf</span> </label>
                          <div className="col-sm-12">
                            <input className="form-control" value={this.state.nomedoc} type="file" accept=".pdf" onChange={(e) => {
                              let doclicenca = e.target.files[0];
                              this.setState({ nomedoc: e.target.value });
                              this.setState({ doclicenca });
                              let docpath = '';
                              let reader = new FileReader()
                              reader.readAsDataURL(e.target.files[0])

                              reader.onload = () => {
                                docpath = reader.result;
                                this.setState({ docpath });
                                console.log(this.state.nomedoc);
                              };
                              reader.onerror = function (error) {
                                console.log('Error: ', error);
                              }
                            }} />
                            {this.state.link_pdf != null && <a href={URL + this.state.link_pdf} target="_blank" style={{
                              marginLeft: '20px',
                              fontWeight: 'bold'
                            }}>Arquivo atual</a>}
                          </div>
                        </div>
                      </div>
                    } */}

                    <hr />
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Resíduos</label>
                      <div className="col-sm-12">
                        <select value={''} className="form-control" onChange={(e) => {
                          let residuo = this.state.residuos_rejeitos.filter(r => r.id == e.target.value)[0];
                          let residuos_selecionados = { ...this.state.residuos_selecionados }
                          residuos_selecionados[residuo.id] = residuo;
                          this.setState({ residuos_selecionados: residuos_selecionados })
                          console.log(this.state.residuos_selecionados);


                        }}>
                          <option value={''}>Selecione os resíduos que serão recebidos</option>
                          {this.state.residuos_rejeitos.filter(d => d.tipo === 'Resíduo').map((item, id) => (<option key={id} name={item.nome} value={item.id}>{item.nome}</option>))}

                        </select>
                      </div>
                    </div>
                    {Object.values(this.state.residuos_selecionados).length != 0 &&
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Resíduos Selecionadas:</label>

                        {Object.values(this.state.residuos_selecionados).map((item, id) => (
                          <div key={id} className="col-sm-6 col-12 col-md-4 col-lg-3" style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: '1px solid gray',
                            borderRadius: 10,
                            marginLeft: 5
                          }}>
                            <span>{item.nome}</span>
                            <button onClick={() => {
                              let residuos_selecionados = { ...this.state.residuos_selecionados };
                              delete (residuos_selecionados[item.id]);
                              this.setState({ residuos_selecionados: residuos_selecionados });


                            }}
                              className="btn btn-danger btn-outline btn-circle m-r-1" style={{ background: 'white', border: '0px solid red' }}><FaTrash color='red' /></button>
                          </div>
                        ))}

                      </div>}

                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Rejeitos</label>
                      <div className="col-sm-12">
                        <select value={''} className="form-control" onChange={(e) => {
                          let rejeito = this.state.residuos_rejeitos.filter(r => r.id == e.target.value)[0];
                          let rejeitos_selecionados = { ...this.state.rejeitos_selecionados };
                          rejeitos_selecionados[rejeito.id] = rejeito;
                          this.setState({ rejeitos_selecionados: rejeitos_selecionados });
                          console.log(this.state.rejeitos_selecionados);
                        }}>
                          <option value={''}>Selecione os rejeitos que serão recebidos</option>
                          {this.state.residuos_rejeitos.filter(d => d.tipo === 'Rejeito').map((item, id) => (<option key={id} type={item.nome} value={item.id}>{item.nome}</option>))}

                        </select>
                      </div>
                    </div>
                    {Object.values(this.state.rejeitos_selecionados).length != 0 &&
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Resíduos Selecionados:</label>

                        {Object.values(this.state.rejeitos_selecionados).map((item, id) => (
                          <div key={id} className="col-sm-6 col-12 col-md-4 col-lg-3" style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            border: '1px solid gray',
                            borderRadius: 10,
                            marginLeft: 5
                          }}>
                            <span>{item.nome}</span>
                            <button onClick={() => {
                              let rejeitos_selecionados = { ...this.state.rejeitos_selecionados };
                              delete (rejeitos_selecionados[item.id]);
                              console.log(rejeitos_selecionados);
                              this.setState({ rejeitos_selecionados: rejeitos_selecionados });
                            }}
                              className="btn btn-danger btn-outline btn-circle m-r-1" style={{ background: 'white', border: '0px solid red' }}><FaTrash color='red' /></button>
                          </div>
                        ))}

                      </div>}
                    {this.props.permissions.sigor?.acesso &&
                      <div className="form-group row pt-4">
                        <div className="col-12">
                          <div className="d-flex gap-5">
                            <div className="form-check form-switch">
                              <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={this.state.sigor} onChange={() => this.setState({ sigor: !this.state.sigor, unidade_sigor: '' })} />
                              <label className="form-check-label" for="flexSwitchCheckDefault">Possui registro no SIGOR</label>
                            </div>
                            <div className="form-check form-switch">
                              <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={this.state.sinir} onChange={() => this.setState({ sinir: !this.state.sinir, unidade_sinir: '' })} />
                              <label className="form-check-label" for="flexSwitchCheckDefault">Possui registro no SINIR</label>
                            </div>
                          </div>
                          {this.state.sigor &&
                            <div className="col-sm-12 mt-3">
                              <label className="col-sm-12 col-form-label">Unidade SIGOR<b style={{ color: 'red' }}>*</b></label>
                              <input className="form-control" placeholder="Número da unidade SIGOR" type="text" onChange={(e) => this.setState({ unidade_sigor: e.target.value })} value={this.state.unidade_sigor} />
                            </div>}
                          {this.state.sinir &&
                            <div className="col-sm-12 mt-3">
                              <label className="col-sm-12 col-form-label">Unidade SINIR<b style={{ color: 'red' }}>*</b></label>
                              <input className="form-control" placeholder="Número da unidade SINIR" type="text" onChange={(e) => this.setState({ unidade_sinir: e.target.value })} value={this.state.unidade_sinir} />
                            </div>}
                        </div>
                      </div>
                    }

                  </div>
                </div>

                {this.state.loading_save == false && <div className="row mt-3">
                  <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                    style={{ marginBottom: '0.5rem' }}>
                    <Link to="/destinos"> <span
                      className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                  </div>
                  <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                    style={{ marginBottom: '0.5rem' }}>
                    <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                      style={{ backgorundColor: '#f2f2f2' }}>Salvar Destino</button>
                  </div>
                </div>}
                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                  <div className="spinner-border text-primary" role="status"  >
                  </div>
                </div></div>}
              </div>}

              {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                <div className="spinner-border text-primary" role="status"  >
                </div>
              </div></div>}

            </div>

          </div>
        </div>
      </div>
    );
  }
}
const mapsStateToProps = (state) => (
  {
    token: state.AppReducer.token,
    permissions: state.AppReducer.permissoes,
  }
);

export default connect(mapsStateToProps, { logout })(EditarDestino);