import React from 'react'
import { maskTelefone, maskCpf } from '../../../Auxiliar/Masks';

const Usuario = ({ state, setState }) => {
  return (
    <div className="row">
      <div className="form-group row">
        <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Imagem</label>
        <div className="col-sm-12">
          <input className="form-control" type="file" accept="image/*" onChange={(e) => {
            let imagem = e.target.files[0];
            console.log(imagem)
            setState({ ...state, imagem: imagem });
            let imagem_path = '';
            let reader = new FileReader()
            reader.readAsDataURL(e.target.files[0])
            reader.onload = () => {
              imagem_path = reader.result;
              setState({ ...state, imagem_path: imagem_path });
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
            }
            e.target.value = '';
          }} />

        </div>
      </div>
      <div className="row">
        {state.imagem_path != '' &&
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }} className="col-12 col-md-12 mt-3">
            <div>
              <img src={state.imagem_path} style={{ height: 100, width: 100, objectFit: "contain", border: '1px solid lightgrey' }} />
              <p><a onClick={() => {
                setState({ ...state, imagem: '', imagem_path: '' });
              }} className="btn btn-link btn-link-dark" style={{ color: 'indianred', cursor: 'pointer' }}><span style={{ marginRight: 5 }}>Remover</span><i className="fas fa-times"></i></a></p>
            </div>
          </div>

        }
      </div>
      <hr />
      <div className="form-group row">
        <label className="col-sm-12 col-form-label">Nome <b style={{ color: 'red' }}>*</b></label>
        <div className="col-sm-12">
          <input className={`form-control ${state.name.error && 'is-invalid'}`} placeholder="Nome da pessoa" type="text" onChange={(e) => setState({ ...state, name: { value: e.target.value, error: false }, error1Step: false })} value={state.name.value} />

        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-12 col-form-label">E-mail <b style={{ color: 'red' }}>*</b></label>
        <div className="col-sm-12">
          <input className={`form-control ${state.email.error && 'is-invalid'}`} placeholder="E-mail da pessoa" type="email" onChange={(e) => setState({ ...state, email: { value: e.target.value, error: false }, error1Step: false })} error={state.email.value} value={state.email.value} />

        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-12 col-form-label">CPF <b style={{ color: 'red' }}></b></label>
        <div className="col-sm-12">
          <input className="form-control" placeholder="CPF" type="text" onChange={(e) => setState({ ...state, cpf: maskCpf(e.target.value) })} value={state.cpf} />

        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-12 col-form-label">Telefone <b style={{ color: 'red' }}></b></label>
        <div className="col-sm-12">
          <input className="form-control" placeholder="Telefone" type="text" onChange={(e) => setState({ ...state, telefone: maskTelefone(e.target.value) })} value={state.telefone} />

        </div>
      </div>

      <div className="form-group row">
        <label className="col-sm-12 col-form-label">Segundo telefone <b style={{ color: 'red' }}></b></label>
        <div className="col-sm-12">
          <input className="form-control" placeholder="Segundo telefone" type="text" onChange={(e) => setState({ ...state, segundo_telefone: maskTelefone(e.target.value) })} value={state.segundo_telefone} />

        </div>
      </div>
    </div>
  )
}

export default Usuario