import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// logs
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdSearch } from 'react-icons/md';

class Auditorias extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            logs: [],
            msgErro: '',
            show_warning: false,
            show_erro: false,
            log_to_delete: '',
            search: '',
            loading_search: false,
            loadings_logs: {}
        };
    }






    componentDidMount() {

        this.get_logs();
    }

    get_logs(search = '', busca = false) {
        if (busca == true) {
            if (this.state.loading_search == false) {
                this.setState({ loading_search: true });
            }
        }
        else if (this.state.loading_screen === false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_auditorias?search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    this.setState({ loading_search: false, logs: resp.auditorias, loading_screen: false, });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    gravar(id, gravar, index) {
        let loadings_logs = { ...this.state.loadings_logs };
        loadings_logs[id] = true;
        this.setState({ loadings_logs: loadings_logs });
        fetch(`${URL}api/store_auditoria`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                gravar: gravar,
                auditoria_id: id,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    let loadings_logs = { ...this.state.loadings_logs };
                    loadings_logs[id] = false;
                    this.setState({ loadings_logs: loadings_logs, msgErro: erro });
                }
                else {
                    let loadings_logs = { ...this.state.loadings_logs };
                    loadings_logs[id] = false;
                    this.setState({ loadings_logs: loadings_logs });

                }




            } catch (err) {
                console.log(err);
                let loadings_logs = { ...this.state.loadings_logs };
                loadings_logs[id] = false;
                let logs = [...this.state.logs];
                logs[index].gravar = !logs[index].gravar;
                this.setState({ logs: logs, loadings_logs: loadings_logs, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                let loadings_logs = { ...this.state.loadings_logs };
                loadings_logs[id] = false;
                let logs = [...this.state.logs];
                logs[index].gravar = !logs[index].gravar;
                this.setState({ logs: logs, loadings_logs: loadings_logs, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }










    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">

                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Lista de parâmetros de logs</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados todas os parâmetros de logs do seu sistema</p>
                            <br />
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                <div className="row mb-2">
                                    <div className="col-12 col-md-6">
                                        <form className="app-search mt-1" style={{ width: '100%' }} onSubmit={(e) => {
                                            clearTimeout(this.pesquisarTimeout); clearTimeout(this.pesquisarTimeout);
                                            this.get_logs(this.state.search, true)
                                            e.preventDefault();
                                        }}>
                                            <div className="form-group mb-0" style={{ width: '100%', position: 'relative' }}>
                                                <input value={this.state.search} name="search" placeholder="Pesquisar pela Descrição..." className="form-control" style={{ width: '100%', paddingRight: 40, borderRadius: 100 }} onChange={(e) => {
                                                    clearTimeout(this.pesquisarTimeout);
                                                    let search = e.target.value;
                                                    this.setState({ search: search });
                                                    this.pesquisarTimeout = setTimeout(() => {
                                                        this.get_logs(search, true)
                                                    }, 500);
                                                }} />
                                                <div style={{ width: 40, position: "absolute", right: 0, top: 0, bottom: 0, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                                    <MdSearch />

                                                </div>

                                            </div>
                                        </form>
                                    </div>

                                    <div className="col-12 col-md-6 d-flex justify-content-end">

                                    </div>
                                </div>
                                <div className="table-rep-plugin">
                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                        <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }}>
                                            <thead>
                                                <tr>

                                                    <th>Descrição</th>
                                                    <th>Gravar log?</th>



                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.loading_search == false && this.state.logs.map((item, id) => (

                                                    <tr key={id}>

                                                        <td style={{ maxWidth: '50px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{item.descricao}</td>


                                                        <td>
                                                            {/* <Link to={'/logs/editar/' + item.id}><span className="btn btn-info btn-outline btn-circle me-1 mb-1"
                                                                data-tip="Editar"
                                                            ><Tooltip/><MdModeEdit /></span></Link> */}
                                                            <div className="row d-flex justify-content-center" style={{ marginLeft: 0, marginRight: 0 }}>
                                                                <div className="form-check form-switch col-12 col-md-4 col-lg-4">
                                                                    <input disabled={this.props.user.tipo_usuario.permissoes['logs'].escrita == false} className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={item.gravar} onChange={(e) => {
                                                                        if (this.props.user.tipo_usuario.permissoes['logs'].escrita == true) {
                                                                            let logs = [...this.state.logs];
                                                                            logs[id].gravar = e.target.checked;
                                                                            this.setState({ logs: logs }, () => {
                                                                                this.gravar(item.id, e.target.checked, id);
                                                                            });
                                                                        }

                                                                    }} />
                                                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Gravar</label>
                                                                </div>

                                                            </div>

                                                        </td>

                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                        {this.state.loading_search == true &&
                                            <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                                <div className="spinner-border text-primary" role="status"  >
                                                </div>
                                            </div></div>

                                        }
                                    </div>
                                </div>
                                {this.state.loading_search == false && this.state.logs.length == 0 && <div className="row w-100">
                                    <div className="col-12">
                                        <p style={{ textAlign: 'center' }}>Nenhum paramêtro de log encontrado</p>

                                    </div>
                                </div>}




                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(Auditorias);


