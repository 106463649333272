import * as React from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { MdClose, MdLocalShipping, MdVisibility } from "react-icons/md";
import { FaCheckDouble } from "react-icons/fa";
import ReactTooltip from "react-tooltip";

const style = {
  position: "absolute",
  left: "25%",
  transform: "translate(0, 0, 0, 50%)",
  width: "70%",
  height: "100%",
  bgcolor: "background.paper",
  border: "2px solid transparent",
  boxShadow: 24,
  p: 4,

  overflowY: "auto",
  "@media(max-width: 1000px)": {
    width: "90%",
    left: "5%",
  },
};

export default function ConfirmOrganizationModal({
  organization = {},
  disabled = false,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const listStyle = {
    marginBottom: "10px",
    padding: "10px",
    backgroundColor: "#f2f2f2",
    borderRadius: "5px",
    overflow: "auto",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  return (
    <>
      <button
        className="btn btn-primary btn-outline btn-circle"
        onClick={handleOpen}
        disabled={disabled}
        data-tip="Ver"
      >
        <ReactTooltip />
        <MdVisibility style={{ color: "white" }} />
      </button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="d-flex justify-content-end">
              <span style={{ cursor: "pointer" }} onClick={() => handleClose()}>
                <MdClose />
              </span>
            </div>
            <h4
              className=" header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              Confirmar Organização
            </h4>
            <div>
              <ul
                className="list-group mt-4"
                style={{ listStyleType: "none", padding: 0 }}
              >
                <li
                  className="list-group-item d-flex justify-content-between"
                  style={listStyle}
                >
                  <strong>Razão Social:</strong>{" "}
                  <span>{organization?.company_name}</span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between"
                  style={listStyle}
                >
                  <strong>Nome Fantasia:</strong>{" "}
                  <span>{organization?.commercial_name}</span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between"
                  style={listStyle}
                >
                  <strong>CNPJ:</strong> <span>{organization?.cnpj}</span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between"
                  style={listStyle}
                >
                  <strong>CEP:</strong> <span>{organization?.cep}</span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between"
                  style={listStyle}
                >
                  <strong>Localidade:</strong>{" "}
                  <span>
                    {organization?.city}, {organization?.state}
                  </span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between"
                  style={listStyle}
                >
                  <strong>Endereço: </strong>{" "}
                  <span>
                    {organization?.street} - {organization?.number || "S/N"}
                  </span>
                </li>
                <li
                  className="list-group-item d-flex justify-content-between"
                  style={listStyle}
                >
                  <strong>Bairro e Complemento:</strong>{" "}
                  <span>
                    {organization?.neighborhood}{" "}
                    {organization?.complement
                      ? `, ${organization?.complement}`
                      : ""}
                  </span>
                </li>
              </ul>
            </div>
            <div className="mt-2 d-flex justify-content-end">
              <button
                className="btn btn-primary btn-lg"
                onClick={() => handleClose()}
              >
                Confirmar
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
