import { URL } from '../../variables'

const GenMtr = (props) => {
  function salvarManifesto(mtr) {
    console.log('props', props, mtr)
    fetch(`${URL}api/store_sigor_mtr`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${props.token}`,
      },
      body: JSON.stringify({ codigo_manifesto: mtr.manifestoNumeroNacional, destinacao_id: props.destinacaoId })
    }).then(async (response) => {
      const resp = await response.json()
      //Tratar erros
      return resp
    })
  }

  return (
    fetch(`https://mtrr.cetesb.sp.gov.br/apiws/rest/salvarManifestoLote`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': props.sigorToken,
      },
      body: JSON.stringify(props.body)
    }).then(async (response) => {
      let resp = await response.json()
      console.log('body MTR', props.body)
      console.log('gerou', resp)
      let data = {}

      if (resp.erroNacional) {
        return { status: false, resp: resp, message: 'ERROR' }
      }

      resp.respostaApiwsManifestoDTO.forEach(mtr => {
        if (!mtr.restResponseValido) data = { status: false, message: mtr.restResponseMensagem }
        else data = { status: true, resp: salvarManifesto(mtr), message: 'OK' }
        // switch (mtr.codigoGerado) {
        //   case 0:
        //     return data = { status: true, resp: salvarManifesto(mtr), message: 'OK' }

        //   case '005':
        //     return data = { status: false, message: 'A Data de expedição não pode ser anterior a data atual!' }

        //   case '007':
        //     return data = { status: false, message: 'Para MTR de AT, permite apenas um resíduo!' }

        //   case 999:
        //     if (!mtr.transportador.restResponseValido && !mtr.destinador.restResponseValido) data = { status: false, message: 'Dados do Transportador e Destinador não conferem - Unidade e CNPJ/CPF!' }

        //     else if (!mtr.transportador.restResponseValido) data = { status: false, message: 'Dados do Transportador não conferem - Unidade e CNPJ/CPF!' }

        //     else if (!mtr.destinador.restResponseValido) data = { status: false, message: 'Dados do Destinador não conferem - Unidade e CNPJ/CPF!' }

        //     return data

        //   default:
        //     return data = { status: false, message: 'Ocorreu um erro ao gerar o manifesto, tente novamente mais tarde!' }
        // }
      })
      return data
    })
  )
}

export default GenMtr