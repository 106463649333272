import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton, Tooltip } from '@mui/material';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import { URL } from '../../../variables';
import { useSelector } from 'react-redux';

const style = {
  position: 'absolute',
  // top: '50%',
  left: '50%',
  transform: 'translate(0, 0, 0, 50%)',
  width: '50%',
  height: '100%',
  bgcolor: 'background.paper',
  border: '2px solid transparent',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  '@media(max-width: 1000px)': {
    width: '90%',
    left: '5%',
  },
};

export default function InfoModal() {
  const token = useSelector(state => state.AppReducer.token);
  const user = useSelector(state => state.AppReducer.user);
  const [open, setOpen] = React.useState(user?.helpers?.default_active);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    if (user?.helpers?.default_active) {
      fetch(`${URL}api/update_info_mtr`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_id: user.id }),
      })
    }
    setOpen(false)
  };

  return (
    <div>
      <Tooltip title="Dúvidas">
        <IconButton size='small' onClick={handleOpen}>
          <BsFillInfoCircleFill />
        </IconButton>
      </Tooltip>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h5" component="h2">
              MANUAL PARA GERAÇÃO DE MTR
            </Typography>
            <Typography variant='h6' sx={{ mt: 2 }}>
              PRIMEIRO PASSO:
            </Typography>
            <Typography id="transition-modal-description">
              Possua ao menos um Destino, Transportadora e Gerador com códigos sigor cadastrados, é possível encontrar os destinos e transportadoras no módulo "Cadastro", basta marcar a opção "Possui registro sigor" e cadastrar a unidade, certifique-se que a unidade e o CNPJ estão cadastrados no sistema Sigor. Para cadastrar um gerador basta localizar a tela de "Dados da empresa" no módulo "Configurações" e marcar a opção "Empresa possui registro como gerador no Sigor", assim completando os dados.
            </Typography>

            <Typography variant='h6' sx={{ mt: 3 }}>
              SEGUNDO PASSO:
            </Typography>
            <Typography id="transition-modal-description">
              Possua um resíduo/rejeito aprovado do Sigor, lembrando que para realizar esse cadastro será necessário as seguintes aprovações: Classes, Unidades(uma de peso e outra de volume), Estados Físicos, Tratamentos e Acondicionamentos, lembrando que cada resíduo/rejeito possui uma classe suportada, e cada acondicionamento depende de um estado físico préviamente selecionado(O sistema irá indicar na hora se não possuir esses registros). Lembre-se de atualizar as informações Sigor no módulo "Configurações" antes de prosseguir com a aprovação do resíduo/rejeito.
            </Typography>

            <Typography variant='h6' sx={{ mt: 3 }}>
              TERCEIRO PASSO:
            </Typography>
            <Typography id="transition-modal-description">
              No módulo "Cadastros", crie um ponto de coleta que suporte o resíduo/rejeito aprovado anteriormente, logo após no módulo "Coleta e transporte" crie uma solicitação de coleta, vinculando o ponto de coleta criado à solicitação.
            </Typography>


            <Typography variant='h6' sx={{ mt: 3 }}>
              RELACIONADOS:
            </Typography>
            <Typography id="transition-modal-description">
              Você pode vincular unidades, classes, tratamentos, acondicionamentos e estados físicos cadastrados no sistema com outros aprovados do sigor, caso queira relacionar uma unidade de quilogramas(KG) com uma unidade sigor de toneladas(TON) será possivel, todas as telas do sistema mostrarão essa unidade herdada e realizarão os cálculos com ela, porem na hora da geração do MTR, será gerado com a "unidade pai" para fazer aproveitamento de seus códigos Sigor.
            </Typography>
            <Typography sx={{ mt: 1 }}>
              Exemplo: é vinculada uma unidade de quilogramas(KG) à uma unidade de toneladas(TON) e gerado um despacho de 1000KG, o manifesto gerado conterá 1,000TON(uma unidade) como peso total do resíduo.
            </Typography>


            <Typography sx={{ mt: 3 }}>
              Pronto! basta gerar um despacho com as marcações de destino externo e MTR que ao salvar a destinação será gerado o manifesto, ele ficará disponível aqui.
            </Typography>
            <div className="d-flex justify-content-end">
              <Button sx={{ mt: 4 }} variant='contained' color='success' onClick={handleClose}>Entendi</Button>
            </div>

          </Box>
        </Fade>
      </Modal>
    </div>
  );
}