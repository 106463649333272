import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";

import { NAME, URLadmin } from "../../../variables";
import { MdReply, MdVisibility } from "react-icons/md";

function ViewRelacionamentoInterno() {
  const { id } = useParams();
  const history = useHistory();

  const [tabOptions, setTabOptions] = React.useState([
    { name: "Relacionamento", active: true },
    { name: "Você", active: false },
    { name: "Vínculo de Materiais", value: 2, active: false },
  ]);
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);

  const [linkedOrganization, setLinkedOrganization] = React.useState({});
  const [myOrganization, setMyOrganization] = React.useState({});

  const [
    internalRelationshipWasteRejects,
    setInternalRelationshipWasteRejects,
  ] = React.useState([]);

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    fetch(
      `${URLadmin}api/auth/admin/internal_relationship/show/${id}?connection=${NAME}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    )
      .then(async (responseLog) => {
        try {
          let response = await responseLog.json();
          if (response.response) {
            setInternalRelationshipWasteRejects(
              response?.internalRelationship.wasteRejects
            );
            let organization = response.internalRelationship.organization;
            setMyOrganization(organization.logged);
            setLinkedOrganization(organization.linked);
          } else {
            history.push("/relacionamentos_internos");
          }
        } catch (err) {
          history.push("/relacionamentos_internos");
          console.error(err);
        }
      })
      .catch((err) => {
        history.push("/relacionamentos_internos");
        console.error(err);
      });
  };

  function handleTabs(index) {
    const updatedTabs = tabOptions.map((item, i) => ({
      ...item,
      active: i === index ? true : false,
    }));
    setTabOptions(updatedTabs);
    setActiveTabIndex(index);
  }

  function renderPage() {
    if (activeTabIndex === 0) return renderOrganization(linkedOrganization);
    if (activeTabIndex === 1) return renderOrganization(myOrganization);
    if (activeTabIndex === 2)
      return renderWasteRejects(internalRelationshipWasteRejects);
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body" id="card">
            <h4
              className="mt-0 header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              Ver relacionamento interno
            </h4>
            <p style={{ fontSize: "1rem", color: "black" }}>
              Veja todas as informações sobre o relacionamento interno.
            </p>
            <br />

            <ul className="nav nav-tabs">
              {tabOptions.map((item, index) => (
                <li className="nav-item" key={index}>
                  <span
                    className={`nav-link ${item.active ? "active" : ""}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleTabs(index)}
                  >
                    {item.name}
                  </span>
                </li>
              ))}
            </ul>
            {renderPage()}
            <div className="row pt-4">
              <div style={{ marginBottom: "0.5rem" }}>
                <Link to="/relacionamentos_internos">
                  <span className="btn btn-secondary btn-lg waves-effect waves-light">
                    <MdReply /> Voltar
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function renderOrganization(organization) {
  return (
    <React.Fragment>
      <div className="row mt-3">
        <div className="col-12">
          <div className="form-group row">
            <label
              className="col-sm-12 col-form-label"
              style={{ fontSize: "17px" }}
            >
              Atuação:{" "}
              <span style={{ fontWeight: "normal" }}>
                {organization?.department}
              </span>
            </label>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group row">
            <label
              className="col-sm-12 col-form-label"
              style={{ fontSize: "17px" }}
            >
              Razão Social:{" "}
              <span style={{ fontWeight: "normal" }}>
                {organization?.company_name}
              </span>
            </label>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group row">
            <label
              className="col-sm-12 col-form-label"
              style={{ fontSize: "17px" }}
            >
              Nome Fantasia:{" "}
              <span style={{ fontWeight: "normal" }}>
                {organization?.commercial_name}
              </span>
            </label>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group row">
            <label
              className="col-sm-12 col-form-label"
              style={{ fontSize: "17px" }}
            >
              CNPJ:{" "}
              <span style={{ fontWeight: "normal" }}>{organization?.cnpj}</span>
            </label>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group row">
            <label
              className="col-sm-12 col-form-label"
              style={{ fontSize: "17px" }}
            >
              CEP:{" "}
              <span style={{ fontWeight: "normal" }}>{organization?.cep}</span>
            </label>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group row">
            <label
              className="col-sm-12 col-form-label"
              style={{ fontSize: "17px" }}
            >
              Endereço:{" "}
              <span style={{ fontWeight: "normal" }}>
                {organization.street} - {organization?.number || "S/N"},{" "}
                {organization.nbhd}. {organization?.comp}
              </span>
            </label>
          </div>
        </div>
        <div className="col-12">
          <div className="form-group row">
            <label
              className="col-sm-12 col-form-label"
              style={{ fontSize: "17px" }}
            >
              Localidade:{" "}
              <span style={{ fontWeight: "normal" }}>
                {organization.city} - {organization?.state}
              </span>
            </label>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
function renderWasteRejects(wasteRejects) {
  return (
    <React.Fragment>
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0">
          <table
            className="table table-bordered"
            style={{ tableLayout: "fixed" }}
          >
            <colgroup>
              <col style={{ width: "50%" }} />
              <col style={{ width: "50%" }} />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">Ponto de Coleta</th>
                <th scope="col">Destino</th>
              </tr>
            </thead>
            <tbody>
              {wasteRejects?.map((item, index) => (
                <tr key={index}>
                  <td
                    className="align-middle"
                    style={{ wordWrap: "break-word" }}
                  >
                    {item.pickupPoint.name}
                  </td>
                  <td
                    className="align-middle"
                    style={{ wordWrap: "break-word" }}
                  >
                    {item.destination.name}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewRelacionamentoInterno;
