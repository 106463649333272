import React from 'react'

const EditarClasseSigor = (props) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">
            Nome <b style={{ color: 'red' }}>*</b>
          </label>
          <div className="col-sm-12">
            <input className="form-control" placeholder="Nome da classe" type="text" onChange={(e) =>
              props.setState({...props.state, nome: e.target.value })} value={props.state.descricao} disabled/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditarClasseSigor