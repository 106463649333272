
import React from 'react';
import Coletas from './SolicitacaoFinalizada';
import ColetasPendentes from './SolicitacaoGeral';
import Filter from '../../../Auxiliar/Filter';

const SelecionarJsSolicitarColeta = () => {
    const [search, setSearch] = React.useState(false)
    const [dateOf, setDateOf] = React.useState('')
    const [dateFor, setDateFor] = React.useState('')
    const [options, setOptions] = React.useState({
        coletaPendente: { value: true, label: 'Solicitações Pendentes', checked: true },
        coleta: { value: false, label: 'Solicitações Finalizadas', checked: false },
        coletaFixaPendente: { value: false, label: 'Solicitações Fixas Pendentes', checked: false },
        coletaFixa: { value: false, label: 'Solicitações Fixas Finalizadas', checked: false },
    })

    return (
        <div className="card">
            <div className="card-body" id="card">
                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Solicitações de Coletas</h4>
                <Filter setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setSearch} />
                <div>
                    <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados as solicitações de coletas do sistema</p>
                </div>
                <div className="row">
                    {options.coleta.value &&
                        <Coletas dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} coletaFixa={false} />}
                    {options.coletaPendente.value &&
                        <ColetasPendentes dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} coletaFixa={false} />}
                    {options.coletaFixa.value &&
                        <Coletas dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} coletaFixa={true} />}
                    {options.coletaFixaPendente.value &&
                        <ColetasPendentes dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} coletaFixa={true} />}
                </div>
            </div>
        </div>
    )
}

export default SelecionarJsSolicitarColeta


