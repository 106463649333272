import { createTheme } from "@mui/material"

const Theme = createTheme({
  palette: {
    primary: { main: '#73B054' },
    secondary: { main: '#1976D2' },
    text: { main: '#fff' },
  }
})

export default Theme