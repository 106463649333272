
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import UsuarioModal from '../UsuarioModal/UsuarioModal';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import { MdReply } from 'react-icons/md';
import { maskNumber, maskCep, maskCnpj } from '../../../Auxiliar/Masks';

class EditarTransportadora extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/transportadoras',
            loading_screen: false,
            responsaveis: [],
            responsavel_id: '',

            cnpj: '',
            razao_social: '',
            nome_fantasia: '',
            licenca: '',
            descricao: '',
            numero: '',
            cep: '',
            bairro: '',
            cidade: '',
            estado: '',
            logradouro: '',
            complemento: '',
            loading_save: false,
            msgErro: '',

            sigor: false,
            unidade_sigor: '',

            sinir: false,
            unidade_sinir: ''
        };
    }

    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/update_transportadoras`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                responsavel_id: this.state.responsavel_id,

                cnpj: this.state.cnpj,
                razao_social: this.state.razao_social,
                nome_fantasia: this.state.nome_fantasia,
                licenca: this.state.licenca,
                descricao: this.state.descricao,
                numero: this.state.numero,
                cep: this.state.cep,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                logradouro: this.state.logradouro,
                complemento: this.state.complemento,
                transportadora_id: this.props.match.params.id,
                sigor: this.state.sigor,
                num: this.state.unidade_sigor,
                sinir: this.state.sinir,
                num_sinir: this.state.unidade_sinir
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {

        this.get_transportadora(this.props.match.params.id);
    }

    get_transportadora(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_transportadora/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                debugger;
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);                    

                    this.setState({
                        loading_screen: false,
                        responsaveis: resp.responsaveis,
                        responsavel_id: resp.transportadora.responsavel_id,
                        cnpj: resp.transportadora.cnpj,

                        razao_social: resp.transportadora.razao_social,
                        nome_fantasia: resp.transportadora.nome_fantasia,
                        licenca: resp.transportadora.licenca,
                        descricao: resp.transportadora.descricao,
                        numero: resp.transportadora.numero == null ? '' : resp.transportadora.numero,
                        cep: resp.transportadora.cep,
                        bairro: resp.transportadora.bairro,
                        cidade: resp.transportadora.cidade,
                        estado: resp.transportadora.estado,
                        logradouro: resp.transportadora.logradouro,
                        complemento: resp.transportadora.complemento == null ? '' : resp.transportadora.complemento,
                        sigor: resp.transportadora.unidade_sigor ? true : false,
                        unidade_sigor: resp.transportadora.unidade_sigor ? resp.transportadora.unidade_sigor.num : '',
                        sinir: resp.transportadora.unidade_sinir ? true : false,
                        unidade_sinir: resp.transportadora.unidade_sinir ? resp.transportadora.unidade_sinir.num : '',

                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    buscar_endereco(cep) {
        this.setState({ loading_cep: true });
        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`, {
            method: 'GET',
            headers: {

            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                this.setState({
                    logradouro: resp.logradouro,
                    bairro: resp.bairro,
                    cidade: resp.localidade,
                    estado: resp.uf,
                    numero: '',
                    loading_cep: false

                }, () => {
                    if (this.state.logradouro == '') {
                        document.getElementById('rua').focus();
                    } else {
                        document.getElementById('numero').focus();
                    }
                });

                // await AsyncStorage.setItem('token', resp.access_token)
                // await AsyncStorage.setItem('user', JSON.stringify(resp.user))




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Algo deu errado. Seu CEP está correto?', loading_cep: false });
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: `Erro ao pegar resposta do servidor. Você está conectado a internet?`, loading_cep: false });
            });


    }




    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }


    render() {
        let anos = [];
        for (let i = parseInt(moment().format('YYYY')); i >= 1990; i--) {
            anos.push(i);
        }
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar transportadora</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um transportadora do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">CNPJ <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="CNPJ" type="text" onChange={(e) => this.setState({ cnpj: maskCnpj(e.target.value) })} value={this.state.cnpj} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Razão social <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Razão social" type="text" onChange={(e) => this.setState({ razao_social: e.target.value })} value={this.state.razao_social} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome fantasia <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Nome fantasia" type="text" onChange={(e) => this.setState({ nome_fantasia: e.target.value })} value={this.state.nome_fantasia} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Licença <b style={{ color: 'red' }}></b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Licença" type="text" onChange={(e) => this.setState({ licenca: e.target.value })} value={this.state.licenca} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Descrição</label>
                                            <div className="col-sm-12">
                                                <textarea className="form-control" placeholder="Descrição " type="text" onChange={(e) => this.setState({ descricao: e.target.value })} value={this.state.descricao}></textarea>

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Responsável <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <div className="input-group">
                                                    {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                    <select value={this.state.responsavel_id} className="form-control" id="cameras" onChange={(e) => {
                                                        this.setState({ responsavel_id: e.target.value })
                                                    }}>
                                                        <option value={''}>Selecione um responsável</option>

                                                        {this.state.responsaveis.map((item, id) => (
                                                            <option key={id} value={item.id}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                    <UsuarioModal reload={(e) => this.get_transportadora(e)} id={this.props.match.params.id} />
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">CEP <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="CEP" value={this.state.cep} type="text" onChange={(e) => {
                                                    this.setState({ cep: maskCep(e.target.value) });
                                                    let text = e.target.value.replace('-', '');
                                                    if (text.length == 8) {
                                                        this.buscar_endereco(text);
                                                    }
                                                }} />
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-sm-12 col-form-label">Logradouro <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" id="rua" placeholder="Logradouro do endereço" type="text" onChange={(e) => this.setState({ logradouro: e.target.value })} value={this.state.logradouro} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 col-12">
                                                        <label className="col-sm-12 col-form-label">Número <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" id="numero" placeholder="Número do endereço" type="text" onChange={(e) => this.setState({ numero: e.target.value })} value={this.state.numero} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-1 col-12" >
                                                        <label className="col-form-label col-sm-12" htmlFor="select-all"  >S/N</label>
                                                        <div className="form-check form-switch col-sm-12 " >
                                                            <input className="form-check-input" checked={this.state.numero == 'S/N' ? true : false} type="checkbox" id={'select-all'} onChange={(e) => { this.setState({ numero: e.target.checked ? 'S/N' : '' }) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-sm-12 col-form-label">Bairro <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Nome do bairro" type="text" onChange={(e) => this.setState({ bairro: e.target.value })} value={this.state.bairro} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-sm-12 col-form-label">Complemento</label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Complemento" type="text" onChange={(e) => this.setState({ complemento: e.target.value })} value={this.state.complemento} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-10 col-12">
                                                        <label className="col-sm-12 col-form-label">Cidade <b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" disabled placeholder="Nome da cidade" type="text" onChange={(e) => this.setState({ cidade: e.target.value })} value={this.state.cidade} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2 col-12">
                                                        <label className="col-sm-12 col-form-label">Estado<b style={{ color: 'red' }}>*</b></label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" disabled placeholder="UF" type="text" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.props.permissions.sigor?.acesso &&
                                            <div className="form-group row pt-4">
                                                <div className="col-12">
                                                    <div className="d-flex gap-5">
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={this.state.sigor} onChange={() => this.setState({ sigor: !this.state.sigor, unidade_sigor: '' })} />
                                                            <label className="form-check-label" for="flexSwitchCheckDefault">Possui registro no SIGOR</label>
                                                        </div>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={this.state.sinir} onChange={() => this.setState({ sinir: !this.state.sinir, unidade_sinir: '' })} />
                                                            <label className="form-check-label" for="flexSwitchCheckDefault">Possui registro no SINIR</label>
                                                        </div>
                                                    </div>
                                                    {this.state.sigor &&
                                                        <div className="col-sm-12 mt-3">
                                                            <label className="col-sm-12 col-form-label">Unidade SIGOR<b style={{ color: 'red' }}>*</b></label>
                                                            <input className="form-control" placeholder="Número da unidade SIGOR" type="text" onChange={(e) => this.setState({ unidade_sigor: e.target.value })} value={this.state.unidade_sigor} />
                                                        </div>}
                                                    {this.state.sinir &&
                                                        <div className="col-sm-12 mt-3">
                                                            <label className="col-sm-12 col-form-label">Unidade SINIR<b style={{ color: 'red' }}>*</b></label>
                                                            <input className="form-control" placeholder="Número da unidade SINIR" type="text" onChange={(e) => this.setState({ unidade_sinir: e.target.value })} value={this.state.unidade_sinir} />
                                                        </div>}
                                                </div>
                                            </div>
                                        }

                                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}
                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/transportadoras"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar transportadora</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        permissions: state.AppReducer.permissoes,
    }
);

export default connect(mapsStateToProps, { logout })(EditarTransportadora);


