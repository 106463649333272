
import React, { Component } from 'react';
import { Table } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import Chart from "react-apexcharts";
import { URL } from '../../../variables';
import { MdErrorOutline } from 'react-icons/md';
import ReactPaginate from 'react-paginate';
import { maskNumberBr } from '../../../Auxiliar/Masks';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';

class RelatoriosGestaoInterna extends Component {

    constructor(props) {
        super(props);
        this.state = {
            msgErro: false,
            redirect: false,
            path: '/',
            loading_gerar: false,
            loading_screen: true,
            Destinacao: [],
            DestinacaoAgrupadas: [],
            Destinacao_destinos: [],
            labels: [],
            series: [],
            Materiais: [],
            MateriaisSelecionados: [],
            DataAtual: '',
            DataInicial: '',
            DataFinal: '',
            TipoMaterial: 'Todos',
            Material: 'Todos',
            Descricao: '',
            Total: '',
            Titulo: '',
            TipoRelatorio: 'Peso',
            Situacao: 'Todas',
            RespTipo: 'Peso',
            Origem: 'Todas',
            Periodo: [],
            AnoPassado: '',
            GrafBar: [],
            showinp: true,
            page: 1,
            total_pages: 1,
            loading_table: false,
            page_gerar: false,
            Destinacao_pag: [],
            yearSelector: false,
            AnoEscolhido: ''
        };
    }

    componentDidMount() {
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        this.setState({
            DataAtual: ano + '-' + mes + '-' + dia,
            AnoPassado: ano - 1
        })
        this.get_Destinacao(this.state.page);
    }

    get_Destinacao(page) {

        if (this.state.loading_screen == false && this.state.Destinacao_pag == null) {
            this.setState({ loading_screen: true });
        } else {
            this.setState({ loading_table: true });
        }
        fetch(`${URL}api/get_relatorios_gestao_interna?page=${page}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    if (resp.destinacoes_group_destinos.length == 0) {
                        this.setState({
                            loading_table: false,
                            loading_gerar: false,
                            loading_screen: false,
                            Descricao: 'Não há registro suficiente para gerar relatório com os dados fornecidos ...',
                            Materiais: resp.materiais,
                            MateriaisSelecionados: resp.materiais,
                            labels: resp.destinacoes_group_destinos.map(item => item.fantasia),
                            series: resp.destinacoes_group_destinos.map(item => parseFloat(item.soma)),
                            Total: 0,
                            DestinacaoAgrupadas: resp.destinacoes_group_destinos,
                            Destinacao_pag: resp.destinacoes_page,
                            Titulo: 'Não encontrado',
                            total_pages: resp.pagination.last_page,
                            page: resp.pagination.current_page
                        });
                    } else {
                        this.setState({
                            loading_table: false,
                            Destinacao: resp.destinacoes,
                            loading_screen: false,
                            loading_search: false,
                            Materiais: resp.materiais,
                            MateriaisSelecionados: resp.materiais,
                            labels: resp.destinacoes_group_destinos.map(item => item.fantasia),
                            series: resp.destinacoes_group_destinos.map(item => parseFloat(item.soma)),
                            Total: resp.destinacoes_group_destinos.map(item => parseFloat(item.soma)).reduce((t, n) => t + n),
                            DestinacaoAgrupadas: resp.destinacoes_group_destinos,
                            Destinacao_pag: resp.destinacoes_page,
                            Descricao: resp.descricao,
                            Titulo: resp.titulo,
                            total_pages: resp.pagination.last_page,
                            page: resp.pagination.current_page
                        });
                    }
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false); 
            });
    }

    GerarRelatorio(page, gerador) {
        console.log(this.state.AnoEscolhido)
        if (this.state.DataInicial != '' && this.state.DataFinal == '' || this.state.DataInicial == '' && this.state.DataFinal != '') {
            this.setState({
                msgErro: 'Digite ambas ou nenhuma data'
            })
            return;
        }
        if (this.state.DataInicial != '' && this.state.DataFinal != '' && this.state.DataInicial > this.state.DataFinal) {
            this.setState({
                msgErro: 'Datas conflitantes'
            })
            return;
        }
        if (this.state.DataInicial != '' && this.state.DataFinal != '' && this.state.DataInicial > this.state.DataAtual || this.state.DataFinal > this.state.DataAtual && this.state.yearSelector == false) {
            this.setState({
                msgErro: 'Data inválida. Não é possível gerar relatórios futuros'
            })
            return;
        }
        if (this.state.yearSelector == true && this.state.AnoEscolhido.length != 4) {
            this.setState({
                msgErro: 'Digite o ano corretamente.'
            })
            return;
        }
        if (gerador) {
            this.setState({ loading_gerar: true, loading_screen: true, msgErro: false, page_gerar: true });
        } else {
            this.setState({ loading_table: true });
        }

        fetch(`${URL}api/gerar_relatorios_gestao_interna`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data_inicial: this.state.DataInicial,
                data_final: this.state.DataFinal,
                tipo_material: this.state.TipoMaterial,
                material: this.state.Material,
                tipo_relatorio: this.state.TipoRelatorio,
                situacao: this.state.Situacao,
                origem: this.state.Origem,
                page: page,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);
                    this.setState({
                        Descricao: 'Não foi posivel gerar o relatório por falta de informações...',
                        Total: 0,
                        loading_gerar: false,
                        msgErro: erro,
                        loading_screen: false
                    });
                }
                else {
                    console.log(resp)
                    let time_line = [];
                    let periodo = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
                    for (let i = this.state.DataInicial.replace(/(\d*)-(\d*)-(\d*).*/, '$1'); i <= this.state.DataFinal.replace(/(\d*)-(\d*)-(\d*).*/, '$1'); i++) {
                        if (i == this.state.DataFinal.replace(/(\d*)-(\d*)-(\d*).*/, '$1') && i == this.state.DataInicial.replace(/(\d*)-(\d*)-(\d*).*/, '$1')) {
                            for (let j = this.state.DataInicial.replace(/(\d*)-(\d*)-(\d*).*/, '$2') - 1; j < this.state.DataFinal.replace(/(\d*)-(\d*)-(\d*).*/, '$2'); j++) {
                                time_line.push(periodo[j] + '-' + i);
                            }
                        } else if (i == this.state.DataFinal.replace(/(\d*)-(\d*)-(\d*).*/, '$1')) {
                            for (let j = 0; j < this.state.DataFinal.replace(/(\d*)-(\d*)-(\d*).*/, '$2'); j++) {
                                time_line.push(periodo[j] + '-' + i);
                            }
                        } else if (i == this.state.DataInicial.replace(/(\d*)-(\d*)-(\d*).*/, '$1')) {
                            for (let j = this.state.DataInicial.replace(/(\d*)-(\d*)-(\d*).*/, '$2') - 1; j < periodo.length; j++) {
                                time_line.push(periodo[j] + '-' + i);
                            }
                        } else {
                            for (let j = 0; j < periodo.length; j++) {
                                time_line.push(periodo[j] + '-' + i);
                            }
                        }
                    }
                    let graf = [];
                    if (resp.tipo_relatorio != 'Quantidade' && resp.tipo_relatorio != 'Peso' && resp.tipo_relatorio != 'Despesa descricao' &&
                        resp.tipo_relatorio != 'Receita descricao' && resp.tipo_relatorio != 'Peso descricao' && resp.tipo_relatorio != 'Peso geracao' && resp.tipo_relatorio != 'Peso origem') {
                        for (let i = 0; i < resp.destinacoes_group_destinos.length; i++) {
                            let b = graf.filter((item) => item.name == resp.destinacoes_group_destinos[i].fantasia);
                            if (b.length == 0) {
                                let a = resp.destinacoes_group_destinos.filter((item) => item.fantasia == resp.destinacoes_group_destinos[i].fantasia);
                                let dados = [];
                                let tempo = 0;
                                for (let j = this.state.DataInicial.replace(/(\d*)-(\d*)-(\d*).*/, '$1'); j <= this.state.DataFinal.replace(/(\d*)-(\d*)-(\d*).*/, '$1'); j++) {
                                    for (let k = 0; k < a.length; k++) {
                                        if (a[k].periodo.replace(/(\d*)\/(\d*).*/, '$2') == j) {
                                            dados[(parseFloat(a[k].periodo.replace(/(\d*)\/(\d*).*/, '$1') - 1)) + tempo] = a[k].soma;
                                        }
                                    }
                                    if (j == this.state.DataInicial.replace(/(\d*)-(\d*)-(\d*).*/, '$1') && j != this.state.DataFinal.replace(/(\d*)-(\d*)-(\d*).*/, '$1')) {
                                        tempo = tempo + (13 - (this.state.DataInicial.replace(/(\d*)-(\d*)-(\d*).*/, '$2')));
                                    } else {
                                        tempo = tempo + 12
                                    }
                                }

                                for (let g = 0; g < time_line.length; g++) {
                                    if (dados[g] == undefined) {
                                        dados[g] = 0;
                                    }
                                }
                                let obj_bar = { name: resp.destinacoes_group_destinos[i].fantasia, data: dados };
                                graf.push(obj_bar);
                            }
                        }
                    }
                    if (resp.destinacoes_group_destinos.length == 0) {
                        this.setState({
                            loading_table: false,
                            loading_gerar: false,
                            loading_screen: false,
                            Descricao: 'Não há registro suficiente para gerar relatório com os dados fornecidos ...',
                            labels: resp.destinacoes_group_destinos.map(item => item.fantasia),
                            series: resp.destinacoes_group_destinos.map(item => parseFloat(item.soma)),
                            Total: 0,
                            DestinacaoAgrupadas: resp.destinacoes_group_destinos,
                            total_pages: resp.pagination.last_page,
                            page: resp.pagination.current_page,
                            Destinacao_pag: resp.destinacoes_page,
                            Periodo: time_line,
                            RespTipo: resp.tipo_relatorio,
                            GrafBar: graf,
                            Titulo: 'Não encontrado'
                        });
                    } else {
                        if (graf.length > 0) {
                            if (graf[0].name == undefined) {
                                graf[0].name = 'Total';
                            }
                        }

                        this.setState({
                            loading_gerar: false,
                            loading_screen: false,
                            loading_table: false,
                            labels: resp.destinacoes_group_destinos.map(item => item.fantasia),
                            series: resp.destinacoes_group_destinos.map(item => parseFloat(item.soma)),
                            Total: resp.destinacoes_group_destinos.map(item => parseFloat(item.soma)).reduce((t, n) => t + n),
                            DestinacaoAgrupadas: resp.destinacoes_group_destinos,
                            Descricao: resp.descricao,
                            Periodo: time_line,
                            RespTipo: resp.tipo_relatorio,
                            GrafBar: graf,
                            Titulo: resp.titulo,
                            total_pages: resp.pagination.last_page,
                            page: resp.pagination.current_page,
                            Destinacao_pag: resp.destinacoes_page,
                        });
                        console.log('Período ', this.state.Periodo)
                        console.log('GrafBar ', this.state.GrafBar)
                    }

                }

            } catch (err) {
                console.log(err);
                this.setState({ loading_gerar: false, msgErro: 'Erro ao pegar resposta do servidor', loading_screen: false });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_gerar: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?', loading_screen: false });
            });
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}
                {this.state.msgErro != '' && <div>
                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>
                    </div>
                    <br />
                </div>}
                <div className=" col-sm-12">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-12">
                            <label className="col-sm-12 col-form-label">Relatório</label>
                            <div className="input-group mb-3">
                                <select value={this.state.TipoRelatorio} className="form-control"
                                    onChange={(e) => {
                                        if (e.target.value == 'Despesa' || e.target.value == 'Receita' || e.target.value == 'Receita total' || e.target.value == 'Despesa total') {
                                            this.setState({
                                                showinp: false,
                                                DataInicial: this.state.AnoPassado + '-01-01',
                                                DataFinal: this.state.AnoPassado + '-12-31',
                                                TipoRelatorio: e.target.value,
                                                yearSelector: true,
                                                AnoEscolhido: this.state.AnoPassado.toString()
                                            })
                                        } else {
                                            this.setState({
                                                showinp: true,
                                                DataInicial: '',
                                                DataFinal: '',
                                                TipoRelatorio: e.target.value,
                                                yearSelector: false,
                                                AnoEscolhido: ''
                                            });
                                        }
                                    }}>

                                    <option value={'Quantidade'}>Número de destinações</option>
                                    <option defaultValue value={'Peso'}>Peso por destinação</option>
                                    <option value={'Peso descricao'}>Peso por material</option>
                                    <option value={'Peso geracao'}>Peso por geração</option>
                                    <option value={'Peso origem'}>Peso por origem</option>
                                    <option value={'Despesa'}>Despesa por destinação</option>
                                    <option value={'Receita'}>Receita por destinação</option>
                                    <option value={'Despesa descricao'}>Despesa por material</option>
                                    <option value={'Receita descricao'}>Receita por material</option>
                                    <option value={'Receita total'}>Receita total</option>
                                    <option value={'Despesa total'}>Despesa total</option>

                                </select>
                            </div>
                        </div>
                        {(this.state.TipoRelatorio != 'Peso geracao' && this.state.TipoRelatorio != 'Peso origem') && 
                        <div className="col-lg-4 col-md-6 col-12">
                        <label className="col-sm-12 col-form-label">Situação</label>
                            <div className="input-group mb-3">
                                <select value={this.state.Situacao} className="form-control"
                                    onChange={(e) => {
                                        this.setState({ Situacao: e.target.value });
                                    }}>
                                    <option value={'Todas'}>Todas</option>
                                    <option value={'Em andamento'} defaultValue>Em andamento</option>
                                    <option value={'Finalizadas'}>Finalizadas</option>

                                </select>
                            </div>
                        </div>}
                        {this.state.TipoRelatorio == 'Peso origem' && 
                        <div className="col-lg-4 col-md-6 col-12">
                        <label className="col-sm-12 col-form-label">Origem</label>
                            <div className="input-group mb-3">
                                <select value={this.state.Origem} className="form-control"
                                    onChange={(e) => {
                                        this.setState({ Origem: e.target.value });
                                    }}>
                                    <option value={'Todas'}>Todas</option>
                                    <option value={'Interna'} defaultValue>Interna</option>
                                    <option value={'Externa'}>Externa</option>

                                </select>
                            </div>
                        </div>}
                        {!this.state.yearSelector &&
                            <>
                                <div className="col-lg-4 col-md-6 col-12">

                                    <label className="col-sm-12 col-form-label">Data inicial: </label>
                                    <div className="col-sm-12">
                                        <input className="form-control" type="date"  max={this.state.DataAtual} onChange={(e) => this.setState({ DataInicial: e.target.value })} value={this.state.DataInicial} />
                                    </div>
                                </div>
                        <div className="col-lg-4 col-md-6 col-12">
                                
                                    <label className="col-sm-12 col-form-label">Data final: </label>
                                    <div className="input-group mb-3">
                                        <input className="form-control" type="date"  max={this.state.DataAtual} value={this.state.DataFinal} onChange={(e) => {

                                            this.setState({
                                                DataFinal: e.target.value
                                            })
                                            console.log(this.state.DataFinal)
                                            console.log(this.state.DataInicial)
                                        }} />

                                    </div>
                                </div>
                            </>}
                        {this.state.yearSelector && 
                       
                            <div className="col-lg-4 col-md-6 col-12">

                                <label className="col-sm-12 col-form-label">Digite um ano: </label>
                                <div className="col-sm-12">
                                    <input className="form-control" type="text"  min={4} max={4} onChange={(e) => this.setState({ DataInicial: e.target.value + '-01-01', DataFinal: e.target.value + '-12-31', AnoEscolhido: e.target.value })} value={this.state.AnoEscolhido} />
                                </div>
                            </div>
                        }
                {this.state.showinp == true && <>

                        {this.state.TipoRelatorio != 'Peso origem' &&                         <div className="col-lg-4 col-md-6 col-12">

                            <label className="col-sm-12 col-form-label">Tipo de material</label>
                            <div className="input-group mb-3">
                                <select value={this.state.TipoMaterial} className="form-control"
                                    onChange={(e) => {
                                        if (e.target.value == 'Todos') {
                                            this.setState({
                                                TipoMaterial: e.target.value,
                                                Material: 'Todos',
                                                MateriaisSelecionados: this.state.Materiais,
                                            });
                                        } else {
                                            let materiais = this.state.Materiais.filter(item => item.tipo == e.target.value);
                                            this.setState({
                                                TipoMaterial: e.target.value,
                                                Material: 'Todos',
                                                MateriaisSelecionados: materiais,
                                            });
                                        }
                                    }}>
                                    <option value={'Todos'}>Todos</option>
                                    <option value={'Resíduo'}>Resíduo</option>
                                    <option value={'Rejeito'}>Rejeito</option>

                                </select>
                            </div>
                        </div>}
                        {(this.state.TipoRelatorio != 'Despesa descricao' && this.state.TipoRelatorio != 'Receita descricao'
                            && this.state.TipoRelatorio != 'Peso descricao' && this.state.TipoRelatorio != 'Peso geracao' && this.state.TipoRelatorio != 'Peso origem') &&                        <div className="col-lg-4 col-md-6 col-12">

                                <label className="col-sm-12 col-form-label">Material</label>
                                <div className="input-group mb-3">
                                    <select value={this.state.Material} className="form-control"
                                        onChange={(e) => {
                                            this.setState({
                                                Material: e.target.value
                                            })
                                        }}>
                                        <option value={'Todos'}>Todos</option>
                                        {this.state.MateriaisSelecionados.map((item, id) => (<option key={id} value={item.id}>{item.nome}</option>))}
                                    </select>
                                </div>
                            </div>}
                    </>
                }
                    </div>

                </div>

                {this.state.loading_gerar == false && <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button type="button" className="btn btn-info" style={{ backgorundColor: '#f2f2f2', marginBottom: '5px' }} onClick={() => this.GerarRelatorio(1, true)}>Gerar relatório</button>
                </div>}
                <hr />

                {this.state.loading_screen == false &&
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body" id="card">
                                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.state.Titulo}</h4>
                                <p style={{ fontSize: '1rem', color: 'black' }}>{this.state.Descricao}
                                    {this.state.yearSelector && <IoIosInformationCircleOutline data-tip={`Para dados mais precisos, utilize a ferramenta de zoom, no canto superior direito do gráfico`} />}
                                    {!this.state.yearSelector && <IoIosInformationCircleOutline data-tip={`Para ver dados menores que não aparecem no gráfico, clique na legenda`} />}
                                    <ReactTooltip place="top" effect="solid" /></p>

                                <br />
                                {this.state.Total == 0 && <div style={{ fontSize: '100px', textAlign: 'center', color: '#ffc107' }}><MdErrorOutline /></div>}

                                {(this.state.Total > 0 && this.state.RespTipo == 'Peso' || this.state.Total > 0 && this.state.RespTipo == 'Peso descricao' || this.state.Total > 0 && this.state.RespTipo == 'Peso geracao' || this.state.Total > 0 && this.state.RespTipo == 'Peso origem') &&
                                    <div style={{ width: '80%', position: 'center' }}><Chart
                                        options={
                                            {
                                                dataLabels: {
                                                    enabled: true,
                                                },
                                                plotOptions: {
                                                    pie: {
                                                        donut: {
                                                            size: '50%',
                                                            labels: {
                                                                show: true,
                                                                name: {
                                                                    show: true,
                                                                    color: 'black',
                                                                    fontWeight: 'bold'
                                                                },
                                                                value: {
                                                                    show: true,
                                                                    offsetY: 0,
                                                                    formatter: function (val) {
                                                                        return maskNumberBr(val) + ' kg'
                                                                    }
                                                                },
                                                                total: {
                                                                    show: true,
                                                                    formatter: function (w) {
                                                                        console.log(w.globals.seriesTotals)
                                                                        let resultado = w.globals.seriesTotals.reduce((total, numero) => {
                                                                            return total + numero;
                                                                        });
                                                                        return maskNumberBr(resultado) + ' kg'
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                },
                                                labels: this.state.labels,
                                                legend: { position: 'right', fontSize: '16px', fontWeight: 'bold', fontFamily: 'Roboto, sans-serif' },
                                            }}
                                        series={this.state.series}
                                        height="350px"
                                        width="100%"
                                        type="donut"
                                    /> </div>}
                                {(this.state.Total > 0 && this.state.RespTipo == 'Quantidade' || this.state.RespTipo == 'Despesa descricao' || this.state.RespTipo == 'Receita descricao') &&
                                    <div style={{ width: '80%', position: 'center' }}>
                                        <Chart
                                            options={
                                                {
                                                    dataLabels: {
                                                        enabled: true,
                                                    },
                                                    annotations: {
                                                        texts: [this.state.RespTipo]
                                                    },
                                                    plotOptions: {
                                                        pie: {
                                                            donut: {
                                                                size: '50%',
                                                                labels: {
                                                                    show: true,
                                                                    name: {
                                                                        show: true,
                                                                        color: 'black',
                                                                        fontWeight: 'bold'
                                                                    },
                                                                    value: {
                                                                        offsetY: 0,
                                                                        show: true,
                                                                        formatter: function (val, opts) {
                                                                            console.log(opts);
                                                                            let tipo = opts.config.annotations.texts[0];
                                                                            if (tipo == 'Despesa descricao' || tipo == 'Receita descricao') {
                                                                                return 'R$ ' + maskNumberBr(parseFloat(val).toFixed(2));
                                                                            }
                                                                            else {
                                                                                return val;
                                                                            }
                                                                        }
                                                                    },
                                                                    total: {
                                                                        show: true,
                                                                        formatter: function (w) {
                                                                            console.log(w);
                                                                            let tipo = w.config.annotations.texts[0];
                                                                            let resultado = w.globals.seriesTotals.reduce((total, numero) => {
                                                                                return total + numero;
                                                                            });
                                                                            console.log(tipo);
                                                                            if (tipo == 'Despesa descricao' || tipo == 'Receita descricao') {
                                                                                return 'R$ ' + maskNumberBr(resultado.toFixed(2));
                                                                            }
                                                                            else {
                                                                                return resultado;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    },
                                                    labels: this.state.labels,
                                                    legend: {
                                                        formatter: function (seriesName,) {
                                                            return seriesName.charAt(0).toUpperCase() + seriesName.slice(1);
                                                        }, position: 'right', fontSize: '16px', fontWeight: 'bold', horizontalAlign: 'right', fontFamily: 'Roboto, sans-serif'
                                                    },
                                                }
                                            }
                                            series={this.state.series}
                                            height="350px"
                                            width='100%'
                                            type="donut"
                                        /> </div>}
                                {(this.state.Total > 0 && this.state.RespTipo == 'Despesa' || this.state.Total > 0 && this.state.RespTipo == 'Receita') && <Chart
                                    options={
                                        {
                                            chart: {
                                                type: 'area',
                                                height: 350,
                                                stacked: 'true',
                                                zoom: {
                                                    enabled: true,
                                                },
                                            },
                                            grid: {
                                                show: true,
                                                xaxis: {
                                                    lines: {
                                                        show: true
                                                    }
                                                }
                                            },
                                            dataLabels: {
                                                enabled: false
                                            },
                                            stroke: {
                                                show: true,
                                                width: 2,
                                                curve: 'smooth',
                                                colors: ['transparent']
                                            },
                                            xaxis: {
                                                categories: this.state.Periodo,
                                            },
                                            yaxis: {
                                                labels: {
                                                    formatter: function (val) {
                                                        return "R$ " + maskNumberBr(val.toFixed(2))
                                                    }
                                                },
                                                min: 0,
                                                title: {
                                                    text: 'R$ (BRL)'
                                                }
                                            },
                                            fill: {
                                                type: 'gradient',
                                                gradient: {
                                                    opacityFrom: 0.6,
                                                    opacityTo: 1,
                                                }
                                            },
                                            tooltip: {
                                                y: {
                                                    formatter: function (val) {
                                                        return "R$ " + maskNumberBr(val.toFixed(2))
                                                    }
                                                }
                                            },
                                        }
                                    }
                                    series={this.state.GrafBar}
                                    height="450px"
                                    type="area"
                                />}
                                {(this.state.Total > 0 && this.state.RespTipo == 'Receita total' || this.state.Total > 0 && this.state.RespTipo == 'Despesa total') && <Chart
                                    options={
                                        {
                                            chart: {
                                                height: 350,
                                                type: 'line',
                                                zoom: {
                                                    enabled: true,
                                                },
                                            },
                                            markers: {
                                                size: 4,
                                                colors: 'white',
                                                strokeColors: 'black'
                                            },
                                            stroke: {
                                                curve: 'straight'
                                            },
                                            grid: {
                                                row: {
                                                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                                                    opacity: 0.5
                                                },
                                            },
                                            tooltip: {
                                                y: {
                                                    formatter: function (val) {
                                                        return "R$ " + maskNumberBr(val.toFixed(2))
                                                    }
                                                }
                                            },
                                            xaxis: {
                                                categories: this.state.Periodo,
                                            },
                                            fill: {
                                                opacity: 1
                                            },
                                            yaxis: {
                                                labels: {
                                                    formatter: function (val) {
                                                        return "R$ " + maskNumberBr(val.toFixed(2))
                                                    }
                                                },
                                                title: {
                                                    text: 'R$ (BRL)'
                                                }
                                            }
                                        }
                                    }
                                    series={this.state.GrafBar}
                                    height="450px"
                                    type="line"
                                />}
                            </div>
                            <ul className="list-group list-group-flush">
                                <Table responsive="sm">
                                    <thead>
                                        <tr>
                                            {(this.state.RespTipo != 'Receita total' && this.state.RespTipo != 'Despesa total' && this.state.RespTipo != 'Peso origem') && <th>Favorecido/Credor</th>}
                                            {this.state.RespTipo == 'Peso origem' && <th>Origem</th>}
                                            {(this.state.RespTipo == 'Despesa' || this.state.RespTipo == 'Receita' || this.state.RespTipo == 'Receita total' || this.state.RespTipo == 'Despesa total') && <th>Periodo</th>}
                                            <th style={{ textAlign: 'right' }}>{(this.state.RespTipo == 'Peso' || this.state.RespTipo == 'Peso descricao' || this.state.RespTipo == 'Peso geracao' || this.state.RespTipo == 'Peso origem') ? 'Valor (Kg)' : this.state.RespTipo == 'Quantidade' ? 'Número de destinações' : 'Valor (R$)'}</th>
                                        </tr>
                                    </thead>
                                    {this.state.loading_table == false && <tbody>
                                        {this.state.Destinacao_pag.map((item, id) => (
                                            <tr key={id}>
                                                {(this.state.RespTipo != 'Receita total' && this.state.RespTipo != 'Despesa total' && this.state.RespTipo != 'Peso origem') && <td style={{ fontSize: '1rem', fontWeight: 'bold', color: 'blue' }}>{item.fantasia.charAt(0).toUpperCase() + item.fantasia.slice(1)}</td>}
                                                {item.periodo != undefined && <td style={{ fontSize: '1rem' }}>{item.periodo}</td>}
                                                {this.state.RespTipo == 'Peso origem' && <td style={{ fontSize: '1rem', fontWeight: 'bold', color: 'blue' }}>{item.origem_material + ' - ' + item.fantasia.charAt(0).toUpperCase() + item.fantasia.slice(1)}</td>}
                                                <td style={{ fontSize: '1rem', fontWeight: 'normal', color: 'black', textAlign: 'right' }}>{(this.state.RespTipo == 'Peso' || this.state.RespTipo == 'Peso descricao' || this.state.RespTipo == 'Peso geracao' || this.state.RespTipo == 'Peso origem') ? maskNumberBr(parseFloat(item.soma).toFixed(2).toString()) + ' Kg' :
                                                    this.state.RespTipo == 'Quantidade' ? maskNumberBr(parseFloat(item.soma).toString()) : 'R$ ' + maskNumberBr(parseFloat(item.soma).toFixed(2).toString())}</td>
                                            </tr>))}
                                    </tbody>}
                                </Table>
                                {this.state.loading_table == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}
                                <div className="col-12 d-flex justify-content-end" style={{ marginBottom: '10px' }} >
                                    <ReactPaginate
                                        previousLabel={'Anterior'}
                                        nextLabel={'Próxima'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.total_pages}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handlePageClick.bind(this)}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                        initialPage={this.state.page - 1}
                                    />
                                </div>
                            </ul>

                            <ul className="list-group list-group-flush">
                                <Table responsive="sm">
                                    <tbody>
                                        <tr>
                                            <td style={{ fontSize: '1rem', fontWeight: 'bold', color: 'black' }}>Total</td>
                                            <td style={{ fontSize: '1rem', fontWeight: 'bold', color: 'black', textAlign: 'right' }}>{(this.state.RespTipo == 'Peso' || this.state.RespTipo == 'Peso descricao' || this.state.RespTipo == 'Peso geracao' || this.state.RespTipo == 'Peso origem') ? maskNumberBr(parseFloat(this.state.Total.toString()).toFixed(2)) + ' Kg' :
                                                this.state.RespTipo == 'Quantidade' ? maskNumberBr(this.state.Total.toString()) : 'R$ ' + maskNumberBr(parseFloat(this.state.Total).toFixed(2).toString())}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </ul>
                        </div>
                    </div>
                }

                {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                    </div>
                </div></div>}
            </div>

        );
    }
    handlePageClick(page) {
        console.log(page.selected);
        if (page.selected + 1 != this.state.page) {
            if (this.state.page_gerar == false) {
                this.get_Destinacao(page.selected + 1);
            } else {
                this.GerarRelatorio(page.selected + 1);
            }

        }
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(RelatoriosGestaoInterna);


