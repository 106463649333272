import SweetAlert from "react-bootstrap-sweetalert";
import { toast, ToastContainer } from "react-toastify";

export function ToastContent() {
  return (
    <ToastContainer
      position="bottom-right"
      autoClose={1000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  )
}

export function renderToast(props) {
  toast[props.type](props.error, {
    position: "bottom-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}

export const renderAlert = (text, callback) => (
  <SweetAlert
    danger
    title={"Ops..."}
    onConfirm={() => {
      callback()
    }}
    confirmBtnText="Entendi"
    confirmBtnBsStyle="primary"
  >
    {text}
  </SweetAlert>
)