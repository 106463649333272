
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import UsuarioModal from '../../Cadastros/UsuarioModal/UsuarioModal';

// users
import { URL } from '../../../variables';
import { maskNumber, maskNumberBr } from '../../../Auxiliar/Masks';

import { MdReply } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';

class CriarDestinos extends Component {



  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: '/entrada_coleta',
      loading_screen: false,
      Registros: [],
      PontosColetas: [],
      Residuos: [],
      Rejeitos: [],
      Responsaveis: [],
      Todos_Responsaveis: [],
      Estoque: [],
      Capacidade_Maxima_peso: '',
      Capacidade_Maxima_un: '',
      QuantityError: false,

      Rejeito_selecionado: {},
      Residuo_selecionado: {},

      atraso: '',
      MaterialAgrupado: '',
      Responsavel: '',
      Rastreamento: '',
      DadosRastreamento: '',
      TipoMaterial: '',
      ChaveNota: '',
      NumeroNota: '',
      SerieNota: '',
      PontoColeta: '',
      ResiduoRejeito: '',
      DataAtual: '',
      DataEntrada: '',
      Descricao: '',
      Quantidade: '',
      QuantidadeAtual: '',
      UnidadeQuantidade: '',
      Equivalencia: '',
      ValorEquivalente: '',
      UnidadeEquivalencia: '',
      UnidadeQuantidadeId: '',
      UnidadeEquivalenciaId: '',
      loading_save: false,
      TipoQuantidade: '',
      CalcEstoque: '',
      msgErro: '',
      PrincipalMaxAmount: '',
      PrincipalMaxAmount: '',
      PrincipalUnity: '',
      SecondaryUnity: ''
    };
  }

  // carrega dados para preenchimento dos <select> apos a montagem do componente
  componentDidMount() {

    this.get_Registro(this.props.match.params.id);
  }


  // busca dados para preenchimento dos <selects>---------------------------------------
  get_Registro(id) {
    if (this.state.loading_screen == false) {
      this.setState({ loading_screen: true });
    }
    // busca dados da tabela pessoa para <select> contato---------------------------------------
    fetch(`${URL}api/get_registro_entrada/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${this.props.token}`,
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token'); localStorage.removeItem('user');
          // this.setState({redirect:true,path:'/'});
          this.props.logout();
          return;
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);
          this.setState({ redirect: true });
        }
        else {
          let quantidade = resp.registro.quantidade;
          let valeq = resp.registro.equivalencia;
          let ponto_selecionado = { ...resp.pontos_coletas.filter(p => p.id == resp.registro.pontos_coleta_id.id) };
          console.log(ponto_selecionado);

          let [autorizados] = resp.pontos_coletas.filter((item) => item.id == resp.registro.pontos_coleta_id.id).map(item => item.pessoas);
          let responsaveis = [];
          for (let i = 0; i < autorizados.length; i++) {
            for (let j = 0; j < resp.responsaveis.length; j++) {
              if (autorizados[i].user_id == resp.responsaveis[j].id) {
                responsaveis.push(resp.responsaveis[j]);
                j = resp.responsaveis.length
              }
            }
          }

          const residuo_selecionado = ponto_selecionado[0].residuos_rejeitos.find(item => item.id === resp.registro.residuo_rejeito_id.id)
          const { PrincipalMaxAmount, SecondaryMaxAmount } = this.getTooltipData(ponto_selecionado[0], residuo_selecionado)

          this.setState({
            Estoque: resp.estoque,
            loading_screen: false,
            Equivalencia: valeq / quantidade,
            MaterialAgrupado: resp.registro.material_agrupado,
            Responsavel: resp.registro.responsavel_id.id,
            Rastreamento: resp.registro.rastreamento,
            DadosRastreamento: resp.registro.dados_rastreamento == null ? '' : resp.registro.dados_rastreamento,
            TipoMaterial: resp.registro.tipo_material,
            ChaveNota: resp.registro.chave_nfe == null ? '' : resp.registro.chave_nfe,
            NumeroNota: resp.registro.numero_nfe == null ? '' : resp.registro.numero_nfe,
            SerieNota: resp.registro.serie_nfe == null ? '' : resp.registro.serie_nfe,

            PontoColeta: resp.registro.pontos_coleta_id.id,
            ResiduoRejeito: resp.registro.residuo_rejeito_id.id,
            DataEntrada: resp.registro.data_entrada,
            Descricao: resp.registro.descricao == null ? '' : resp.registro.descricao,
            Quantidade: resp.registro.quantidade,
            UnidadeQuantidade: resp.registro.unidade_quantidade_id.nome,
            UnidadeQuantidadeId: resp.registro.unidade_quantidade_id.id,

            QuantidadeAtual: resp.registro.quantidade,
            ValorEquivalente: resp.registro.equivalencia,
            UnidadeEquivalencia: resp.registro.unidade_equivalencia_id.nome,
            UnidadeEquivalenciaId: resp.registro.unidade_equivalencia_id.id,

            atraso: resp.data_em_atraso,
            PontosColetas: resp.pontos_coletas,
            TipoMateriais: resp.tipo_material,
            Todos_Responsaveis: resp.responsaveis,
            Responsaveis: responsaveis,
            TipoQuantidade: resp.registro.residuo_rejeito_id.unidade_principal.tipo,
            Residuos: ponto_selecionado[0].residuos,
            Rejeitos: ponto_selecionado[0].rejeitos,
            PrincipalUnity: residuo_selecionado.unidade_principal.sigla,
            SecondaryUnity: residuo_selecionado.unidade_secundaria.sigla,
            PrincipalMaxAmount,
            SecondaryMaxAmount,

          });
        }
      } catch (err) {
        this.setState({ redirect: true });
        console.log(err);
      }
    })
      .catch((err) => {
        this.setState({ redirect: true });
        console.log(err);
      });


  }



  // // insere dados no banco  botão salvar-----------------------------
  salvar() {

    let form = new FormData();
    form.append('entrada_id', this.props.match.params.id);
    form.append('material_agrupado', this.state.MaterialAgrupado);
    form.append('responsavel_id', this.state.Responsavel);
    form.append('rastreamento', this.state.Rastreamento);
    form.append('dados_rastreamento', this.state.DadosRastreamento);
    form.append('tipo_material', this.state.TipoMaterial);
    form.append('chave_nfe', this.state.ChaveNota);
    form.append('numero_nfe', this.state.NumeroNota);
    form.append('serie_nfe', this.state.SerieNota);
    form.append('pontos_coleta_id', this.state.PontoColeta);
    form.append('residuo_rejeito_id', this.state.ResiduoRejeito);
    form.append('data_entrada', this.state.DataEntrada);
    form.append('descricao', this.state.Descricao);
    form.append('quantidade', this.state.Quantidade);
    form.append('unidade_quantidade', this.state.UnidadeQuantidade);
    form.append('unidade_quantidade_id', this.state.UnidadeQuantidadeId);

    form.append('equivalencia', this.state.ValorEquivalente);
    form.append('unidade_equivalencia', this.state.UnidadeEquivalencia);
    form.append('unidade_equivalencia_id', this.state.UnidadeEquivalenciaId);

    form.append('data_em_atraso', this.state.atraso);

    console.log(this.state.DadosRastreamento);
    console.log(Object.fromEntries(form));
    this.setState({ loading_save: true, msgErro: '' });

    fetch(`${URL}api/update_entrada_coleta`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${this.props.token}`,
      },
      body: form
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);
          // document.getElementsByClassName('app')[0].scrollTo({ top: 0, behavior: 'smooth' });
          window.scrollTo(500, 0);
          this.setState({ loading_save: false, msgErro: erro });
        }
        else {
          this.setState({ loading_save: false, redirect: true });
        }
      } catch (err) {
        console.log(err);
        this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });
      }
    })
      .catch((err) => {
        console.log(err);
        this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
      });
  }

  getTooltipData = (pontoSelecionado, residuoSelecionado) => {
    let PrincipalMaxAmount = ''
    let SecondaryMaxAmount = ''

    if (residuoSelecionado.unidade_principal.tipo === 'Peso') {
      PrincipalMaxAmount = pontoSelecionado.capacidade_maxima_peso / residuoSelecionado.unidade_principal.equivalencia
      SecondaryMaxAmount = pontoSelecionado.capacidade_maxima_volume / residuoSelecionado.unidade_secundaria.equivalencia
    } else {
      PrincipalMaxAmount = pontoSelecionado.capacidade_maxima_volume / residuoSelecionado.unidade_principal.equivalencia
      SecondaryMaxAmount = pontoSelecionado.capacidade_maxima_peso / residuoSelecionado.unidade_secundaria.equivalencia
    }

    return { PrincipalMaxAmount, SecondaryMaxAmount }
  }

  render() {
    return (
      <div className="row">
        {this.state.redirect == true && <Redirect to={this.state.path} />}

        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">


              <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar registro de entrada </h4>
              <p style={{ fontSize: '1rem', color: 'black' }}>Edite um registro de entrada</p>
              <br />

              {/* <hr /> */}
              {this.state.loading_screen == false &&
                <div>{this.state.msgErro != '' && <div>
                  <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>
                  </div>
                  <br />
                </div>}

                  <div className="row">
                    <div className="col-12">


                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Ponto de coleta<b style={{ color: 'red' }}>*</b></label>
                        <div className="col-sm-12">
                          <select disabled value={this.state.PontoColeta} className="form-control"
                            onChange={(e) => {
                              if (e.target.value == '') {
                                this.setState({
                                  PontoColeta: '',
                                  Residuos: [],
                                  Rejeitos: [],
                                  Quantidade: '',
                                  ResiduoRejeito: '',
                                  ValorEquivalente: '',
                                  Equivalencia: '',
                                  UnidadeQuantidade: 'un',
                                  UnidadeEquivalencia: 'un',
                                  UnidadeQuantidadeId: '',
                                  UnidadeEquivalenciaId: '',
                                  Responsaveis: []
                                });
                              } else {
                                let ponto_selecionado = { ...this.state.PontosColetas.filter(p => p.id == e.target.value) };
                                let [autorizados] = this.state.PontosColetas.filter((item) => item.id == e.target.value).map(item => item.pessoas);
                                let responsaveis = [];
                                for (let i = 0; i < autorizados.length; i++) {
                                  for (let j = 0; j < this.state.Todos_Responsaveis.length; j++) {
                                    if (autorizados[i].user_id == this.state.Todos_Responsaveis[j].id) {
                                      responsaveis.push(this.state.Todos_Responsaveis[j]);
                                      j = this.state.Todos_Responsaveis.length
                                    }
                                  }
                                }
                                this.setState({
                                  PontoColeta: e.target.value,
                                  Residuos: ponto_selecionado[0].residuos,
                                  Rejeitos: ponto_selecionado[0].rejeitos,
                                  Responsaveis: responsaveis,
                                  Quantidade: '',
                                  ResiduoRejeito: '',
                                  ValorEquivalente: '',
                                  Equivalencia: '',
                                  UnidadeQuantidade: 'un',
                                  UnidadeEquivalencia: 'un',
                                  UnidadeQuantidadeId: '',
                                  UnidadeEquivalenciaId: '',
                                  Capacidade_Maxima_peso: ponto_selecionado[0].capacidade_maxima_peso,
                                  Capacidade_Maxima_un: ponto_selecionado[0].unidade_peso[0].sigla,
                                });
                              }
                            }}>
                            <option value={''}>Selecione uma opção</option>
                            {this.state.PontosColetas.map((item, id) => (<option key={id} value={item.id}>{item.nome_fantasia}</option>))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Tipo de material<b style={{ color: 'red' }}>*</b></label>
                        <div className="col-sm-12">
                          <select disabled value={this.state.TipoMaterial} className="form-control"
                            onChange={(e) => {
                              console.log(this.state.Residuos);
                              console.log(this.state.Rejeitos);
                              this.setState({
                                ResiduoRejeito: '',
                                TipoMaterial: e.target.value
                              })
                            }}>
                            <option value={'Resíduo'}>Resíduo</option>
                            <option value={'Rejeito'}>Rejeito</option>
                          </select>
                        </div>
                      </div>
                      {this.state.TipoMaterial == 'Resíduo' &&
                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">Resíduo<b style={{ color: 'red' }}>*</b></label>
                          <div className="col-sm-12">
                            <select disabled value={this.state.ResiduoRejeito} className="form-control"
                              onChange={(e) => {
                                if (e.target.value == '') {
                                  this.setState({
                                    Quantidade: '',
                                    ResiduoRejeito: '',
                                    ValorEquivalente: '',
                                    Equivalencia: '',
                                    UnidadeQuantidade: 'un',
                                    UnidadeEquivalencia: 'un',
                                    UnidadeQuantidadeId: '',
                                    UnidadeEquivalenciaId: ''
                                  });
                                } else {
                                  let residuo_id = e.target.value;
                                  let residuo_selecionado = { ...this.state.Residuos.filter(residuo => residuo.id == residuo_id) };
                                  let pontoSelecionado = this.state.PontosColetas.find(item => item.id === this.state.PontoColeta)

                                  const { PrincipalMaxAmount, SecondaryMaxAmount } = this.getTooltipData(pontoSelecionado, residuo_selecionado[0])

                                  this.setState({
                                    ResiduoRejeito: e.target.value,
                                    ValorEquivalente: residuo_selecionado[0].equivalencia,
                                    UnidadeQuantidade: residuo_selecionado[0].unidade_principal.nome,
                                    UnidadeQuantidadeId: residuo_selecionado[0].unidade_principal.id,
                                    Equivalencia: residuo_selecionado[0].equivalencia,
                                    UnidadeEquivalencia: residuo_selecionado[0].unidade_secundaria.nome,
                                    UnidadeEquivalenciaId: residuo_selecionado[0].unidade_principal.id,
                                    PrincipalUnity: residuo_selecionado[0].unidade_principal.sigla,
                                    SecondaryUnity: residuo_selecionado[0].unidade_secundaria.sigla,
                                    PrincipalMaxAmount,
                                    SecondaryMaxAmount,

                                  });
                                }
                              }}>
                              <option value={''}>Selecione uma opção</option>
                              {this.state.Residuos.map((item, id) => (<option key={id} value={item.id}>{item.nome}</option>))}
                            </select>
                          </div>
                        </div>}
                      {this.state.TipoMaterial == 'Rejeito' &&
                        <div className="form-group row">
                          <label className="col-sm-12 col-form-label">Rejeito<b style={{ color: 'red' }}>*</b></label>
                          <div className="col-sm-12">
                            <select disabled value={this.state.ResiduoRejeito} className="form-control"
                              onChange={(e) => {
                                if (e.target.value == '') {
                                  this.setState({
                                    Quantidade: '',
                                    ResiduoRejeito: '',
                                    ValorEquivalente: '',
                                    Equivalencia: '',
                                    UnidadeQuantidade: 'un',
                                    UnidadeEquivalencia: 'un',
                                    UnidadeQuantidadeId: '',
                                    UnidadeEquivalenciaId: ''
                                  });
                                } else {
                                  let rejeito_id = e.target.value;
                                  let rejeito_selecionado = { ...this.state.Rejeitos.filter(rejeito => rejeito.id == rejeito_id) };
                                  let pontoSelecionado = this.state.PontosColetas.find(item => item.id === this.state.PontoColeta)

                                  const { PrincipalMaxAmount, SecondaryMaxAmount } = this.getTooltipData(pontoSelecionado, rejeito_selecionado[0])

                                  this.setState({
                                    ResiduoRejeito: e.target.value,
                                    ValorEquivalente: rejeito_selecionado[0].equivalencia,
                                    UnidadeQuantidade: rejeito_selecionado[0].unidade_principal.nome,
                                    Equivalencia: rejeito_selecionado[0].equivalencia,
                                    UnidadeEquivalencia: rejeito_selecionado[0].unidade_secundaria.nome,
                                    UnidadeQuantidadeId: rejeito_selecionado[0].unidade_principal.id,
                                    UnidadeEquivalenciaId: rejeito_selecionado[0].unidade_secundaria.id,
                                    PrincipalUnity: rejeito_selecionado[0].unidade_principal.sigla,
                                    SecondaryUnity: rejeito_selecionado[0].unidade_secundaria.sigla,
                                    PrincipalMaxAmount,
                                    SecondaryMaxAmount,
                                  });
                                }
                              }}>
                              <option value={''}>Selecione uma opção</option>
                              {this.state.Rejeitos.map((item, id) => (<option key={id} value={item.id}>{item.nome}</option>))}
                            </select>
                          </div>
                        </div>}
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Data de entrada <b style={{ color: 'red' }}>*</b></label>
                        <div className="col-sm-12">
                          <input className="form-control" value={this.state.DataEntrada} disabled type="date" style={{ maxWidth: 168 }} max={this.state.DataAtual} onChange={(e) => {
                            if (e.target.value > this.state.DataAtual) {
                              e.target.value = this.state.DataAtual
                            }
                            e.target.value < this.state.DataAtual ? this.setState({ atraso: 'sim' }) : this.setState({ atraso: 'não' });
                            this.setState({ DataEntrada: e.target.value });
                          }} />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Descrição </label>
                        <div className="col-sm-12">
                          <textarea className="form-control" placeholder="Descrição" value={this.state.Descricao} type="text" onChange={(e) => { this.setState({ Descricao: e.target.value }) }} />
                        </div>
                      </div>
                      <div className="form-group row">

                      </div>
                      <div className="form-group  row">
                        <div className=" col-sm-12">
                          {this.state.ResiduoRejeito != '' && <div className="row">
                            <div className="col-md-6 col-12">
                              <label className="col-sm-12 col-form-label">Quantidade <b style={{ color: 'red' }} >*  </b>
                                <IoIosInformationCircleOutline data-tip={`Capacidade Máxima: ${maskNumberBr(this.state.PrincipalMaxAmount + '')} ${this.state.PrincipalUnity} - ${maskNumberBr(this.state.SecondaryMaxAmount + '')} ${this.state.SecondaryUnity}`} />
                                <ReactTooltip place="bottom" effect="float" />
                              </label>

                              <div className="input-group mb-3">
                                <NumericFormat type="text" className="form-control"
                                  value={this.state.Quantidade}
                                  aria-describedby="basic-addon3"
                                  valueIsNumericString
                                  thousandsGroupStyle='thousand'
                                  thousandSeparator="."
                                  decimalScale={2}
                                  decimalSeparator=","
                                  onValueChange={(values, e) => {
                                    const { PontoColeta, ResiduoRejeito, PontosColetas, Equivalencia } = this.state;
                                    const value = values.value

                                    let message = '';
                                    let CalcEstoque = 0;
                                    let pontoSelecionado = PontosColetas.find(item => item.id === PontoColeta);
                                    let residuoRejeito = pontoSelecionado.residuos_rejeitos.find(item => item.id === ResiduoRejeito);
                                    let equivalencia = residuoRejeito.unidade_principal.equivalencia;
                                    let estoque = [...this.state.Estoque.filter(item => item.ponto_coleta_id == this.state.PontoColeta && item.material_id == this.state.ResiduoRejeito)];

                                    const pesoMaximo = parseFloat(pontoSelecionado.capacidade_maxima_peso) * parseFloat(pontoSelecionado.unidade_peso[0].equivalencia);
                                    const volumeMaximo = parseFloat(pontoSelecionado.capacidade_maxima_volume) * parseFloat(pontoSelecionado.unidade_volume[0].equivalencia);

                                    console.log('residuoRejeito', residuoRejeito)

                                    if (residuoRejeito.unidade_principal.tipo === 'Peso') {
                                      CalcEstoque = (parseFloat(value) - parseFloat(this.state.QuantidadeAtual)) + (parseFloat(estoque.peso_disponivel));

                                      if ((parseFloat(value) * parseFloat(residuoRejeito.equivalencia)) * residuoRejeito.unidade_secundaria.equivalencia > volumeMaximo) {
                                        message = `Total de volume maior que capacidade do ponto de coleta (${maskNumberBr(volumeMaximo)} L)`;
                                      }

                                      if (value * equivalencia > pesoMaximo) {
                                        message = `Total de peso maior que capacidade do ponto de coleta (${maskNumberBr(pesoMaximo)} Kg)`;
                                      }
                                    }

                                    if (residuoRejeito.unidade_principal.tipo === 'Volume') {
                                      CalcEstoque = (parseFloat(value) - parseFloat(this.state.QuantidadeAtual)) + (parseFloat(estoque.volume_disponivel));

                                      if ((parseFloat(value) * parseFloat(residuoRejeito.equivalencia)) * residuoRejeito.unidade_secundaria.equivalencia > pesoMaximo) {
                                        message = `Total de peso maior que capacidade do ponto de coleta (${maskNumberBr(pesoMaximo)} Kg)`;
                                      }

                                      if (value * equivalencia > volumeMaximo) {
                                        message = `Total de volume maior que capacidade do ponto de coleta (${maskNumberBr(volumeMaximo)} L)`;
                                      }
                                    }

                                    this.setState({
                                      QuantityError: message || false,
                                      CalcEstoque: CalcEstoque || 0,
                                      Quantidade: value,
                                      ValorEquivalente: value * Equivalencia
                                    });
                                  }} />
                                <span className="input-group-text" id="basic-addon3" style={{ fontWeight: 'bold' }}>{this.state.UnidadeQuantidade}</span>
                                {this.state.QuantityError && <b style={{ color: 'red', fontSize: '12px' }}>{this.state.QuantityError}</b>}
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <label className="col-sm-12 col-form-label">Equivalência <b style={{ color: 'red' }}>*</b></label>
                              <div className="input-group mb-3">
                                <NumericFormat type='text' className="form-control" disabled
                                  valueIsNumericString
                                  thousandsGroupStyle='thousand'
                                  thousandSeparator="."
                                  decimalScale={2}
                                  placeholder={'Valor'}
                                  decimalSeparator=","
                                  value={this.state.ValorEquivalente} aria-describedby="basic-addon3" />
                                <span className="input-group-text" id="basic-addon3" style={{ fontWeight: 'bold' }}>{this.state.UnidadeEquivalencia}</span>
                              </div>
                            </div>
                          </div>}
                        </div>
                      </div>
                      {/* <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Material agrupado em lote<b style={{ color: 'red' }}>*</b></label>
                        <div className="col-sm-12">
                          <select value={this.state.MaterialAgrupado} className="form-control"
                            onChange={(e) => {
                              this.setState({ MaterialAgrupado: e.target.value })
                            }}>
                            <option value={''}>Selecione uma opção</option>
                            <option value={'Sim'}>Sim</option>
                            <option value={'Não'}>Não</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Rastreamento<b style={{ color: 'red' }}>*</b></label>
                        <div className="col-sm-12">
                          <select value={this.state.Rastreamento} className="form-control"
                            onChange={(e) => {
                              if (e.target.value == 'Não') this.setState({ DadosRastreamento: '' });
                              this.setState({ Rastreamento: e.target.value });
                            }}>
                            <option value={''}>Selecione uma opção</option>
                            <option value={'Sim'}>Sim</option>
                            <option value={'Não'}>Não</option>
                          </select>
                        </div>
                      </div>
                      {this.state.Rastreamento == 'Sim' && <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Dados do rastreamento<b style={{ color: 'red' }}>*</b></label>
                        <div className="col-sm-12">
                          <input className="form-control" placeholder="Dados do Rastreamento" value={this.state.DadosRastreamento} type="text" onChange={(e) => {
                            this.setState({ DadosRastreamento: e.target.value })
                          }} />
                        </div>
                      </div>}
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Chave da NF-e</label>
                        <div className="col-sm-12">
                          <input className="form-control" placeholder="Chave de acesso da nota fiscal" value={this.state.ChaveNota} type="text" onChange={(e) => { this.setState({ ChaveNota: e.target.value }) }} />
                        </div>
                      </div>
                      <div className="form-group  row">
                        <div className=" col-sm-12">
                          <div className="row">
                            <div className="col-md-8 col-12">
                              <label className="col-sm-12 col-form-label">Número da NF-e </label>
                              <div className="col-sm-12">
                                <input className="form-control" placeholder="Número da nota fiscal" type="text" onChange={(e) => this.setState({ NumeroNota: e.target.value })} value={this.state.NumeroNota} />
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <label className="col-sm-12 col-form-label">Série da NF-e </label>
                              <div className="col-sm-12">
                                <input className="form-control" placeholder="Série da nota fiscal" type="text" onChange={(e) => this.setState({ SerieNota: e.target.value })} value={this.state.SerieNota} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">Responsável<b style={{ color: 'red' }}>*</b></label>
                        <div className="col-sm-12">
                          <div className="input-group">
                            <select value={this.state.Responsavel} disabled={this.state.PontoColeta == ''} className="form-control"
                              onChange={(e) => {
                                this.setState({ Responsavel: e.target.value })
                              }}>
                              <option value={''}>Selecione uma opção</option>
                              {this.state.Responsaveis.map((item, id) => (<option key={id} value={item.id}>{item.name}</option>))}
                            </select>
                            <UsuarioModal disabled={this.state.PontoColeta == ''} reload={(e) => this.get_Registro(e)} id={this.props.match.params.id} ponto_id={this.state.PontoColeta} />
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>


                  {/*-------------- loadings -------------*/}
                  {this.state.loading_save == false &&
                    <div className="row mt-3">
                      <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start" style={{ marginBottom: '0.5rem' }}>
                        <Link to="/entrada_coleta"><span className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                      </div>
                      <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end" style={{ marginBottom: '0.5rem' }}>
                        <button type="button" onClick={() => {
                          if (this.state.CalcEstoque >= 0) {
                            this.salvar();
                          } else {
                            window.scrollTo(500, 0);
                            this.setState({ msgErro: 'A operação não pode ser realizada, pois o estoque não pode ser negativo.' })
                          }
                        }} disabled={this.state.QuantityError} className="btn btn-success btn-lg waves-effect waves-light" style={{ backgorundColor: '#f2f2f2' }}>Salvar entrada</button>
                      </div>
                    </div>}

                  {this.state.loading_save == true &&
                    <div className="row">
                      <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                        <div className="spinner-border text-primary" role="status" ></div>
                      </div>
                    </div>}




                </div>}
              {this.state.loading_screen == true &&
                <div className="row">
                  <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status" ></div>
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    );
  }


}
const mapsStateToProps = (state) => (
  {
    token: state.AppReducer.token,
  }
);

export default connect(mapsStateToProps, { logout })(CriarDestinos);


