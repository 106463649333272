import React from 'react'
import { GoKebabHorizontal } from "react-icons/go";
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from '@mui/material'
import { GrDocumentText } from 'react-icons/gr';

const OptionsButton = ({ Icon, tooltipTitle, items }) => {
  const [anchorButton, setAnchorButton] = React.useState(null)

  const handleClose = () => setAnchorButton(null)
  const handleOpen = (event) => setAnchorButton(event.currentTarget)

  const handleClick = (callback) => {
    setAnchorButton(null)
    callback()
  }

  return (
    <Box sx={{ flexGrow: 0 }}>
      {tooltipTitle ?
        <Tooltip title={tooltipTitle}>
          <IconButton sx={{ padding: 0 }} onClick={handleOpen}>
            {Icon}
          </IconButton>
        </Tooltip>
        :
        <IconButton sx={{ padding: 0 }} onClick={handleOpen}>
          {Icon}
        </IconButton>}
      {/* -------------------------Dialog-section------------------------- */}
      <Menu
        sx={{ mt: '40px' }}
        id="menu-appbar"
        anchorEl={anchorButton}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorButton)}
        onClose={handleClose}
      >
        {items.map(item => {
          if (!item) {
            return
          }

          return (
            <MenuItem key={item.label} disabled={item.disabled} onClick={() => handleClick(item.action)}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}

OptionsButton.defaultProps = {
  Icon: <GoKebabHorizontal />,
  tooltipTitle: '',
  items: [{ label: '', icon: <GrDocumentText />, action: () => console.log('callback') }]
}

export default OptionsButton