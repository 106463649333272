
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import { BsPlusLg } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';

// users
import { URL } from '../../../variables';
import { MdReply } from 'react-icons/md';

class EditarAcondicionamento extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/acondicionamentos',
            loading_screen: false,
            codigo: '',
            descricao: '',
            loading_save: false,
            msgErro: '',
            e_sigor: false,
            acondicionamento_id: '',
            acondicionamentos: [],
            acondicionamentos_selecionados: [],
        };
    }



    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/update_acondicionamentos`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                codigo: this.state.codigo,
                descricao: this.state.descricao,
                acondicionamento_id: this.props.match.params.id,
                e_sigor: this.state.e_sigor,
                acondicionamentos_selecionados: this.state.acondicionamentos_selecionados
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i !== errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_acondicionamento(this.props.match.params.id);
        // this.fill_acondicionamentos();
    }

    get_acondicionamento(id) {

        if (this.state.loading_screen === false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_acondicionamento/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message === "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i !== errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false,
                        codigo: resp.acondicionamento.codigo,
                        descricao: resp.acondicionamento.descricao,
                        e_sigor: resp.acondicionamento.e_sigor,
                        acondicionamentos_selecionados: resp.acondicionamento.acondicionamentos_sigor,
                        acondicionamentos: resp.acondicionamentos,
                        acondicionamento_id: resp.acondicionamentos[0].id
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    fill_acondicionamentos() {
        fetch(`${URL}api/fill_acondicionamentos_sigor`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.props.token}`
            }
        }).then(async (response) => {
            const resp = await response.json()
            console.log('resp tratamentos', resp)
            this.setState({ acondicionamentos: resp.acondicionamentos, acondicionamento_id: resp.acondicionamentos[0].id })
        })
    }

    add_acondicionamento(id) {
        if (!this.state.acondicionamentos_selecionados.filter(item => item.dados_acondicionamento.id === id)[0]) {
            const acondicionamento = this.state.acondicionamentos.filter(item => item.id === id)[0]

            const obj_acondicionamento = {
                id: acondicionamento.id,
                descricao: acondicionamento.descricao,
                codigo: acondicionamento.codigo
            }

            this.setState({ acondicionamentos_selecionados: [...this.state.acondicionamentos_selecionados, { dados_acondicionamento: obj_acondicionamento }] })
        }
    }

    remove_acondicionamento(id) {
        const acondicionamentos_selecionados = this.state.acondicionamentos_selecionados.filter(item => item.dados_acondicionamento.id !== id)
        this.setState({ acondicionamentos_selecionados })
    }

    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect === true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar acondicionamento</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um acondicionamento do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen === false && <div>
                                {this.state.msgErro !== '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Código <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Código do acondicionamento" type="text" onChange={(e) => this.setState({ codigo: e.target.value })} value={this.state.codigo} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Descrição <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <textarea className="form-control" placeholder="Descrição do acondicionamento" type="text" onChange={(e) => this.setState({ descricao: e.target.value })} value={this.state.descricao} disabled={this.state.e_sigor}></textarea>
                                            </div>
                                        </div>

                                        {this.state.e_sigor && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Acondicionamento Relacionados:</label>
                                            <div className="input-group col-sm-12">
                                                <select value={this.state.acondicionamento_id} className="form-control" id="cameras" onChange={(e) => this.setState({ acondicionamento_id: e.target.value })}>
                                                    {this.state.acondicionamentos.map(item => (
                                                        <>
                                                            {!item.relation && <option key={item.id} value={item.id}>{item.descricao}</option>}
                                                        </>
                                                    ))}
                                                </select>
                                                <button className='btn btn-primary' onClick={() => this.add_acondicionamento(this.state.acondicionamento_id)}>
                                                    <BsPlusLg />
                                                </button>
                                            </div>
                                        </div>}

                                        {this.state.acondicionamentos_selecionados.length > 0 &&
                                            <div className="row my-3">
                                                <div className="col-12">
                                                    <table className='table table-striped table-hover text-center'>
                                                        <thead>
                                                            <tr>
                                                                <th>Nome</th>
                                                                <th>Codigo</th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.acondicionamentos_selecionados.map(item2 => (
                                                                <tr key={item2.dados_acondicionamento.id}>
                                                                    <td>{item2.dados_acondicionamento.descricao}</td>
                                                                    <td>{item2.dados_acondicionamento.codigo}</td>
                                                                    <td><button className="btn btn-danger btn-small btn-circle m-r-5"
                                                                        onClick={() => this.remove_acondicionamento(item2.dados_acondicionamento.id)}>
                                                                        <FaTrash color='white' />
                                                                    </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>}

                                    </div>

                                </div>

                                {this.state.loading_save === false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/acondicionamentos"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar acondicionamento</button>
                                    </div>
                                </div>}
                                {this.state.loading_save === true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento === true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen === true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(EditarAcondicionamento);


