import React, { Component } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// transportadoras
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdSearch } from 'react-icons/md';
import { BsXLg, BsCheckLg } from 'react-icons/bs';
import { CircularProgress } from '@mui/material';
import Filter from '../../../Auxiliar/Filter';

class Transportadoras extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            transportadoras: [],
            page: 1,
            total_pages: 1,
            msgErro: '',
            show_warning: false,
            show_erro: false,
            transportadorato_delete: '',
            search: '',
            loading_search: false,
            list_loading: [],
            dateOf: '',
            dateFor: '',
            reload: false,
        };
    }

    componentDidMount() {
        this.get_transportadoras(this.state.page);
        this.props.setSearch(false)
    }

    componentDidUpdate() {
        if (this.props.search) {
            this.props.setSearch(false)
            this.get_transportadoras(this.state.page);
        }
    }

    get_transportadoras(page, search = '', busca = false) {
        if (busca == true) {
            if (this.state.loading_search == false) {
                this.setState({ loading_search: true });
            }
        } else if (this.state.loading_screen === false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_transportadoras?page=${page}&search=${search}&dateOf=${this.props.dateOf}&dateFor=${this.props.dateFor}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            },
        })
            .then(async (responseLog) => {
                try {
                    let resp = await responseLog.json();
                    console.log(resp);
                    if (resp.message == 'Unauthenticated.') {
                        localStorage.removeItem('token');
                        localStorage.removeItem('user');
                        this.props.logout();
                        return;
                    }
                    if (resp.errors != null || resp.error != null) {
                        let errors = Object.values(resp.errors);
                        let erro = '';
                        for (let i = 0; i < errors.length; i++) {
                            if (i != errors.length - 1) erro += errors[i] + '\n';
                            else erro += errors[i];
                        }
                        console.log(erro);
                        this.setState({ redirect: true });
                    } else {
                        this.setState({
                            loading_search: false,
                            transportadoras: resp.transportadoras,
                            loading_screen: false,
                            page: resp.pagination.current_page,
                            total_pages: resp.pagination.last_page,
                        });
                    }
                } catch (err) {
                    this.setState({ redirect: true });
                    console.log(err);
                }
            })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    delete_transportadora(id) {
        fetch(`${URL}api/delete_transportadora/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            },
        })
            .then(async (responseLog) => {
                try {
                    let resp = await responseLog.json();
                    console.log(resp);
                    if (resp.message == 'Unauthenticated.') {
                        localStorage.removeItem('token');
                        localStorage.removeItem('user');
                        // this.setState({redirect:true,path:'/'});
                        this.props.logout();
                        return;
                    }
                    if (resp.errors != null || resp.error != null) {
                        let errors = Object.values(resp.errors);
                        let erro = '';
                        for (let i = 0; i < errors.length; i++) {
                            if (i != errors.length - 1) erro += errors[i] + '\n';
                            else erro += errors[i];
                        }
                        console.log(erro);
                        this.setState({
                            msgErro: erro,
                            show_warning: false,
                            show_erro: true,
                        });
                    } else {
                        this.setState({ show_warning: false });
                        this.get_transportadoras(this.state.page);
                    }
                } catch (err) {
                    this.setState({ redirect: true });
                    console.log(err);
                }
            })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
            });
    }

    changeStatus(id, status) {
        this.changeLoading(id, true);
        fetch(`${URL}api/update_status_transportadoras`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ status: status, id: id }),
        }).then(async (responseLog) => {
            let resp = await responseLog.json();
            let transportadoras = this.state.transportadoras.map(item => {
                if (item.id == id) item.inativo = !item.inativo
                return item
            })
            this.setState({ transportadoras })
            this.changeLoading(id, false);
        });
    }

    changeLoading(id, bool) {
        let list_loading = { ...this.state.list_loading }
        list_loading[id] = bool
        this.setState({ list_loading })
    }

    render() {
        console.log(this.state.total_pages);
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    {this.state.loading_screen == false && (
                        <div>
                            <div className="row mb-2">
                                <div className="col-12 col-md-6">
                                    <form
                                        className="app-search mt-1"
                                        style={{ width: '100%' }}
                                        onSubmit={(e) => {
                                            clearTimeout(this.pesquisarTimeout);
                                            this.setState({ page: 1 });
                                            this.get_transportadoras(1, this.state.search, true);
                                            e.preventDefault();
                                        }}
                                    >
                                        <div
                                            className="form-group mb-0"
                                            style={{ width: '100%', position: 'relative' }}
                                        >
                                            <input
                                                value={this.state.search}
                                                name="search"
                                                placeholder="Pesquisar pelo Razão social, Nome fantasia ou Responsável..."
                                                className="form-control"
                                                style={{
                                                    width: '100%',
                                                    paddingRight: 40,
                                                    borderRadius: 100,
                                                }}
                                                onChange={(e) => {
                                                    clearTimeout(this.pesquisarTimeout);
                                                    let search = e.target.value;
                                                    this.setState({ page: 1, search: search });
                                                    this.pesquisarTimeout = setTimeout(() => {
                                                        this.get_transportadoras(1, search, true);
                                                    }, 500);
                                                }}
                                            />
                                            <div
                                                style={{
                                                    width: 40,
                                                    position: 'absolute',
                                                    right: 0,
                                                    top: 0,
                                                    bottom: 0,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <MdSearch />
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                {this.props.user.tipo_usuario.permissoes['transportadoras']
                                    .escrita == true && (
                                        <div className="col-12 col-md-6 d-flex justify-content-end">
                                            <Link to="/transportadoras/criar">
                                                <span className="btn btn-primary btn-lg waves-effect waves-light mt-1">
                                                    Adicionar transportadora
                                                </span>
                                            </Link>
                                        </div>
                                    )}
                            </div>
                            <div className="table-rep-plugin">
                                <div
                                    className="table-responsive mb-0"
                                    data-pattern="priority-columns"
                                >
                                    <table
                                        id="tech-companies-1"
                                        className="table table-striped table-hover"
                                        style={{ textAlign: 'center' }}
                                    >
                                        <thead>
                                            <tr>
                                                <th>Razão social</th>
                                                <th>Nome fantasia</th>
                                                <th>Responsável</th>

                                                <th data-priority="3">Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.loading_search == false &&
                                                this.state.transportadoras.map((item, id) => {
                                                    return (
                                                        <tr key={id}>
                                                            <td>{item.razao_social}</td>
                                                            <td>{item.nome_fantasia}</td>

                                                            <td>{item.responsavel.name}</td>

                                                            <td>
                                                                {this.props.user.tipo_usuario.permissoes[
                                                                    'transportadoras'
                                                                ].escrita == true && (
                                                                        <Link
                                                                            to={'/transportadoras/editar/' + item.id}
                                                                        >
                                                                            <span
                                                                                className="btn btn-info btn-outline btn-circle me-1 mb-1"
                                                                                data-tip="Editar"
                                                                            >
                                                                                <Tooltip />
                                                                                <MdModeEdit />
                                                                            </span>
                                                                        </Link>
                                                                    )}
                                                                {this.props.user.tipo_usuario.permissoes[
                                                                    'transportadoras'
                                                                ].exclusao == true && (
                                                                        <button
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    show_warning: true,
                                                                                    transportadorato_delete: item.id,
                                                                                });
                                                                            }}
                                                                            className="destroy_it btn btn-danger btn-outline btn-circle me-1 mb-1"
                                                                            data-tip="Excluir"
                                                                        >
                                                                            <Tooltip />
                                                                            <MdDelete />
                                                                        </button>
                                                                    )}
                                                                {this.props.user.tipo_usuario.permissoes[
                                                                    'transportadoras'
                                                                ].exclusao == true && !item.inativo ? (
                                                                    <button
                                                                        className="btn btn-secondary btn-outline btn-circle me-1 mb-1"
                                                                        data-tip="Inativar"
                                                                        onClick={() =>
                                                                            this.changeStatus(item.id, true)
                                                                        }
                                                                    >
                                                                        {this.state.list_loading[item.id] ? (
                                                                            <CircularProgress
                                                                                color="inherit"
                                                                                size={16}
                                                                            />
                                                                        ) : (
                                                                            <BsXLg />
                                                                        )}
                                                                    </button>
                                                                ) : (
                                                                    <button
                                                                        className="btn btn-secondary btn-outline btn-circle me-1 mb-1"
                                                                        data-tip="Ativar"
                                                                        onClick={() =>
                                                                            this.changeStatus(item.id, false)
                                                                        }
                                                                    >
                                                                        {this.state.list_loading[item.id] ? (
                                                                            <CircularProgress
                                                                                color="inherit"
                                                                                size={16}
                                                                            />
                                                                        ) : (
                                                                            <BsCheckLg />
                                                                        )}
                                                                    </button>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                    {this.state.loading_search == true && (
                                        <div className="row" style={{ margin: 0 }}>
                                            {' '}
                                            <div className="col-12 d-flex justify-content-center align-items-center">
                                                <div
                                                    className="spinner-border text-primary"
                                                    role="status"
                                                ></div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {this.state.loading_search == false &&
                                this.state.transportadoras.length == 0 && (
                                    <div className="row w-100">
                                        <div className="col-12">
                                            <p style={{ textAlign: 'center' }}>
                                                Nenhuma transportadora cadastrada
                                            </p>
                                        </div>
                                    </div>
                                )}
                            <div className="row">
                                <div className="col-12 d-flex justify-content-end">
                                    <ReactPaginate
                                        previousLabel={'Anterior'}
                                        nextLabel={'Próxima'}
                                        breakLabel={'...'}
                                        breakClassName={'break-me'}
                                        pageCount={this.state.total_pages}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={2}
                                        onPageChange={this.handlePageClick.bind(this)}
                                        containerClassName={'pagination'}
                                        subContainerClassName={'pages pagination'}
                                        activeClassName={'active'}
                                        initialPage={this.state.page - 1}
                                    />
                                </div>
                            </div>

                            {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}
                        </div>
                    )}

                    {this.state.loading_screen == true && (
                        <div className="row">
                            {' '}
                            <div
                                className="col-12 d-flex justify-content-center align-items-center"
                                style={{ marginTop: '2rem' }}
                            >
                                <div
                                    className="spinner-border text-primary"
                                    role="status"
                                ></div>
                            </div>
                        </div>
                    )}
                    <SweetAlert
                        warning
                        title={'Atenção'}
                        onConfirm={() => {
                            this.delete_transportadora(
                                this.state.transportadorato_delete,
                            );
                        }}
                        onCancel={() => {
                            this.setState({ show_warning: false });
                        }}
                        show={this.state.show_warning}
                        confirmBtnText="Sim, desejo apagar"
                        cancelBtnText="Cancelar"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        showCancel={true}
                    >
                        Ao deletar esta transportadora ela não estará mais disponível no
                        sistema
                    </SweetAlert>
                    <SweetAlert
                        danger
                        title={'Erro'}
                        onConfirm={() => {
                            this.setState({ show_erro: false });
                        }}
                        show={this.state.show_erro}
                        confirmBtnText="OK"
                        confirmBtnBsStyle="primary"
                    >
                        {this.state.msgErro}
                    </SweetAlert>
                </div>
            </div>
        );
    }

    handlePageClick(page) {
        console.log(page.selected);
        if (page.selected + 1 != this.state.page) {
            this.setState({ page: page + 1 });
            this.get_transportadoras(page.selected + 1);
        }
    }
}
const mapsStateToProps = (state) => ({
    user: state.AppReducer.user,
    token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(Transportadoras);
