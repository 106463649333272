import React, { Component } from 'react';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import { maskNumber } from '../../../Auxiliar/Masks';

class EditarUnidade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      redirect: false,
    };
  }

  render() {
    return (
      <div className="row">
        {this.state.redirect == true && <Redirect to={this.props.state.path} />}
        {this.state.loading == true && (
          <div>
            {this.props.state.msgErro != '' && (
              <div>
                <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                  <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.props.setState({ ...this.props.state, msgErro: '' })}>
                  </button>

                  <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>
                    {this.props.state.msgErro}
                  </p>
                </div>
                <br />
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="form-group row">
                  <label className="col-sm-12 col-form-label">Nome <b style={{ color: 'red' }}>*</b></label>
                  <div className="col-sm-12">
                    <input className="form-control" placeholder="Nome da unidade" type="text" onChange={(e) =>
                      this.props.setState({ ...this.props.state, descricao: e.target.value })} value={this.props.state.descricao} disabled />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-12 col-form-label">Sigla <b style={{ color: 'red' }}>*</b></label>
                  <div className="col-sm-12">
                    <input className="form-control" placeholder="Sigla da unidade" type="text" onChange={(e) => this.props.setState({ ...this.props.state, sigla: e.target.value })} value={this.props.state.sigla} disabled />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-12 col-form-label">Tipo <b style={{ color: 'red' }}>*</b></label>
                  <div className="col-sm-12">
                    {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                    <select value={this.props.state.tipo} className="form-control" id="cameras" onChange={(e) => this.props.setState({ ...this.props.state, tipo: e.target.value })}>
                      <option value={'Peso'}>{'Peso'}</option>
                      <option value={'Volume'}>{'Volume'}</option>
                      <option value={'Unidade'}>{'Unidade'}</option>
                    </select>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-12 col-form-label">Em {this.props.state.tipo == 'Peso' ? 'quilogramas' : 'litros'},
                    corresponde à (equivalência):{' '}<b style={{ color: 'red' }}>*</b>
                  </label>
                  <div className="col-sm-12">
                    <input className="form-control" placeholder={'Equivalência em ' + (this.props.state.tipo == 'Peso' ? 'Quilogramas' : 'Litros')} type="text" onChange={(e) => this.props.setState({ ...this.props.state, equivalencia: maskNumber(e.target.value) })} value={this.props.state.equivalencia} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapsStateToProps = (state) => ({
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(EditarUnidade);
