
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout, login, mudarDados } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

// users
import { URL } from '../../../variables';
import { MdReply } from 'react-icons/md';
import { maskCpf, maskTelefone } from '../../../Auxiliar/Masks';
import { IoIosInformationCircleOutline } from "react-icons/io";
import SweetAlert from 'react-bootstrap-sweetalert';

class EditarPessoa extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/pessoas',
            loading_screen: false,
            name: '',
            email: '',
            imagem: '',
            imagem_path: '',
            cpf: '',
            telefone: '',
            segundo_telefone: '',
            password: '',
            confirm_password: '',
            tipo: '',
            tipos: [],
            loading_save: false,
            msgErro: '',
            acessoSistema: false,
            changePassword: false,
            infoChangePassword: false,
            is_open: false,

            acesso: {
                'ContatoDestino': true,
                'ResponsavelPontoColeta': true,
                'PessoaAutorizadaPontoColeta': true,
                'ResponsavelEstoque': true,
                'PessoaAutorizadaEstoque': true,
                'ResponsavelTransportadora': true,
                'ResponsavelRegistroEntradaCT': true,
                'ResponsavelSolicitacaoColeta': true,
                'ResponsavelRegistroEntradaGI': true,
                'NotificacaoIndividual': true
            },

            permissao_notificacao: false
        };
    }



    salvar() {
        let form = new FormData();
        form.append('imagem', this.state.imagem);
        console.log(this.state.imagem);
        form.append('imagem_path', this.state.imagem_path);
        console.log(this.state.imagem_path);
        form.append('name', this.state.name);
        form.append('email', this.state.email);
        form.append('cpf', this.state.cpf);
        form.append('telefone', this.state.telefone);
        form.append('segundo_telefone', this.state.segundo_telefone);
        form.append('acesso', this.state.acessoSistema);
        form.append('pessoa_id', this.props.match.params.id);
        form.append('acessos_modulos', JSON.stringify(this.state.acesso));
        if (this.state.acessoSistema == true) {
            form.append('change_password', this.state.changePassword);
            form.append('password', this.state.password);
            form.append('password_confirmation', this.state.confirm_password);
            form.append('tipo', this.state.tipo);
        }
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/update_pessoa`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {
                    let permissoes = {};
                    let permissoes_app = {};
                    let permissoes_notificacoes = {};

                    if (resp.user.tipo_usuario == undefined) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("user");
                        this.props.logout();
                        return;
                    }
                    for (let i = 0; i < resp.user.tipo_usuario.permissoes.length; i++) {
                        permissoes[resp.user.tipo_usuario.permissoes[i].modulo] =
                            resp.user.tipo_usuario.permissoes[i];
                    }
                    for (
                        let i = 0;
                        i < resp.user.tipo_usuario.permissoes_app.length;
                        i++
                    ) {
                        permissoes_app[resp.user.tipo_usuario.permissoes_app[i].modulo] =
                            resp.user.tipo_usuario.permissoes_app[i];
                    }
                    for (
                        let i = 0;
                        i < resp.user.tipo_usuario.permissoes_notificacoes.length;
                        i++
                    ) {
                        permissoes_notificacoes[resp.user.tipo_usuario.permissoes_notificacoes[i].modulo] =
                            resp.user.tipo_usuario.permissoes_notificacoes[i];
                    }

                    resp.user.tipo_usuario.permissoes = permissoes;
                    resp.user.tipo_usuario.permissoes_app = permissoes_app;
                    resp.user.tipo_usuario.permissoes_notificacoes = permissoes_notificacoes;

                    localStorage.setItem("user", JSON.stringify(resp.user));
                    this.props.login({ token: this.props.token, user: resp.user });

                    this.setState({ loading_save: false, redirect: true });
                }

            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {

        this.get_pessoa(this.props.match.params.id);
    }

    get_pessoa(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_pessoa/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    let object = {};
                    let notificacao_acesso = false
                    if (resp.pessoa.user_dado != null) {
                        object.cpf = resp.pessoa.user_dado.cpf == null ? '' : resp.pessoa.user_dado.cpf;
                        object.telefone = resp.pessoa.user_dado.telefone == null ? '' : resp.pessoa.user_dado.telefone;
                        object.segundo_telefone = resp.pessoa.user_dado.segundo_telefone == null ? '' : resp.pessoa.user_dado.segundo_telefone;
                        object.acessoSistema = resp.pessoa.user_dado.acesso == null ? false : resp.pessoa.user_dado.acesso;
                        object.imagem_path = resp.pessoa.user_dado.imagem == null ? '' : URL + resp.pessoa.user_dado.imagem;
                        object.acesso = Object.values(resp.modulos).length > 0 ? resp.modulos : this.state.acesso

                    }
                    object.tipo = resp.pessoa.tipo_usuario == null ? '' : resp.pessoa.tipo_usuario.id;

                    if (resp.pessoa.tipo_usuario != null) {
                        let item = resp.pessoa.tipo_usuario.permissoes_notificacoes[0]
                        if (item?.has_access) {
                            notificacao_acesso = item?.has_access
                        }
                    }


                    this.setState({
                        loading_screen: false,
                        name: resp.pessoa.name,
                        email: resp.pessoa.email,
                        is_open: resp.pessoa.is_open,
                        tipos: resp.tipos,
                        permissao_notificacao: notificacao_acesso,
                        ...object
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }




    change_tipo(event) {
        this.setState({ tipo: event.target.value });

        fetch(`${URL}api/get_tipo/${event.target.value}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            let resp = await responseLog.json()
            let item = resp.permissoes_notificacoes?.["destinacaointerna.ativar_notificacoes"]
            if (item?.has_access) {
                this.setState({ permissao_notificacao: item?.has_access })
            } else {
                this.setState({ permissao_notificacao: false })
            }
        }).catch((err) => console.log(err))
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar pessoa</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um pessoa do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Imagem</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" type="file" accept="image/*" onChange={(e) => {
                                                    let imagem = e.target.files[0];
                                                    this.setState({ imagem });
                                                    let imagem_path = '';
                                                    let reader = new FileReader()
                                                    reader.readAsDataURL(e.target.files[0])
                                                    reader.onload = () => {
                                                        imagem_path = reader.result;
                                                        this.setState({ imagem_path });
                                                    };
                                                    reader.onerror = function (error) {
                                                        console.log('Error: ', error);
                                                    }
                                                    e.target.value = '';
                                                }} />

                                            </div>
                                        </div>
                                        <div className="row">
                                            {this.state.imagem_path != '' &&
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }} className="col-12 col-md-12 mt-3">
                                                    <div>
                                                        <img src={this.state.imagem_path} style={{ height: 100, width: 100, objectFit: "contain", border: '1px solid lightgrey' }} />
                                                        <p><a onClick={() => {
                                                            this.setState({ imagem: '', imagem_path: '' });
                                                        }} className="btn btn-link btn-link-dark" style={{ color: 'indianred', cursor: 'pointer' }}><span style={{ marginRight: 5 }}>Remover</span><i className="fas fa-times"></i></a></p>
                                                    </div>
                                                </div>

                                            }
                                        </div>
                                        <hr />
                                        {this.state.is_open && <div className='alert alert-info'>
                                            Esta conta já está registrada na Blueen Open. Modificar as informações aqui <b>não refletirá</b> em mudanças no ambiente aberto.                                            </div>}
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Nome da pessoa" type="text" onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">E-mail <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="E-mail da pessoa" type="text" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">CPF <b style={{ color: 'red' }}></b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="CPF" type="text" onChange={(e) => this.setState({ cpf: maskCpf(e.target.value) })} value={this.state.cpf} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Telefone <b style={{ color: 'red' }}></b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Telefone" type="text" onChange={(e) => this.setState({ telefone: maskTelefone(e.target.value) })} value={this.state.telefone} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Segundo telefone <b style={{ color: 'red' }}></b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Segundo telefone" type="text" onChange={(e) => this.setState({ segundo_telefone: maskTelefone(e.target.value) })} value={this.state.segundo_telefone} />

                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-12 col-form-label" >Terá acesso ao sistema? <b style={{ color: 'red' }}></b></label>

                                            <div className="col-sm-12 d-flex align-items-center">
                                                <label className="form-check-label" htmlFor="acesso" style={{ marginRight: 10 }}>Não</label>

                                                <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                    <input className="form-check-input" type="checkbox" id="acesso" checked={this.state.acessoSistema} onChange={(e) => {
                                                        console.log(e.target.checked);
                                                        this.setState({ acessoSistema: e.target.checked });
                                                    }} />
                                                    <label className="form-check-label" htmlFor="acesso">Sim</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div hidden={this.state.acessoSistema == false}>
                                            <hr />

                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Senha {this.state.changePassword && <b style={{ color: 'red' }}>*</b>}</label>

                                                <div className="col-sm-12">
                                                    <input className="form-control" placeholder="Senha" type="password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />

                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Confirmar senha {this.state.changePassword && <b style={{ color: 'red' }}>*</b>}</label>

                                                <div className="col-sm-12">
                                                    <input className="form-control" placeholder="Confirmar senha" value={this.state.confirm_password} type="password" onChange={(e) => this.setState({ confirm_password: e.target.value })} />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-12 col-form-label" >Deseja que o usuário altere essa senha no próximo acesso?
                                                    <span onClick={() => this.setState({ infoChangePassword: true })}>
                                                        <IoIosInformationCircleOutline data-tip={`Como funciona?`} />
                                                        <ReactTooltip place="bottom" effect="float" />
                                                    </span></label>
                                                <div className="col-sm-12 d-flex align-items-center">
                                                    <label className="form-check-label" htmlFor="acesso" style={{ marginRight: 10 }}>Não</label>
                                                    <div className="form-check form-switch col-12 col-md-4 col-lg-4">
                                                        <input className="form-check-input" type="checkbox" id="slterar" checked={this.state.changePassword} onChange={(e) => {
                                                            this.setState({ changePassword: e.target.checked });
                                                        }}
                                                            value={this.state.changePassword} />
                                                        <label className="form-check-label" htmlFor="alterar">Sim</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Tipo <b style={{ color: 'red' }}>*</b></label>
                                                <div className="col-sm-12">
                                                    {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                    <select value={this.state.tipo} className="form-control" id="cameras" onChange={this.change_tipo.bind(this)}>
                                                        <option value={''}>{'Selecione um tipo de usuário'}</option>
                                                        {this.state.tipos.map((item, id) => (
                                                            <option key={id} value={item.id}>{item.nome}</option>

                                                        ))}


                                                    </select>
                                                </div>
                                            </div>

                                            <div className="row mt-2">
                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser contato de um destino? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="contatodestino" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="contatodestino" checked={this.state.acesso['ContatoDestino']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    acesso['ContatoDestino'] = e.target.checked;
                                                                    this.setState({ acesso: acesso });
                                                                }} />
                                                                <label className="form-check-label" htmlFor="contatodestino">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser responsável por um ponto de coleta? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="responsavelpontocoleta" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="responsavelpontocoleta" checked={this.state.acesso['ResponsavelPontoColeta']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    if (e.target.checked == true) {
                                                                        acesso['PessoaAutorizadaPontoColeta'] = e.target.checked;
                                                                    }
                                                                    acesso['ResponsavelPontoColeta'] = e.target.checked;
                                                                    this.setState({ acesso: acesso });
                                                                }} />
                                                                <label className="form-check-label" htmlFor="responsavelpontocoleta">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser uma pessoa autorizada em um ponto de coleta? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="autorizadopontocoleta" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="autorizadopontocoleta" checked={this.state.acesso['PessoaAutorizadaPontoColeta']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    if (acesso['ResponsavelPontoColeta'] == true) {
                                                                        return false;
                                                                    } else {

                                                                        acesso['PessoaAutorizadaPontoColeta'] = e.target.checked;
                                                                        this.setState({ acesso: acesso });
                                                                    }
                                                                }} />
                                                                <label className="form-check-label" htmlFor="autorizadopontocoleta">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser responsável por um estoque? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="responsavelestoque" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="responsavelestoque" checked={this.state.acesso['ResponsavelEstoque']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    if (e.target.checked == true) {
                                                                        acesso['PessoaAutorizadaEstoque'] = e.target.checked;
                                                                    }
                                                                    acesso['ResponsavelEstoque'] = e.target.checked;
                                                                    this.setState({ acesso: acesso });
                                                                }} />
                                                                <label className="form-check-label" htmlFor="responsavelestoque">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser uma pessoa autorizada em um estoque? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="autorizadoestoque" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="autorizadoestoque" checked={this.state.acesso['PessoaAutorizadaEstoque']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    if (acesso['ResponsavelEstoque'] == true) {
                                                                        return false;
                                                                    } else {
                                                                        acesso['PessoaAutorizadaEstoque'] = e.target.checked;
                                                                        this.setState({ acesso: acesso });
                                                                    }

                                                                }} />
                                                                <label className="form-check-label" htmlFor="autorizadoestoque">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser responsável em uma transportadora? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="responsaveltransportadora" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="responsaveltransportadora" checked={this.state.acesso['ResponsavelTransportadora']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    acesso['ResponsavelTransportadora'] = e.target.checked;
                                                                    this.setState({ acesso: acesso });
                                                                }} />
                                                                <label className="form-check-label" htmlFor="responsaveltransportadora">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser responsável em um registro de entrada (coleta e transporte)? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="responsavelentradacoleta" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="responsavelentradacoleta" checked={this.state.acesso['ResponsavelRegistroEntradaCT']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    acesso['ResponsavelRegistroEntradaCT'] = e.target.checked;
                                                                    this.setState({ acesso: acesso });
                                                                }} />
                                                                <label className="form-check-label" htmlFor="responsavelentradacoleta">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser responsável em uma solicitação de coleta? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="responsavelsolicitacaocoleta" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="responsavelsolicitacaocoleta" checked={this.state.acesso['ResponsavelSolicitacaoColeta']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    acesso['ResponsavelSolicitacaoColeta'] = e.target.checked;
                                                                    this.setState({ acesso: acesso });
                                                                }} />
                                                                <label className="form-check-label" htmlFor="responsavelsolicitacaocoleta">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário pode ser responsável em um registro de entrada (gestão interna)? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="responsavelentradainterna" style={{ marginRight: 10 }}>Não</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="responsavelentradainterna" checked={this.state.acesso['ResponsavelRegistroEntradaGI']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    acesso['ResponsavelRegistroEntradaGI'] = e.target.checked;
                                                                    this.setState({ acesso: acesso });
                                                                }} />
                                                                <label className="form-check-label" htmlFor="responsavelentradainterna">Sim</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {this.state.permissao_notificacao && <div className="col-12 col-md-6 col-lg-4">

                                                    <div className="form-group row">
                                                        <label className="col-12 col-form-label" >Usuário recebe notificações de todo sistema ou apenas as direcionadas à ele? <b style={{ color: 'red' }}></b></label>

                                                        <div className="col-sm-12 d-flex align-items-center">
                                                            <label className="form-check-label" htmlFor="notificacaoIndividual" style={{ marginRight: 10 }}>Geral</label>

                                                            <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                                <input className="form-check-input" type="checkbox" id="notificacaoIndividual" checked={this.state.acesso['NotificacaoIndividual']} onChange={(e) => {
                                                                    let acesso = { ...this.state.acesso };
                                                                    acesso['NotificacaoIndividual'] = e.target.checked;
                                                                    this.setState({ acesso: acesso });
                                                                }} />
                                                                <label className="form-check-label" htmlFor="notificacaoIndividual">Apenas para ele</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/pessoas"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar pessoa</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}
                            <SweetAlert
                                info
                                title={"Como funciona?"}
                                onConfirm={() => {
                                    this.setState({ infoChangePassword: false });
                                }}
                                show={this.state.infoChangePassword}
                                confirmBtnText='Entendi'
                                confirmBtnBsStyle="success"
                            >
                                Ao selecionar essa opção, envie a senha escolhida ao usuário para que ele possa alterá-la em seu próximo acesso!
                            </SweetAlert>
                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, login, mudarDados })(EditarPessoa);


