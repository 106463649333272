import React from 'react'
import { ptBR } from '@mui/x-date-pickers/locales';
import { BsCalendarXFill, BsFilter } from 'react-icons/bs'
import { MdClose, MdInfo, MdSearch } from 'react-icons/md';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { Box, IconButton, Menu, Tooltip, Typography, Zoom } from '@mui/material'
import 'dayjs/locale/pt-br';

const Filter = (props) => {
  const [valueOf, setValueOf] = React.useState('')
  const [valueFor, setValueFor] = React.useState('')
  const [anchorElUser, setAnchorElUser] = React.useState(null)

  //-------------------------*-------------------------
  // Function to handle opening of a dialog
  const handleOpen = (event) => {
    props.classComponent ? props.setState({ reload: false }) : props.setSearch(false)
    setAnchorElUser(event.currentTarget);
  };

  //-------------------------*-------------------------
  // Function to handle closing of a dialog
  const handleClose = () => {
    if (props.options) {
      let newOptions = { ...props.options }
      let keys = Object.keys({ ...props.options })

      // Looping on options object keys
      keys.forEach(item2 => {
        if (newOptions[item2].checked) newOptions[item2].value = true;
        else newOptions[item2].value = false
      })

      // Check the type of component (class or functional) and perform the corresponding action
      props.classComponent ? props.setState({ options: newOptions }) : props.setOptions(newOptions)
    }
    // Check the type of component (class or functional) and perform the corresponding action
    props.classComponent ? props.setState({ reload: true }) : props.setSearch(true)
    setAnchorElUser(null);
  };

  // Function to handle changes in options based on the selected item
  const handleChange = (item) => {
    let newOptions = { ...props.options }

    let keys = Object.keys({ ...props.options })

    keys.forEach(item2 => {
      if (!newOptions[item2].multiCheck && newOptions[item2].data) {
        const newData = newOptions[item2].data.map(dataItem => ({
          ...dataItem,
          value: (dataItem === item),
        }));

        if (newData.some(dataItem => dataItem.value)) {
          newOptions[item2].data = newData;
        }
      }

      const itemData = newOptions[item2]?.data?.find(dataItem => dataItem === item);

      if (newOptions[item2].data && itemData) {
        itemData.value = !itemData.value;
        return;
      }

      newOptions[item2].checked = (item === item2);
    });

    // Check the type of component (class or functional) and perform the corresponding action
    props.classComponent ? props.setState({ options: newOptions }) : props.setOptions(newOptions)
  }

  //-------------------------*-------------------------
  // Function to handle date changes based on the given value and type
  const handleDateChange = (value, type) => {
    let month
    let day

    // Format the month to have leading zero if necessary
    if (Array.from(String(Number(value.$M + 1))).length === 1) {
      month = '0' + Number(value.$M + 1)
    } else {
      month = value.$M + 1
    }

    // Format the day to have leading zero if necessary
    if (Array.from(String(value.$D)).length === 1) {
      day = '0' + value.$D
    } else {
      day = value.$D
    }

    // Create the date string in the format 'YYYY-MM-DD'
    const date = value.$y + '-' + month + '-' + day

    if (type === 'Of') {
      // Handle date changes for 'Of' type
      setValueOf(value)
      setValueFor('')
      if (props.classComponent) {
        props.setState({ dateOf: date, dateFor: '' })
      } else {
        props.setDateOf(date)
        props.setDateFor('')
      }
    } else {
      // Handle date changes for any other type
      setValueFor(value)
      if (props.classComponent) {
        props.setState({ dateFor: date })
      } else {
        props.setDateFor(date)
      }
    }
  }

  //-------------------------*-------------------------
  // Function to reset dates based on the given type
  const resetDate = (type) => {
    if (type === 'All') {
      // Reset both dateOf and dateFor
      if (props.classComponent) {
        props.setState({ dateOf: '', dateFor: '' })
      } else {
        props.setDateOf('')
        props.setDateFor('')
      }
      setValueOf('')
      setValueFor('')
    }
    if (type === 'Of') {
      // Reset dateOf
      if (props.classComponent) {
        props.setState({ dateOf: '' })
      } else {
        props.setDateOf('')
      }
      setValueOf('')
    } else {
      // Reset dateFor
      if (props.classComponent) {
        props.setState({ dateFor: '' })
      } else {
        props.setDateFor('')
      }
      setValueFor('')
    }
  }

  //-------------------------*-------------------------
  // Function to render options based on the props.options object
  const renderOptions = () => {
    if (props.options) {
      let keys = Object.keys({ ...props.options })

      // Map over the keys and render each option
      return keys.map(item => {
        if (props.options[item].data) {
          const cols = Math.floor(12 / keys.length)

          return (
            <div className='m-3' key={props.options[item].label}>
              <span><strong>{props.options[item].label}</strong></span>
              {props.options[item].data.map(dataItem => (
                <div key={dataItem.label} className='form-check my-1 ms-2'>
                  <input
                    className="form-check-input"
                    type={props.options[item].multiCheck ? "checkbox" : "radio"}
                    name="exampleRadios"
                    id={dataItem.label}
                    value={dataItem.value}
                    onChange={() => handleChange(dataItem)}
                    checked={dataItem.value}
                  />
                  <label className="form-check-label" htmlFor={dataItem.label}>
                    {dataItem.label}
                  </label>
                </div>
              ))}
            </div>
          )
        }

        return (
          <>
            {!props.options[item].disabled && (
              <div className="form-check my-1 ms-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="exampleRadios"
                  id={item}
                  value={props.options[item]?.checked}
                  onChange={() => handleChange(item)}
                  checked={props.options[item]?.checked}
                />
                <label className="form-check-label" htmlFor={item}>
                  {props.options[item].label}
                </label>
              </div>
            )}
          </>
        )
      });
    }
  };


  return (
    <div className="d-flex align-items-center">
      <p className='filter-label me-1' onClick={handleOpen}>Opções de filtro</p>
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Filtro">
          <IconButton sx={{ padding: 0 }} onClick={handleOpen}>
            <BsFilter size={30} />
          </IconButton>
        </Tooltip>
        {/* -------------------------Dialog-section------------------------- */}
        <Menu
          sx={{ mt: '40px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleClose}
        >
          <div className="p-3">
            {/* -------------------------Functions-section------------------------- */}
            <div className="d-flex">
              <IconButton onClick={() => setAnchorElUser(null)}>
                <MdClose />
              </IconButton>

              <div className="ms-auto">
                {!props.hideDate && (
                  <>
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Clique para excluir datas do filtro"
                      arrow
                      placement="top"
                    >
                      <IconButton onClick={() => resetDate('All')}>
                        <BsCalendarXFill size={20} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      TransitionComponent={Zoom}
                      title="Clique na data selecionada para retirá-la do filtro"
                      arrow
                      placement="top"
                    >
                      <IconButton>
                        <MdInfo />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                <button className="rounded-button hvr-grow" onClick={handleClose}>
                  <MdSearch size={22} />
                </button>
              </div>
            </div>
            {props.options && (
              <div className="my-4 m-auto">{renderOptions()}</div>
            )}
            {!props.hideDate && (
              <div className={`${props.classComponent && 'mt-3'} row`}>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={'pt-br'}
                  localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}
                >
                  <div className="col-6">
                    {/* -------------------------Date-of-section------------------------- */}
                    <div className="d-flex justify-content-center align-items-center">
                      <Typography>De: </Typography>
                      <DatePicker
                        value={valueOf}
                        onChange={(value) => handleDateChange(value, 'Of')}
                        renderInput={({ inputRef, InputProps }) => (
                          <div ref={inputRef} style={{ height: 0 }}>
                            {InputProps?.endAdornment}
                          </div>
                        )}
                      />
                    </div>
                    {valueOf ? (
                      <b className="date" onClick={() => resetDate('Of')}>
                        {Array.from(String(valueOf.$D)).length === 1
                          ? '0' + valueOf.$D
                          : valueOf.$D}/
                        {Array.from(String(Number(valueOf.$M + 1))).length === 1
                          ? '0' + (valueOf.$M + 1)
                          : valueOf.$M + 1}/
                        {valueOf.$y}
                      </b>
                    ) : (
                      <b className="date">- / - / -</b>
                    )}
                  </div>
                  {/* -------------------------Date-for-section------------------------- */}
                  <div className="col-6">
                    <div className="d-flex justify-content-center align-items-center">
                      <Typography>Até: </Typography>
                      <DatePicker
                        value={valueFor}
                        onChange={(value) => handleDateChange(value, 'For')}
                        minDate={valueOf && new Date(valueOf)}
                        renderInput={({ inputRef, InputProps }) => (
                          <div ref={inputRef} style={{ height: 0 }}>
                            {InputProps?.endAdornment}
                          </div>
                        )}
                      />
                    </div>
                    {valueFor ? (
                      <b className="date" onClick={() => resetDate()}>
                        {Array.from(String(valueFor.$D)).length === 1
                          ? '0' + valueFor.$D
                          : valueFor.$D}/
                        {Array.from(String(Number(valueFor.$M + 1))).length === 1
                          ? '0' + (valueFor.$M + 1)
                          : valueFor.$M + 1}/
                        {valueFor.$y}
                      </b>
                    ) : (
                      <b className="date">- / - / -</b>
                    )}
                  </div>
                </LocalizationProvider>
              </div>
            )}
          </div>
        </Menu>
      </Box>
    </div>
  );
}

export default Filter