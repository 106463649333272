import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { BsCheckLg } from 'react-icons/bs';
import { IconButton, Step, StepLabel, Stepper, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import { URL } from '../../../variables';
import { MdClose } from 'react-icons/md';
import EditarAcondicionamentoSigor from './EditarAcondicionamentoSigor';
import SigorRelacionadas from './SigorRelacionadas';
import Theme from '../../Theme';

const style = {
  position: 'absolute',
  // top: '50%',
  left: '50%',
  transform: 'translate(0, 0, 0, 50%)',
  width: '50%',
  height: '100%',
  bgcolor: 'background.paper',
  border: '2px solid transparent',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  '@media(max-width: 1000px)': {
    width: '90%',
    left: '5%',
  },
};

const ModalAcondicionamento = (props) => {
  const [state, setState] = React.useState({
    redirect: false,
    path: '/acondicionamentos',
    loading: true,
    codigo: '',
    descricao: '',
    msgErro: '',
  })
  const [acondicionamentos, setAcondicionamentos] = React.useState({
    loading: true,
    acondicionamento_id: '',
    acondicionamentos: [],
    all_acondicionamentos: [],
    acondicionamentos_selecionados: [],
  })
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const token = useSelector((state) => state.AppReducer.token);

  React.useEffect(() => {
    if (open === true) {
      fetch(`${URL}api/get_acondicionamento_sigor/${props.id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'application/json',
        },
      })
        .then(async (responseLog) => {
          let resp = await responseLog.json();
          console.log(resp);
          setState({ ...state, loading: false, descricao: resp.acondicionamento.descricao });
          fill_acondicionamentos()
        })

      function fill_acondicionamentos() {
        fetch(`${URL}api/fill_acondicionamentos_sigor`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }).then(async (response) => {
          const resp = await response.json()
          setAcondicionamentos({ ...acondicionamentos, acondicionamentos: resp.acondicionamentos, acondicionamento_id: resp.acondicionamentos[0].id, all_acondicionamentos: resp.acondicionamentos, loading: false })
        })
      }
    }
  }, [open]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log('disabled', props.disabled);

  function salvar() {
    const obj_acondicionamento = {
      codigo: state.codigo,
      unidade_id: props.id,
      descricao: state.descricao,
      acondicionamentos: acondicionamentos.acondicionamentos_selecionados
    };

    console.log('obj', obj_acondicionamento)
    props.storeAcondicionamento(obj_acondicionamento);
    setOpen(false);
  }

  function renderPage() {
    if (page == 1) return <EditarAcondicionamentoSigor id={props.id} state={state} setState={(e) => setState(e)} />
    if (page == 2) return <SigorRelacionadas id={props.id} state={acondicionamentos} setState={(e) => setAcondicionamentos(e)} />
  }

  return (
    <>
      <button
        className="btn btn-primary btn-outline btn-circle me-1 mb-1"
        data-tip="Inserir"
        onClick={handleOpen}
        disabled={props.disabled}
      >
        <BsCheckLg />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ThemeProvider theme={Theme}>
          <Box sx={style}>
            <>
              <IconButton onClick={handleClose}>
                <MdClose />
              </IconButton>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div className="text-center">
                  <h4
                    className="mt-0 header-title"
                    style={{ fontSize: '1.5rem', color: 'black' }}
                  >
                    Editar acondicionamento
                  </h4>
                  <p style={{ fontSize: '1rem', color: 'black' }}>
                    Edite um acondicionamento do seu sistema
                  </p>
                </div>
              </Typography>

              <Stepper activeStep={page - 1} alternativeLabel>
                <Step>
                  <StepLabel color='success'>Dados de Acondicionamento</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Acondicionamentos Relacionados</StepLabel>
                </Step>
              </Stepper>

              {!state.loading ? <div>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {renderPage()}
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className="d-flex mt-3">
                    <div className="align-self-center">
                      <Button sx={{ color: 'text.main' }} variant="contained" size="large" onClick={() => setPage(page - 1)}
                        disabled={page === 1}>
                        Voltar
                      </Button>
                    </div>
                    <div className="align-self-center ms-auto">
                      {page < 2 && (
                        <Button sx={{ color: 'text.main' }} variant="contained" size="large" onClick={() => setPage(2)}
                          disabled={page === 2}>
                          Próximo
                        </Button>
                      )}
                      {page === 2 && (
                        <Button sx={{ color: 'text.main' }} className="align-self-end" variant="contained" size="large" onClick={() => salvar()}>
                          Salvar
                        </Button>
                      )}
                    </div>
                  </div>
                </Typography>
              </div>
                :
                <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                  <div className="spinner-border text-primary" role="status"></div>
                </div>}
            </>
          </Box>
        </ThemeProvider>
      </Modal>
    </>
  );
};

export default ModalAcondicionamento;
