const INITIAL_STATE = {
    token: null,
    user: {},
    collapsed:false,
    toggled:false,
    permissoes:{},
    permissoes_app:{},
    permissoes_notificacoes:{},
    notifications: 0,

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'dados':

            if(action.payload.logo!=undefined){
                const handleIcon = (logo) => {
                    const icon = getIconEl(); // Accessing favicon element
                    icon.href = logo;
                };

                function getIconEl() {
                    return document.getElementById('icon');
                }

                handleIcon(action.payload.logo)
            }
            return {
                ...state,
                ...action.payload
            };
        
        case 'logout':
            return {
                ...state,
                token: null,
                user: {},
                permissoes:{},
                permissoes_app:{},
                permissoes_notificacoes:{},
                notifications: 0,
            };
        case 'user':
            return {
                ...state,
                user: action.payload,
            };
        case 'login':
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user,

            };
        case 'notifications':
            return {
                ...state,
                notifications: action.payload,
            };
        default:
            return { ...state };
    }
};
