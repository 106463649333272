import React, { Component } from 'react';
// import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';

// import Custom Components
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { URL } from '../../variables';
import SweetAlert from 'react-bootstrap-sweetalert';
import { logout, mudarUser, } from '../../actions/AppActions';
import Modal from 'react-bootstrap/Modal';
import './chamado.css';
import { FaArrowCircleLeft, FaArrowCircleRight, FaCheck, FaCheckDouble, FaClock, FaImage, FaPaperclip, FaPaperPlane, FaSearch } from 'react-icons/fa';
import { MdBackspace, MdDelete, MdMessage, MdReply, MdSend } from 'react-icons/md';
import DefaultProfile from '../../assets/images/default.png';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
const customStyles = {
    content: {
        // top: '50%',
        // transform: 'translateY(-50%)'
    },
    overlay: {
        backgroundColor: 'rgba(51,51,51,0.6)',
        zIndex: '10000',
        // overflowY: 'scroll'
    }
};
class ChatGeral extends Component {

    constructor(props) {
        super(props);
        this.state = {
            conversas: [],
            conversas_ids: {},

            loading: true,
            show_warning: false,
            show_erro: false,
            msgErro: '',
            conversa_select: '',
            mensagem: '',
            modal_open: false,
            msgErroResposta: '',
            assunto: '',
            mensagem: '',
            loading_save: false,

            pessoa_name: '',
            loading_search_pessoa: false,
            pessoas: [],
            pessoa_selecionada: {},
            show_autocomplete_pessoa: false,

            assunto: '',
            mensagem_inicial: '',
            name_search: '',

            loading_finalizar: false,
            msgErroJustificativa: '',
            justificativa: '',
            modal_open_finalizar: false,
            show_sucesso: false,
            item_preview: null,
            menu_open: false,
            file_select: 0,
            files_pending: [],
            loading_send_file: false,
            show_preview: false,
            files_pending_preview: [],
            msgErroSend: '',
            conversas_pessoas: {}

        };
        this.messages_view = React.createRef();
        this.files_input = React.createRef();

    }


    get_conversas() {
        this.setState({ loading: true });

        fetch(`${URL}api/get_conversas`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {

                    this.setState({
                        conversas: resp.conversas,
                        conversas_ids: resp.conversas_ids,
                    }, () => {
                        this.init_shared_worker();
                    });

                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    componentWillUnmount() {
        this.shared_worker.port.postMessage({ status: 'disconnect' });
        this.shared_worker.port.onmessage = null;
    }

    componentDidMount() {
        this.get_conversas();
    }

    init_shared_worker() {
        this.shared_worker = new SharedWorker('chat_geral.js');
        this.shared_worker.port.onmessage = (e) => {
            console.log(e.data);
            let conversas = this.state.conversas;
            if (e.data.status == 'connect') {
                this.shared_worker.port.postMessage({ status: 'connect', user: this.props.user, token: this.props.token, URL: URL })
                this.setState({ loading: false });
            }
            else if (e.data.status == 'connected') {
                this.setState({ loading: false });
            }
            else if (e.data.status == 'MessageReceiveUser') {
                let conversas = [...this.state.conversas];
                for (let i = 0; i < conversas.length; i++) {
                    if (conversas[i].sender.id == e.data.data.sender.id || conversas[i].receive.id == e.data.data.sender.id) {
                        for (let j = 0; j < conversas[i].mensagens.length; j++) {
                            conversas[i].mensagens[j].visto = true;
                            if (conversas[i].mensagens[j].uuid == e.data.data.mensagem.uuid) {
                                break;
                            }
                        }
                    }

                }
                this.setState({ conversas });
            }
            else if (e.data.status == 'MessageUser') {
                // this.setState({ loading: false });
                let conversas = [...this.state.conversas];
                let conversas_ids = { ...this.state.conversas_ids };

                let conversa_select = '';
                for (let i = 0; i < conversas.length; i++) {
                    if (conversas[i].sender.id == e.data.data.sender.id) {
                        conversas[i].mensagens.push(e.data.data.mensagem);
                        if (this.state.conversa_select != conversas[i].id) {
                            conversas[i].nao_vistas_sender += 1;

                        }
                        else {
                            this.visualizar_mensagem(e.data.data.mensagem.uuid, e.data.data.sender.id, conversas[i].id);
                        }
                        conversas[i].last_message = e.data.data.sender.name.split(' ')[0] + ': ' + (e.data.data.mensagem.type == 'text' ? e.data.data.mensagem.message : (e.data.data.mensagem.type == 'image' ? 'Imagem' : 'Vídeo'));

                        conversa_select = conversas[i].id;
                        break;
                    }
                    else if (conversas[i].receive.id == e.data.data.sender.id) {
                        conversas[i].mensagens.push(e.data.data.mensagem);
                        if (this.state.conversa_select != conversas[i].id) {
                            conversas[i].nao_vistas_receive += 1;

                        }
                        else {
                            this.visualizar_mensagem(e.data.data.mensagem.uuid, e.data.data.sender.id, conversas[i].id);
                        }
                        conversas[i].last_message = e.data.data.sender.name.split(' ')[0] + ': ' + e.data.data.mensagem.message;
                        conversa_select = conversas[i].id;
                        break;
                    }
                }
                if (conversa_select == '') {
                    conversa_select = uuidv4();
                    let conversa_ = {
                        'id': conversa_select,
                        'sender': e.data.data.sender,
                        'receive': this.props.user,
                        'date': moment().format('YYYY-MM-DD HH:mm:ss'),
                        'nao_vistas_sender': 1,
                        'nao_vistas_receive': 0,
                        'last_message': e.data.data.sender.name.split(' ')[0] + ': ' + (e.data.data.mensagem.type == 'text' ? e.data.data.mensagem.message : (e.data.data.mensagem.type == 'image' ? 'Imagem' : 'Vídeo')),
                        'mensagens': [e.data.data.mensagem],
                        'created_at': moment().format('YYYY-MM-DD HH:mm:ss'),
                    };
                    conversas.unshift(conversa_);
                    conversas_ids[conversa_select] = conversa_;
                }

                // if (this.state.mensagem_inicial.trim().length == 0) {
                //     this.setState({ msgErroResposta: 'O campo Mensagem é obrigatório' });
                //     return;
                // }
                let scroll = false;
                if (this.messages_view.current != null) {

                    if (this.messages_view.current.scrollTop + this.messages_view.current.offsetHeight == this.messages_view.current.scrollHeight) {
                        scroll = true;
                    }
                }

                this.setState({ conversas, conversas_ids }, () => {
                    if (scroll == true) {
                        this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                    }
                });

            }
        }
        this.shared_worker.port.start();

    }

    salvar_arquivo(files, uuids, receive_id, conversa_id) {
        this.setState({ loading_send_file: true, msgErroSend: '' });

        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('arquivos[]', files[i]);
            formData.append('tipos[]', files[i].type.includes('image') ? 'image' : 'video');
            formData.append('uuids[]', uuids[i]);

        }
        formData.append('receive_id', receive_id);
        console.log(files);

        fetch(`${URL}api/salvar_arquivo`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,

            },
            body: formData
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);

                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErroSend: erro, loading_send_file: false });
                }
                else {

                    // socket.emit("send_msg_fiscal", {msg:msg,sala_id:sala_id,aluno_id:aluno_id,nome_chat:$('#nome_chat').text().split(' ')[0]+' '+$('#nome_chat').text().split(' ')[1],id_msg:uid_msg,faculdade_id:faculdade_id});
                    let conversas = this.state.conversas;
                    console.log(conversas);
                    for (let i = 0; i < conversas.length; i++) {
                        if (conversa_id == conversas[i].id) {
                            for (let j = conversas[i].mensagens.length - 1; j >= 0; j--) {
                                console.log(conversas[i]);
                                if (uuids.includes(conversas[i].mensagens[j].uuid)) {
                                    if (conversas[i].mensagens[j].visto == undefined) {
                                        conversas[i].mensagens[j].visto = false;
                                    }
                                    uuids = uuids.filter((item, id) => item != conversas[i].mensagens[j].uuid);
                                    if (uuids.length == 0) {
                                        break;
                                    }
                                }


                            }
                            this.setState({ conversas, loading_send_file: false }, () => {
                                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight + 1000;
                            });
                            break;
                        }
                        // socket.emit("send_msg_fiscal", {msg:msg,sala_id:sala_id,aluno_id:aluno_id,nome_chat:$('#nome_chat').text().split(' ')[0]+' '+$('#nome_chat').text().split(' ')[1],id_msg:uid_msg,faculdade_id:faculdade_id});


                    }


                }



            } catch (err) {
                console.log(err);
                this.setState({ loading_send_file: false, msgErroSend: 'Erro ao pegar resposta do servidor' });
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_send_file: false, msgErroSend: `Erro ao pegar resposta do servidor. Você está conectado a internet?` });
            });


    }


    get_pessoa_search(search) {

        if (this.state.loading_search_pessoa == false) {
            this.setState({ loading_search_pessoa: true });
        }
        fetch(`${URL}api/pessoa_search?search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });
                    this.setState({ loading_search_pessoa: false });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_search_pessoa: false, pessoas: resp.users });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_search_pessoa: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_search_pessoa: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    visualizar_mensagem(uuid, receive_id, conversa_id) {

        let formData = new FormData();
        formData.append('receive_id', receive_id);
        formData.append('uuid', uuid);


        fetch(`${URL}api/visualizar_mensagem`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,

            },
            body: formData
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);

                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                }
                else {

                    // let conversas = this.state.conversas;
                    // console.log(conversas);
                    // for (let i = 0; i < conversas.length; i++) {
                    //     if (conversa_id == conversas[i].id) {
                    //         conversas[i].nao_vistas_receive = 0;
                    //         conversas[i].nao_vistas_sender = 0;
                    //     }
                    //     this.setState({ conversas }, () => {
                    //         this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                    //     });
                    //     break;
                    // }


                }



            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                this.setState({ loading_send_file: false, msgErroSend: `Erro ao pegar resposta do servidor. Você está conectado a internet?` });
            });


    }


    enviar_mensagem(mensagem, uuid, receive_id, conversa_id) {

        let formData = new FormData();
        formData.append('mensagem', mensagem);
        formData.append('receive_id', receive_id);
        formData.append('uuid', uuid);


        fetch(`${URL}api/enviar_mensagem`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,

            },
            body: formData
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);

                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                }
                else {
                    // await AsyncStorage.setItem('token', resp.access_token)
                    // await AsyncStorage.setItem('user', JSON.stringify(resp.user))
                    // let produtos = [...this.props.produtos];
                    // produtos.unshift(resp.produto);
                    // this.props.mudarProdutos(produtos);
                    // console.log(tipo,resp.arquivo);
                    // this.props.socket.emit('send_msg_atendentes', {
                    //     vendedor_foto: null,
                    //     vendedor_uuid: uuidv4(),
                    //     pessoa_name: this.props.user.name,
                    //     vendedor_id: this.props.user.id,
                    //     vendedor_email: this.props.user.email,
                    //     msg: '',
                    //     id_msg: id_msg,
                    //     tipo: tipo,
                    //     protocolo: protocolo,
                    //     arquivo: resp.arquivo
                    // });
                    let conversas = this.state.conversas;
                    console.log(conversas);
                    for (let i = 0; i < conversas.length; i++) {
                        if (conversa_id == conversas[i].id) {
                            for (let j = conversas[i].mensagens.length - 1; j >= 0; j--) {
                                console.log(conversas[i]);
                                if (conversas[i].mensagens[j].uuid == uuid) {
                                    if (conversas[i].mensagens[j].visto == undefined) {
                                        conversas[i].mensagens[j].visto = false;
                                    }
                                    break;
                                }


                            }

                            this.setState({ conversas }, () => {
                                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                            });
                            break;
                        }
                        // socket.emit("send_msg_fiscal", {msg:msg,sala_id:sala_id,aluno_id:aluno_id,nome_chat:$('#nome_chat').text().split(' ')[0]+' '+$('#nome_chat').text().split(' ')[1],id_msg:uid_msg,faculdade_id:faculdade_id});


                    }
                }



            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                this.setState({ loading_send_file: false, msgErroSend: `Erro ao pegar resposta do servidor. Você está conectado a internet?` });
            });


    }


    send_msg() {
        let uid_msg = uuidv4();
        let conversa_id = this.state.conversa_select;
        let conversas = this.state.conversas;
        let msg = this.state.mensagem;

        if (msg != null && msg != '' && msg.replace(/ /g, '') != '') {
            let receive_id = this.state.conversas_ids[conversa_id].sender.id == this.props.user.id ? this.state.conversas_ids[conversa_id].receive.id : this.state.conversas_ids[conversa_id].sender.id;
            for (let i = 0; i < conversas.length; i++) {
                if (conversas[i].id == conversa_id) {
                    conversas[i].mensagens.push({
                        'id': uid_msg,
                        'sender': this.props.user,
                        'type': 'text',
                        'message': msg,
                        'arquivo': null,
                        'uuid': uid_msg,
                        'visto': undefined,
                        'created_at': moment().format('YYYY-MM-DD HH:mm:ss')
                    });
                    conversas[i].last_message = this.props.user.name.split(' ')[0] + ': ' + msg;

                    break;
                }
            }

            this.setState({ mensagem: '', conversas }, () => {
                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                this.enviar_mensagem(msg, uid_msg, receive_id, conversa_id)

            });
        }
    }

    visualizar(item) {
        this.setState({ item_preview: item });
    }

    render() {
        let item_preview = this.state.item_preview;
        console.log(item_preview);
        let conversas = this.state.conversas;
        if (this.state.name_search.trim().length > 0) {
            conversas = conversas.filter((item, id) => {
                if(item.sender.id!=this.props.user.id){
                    console.log(item.sender,item.sender.name.toLowerCase().includes(this.state.name_search.toLowerCase()))
                    return item.sender.name.toLowerCase().includes(this.state.name_search.toLowerCase());
                }
                else{
                    console.log(item.receive,item.receive.name.toLowerCase().includes(this.state.name_search.toLowerCase()))

                    return item.receive.name.toLowerCase().includes(this.state.name_search.toLowerCase());
                }
            });
        }
        return (
            <div>

                <div style={{ width: '100%', display: "flex", justifyContent: "center" }}>
                    <div id="myModal" className="modal_" style={{ display: item_preview == null ? 'none' : 'block' }}>

                        <span onClick={() => this.setState({ item_preview: null })} className="close_">&times;</span>

                        {item_preview != null && item_preview.type == 'image' && <img src={item_preview.arquivo} style={{
                            height: '80%', width: 'auto',

                            objectFit: 'contain',
                            background: 'rgba(0,0,0,0)'
                        }} className="modal-content" id="img01" />}
                        {/* {item_preview != null && item_preview.tipo == 'video' && <video src={item_preview.arquivo} controls style={{
    height: '80%', width: 'auto',
    objectFit: 'contain',
    background: 'rgba(0,0,0,0)'
}} className="modal-content" id="video01"></video>} */}

                        <div id="caption"></div>
                    </div>
                    <div style={{
                        margin: 15,
                        position: 'relative',
                        width: '100%',
                        marginTop: 0
                    }} >
                        {/* <h6>Chat de Atendimento</h6> */}
                        {<div className="row" style={{ borderRadius: 10, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.03)' }}>
                            <div className="col-md-4 overflow-auto"
                                style={{ position: 'relative', border: '1px solid white', padding: 0, height: '680px', background: 'white', borderRight: '1px solid lightgrey', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>

                                <div id="conversas_head">
                                    <div style={{ position: 'relative', padding: 10, height: 60 }}>
                                        <div style={{ position: 'absolute', top: 18, left: 20 }}>
                                            <FaSearch style={{ fontSize: '1rem' }} />
                                        </div>
                                        <input autoComplete="off" onChange={(e) => this.setState({ name_search: e.target.value, conversa_select: '' })} value={this.state.name_search} name="msg" placeholder="Nome da pessoa" className="form-control msg_input" style={{ resize: 'none', height: '100%', borderRadius: 50, paddingLeft: 40 }} />
                                    </div>
                                    {conversas.length == 0 && <div className="row d-flex justify-content-center align-items-center" style={{ marginTop: '2rem', marginRight: 0, marginLeft: 0 }}>
                                        <p style={{ textAlign: "center" }}>Nenhuma conversa iniciada</p>
                                    </div>}
                                    {conversas.map((item, id) => (

                                        <div onClick={() => {
                                            let conversas = [...this.state.conversas];
                                            conversas[id].nao_vistas_sender = 0;
                                            conversas[id].nao_vistas_receive = 0;

                                            if (conversas[id].mensagens.length > 0 && this.state.conversa_select != item.id) {
                                                let mensagem = conversas[id].mensagens[conversas[id].mensagens.length - 1];
                                                this.visualizar_mensagem(mensagem.uuid, conversas[id].sender.id == this.props.user.id ? conversas[id].receive.id : conversas[id].sender.id, conversas[id].id)

                                            }
                                            this.setState({ conversa_select: item.id, conversas, }, () => {
                                                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                                            });
                                            //marcar como visto

                                        }} key={id} className="chat"
                                            style={{ background: this.state.conversa_select == item.id ? '#f2f2f2' : 'white', borderBottom: '1px solid grey', cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
                                            <div style={{ display: 'flex', margin: '5px' }} >
                                                <div className="image me-3"><img
                                                    src={(item.sender.id == this.props.user.id ? (item.receive.user_dado.imagem == null ? DefaultProfile : URL + item.receive.user_dado.imagem) : (item.sender.user_dado.imagem == null ? DefaultProfile : URL + item.sender.user_dado.imagem))}
                                                    alt="User image" className="rounded-circle" style={{ minWidth: '60px', height: '60px', width: '60px', objectFit: 'cover', border: '1px solid lightgrey' }} /></div>
                                                <div>
                                                    <p className="text_" style={{ fontWeight: 'bold', fontSize: '1rem', color: 'black', marginBottom: '5px' }}>{(item.sender.id == this.props.user.id ? item.receive.name : item.sender.name)}</p>
                                                    <span className="text_" style={{ fontWeight: 'normal', fontSize: '0.9rem' }}>
                                                        {item.last_message == null ? 'Envie uma mensagem' : item.last_message}
                                                    </span>

                                                </div>
                                            </div>
                                            <div style={{ marginRight: 5 }}>
                                                {item.mensagens.length > 0 && moment(item.mensagens[item.mensagens.length - 1].created_at).format('DDMMYYYY') != moment().format('DDMMYYYY') && <p style={{ marginBottom: 0, textAlign: 'end' }}>{moment(item.mensagens[item.mensagens.length - 1].created_at).format('DD/MM/YYYY')}</p>}
                                                {item.mensagens.length > 0 && moment(item.mensagens[item.mensagens.length - 1].created_at).format('DDMMYYYY') == moment().format('DDMMYYYY') && <p style={{ marginBottom: 0, textAlign: 'end' }}>{moment(item.mensagens[item.mensagens.length - 1].created_at).format('HH:mm')}</p>}
                                                {item.mensagens.length == 0 && <p style={{ marginBottom: 0, textAlign: 'end' }}>{moment().format('HH:mm')}</p>}
                                                <div style={{ display: 'flex', margin: '5px', justifyContent: 'center', alignItems: 'center' }} >
                                                    <div hidden={item.sender.id == this.props.user.id ? item.nao_vistas_receive == 0 : item.nao_vistas_sender == 0}
                                                        style={{ minWidth: '30px', backgroundColor: 'green', borderRadius: '100px', height: '30px', width: '30px', color: 'white', textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                                        {item.sender.id == this.props.user.id ? item.nao_vistas_receive : item.nao_vistas_sender}</div>
                                                </div>
                                            </div>

                                        </div>
                                    ))}

                                </div>
                                <div style={{ position: 'absolute', bottom: 50, right: 20 }}>

                                    <button onClick={() => {
                                        this.setState({ modal_open: true, msgErroResposta: '', assunto: '', mensagem_inicial: '' });
                                    }} className="btn btn-success" style={{ borderRadius: 100, width: 50, height: 50 }}>
                                        <MdMessage style={{ fontSize: '25px', color: 'white', fontStyle: 'normal' }} />
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-8 col-12" style={{ background: 'white', padding: 0, borderTopRightRadius: '10px', borderBottomRightRadius: '10px', position: 'relative' }}>
                                {this.state.show_preview == true && <div style={{
                                    height: '680px', width: '100%', position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    background: 'white',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    zIndex: 1,
                                    alignItems: 'center',
                                    borderTopRightRadius: '10px',
                                    borderBottomRightRadius: '10px'
                                }}>
                                    {this.state.loading_send_file == false &&
                                        <span onClick={() => {
                                            this.setState({ show_preview: false, files_pending: [] });
                                        }} style={{ fontSize: '35px' }} className="close_">&times;</span>

                                    }
                                    <div>
                                        {this.state.files_pending.map((file, i) => (

                                            <div key={i} className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                                                {i == this.state.file_select && <div className="col-md-2" style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '300px'
                                                }}>
                                                    {i != 0 &&
                                                        <FaArrowCircleLeft unselectable="on" style={{ userSelect: 'none' }} className="arrow_pass" onClick={() => {
                                                            this.setState({ file_select: i - 1 });
                                                        }} />
                                                    }
                                                </div>}

                                                {i == this.state.file_select && <div className="col-md-8" >
                                                    {this.state.files_pending_preview[i] != undefined && this.state.files_pending_preview[i].type == 'image' && <img style={{ width: '100%', height: '300px', objectFit: 'contain' }} src={this.state.files_pending_preview[i].data} />}
                                                    {/* {this.state.files_pending_preview[i] != undefined && this.state.files_pending_preview[i].type == 'video' && <video controls style={{ width: '100%', height: '300px' }} src={this.state.files_pending_preview[i].data} ></video>} */}

                                                </div>}
                                                {i == this.state.file_select && <div className="col-md-2" style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    height: '300px'
                                                }}>
                                                    {i != this.state.files_pending.length - 1 &&
                                                        <FaArrowCircleRight unselectable="on" style={{ userSelect: 'none' }} className="arrow_pass" onClick={() => {
                                                            this.setState({ file_select: i + 1 });
                                                        }} />
                                                    }
                                                </div>}
                                            </div>
                                        ))}
                                        <div className="row" style={{
                                            marginLeft: 10, marginRight: 10, display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginTop: '40px'
                                        }}>
                                            {this.state.loading_send_file == false && <button className="btn btn-success" onClick={() => {
                                                let conversas = this.state.conversas;
                                                let conversa_id = this.state.conversa_select;
                                                let receive_id = this.state.conversas_ids[conversa_id].sender.id == this.props.user.id ? this.state.conversas_ids[conversa_id].receive.id : this.state.conversas_ids[conversa_id].sender.id;

                                                let uuids = [];



                                                for (let i = 0; i < conversas.length; i++) {
                                                    if (conversas[i].id == conversa_id) {
                                                        for (let j = 0; j < this.state.files_pending.length; j++) {
                                                            let uid_msg = uuidv4();

                                                            conversas[i].mensagens.push({
                                                                'id': uid_msg,
                                                                'sender': this.props.user,
                                                                'type': this.state.files_pending[j].type.includes('image') ? 'image' : 'video',
                                                                'message': null,
                                                                'arquivo': (window.URL || window.webkitURL).createObjectURL(this.state.files_pending[j]),
                                                                'uuid': uid_msg,
                                                                'visto': undefined,
                                                                'created_at': moment().format('YYYY-MM-DD HH::mm:ss')
                                                            });
                                                            uuids.push(uid_msg);
                                                            conversas[i].last_message = this.props.user.name.split(' ')[0] + ': ' + (this.state.files_pending[j].type.includes('image') ? 'Imagem' : 'Vídeo');
                                                        }

                                                        break;
                                                    }

                                                }
                                                let files_pending = this.state.files_pending;
                                                console.log(conversas);
                                                this.setState({ mensagem: '', conversas, show_preview: false, files_pending: [] }, () => {
                                                    this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                                                    this.salvar_arquivo(files_pending, uuids, receive_id, conversa_id);
                                                });
                                            }}>
                                                Enviar fotos
                                                <FaPaperPlane style={{ fontSize: '0.9rem', color: 'white', marginLeft: 5 }} />
                                            </button>}
                                            {this.state.loading_send_file == true && <div style={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}  >
                                                <div className="spinner-border text-primary" role="status"  >
                                                </div>
                                            </div>}
                                        </div>
                                    </div>


                                </div>}
                                <div style={{ height: '630px', width: '100%' }} className="overflow-auto" id="body_msg" ref={this.messages_view}>

                                    {conversas.map((item, id) => (


                                        <div key={id} hidden={this.state.conversa_select != item.id}>



                                            {item.mensagens.map((msg, id2) => (
                                                <div key={id2}>
                                                    {(id2 == 0 || moment(item.mensagens[id2].created_at).format('YYYY-MM-DD') != moment(item.mensagens[id2 - 1].created_at).format('YYYY-MM-DD')) && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <p style={{
                                                            marginTop: 10,
                                                            textAlign: 'center',
                                                            marginBottom: 0,
                                                            padding: '5px',
                                                            border: '1px solid lightslategrey',
                                                            color: 'darkslategrey',
                                                            borderRadius: '10px',
                                                            backgroundColor: 'aliceblue',
                                                            marginTop: '10px',
                                                            paddingLeft: '10px',
                                                            paddingRight: '10px'
                                                        }}>{moment(item.mensagens[id2].created_at).format('DD/MM/YYYY')}</p>
                                                    </div>}
                                                    {msg.sender.id == this.props.user.id && <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                                        <div
                                                            style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                            <div
                                                                style={{ backgroundColor: 'aquamarine', marginRight: '0.5rem', marginLeft: '6rem', borderRadius: '5px', textAlign: 'justify', display: 'flex', alignItems: 'flex-end' }}>
                                                                {msg.type == 'text' && <p style={{ marginBottom: 0, color: 'black', marginRight: '0.5rem', padding: '5px', }}>
                                                                    {msg.message}</p>}
                                                                {msg.type == 'image' && <img onClick={() => this.visualizar(msg)} className="image_video" src={msg.arquivo} style={{ marginBottom: 0, color: 'black', marginRight: '1rem', width: '200px', maxHeight: '200px', objectFit: 'contain', marginTop: 5, marginLeft: 5, marginRight: 5, marginBottom: 5 }} />}

                                                                <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, marginRight: 3 }}>{moment(msg.created_at).tz("America/Sao_Paulo").format('HH:mm')} {msg.visto == undefined ? <FaClock style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }} /> : (msg.visto == false ? <FaCheck style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }} /> : <FaCheckDouble style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }} />)}
                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>}
                                                    {msg.sender.id != this.props.user.id && <div
                                                        style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                        <div style={{ padding: '5px', backgroundColor: 'azure', marginRight: '6rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify' }}
                                                        >
                                                            <p style={{ marginBottom: 0, color: 'blue', fontWeight: 'bold', fontSize: '1rem' }}>
                                                                {msg.sender.name}
                                                            </p>
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', minWidth: 90 }}>
                                                                {msg.type == 'text' && <p style={{ marginBottom: 0, color: 'black' }}>{msg.message}</p>}
                                                                {msg.type == 'image' && <img onClick={() => this.visualizar(msg)} className="image_video" src={msg.arquivo} style={{ marginBottom: 0, color: 'black', marginRight: '1rem', width: '200px', maxHeight: '200px', objectFit: 'contain' }} />}

                                                                <p style={{ fontSize: 13, marginBottom: 0, fontSize: 10, marginRight: 3, marginLeft: 5 }}>{moment(msg.created_at).tz("America/Sao_Paulo").format('HH:mm')}</p>
                                                            </div>
                                                        </div>
                                                    </div>}

                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                                <input ref={this.files_input} multiple accept="image/*" onChange={(e) => {
                                    let files = Array.from(e.target.files);
                                    console.log(files);
                                    if (files.length > 0) {

                                        let show_preview = true;
                                        let files_pending = files;
                                        let files_pending_preview = [];
                                        let file_select = 0;
                                        let menu_open = false;
                                        this.setState({ file_select, menu_open, show_preview, files_pending }, () => {
                                            for (let i = 0; i < files.length; i++) {
                                                let fileReader = new FileReader();
                                                fileReader.onload = (e) => {
                                                    console.log(fileReader.result);
                                                    // this.
                                                    if (this.state.show_preview == true) {
                                                        files_pending_preview[i] = { type: files[i].type.includes('image') ? 'image' : 'video', data: fileReader.result };
                                                    }
                                                    this.setState({ files_pending_preview });

                                                }
                                                fileReader.readAsDataURL(files[i]);
                                            }
                                        });

                                    }
                                    this.files_input.current.value = '';
                                }} type="file" style={{ display: 'none' }} />


                                {this.state.menu_open == true && <div style={{ position: 'absolute', right: '30px', bottom: '50px' }}>
                                    <div onClick={() => {
                                        this.files_input.current.click();
                                    }} className="hover_btn_imagem" >
                                        <FaImage style={{ color: 'white', fontSize: '18px' }} />
                                    </div>
                                </div>}
                                <div hidden={this.state.conversa_select == '' || this.props.user.tipo_usuario.permissoes['comunicacao'].escrita == false}
                                    style={{ borderBottomRightRadius: '10px', height: '50px', backgroundColor: 'rgba(255,255,255,0.5)', borderTop: '1px solid rgba(255,255,255,0.5)', padding: '5px', display: 'flex' }}>
                                    <textarea value={this.state.mensagem} onChange={(e) => {
                                        this.setState({ mensagem: e.target.value })
                                    }} onKeyPress={(e) => {
                                        if (e.key == 'Enter') {
                                            e.preventDefault();
                                            this.send_msg();
                                        }
                                    }} name="msg" placeholder="Mensagem..." className="form-control" style={{ resize: 'none', minHeight: 0, margin: 0 }} ></textarea>
                                    <div className="send_msg"
                                        onClick={() => this.setState({ menu_open: !this.state.menu_open })}
                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px', width: '30px', cursor: 'pointer', paddingRight: '10px' }}>
                                        <FaPaperclip style={{ fontSize: '1rem' }} />
                                    </div>
                                    <div className="send_msg"
                                        onClick={() => {
                                            this.send_msg();

                                        }}
                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px', width: '30px', cursor: 'pointer', paddingRight: '10px' }}>
                                        <FaPaperPlane style={{ fontSize: '1rem' }} />
                                    </div>

                                </div>
                            </div>
                        </div>}


                        <div hidden={this.state.loading == false} id="finalizar_spinner"
                            style={{ position: 'absolute', top: '0px', left: 0, right: 0, bottom: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(255,255,255,0.7)' }}>
                            <div style={{ display: 'block' }} className="spinner-border text-primary" role="status">
                            </div>
                        </div>
                    </div>
                </div >
                <Modal
                    show={this.state.modal_open == true}
                    onHide={() => this.setState({ modal_open: false })}
                >
                    <Modal.Header ><Modal.Title>Nova Conversa<br /></Modal.Title></Modal.Header>

                    {/* <div style={{ position: 'fixed', top: 10, right: 10, cursor: 'pointer' }} onClick={() => {
                        this.setState({ modal_open: false });
                    }}>
                        <i style={{ color: 'white', fontSize: '2.3rem' }} className="fas fa-times"></i>
                    </div> */}
                    {/* <div style={{ padding: 10, borderBottom: '1px solid grey' }}>
                    </div> */}
                    <Modal.Body>

                        <div>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center mt-1">
                                    <p style={{ color: 'black', textarea: 'center', fontWeight: 400, fontSize: '1rem', marginBottom: 0 }}>Envie uma mensagem para alguma pessoa
                                </p>

                                </div>

                            </div>
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Nome da pessoa</label>
                                <div className="col-sm-12">
                                    <input autoComplete="nope" onFocus={() => this.setState({ show_autocomplete_pessoa: true })} className="form-control" value={this.state.pessoa_name} placeholder="Nome da pessoa" type="text" onChange={(e) => { this.get_pessoa_search(e.target.value); this.setState({ pessoa_name: e.target.value }) }} />
                                    {this.state.show_autocomplete_pessoa == true && this.state.pessoa_name != '' && <div className="overflow-auto" style={{ width: '90%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', height: 'auto' }}>
                                        {this.state.loading_search_pessoa == false && this.state.pessoas.map((item, id) => (
                                            <div key={id} onClick={() => this.select_pessoa(item)} className="opcao_autocomplete">
                                                {item.name}
                                            </div>
                                        ))}
                                        {this.state.loading_search_pessoa == false && this.state.pessoas.length == 0 &&
                                            <div className="opcao_autocomplete">
                                                Nenhuma pessoa encontrada com este termo
                                                        </div>
                                        }
                                        {this.state.loading_search_pessoa == true &&
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                <div className="spinner-border text-primary" role="status"  >
                                                </div></div>}
                                    </div>}
                                </div>


                            </div>

                            {Object.values(this.state.pessoa_selecionada).length != 0 && <div className="form-group row">
                                <label className="col-sm-12 col-form-label">Pessoa Selecionada:</label>

                                {Object.values(this.state.pessoa_selecionada).map((item, id) => (
                                    <div key={id} className="col-sm-12 col-12 col-md-12 col-lg-12" style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                        <span>{item.name}</span>
                                        <button onClick={() => {
                                            let users = { ...this.state.pessoa_selecionada };
                                            delete (users[item.id]);
                                            this.setState({ pessoa_selecionada: users });
                                        }}
                                            className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white' }}>
                                            <MdDelete style={{ color: 'red' }} />
                                        </button>
                                    </div>
                                ))}

                            </div>}
                            {/*                             
                            <div className="form-group row">
                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Mensagem <b style={{ color: 'red' }}>*</b></label>

                                <div className="col-sm-12">
                                    <textarea value={this.state.mensagem_inicial} onChange={(e) => this.setState({ mensagem_inicial: e.target.value })} type="text" placeholder="Mensagem" className="form-control" required style={{ minHeight: 100 }} />

                                </div>
                            </div> */}

                            <p style={{ color: 'red' }}>{this.state.msgErroResposta}</p>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <button onClick={() => {
                            this.setState({ modal_open: false, pessoa_selecionada: {} })
                            // this.enviar_resposta();
                        }} type="button" className="btn btn-secondary btn-lg waves-effect waves-light">
                            <MdReply style={{ marginRight: 5 }} />
                            <span>Fechar</span>
                        </button>
                        {this.state.loading_save == false && <button onClick={() => {
                            if (Object.values(this.state.pessoa_selecionada).length == 0) {
                                this.setState({ msgErroResposta: 'O campo Nome é obrigatório' });
                                return;
                            }

                            let conversas = [...this.state.conversas];
                            let conversas_ids = { ...this.state.conversas_ids };
                            console.log(conversas);
                            let conversa_select = '';
                            for (let i = 0;i< conversas.length; i++) {
                                if (conversas[i].sender.id == Object.values(this.state.pessoa_selecionada)[0].id) {
                                    conversa_select = conversas[i].id;
                                    break;
                                }
                                else if (conversas[i].receive.id == Object.values(this.state.pessoa_selecionada)[0].id) {
                                    conversa_select = conversas[i].id;
                                    break;
                                }
                            }
                            if (conversa_select == '') {
                                conversa_select = uuidv4();
                                let conversa_ = {
                                    'id': conversa_select,
                                    'sender': this.props.user,
                                    'receive': Object.values(this.state.pessoa_selecionada)[0],
                                    'date': moment().format('YYYY-MM-DD HH:mm:ss'),
                                    'nao_vistas_sender': 0,
                                    'nao_vistas_receive': 0,
                                    'mensagens': [],
                                    'created_at': moment().format('YYYY-MM-DD HH:mm:ss'),
                                };
                                conversas.unshift(conversa_);
                                conversas_ids[conversa_select] = conversa_;
                            }

                            // if (this.state.mensagem_inicial.trim().length == 0) {
                            //     this.setState({ msgErroResposta: 'O campo Mensagem é obrigatório' });
                            //     return;
                            // }
                            this.setState({ conversas, conversa_select, modal_open: false, pessoa_selecionada: {}, conversas_ids });

                            // this.enviar_resposta();
                        }} type="button" className="btn btn-success btn-lg waves-effect waves-light">
                            <span>Conversar</span>
                            <MdSend style={{ marginLeft: 5 }} />
                        </button>}
                        {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        </div></div>}
                    </Modal.Footer>


                </Modal>






            </div>

        )
    }

    select_pessoa(item) {
        let users = {};
        if (users[item.id] == undefined) {
            users[item.id] = item;
        }
        this.setState({ pessoa_name: '', show_autocomplete_pessoa: false, pessoa_selecionada: users })
    }
}

export const mapStateToProps = (state) => ({
    token: state.AppReducer.token,
    user: state.AppReducer.user,
})

export default connect(mapStateToProps, { logout, })(ChatGeral);