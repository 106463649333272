import React from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';

const SigorRelacionadas = (props) => {

  function add_estado(id) {
    if (!props.state.estados_selecionados.filter(item => item.id === id)[0]) {
      const estado = props.state.estados.filter(item => item.id === id)[0]
      const estados = props.state.estados.filter(item => item.id !== id)

      const obj_estado = {
        id: estado.id,
        descricao: estado.descricao,
        codigo: estado.codigo
      }

      props.setState({ ...props.state, estados_selecionados: [...props.state.estados_selecionados, obj_estado], estados, estado_id: estados[0].id })
    }
  }

  function remove_estado(id) {
    const estados_selecionados = props.state.estados_selecionados.filter(item => item.id !== id)
    const devolution = props.state.all_estados.filter(item => item.id === id)[0]
    let estados = [devolution, ...props.state.estados]

    props.setState({ ...props.state, estados_selecionados, estados, estado_id: estados[0].id })
  }

  return (
    <div>
      {!props.state.loading ? <div className="form-group row">
        <label className="col-sm-12 col-form-label">Estados Relacionais</label>
        <div className="input-group col-sm-12">
          <select value={props.state.estado_id} className="form-control" id="cameras" onChange={(e) => props.setState({ ...props.state, estado_id: e.target.value })}>
            {props.state.estados.map(item => (
              <>{!item.relation && <option value={item.id}>{item.descricao}</option>}</>
            ))}
          </select>
          <button className='btn btn-primary' onClick={() => add_estado(props.state.estado_id)}>
            <BsPlusLg />
          </button>
        </div>
      </div>
        :
        <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
          <div className="spinner-border text-primary" role="status"></div>
        </div>}

      {props.state.estados_selecionados.length > 0 &&
        <div className="row my-3">
          <div className="col-12">
            <table className='table table-striped table-hover text-center'>
              <thead>
                <th>Nome</th>
                <th>Código</th>
                <th>Ações</th>
              </thead>
              <tbody>
                {props.state.estados_selecionados.map(item2 => (
                  <tr key={item2.id}>
                    <td>{item2.descricao}</td>
                    <td>{item2.codigo}</td>
                    <td><button className="btn btn-danger btn-small btn-circle m-r-5"
                      onClick={() => remove_estado(item2.id)}>
                      <FaTrash color='white' />
                    </button>
                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </div>}
    </div>
  )
}

export default SigorRelacionadas