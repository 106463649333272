import { MdSearch } from "react-icons/md";

export default function BuscarCDF({ handleCDFNumber, findCDF, searchCDF }) {
    return (
        <div className="d-flex flex-column">
            <div className="input-group" style={{ maxWidth: '400px' }}>
                <input type="text" className="form-control" placeholder="Número do CDF" onChange={handleCDFNumber} value={searchCDF.value} />
                <div className="input-group-append">
                    <button className="btn btn-primary" type="button" onClick={findCDF} disabled={searchCDF.loading}>
                        <MdSearch /> Buscar CDF
                    </button>
                </div>
            </div>
            {searchCDF.error && <span className="text-danger small">{searchCDF?.error}</span>}
        </div>
    )
}