
import React from 'react';
import Destinacoes from './DestinacaoInternaGeral';
import DestinacoesFinalizadas from './DestinacaoInternaFinalizadas';
import Filter from '../../../Auxiliar/Filter';
import { maskDate } from '../../../Auxiliar/Masks';
import DestinacaoInternaFiscais from './DestinacaoInternaFiscais';
import ListaDestinacaoInternaColeta from '../../Relacionamentos/Internos/Coleta/ListaDestinacaoInternaColeta';

const SelecionarJsDestinacao = () => {
    const [search, setSearch] = React.useState(false)
    const [dateOf, setDateOf] = React.useState('')
    const [dateFor, setDateFor] = React.useState('')
    const [options, setOptions] = React.useState({
        destinacaoPendente: { value: true, label: 'Destinações Pendentes', checked: true },
        destinacao: { value: false, label: 'Destinações Finalizadas', checked: false },
        destinacaoFiscal: { value: false, label: 'Destinações com pendências fiscais', checked: false },
        destinacaoRelacionamentoInterno: { value: false, label: 'Destinações de Relacionamentos Internos', checked: false },
    })

    return (
        <div className="card">
            <div className="card-body" id="card">
                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Destinações</h4>
                <Filter setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setSearch} />
                <div className="d-flex small align-items-center mb-3">
                    <p className='bold me-2 m-0' style={{ color: 'black' }}>Filtros ativos: </p>
                    {options.destinacaoPendente.value &&
                        <p className='m-0'>Pendentes</p>}
                    {options.destinacao.value &&
                        <p className='m-0'>Finalizadas</p>}
                    {options.destinacaoFiscal.value &&
                        <p className='m-0'>Dados fiscais pendentes</p>}
                    {options.destinacaoRelacionamentoInterno.value &&
                        <p className='m-0'>Relacionamentos Internos</p>}
                    {dateOf && <p className='ms-1 m-0'> - de {maskDate(dateOf)}</p>}
                    {dateFor && <p className='ms-1 m-0'> - até {maskDate(dateFor)}</p>}
                </div>
                <div>
                    <p className='m-0' style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados as destinações do sistema</p>
                </div>
                <div className="row">
                    {options.destinacaoPendente.value &&
                        <Destinacoes dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                    {options.destinacao.value &&
                        <DestinacoesFinalizadas dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                    {options.destinacaoFiscal.value &&
                        <DestinacaoInternaFiscais dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                    {options.destinacaoRelacionamentoInterno.value &&
                        <ListaDestinacaoInternaColeta dateOf={dateOf} dateFor={dateFor} />}
                </div>
            </div>
        </div>
    )
}

export default SelecionarJsDestinacao