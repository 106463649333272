import React from 'react';
import Submateriais from './SelecionarSubmateriais';
import Relatorios from './Relatorios';
import Filter from '../../../Auxiliar/Filter';


const SelecionarJsUnidade = () => {
    const [search, setSearch] = React.useState(false)
    const [dateOf, setDateOf] = React.useState('')
    const [dateFor, setDateFor] = React.useState('')
    const [options, setOptions] = React.useState({
        submaterial: { value: true, label: 'Registros de Submateriais', checked: true },
        relatorio: { value: false, label: 'Registros de Relatórios', checked: false },
    })

    return (
        <div className="card">
            <div className="card-body" id="card">
                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Relatórios</h4>
                <Filter setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setSearch} />
                <div>
                    <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados os relatórios do sistema</p>
                </div>
                <div className="row">
                    {options.submaterial.value &&
                        <Submateriais dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                    {options.relatorio.value &&
                        <Relatorios dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
                </div>
            </div>
        </div>
    )
}

export default SelecionarJsUnidade