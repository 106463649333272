import React, { useEffect, useState } from "react";
import moment from "moment";

import { BsFillCircleFill } from "react-icons/bs";
import { useHistory, useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  IconButton,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import { MdDelete } from "react-icons/md";
import SweetAlert from "react-bootstrap-sweetalert";
import { NAME, URLopen } from "../variables";
import { notificationCounter } from "../actions/AppActions";
import { useDispatch, useSelector } from "react-redux";

function Notificacao(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const notificationsCount = useSelector(
    (state) => state.AppReducer.notifications
  );

  function openContent(id) {
    fetch(`${URLopen}api/auth/blueen/notification/visualize/${id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        connection: NAME,
      })
    }).then(async (responseLog) => {
      let resp = await responseLog.json();
      if (resp.status) {
        dispatch(notificationCounter(notificationsCount - 1));
      }
    });
    history.push(props.item.content.path);
  }

  function deleteNotification() {
    fetch(`${URLopen}api/auth/blueen/notification/destroy/${props.item.id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${props.reducer.token}`,
      },
    })
      .then(async (responseLog) => {
        let resp = await responseLog.json();
        if (!resp.visualized) {
          dispatch(notificationCounter(notificationsCount - 1));
        }

        let notificationContent = [...props.state.notificationContent];
        notificationContent = notificationContent.filter(
          (item2, id2) => item2.id != props.item.id
        );

        props.setState({ ...props.state, notificationContent, warning: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <Box>
      <ListItemButton className="d-flex align-items-center row py-2 my-1">
        {/* <span
          className="m-2"
          style={{ border: "1px solid red", width: "8vh", height: "8vh" }}
        ></span> */}
        <div className="mt-2 col-11" onClick={() => openContent(props.item.id)}>
          <div className="d-flex justify-content-between">
            <h5 className="fw-bold">{props.item.content.title}</h5>
            <span className="text-muted small">
              {moment(props.item.created_at).format("DD/MM/YYYY")}
              {!props.item.visualized && (
                <BsFillCircleFill size={10} className="mx-2" color="#f8333c" />
              )}
            </span>
          </div>
          <div
            style={{
              fontSize: "1.75vh",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxHeight: "5em",
            }}
          >
            <strong>{props.item.sender}</strong>: {props.item.content.message}
          </div>
        </div>
        <div className="col-1 mt-3 d-flex align-items-center justify-content-center">
          <Tooltip title="Deletar notificação" placement="right">
            <IconButton
              size="small"
              color="error"
              style={{
                marginRight: "1vh",
              }}
              onClick={() => {
                props.setState({ ...props.state, warning: true });
              }}
            >
              <MdDelete />
            </IconButton>
          </Tooltip>
        </div>
      </ListItemButton>
      <SweetAlert
        danger
        showCancel
        title={"Atenção"}
        onConfirm={() => {
          deleteNotification();
        }}
        onCancel={() => {
          props.setState({ ...props.state, warning: false });
        }}
        show={props.state.warning}
        confirmBtnText="Sim, desejo apagar"
        cancelBtnText="Cancelar"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="secondary"
        focusCancelBtn
      >
        Tem certeza que deseja deletar essa notificação?
      </SweetAlert>
    </Box>
  );
}

export default Notificacao;
