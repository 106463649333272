import React, { Component } from 'react';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

class EditarEstadoSigor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      redirect: false,
    };
  }

  render() {
    return (
      <div className="row">
        <div>
          <div className="row">
            <div className="col-12">
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">Código <b style={{ color: 'red' }}>*</b></label>
                <div className="col-sm-12">
                  <input className="form-control" placeholder="Código do estado físico (Ex: G, L, etc)" type="text"
                    onChange={(e) => this.props.setState({ ...this.props.state, codigo: e.target.value })} value={this.props.state.codigo} />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-12 col-form-label">Descrição <b style={{ color: 'red' }}>*</b></label>
                <div className="col-sm-12">
                  <textarea className="form-control" placeholder="Descrição do estado físico (Ex: Gasoso, Líquido, etc)"
                    type="text" onChange={(e) => this.props.setState({ ...this.props.state, descricao: e.target.value })}
                    value={this.props.state.descricao} disabled>
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapsStateToProps = (state) => ({
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(EditarEstadoSigor);
