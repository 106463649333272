import React from 'react'
import { MdEdit, MdReply } from 'react-icons/md'
import Label from '../../../Auxiliar/Label'
import { GET } from '../../../Auxiliar/Requests'
import { StatusColeta } from '../../../Configs/Status'
import FinishDestinationModal from './FinishDestinationModal'
import StatusStepper from '../../../Auxiliar/StatusStepper'
import { maskDate, maskNumberBr, moneyMask, moneyMask2, numberLabelMask } from '../../../Auxiliar/Masks'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Chip } from '@mui/material'
import { URL } from '../../../variables'
import { NumericFormat } from 'react-number-format'
import { toast, ToastContainer } from 'react-toastify';
import ControlMtr from '../../../Home/ColetaTrasporte/MTR/ControlMtr';
import { useSelector } from 'react-redux';
import BuscarMTR from './components/BuscarMTR';

import SweetAlert from 'react-bootstrap-sweetalert';
import { EdgesensorHighSharp, Padding } from '@mui/icons-material'
const ViewDetails = () => {
  const history = useHistory()
  const { processType, id } = useParams()


  const [data, setData] = React.useState('')
  const [pesagens, setPesagens] = React.useState([])

  const [loading, setLoading] = React.useState(true)
  const token = useSelector((store) => store.AppReducer.token);
  const [state, setState] = React.useState({
    active: [],
    canceled: [],
    codigoManifesto: '',
    pdf: '',
    pagination: '',
    pageNumber: 1,
    loading: true,
    loadingSearch: false,
    reload: false,
    search: '',
    showWarningCancel: false,
    loadingCancel: false,
    mtrToCancel: '',
    showWarningDelete: false,
    loadingDelete: false,
    mtrToDelete: '',
    searchMtr: {
      loading: false,
      error: null,
      value: ''
    },
    show_warning: false,
    show_warning_desvincular_mtr: false,
    msgErro: '',
  })
  React.useEffect(() => {
    getData()
  }, [])

  //-------------------------*-------------------------
  const getData = async () => {
    let response = await GET({ url: `${processType}/view/${id}` })
    console.log(response)
     
    if (!response) {
      console.log('error')
      history.push('/acompanhar_etapas')
      return
    }
    // let pesagens = [];
    // response.data.collect_materials = response.data.materials.map((item, id) => {
    //   let material = response.data.materials.filter((item2, id2) => item2.residuo_rejeito_id == item.residuo_rejeito_id && item.solicitacao_id == item2.solicitacao_id);
    //   let obj = {}
    //   if (material.length > 0) {
    //     obj = { ...material[0] };
    //   }
    //   else {
    //     obj = { ...item };
    //   }
    //   obj.status = material.length == 0 ? 'Proveniente da solicitação' : (material[0].weight == item.weight ? 'Confirmado pelo motorista' : 'Alterado pelo motorista');
    //   let hasRegister = pesagens.filter(item2 => item2 === item.residuo_rejeito_id)[0]
    //   if (!hasRegister) pesagens = [...pesagens, item.residuo_rejeito_id]

    //   return obj;
    // })

    // let PesoTotal = response.data.collect_materials.reduce((total, item2) => total + parseFloat(item2.peso_kg), 0) || 0;
    // let VolumeTotal = response.data.collect_materials.reduce((total, item2) => total + parseFloat(item2.volume_lt), 0) || 0;
    // let ReceitaTotal = response.data.collect_materials.reduce((total, item2) => {
    //   if (item2.movimentacao === 'Receita') {
    //     return total + parseFloat(item2.residuo_rejeito.valor_saldo_financeiro) * parseFloat(item2.peso)
    //   } else {
    //     return total
    //   }
    // }, 0)

    // let DespesaTotal = response.data.collect_materials.reduce((total, item2) => {
    //   if (item2.movimentacao === 'Despesa') {
    //     return total + parseFloat(item2.residuo_rejeito.valor_saldo_financeiro) * parseFloat(item2.peso)
    //   } else {
    //     return total
    //   }
    // }, 0)

    // const mappedMaterials = response.data.collect_materials.map(item => {
    //   const material = item.residuo_rejeito

    //   return {
    //     ...item,
    //     "equivalencia_peso": material.unidade_principal.tipo === 'Peso'
    //       ? material.equivalencia
    //       : material.unidade_secundaria.equivalencia,
    //     "equivalencia_volume": material.unidade_principal.tipo !== 'Peso'
    //       ? material.equivalencia
    //       : material.unidade_secundaria.equivalencia,
    //     "peso_solicitado": item.peso,
    //     "volume_solicitado": item.volume,
    //     "peso_kg_solicitado": item.peso_kg,
    //     "volume_lt_solicitado": item.volume_lt,
    //   }
    // })
    // response.data.collect_materials = mappedMaterials


    setData(response.data)
    setLoading(false)
  }
  const getCdf = async (codigo_cdf) => {

    return fetch(`${URL}api/get_download_cdf/${codigo_cdf}`, {
      method: 'GET',
      headers: {
        Accept: 'application/pdf',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/pdf',
      }
    })
      .then(async (response) => {
        response.blob().then(blob => download(blob, codigo_cdf))
      })
  }
  const desvinculaMtrAoDespacho = async ( ) => {
 
    return fetch(`${URL}api/post_desvincular_mtr_ao_despacho`, {
      method: 'POST',
      headers: {
        Accept: 'application/pdf',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        dispachoId: id 
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null) {
            toast.warning('Erro ao desvincular MTR!');
            setState({
              ...state, show_warning_desvincular_mtr: false
            });
          }
          else {

            data.documents.mtr = '';
            setState({
              ...state, show_warning_desvincular_mtr: false
            });

            toast.success('MTR vinculado com sucesso');
          }
        } catch (err) {
          console.log(err);
          toast.warning('Erro ao desvincular MTR!');
          setState({
            ...state, show_warning_desvincular_mtr: false
          });
        }

      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
      });
  }
  function download(blob, filename) {
    const mimeType = blob.type;

    if (mimeType === 'application/pdf') {
      console.log('O arquivo é um PDF.');

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } else if (mimeType === 'text/plain') {
      console.log('O arquivo é um TXT.');
    } else {
      console.log('O arquivo é de outro tipo: ' + mimeType);
    }
  }

  const getMtr = async (cod) => {
    return fetch(`${URL}api/get_download_mtr/${cod}`, {
      method: 'GET',
      headers: {
        Accept: 'application/pdf',
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/pdf',
      }
    })
      .then(async (response) => {

        response.blob().then(blob => download(blob, cod))
      })

  }
  const vinculaMtrAoDespacho = async () => {

    if (state.searchMtr.value != '') {

      return fetch(`${URL}api/post_vincular_mtr_ao_despacho`, {
        method: 'POST',
        headers: {
          Accept: 'application/pdf',
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          dispachoId: id,
          mtr: state.searchMtr.value
        }),
      })
        .then(async (responseLog) => {
          try {
            let resp = await responseLog.json();
            if (resp.errors != null) {
              toast.warning('Erro ao vincular MTR!');
              setState({
                ...state, show_warning: false
              });
            }
            else {

              data.documents.mtr = state.searchMtr.value;
              setState({
                ...state, show_warning: false
              });

              toast.success('MTR vinculado com sucesso');
            }
          } catch (err) {
            console.log(err);
            toast.warning('Erro ao vincular MTR!');
            setState({
              ...state, show_warning: false
            });
          }

        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
        });
    }
  }
  const handleMTRNumber = (e) => {
    setState({
      ...state, searchMtr: {
        loading: false,
        error: null,
        value: e.target.value
      }
    });
  }

  const findMTR = async () => {
    if (!state.searchMtr.value || state.searchMtr.value == '') {
      setState({
        ...state, searchMtr: {
          loading: false,
          error: "Digite o número do MTR para buscá-lo...",
          value: ''
        }
      });
      return;
    }

    setState({
      ...state, searchMtr: {
        loading: true,
        error: null,
        value: state.searchMtr.value
      }
    });
    setState({
      ...state, show_warning: true
    });
    getMtr(state.searchMtr.value);
    console.log('número MTR::', state.searchMtr.value)
    return;
  }

  return (

    <div className="card">
      <div className="card-body" id="card">
        {!loading && data && <>
          <h1 className="mb-4 header-title" style={{ fontSize: '1.5rem' }}>Detalhes da Solicitação</h1>
          <Label title='Status' text={data.status} />
          <div className="my-3" style={{ overflowY: 'scroll' }}>
            <StatusStepper statusIndex={StatusColeta.getKeyByValue(data.status)} />
          </div>
          <Label title={data.stock ? 'Estoque' : 'Destino'} text={data.stock ?? data.destiny} />
          <hr className='my-5' />
          <div className="mt-4">
            <h2 className="header-title" style={{ fontSize: '1.2rem', color: 'black' }}>Dados das solicitações em aberto</h2>
            {data.collect_requests.map(item => (
              <div className='mb-5' key={item.id}>
                <Label title='Solicitação de coleta' text={item.code} />
                <Label title='Ponto de coleta' text={item.collect_point} />
                <Label title='Responsável' text={item.responsible} />
                <Label title='Materiais selecionados na solicitação' text='' />
                <table className="table table-striped table-hover"  >
                  <thead>
                    <tr>
                      <th>Material</th>
                      <th>Peso</th>
                      <th>Volume</th>
                      <th>Peso Solicitado no despacho</th>
                      <th>Volume Solicitado no despacho</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.request_materials.map(material => (
                      <tr key={item.id + material.id}>
                        <td>{material.name}</td>
                        <td>{material.weight + ' ' + material.weightUnity}</td>
                        <td>{material.volume + ' ' + material.volumeUnity}</td>
                        <td>{material.requested_weight}</td>
                        <td>{material.requested_volume}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
          <hr className='my-5' />
          <div className="mt-4">
            <h2 className=" header-title" style={{ fontSize: '1.2rem', color: 'black' }}>Dados do despacho</h2>
            <div className="d-flex align-items-center">
              <Label title='Situação' text='' spaced={false} />
              <Chip
                label={data.status}
                color={StatusColeta.getPallete(data.status)}
                size="small"
                className='ms-2'
              />
            </div>
            <Label title='Código' text={data.code} />
            <Label title='Data de criação' text={maskDate(data.created_at)} />
            <Label title='Agendado Para' text={data.scheduled_to ? maskDate(data.scheduled_to) : 'Não marcado'} />
            <Label title='Iniciado em' text={data.started_at ? maskDate(data.started_at) : 'Não iniciado'} />
            <Label title='Finalizado em' text={data.finished_at ? maskDate(data.finished_at) : 'Não finalizado'} />
            <Label title='Peso total' text={numberLabelMask(data.total_weight)} />
            <Label title='Volume total' text={numberLabelMask(data.total_volume)} />
            <Label title='Receita' text={moneyMask2(data.total_receipt)} />
            <Label title='Despesa' text={moneyMask2(data.total_expense)} />
            <Label title='Saldo' text={moneyMask2(data.balance)} />
            <Label title='Materiais selecionados' text='' />
            <table className="table table-striped table-hover"  >
              <thead>
                <tr>
                  <th>Material</th>
                  <th>Peso</th>
                  <th>Volume</th>
                  <th>Ponto Coleta</th>
                </tr>
              </thead>
              <tbody>
                {data.materials.map(item => (
                  <tr key={item.collect_point + item.id}>
                    <td>{item.name}</td>
                    <td>{(item.weight) + ' ' + item.weightUnity}</td>
                    <td>{item.volume + ' ' + item.volumeUnity}</td>
                    {/* <td>{numberLabelMask(item.weight) + ' ' + item.weightUnity}</td>
                    <td>{numberLabelMask(item.volume) + ' ' + item.volumeUnity}</td> */}
                    <td>{item.collect_point}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <hr className='my-5' />
          <div className="mt-4">
            <h2 className=" header-title" style={{ fontSize: '1.2rem', color: 'black' }}>Dados do transporte</h2>
            <Label title='Transportadora' text={data.carrier} />
            <Label title='Veículos/Motoristas selecionados' text='' />
            <table className="table table-striped table-hover" >
              <thead>
                <tr>
                  <th>Veículo</th>
                  <th>Motorista</th>
                  <th>Capacidade Peso</th>
                  <th>Capacidade Volume</th>
                </tr>
              </thead>
              <tbody>
                {!data.vehicles.length ?
                  <tr>
                    <td colSpan="4" style={{ textAlign: 'center' }}>Nenhum Veículo/Motorista registrado</td>
                  </tr>
                  :
                  data.vehicles.map(item => (
                    <tr key={item.id}>
                      <td>{item.vehicle}</td>
                      <td>{item.driver}</td>
                      <td>{numberLabelMask(item.weight_capacity)}</td>
                      <td>{numberLabelMask(item.volume_capacity)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <Label title='Peso total Veículo(s)' text={numberLabelMask(data.total_weight_capacity)} />
            <Label title='Volume total Veículo(s)' text={numberLabelMask(data.total_volume_capacity)} />
            <Label title='Observações' text={data.description ?? 'Sem observações'} />
          </div>
          <hr className='my-5' />
          <h2 className="header-title" style={{ fontSize: '1.2rem', color: 'black' }}>Informações fiscais</h2>
          {data.documents.nf.key ?
            <>
              <div className="d-flex">
                <Label title='Chave nota' text={data.documents.nf.key} spaced={false} />
                <Label title='Número nota' text={data.documents.nf.number} className='ms-2' spaced={false} />
                <Label title='Série nota' text={data.documents.nf.serie} className='ms-2' spaced={false} />
              </div>
              {data.documents.nf.document &&
                <div className="row">
                  <iframe style={{ height: 1000 }} src={URL + 'storage/' + data.documents.nf.document} frameborder="0" />
                </div>}
            </> :
            <Label title='Nota físcal' text='Sem registros' />}

          <div>
            {
              data.documents.mtr && data.documents.mtr_erros ?
                <div className="mt-3 text-danger">
                  MTR:{data.documents.mtr_erros}
                </div>
                :
                (data.documents.mtr ?
                  <>
                    <span className='bold'>MTR:</span>{' '}
                    <button
                      onClick={() => getMtr(data.documents.mtr)}

                      className="btn btn-success">Baixar MTR</button>

                    <button style={{ marginLeft: '2px' }}
                      onClick={() =>  setState({
                        ...state, show_warning_desvincular_mtr: true
                      })}

                      className="btn btn-danger">Desvincular MTR</button>
                    {/* {data.documents.mtr &&
                        <ControlMtr state={this.state} setState={(e) => this.setState(e)} type='active' getMtr={(e) => this.getMtr(e)} />} */}
                  </>
                  :
                  <>
                    <span className='bold'>MTR:</span>{' '}
                    <BuscarMTR handleMTRNumber={handleMTRNumber} findMTR={findMTR} searchMtr={state.searchMtr} />
                  </>
                )

            }
            <SweetAlert
              warning
              title={'Atenção'}
              onConfirm={() => vinculaMtrAoDespacho()}
              onCancel={() => {
                setState({
                  ...state, show_warning: false
                });
              }}
              show={state.show_warning}
              confirmBtnText="Sim"
              cancelBtnText="Cancelar"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              showCancel={true}
            >
              Deseja vicular esse MTR ao Despacho?
            </SweetAlert>
            <SweetAlert
              warning
              title={'Atenção'}
              onConfirm={() => desvinculaMtrAoDespacho()}
              onCancel={() => {
                setState({
                  ...state, show_warning_desvincular_mtr: false
                });
              }}
              show={state.show_warning_desvincular_mtr}
              confirmBtnText="Sim"
              cancelBtnText="Cancelar"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              showCancel={true}
            >
              Deseja desvincular esse MTR ao Despacho?
            </SweetAlert>

          </div>

          <div className="mt-2">
            {
              data.documents.cdf && data.documents.cdf.lista_erros ?
                <div className="mt-3 text-danger">
                  {data.documents.cdf.lista_erros}
                </div>
                :
                (data.documents.cdf ?
                  <>
                    <span className='bold'>CDF:</span>{' '}
                    <button className="btn btn-success" onClick={() => getCdf(data.documents.cdf.numeroCDF)}>Baixar CDF</button>
                  </>
                  : <Label title='CDF' text={data.documents.cdf ?? 'Sem registros'} />)
            }

          </div>
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-secondary btn-lg waves-effect waves-light"
              onClick={() => history.goBack()}>
              <MdReply /> Voltar
            </button>
            <div className="d-flex justify-content-center justify-content-sm-end" >

            {data.rotas_path != null && <a target="_blank" href={URL + 'storage/' + data.rotas_path} className="btn btn-success btn-lg waves-effect waves-light justify-content-sm-end"
                                            style={{ marginRight: '5px' }}>Visualizar Rota </a>}
            

            {(data.status == 'Agendado' || data.status == 'Rascunho') && <button
              type="button"
              className="btn btn-info btn-lg waves-effect waves-light"
              onClick={() => history.push('/despacho/editar/interno/' + data.id)}>
              <MdEdit /> Ir para Edição
            </button>}

            {(data.status == 'Iniciado' || data.status == 'Retirado' || data.status == 'Recebido') && <button
              type="button"
              className="btn btn-info btn-lg waves-effect waves-light"
              onClick={() => history.push('/despachos_abertos/receber_coleta/' + data.id)}>
              <MdEdit /> Ir para Recebimento
            </button>}

            {(processType === 'destinacao' && !data.finished_at && data.status === 'Iniciado') && <FinishDestinationModal data={data} />}
            </div>
          
            </div>
        </>}

        {loading &&
          <div className="col-12 d-flex justify-content-center align-items-center py-5">
            <div className="spinner-border text-primary" role="status"></div>
          </div>}
      </div>
    </div>


  )


}

export default ViewDetails