import { Box, IconButton, Modal, ThemeProvider } from '@mui/material';
import React from 'react'
import { MdClose } from 'react-icons/md';
import Theme from '../../Theme';

function BlueenOpenModal() {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [height, setHeight] = React.useState(0)
    const [rerender, setRerender] = React.useState(0)
    const content = React.useRef()
    const modalRef = React.useRef()

    //* Modal Texts
    let textIntro = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae nisl vitae nisl lacinia aliquam. Sed euismod, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl`;
    let textStock = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae nisl vitae nisl lacinia aliquam. Sed euismod, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl`;
    let textDestination = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae nisl vitae nisl lacinia aliquam. Sed euismod, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl`;
    let textCarrier = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae nisl vitae nisl lacinia aliquam. Sed euismod, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl`;
    let textIndDriver = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae nisl vitae nisl lacinia aliquam. Sed euismod, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl vitae lacinia aliquam, nisl`;


    return (
        <>
            <a onClick={handleOpen} style={{
                textDecoration: 'none',
                color: '#73B054',
                cursor: 'pointer'
            }} > clique aqui e veja quais funcionalidades a empresa pode ter.</a>
            <Modal
                ref={modalRef}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ThemeProvider theme={Theme}>
                    <Box>
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-header text-center">
                                    <h4 className="modal-title">
                                        Sobre a Blueen Open
                                    </h4>
                                    <IconButton
                                        onClick={handleClose}
                                        type="button"
                                        data-dismiss="modal"
                                        aria-label="Fechar"
                                    >
                                        <MdClose
                                            titleAccess="Fechar"
                                            color="error"
                                            aria-hidden="true"
                                        >
                                            &times;
                                        </MdClose>
                                    </IconButton>
                                </div>
                                <div className="modal-body mx-3">
                                    <p>{textIntro}</p>
                                    <h4 className="modal-title mt-3">Estoque</h4>
                                    <p>{textStock}</p>
                                    <h4 className="modal-title mt-3">Destino</h4>
                                    <p>{textDestination}</p>
                                    <h4 className="modal-title mt-3">Transportadora</h4>
                                    <p>{textCarrier}</p>
                                    <h4 className="modal-title mt-3">
                                        Motorista Independente
                                    </h4>
                                    <p>{textIndDriver}</p>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn text-light bg-primary"
                                        onClick={handleClose}
                                    >
                                        Entendi
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </ThemeProvider>
            </Modal>
        </>
    );
}

export default BlueenOpenModal