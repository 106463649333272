//* React
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

//* Components
import { Tooltip } from "@mui/material";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdReply } from "react-icons/md";

//* Utils
import { GET } from "../../../../Auxiliar/Requests";
import { maskDate, maskNumberBr } from "../../../../Auxiliar/Masks";

export default function AcompanharSolicitacaoColeta() {
  const { id } = useParams();
  const [collect, setCollect] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const response = await GET({ url: `get_view_solicitar_coleta/${id}` });

    if (!response || response.error) {
      console.error("Erro ao trazer informações da coleta");
      setLoading(false);
      return;
    }
    setCollect(response.solicitacoes_edit);
    setLoading(false);
    console.log("response", response.solicitacoes_edit);
  }

  return (
    <div className="row">
      {!loading ? (
        <div className="col-12">
          <div className="card">
            {collect ? (
              <div className="card-body" id="card">
                <h4
                  className="mt-0 header-title"
                  style={{ fontSize: "1.5rem", color: "black" }}
                >
                  Solicitação de Coleta
                </h4>
                <p style={{ fontSize: "1rem", color: "black" }}>
                  Solicitação de Coleta em andamento{" "}
                  <Tooltip title="Realize um despacho para dar sequência a essa coleta.">
                    <a
                      className="text-secondary"
                      style={{ padding: 0, margin: 0 }}
                    >
                      <BsInfoCircleFill />
                    </a>
                  </Tooltip>
                </p>
                <p style={{ fontSize: "1rem" }}>
                  Essa solicitação de coleta é uma destinação no ambiente do
                  ponto de coleta selecionado, pois se trata da uma função de
                  Relacionamentos Internos.
                  <Tooltip
                    title=" Observação: Algumas informações podem ter base em dados do
                  ambiente relacionado, como peso, total, etc."
                  >
                    <a
                      className="text-secondary"
                      style={{ padding: 0, margin: 0 }}
                    >
                      <BsInfoCircleFill />
                    </a>
                  </Tooltip>
                </p>
                <br />
                <div className="row">
                  <div className="col-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Ponto de Coleta:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {collect?.ponto_coleta.razao_social}
                        </span>
                      </label>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Responsável:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {collect?.responsavel.name}
                        </span>
                      </label>
                    </div>
                    <hr />
                    <label
                      className="col-sm-12 col-form-label"
                      style={{ color: "black", fontSize: "1.5rem" }}
                    >
                      Dados do estoque:
                    </label>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Estoque destinado:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          Informar na realização do despacho
                        </span>
                      </label>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-12 col-form-label"
                        style={{ color: "black" }}
                      >
                        Materiais selecionados:
                      </label>

                      <table
                        id="tech-companies-1"
                        className="table table-striped table-hover"
                      >
                        <thead>
                          <tr>
                            <th>Material</th>
                            <th>Tipo</th>
                            <th>Peso</th>
                            <th>Volume</th>
                          </tr>
                        </thead>
                        <tbody>
                          {collect?.material.map((item, index) => (
                            <tr key={index}>
                              <td>{item.residuo_rejeito.nome}</td>
                              <td>{item.residuo_rejeito.tipo}</td>
                              <td>
                                {maskNumberBr(item.peso)}{" "}
                                {item.residuo_rejeito.unidade_principal.tipo ==
                                "Peso"
                                  ? item.residuo_rejeito.unidade_principal.sigla
                                  : item.residuo_rejeito.unidade_secundaria
                                      .sigla}
                              </td>
                              <td>
                                {maskNumberBr(item.volume)}{" "}
                                {item.residuo_rejeito.unidade_principal.tipo ==
                                "Peso"
                                  ? item.residuo_rejeito.unidade_secundaria
                                      .sigla
                                  : item.residuo_rejeito.unidade_principal
                                      .sigla}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div id="despacho" className="col-12">
                      <h4>
                        <strong>Dados do Despacho</strong>
                      </h4>
                      <div id="situation" className="mt-4">
                        <div className="form-group row">
                          <div className="col-12">
                            <label className="col-4 col-form-label fw-bold">
                              Status:
                            </label>
                            {collect?.status || "Não informado"}
                          </div>
                          <div className="col-12">
                            <label className="col-4 col-form-label fw-bold">
                              Data de Coleta:
                            </label>
                            {maskDate(collect?.data) || "Não informado"}
                          </div>
                          <div className="col-12">
                            <label className="col-4 col-form-label fw-bold">
                              Peso total (Kg):
                            </label>
                            {maskNumberBr(collect?.total_kg)} Kg
                          </div>
                          <div className="col-12">
                            <label className="col-4 col-form-label fw-bold">
                              Volume total (L):
                            </label>
                            {maskNumberBr(collect?.total_lt)} L
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div
                      className="col-12 col-sm-12 d-flex justify-content-center justify-content-sm-start"
                      style={{ marginBottom: "0.5rem" }}
                    >
                      <Link to="/solicitar_coleta">
                        <span className="btn btn-secondary btn-lg waves-effect waves-light">
                          <MdReply /> Voltar
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>Erro inesperado, coleta não encontrada.</div>
            )}
          </div>
        </div>
      ) : (
        <div
          className="col-12 d-flex justify-content-center align-items-center"
          style={{ marginTop: "2rem" }}
        >
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      )}
    </div>
  );
}
