import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { logout } from "../../../actions/AppActions";
import { connect } from "react-redux";

// users
import { URL } from "../../../variables";
import { MdReply } from "react-icons/md";
import { maskNumberBr } from "../../../Auxiliar/Masks";

class ViewPontoColeta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: "/pontos_coleta",
      loading_screen: false,
      responsaveis: [],
      responsavel_id: "",

      cnpj: "",
      razao_social: "",
      nome_fantasia: "",
      numero_telefone: "",

      numero: "",
      cep: "",
      bairro: "",
      cidade: "",
      estado: "",
      logradouro: "",
      complemento: "",

      loading_save: false,
      msgErro: "",
      pessoas_selecionadas: {},
      pessoas: {},
      pessoa: "",

      materiais: [],

      capacidade_maxima_peso: "",
      capacidade_maxima_volume: "",
      unidade_peso: "",
      unidade_volume_id: "",
    };
  }
  componentDidMount() {
    this.get_ponto(this.props.match.params.id);
  }

  get_ponto(id) {
    if (this.state.loading_screen == false) {
      this.setState({ loading_screen: true });
    }
    fetch(`${URL}api/get_ponto/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      },
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.message == "Unauthenticated.") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            // this.setState({redirect:true,path:'/'});
            this.props.logout();
            return;
          }
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            this.setState({ redirect: true });
          } else {
            // Unificar os arrays de residuos e rejeitos
            let todosMateriais = resp.ponto.residuos.concat(
              resp.ponto.rejeitos
            );

            // Criar um novo array com os atributos desejados
            let materiais_selecionados = todosMateriais.map((material) => ({
              id: material.id,
              nome: material.nome,
              tipo: material.tipo,
              valor_saldo_financeiro: material.valor_saldo_financeiro,
              saldo_financeiro: material.saldo_financeiro,
            }));

            let pessoas_selecionadas = {};
            for (let i = 0; i < resp.pessoas_selecionadas.length; i++) {
              pessoas_selecionadas[resp.pessoas_selecionadas[i].user_id] = {
                id: resp.pessoas_selecionadas[i].user_id,
                ponto_id: resp.pessoas_selecionadas[i].ponto_id,
                user_id: resp.pessoas_selecionadas[i].user_id,
                name: resp.pessoas_selecionadas[i].users.name,
                notificacoes: resp.pessoas_selecionadas[i].notificacoes,
              };
            }

            let pessoas = {};
            for (let i = 0; i < resp.autorizadas.length; i++) {
              pessoas[resp.autorizadas[i].id] = resp.autorizadas[i];
            }

            //troca de autorizadas no backend para usuários com e sem permissão, e responsáveis estou passando os mesmos valores das "autorizadas"
            this.setState({
              loading_screen: false,
              responsaveis: resp.autorizadas,
              pessoas: resp.autorizadas,
              responsavel_id: resp.ponto.responsavel_id,

              cnpj: resp.ponto.cnpj,
              razao_social: resp.ponto.razao_social,
              nome_fantasia: resp.ponto.nome_fantasia,
              numero_telefone: resp.ponto.numero_telefone,
              unidade_peso: resp.ponto.unidade_peso[0].sigla,
              unidade_volume: resp.ponto.unidade_volume[0].sigla,
              capacidade_maxima_peso: resp.ponto.capacidade_maxima_peso,
              capacidade_maxima_volume: resp.ponto.capacidade_maxima_volume,

              numero: resp.ponto.numero,

              cep: resp.ponto.cep,
              bairro: resp.ponto.bairro,
              cidade: resp.ponto.cidade,
              estado: resp.ponto.estado,
              logradouro: resp.ponto.logradouro,
              complemento:
                resp.ponto.complemento == null ? "" : resp.ponto.complemento,

              materiais: materiais_selecionados,
              pessoas_selecionadas,
            });
          }
        } catch (err) {
          this.setState({ redirect: true });
          console.error(err);
        }
      })
      .catch((err) => {
        this.setState({ redirect: true });
        console.error(err);
      });
  }

  render() {
    return (
      <div className="row">
        {this.state.redirect == true && <Redirect to={this.state.path} />}
        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
              <h4
                className="mt-0 header-title"
                style={{ fontSize: "1.5rem", color: "black" }}
              >
                Ver ponto de coleta
              </h4>
              <p style={{ fontSize: "1rem", color: "black" }}>
                Veja um ponto de coleta do seu sistema. Este é um Relacionamento
                Interno, por isso não pode ser editado.
              </p>
              <br />

              {this.state.loading_screen != true && (
                <div className="row">
                  <div className="col-12">
                    <div className="form-group row">
                      <label
                        className="col-sm-12 col-form-label"
                        style={{ fontSize: "17px" }}
                      >
                        CNPJ:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {this.state.cnpj}
                        </span>
                      </label>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-12 col-form-label"
                        style={{ fontSize: "17px" }}
                      >
                        Razão Social:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {this.state.razao_social}
                        </span>
                      </label>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-12 col-form-label"
                        style={{ fontSize: "17px" }}
                      >
                        Nome Fantasia:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {this.state.nome_fantasia}
                        </span>
                      </label>
                    </div>
                    <h4
                      className="mt-4 header-title"
                      style={{ fontSize: "1.5rem", color: "black" }}
                    >
                      Localização
                    </h4>
                    <div className="form-group row">
                      <label
                        className="col-sm-12 col-form-label"
                        style={{ fontSize: "17px" }}
                      >
                        CEP:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {this.state.cep}
                        </span>
                      </label>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-12 col-form-label"
                        style={{ fontSize: "17px" }}
                      >
                        Endereço:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {this.state.logradouro} - {this.state?.numero},{" "}
                          {this.state.bairro}. {this.state?.complemento}
                        </span>
                      </label>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-12 col-form-label"
                        style={{ fontSize: "17px" }}
                      >
                        Cidade:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {this.state.cidade} - {this.state.estado}
                        </span>
                      </label>
                    </div>
                    <h4
                      className="mt-4 header-title"
                      style={{ fontSize: "1.5rem", color: "black" }}
                    >
                      Pessoas
                    </h4>
                    <div className="form-group row">
                      <label
                        className="col-sm-12 col-form-label"
                        style={{ fontSize: "17px" }}
                      >
                        Responsável:{" "}
                        <span style={{ fontWeight: "normal" }}>ver depois</span>
                      </label>
                    </div>
                    {Object.values(this.state.pessoas_selecionadas).length !=
                      0 && (
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          Pessoas autorizadas selecionadas:
                        </label>
                        {Object.values(this.state.pessoas_selecionadas).map(
                          (item, id) => (
                            <div
                              key={id}
                              className="col-sm-4  col-md-4 col-lg-3"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                border: "1px solid gray",
                                borderRadius: 10,
                                marginLeft: 10,
                                marginTop: 10,
                              }}
                            >
                              <span>{item.name}</span>
                            </div>
                          )
                        )}
                      </div>
                    )}
                    <h4
                      className="mt-4 header-title"
                      style={{ fontSize: "1.5rem", color: "black" }}
                    >
                      Detalhes
                    </h4>
                    <div className="form-group row">
                      <label
                        className="col-sm-12 col-form-label"
                        style={{ fontSize: "17px" }}
                      >
                        Capacidade Máxima de Peso:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {maskNumberBr(this.state.capacidade_maxima_peso)}{" "}
                          {this.state.unidade_peso}
                        </span>
                      </label>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-12 col-form-label"
                        style={{ fontSize: "17px" }}
                      >
                        Capacidade Máxima de Volume:{" "}
                        <span style={{ fontWeight: "normal" }}>
                          {maskNumberBr(this.state.capacidade_maxima_volume)}{" "}
                          {this.state.unidade_volume}
                        </span>
                      </label>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Materiais Suportados:
                      </label>
                      <table
                        id="tech-companies-1"
                        className="table table-striped table-hover"
                      >
                        <thead>
                          <tr style={{ textAlign: "center" }}>
                            <th>Nome</th>
                            <th>Categoria</th>
                            <th>Movimentação Financeira</th>
                            <th>Saldo Financeiro Estimado</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.materiais.map((item, id) => (
                            <tr key={id} style={{ textAlign: "center" }}>
                              <td>{item.nome}</td>
                              <td>{item.tipo}</td>
                              <td>{item.saldo_financeiro}</td>
                              <td>
                                R$ {maskNumberBr(item.valor_saldo_financeiro)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div
                      className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                      style={{ marginBottom: "0.5rem" }}
                    >
                      <Link to="/pontos_coleta">
                        {" "}
                        <span className="btn btn-secondary btn-lg waves-effect waves-light">
                          <MdReply /> Voltar
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              {this.state.loading_screen == true && (
                <div className="row">
                  {" "}
                  <div
                    className="col-12 d-flex justify-content-center align-items-center"
                    style={{ marginTop: "2rem" }}
                  >
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    ></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapsStateToProps = (state) => ({
  token: state.AppReducer.token,
  permissions: state.AppReducer.permissoes,
});

export default connect(mapsStateToProps, { logout })(ViewPontoColeta);
