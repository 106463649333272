import React from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions/AppActions';
import validator from 'validator'

import BG from '../../assets/images/verdaz.png'
import { URL } from '../../variables';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Step, StepLabel, Stepper } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import Theme from '../../Home/Theme';

class NewPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            page: 0,
            msgErro: '',
            email: '',
            codigo: '',
            password: '',
            password2: '',
            redirect: false,
            loading_save: false,
            resend: false,
            year: new Date(),
        }
    }

    handleSubmit(e) {
        e.preventDefault()
        this.setState({ loading_save: true, msgErro: '' });

        /********************INÍCIO 1ª ETAPA: Enviar código por e-mail para um usuário existente.*********************/
        if (this.state.page == 0) {
            if (this.state.email == '') {
                this.setState({ loading_save: false, msgErro: 'O campo está vazio.' })
                return;
            }
            if (!validator.isEmail(this.state.email)) {
                this.setState({ loading_save: false, msgErro: 'Digite um formato de e-mail válido.' })
                return;
            }
            else {
                fetch(`${URL}api/auth/new_password`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        //'Authorization': `Bearer ${this.props.token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: this.state.email,
                    })
                }).then(async (responseLog) => {
                    try {
                        let resp = await responseLog.json();
                        if (resp.errors != null || resp.error != null) {
                            let errors = Object.values(resp.errors);
                            let erro = '';
                            for (let i = 0; i < errors.length; i++) {
                                if (i != errors.length - 1)
                                    erro += errors[i] + '\n';
                                else
                                    erro += errors[i];
                            }
                            console.log(erro);
                            window.scrollTo(500, 0);

                            this.setState({ loading_save: false, msgErro: erro });
                        }
                        else {
                            this.setState({ loading_save: false, redirect: true, page: 1 });
                            setTimeout(() => this.setState({ resend: true }), 5000);
                        }
                    } catch (err) {
                        console.log(err);
                        this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });
                    }
                })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
                    });
            }
        }
        /********************FIM 1ª ETAPA.*********************/

        /********************INÍCIO 2ª ETAPA: Inserir código recebido por e-mail.*********************/
        else if (this.state.page == 1) {
            if (this.state.codigo == '') {
                this.setState({ loading_save: false, msgErro: 'O campo não pode estar vazio.', resend: true })
                return;
            }
            if (this.state.codigo.length != 12) {
                this.setState({ loading_save: false, msgErro: 'O código possui 12 caracteres.', resend: true })
                return;
            } else {
                fetch(`${URL}api/auth/insert_code`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: this.state.email,
                        codigo: this.state.codigo,
                    })
                }).then(async (responseLog) => {
                    try {
                        let resp = await responseLog.json();
                        if (resp.errors != null || resp.error != null) {
                            let errors = Object.values(resp.errors);
                            let erro = '';
                            for (let i = 0; i < errors.length; i++) {
                                if (i != errors.length - 1)
                                    erro += errors[i] + '\n';
                                else
                                    erro += errors[i];
                            }
                            console.log(erro);
                            window.scrollTo(500, 0);

                            this.setState({ loading_save: false, msgErro: erro, resend: true });
                        }
                        else {
                            this.setState({ loading_save: false, redirect: true, page: 2 });
                        }
                    } catch (err) {
                        console.log(err);
                        this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });
                    }
                })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
                    });

            }
            /********************FIM 2ª ETAPA.*********************/

            /********************INÍCIO 3ª ETAPA: Atualizar senha do usuário.*********************/
        } else if (this.state.page == 2) {
            if (this.state.password == '' || this.state.password2 == '') {
                this.setState({ loading_save: false, msgErro: 'Preencha os campos corretamente' })
                return;
            }
            if (this.state.password != this.state.password2) {
                this.setState({ loading_save: false, msgErro: 'As senhas são diferentes.' })
                return;
            }
            if (this.state.password.length < 6) {
                this.setState({ loading_save: false, msgErro: 'A senha precisa ter no mínimo 6 caracteres.' })
                return;
            }
            if (this.state.password.length >= 100) {
                this.setState({ loading_save: false, msgErro: 'A senha não pode ter mais que 100 caracteres.' })
                return;
            } else {
                toast.loading(
                    'Alterando senha...',
                    {
                        toastId:
                            'toastpromisse' + this.state.password,
                        hideProgressBar: false,
                    }
                )
                fetch(`${URL}api/auth/update_password`, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: this.state.email,
                        password: this.state.password,
                        password_confirmation: this.state.password2
                    })
                }).then(async (responseLog) => {
                    try {
                        let resp = await responseLog.json();
                        if (resp.errors != null || resp.error != null) {
                            let errors = Object.values(resp.errors);
                            let erro = '';
                            for (let i = 0; i < errors.length; i++) {
                                if (i != errors.length - 1)
                                    erro += errors[i] + '\n';
                                else
                                    erro += errors[i];
                            }
                            console.log(erro);
                            window.scrollTo(500, 0);
                            this.setState({ loading_save: false });
                            toast.update('toastpromisse' + this.state.password, {
                                render: erro,
                                type: "error",
                                isLoading: false,
                                autoClose: 1000,
                            });
                        }
                        else {
                            this.setState({ loading_save: false, redirect: true });
                            /********************Alerta para o usuário saber se sua senha foi alterada com sucesso ou não.*********************/
                            toast.update(
                                'toastpromisse' +
                                this.state.password,
                                {
                                    render: 'Senha alterada com sucesso',
                                    type: 'success',
                                    isLoading: false,
                                    autoClose: 1500,
                                },
                            );
                            setTimeout(() => {
                                this.props.history.push('/login')
                            }, 2000)
                        }
                    } catch (err) {
                        console.log(err);
                        this.setState({ loading_save: false });
                        toast.update('toastpromisse' + this.state.password, {
                            render: "Erro ao pegar resposta do servidor",
                            type: "error",
                            isLoading: false,
                            autoClose: 1000,
                        });
                    }
                })
                    .catch((err) => {
                        console.log(err);
                        this.setState({ loading_save: false });
                        toast.update('toastpromisse' + this.state.password, {
                            render: "Erro ao alterar senha",
                            type: "error",
                            isLoading: false,
                            autoClose: 1000,
                        });
                    });
            }
        }
    }

    /********************Reenvia o código para o e-mail do usuário.*********************/
    resendCode(e) {
        e.preventDefault();
        this.setState({ resend: false })
        setTimeout(() => this.setState({ resend: true }), 6000);

        fetch(`${URL}api/auth/resend_code`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                //'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: this.state.email,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {
                    this.setState({ loading_save: false, redirect: true });
                }
            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });
            }
        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    render() {
        return (
            <div>
                {this.state.loading && <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <div className="spinner-border text-primary" role="status"  >
                </div></div>}
                {this.state.loading == false && <div>
                    <div className="col-12 d-block" style={{ overflow: 'hidden', backgroundImage: `url(${BG})`, backgroundSize: 'cover', padding: 0, margin: 0, height: '100vh' }}>
                        <div className="row" style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div className="col-10 col-sm-8 col-md-6 col-xl-4">
                                <div className="card" style={{ margin: 0 }}>
                                    <div className="card-body" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                                        <div className="text-center">
                                            <a className="logo"><img src={this.props.logo}
                                                style={{
                                                    objectFit: 'contain',
                                                    height: '100px',
                                                    width: '90%'
                                                }} height="60" alt="logo" /></a>
                                        </div>

                                        <div style={{ padding: '1rem', paddingBottom: 0 }}>
                                            <ThemeProvider theme={Theme}>
                                                <Stepper activeStep={this.state.page} alternativeLabel>
                                                    <Step><StepLabel></StepLabel></Step>
                                                    <Step><StepLabel></StepLabel></Step>
                                                    <Step><StepLabel></StepLabel></Step>
                                                </Stepper>
                                            </ThemeProvider>
                                            <form className="form-horizontal m-t-30 pt-3" onSubmit={(e) => this.handleSubmit(e)}>
                                                {/* ********************INÍCIO 1ª ETAPA.********************* */}
                                                {this.state.page == 0 && <div>
                                                    <h4 className="font-18 m-b-5 text-center header-title">Recuperar Senha</h4>
                                                    <p className="text-muted text-center" >Insira seu endereço de e-mail associado à sua conta Blueen.</p>

                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label" style={{ paddingLeft: 0, paddingRight: 0 }}>E-mail <b style={{ color: 'red' }}>*</b></label>

                                                        <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                            <input id="email" type="text"
                                                                className="form-control  " name="email"
                                                                autoComplete="email" autoFocus
                                                                value={this.state.email}
                                                                onChange={(e) => this.setState({ email: e.target.value })} />
                                                        </div>
                                                    </div>
                                                </div>}
                                                {/* ********************FIM 1ª ETAPA.********************* */}

                                                {/* ********************INÍCIO 2ª ETAPA.********************* */}
                                                {this.state.page == 1 && <div>
                                                    <h4 className="font-18 m-b-5 text-center header-title">Insira o código de segurança</h4>
                                                    <p className="text-muted text-center" >Verifique se você recebeu um e-mail contendo um código com 12 caracteres.</p>
                                                    <div className="form-group row">
                                                        <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                            <input id="code" type="text" placeholder="Insira o código"
                                                                className="form-control" name="code"
                                                                value={this.state.codigo}
                                                                onChange={(e) => {
                                                                    this.setState({ codigo: e.target.value })
                                                                }} />
                                                        </div>
                                                    </div>
                                                </div>}
                                                {/* ********************FIM 2ª ETAPA.********************* */}

                                                {/* ********************INÍCIO 3ª ETAPA.********************* */}
                                                {this.state.page == 2 && <div>
                                                    <h4 className="font-18 m-b-5 text-center header-title">Criar nova senha</h4>
                                                    <p className="text-muted text-center" >Crie uma nova senha com no mínimo 6 caracteres.</p>

                                                    <div className="form-group row">
                                                        <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                            <label className="col-sm-12 col-form-label" style={{ paddingLeft: 0, paddingRight: 0 }}>Insira a senha nova<b style={{ color: 'red' }}>*</b></label>

                                                            <input id="password" type="password"
                                                                className="form-control  " name="password"
                                                                value={this.state.password}
                                                                onChange={(e) => this.setState({ password: e.target.value })} />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                            <label className="col-sm-12 col-form-label" style={{ paddingLeft: 0, paddingRight: 0 }}>Insira a senha nova mais uma vez<b style={{ color: 'red' }}>*</b></label>
                                                            <input id="password2" type="password"
                                                                className="form-control  " name="password2"
                                                                value={this.state.password2}
                                                                onChange={(e) => this.setState({ password2: e.target.value })} />
                                                        </div>
                                                    </div>

                                                </div>}
                                                {/* ********************FIM 3ª ETAPA.********************* */}
                                                <div className="form-group row ">
                                                    <div className="col-sm-12" style={{ padding: 0 }}>
                                                        <span className="invalid-feedback" style={{ display: 'flex', justifyContent: 'center' }} role="alert">
                                                            <strong style={{ textAlign: 'center', fontSize: '0.8rem' }}>{this.state.msgErro}</strong>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='form-group row'>
                                                    <div className="col-sm-12 mt-3 text-right" style={{ padding: 0 }}>
                                                        {!this.state.loading_save && <div>
                                                            <button className="btn btn-primary w-md waves-effect waves-light" style={{ width: '100%', marginTop: '15px', height: '3rem', }} type="submit">Continuar</button>
                                                            <div className="d-flex justify-content-start align-items-center">
                                                                {this.state.page == 1 && this.state.resend && <a className="auth-link text-black btn" style={{
                                                                    fontSize: '13px',
                                                                    color: '#73B054'
                                                                }}
                                                                    onClick={(e) => this.resendCode(e)} >Reenviar código</a>}
                                                            </div>
                                                        </div>
                                                        }
                                                        {this.state.loading_save &&
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <div className="spinner-border text-primary" role="status"  ></div></div>
                                                        }
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="text-center mt-4">
                                            <p>© {this.state.year.getFullYear()} Blueen</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ToastContainer
                                position="bottom-right"
                                toastStyle={{ border: '1px solid gray', borderRadius: 4 }}
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={true}
                                closeOnClick
                                rtl={true}
                                pauseOnHover
                            />
                        </div>
                    </div>
                </div>}
            </div>)
    }
}

const mapsStateToProps = (state) => (
    {
        logo: state.AppReducer.logo,
    }
);

export default connect(mapsStateToProps, { login })(NewPassword);
