const GetAcondicionamentos = (props) => {
  if (props.estado.sigor_codigos) {
    let codigo = props.estado.sigor_codigos.estado_codigo
    return (
      fetch(`https://mtrr.cetesb.sp.gov.br/apiws/rest/retornaListaAcondicionamentoPorEstadoFisico/${codigo}`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': props.token,
        }
      }).then(async (response) => {
        const resp = await response.json()
        let aconds = [...props.acondicionamentos]
        aconds.map(item => {
          if (item.sigor_codigos) {
            resp.objetoResposta.map(item2 => {
              if (item.sigor_codigos.acondicionamento_codigo === item2.tiaCodigo) {
                item.hasAproved = true
                item2.hasAproved = true
              }
              return item2
            })
          }
          return item
        })

        let filter = aconds.filter(item => item.hasAproved === true)
        if (filter.length !== resp.objetoResposta.length) {
          resp.objetoResposta.forEach(item => {
            if (!item.hasAproved) {
              let newItem = true
              aconds.forEach(item2 => {
                if (item2.id === item.tiaCodigo) {
                  newItem = false
                }
              })
              if (newItem) aconds.push({ id: item.tiaCodigo, descricao: item.tiaDescricao, hasPendding: true })
            }
          })
        }
        console.log('aconds', aconds)
        aconds = aconds.filter(item => item.hasAproved || item.hasPendding)

        props.acondicionamentos.forEach(item => {
          aconds.forEach(item2 => { if (item?.relation?.sigor_acondicionamento_id === item2.id) aconds.push(item) })
        })
        return { status: true, aconds: aconds, message: 'OK' }
      })
    )
  } else {
    return { status: false, message: 'Versão incompativel, delete e adicione novamente o estado físico no sistema' }
  }
}

export default GetAcondicionamentos