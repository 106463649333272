import React from 'react'
import Filter from '../../../Auxiliar/Filter';
import Compliances from '../Compliance/Compliances';

// Props comming from Home component
const ListarCompliances = ({ path, label, owner }) => {
  const [search, setSearch] = React.useState(false)
  const [dateOf, setDateOf] = React.useState('')
  const [dateFor, setDateFor] = React.useState('')

  return (
    <div className="card">
      <div className="card-body" id="card">
        <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Compliances de {label}</h4>
        <Filter setDateOf={setDateOf} setDateFor={setDateFor} setSearch={setSearch} />
        <div>
          <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listadas as documentações compliance do sistema</p>
        </div>
        <div className="row">
          {/* {Object.entries(options).filter(([key, item]) => item.value).map(([key, item]) => (
            <Compliances dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} owner={key} searchFor={item.owner} />
          )
          )} */}
          <Compliances dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} owner={path} searchFor={owner} />
        </div>
      </div>
    </div>
  )
}

export default ListarCompliances