import * as React from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { BsFillPersonPlusFill } from 'react-icons/bs';
import CriarMotorista from '../Cadastros/Motorista/CriarMotorista';
import { IconButton } from '@mui/material';
import { MdClose } from 'react-icons/md';

const style = {
  position: 'absolute',
  left: '50%',
  transform: 'translate(0, 0, 0, 50%)',
  width: '50%',
  height: '100%',
  bgcolor: 'background.paper',
  border: '2px solid transparent',
  boxShadow: 24,
  p: 4,

  overflowY: 'auto',
  '@media(max-width: 1000px)': {
    width: '90%',
    left: '5%',
  },
};

export default function MotoristaModal({ updateState }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <button onClick={handleOpen} disabled={false} className="btn btn-primary">
        <BsFillPersonPlusFill />
      </button>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <CriarMotorista isModal={true} handleClose={handleClose} updateState={updateState} />
          </Box>
        </Fade>
      </Modal>
    </>
  );
}