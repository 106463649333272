
import React, { Component, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import Chart from "react-apexcharts";

// Registros
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdSearch } from 'react-icons/md';
import LineAreaChart from "../../../AllCharts/apex/lineareachart";
import ptBr from 'apexcharts/dist/locales/pt-br.json';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import Hidden from '@material-ui/core/Hidden';
import 'moment/locale/pt-br';
import apexchart from "apexcharts";
import { maskPrice } from '../../../Auxiliar/Masks';


//pie


function generateDayWiseTimeSeries(baseval, count, yrange) {
    var i = 0;
    var series = [];
    while (i < count) {
        var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

        series.push([baseval, y]);
        baseval += 86400000;
        i++;
    }
    return series;
}

var series_area = [{
    name: "STOCK ABC",
    data: generateDayWiseTimeSeries(new Date('11 Feb 2017 GMT').getTime(), 20, {
        min: 10,
        max: 60
    })
}];
var options_area = {
    chart: {
        type: 'area',
        height: 200,
        zoom: {
            enabled: false
        },
        locales: [ptBr],
        defaultLocale: 'pt-br',

    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'straight'
    },


    // labels: series.monthDataSeries1.dates,
    xaxis: {
        type: 'datetime',
    },
    yaxis: {
        opposite: true
    },
    legend: {
        horizontalAlign: 'left'
    }
}







class Mapa extends Component {

    constructor(props) {
        super(props);

        this.closeCard = this.closeCard.bind(this);

        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            RegistrosColeta: {},
            width_map:12,
            dados_contato: [],
            page: 1,
            total_pages: 1,
            msgErro: '',
            show_warning: false,
            show_erro: false,
            registro_to_delete: '',
            search: '',
            latitude: -14.5905914,
            longitude: -47.1752839,
            pontos: [],
            estoques: [],
            activeMarker: {},
            marker_visible: false,
            card_visible: false,
            selectMarker: {},
            empresa: {},
            loading_mapa: false,
            series: [],
            options: {
                chart: {
                    // width: 380,
                    height: 200,

                    type: 'pie',

                },
                legend: {
                    position: 'bottom',
                },
                labels: [],
                height: 200,
                pie: {
                    height: 200
                },
                dataLabels: {
                    position: 'bottom'
                }


            },



            series_bar_numero_coletas: [{
                name: 'Não finalizadas',
                data: []
            }, {
                name: 'Finalizadas',
                data: []
            }],
            options_bar_numero_coletas: {
                chart: {
                    id: 'bar_',
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: 'Solicitações de Coleta'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "" + val + " solicitações de coleta"
                        }
                    }
                }
            },


            series_donut: [],
            options_donut: {
                chart: {
                    type: 'donut',
                },
                labels: [],

                legend: {
                    position: 'bottom',
                },

            },




            series_bar_valor_gerado: [{
                name: 'Receita',
                data: []
            }, {
                name: 'Despesa',
                data: []
            }],
            options_bar_valor_gerado: {
                chart: {
                    id: 'bar_1',
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: 'Valor em R$'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "" + maskPrice(parseFloat(val).toFixed(2).toString()) + ""
                        }
                    }
                }
            },
        };
    }

    componentDidMount() {
        this.get_pontos_estoques(this.state.page);

    }


    get_pontos_estoques() {
        if (this.state.loading_screen === false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_pontos_estoques`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {

                    this.setState({
                        pontos: resp.pontos,
                        estoques: resp.estoques,
                        empresa: resp.empresa,

                        marker_visible: true,
                        loading_screen: false,
                    });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false); 
            });
    }

    montarGrafico(resp) {
        let options = { ...this.state.options };
        let options_bar_numero_coletas = { ...this.state.options_bar_numero_coletas };
        let options_bar_valor_gerado = { ...this.state.options_bar_valor_gerado };

        let series = [];

        let series_bar_numero_coletas = [...this.state.series_bar_numero_coletas];
        series_bar_numero_coletas[0].data = [];
        series_bar_numero_coletas[1].data = [];

        let series_donut = [];
        let options_donut = { ...this.state.options_donut };


        let series_bar_valor_gerado = [...this.state.series_bar_valor_gerado];
        series_bar_valor_gerado[0].data = [];
        series_bar_valor_gerado[1].data = [];

        while(options.labels.length) {
            options.labels.pop();
        }

        while(options_bar_numero_coletas.xaxis.categories.length) {
            options_bar_numero_coletas.xaxis.categories.pop();
        }

        while(options_bar_valor_gerado.xaxis.categories.length) {
            options_bar_valor_gerado.xaxis.categories.pop();
        }

        for (let i = 0; i < resp.estoques_coleta_transporte.length; i++) {
            options.labels.push(resp.estoques_coleta_transporte[i].material.nome);

            series.push(parseFloat(resp.estoques_coleta_transporte[i].peso_disponivel) * parseFloat(resp.estoques_coleta_transporte[i].material.unidade_principal.equivalencia));

        }

        options.labels.push('Outros');
        series.push(parseFloat(resp.outros_estoques));


        for (let i = 0; i < resp.solicitacoes_coleta.length; i++) {
            options_bar_numero_coletas.xaxis.categories.push(moment(resp.solicitacoes_coleta[i].mes).format('MMM'));

            series_bar_numero_coletas[0].data.push(parseFloat(resp.solicitacoes_coleta[i].contagem));
            series_bar_numero_coletas[1].data.push(parseFloat(resp.solicitacoes_coleta[i].contagem_finalizada));

        }

        for (let i = 0; i < resp.quantidades_residuos.length; i++) {
            options_donut.labels.push(resp.quantidades_residuos[i].nome);

            series_donut.push(parseFloat(resp.quantidades_residuos[i].soma_kg));

        }

        for (let i = 0; i < resp.valores_gerados.length; i++) {
            options_bar_valor_gerado.xaxis.categories.push(moment(resp.valores_gerados[i].mes).format('MMM'));

            series_bar_valor_gerado[0].data.push(parseFloat(resp.valores_gerados[i].receita));
            series_bar_valor_gerado[1].data.push(parseFloat(resp.valores_gerados[i].despesa));

        }

        this.setState({ series, options, series_bar_numero_coletas, options_bar_numero_coletas, options_donut, series_donut, options_bar_valor_gerado, series_bar_valor_gerado }, () => {
            apexchart.exec('bar_', 'updateSeries', this.state.series_bar_numero_coletas);
            apexchart.exec('bar_1', 'updateSeries', this.state.series_bar_valor_gerado);

        });

    }


    onMarkerClick(props, marker, e, tipo, id) {
        this.setState({ marker_visible: true, activeMarker: marker, selectMarker: props.obj, loading_mapa: true, width_map:8 });

        fetch(`${URL}api/get_dados_grafico_mapa/${id}/${tipo}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                // console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                else {
                    if (tipo == 'ponto_coleta') {
                        this.montarGrafico(resp);
                    }
                    this.setState({
                        loading_mapa: false,
                    });
                }
            } catch (err) {
                console.log(err);
                this.setState({
                    loading_mapa: false,
                });
            }

        })
            .catch((err) => {

                console.log(err);
                this.setState({
                    loading_mapa: false,
                });
                // this.props.mudarLoadingHome(false); 
            });
    }

    closeCard() {
        this.setState(oldState => ({ card_visible: !oldState.card_visible }));
      }

    // OpenPanel() {
    //     if (this.state.selectMarker.id != undefined){
    //         this.state.box_visible = true;
    //         console.log(this.state.box_visible)
    //     }
    // }

    // closePanel() {
    //     if (this.state.selectMarker.id != undefined && this.state.box_visible == true){
    //         this.state.box_visible = false;
    //         console.log("deu bom")
    //         console.log(this.state.box_visible)
    //     }
    // }


    render() {

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}
                {this.state.loading_screen == false && <div className="col-12">
                    <div className="row">
                        <div className="col-1 d-flex d-lg-none" style={{ height: '50vh', width: '0%' }}>
                        </div>
                        <div className={' col-11  col-lg-'+this.state.width_map+'  mt-2'} style={{ padding: 0 }}>

                            <div style={{ height: '1087px', width:'100%',overflow: 'hidden' }} id="mapOpen">
                                <Map
                                    google={window.google}
                                    initialCenter={{ lat: this.state.empresa.latitude, lng: this.state.empresa.longitude }}
                                    style={{ height: '100%', position: "relative" }}
                                    // center={{ lat: this.state.latitude, lng: this.state.longitude }}
                                    className="map2"
                                    onClick={() => {
                                        // {this.closePanel()}
                                        this.setState({
                                            activeMarker: {},
                                            marker_visible: false,
                                            selectMarker: {},
                                            width_map: 12,
                                        });
                                    }}
                                    zoom={14}>
                                    {this.state.pontos.map((item, id) => (
                                        <Marker
                                            key={id}
                                            title={'Ponto de Coleta: ' + item.nome_fantasia}
                                            name={'Ponto de Coleta: ' + item.nome_fantasia}
                                            obj={item}
                                            onClick={(props, marker, e) => this.onMarkerClick(props, marker, e, 'ponto_coleta', item.id)}
                                            position={{ lat: item.latitude, lng: item.longitude }} />
                                    ))}

                                    {this.state.estoques.map((item, id) => (
                                        <Marker
                                            key={id}
                                            title={'Estoque: ' + item.nome_fantasia}
                                            name={'Estoque: ' + item.nome_fantasia}
                                            obj={item}
                                            onClick={(props, marker, e) => this.onMarkerClick(props, marker, e, 'estoque', item.id)}
                                            position={{ lat: item.latitude, lng: item.longitude }} />
                                    ))}


                                    <InfoWindow
                                        marker={this.state.activeMarker}
                                        visible={this.state.marker_visible}
                                    >
                                        {
                                            <div>
                                                {this.state.selectMarker.id != undefined && <div style={{ padding: 10, backgroundColor: 'white' }}>
                                                    <p style={{ fontSize: 20, fontWeight: 'bold' }}>{this.state.selectMarker.nome_fantasia}</p>
                                                    <div>
                                                        <p style={{ fontSize: 18 }}><b>Responsável:</b> {this.state.selectMarker.responsavel.name}</p>
                                                        <p style={{ fontSize: 18, whiteSpace: 'pre-line' }}><b>Endereço:</b> {this.state.selectMarker.logradouro}, {this.state.selectMarker.numero} - {this.state.selectMarker.bairro}{'\n'}{this.state.selectMarker.cidade}, {this.state.selectMarker.estado}{'\n'}CEP: {this.state.selectMarker.cep}</p>
                                                        <p style={{ fontSize: 18 }}><b>Telefone:</b> {this.state.selectMarker.numero_telefone}</p>
                                                        <p style={{ fontSize: 18 }}><b>E-mail:</b> {this.state.selectMarker.responsavel.email}</p>


                                                    </div>
                                                </div>}</div>}
                                    </InfoWindow>

                                    {/* {this.state.estoques.map((item, id) => (
                                <Marker
                                    key={id}
                                    onClick={() => {
                                        let markers_visible = { ...this.state.markers_visible };
                                        markers_visible['estoque_' + item.id] = true;
                                        this.setState({ markers_visible });
                                    }}
                                    title={'The marker`s title will appear as a tooltip.'}
                                    name={'SOMA'}
                                    position={{ lat: item.latitude, lng: item.longitude }} >
                                    <InfoWindow
                                        visible={this.state.markers_visible['estoque_' + item.id]}
                                    >
                                        <div style={{ padding: 10, backgroundColor: 'white' }}>
                                            <p>Click on the map or drag the marker to select location where the incident occurred</p>
                                        </div>
                                    </InfoWindow>
                                </Marker>
                            ))} */}

                                </Map>
                                {/* <Hidden  >
                                    <Map
                                        google={window.google}
                                        initialCenter={{ lat: this.state.latitude, lng: this.state.longitude }}
                                        style={{ height: '80vh', position: "relative" }}
                                        // center={{ lat: this.state.latitude, lng: this.state.longitude }}
                                        className="map2"
                                        onClick={() => {
                                            this.setState({
                                                activeMarker: {},
                                                marker_visible: false
                                            });
                                        }}
                                        zoom={5}>
                                        {this.state.pontos.map((item, id) => (
                                            <Marker

                                                key={id}
                                                title={'Ponto de coleta: ' + item.nome}
                                                name={'Ponto de coleta: ' + item.nome}
                                                obj={item}
                                                onClick={this.onMarkerClick.bind(this)}
                                                position={{ lat: item.latitude, lng: item.longitude }} />
                                        ))}

                                        {this.state.estoques.map((item, id) => (
                                            <Marker

                                                key={id}
                                                title={'Estoque: ' + item.nome}
                                                name={'Estoque: ' + item.nome}
                                                obj={item}
                                                onClick={this.onMarkerClick.bind(this)}
                                                position={{ lat: item.latitude, lng: item.longitude }} />
                                        ))}


                                        <InfoWindow
                                            marker={this.state.activeMarker}
                                            visible={this.state.marker_visible}
                                        >
                                            {
                                                <div>
                                                    {this.state.selectMarker.id != undefined && <div style={{ padding: 10, backgroundColor: 'white' }}>
                                                        <p style={{ fontSize: 20, fontWeight: 'bold' }}>{this.state.selectMarker.nome_fantasia}</p>
                                                        <div>
                                                            <p style={{ fontSize: 18 }}><b>Responsável:</b> {this.state.selectMarker.responsavel.name}</p>
                                                            <p style={{ fontSize: 18, whiteSpace: 'pre-line' }}><b>Endereço:</b> {this.state.selectMarker.logradouro}, {this.state.selectMarker.numero} - {this.state.selectMarker.bairro}{'\n'}{this.state.selectMarker.cidade}, {this.state.selectMarker.estado}{'\n'}CEP: {this.state.selectMarker.cep}</p>
                                                            <p style={{ fontSize: 18 }}><b>E-mail:</b> {this.state.selectMarker.responsavel.email}</p>


                                                        </div>
                                                    </div>}</div>}
                                        </InfoWindow>


                                    </Map>
                                </Hidden> */}

                            </div>
                        </div >
                        {this.state.selectMarker.id != undefined && <div className="col-12 col-lg-4 mt-2" style={{ padding: 0 }}>
                            <div className="card" >
                                <div className="card-body" style={{ paddingTop: 0,position:"relative" }}>
                                    {<div className="row">
                                        <div className="col-12 col-sm-6 col-lg-12  mt-3">
                                            <h6 style={{ textAlign: 'center' }}>Estoque</h6>
                                            <Chart
                                                options={this.state.options}
                                                series={this.state.series}
                                                height="200"
                                                type="pie"
                                                style={{ minHeght: 400 }}
                                            // width="500"
                                            />
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-12  mt-3">
                                            <hr className="hr_show" />

                                            <h6 style={{ textAlign: 'center' }}>Número de solicitações de coleta</h6>

                                            <Chart
                                                // options={options_area}
                                                // series={series_area}
                                                // height="200"
                                                // type="area"
                                                // style={{ overflow: 'hidden' }}
                                                options={{ ...this.state.options_bar_numero_coletas }}
                                                series={[...this.state.series_bar_numero_coletas]}
                                                height="200"
                                                type="bar"
                                            />
                                        </div>

                                        <div className="col-12 col-sm-6 col-lg-12  mt-3">
                                            <hr />
                                            <h6 style={{ textAlign: 'center' }}>Quantidade coletada</h6>

                                            <Chart
                                                options={this.state.options_donut}
                                                series={this.state.series_donut}
                                                height="200"
                                                type="donut"
                                            // width="500"
                                            />
                                        </div>

                                        <div className="col-12 col-sm-6 col-lg-12  mt-3">
                                            <hr />

                                            <h6 style={{ textAlign: 'center' }}>Valor gerado</h6>

                                            <Chart
                                                options={this.state.options_bar_valor_gerado}
                                                series={this.state.series_bar_valor_gerado}
                                                height="200"
                                                type="bar"
                                            // width="500"
                                            />
                                        </div>
                                    </div>}
                                    {this.state.loading_mapa==true && <div className="d-flex justify-content-center align-items-center" style={{ position:"absolute",backgroundColor:'rgba(255,255,255,0.5)',top:0,bottom:0,left:0,right:0 }}>
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>}
                {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                    </div>
                </div></div>}
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(GoogleApiWrapper({ apiKey: 'AIzaSyDLGnRQ8D0z43PQCmHBeYOyzZVC4IJwRHw' })(Mapa));


