import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import { URL } from '../../../variables';
import { maskNumberBr } from '../../../Auxiliar/Masks';

import { MdReply } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import SweetAlert from 'react-bootstrap-sweetalert';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { NumericFormat } from 'react-number-format';

class EditarDestinacaoInterna extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/destinacao_interna',
            loading_screen: false,

            DestinacaoAtual: [],
            Destinos: [],
            Estoques: [],
            AllEstoques: [],
            EstoqueInterno: [],
            AllMateriais: [],
            Materiais: [],
            MateriaisInseridos: [],
            Transportadoras: [],
            Veiculos: [],
            ResiduosRejeitos: [],
            Motoristas: [],
            Destinacoes: [],
            Tratamentos: [],
            Devolucao: [],
            DevolucaoSelecionada: [],

            DestinoSelecionado: {},
            EstoqueSelecionado: {},
            Materialselecionado: {},

            DataFinalizado: '',
            PesoDisponivel: '',
            VolumeDisponivel: '',
            Tratamento: '',
            UnidadePeso: '',
            UnidadeVolume: '',
            Peso_kg: '',
            Volume_lt: '',
            Movimentacao: '',
            Outros: false,
            ValorFinanceiro: '',
            DestinacaoId: '',
            Descricao: '',
            ChaveNota: '',
            NumeroNota: '',
            SerieNota: '',
            TransportadoraId: '',
            VeiculoId: '',
            MotoristaId: '',
            PesoTotal: '0',
            VolumeTotal: '0',
            ReceitaTotal: '0.00',
            DespesaTotal: '0.00',
            Saldo: '0.00',
            Situacao: 'Rascunho',
            DataAgendamento: '',
            DataAtual: '',
            DataInicio: '',
            DataCriacao: '',
            Peso: '',
            Volume: '',
            MaterialId: '',
            Destino: '',
            EstoqueId: '',
            loading_save: false,
            msgErro: '',
            required: false,
            validated: false,
            show_warning: false,
            show_warning_inserir: false,
            hasRegister: false,
            ResponsavelId: ''
        };
    }


    get_data(id) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_editar_destinacao_interna/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {

                    let destino = resp.destinos.filter(item => item.id == resp.destinacao.destino?.id,);
                    let estoques = [];
                    for (let i = 0; i < resp.estoques.length; i++) {
                        for (let j = 0; j < resp.estoques[i].residuos_rejeitos.length; j++) {
                            for (let k = 0; k < destino[0].residuos_rejeitos.length; k++) {
                                if (destino[0].residuos_rejeitos[k].id == resp.estoques[i].residuos_rejeitos[j].id) {
                                    estoques.push(resp.estoques[i]);
                                    k = destino[0].residuos_rejeitos.length;
                                    j = resp.estoques[i].residuos_rejeitos.length;
                                }
                            }

                        }
                    }

                    let estoque_filtro = [];
                    for (let i = 0; i < estoques.length; i++) {
                        for (let j = 0; j < resp.estoque_interno.length; j++) {
                            if (estoques[i].id == resp.estoque_interno[j].estoque_id) {
                                estoque_filtro.push(estoques[i]);
                                j = resp.estoque_interno.length;
                            }

                        }
                    }


                    let estoque = estoque_filtro.filter(item => item.id == resp.destinacao.estoque?.id);
                    let materiais = [];
                    for (let i = 0; i < destino[0].residuos_rejeitos.length; i++) {
                        for (let j = 0; j < estoque[0].residuos_rejeitos.length; j++) {
                            if (destino[0].residuos_rejeitos[i].id == estoque[0].residuos_rejeitos[j].id) {
                                materiais.push(estoque[0].residuos_rejeitos[j]);
                                break;
                            }
                        }
                    }
                    console.log(destino)

                    let mat_filtro = [];
                    for (let i = 0; i < materiais.length; i++) {
                        for (let j = 0; j < resp.estoque_interno.length; j++) {
                            if (materiais[i].id == resp.estoque_interno[j].material_id && resp.estoque_interno[j].peso_disponivel != 0) {
                                mat_filtro.push(materiais[i]);
                                j = resp.estoque_interno.length;
                            }

                        }
                    }

                    console.log('seed', resp.destinacao)
                    this.setState({
                        Devolucao: resp.destinacao.materiais,
                        MateriaisInseridos: resp.destinacao.materiais,
                        Materiais: materiais,
                        Estoques: estoques,
                        EstoqueInterno: resp.estoque_interno,
                        AllEstoques: resp.estoques,
                        ResiduosRejeitos: resp.materiais,
                        loading_screen: false,
                        Destinos: resp.destinos,
                        Destinacoes: resp.destinacoes,
                        Tratamentos: resp.tratamentos,
                        Transportadoras: resp.transportadoras,
                        Veiculos: resp.veiculos,
                        Motoristas: resp.motoristas,
                        DestinoSelecionado: destino,

                        EstoqueId: resp.destinacao.estoque?.id,
                        DestinacaoAtual: resp.destinacao,
                        ResponsavelId: resp.destinacao.destino.user_id,
                        TransportadoraId: resp.destinacao.transportadora_id == null ? '' : resp.destinacao.transportadora_id,
                        Situacao: resp.destinacao.situacao,
                        Descricao: resp.destinacao.descricao != null ? resp.destinacao.descricao : '',
                        Destino: resp.destinacao.destino?.id,
                        DataSolicitacao: resp.destinacao.data_solicitacao,
                        DataAgendamento: resp.destinacao.data_agendamento != null ? resp.destinacao.data_agendamento : '',
                        DataInicio: resp.destinacao.data_inicio != null ? resp.destinacao.data_inicio : '',
                        PesoTotal: resp.destinacao.peso_total,
                        VolumeTotal: resp.destinacao.volume_total,
                        ReceitaTotal: resp.destinacao.receita_total,
                        DespesaTotal: resp.destinacao.despesa_total,
                        Saldo: resp.destinacao.saldo,
                        MotoristaId: resp.destinacao.motorista_id,
                        VeiculoId: resp.destinacao.veiculo_id,
                        MateriaisInseridos: resp.destinacao.materiais,
                        ChaveNota: resp.destinacao.chave_nota != null ? resp.destinacao.chave_nota : '',
                        NumeroNota: resp.destinacao.numero_nota != null ? resp.destinacao.numero_nota : '',
                        SerieNota: resp.destinacao.serie_nota != null ? resp.destinacao.serie_nota : '',
                        DataCriacao: resp.destinacao.data_criacao,
                    });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        }).catch((err) => {
            this.setState({ redirect: true });

            console.log(err);
        });
    }


    salvar() {
        console.log(this.state.Materialselecionado)
        if (this.state.Materialselecionado.length > 0) {
            this.setState({ show_warning_inserir: true, loading_save: true, msgErro: '' })
        } else {
            this.setState({ show_warning_inserir: false, loading_save: true, msgErro: '' })
            console.log({
                destinacao_interna_id: this.props.match.params.id,
                estoque_id: this.state.EstoqueId,
                destino_id: this.state.Destino,
                data_criacao: this.state.DataCriacao,
                data_solicitacao: this.state.DataSolicitacao,
                data_agendamento: this.state.DataAgendamento,
                data_inicio: this.state.DataInicio,
                peso_total: this.state.PesoTotal,
                volume_total: this.state.VolumeTotal,
                receita_total: this.state.ReceitaTotal,
                despesa_total: this.state.DespesaTotal,
                saldo: this.state.Saldo,
                motorista_id: this.state.MotoristaId,
                veiculo_id: this.state.VeiculoId,
                descricao: this.state.Descricao,
                situacao: this.state.Situacao,
                transportadora_id: this.state.TransportadoraId,
                materiais: this.state.MateriaisInseridos,
                chave_nota: this.state.ChaveNota,
                numero_nota: this.state.NumeroNota,
                serie_nota: this.state.SerieNota,
                responsavel_id: this.state.ResponsavelId,
                data_entrada: new Date()
            })

            fetch(`${URL}api/update_destinacao_interna`, {
                method: 'PATCH',
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${this.props.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    destinacao_interna_id: this.props.match.params.id,
                    estoque_id: this.state.EstoqueId,
                    destino_id: this.state.Destino,
                    data_criacao: this.state.DataCriacao,
                    data_solicitacao: this.state.DataSolicitacao,
                    data_agendamento: this.state.DataAgendamento,
                    data_inicio: this.state.DataInicio,
                    peso_total: this.state.PesoTotal,
                    volume_total: this.state.VolumeTotal,
                    receita_total: this.state.ReceitaTotal,
                    despesa_total: this.state.DespesaTotal,
                    saldo: this.state.Saldo,
                    motorista_id: this.state.MotoristaId,
                    veiculo_id: this.state.VeiculoId,
                    descricao: this.state.Descricao,
                    situacao: this.state.Situacao,
                    transportadora_id: this.state.TransportadoraId,
                    materiais: this.state.MateriaisInseridos,
                    chave_nota: this.state.ChaveNota,
                    numero_nota: this.state.NumeroNota,
                    serie_nota: this.state.SerieNota,
                    responsavel_id: this.state.ResponsavelId,
                    data_entrada: new Date()
                })
            }).then(async (responseLog) => {
                try {
                    let resp = await responseLog.json();
                    if (resp.errors != null || resp.error != null) {
                        let errors = Object.values(resp.errors);
                        let erro = '';
                        for (let i = 0; i < errors.length; i++) {
                            if (i != errors.length - 1)
                                erro += errors[i] + '\n';
                            else
                                erro += errors[i];
                        }
                        console.log(erro);
                        window.scrollTo(500, 0);
                        this.setState({ loading_save: false, msgErro: erro });
                    }
                    else {

                        this.setState({ loading_save: false, redirect: true });

                    }

                } catch (err) {
                    console.log(err);
                    this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

                }

            })
                .catch((err) => {
                    console.log(err);
                    this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
                });
        }
    }

    finalizar() {
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/finalizar_destinacao_interna`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                destinacao_interna_id: this.props.match.params.id,
                data_finalizado: this.state.DataFinalizado,
                estoque_id: this.state.EstoqueId,
                destino_id: this.state.Destino,
                data_criacao: this.state.DataCriacao,
                data_agendamento: this.state.DataAgendamento,
                data_inicio: this.state.DataInicio,
                peso_total: this.state.PesoTotal,
                volume_total: this.state.VolumeTotal,
                receita_total: this.state.ReceitaTotal,
                despesa_total: this.state.DespesaTotal,
                saldo: this.state.Saldo,
                motorista_id: this.state.MotoristaId,
                veiculo_id: this.state.VeiculoId,
                descricao: this.state.Descricao,
                situacao: 'Finalizado',
                transportadora_id: this.state.TransportadoraId,
                materiais: this.state.MateriaisInseridos,
                chave_nota: this.state.ChaveNota,
                numero_nota: this.state.NumeroNota,
                serie_nota: this.state.SerieNota,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);
                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }

            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });

    }



    componentDidMount() {
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        this.setState({
            DataAtual: ano + '-' + mes + '-' + dia,
            DataSolicitacao: ano + '-' + mes + '-' + dia
        })
        this.get_data(this.props.match.params.id);
    }

    isNull(elemente) {
        if (elemente == null) {
            return ''
        }
        else {
            return elemente
        }
    }


    render() {


        return (
            <div className="row " >
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar destinação</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite uma destinação</p>
                            <br />

                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Destino<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.Destino} className="form-control"
                                                    onChange={(e) => {

                                                        if (e.target.value == '') {
                                                            this.setState({
                                                                Estoques: [],
                                                                Destino: e.target.value,
                                                                DestinoSelecionado: {},
                                                                EstoqueId: '',
                                                                Materiais: [],
                                                                MaterialId: '',
                                                                Volume: '',
                                                                Peso: '',
                                                                Movimentacao: '',
                                                                Outros: false,
                                                                ValorFinanceiro: '',
                                                                DestinacaoId: '',
                                                                required: false,
                                                                validated: false,
                                                                PesoTotal: '0',
                                                                VolumeTotal: '0',
                                                                DespesaTotal: '0.00',
                                                                ReceitaTotal: '0.00',
                                                                Saldo: '0.00',
                                                                Situacao: 'Rascunho',
                                                                Materialselecionado: {},
                                                                MateriaisInseridos: [],
                                                                Tratamento: ''
                                                            });
                                                        } else {
                                                            let destino = this.state.Destinos.filter(item => item.id == e.target.value);
                                                            let estoques = [];
                                                            console.log(destino);

                                                            for (let i = 0; i < this.state.AllEstoques.length; i++) {
                                                                for (let j = 0; j < this.state.AllEstoques[i].residuos_rejeitos.length; j++) {
                                                                    for (let k = 0; k < destino[0].residuos_rejeitos.length; k++) {
                                                                        if (destino[0].residuos_rejeitos[k].id == this.state.AllEstoques[i].residuos_rejeitos[j].id) {
                                                                            estoques.push(this.state.AllEstoques[i]);
                                                                            k = destino[0].residuos_rejeitos.length;
                                                                            j = this.state.AllEstoques[i].residuos_rejeitos.length;
                                                                        }
                                                                    }

                                                                }
                                                            }


                                                            let estoque_filtro = [];
                                                            for (let i = 0; i < estoques.length; i++) {
                                                                for (let j = 0; j < this.state.EstoqueInterno.length; j++) {
                                                                    if (estoques[i].id == this.state.EstoqueInterno[j].estoque_id) {
                                                                        estoque_filtro.push(estoques[i]);
                                                                        j = this.state.EstoqueInterno.length;
                                                                    }

                                                                }
                                                            }

                                                            this.setState({
                                                                Estoques: estoques,
                                                                ResponsavelId: destino[0].user_id.id,
                                                                Destino: e.target.value,
                                                                DestinoSelecionado: destino,
                                                                EstoqueId: '',
                                                                Materiais: [],
                                                                MaterialId: '',
                                                                Volume: '',
                                                                Peso: '',
                                                                Movimentacao: '',
                                                                Outros: false,
                                                                ValorFinanceiro: '',
                                                                DestinacaoId: '',
                                                                required: false,
                                                                validated: false,
                                                                PesoTotal: '0',
                                                                VolumeTotal: '0',
                                                                DespesaTotal: '0.00',
                                                                ReceitaTotal: '0.00',
                                                                Saldo: '0.00',
                                                                Materialselecionado: {},
                                                                MateriaisInseridos: [],
                                                                Tratamento: ''
                                                            });
                                                        }
                                                    }}>
                                                    <option value={''}>Selecione um destino</option>
                                                    {this.state.Destinos.map((item, id) => (<option key={id} value={item.id}>{item.fantasia}</option>))}
                                                </select>
                                            </div>
                                        </div>

                                        {this.state.Estoques.length > 0 && <><hr />
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados do estoque:</label>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Estoque<b style={{ color: 'red' }}>*</b></label>
                                                <div className="col-sm-12">
                                                    <select value={this.state.EstoqueId} className="form-control"
                                                        onChange={(e) => {

                                                            if (e.target.value == '') {
                                                                this.setState({
                                                                    EstoqueId: e.target.value,
                                                                    EstoqueSelecionado: {},
                                                                    Materiais: [],
                                                                    MaterialId: '',
                                                                    Volume: '',
                                                                    Peso: '',
                                                                    PesoTotal: '0',
                                                                    VolumeTotal: '0',
                                                                    Movimentacao: '',
                                                                    Outros: false,
                                                                    ValorFinanceiro: '',
                                                                    DespesaTotal: '0.00',
                                                                    ReceitaTotal: '0.00',
                                                                    Saldo: '0.00',
                                                                    DestinacaoId: '',
                                                                    required: false,
                                                                    validated: false,
                                                                    Tratamento: '',
                                                                    Materialselecionado: {},
                                                                    MateriaisInseridos: [],
                                                                });
                                                            } else {
                                                                let estoque = this.state.Estoques.filter(item => item.id == e.target.value);
                                                                let materiais = [];
                                                                for (let i = 0; i < this.state.DestinoSelecionado[0].residuos_rejeitos.length; i++) {
                                                                    for (let j = 0; j < estoque[0].residuos_rejeitos.length; j++) {
                                                                        if (this.state.DestinoSelecionado[0].residuos_rejeitos[i].id == estoque[0].residuos_rejeitos[j].id) {
                                                                            materiais.push(estoque[0].residuos_rejeitos[j]);
                                                                            break;
                                                                        }
                                                                    }
                                                                }

                                                                let mat_filtro = [];
                                                                for (let i = 0; i < materiais.length; i++) {
                                                                    for (let j = 0; j < this.state.EstoqueInterno.length; j++) {
                                                                        if (materiais[i].id == this.state.EstoqueInterno[j].material_id && this.state.EstoqueInterno[j].peso_disponivel != 0 && this.state.EstoqueInterno[j].estoque_id == e.target.value) {
                                                                            mat_filtro.push(materiais[i]);
                                                                            j = this.state.EstoqueInterno.length;
                                                                        }

                                                                    }
                                                                }
                                                                console.log(this.state.Devolucao);
                                                                this.setState({
                                                                    EstoqueId: e.target.value,
                                                                    Materiais: materiais,
                                                                    MaterialId: '',
                                                                    Volume: '',
                                                                    Peso: '',
                                                                    PesoTotal: '0',
                                                                    VolumeTotal: '0',
                                                                    Movimentacao: '',
                                                                    Outros: false,
                                                                    ValorFinanceiro: '',
                                                                    DespesaTotal: '0.00',
                                                                    ReceitaTotal: '0.00',
                                                                    Saldo: '0.00',
                                                                    DestinacaoId: '',
                                                                    required: false,
                                                                    validated: false,
                                                                    Tratamento: '',
                                                                    Materialselecionado: {},
                                                                    MateriaisInseridos: [],
                                                                });
                                                            }
                                                        }}>
                                                        <option value={''}>Selecione uma opção</option>
                                                        {this.state.Estoques.map((item, id) => (<option key={id} value={item.id}> {item.nome_fantasia}</option>))}
                                                    </select>
                                                </div>
                                            </div>
                                            <br />

                                            {this.state.Materiais.length > 0 && <>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">Material<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-12">
                                                        <select value={this.state.MaterialId} className="form-control"
                                                            onChange={(e) => {
                                                                let duplicated = false;
                                                                for (let i = 0; i < this.state.MateriaisInseridos.length; i++) {
                                                                    console.log(this.state.MateriaisInseridos[i])
                                                                    if (e.target.value == this.state.MateriaisInseridos[i].residuo_rejeito_id) {
                                                                        duplicated = true;
                                                                    }
                                                                }
                                                                if (duplicated == true) {
                                                                    this.setState({
                                                                        MaterialId: '',
                                                                        Materialselecionado: {},
                                                                        Movimentacao: '',
                                                                        Outros: false,
                                                                        ValorFinanceiro: '',
                                                                        DestinacaoId: '',
                                                                        Tratamento: '',
                                                                        Volume: '',
                                                                        Peso: '',
                                                                        required: false
                                                                    })
                                                                }
                                                                else if (e.target.value == '') {
                                                                    this.setState({
                                                                        MaterialId: e.target.value,
                                                                        Materialselecionado: {},
                                                                        Movimentacao: '',
                                                                        Outros: false,
                                                                        ValorFinanceiro: '',
                                                                        DestinacaoId: '',
                                                                        Tratamento: '',
                                                                        Volume: '',
                                                                        Peso: '',
                                                                        required: false
                                                                    })
                                                                } else {
                                                                    // let estoque_selecionado = this.state.EstoqueInterno.filter(item => item.estoque_id == this.state.EstoqueId && item.material_id == e.target.value);
                                                                    let material_selecionado = this.state.Materiais.filter(item => item.id == e.target.value);
                                                                    let devolucao_sel = this.state.Devolucao.filter(item => item.residuo_rejeito_id == material_selecionado[0].id);
                                                                    let valor = parseFloat(material_selecionado[0].valor_saldo_financeiro);
                                                                    let peso_disponivel = '';
                                                                    let volume_disponivel = '';

                                                                    let estoque_selecionado
                                                                    let hasRegister

                                                                    estoque_selecionado = this.state.EstoqueInterno.filter(item => item.estoque_id === this.state.EstoqueId && item.material_id === e.target.value)

                                                                    if (estoque_selecionado.length > 0) {
                                                                        hasRegister = true
                                                                        console.log('possui estoque')
                                                                    } else {
                                                                        estoque_selecionado = this.state.Estoques.filter(item => item.id === this.state.EstoqueId);
                                                                        hasRegister = false
                                                                        console.log('não possui estoque')
                                                                    }

                                                                    console.log(estoque_selecionado);
                                                                    console.log(devolucao_sel)

                                                                    if (material_selecionado[0].unidade_principal.tipo === 'Peso') {
                                                                        if (devolucao_sel.length > 0 && this.state.DestinacaoAtual.estoque_id == this.state.EstoqueId) {
                                                                            peso_disponivel = parseFloat(devolucao_sel[0].peso) + parseFloat(estoque_selecionado[0].peso_disponivel);
                                                                            volume_disponivel = parseFloat(devolucao_sel[0].volume) + parseFloat(estoque_selecionado[0].volume_disponivel);
                                                                        } else {
                                                                            peso_disponivel = estoque_selecionado[0].peso_disponivel;
                                                                            volume_disponivel = estoque_selecionado[0].volume_disponivel;
                                                                        }
                                                                    }
                                                                    else if (material_selecionado[0].unidade_principal.tipo !== 'Peso') {
                                                                        if (devolucao_sel.length > 0 && this.state.DestinacaoAtual.estoque_id == this.state.EstoqueId) {
                                                                            volume_disponivel = parseFloat(devolucao_sel[0].volume) + parseFloat(estoque_selecionado[0].volume_disponivel);
                                                                            peso_disponivel = parseFloat(devolucao_sel[0].peso) + parseFloat(estoque_selecionado[0].peso_disponivel)
                                                                        } else {
                                                                            volume_disponivel = estoque_selecionado[0].volume_disponivel;
                                                                            peso_disponivel = estoque_selecionado[0].peso_disponivel;
                                                                        }
                                                                    }
                                                                    this.setState({
                                                                        EstoqueSelecionado: estoque_selecionado,
                                                                        MaterialId: e.target.value,
                                                                        Materialselecionado: material_selecionado,
                                                                        Movimentacao: material_selecionado[0].saldo_financeiro,
                                                                        ValorFinanceiro: valor.toFixed(2),
                                                                        DestinacaoId: material_selecionado[0].destinacao_id,
                                                                        Tratamento: material_selecionado[0].tratamento_id,
                                                                        Volume: '',
                                                                        Peso: '',
                                                                        DevolucaoSelecionada: devolucao_sel,
                                                                        required: false,
                                                                        PesoDisponivel: peso_disponivel,
                                                                        VolumeDisponivel: volume_disponivel,
                                                                        hasRegister
                                                                    })
                                                                }
                                                            }}>
                                                            <option value={''}>Selecione uma opção</option>
                                                            {this.state.Materiais.map((item, id) => (<option key={id} value={item.id}>{item.nome}</option>))}
                                                        </select>
                                                    </div>
                                                </div>
                                                {this.state.Materialselecionado.length > 0 && <>

                                                    {this.state.Materialselecionado[0].unidade_principal.tipo == 'Peso' &&
                                                        <div className="row">
                                                            <div className="col-md-6 col-12">
                                                                <label className="col-sm-12 col-form-label">Peso<b style={{ color: 'red' }}>*</b></label>
                                                                <div className="input-group mb-3">
                                                                    {this.state.hasRegister
                                                                        ?
                                                                        <span className="input-group-text" id="basic-addon3">Disponível: {this.state.PesoDisponivel} {this.state.EstoqueSelecionado[0].material.unidade_principal.sigla}</span>
                                                                        :
                                                                        <span className="input-group-text" id="basic-addon3">Disponível: 0{this.state.Materialselecionado[0].unidade_principal.sigla}</span>
                                                                    }
                                                                    <NumericFormat className="form-control quantidade" id="peso" value={this.state.Peso}
                                                                        placeholder="volume atendido" type="text"
                                                                        thousandsGroupStyle='thousand'
                                                                        thousandSeparator="."
                                                                        decimalScale={2}
                                                                        decimalSeparator=","
                                                                        valueIsNumericString
                                                                        onValueChange={(values, e) => {
                                                                            let mult;
                                                                            values.value == '' ? mult = 0.00 : mult = values.value;
                                                                            let val_total = parseFloat(this.state.Materialselecionado[0].valor_saldo_financeiro).toFixed(2) * parseFloat(mult);
                                                                            this.setState({
                                                                                required: false,
                                                                                validated: false,
                                                                                UnidadePeso: this.state.Materialselecionado[0].unidade_principal.sigla,
                                                                                UnidadeVolume: this.state.Materialselecionado[0].unidade_secundaria.sigla,
                                                                                Peso_kg: values.value * parseFloat(this.state.Materialselecionado[0].unidade_principal.equivalencia),
                                                                                Volume_lt: (values.value * parseFloat(this.state.Materialselecionado[0].equivalencia)) * parseFloat(this.state.Materialselecionado[0].unidade_secundaria.equivalencia),
                                                                                Peso: values.value,
                                                                                ValorFinanceiro: parseFloat(val_total).toFixed(2),
                                                                                Volume: values.value == '' ? '' : values.value * parseFloat(this.state.Materialselecionado[0].equivalencia)
                                                                            })
                                                                        }} />
                                                                </div>
                                                                {this.state.required == true && <b style={{ color: 'red', fontSize: '12px' }}>O campo Peso é obrigatório</b>}
                                                                {this.state.validated == true && <b style={{ color: 'red', fontSize: '12px' }}>O Peso atendido não pode ser maior que o disponivel</b>}
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <label className="col-sm-12 col-form-label">Volume<b style={{ color: 'red' }}>*</b></label>
                                                                <div className="input-group mb-3">
                                                                    {this.state.hasRegister
                                                                        ?
                                                                        <span className="input-group-text" id="basic-addon3">Disponível: {maskNumberBr(this.state.VolumeDisponivel)} {this.state.EstoqueSelecionado[0].material.unidade_secundaria.sigla}
                                                                        </span>
                                                                        :
                                                                        <span className="input-group-text" id="basic-addon3">Disponível: 0{this.state.Materialselecionado[0].unidade_secundaria.sigla}</span>
                                                                    }
                                                                    <NumericFormat className="form-control" id="volume"
                                                                        disabled placeholder="volume atendido" type="text"
                                                                        thousandsGroupStyle='thousand'
                                                                        thousandSeparator="."
                                                                        decimalScale={2}
                                                                        decimalSeparator=","
                                                                        valueIsNumericString
                                                                        value={this.state.Volume} />
                                                                </div>
                                                            </div>
                                                        </div>}

                                                    {this.state.Materialselecionado[0].unidade_principal.tipo !== 'Peso' &&
                                                        <div className="row">
                                                            <div className="col-md-6 col-12">
                                                                <label className="col-sm-12 col-form-label">{this.state.Materialselecionado[0].unidade_principal.tipo}<b style={{ color: 'red' }}>*</b></label>
                                                                <div className="input-group mb-3">
                                                                    {this.state.hasRegister
                                                                        ?
                                                                        <span className="input-group-text" id="basic-addon3">Disponível: {maskNumberBr(this.state.VolumeDisponivel)} {this.state.EstoqueSelecionado[0].material.unidade_principal.sigla}
                                                                        </span>
                                                                        :
                                                                        <span className="input-group-text" id="basic-addon3">Disponível: 0{this.state.Materialselecionado[0].unidade_principal.sigla}</span>
                                                                    }
                                                                    <NumericFormat className="form-control" id="volume"
                                                                        placeholder={`${this.state.Materialselecionado[0].unidade_principal.tipo} ${this.state.Materialselecionado[0].unidade_principal.tipo === 'Volume' ? 'atendido' : 'atendida'}`}
                                                                        type="text"
                                                                        thousandsGroupStyle='thousand'
                                                                        thousandSeparator="."
                                                                        decimalScale={2}
                                                                        decimalSeparator=","
                                                                        valueIsNumericString
                                                                        value={this.state.Volume}
                                                                        onValueChange={(values, e) => {
                                                                            let mult;
                                                                            values.value == '' ? mult = 0.00 : mult = values.value;
                                                                            let val_total = parseFloat(this.state.Materialselecionado[0].valor_saldo_financeiro).toFixed(2) * parseFloat(mult)
                                                                            this.setState({
                                                                                required: false,
                                                                                validated: false,
                                                                                UnidadePeso: this.state.Materialselecionado[0].unidade_secundaria.sigla,
                                                                                UnidadeVolume: this.state.Materialselecionado[0].unidade_principal.sigla,
                                                                                Volume_lt: values.value * parseFloat(this.state.Materialselecionado[0].unidade_principal.equivalencia),
                                                                                Peso_kg: (values.value * parseFloat(this.state.Materialselecionado[0].equivalencia)) * parseFloat(this.state.Materialselecionado[0].unidade_secundaria.equivalencia),
                                                                                Volume: values.value,
                                                                                ValorFinanceiro: parseFloat(val_total).toFixed(2),
                                                                                Peso: values.value == '' ? '' : values.value * parseFloat(this.state.Materialselecionado[0].equivalencia)
                                                                            })
                                                                        }} />
                                                                </div>
                                                                {this.state.required == true && <b style={{ color: 'red', fontSize: '12px' }}>O campo Volume é obrigatório</b>}
                                                                {this.state.validated == true && <b style={{ color: 'red', fontSize: '12px' }}>O Volume atendido não pode ser maior que o disponivel</b>}
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <label className="col-sm-12 col-form-label">Peso<b style={{ color: 'red' }}>*</b></label>
                                                                <div className="input-group mb-3">
                                                                    {this.state.hasRegister
                                                                        ?
                                                                        <span className="input-group-text" id="basic-addon3">Disponível: {maskNumberBr(this.state.PesoDisponivel)} {this.state.EstoqueSelecionado[0].material.unidade_secundaria.sigla}
                                                                        </span>
                                                                        :
                                                                        <span className="input-group-text" id="basic-addon3">Disponível: 0{this.state.Materialselecionado[0].unidade_secundaria.sigla}</span>
                                                                    }
                                                                    <NumericFormat className="form-control" id="peso"
                                                                        disabled placeholder="peso atendido" type="text"
                                                                        thousandsGroupStyle='thousand'
                                                                        thousandSeparator="."
                                                                        decimalScale={2}
                                                                        decimalSeparator=","
                                                                        valueIsNumericString
                                                                        value={this.state.Peso} />
                                                                </div>
                                                            </div>
                                                        </div>}

                                                    <div className="row">
                                                        <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Tipo de movimentação<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="input-group mb-3">
                                                                <select value={this.state.Movimentacao} className="form-control"
                                                                    onChange={(e) => {
                                                                        if (e.target.value == 'Outros') {
                                                                            this.setState({
                                                                                Outros: true,
                                                                                Movimentacao: 'Não Aplicável'
                                                                            })
                                                                        } else {
                                                                            this.setState({
                                                                                Movimentacao: e.target.value,
                                                                                Outros: false,
                                                                            })
                                                                        }
                                                                    }}>
                                                                    <option value={'Outros'}>Outros</option>
                                                                    <option value={'Receita'}>Receita</option>
                                                                    <option value={'Despesa'}>Despesa</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        {this.state.Outros == true &&
                                                            <div className="col-md-6 col-12">
                                                                <label className="col-sm-12 col-form-label">Outros<b style={{ color: 'red' }}>*</b></label>
                                                                <div className="input-group mb-3">
                                                                    <select value={this.state.Movimentacao} className="form-control"
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                Movimentacao: e.target.value
                                                                            })
                                                                        }}>
                                                                        <option value={'Não Aplicável'}>Não Aplicável</option>
                                                                        <option value={'Doação'}>Doação</option>
                                                                        <option value={'Despesa Evitada'}>Despesa Evitada</option>
                                                                    </select>
                                                                </div>
                                                            </div>}
                                                        {this.state.Outros == false && <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Valor financeiro<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="input-group mb-3">
                                                                <NumericFormat className="form-control"
                                                                    disabled placeholder="R$ 99,99" type="text"
                                                                    prefix={'R$ '}
                                                                    thousandsGroupStyle='thousand'
                                                                    thousandSeparator="."
                                                                    decimalScale={2}
                                                                    decimalSeparator=","
                                                                    valueIsNumericString
                                                                    onValueChange={(values, e) =>
                                                                        this.setState({ ValorFinanceiro: values.value })}
                                                                    value={this.state.ValorFinanceiro} />
                                                            </div>
                                                        </div>}
                                                        {this.state.Outros == true && <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Valor financeiro<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="input-group mb-3">
                                                                <NumericFormat className="form-control"
                                                                    disabled placeholder="R$ 99,99" type="text"
                                                                    prefix={'R$ '}
                                                                    thousandsGroupStyle='thousand'
                                                                    thousandSeparator="."
                                                                    decimalScale={2}
                                                                    decimalSeparator=","
                                                                    valueIsNumericString
                                                                    onValueChange={(values, e) =>
                                                                        this.setState({ ValorFinanceiro: values.value })}
                                                                    value={'0.00'} />
                                                            </div>
                                                        </div>}
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Destinação<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="input-group mb-3">
                                                                <select value={this.state.DestinacaoId} className="form-control"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            DestinacaoId: e.target.value
                                                                        })
                                                                    }}>
                                                                    <option value={''}>selecione uma opção</option>
                                                                    {this.state.Destinacoes.map((item, id) => (<option key={id} value={item.id}>{'[' + item.codigo + '] ' + item.descricao}</option>))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Tratamento<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="input-group mb-3">
                                                                <select value={this.state.Tratamento} className="form-control"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            Tratamento: e.target.value
                                                                        })
                                                                    }}>
                                                                    <option value={''}>selecione uma opção</option>
                                                                    {this.state.Tratamentos.map((item, id) => (<option key={id} value={item.id}>{'[' + item.codigo + '] ' + item.descricao}</option>))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                        <button type="button" className="btn btn-success" style={{ backgorundColor: '#f2f2f2' }} onClick={() => {
                                                            console.log(this.state.Peso)
                                                            console.log(this.state.PesoDisponivel)
                                                            if (this.state.Materialselecionado[0].unidade_principal.tipo === 'Peso' && this.state.Peso == 0 || this.state.Materialselecionado[0].unidade_principal.tipo == 'Peso' && this.state.Peso == '') {
                                                                this.setState({
                                                                    required: true
                                                                })
                                                                document.getElementById('peso').focus();
                                                            } else if (this.state.Materialselecionado[0].unidade_principal.tipo !== 'Peso' && this.state.Volume == 0 || this.state.Materialselecionado[0].unidade_principal.tipo == 'Volume' && this.state.Volume == '') {
                                                                this.setState({
                                                                    required: true
                                                                })
                                                                document.getElementById('volume').focus();
                                                            }

                                                            else {
                                                                let materiais = this.state.MateriaisInseridos;
                                                                let novo_material = {
                                                                    "residuo_rejeito_id": this.state.Materialselecionado[0].id, "nome": this.state.Materialselecionado[0].nome, "unidade_peso": this.state.UnidadePeso,
                                                                    "unidade_volume": this.state.UnidadeVolume, "peso": this.state.Peso, "volume": this.state.Volume, "peso_kg": this.state.Peso_kg,
                                                                    "volume_lt": this.state.Volume_lt, "movimentacao": this.state.Movimentacao, "valor": this.state.ValorFinanceiro,
                                                                    "destinacao_disposicao_id": this.state.DestinacaoId, "tratamento_id": this.state.Tratamento,

                                                                    //Input record data
                                                                    "tipo": this.state.Materialselecionado[0].tipo,
                                                                    // "equivalencia": this.state.Materialselecionado[0].equivalencia,
                                                                    "equivalencia": this.state.Materialselecionado[0].unidade_principal.tipo === 'Peso' ? this.state.Volume : this.state.Peso,
                                                                    "quantidade": this.state.Materialselecionado[0].unidade_principal.tipo !== 'Peso' ? this.state.Volume : this.state.Peso,

                                                                    "unidade_quantidade": this.state.Materialselecionado[0].unidade_principal.nome,
                                                                    "unidade_equivalencia": this.state.Materialselecionado[0].unidade_secundaria.nome,
                                                                    "unidade_quantidade_id": this.state.Materialselecionado[0].unidade_principal.id,
                                                                    "unidade_equivalencia_id": this.state.Materialselecionado[0].unidade_secundaria.id,
                                                                    "has_register": this.state.hasRegister
                                                                };

                                                                materiais.push(novo_material);
                                                                let valor = this.state.ValorFinanceiro;
                                                                console.log(this.state.MateriaisInseridos);
                                                                let saldo;
                                                                let receita;
                                                                let despesa;
                                                                console.log(parseFloat(valor));
                                                                console.log(parseFloat(this.state.Saldo));
                                                                console.log(parseFloat(this.state.ReceitaTotal));

                                                                if (this.state.Movimentacao == 'Receita') {
                                                                    saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2)) + parseFloat(parseFloat(valor).toFixed(2));
                                                                    receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2)) + parseFloat(parseFloat(valor).toFixed(2));
                                                                    despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2));
                                                                } else if (this.state.Movimentacao == 'Despesa') {
                                                                    despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2)) + parseFloat(parseFloat(valor).toFixed(2));
                                                                    saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2)) - parseFloat(parseFloat(valor).toFixed(2));
                                                                    receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2));
                                                                } else {
                                                                    despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2));
                                                                    saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2));
                                                                    receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2));
                                                                }
                                                                console.log(saldo);
                                                                console.log(receita);
                                                                console.log(despesa);
                                                                this.setState({
                                                                    required: true,
                                                                    MateriaisInseridos: materiais,
                                                                    Materialselecionado: {},
                                                                    MaterialId: '',
                                                                    Volume: '',
                                                                    Peso: '',
                                                                    Movimentacao: '',
                                                                    Outros: false,
                                                                    ValorFinanceiro: '',
                                                                    DestinacaoId: '',
                                                                    Tratamento: '',
                                                                    PesoTotal: parseFloat(this.state.PesoTotal) + parseFloat(this.state.Peso_kg),
                                                                    VolumeTotal: parseFloat(this.state.VolumeTotal) + parseFloat(this.state.Volume_lt),
                                                                    ReceitaTotal: receita.toFixed(2).toString(),
                                                                    DespesaTotal: despesa.toFixed(2).toString(),
                                                                    Saldo: saldo.toFixed(2).toString(),
                                                                })
                                                            }
                                                        }
                                                        }>Inserir</button>
                                                    </div>
                                                </>}
                                            </>}
                                        </>}
                                        <hr />

                                        {this.state.MateriaisInseridos.length > 0 &&
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Materiais selecionados:</label>

                                                <table id="tech-companies-1" className="table table-striped table-hover" >
                                                    <thead>
                                                        <tr>
                                                            <th>Material</th>
                                                            <th>Peso</th>
                                                            <th>Volume</th>
                                                            <th data-priority="3">Excluir</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.MateriaisInseridos.map((item, id) => (
                                                            <tr key={id}>
                                                                <td>{item.nome}</td>
                                                                <td>{maskNumberBr(item.peso + '')} {item.residuo_rejeito != undefined ? item.residuo_rejeito.unidade_principal.tipo === 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla : item.unidade_peso}</td>
                                                                <td>{maskNumberBr(item.volume + '')} {item.residuo_rejeito != undefined ? item.residuo_rejeito.unidade_principal.tipo !== 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla : item.unidade_volume}</td>
                                                                <td>
                                                                    <button className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white', border: '0px solid red' }}><FaTrash color='red' onClick={() => {
                                                                        let materiais_inseridos = this.state.MateriaisInseridos.filter(item2 => item2.residuo_rejeito_id != item.residuo_rejeito_id);
                                                                        let receita;
                                                                        let despesa;
                                                                        let saldo;
                                                                        if (item.movimentacao == 'Receita') {
                                                                            receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2)) - parseFloat(parseFloat(item.valor).toFixed(2));
                                                                            despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2));
                                                                            saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2)) - parseFloat(parseFloat(item.valor).toFixed(2));
                                                                        } else if (item.movimentacao == 'Despesa') {
                                                                            receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2));
                                                                            despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2)) - parseFloat(parseFloat(item.valor).toFixed(2));
                                                                            saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2)) + parseFloat(parseFloat(item.valor).toFixed(2));
                                                                        } else {
                                                                            despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2));
                                                                            saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2));
                                                                            receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2));
                                                                        }


                                                                        this.setState({
                                                                            MateriaisInseridos: materiais_inseridos,
                                                                            PesoTotal: parseFloat(this.state.PesoTotal) - parseFloat(item.peso_kg),
                                                                            VolumeTotal: parseFloat(this.state.VolumeTotal) - parseFloat(item.volume_lt),
                                                                            ReceitaTotal: parseFloat(receita).toFixed(2).toString(),
                                                                            DespesaTotal: parseFloat(despesa).toFixed(2).toString(),
                                                                            Saldo: parseFloat(saldo).toFixed(2).toString()
                                                                        });
                                                                    }} />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>}

                                        <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados do despacho:</label>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Situação: </label>{this.state.Situacao}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"> <label className="col-sm-4 col-form-label">Data da criação: </label>{this.state.DataCriacao.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Agendado para: </label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.DataAgendamento} type="date" style={{ maxWidth: 168 }}
                                                    onChange={(e) => {
                                                        if (e.target.value != '' && this.state.DataInicio == '') {
                                                            this.setState({ Situacao: 'Agendado', DataAgendamento: e.target.value });
                                                        }
                                                        // else if (e.target.value != '' && this.state.DataInicio == '' && e.target.value < this.state.DataAtual) {
                                                        //     this.setState({ Situacao: 'Agendado', DataAgendamento: this.state.DataAtual });
                                                        // }
                                                        // else if (e.target.value != '' && this.state.DataInicio != '' && e.target.value < this.state.DataAtual) {
                                                        //     this.setState({ DataAgendamento: this.state.DataAtual });
                                                        // }
                                                        else if (e.target.value != '' && this.state.DataInicio != '' && e.target.value >= this.state.DataInicio) {
                                                            this.setState({ DataAgendamento: e.target.value });
                                                        }
                                                        else if (e.target.value != '' && this.state.DataInicio != '' && e.target.value < this.state.DataInicio) {
                                                            this.setState({ DataAgendamento: e.target.value })
                                                        }
                                                        else if (e.target.value == '' && this.state.DataInicio == '') {
                                                            this.setState({ Situacao: 'Rascunho', DataAgendamento: e.target.value });
                                                        }
                                                        else if (e.target.value == '' && this.state.DataInicio != '') {
                                                            this.setState({ DataAgendamento: e.target.value });
                                                        }
                                                    }} />
                                            </div>
                                        </div>
                                        {this.state.DataAgendamento >= this.state.DataAtual &&
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Iniciado em:</label>
                                                <div className="col-sm-12">
                                                    <input className="form-control" value={this.state.DataInicio} type="date" style={{ maxWidth: 168 }}
                                                        // min={this.state.DataAtual} 
                                                        // max={this.state.DataAgendamento}
                                                        onChange={(e) => {
                                                            // if (e.target.value != '' && e.target.value != this.state.DataAtual) {
                                                            //     this.setState({ DataInicio: this.state.DataAtual })
                                                            // }
                                                            // if (e.target.value != '' && e.target.value <= this.state.DataAgendamento) {
                                                            //     this.setState({ Situacao: 'Iniciado', DataInicio: e.target.value })
                                                            // }
                                                            // if (e.target.value != '' && e.target.value > this.state.DataAgendamento) {
                                                            //     this.setState({ Situacao: 'Iniciado', DataInicio: this.state.DataAtual })
                                                            // }
                                                            if (e.target.value != '' && this.state.DataAgendamento != '') {
                                                                this.setState({ Situacao: 'Iniciado', DataInicio: e.target.value })
                                                            }
                                                            if (e.target.value != '' && this.state.DataAgendamento == '') {
                                                                this.setState({ Situacao: 'Iniciado', DataInicio: e.target.value })
                                                            }
                                                            if (e.target.value == '' && this.state.DataAgendamento != '') {
                                                                this.setState({ Situacao: 'Agendado', DataInicio: '' })
                                                            }
                                                            if (e.target.value == '' && this.state.DataAgendamento == '') {
                                                                this.setState({ Situacao: 'Rascunho', DataInicio: '' })
                                                            }
                                                        }} />
                                                </div>
                                            </div>
                                        }
                                        {this.state.DataAgendamento < this.state.DataAtual &&
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Iniciado em:</label>
                                                <div className="col-sm-12">
                                                    <input className="form-control" value={this.state.DataInicio} type="date" style={{ maxWidth: 168 }}
                                                        // min={this.state.DataAtual}
                                                        // max={this.state.DataAgendamento}
                                                        onChange={(e) => {
                                                            // if (e.target.value != '' && e.target.value != this.state.DataAtual) {
                                                            //     this.setState({ DataInicio: this.state.DataAtual })
                                                            // }
                                                            // if (e.target.value != '' && e.target.value <= this.state.DataAgendamento) {
                                                            //     this.setState({ Situacao: 'Iniciado', DataInicio: e.target.value })
                                                            // }
                                                            // if (e.target.value != '' && e.target.value > this.state.DataAgendamento) {
                                                            //     this.setState({ Situacao: 'Iniciado', DataInicio: this.state.DataAtual })
                                                            // }
                                                            if (e.target.value != '' && this.state.DataAgendamento != '') {
                                                                this.setState({ Situacao: 'Iniciado', DataInicio: e.target.value })
                                                            }
                                                            if (e.target.value != '' && this.state.DataAgendamento == '') {
                                                                this.setState({ Situacao: 'Iniciado', DataInicio: e.target.value })
                                                            }
                                                            if (e.target.value == '' && this.state.DataAgendamento != '') {
                                                                this.setState({ Situacao: 'Agendado', DataInicio: '' })
                                                            }
                                                            if (e.target.value == '' && this.state.DataAgendamento == '') {
                                                                this.setState({ Situacao: 'Rascunho', DataInicio: '' })
                                                            }
                                                        }} />
                                                </div>
                                            </div>
                                        }
                                        {this.state.Situacao != 'Rascunho' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Finalizado em:</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.DataFinalizado} type="date" style={{ maxWidth: 168 }} min={this.state.DataInicio} onChange={(e) => {
                                                    if (e.target.value != '' && e.target.value < this.state.DataInicio) {
                                                        this.setState({ DataFinalizado: this.state.DataAtual })
                                                    } else {
                                                        this.setState({ DataFinalizado: e.target.value })
                                                    }
                                                }} />
                                            </div>
                                        </div>}

                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Peso total: </label> {maskNumberBr(this.state.PesoTotal)} Kg</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Volume total: </label> {maskNumberBr(this.state.VolumeTotal)} L</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Receita: </label> R$ {this.state.ReceitaTotal.replace('.', ',')}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Despesas: </label> R$ {this.state.DespesaTotal.replace('.', ',')}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Saldo: </label> R$ {this.state.Saldo.replace('.', ',')}</div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Transportadora<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.TransportadoraId} className="form-control"
                                                    onChange={(e) => {
                                                        this.setState({ TransportadoraId: e.target.value })
                                                    }}>
                                                    <option value={''}>Carro próprio</option>
                                                    {this.state.Transportadoras.map((item, id) => (<option key={id} value={item.id}>{item.nome_fantasia}</option>))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Veículo<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.VeiculoId} className="form-control"
                                                    onChange={(e) => {
                                                        this.setState({ VeiculoId: e.target.value })
                                                    }}>
                                                    <option value={''}>Selecione uma opção</option>
                                                    {this.state.Veiculos.map((item, id) => (


                                                        this.isNull(item.transportadora_id) == this.state.TransportadoraId &&
                                                        (
                                                            <option key={id} value={item.id}>
                                                                {item.modelo.nome}
                                                            </option>
                                                        )
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Motorista<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.MotoristaId} className="form-control"
                                                    onChange={(e) => {
                                                        this.setState({ MotoristaId: e.target.value })
                                                    }}>
                                                    <option value={''}>Selecione uma opção</option>
                                                    {this.state.Motoristas.map((item, id) => (<option key={id} value={item.id}>{item.nome}</option>))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Observações </label>
                                            <div className="col-sm-12">
                                                <textarea className="form-control" placeholder="Observações" value={this.state.Descricao} type="text" onChange={(e) => { this.setState({ Descricao: e.target.value }) }} />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Chave da NF-e</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Chave de acesso da nota fiscal" value={this.state.ChaveNota} type="text" onChange={(e) => { this.setState({ ChaveNota: e.target.value }) }} />
                                            </div>
                                        </div>
                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-sm-12 col-form-label">Número da NF-e </label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Número da nota fiscal" type="text" onChange={(e) => this.setState({ NumeroNota: e.target.value })} value={this.state.NumeroNota} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-sm-12 col-form-label">Série da NF-e </label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Série da nota fiscal" type="text" onChange={(e) => this.setState({ SerieNota: e.target.value })} value={this.state.SerieNota} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-3 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/destinacao_interna"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-9 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>

                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2', marginLeft: '5px' }}>Salvar destinação</button>
                                        {this.state.Situacao != 'Rascunho' && <button type="button" onClick={() => this.finalizar()} className="btn btn-info btn-lg waves-effect waves-light justify-content-sm-end"
                                            style={{ marginLeft: '5px' }}>Finalizar </button>}
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}
                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}
                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => { this.setState({ show_warning: false }); }}
                                show={this.state.show_warning}
                                confirmBtnText='     OK     '
                                confirmBtnBsStyle="success"
                            >
                                Para gerar o MTR você deve preencher todos os campos que são obrigatórios.
                            </SweetAlert>

                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => { this.setState({ Materialselecionado: {} }, () => this.salvar()) }}
                                onCancel={() => { this.setState({ show_warning_inserir: false }); }}
                                show={this.state.show_warning_inserir}
                                confirmBtnText='Sim, desejo salvar '
                                cancelBtnText='Cancelar'
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                showCancel={true}
                            >
                                Existe um material selecionado que não foi inserido, deseja salvar mesmo assim ?
                            </SweetAlert>

                        </div>

                    </div>
                </div>
            </div >
        );

    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(EditarDestinacaoInterna);


