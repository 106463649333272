
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import Chart from "react-apexcharts";

// Registros
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdClose, MdDelete, MdModeEdit, MdSearch } from 'react-icons/md';
import LineAreaChart from "../../../AllCharts/apex/lineareachart";
import ptBr from 'apexcharts/dist/locales/pt-br.json';
import { maskNumber, maskNumberBr, maskPrice, moneyMask, moneyMask2 } from '../../../Auxiliar/Masks';
import ReactTooltip from 'react-tooltip';
import { months, years } from './Options';
import DashboardFilter from '../../../Auxiliar/DashboardFilter';


function generateDayWiseTimeSeries(baseval, count, yrange) {
  var i = 0;
  var series = [];
  while (i < count) {
    var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push([baseval, y]);
    baseval += 86400000;
    i++;
  }
  return series;
}
var randomizeArray = function (arg) {
  var array = arg.slice();
  var currentIndex = array.length, temporaryValue, randomIndex;

  while (0 !== currentIndex) {

    randomIndex = Math.abs(Math.floor(Math.random() * currentIndex));
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const barOptions = {
  chart: {
    height: 400,
    type: "bar",
    stacked: false
  },
  dataLabels: {
    enabled: false
  },
  colors: ['#f8bd13', '#f76269', '#46c2e1'],
  series: [

    {
      name: 'Receitas',
      data: []
    },
    {
      name: "Despesas",
      data: []
    },
    {
      name: "Saldo Total",
      data: []
    },
  ],
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '75%',
      endingShape: 'rounded'
    },
  },
  xaxis: {
    categories: []
  },
  yaxis: {
    labels: {
      formatter: (value) => {
        return moneyMask2(value + ' ')
      }
    }
  },
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter: (y) => {
        if (typeof y !== "undefined") {
          y.toFixed(0);
        }

        return moneyMask2(y + ' ')
      }
    }
  },
  legend: {
    horizontalAlign: "left",
    offsetX: 40
  }
}

// data for the sparklines that appear below header area
var sparklineData = [47, 45, 54, 38, 56, 24, 65, 31, 37, 39, 62, 51, 35, 41, 35, 27, 93, 53, 61, 27, 54, 43, 19, 46];
var spark1 = {
  tooltip: {
    enabled: false
  },
  chart: {
    id: 'sparkline3',
    group: 'sparklines',
    type: 'area',
    height: 50,
    sparkline: {
      enabled: true
    },
  },
  stroke: {
    curve: 'straight'
  },
  fill: {
    opacity: 1,
  },
  series: [{
    data: randomizeArray(sparklineData)
  }],
  labels: [...Array(24).keys()].map(n => `2018-09-0${n + 1}`),
  xaxis: {
    type: 'datetime',

  },
  yaxis: {
    min: 0
  },
  colors: ['#3ABBE2'],
  //colors: ['#5564BE'],

}

var spark2 = {
  tooltip: {
    enabled: false
  },
  chart: {
    id: 'sparkline2',
    group: 'sparklines2',
    type: 'area',
    height: 350,
    sparkline: {
      enabled: true
    },
  },
  stroke: {
    curve: 'straight'
  },
  fill: {
    opacity: 1,
  },
  series: [{
    data: randomizeArray(sparklineData)
  }],
  labels: [...Array(24).keys()].map(n => `2018-09-0${n + 1}`),
  xaxis: {
    type: 'datetime',
  },
  yaxis: {
    min: 0
  },
  colors: ['#81D6EB'],
  //colors: ['#5564BE'],

}

var spark3 = {
  tooltip: {
    enabled: false
  },
  chart: {
    id: 'sparkline2',
    group: 'sparklines2',
    type: 'area',
    height: 350,
    sparkline: {
      enabled: true
    },
  },
  stroke: {
    curve: 'straight'
  },
  fill: {
    opacity: 1,
  },
  series: [{
    data: randomizeArray(sparklineData)
  }],
  labels: [...Array(24).keys()].map(n => `2018-09-0${n + 1}`),
  xaxis: {
    type: 'datetime',
  },
  yaxis: {
    min: 0
  },
  colors: ['#FDE07E'],
  //colors: ['#5564BE'],

}

var spark4 = {
  tooltip: {
    enabled: false
  },
  chart: {
    id: 'sparkline2',
    group: 'sparklines2',
    type: 'area',
    height: 350,
    sparkline: {
      enabled: true
    },
  },
  stroke: {
    curve: 'straight'
  },
  fill: {
    opacity: 1,
  },
  series: [{
    data: randomizeArray(sparklineData)
  }],
  labels: [...Array(24).keys()].map(n => `2018-09-0${n + 1}`),
  xaxis: {
    type: 'datetime',

  },
  yaxis: {
    min: 0
  },
  colors: ['#F89194'],
  //colors: ['#5564BE'],

}
console.log('date', new Date().getMonth())
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: '/',
      loading_screen: true,
      RegistrosColeta: {},
      dados_contato: [],
      page: 1,
      total_pages: 1,
      msgErro: '',
      show_warning: false,
      show_erro: false,
      registro_to_delete: '',
      search: '',
      total_residuos_gerados: 0,
      saldo_financeiro: 0,
      saldo_financeiro_total: 0,
      quantidade_residuos_reciclagem: 0,
      quantidade_residuos_aterrados: 0,

      residuo_maior_receita: [],
      residuo_maior_despesa: [],
      total_receitas: 0,
      total_despesas: 0,
      lista_rr: [],
      total_kg: 1,
      total_kg_destinacao: 1,
      residuo_maior_kg: [],

      loading_graph: true,
      first_access: true,

      saldos_graficos: [],
      datas_grafico: [],
      pesos_grafico: [],
      solicitacoes_grafico: [],
      pontos_coleta_grafico: [],
      escala: 'mes',
      quantidade_residuos_aterrados_anterior: 0,
      quantidade_residuos_reciclagem_anterior: 0,
      total_kg_destinacao_anterior: 1,

      total_receitas: 0,
      total_despesas: 0,

      months: months,
      years: years,

      date_from: moment().subtract(1, 'month').format('YYYY-MM'),
      date_to: moment().format('YYYY-MM'),
      filter_rrs: [],
      filter_unity: 'peso',
      apply: false,

      options_rr: [],
      selected_rr: '',
      rr_error: '',

      destinados_por_parceiro: [],
      maiores_despesas: [],
      maiores_receitas: [],

      options_: barOptions
    };
  }

  componentDidMount() {
    this.get_dados_dashboard();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.apply !== this.state.apply) {
      this.get_dados_dashboard();
    }
  }


  get_dados_dashboard() {
    if (this.state.loading_screen === false) {
      this.setState({ loading_screen: true });
    }

    const residuo_rejeito_ids = this.state.filter_rrs.length > 0 ? encodeURIComponent(JSON.stringify(this.state.filter_rrs)) : ''

    const { date_to, date_from, filter_unity } = this.state;
    console.log(date_from)
    const formattedMonth = date_from.toString().padStart(2, '0');
    const period = `${date_to}-${formattedMonth}`;

    fetch(`${URL}api/interna/get_dados_dashboard?residuo_rejeito_ids=${residuo_rejeito_ids}&period=${date_from}&period_end=${date_to}&unity=${filter_unity}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${this.props.token}`,
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token'); localStorage.removeItem('user');
          this.props.logout();
          return;
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);
          this.setState({ redirect: true });
        }
        else {
          this.setState({
            total_residuos_gerados: resp.total_residuos_gerados,
            saldo_financeiro: resp.saldo_financeiro,
            saldo_financeiro_total: resp.saldo_financeiro_total,
            quantidade_residuos_reciclagem: resp.quantidade_residuos_reciclagem,
            quantidade_residuos_aterrados: resp.quantidade_residuos_aterrados,

            quantidade_residuos_reciclagem_anterior: resp.quantidade_residuos_reciclagem_anterior,
            quantidade_residuos_aterrados_anterior: resp.quantidade_residuos_aterrados_anterior,
            total_kg_destinacao_anterior: resp.total_kg_destinacao_anterior,

            residuo_maior_receita: resp.residuo_maior_receita,
            residuo_maior_despesa: resp.residuo_maior_despesa,
            total_receitas: resp.total_receitas,
            total_despesas: resp.total_despesas,
            lista_rr: resp.lista_rr,
            total_kg: resp.total_kg,
            total_kg_destinacao: resp.total_kg_destinacao,
            residuo_maior_kg: resp.residuo_maior_kg,

            destinados_por_parceiro: resp.destinados_por_parceiro,
            maiores_receitas: resp.maiores_receitas,
            maiores_despesas: resp.maiores_despesas,
            loading_screen: false,

            options_rr: resp.residuos,
            unity: resp.unidade,
          }, () => {
            this.get_dados_grafico(this.state.escala, residuo_rejeito_ids, date_from, date_to);
          });

        }
      } catch (err) {
        this.setState({ redirect: true });
        console.log(err);
      }

    })
      .catch((err) => {
        this.setState({ redirect: true });

        console.log(err);
        // this.props.mudarLoadingHome(false); 
      });
  }


  get_dados_grafico(escala, residuo_rejeito_ids, date_from, date_to) {
    if (this.state.loading_graph === false) {
      this.setState({ loading_graph: true });
    }
    fetch(`${URL}api/interna/get_dados_grafico/${escala}?residuo_rejeito_ids=${residuo_rejeito_ids}&period=${date_from}&period_end=${date_to}&first_access=${this.state.first_access}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${this.props.token}`,
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token'); localStorage.removeItem('user');
          this.props.logout();
          return;
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);
          this.setState({ loading_graph: true });
        }
        else {
          let options_ = { ...this.state.options_ };

          options_.series[0].data = resp.receitas_graficos.reverse();
          options_.series[1].data = resp.despesas_graficos.reverse();
          options_.series[2].data = resp.saldos_graficos.reverse();

          options_.xaxis.categories = resp.datas_grafico.map(item => {
            const [year, month] = item.split('-')
            return `${month}/${year}`
          }).reverse()
          console.log('ooptuions', options_)
          this.setState({
            saldos_graficos: resp.saldos_graficos,
            datas_grafico: resp.datas_grafico,
            pesos_grafico: resp.pesos_grafico,
            // solicitacoes_grafico: resp.solicitacoes_grafico,
            // pontos_coleta_grafico: resp.pontos_coleta_grafico,
            options_: options_,
            loading_graph: false,
            first_access: false,
          });
        }
      } catch (err) {
        this.setState({ loading_graph: true });
        console.log(err);
      }

    })
      .catch((err) => {
        this.setState({ loading_graph: true });

        console.log(err);
        // this.props.mudarLoadingHome(false); 
      });
  }

  handleRRChange = ({ target }) => {
    if (this.state.filter_rrs.find(id => id === target.value)) {
      this.setState({
        selected_rr: target.value,
        rr_error: target.value
      })
      return
    }

    this.setState({
      selected_rr: target.value,
      filter_rrs: [...this.state.filter_rrs, target.value],
      rr_error: ''
    })
  }

  // Função auxiliar para preencher o array com nulos até que tenha pelo menos 5 registros
  fillArrayWithNulls = (array, minSize) => {
    while (array.length < minSize) {
      array.push(null);
    }
  };

  renderCard = (item, valueParam, averageParam, isPrice) => {
    if (!item) {
      return (
        <>
          <div className='col-6 text-end py-1' style={{ borderRight: '1px solid lightgrey' }}>
            <span className='bold me-2'>-</span>
            <p className='bold me-2' style={{ marginBottom: 0 }}>-</p>
          </div>

          <div className='col-6 d-flex align-items-center'>
            <span className='bold ms-2'>0%</span>
          </div>
        </>
      )
    }

    return (
      <>
        <div className='col-6 text-end py-1' style={{ borderRight: '1px solid lightgrey' }}>
          <span className='bold me-2'>{isPrice ? moneyMask2(valueParam) : `${maskNumberBr(valueParam)} ${this.state.unity}`}</span>
          <p className='bold me-2' style={{ fontSize: 13, marginBottom: 0 }}>{item.nome}</p>
        </div>

        <div className='col-6 d-flex align-items-center'>
          <span className='bold ms-2'>
            {maskNumberBr(parseFloat(((parseFloat(valueParam) / parseFloat(averageParam)) || 0) * 100).toFixed(2)) + '%'}
          </span>
        </div>
      </>
    )
  }


  render() {
    let taxa_reciclagem = this.state.quantidade_residuos_reciclagem / this.state.total_kg_destinacao;

    let taxa_reciclagem_anterior = this.state.quantidade_residuos_reciclagem_anterior / this.state.total_kg_destinacao_anterior;

    if (taxa_reciclagem == Infinity || isNaN(taxa_reciclagem)) {
      taxa_reciclagem = 0;
    }
    if (taxa_reciclagem_anterior == Infinity || isNaN(taxa_reciclagem_anterior)) {
      taxa_reciclagem_anterior = 0;
    }

    console.log(taxa_reciclagem, taxa_reciclagem_anterior);

    let percentual_residuo_aterrado = this.state.quantidade_residuos_aterrados / this.state.total_kg_destinacao;

    let percentual_residuo_aterrado_anterior = this.state.quantidade_residuos_aterrados_anterior / this.state.total_kg_destinacao_anterior;



    if (percentual_residuo_aterrado == Infinity || isNaN(percentual_residuo_aterrado)) {
      percentual_residuo_aterrado = 0;
    }
    if (percentual_residuo_aterrado_anterior == Infinity || isNaN(percentual_residuo_aterrado_anterior)) {
      percentual_residuo_aterrado_anterior = 0;
    }

    // Preencha as variáveis com nulos até que tenham pelo menos 5 registros
    this.fillArrayWithNulls(this.state.residuo_maior_kg, 5);
    this.fillArrayWithNulls(this.state.residuo_maior_receita, 5);
    this.fillArrayWithNulls(this.state.residuo_maior_despesa, 5);
    this.fillArrayWithNulls(this.state.destinados_por_parceiro, 5);
    this.fillArrayWithNulls(this.state.lista_rr, 5);

    return (
      <div className="row">
        {this.state.redirect == true && <Redirect to={this.state.path} />}
        {this.state.loading_screen == false && <div className="col-12">
          <div>
            <h1 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Indicadores</h1>
            <p>Destinação por período</p>
          </div>

          <DashboardFilter
            dateFrom={this.state.date_from}
            dateTo={this.state.date_to}
            moment={() => moment()}
            filterUnity={this.state.filter_unity}
            filterRrs={this.state.filter_rrs}
            optionsRr={this.state.options_rr}
            selectedRr={this.state.selected_rr}
            getData={() => this.get_dados_dashboard()}
            setState={(args) => this.setState(args)}
          />

          <div className="row mb-2">
            <div className="col-12 col-md-6 col-sm-6 col-lg-3 mt-2">
              <div style={{ width: '100%', minHeight: 190, height: '100%', borderRadius: 10, boxShadow: '0px 1px 5px #888888', backgroundImage: 'linear-gradient(to top right, #36B2E2, #2D98C6)', padding: 10 }}>
                <div style={{ padding: 15, paddingBottom: 0 }}>
                  <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <h3 style={{ color: "white", fontFamily: 'sans-serif', fontWeight: "bold", fontSize: 28 }}>{maskNumberBr(this.state.total_residuos_gerados)} {this.state.unity}</h3>
                  </div>
                  <div>
                    <p style={{ color: 'white', fontSize: 15 }}>Total Destinado em {this.state.unity}</p>
                  </div>
                </div>

                <div>
                  <Chart
                    options={spark1}
                    series={spark1.series}

                    type="line"
                    height="70"
                    style={{ overflow: 'hidden' }}
                  // width="500"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-sm-6 col-lg-3 mt-2">
              <div style={{ width: '100%', minHeight: 190, height: '100%', borderRadius: 10, boxShadow: '0px 1px 5px #888888', backgroundImage: 'linear-gradient(to top right, #57CAE5, #3ABCDE)', padding: 10 }}>
                <div style={{ padding: 15, paddingBottom: 0 }}>

                  <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <h3 style={{ color: "white", fontFamily: 'sans-serif', fontWeight: "bold", fontSize: 28 }}>{moneyMask2(this.state.saldo_financeiro)}</h3>
                  </div>
                  <div>
                    <p style={{ color: 'white', fontSize: 15 }}>Saldo Total Destinado em R$</p>
                  </div>
                </div>

                <div>
                  <Chart
                    options={spark2}
                    series={spark2.series}

                    type="line"
                    height="70"
                    style={{ overflow: 'hidden' }}
                  // width="500"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-sm-6 col-lg-3 mt-2">
              <div style={{ width: '100%', minHeight: 190, height: '100%', borderRadius: 10, boxShadow: '0px 1px 5px #888888', backgroundImage: 'linear-gradient(to top right, #FAC924, #F6B000)', padding: 10 }}>
                <div style={{ padding: 15, paddingBottom: 0 }}>

                  <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <h3 style={{ color: "white", fontFamily: 'sans-serif', fontWeight: "bold", fontSize: 28 }}>{moneyMask2(parseFloat(this.state.total_receitas).toFixed(2))}</h3>
                  </div>
                  <div>
                    <p style={{ color: 'white', fontSize: 15 }}>Receita Total Destinada em R$</p>
                  </div>
                </div>

                <div>
                  <Chart
                    options={spark3}
                    series={spark3.series}

                    type="area"
                    height="80"
                    style={{ overflow: 'hidden' }}
                  // width="500"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-sm-6 col-lg-3 mt-2">
              <div style={{ width: '100%', minHeight: 190, height: '100%', borderRadius: 10, boxShadow: '0px 1px 5px #888888', backgroundImage: 'linear-gradient(to top right, #F7777B, #F5424D)', padding: 10 }}>
                <div style={{ padding: 15, paddingBottom: 0 }}>

                  <div style={{ display: "flex", justifyContent: 'space-between' }}>
                    <h3 style={{ color: "white", fontFamily: 'sans-serif', fontWeight: "bold", fontSize: 28 }}>{moneyMask2(parseFloat(this.state.total_despesas).toFixed(2))}</h3>
                  </div>
                  <div>
                    <p style={{ color: 'white', fontSize: 15 }}>Despesa Total Destinada em R$</p>
                  </div>
                </div>

                <div>
                  <Chart
                    options={spark4}
                    series={spark4.series}

                    type="bar"
                    height="80"
                    style={{ overflow: 'hidden' }}
                  // width="500"
                  />
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="card ">
            <div className="card-body" id="card" style={{ paddingBottom: 0 }}>
              <div>
                <div className="row mb-2">
                  <div className="col-12 col-md-12">
                    <h3>Resumo Financeiro</h3>

                  </div>
                  {this.state.loading_graph == false &&
                    <div className="col-12 col-md-12 custom_scrollbar">
                      {/* <LineAreaChart /> */}
                      <Chart
                        options={this.state.options_}
                        series={this.state.options_.series}
                        type="bar"
                        height="400"
                      // width="500"
                      />
                    </div>}
                  {this.state.loading_graph == true && <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                    </div>
                  </div>}
                </div>
                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}
              </div>

              <SweetAlert
                warning
                title={"Atenção"}
                onConfirm={() => { this.delete_entrada_coleta(this.state.registro_to_delete); }}
                onCancel={() => { this.setState({ show_warning: false }); }}
                show={this.state.show_warning}
                confirmBtnText='Sim, desejo apagar'
                cancelBtnText='Cancelar'
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                showCancel={true}
              >
                Ao deletar este registro de entrada ele não estará mais disponível no sistema

              </SweetAlert>
              <SweetAlert
                danger
                title={"Erro"}
                onConfirm={() => {
                  this.setState({ show_erro: false });
                }}
                show={this.state.show_erro}
                confirmBtnText='OK'
                confirmBtnBsStyle="primary"

              >
                {this.state.msgErro}

              </SweetAlert>

            </div>
          </div>
          <br />
          <div className="row " >
            <div className="col-12 col-sm-6 col-md-4 mt-2" >
              <div className="card" >

                <div style={{ height: 80, backgroundColor: '#3cbdde', borderTopLeftRadius: 5, borderTopRightRadius: 5, display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: 'column' }}>
                  <h2 style={{ textAlign: "center", color: 'white', fontSize: 16 }}>MAIORES QUANTIDADES ({this.state.unity})</h2>
                  <h2 style={{ textAlign: "center", color: 'white', fontSize: 13 }}>TOP 5</h2>
                </div>

                {this.state.residuo_maior_kg.map((item, index) => (
                  <div className='d-flex' style={{ borderBottom: '1px solid lightgrey' }} key={index}>
                    {this.renderCard(item, item ? item.soma_kg : null, this.state.total_kg, false)}
                  </div>
                ))}

              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 mt-2" >
              <div className="card" >

                <div style={{ height: 80, backgroundColor: '#f7b203', borderTopLeftRadius: 5, borderTopRightRadius: 5, display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: 'column' }}>
                  <h2 style={{ textAlign: "center", color: 'white', fontSize: 16 }}>MAIORES RECEITAS (R$)</h2>
                  <h2 style={{ textAlign: "center", color: 'white', fontSize: 13 }}>TOP 5</h2>
                </div>

                {this.state.residuo_maior_receita.map((item, index) => (
                  <div className='d-flex' style={{ borderBottom: '1px solid lightgrey' }} key={index}>
                    {this.renderCard(item, item ? item.soma_valor : null, this.state.total_receitas, true)}
                  </div>
                ))}

              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 mt-2" >
              <div className="card" >

                <div style={{ height: 80, backgroundColor: '#f5434e', borderTopLeftRadius: 5, borderTopRightRadius: 5, display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: 'column' }}>
                  <h2 style={{ textAlign: "center", color: 'white', fontSize: 16 }}>MAIORES CUSTOS (R$)</h2>
                  <h2 style={{ textAlign: "center", color: 'white', fontSize: 13 }}>TOP 5</h2>
                </div>

                {this.state.residuo_maior_despesa.map((item, index) => (
                  <div className='d-flex' style={{ borderBottom: '1px solid lightgrey' }} key={index}>
                    {this.renderCard(item, item ? item.soma_valor : null, this.state.total_despesas, true)}
                  </div>
                ))}

              </div>
            </div>



          </div>
          <br />
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-12 col-md-6 ">

                  <div className="row">
                    <div className="position-relative">
                      <div style={{ width: 5, height: 28, backgroundColor: '#3CBFDF', borderRadius: '3px 3px 0 0', marginRight: 10 }}></div>
                      <div style={{ width: 5, height: 28, backgroundColor: '#F5545B', borderRadius: '0 0 3px 3px', marginRight: 10, marginTop: 4 }}></div>
                      <div className="position-absolute top-50 start-50 translate-middle text-center">
                        <p style={{ fontSize: 15, color: "grey", marginBottom: 0 }}>TOTAL DE RESÍDUOS DESTINADOS</p>
                        <p style={{ fontSize: 15, color: "grey", marginBottom: 0 }}>TOP 5 Relação (R$ x {this.state.unity})</p>
                      </div>
                    </div>
                    <div className="col-12 ">
                      <hr className="mt-3 mb-1" />
                    </div>

                    <div className="col-12 ">
                      {this.state.lista_rr.map((item, index) => {
                        if (!item) {
                          return (
                            <div key={index} className="row mt-3">
                              <div className="col-2" style={{ margin: 'auto auto' }}>
                                <p className='text-center' style={{ fontSize: 15, color: "grey", marginBottom: 0 }}>-</p>
                              </div>
                              <div className="col-10" style={{ margin: 'auto auto' }}>

                                <div className="d-flex align-items-center">
                                  <div className='col-10' style={{ height: 5, backgroundColor: '#f2f2f2', borderRadius: 2 }}>
                                    <div style={{ width: 0, height: 5, backgroundColor: '#3CBFDF', borderRadius: 2 }}></div>
                                  </div>
                                  <span className='col-2 ms-2' style={{ fontSize: '.8em', whiteSpace: 'nowrap' }}>
                                    {moneyMask2('0.00')}
                                  </span>
                                </div>

                                <div className="d-flex align-items-center">
                                  <div className='col-10' style={{ height: 5, backgroundColor: '#f2f2f2', borderRadius: 2, marginTop: 2 }}>
                                    <div style={{ width: 0, height: 5, backgroundColor: '#F5545B', borderRadius: 2 }}></div>
                                  </div>
                                  <span className='col-2 ms-2' style={{ fontSize: '.8em', whiteSpace: 'nowrap' }}>
                                    0 {this.state.unity}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )
                        }
                        return (
                          <div key={item.id} className="row mt-3">
                            <div className="col-2" style={{ margin: 'auto auto' }}>
                              <p style={{ fontSize: 15, color: "grey", marginBottom: 0 }}>{item.nome}</p>
                            </div>
                            <div className="col-10" style={{ margin: 'auto auto' }}>

                              <div className="d-flex align-items-center">
                                <div className='col-10' style={{ height: 5, backgroundColor: '#f2f2f2', borderRadius: 2 }}
                                  data-tip={maskNumberBr(parseFloat(((Math.abs(parseFloat(item.soma_valor)) / parseFloat(this.state.saldo_financeiro_total)) || 0) * 100).toFixed(2)) + '%'}>
                                  <div style={{
                                    width:
                                      parseFloat(((Math.abs(parseFloat(item.soma_valor)) / parseFloat(this.state.saldo_financeiro_total)) || 0) * 100).toFixed(2) + '%',
                                    height: 5,
                                    backgroundColor: '#3CBFDF',
                                    borderRadius: 2
                                  }}>
                                  </div>
                                </div>
                                <span className='col-2 ms-2' style={{ fontSize: '.8em', whiteSpace: 'nowrap' }}>
                                  {moneyMask2(item.soma_valor)}
                                </span>
                              </div>

                              <div className="d-flex align-items-center">
                                <div className='col-10' style={{ height: 5, backgroundColor: '#f2f2f2', borderRadius: 2, marginTop: 2 }}
                                  data-tip={maskNumberBr(parseFloat(((parseFloat(item.soma_kg) / parseFloat(this.state.total_kg_destinacao)) || 0) * 100).toFixed(2)) + '%'}>

                                  <div style={{
                                    width: parseFloat(((Math.abs(parseFloat(item.soma_kg)) / parseFloat(this.state.total_kg_destinacao)) || 0) * 100).toFixed(2) + '%',
                                    height: 5,
                                    backgroundColor: '#F5545B',
                                    borderRadius: 2
                                  }}>
                                  </div>
                                </div>
                                <span className='col-2 ms-2' style={{ fontSize: '.8em', whiteSpace: 'nowrap' }}>
                                  {item.soma_kg} {this.state.unity}
                                </span>
                              </div>
                              <ReactTooltip place="right" effect="solid" />
                            </div>
                          </div>
                        )
                      })}

                    </div>

                  </div>
                </div>



                <div className="col-12 col-md-6 ">
                  <br className="d-block d-md-none" />
                  <br className="d-block d-md-none" />

                  <div className="row">
                    <div className="col-12 d-flex align-items-center">
                      <div style={{ width: 5, height: 60, backgroundColor: '#FABB00', borderRadius: 3, marginRight: 10 }}>
                      </div>
                      <div className='text-center w-100'>
                        <p style={{ fontSize: 15, color: "grey", marginBottom: 0 }}>TOTAL DE RESÍDUOS DESTINADOS POR PARCEIRO</p>
                        <p style={{ fontSize: 15, color: "grey", marginBottom: 0 }}>TOP 5 (Percentual de {this.state.unity})</p>
                      </div>
                    </div>
                    <div className="col-12 ">

                      <hr className="mt-3 mb-1" />
                    </div>

                    <div className="col-12 ">
                      {this.state.destinados_por_parceiro.map((item, index) => {
                        if (!item) {
                          return (
                            <div key={index} className="row mt-3">
                              <div className="col-12" style={{ margin: 'auto auto' }}>
                                <div className="d-flex justify-content-between">
                                  <p style={{ fontSize: 15, color: "grey", marginBottom: 3, marginLeft: 5 }}>-</p>
                                  <p style={{ fontSize: 18, color: "black", marginBottom: 3, marginLeft: 5 }}>0%</p>

                                </div>
                                <div style={{ width: '100%', height: 5, backgroundColor: '#f2f2f2', borderRadius: 2 }}>
                                  <div style={{ width: 0, height: 5, backgroundColor: '#FABB00', borderRadius: 2 }}></div>
                                </div>

                              </div>
                            </div>
                          )
                        }

                        return (
                          <div key={item.id} className="row mt-3">
                            <div className="col-12" style={{ margin: 'auto auto' }}>
                              <div className="d-flex justify-content-between">
                                <p style={{ fontSize: 15, color: "grey", marginBottom: 3, marginLeft: 5 }}>{item.nome_fantasia}</p>
                                <p style={{ fontSize: 18, color: "black", marginBottom: 3, marginLeft: 5 }}>{maskNumberBr((parseFloat(this.state.filter_unity == 'peso' ? item.soma_kg : item.soma_lt) / parseFloat(this.state.total_kg) * 100).toFixed(2)) + '%'}</p>

                              </div>
                              <div style={{ width: '100%', height: 5, backgroundColor: '#f2f2f2', borderRadius: 2 }}>
                                <div style={{ width: (((parseFloat(this.state.filter_unity == 'peso' ? item.soma_kg : item.soma_lt) / parseFloat(this.state.total_kg)) * 100).toFixed(2)) + '%', height: 5, backgroundColor: '#FABB00', borderRadius: 2 }}>
                                </div>
                              </div>

                            </div>
                          </div>)
                      })}
                    </div>

                  </div>
                </div>

              </div>

            </div>
          </div>

        </div>}
        {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
          <div className="spinner-border text-primary" role="status"  >
          </div>
        </div></div>}
      </div>
    );
  }


}
const mapsStateToProps = (state) => (
  {
    user: state.AppReducer.user,
    token: state.AppReducer.token,
  }
);

export default connect(mapsStateToProps, { logout })(Dashboard);


