import React from 'react'
import PropTypes from 'prop-types'

const SuggestionsInput = ({
  label, checkLabel, placeholder, value, onChange, getDataCallback, selectCallback, abbleSuggestionsCheck, defaultAbbleSuggestions, selectMaxHeight
}) => {

  const [errorOption, setErrorOption] = React.useState('')
  const [pagination, setPagination] = React.useState({})
  const [suggestions, setSuggestions] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [showSuggestions, setShowSuggestions] = React.useState(false)
  const [isChecked, setIsChecked] = React.useState(defaultAbbleSuggestions)

  const timeoutSearch = React.useRef(false)
  const inputRef = React.useRef(null)
  const listRef = React.useRef(null)

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  //-------------------------*-------------------------
  const handleClickOutside = (e) => {
    if (!(listRef.current && listRef.current.contains(e.target)) &&
      !(inputRef.current && inputRef.current.contains(e.target))) {
      setShowSuggestions(false)
    }
  }

  //-------------------------*-------------------------
  const handleScroll = ({ target }) => {
    const { offsetHeight, scrollTop, scrollHeight } = target
    const { current_page, last_page } = pagination

    if (!isLoading && current_page < last_page &&
      (offsetHeight + scrollTop >= scrollHeight * 0.8)) {
      getData(value, current_page + 1)
    }
  };

  //-------------------------*-------------------------
  const handleChange = (value) => {
    onChange(value)

    if (abbleSuggestionsCheck && !isChecked) {
      return
    }

    clearTimeout(timeoutSearch.current)
    timeoutSearch.current = setTimeout(() => {
      setSuggestions([])
      getData(value)
    }, 500)
  }

  //-------------------------*-------------------------
  const handleSelect = (item) => {
    selectCallback(item)
    setShowSuggestions(false)
  }

  //-------------------------*-------------------------
  const handleCheck = (checked) => {
    setIsChecked(checked)

    if (checked) {
      inputRef.current.focus()
      getData(value)
      return
    }

    setSuggestions([])
    setPagination({})
  }

  //-------------------------*-------------------------
  const getData = async (value, page = 1) => {
    setIsLoading(true)
    const response = await getDataCallback(value, page)
    setIsLoading(false)

    setShowSuggestions(true)

    if (response.errorMessage) {
      setErrorOption(response.errorMessage)
      return
    }

    setSuggestions(oldState => page !== 1
      ? [...oldState, ...response.data]
      : response.data)

    setPagination(response.pagination)
  }

  return (
    <div className="form-group">
      <div className="d-flex align-items-center">
        <label className="col-form-label m-0">
          {label} <b style={{ color: "red" }}>*</b>
        </label>

        {abbleSuggestionsCheck &&
          <div className="d-flex ms-3">
            <label className="m-0" htmlFor="suggestions">{checkLabel}</label>
            <input
              className='form-check-input pointer ms-1'
              id="suggestions"
              type='checkbox'
              checked={isChecked}
              onChange={({ target }) => handleCheck(target.checked)} />
          </div>}
      </div>

      <div className="col-sm-12">
        <input
          type="text"
          value={value}
          ref={inputRef}
          className="form-control"
          placeholder={(abbleSuggestionsCheck && isChecked) ? 'Mínimo de três caracteres para busca' : placeholder}
          onFocus={() => setShowSuggestions(true)}
          onChange={({ target }) => handleChange(target.value)}
        />
        {(suggestions?.length > 0 || errorOption) && showSuggestions &&
          <ul className="suggestions-list" ref={listRef} onScroll={handleScroll} style={{ maxHeight: selectMaxHeight }}>
            {suggestions.map(item => (
              <li key={item} onClick={() => handleSelect(item)}>
                {item}
              </li>
            ))}
            {errorOption &&
              <li onClick={() => setShowSuggestions(false)}>
                {errorOption}
              </li>}
            {isLoading &&
              <li>
                <div className="d-flex justify-content-center p-2">
                  <div className="spinner-border text-primary" role="status" />
                </div>
              </li>}
          </ul>
        }

        {isLoading && suggestions?.length === 0 && (
          <ul className="suggestions-list" style={{ maxHeight: selectMaxHeight }}>
            <div className="d-flex justify-content-center p-4">
              <div className="spinner-border text-primary" role="status" />
            </div>
          </ul>
        )}
      </div>
    </div>
  )
}

SuggestionsInput.propTypes = {
  label: PropTypes.string.isRequired,
  checkLabel: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  abbleSuggestionsCheck: PropTypes.bool,
  defaultAbbleSuggestions: PropTypes.bool,
  selectMaxHeight: PropTypes.number,
  getDataCallback: PropTypes.func.isRequired,
  selectCallback: PropTypes.func.isRequired,
};

SuggestionsInput.defaultProps = {
  label: 'Need to inherit a label',
  checkLabel: 'Habilitar sugestões',
  placeholder: 'Digite um valor',
  value: '',
  abbleSuggestionsCheck: false,
  defaultAbbleSuggestions: false,
  selectMaxHeight: 400,
  getDataCallback: () => console.log('Need to inherit a callback: getDataCallback'),
  selectCallback: () => console.log('Need to inherit a callback: selectCallback'),
}
export default SuggestionsInput