import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { URL } from '../../../variables';
import Separacao from './Separacao';

const EditSeparacao = () => {
  const [state, setState] = React.useState({
    separacao_id: '',
    residuos: [],
    token: '',
  })
  const params = useParams();
  let id = params.id;
  state.token = useSelector(state => state.AppReducer.token);

  React.useEffect(() => {
    fetch(`${URL}api/get_residuo_separacao/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        console.log('json', json)
        setState({
          ...state,
          separacao_id: json.separacao.id,
          residuos: json.residuos,
        })
      })
  }, [])

  return (
    <div>
      {state.separacao_id && <Separacao edit={state} setEdit={setState} />}
    </div>
  )
}

export default EditSeparacao