import React from "react";
import Filter from "../../../Auxiliar/Filter";
import RelacionamentosInternos from "./RelacionamentosInternos";

const SelecionarJsRelacionamentosInternos = () => {
  return (
    <div className="card">
      <div className="card-body" id="card">
        <h4
          className="mt-0 header-title"
          style={{ fontSize: "1.5rem", color: "black" }}
        >
          Relacionamentos Internos
        </h4>
        <div>
          <p style={{ fontSize: "1rem", color: "black" }}>
            Aqui são listados os relacionamentos internos, feitos entre
            ambientes.
          </p>
        </div>
        <div className="row">
          <RelacionamentosInternos />
        </div>
      </div>
    </div>
  );
};

export default SelecionarJsRelacionamentosInternos;
