import React from 'react'

const Label = ({ title, text, className, spaced }) => {
  return (
    <div className={`d-flex ${spaced ? 'my-3' : ''} ${className}`}>
      <p className='m-0 bold'>{title}:</p>
      <p className="m-0 ps-2">{text}</p>
    </div>
  )
}

Label.defaultProps = {
  title: '',
  text: '',
  className: '',
  spaced: true
}

export default Label