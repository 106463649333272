//* Libraries imports
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import Chart from "react-apexcharts";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdErrorOutline } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import { Skeleton } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

//* Utils imports
import { maskMoneyV2, maskNumberBr } from "../../../Auxiliar/Masks";
import { URL } from "../../../variables";

export default function RelatoriosColetaTransporte() {
  //* report filters
  const [filters, setFilters] = useState({
    type: "Receita",
    situation: "Todas",
    startDate: "",
    endDate: "",
    material: "Todos",
    materialKind: "Todos",
    pickupPoints: [],

    materials: [],
    selectedMaterials: [],
    avaliablePickupPoints: [],
  });
  const handleFiltersChange = ({ name, value = null }) => {
    setFilters((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePickupPointSelector = ({ value = null }) => {
    const findPickupPoint = filters.avaliablePickupPoints.find((avaliablePP) => avaliablePP.id === value);
    if (!findPickupPoint) return;

    const updatedAvaliablePickupPoints = filters.avaliablePickupPoints.filter((avaliablePP) => avaliablePP.id !== value);
    filters.pickupPoints.unshift(findPickupPoint);

    setFilters((prevData) => ({
      ...prevData,
      avaliablePickupPoints: updatedAvaliablePickupPoints,
      pickupPoints: [...filters.pickupPoints],
    }));
  }
  const removePickupPointBadge = (value = null) => {
    const findPickupPoint = filters.pickupPoints.find((pp) => pp.id === value);
    if (!findPickupPoint) return;

    const updatedPickupPoints = filters.pickupPoints.filter((pp) => pp.id !== value);
    filters.avaliablePickupPoints.push(findPickupPoint);

    setFilters((prevData) => ({
      ...prevData,
      avaliablePickupPoints: [...filters.avaliablePickupPoints],
      pickupPoints: updatedPickupPoints,
    }));
  }

  //* support data
  const [support, setSupport] = useState({
    error: null,
    generatingReport: false,
    loadingTable: false,
    filterBy: "balance", // can filter by "weight" or "balance"
    generator: false,
    isPickupPointRelated: false
  });

  // update support data state
  const handleSupportChange = ({ name, value = null }) => {
    setSupport((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // update report type change
  const handleReportTypeChange = ({ value = null }) => {
    if (value === "Receita PC" || value === "Despesa PC") {
      handleSupportChange({ name: "isPickupPointRelated", value: true });
      handleFiltersChange({ name: "type", value: value })
      handleFiltersChange({ name: "situation", value: "Finalizadas" })
    }
    else if (value === "Receita" || value === "Despesa") {
      handleSupportChange({ name: "isPickupPointRelated", value: false });
      setFilters((prevData) => ({
        ...prevData,
        type: value,
        pickupPoints: []
      }));
    }
  };


  //* report/chart/table data
  const [report, setReport] = useState({
    chart: {
      title: "",
      description: "",
      seriesWeight: [],
      seriesBalance: [],
      labels: [],
    },
    table: {
      dispatches: [],
      totalWeight: null,
      totalVolume: null,
      revenue: null,
      expense: null,
      balance: null,
    },
    page: 1,
    totalPages: 1,
  });
  const reducer = useSelector((store) => store.AppReducer);

  //* get report with all filters
  const getReport = async (page) => {
    if (!support.generatingReport && !report.table.dispatches) {
      handleSupportChange({ name: "generatingReport", value: true });
    } else {
      handleSupportChange({ name: "loadingTable", value: true });
    }

    fetch(`${URL}api/get_relatorios_coleta_transporte?page=${page}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${reducer.token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const { errors, error } = data;

        if (errors || error) {
          const errorMessages = errors
            ? Object.values(errors).join("\n")
            : error;

          console.error(errorMessages);
          handleSupportChange({ name: "error", value: errorMessages });
        } else {
          // calculating values (monetary and weight)
          const calculateValues = (group, key) =>
            group
              .map((item) => parseFloat(item[key] || 0))
              .reduce((t, n) => t + n, 0);

          // update common data
          const updateState = (data, isEmpty) => {
            setSupport((prevData) => ({
              ...prevData,
              error: null,
              generatingReport: false,
              loadingTable: false,
            }));

            // update filters
            setFilters((prevData) => ({
              ...prevData,
              materials: data.materiais,
              selectedMaterials: data.materiais,
              avaliablePickupPoints: data.pontos_coleta,
            }));

            // update reports=>chart/table
            setReport((prevData) => ({
              ...prevData,
              chart: {
                title: isEmpty ? "Não encontrado" : data.titulo,
                description: isEmpty
                  ? "Não há registro suficiente para gerar relatório com os dados fornecidos ..."
                  : data.descricao,
                labels: data.despachos_group.map((item) => item.fantasia),
                seriesBalance: data.despachos_group.map((item) =>
                  parseFloat(item.soma)
                ),
                seriesWeight: data.despachos_group.map((item) =>
                  parseFloat(item.peso)
                ),
              },
              table: {
                dispatches: data.despachos_page,
                revenue: isEmpty
                  ? 0
                  : calculateValues(data.despachos_group, "soma"),
                expense: isEmpty
                  ? 0
                  : calculateValues(data.despachos_group, "despesas"),
                balance: isEmpty
                  ? 0
                  : calculateValues(data.despachos_group, "saldo"),
                totalWeight: isEmpty
                  ? 0
                  : calculateValues(data.despachos_group, "peso"),
              },
              page: data.pagination.current_page,
              totalPages: data.pagination.last_page,
            }));
          };

          if (data.despachos_group.length === 0) {
            updateState(data, true);
          } else {
            updateState(data, false);
          }
        }
      })
      .catch((err) => {
        console.error(err);
        // handleSupportChange({ name: "error", value: err });
      });
  };

  //* generate report with all filters
  const generateReport = async (page, generator) => {
    const {
      startDate,
      endDate,
      DataAtual,
      materialKind,
      material,
      pickupPoints,
      type,
      situation,
    } = filters;

    // simplified error change
    const setError = (msg) =>
      handleSupportChange({ name: "error", value: msg });

    // validating dates
    if ((startDate && !endDate) || (!startDate && endDate)) {
      setError("Digite ambas ou nenhuma data");
      return;
    }

    if (startDate && endDate && startDate > endDate) {
      setError("Datas conflitantes");
      return;
    }

    if (
      (startDate && DataAtual && startDate > DataAtual) ||
      (endDate && DataAtual && endDate > DataAtual)
    ) {
      setError("Data inválida. Não é possível gerar relatórios futuros");
      return;
    }

    // starts loading
    setSupport((prevData) => ({
      ...prevData,
      error: null,
      generatingReport: generator || false,
      loadingTable: generator || false,
      generator: true,
    }));

    // build pickup point id array
    const pickupPointsIds = pickupPoints.map(item => item.id)

    // Faz a requisição fetch
    fetch(`${URL}api/gerar_relatorios_coleta_transporte`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${reducer.token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data_inicial: startDate,
        data_final: endDate,
        tipo_material: materialKind,
        material: material,
        pontos_coleta_ids: pickupPointsIds,
        tipo_relatorio: type,
        situacao: situation,
        page: page,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errors || data.error) {
          const errorMessages = data.errors
            ? Object.values(data.errors).join("\n")
            : data.error;

          setSupport((prevData) => ({
            ...prevData,
            error: errorMessages,
            generatingReport: false,
            loadingTable: false,
          }));
          window.scrollTo(500, 0);

          setReport((prevData) => ({
            ...prevData,
            chart: {
              description:
                "Não foi possível gerar o relatório por falta de informações...",
            },
          }));
          return;
        }

        // update state w/ new data
        const despachosGroup = data?.despachos_group
        const despachosPag = data?.despachos_page;
        const isEmpty = despachosPag?.length === 0;
        const calculateTotal = (key) =>
          despachosPag
            .map((item) => parseFloat(item[key] || 0))
            .reduce((total, value) => total + value, 0);

        // update helpers
        setSupport((prevData) => ({
          ...prevData,
          error: null,
          generatingReport: false,
          loadingTable: false,
        }));

        // update reports=>chart/table
        setReport((prevData) => ({
          ...prevData,
          chart: {
            title: isEmpty ? "Não encontrado" : data.titulo,
            description: isEmpty
              ? "Não há registro suficiente para gerar relatório com os dados fornecidos ..."
              : data.descricao,
            labels: despachosGroup.map((item) => item.fantasia),
            seriesBalance: despachosGroup.map((item) => {
              if (data.tipo_relatorio === "Receita" || data.tipo_relatorio === "Receita PC" || data.tipo_relatorio === "Despesa") {
                return parseFloat(item.soma)
              }
              else if (data.tipo_relatorio === "Despesa PC") {
                return parseFloat(item.despesas)
              }
            }),

            seriesWeight: despachosGroup.map((item) => parseFloat(item.peso)),
          },
          table: {
            dispatches: data.despachos_page,
            revenue: isEmpty
              ? 0
              : data.tipo_relatorio === "Receita" || data.tipo_relatorio === "Receita PC" || data.tipo_relatorio === "Despesa PC"
                ? calculateTotal("soma")
                : calculateTotal("receita"),
            expense: isEmpty
              ? 0
              : data.tipo_relatorio === "Despesa"
                ? calculateTotal("soma")
                : calculateTotal("despesas"),
            balance: isEmpty ? 0 : calculateTotal("saldo"),
            totalWeight: isEmpty ? 0 : calculateTotal("peso"),
          },
          page: data.pagination.current_page,
          totalPages: data.pagination.last_page,
        }));
      })
      .catch((err) => {
        console.error(err);

        setSupport((prevData) => ({
          ...prevData,
          error:
            "Erro ao pegar resposta do servidor. Você está conectado a internet?",
          generatingReport: false,
          loadingTable: false,
        }));
      });
  };

  useEffect(() => {
    getReport(1);
    // let data = new Date();
    // let dia = String(data.getDate()).padStart(2, "0");
    // let mes = String(data.getMonth() + 1).padStart(2, "0");
    // let ano = data.getFullYear();
    // setSupport((prevData) => ({
    //   ...prevData,
    //   currentDate: ano + "-" + mes + "-" + dia,
    //   currentYear: ano,
    // }));
  }, []);

  //* Chart secondary filter
  function getSeriesByFilter(filterBy) {
    switch (filterBy) {
      case "weight":
        return report.chart.seriesWeight;
      case "balance":
        return report.chart.seriesBalance;
      default:
        return report.chart.seriesWeight;
    }
  }

  function updateTitleByFilter(filterBy) {
    switch (filterBy) {
      case "weight":
        return "(Kg)";
      case "balance":
        return "(R$)";
      default:
        return null;
    }
  }

  function handlePageClick(page) {
    if (page.selected + 1 != report.page) {
      if (support.generator) {
        generateReport(page.selected + 1);
      } else {
        getReport(page.selected + 1);
      }
    }
  }

  return (
    <div className="row">
      <div className="container">
        {support.error && ErrorMessage()}
        <section id="filters" className="pb-2">
          <div className=" col-sm-12">
            <div className="row">
              <div className="col-md-3 col-12">
                <label className="col-sm-12 col-form-label">Relatório</label>
                <div className="input-group mb-3">
                  <select
                    className="form-control"
                    id="report-type"
                    name="type"
                    value={filters.type}
                    onChange={(e) => handleReportTypeChange(e.target)}
                  >
                    <option value={"Receita"}>Receita por estoque</option>
                    <option value={"Despesa"}>Despesa por estoque</option>
                    <option value={"Receita PC"}>
                      Receita por ponto de coleta
                    </option>
                    <option value={"Despesa PC"}>
                      Despesa por ponto de coleta
                    </option>
                  </select>
                </div>
              </div>
              <div className="col-md-3 col-12">
                <label className="col-sm-12 col-form-label">Situação</label>
                <div className="input-group mb-3">
                  <select
                    className="form-control"
                    id="report-situation"
                    name="situation"
                    value={filters.situation}
                    onChange={(e) => handleFiltersChange(e.target)}
                    disabled={support.isPickupPointRelated}
                  >
                    <option value={"Todas"}>Todas</option>
                    <option value={"Em andamento"} defaultValue>
                      Em andamento
                    </option>
                    <option value={"Finalizadas"}>Coleta recebida</option>
                  </select>
                </div>
              </div>

              <div className="col-md-3 col-12">
                <label className="col-sm-12 col-form-label">
                  Data inicial:
                </label>
                <div className="col-sm-12">
                  <input
                    className="form-control"
                    type="date"
                    id="report-start-date"
                    name="startDate"
                    value={filters.startDate}
                    style={{ maxWidth: 168 }}
                    onChange={(e) => handleFiltersChange(e.target)}
                  />
                </div>
              </div>
              <div className="col-md-3 col-12">
                <label className="col-sm-12 col-form-label">Data final:</label>
                <div className="input-group mb-3">
                  <input
                    className="form-control"
                    type="date"
                    id="report-end-date"
                    name="endDate"
                    value={filters.endDate}
                    style={{ maxWidth: 168 }}
                    onChange={(e) => handleFiltersChange(e.target)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className=" col-sm-12">
            <div className="row">
              <div className="col-md-6 col-12">
                <label className="col-sm-12 col-form-label">
                  Tipo de material
                </label>
                <div className="input-group mb-3">
                  <select
                    className="form-control"
                    id="report-material-kind"
                    name="materialKind"
                    value={filters.materialKind}
                    onChange={(e) => {
                      const materials =
                        e.target.value === "Todos"
                          ? filters.materials
                          : filters.materials.filter(
                            (material) => material.tipo === e.target.value
                          );

                      handleFiltersChange(e.target);
                      handleFiltersChange({ name: "material", value: "Todos" });
                      handleFiltersChange({
                        name: "selectedMaterials",
                        value: materials,
                      });
                    }}
                  >
                    <option value={"Todos"}>Todos</option>
                    <option value={"Resíduo"}>Resíduo</option>
                    <option value={"Rejeito"}>Rejeito</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <label className="col-sm-12 col-form-label">Material</label>
                <div className="input-group mb-3">
                  <select
                    className="form-control"
                    id="report-material"
                    name="material"
                    value={filters.material}
                    onChange={(e) => handleFiltersChange(e.target)}
                  >
                    <option value={"Todos"}>Todos</option>
                    {filters.selectedMaterials?.map(
                      (selectedMaterial, index) => (
                        <option key={index} value={selectedMaterial.id}>
                          {selectedMaterial.nome}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
          {support.isPickupPointRelated ? (<div className="col-12">
            <label className="col-sm-12 col-form-label">Ponto de Coleta</label>
            <div className="input-group mb-3">
              <select
                className="form-control"
                id="report-pickup-point"
                name="pickupPoints"
                value={"Todos"}
                onChange={(e) => handlePickupPointSelector(e.target)}
              >
                <option value={"Todos"}>Selecione um ou mais pontos de coleta</option>
                {filters.avaliablePickupPoints.map((point, indexPoint) => (
                  <option key={indexPoint} value={point.id}>
                    {point.nome_fantasia}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex align-items-center gap-2">
              {filters.pickupPoints.length > 0 ? <>
                {filters.pickupPoints.map((item, index) => (
                  <span
                    className="rounded-pill fw-bold text-white"
                    key={index}
                    style={{
                      backgroundColor: '#006400',
                      padding: '0.25rem 0.75rem',
                      fontSize: '0.875rem',
                      position: 'relative',
                    }}
                  >
                    {item.nome_fantasia}
                    <CloseRounded
                      style={{
                        marginLeft: '0.5rem',
                        cursor: 'pointer',
                        fontSize: '1rem',
                      }}
                      onClick={() => removePickupPointBadge(item.id)}
                    />
                  </span>
                ))} </> :
                (<span
                  className="rounded-pill fw-bold text-white"
                  style={{
                    backgroundColor: '#006400',
                    padding: '0.25rem 0.75rem',
                    fontSize: '0.875rem',
                    position: 'relative',
                  }}
                >
                  Todos
                </span>)}
            </div>
          </div>

          ) : (<></>)}
          <div className="d-grid d-md-flex justify-content-md-end">
            {support.generatingReport ? (
              <button type="button" className="btn btn-info" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="sr-only">Gerando...</span>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-info"
                onClick={() => generateReport(1, true)}
              >
                Gerar relatório
              </button>
            )}
          </div>
        </section>
        <hr />
        <div className="col-12">
          <div className="card">
            <section id="chart" className="card-body">
              {support.generatingReport ? (
                <ChartSkeleton />
              ) : (
                <>
                  <div className="d-grid gap-2 d-md-flex justify-content-between">
                    <div>
                      <h4
                        className="mt-0 text-dark"
                        style={{ fontSize: "1.5rem" }}
                      >
                        {report.chart.title} {updateTitleByFilter(support.filterBy)}
                      </h4>
                      <p className="text-dark" style={{ fontSize: "1rem" }}>
                        {report.chart.description}
                        <IoMdInformationCircleOutline
                          data-tip={`Para ver dados menores que não aparecem no gráfico, clique na legenda`}
                        />
                        <ReactTooltip place="top" effect="solid" />
                      </p>
                    </div>
                    <div>
                      <button
                        className={`btn btn-success ${support.filterBy === "balance" ? "active" : ""
                          }`}
                        type="button"
                        onPointerDown={() => {
                          handleSupportChange({
                            name: "filterBy",
                            value: "balance",
                          });
                        }}
                      >
                        R$
                      </button>
                      <button
                        className={`btn btn-success ${support.filterBy === "weight" ? "active" : ""
                          }`}
                        type="button"
                        onPointerDown={() =>
                          handleSupportChange({
                            name: "filterBy",
                            value: "weight",
                          })
                        }
                      >
                        KG
                      </button>
                    </div>
                  </div>
                  <br />
                  {report.chart.title == "Não encontrado" ? (
                    <div
                      style={{
                        fontSize: "100px",
                        textAlign: "center",
                        color: "#ffc107",
                      }}
                    >
                      <MdErrorOutline />
                    </div>
                  ) : (
                    <div style={{ width: "80%", position: "center" }}>
                      <Chart
                        options={{
                          dataLabels: {
                            enabled: true,
                          },
                          annotations: {
                            texts: [filters.type],
                          },
                          plotOptions: {
                            pie: {
                              donut: {
                                size: "50%",
                                labels: {
                                  show: true,
                                  name: {
                                    show: true,
                                    color: "black",
                                    fontWeight: "bold",
                                  },
                                  value: {
                                    offsetY: 0,
                                    show: true,
                                    formatter: function (val, opts) {
                                      return maskNumberBr(
                                        parseFloat(val).toFixed(2)
                                      );
                                    },
                                  },
                                  total: {
                                    show: true,
                                    formatter: function (w) {
                                      let resultado =
                                        w.globals.seriesTotals.reduce(
                                          (total, numero) => {
                                            return total + numero;
                                          }
                                        );

                                      return maskNumberBr(
                                        parseFloat(resultado).toFixed(2)
                                      );
                                    },
                                  },
                                },
                              },
                            },
                          },
                          labels: report.chart.labels,
                          legend: {
                            formatter: function (labelName) {
                              return (
                                labelName.charAt(0).toUpperCase() +
                                labelName.slice(1)
                              );
                            },
                            position: "right",
                            fontSize: "16px",
                            fontWeight: "bold",
                            horizontalAlign: "right",
                            fontFamily: "Roboto, sans-serif",
                          },
                        }}
                        series={getSeriesByFilter(support.filterBy)}
                        height="350px"
                        width="100%"
                        type="donut"
                      />
                    </div>
                  )}
                </>
              )}
            </section>
            <section id="table">
              <ul className="list-group list-group-flush">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Favorecido/Credor</th>
                        <th className="text-end">Valor(Kg)</th>
                        <th className="text-end">Receita</th>
                        <th className="text-end">Despesa</th>
                        <th className="text-end">Saldo</th>
                      </tr>
                    </thead>

                    <tbody>
                      {support.loadingTable ? (
                        <TableSkeleton />
                      ) : (
                        <>
                          {report.table.dispatches.map((dispatch, index) => (
                            <tr key={index}>
                              <td
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "bold",
                                  color: "blue",
                                }}
                              >
                                {dispatch.fantasia.charAt(0).toUpperCase() +
                                  dispatch.fantasia.slice(1)}
                              </td>
                              <td
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "normal",
                                  color: "black",
                                  textAlign: "right",
                                }}
                              >
                                {maskNumberBr(
                                  parseFloat(dispatch.peso).toFixed(2)
                                ) + " Kg"}
                              </td>
                              <td
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "normal",
                                  color: "black",
                                  textAlign: "right",
                                }}
                              >
                                {filters.type == "Receita" || filters.type == "Receita PC" || filters.type == "Despesa PC"
                                  ? maskMoneyV2(parseFloat(dispatch.soma))
                                  : maskMoneyV2(parseFloat(dispatch.receita))}
                              </td>
                              <td
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "normal",
                                  color: "black",
                                  textAlign: "right",
                                }}
                              >
                                {filters.type == "Despesa"
                                  ? maskMoneyV2(parseFloat(dispatch.soma))
                                  : maskMoneyV2(parseFloat(dispatch.despesas))}
                              </td>
                              <td
                                style={{
                                  fontSize: "1rem",
                                  fontWeight: "normal",
                                  color: "black",
                                  textAlign: "right",
                                }}
                              >
                                {maskMoneyV2(parseFloat(dispatch.saldo))}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div
                  className="col-12 d-flex justify-content-end"
                  style={{ marginBottom: "10px" }}
                >
                  <ReactPaginate
                    previousLabel={"Anterior"}
                    nextLabel={"Próxima"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={report.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    initialPage={report.page - 1}
                  />
                </div>
              </ul>
              <ul className="list-group list-group-flush">
                <table>
                  <tbody>
                    <tr>
                      <td
                        style={{
                          fontSize: "1rem",
                          fontWeight: "bold",
                          color: "black",
                          width: "35%",
                        }}
                      >
                        Total
                      </td>
                      <td
                        style={{
                          fontSize: "1rem",
                          fontWeight: "normal",
                          color: "black",
                          textAlign: "right",
                        }}
                      >
                        {report.table.totalWeight ? (
                          <>
                            {maskNumberBr(
                              parseFloat(report.table.totalWeight).toFixed(2)
                            ) + " Kg"}
                          </>
                        ) : (
                          <Skeleton />
                        )}
                      </td>
                      <td
                        style={{
                          fontSize: "1rem",
                          fontWeight: "normal",
                          color: "black",
                          textAlign: "right",
                        }}
                      >
                        {report.table.revenue ? (
                          <>{maskMoneyV2(parseFloat(report.table.revenue))}</>
                        ) : (
                          <Skeleton />
                        )}
                      </td>
                      <td
                        style={{
                          fontSize: "1rem",
                          fontWeight: "normal",
                          color: "black",
                          textAlign: "right",
                        }}
                      >
                        {report.table.expense ? (
                          <>{maskMoneyV2(parseFloat(report.table.expense))}</>
                        ) : (
                          <Skeleton />
                        )}
                      </td>
                      <td
                        style={{
                          fontSize: "1rem",
                          fontWeight: "normal",
                          color: "black",
                          textAlign: "right",
                        }}
                      >
                        {report.table.balance ? (
                          <>{maskMoneyV2(parseFloat(report.table.balance))}</>
                        ) : (
                          <Skeleton />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ul>
            </section>
          </div>
        </div>
        <hr />
      </div>
    </div >
  );

  //* Error msg component
  function ErrorMessage() {
    return (
      <div
        className="alert alert-danger alert-dismissible fade show mb-0"
        role="alert"
      >
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => handleSupportChange({ name: "error", value: null })}
        ></button>
        <p className="mb-0" style={{ whiteSpace: "pre" }}>
          {support.error}
        </p>
      </div>
    );
  }

  //* Chart skeleton component
  function ChartSkeleton() {
    return (
      <>
        <div className="mt-0 col-5">
          <Skeleton className="py-4" />
        </div>
        <div>
          <Skeleton />
        </div>
        <Skeleton height={300} />
      </>
    );
  }

  //* Table skeleton component
  function TableSkeleton() {
    return (
      <>
        {Array.from({ length: 5 }).map((_, index) => (
          <tr key={index}>
            <td>
              <Skeleton />
            </td>
            <td className="text-end">
              <Skeleton />
            </td>
            <td className="text-end">
              <Skeleton />
            </td>
            <td className="text-end">
              <Skeleton />
            </td>
            <td className="text-end">
              <Skeleton />
            </td>
          </tr>
        ))}
      </>
    );
  }
}
