

import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout, mudarDados } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { NAME, URL, URLadmin } from '../../../variables';
import { maskTelefone, maskCpf } from '../../../Auxiliar/Masks';
import SweetAlert from 'react-bootstrap-sweetalert';

import { MdReply } from 'react-icons/md';


class CriarLogo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: false,
            page: 1,
            descricao: '',
            gravar: '',
            name: '',
            imagem: '',
            imagem_path: '',
            show_message: false,
            show_success: false,
            show_warning: false,

            logos: [],
            loading_save: false,
            msgErro: ''
        };
    }

    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }

    componentDidMount() {
        this.get_logos()
    }

    salvar() {
        this.setState({ loading_save: false, msgErro: 'Alterações devem ser feitas pelo painel SuperAdmin' });
        window.scrollTo(500, 0);
        return
        let form = new FormData();

        form.append('imagem', this.state.imagem);

        console.log(form);
        fetch(`${URL}api/store_logos`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);
                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {
                    this.setState({ loading_save: false, redirect: false, show_success: true });

                }
            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }
        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    get_logos() {
        fetch(`${URLadmin}api/auth/admin/blueen_get_ambiente?connection=${NAME}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp.organization.logo);
                this.props.mudarDados({ logo: URLadmin + resp.organization.logo });
                this.setState({ imagem_path: URLadmin + resp.organization.logo })
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }

        })
            .catch((err) => {
                this.setState({ redirect: true });
                console.log(err);
            });
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Cadastrar logo</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Cadastre os logos do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Imagem</label>
                                            <div className="col-sm-12">
                                                <input disabled className="form-control" type="file" accept="image/*" onChange={(e) => {
                                                    let imagem = e.target.files[0];
                                                    console.log(imagem)
                                                    this.setState({ imagem });
                                                    let imagem_path = '';
                                                    let reader = new FileReader()
                                                    reader.readAsDataURL(e.target.files[0])
                                                    reader.onload = () => {
                                                        imagem_path = reader.result;
                                                        this.setState({ imagem_path });
                                                    };
                                                    console.log(imagem_path)
                                                    reader.onerror = function (error) {
                                                        console.log('Error: ', error);
                                                    }
                                                    e.target.value = '';
                                                }} />

                                            </div>
                                        </div>
                                        <div className="row">
                                            {this.state.imagem_path != '' &&
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }} className="col-12 col-md-12 mt-3">
                                                    <div>
                                                        <img src={this.state.imagem_path} style={{ height: 100, width: 100, objectFit: "contain", border: '1px solid lightgrey' }} />
                                                        {/* <p><a onClick={() => {
                                                            this.setState({ imagem: '', imagem_path: '' });
                                                        }} className="btn btn-link btn-link-dark" style={{ color: 'indianred', cursor: 'pointer' }}><span style={{ marginRight: 5 }}>Remover</span><i className="fas fa-times"></i></a></p> */}
                                                    </div>
                                                </div>

                                            }
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                                {this.state.loading_save == false && <div className="row mt-3">

                                    <div className="col-12 col-sm-12 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar logo</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}
                            </div>}
                        </div>
                    </div>
                </div>
                <SweetAlert
                    success
                    title={"Sucesso"}
                    onConfirm={() => {
                        // this.salvar();
                        this.setState({ show_success: false })

                        this.get_logos();

                    }}

                    show={this.state.show_success}
                    confirmBtnText='Ok'
                    confirmBtnBsStyle="success"
                    showCancel={false}
                >
                    Dados salvos com sucesso

                </SweetAlert>
            </div>
        );
    }
}


const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout, mudarDados })(CriarLogo);
