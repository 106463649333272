import React from 'react'

const GetRRClass = (props) => {
  console.log('props', props)
  return (
    fetch(`https://mtrr.cetesb.sp.gov.br/apiws/rest/retornaListaClassePorResiduo/${props.codigoIbama}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Authorization': props.token,
      }
    }).then(async (response) => {
      const resp = await response.json()
      let classes = [...props.classes]
      console.log('resposta residuo',resp.objetoResposta)
      classes.map(item => {
        if (item.sigor_codigos) {
          resp.objetoResposta.map(item2 => {
            if (item.sigor_codigos.classe_codigo === item2.claCodigo) {
              item.hasAproved = true
              item2.hasAproved = true
            }
            return item2
          })
        }
        return item
      })
      let filter = classes.filter(item => item.hasAproved === true)
      if (filter.length !== resp.objetoResposta.length) {
        resp.objetoResposta.forEach(item => {
          if (!item.hasAproved) {
            classes.push({ id: item.claCodigo, nome: item.claDescricao, hasPendding: true })
          }
        })
      }
      classes = classes.filter(item => item.hasAproved || item.hasPendding)

      props.classes.forEach(item => {
        classes.forEach(item2 => { if (item?.relation?.sigor_classe_id === item2.id) classes.push(item) })
      })
      console.log('new classes', classes)
      return {classes,all_classes:resp.objetoResposta}
    })
  )
}

export default GetRRClass