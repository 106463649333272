import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { BsCheckLg } from 'react-icons/bs';
import { IconButton, Step, StepLabel, Stepper, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import { URL } from '../../../variables';
import { MdClose } from 'react-icons/md';
import EditarClasseSigor from './EditarClasseSigor';
import SigorRelacionadas from './SigorRelacionadas';
import Theme from '../../Theme';

const style = {
  position: 'absolute',
  // top: '50%',
  left: '50%',
  transform: 'translate(0, 0, 0, 50%)',
  width: '50%',
  height: '100%',
  bgcolor: 'background.paper',
  border: '2px solid transparent',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  '@media(max-width: 1000px)': {
    width: '90%',
    left: '5%',
  },
};

const ModalClasse = (props) => {
  const [state, setState] = React.useState({
    redirect: false,
    path: '/classes',
    loading: true,
    nome: '',
    loading_save: false,
    msgErro: '',
  });
  const [classes, setClasses] = React.useState({
    loading: true,
    classe_id: '',
    classes: [],
    all_classes: [],
    classes_selecionadas: [],
    classe_codigo: '',
  })
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const token = useSelector((state) => state.AppReducer.token);

  React.useEffect(() => {
    if (open === true) {
      fetch(`${URL}api/get_classe_sigor/${props.id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'application/json',
        },
      }).then(async (responseLog) => {
        let resp = await responseLog.json();
        setState({ loading: false, descricao: resp.classe.descricao, classe_codigo: resp.classe.codigo });
        fill_classes()
      });

      function fill_classes() {
        fetch(`${URL}api/fill_classes_sigor`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }).then(async (response) => {
          const resp = await response.json()
          console.log('resp', resp)
          setClasses({ ...classes, classes: resp.classes, all_classes: resp.classes, classe_id: resp.classes[0].id, loading: false })
        })
      }
    }
  }, [open]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function salvar() {
    const obj_classe = {
      unidade_id: props.id,
      classes: classes.classes_selecionadas,
      classe_codigo: state.classe_codigo,
    };

    props.storeClasse(obj_classe);
    setOpen(false);
  }

  function renderPage() {
    if (page === 1) return <EditarClasseSigor id={props.id} state={state} setState={(e) => setState(e)} />
    if (page === 2) return <SigorRelacionadas id={props.id} state={classes} setState={(e) => setClasses(e)} />
  }

  return (
    <>
      <button className="btn btn-primary btn-outline btn-circle me-1 mb-1" data-tip="Inserir" onClick={handleOpen}
        disabled={props.disabled}>
        <BsCheckLg />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ThemeProvider theme={Theme}>
          <Box sx={style}>
            <>
              <IconButton onClick={handleClose}>
                <MdClose />
              </IconButton>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div className="text-center">
                  <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Cadastrar classe de resíduo</h4>
                  <p style={{ fontSize: '1rem', color: 'black' }}>Cadastre um classe de resíduo do seu sistema</p>
                </div>

                <Stepper activeStep={page - 1} alternativeLabel>
                  <Step>
                    <StepLabel color='success'>Dados de Classe</StepLabel>
                  </Step>
                  <Step>
                    <StepLabel>Classes Relacionadas</StepLabel>
                  </Step>
                </Stepper>

              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <div className="row">
                  {!state.loading ? (
                    <div>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {renderPage()}
                      </Typography>

                      <div className="d-flex mt-3">
                        <div className="align-self-center">
                          <Button sx={{ color: 'text.main' }} variant="contained" size="large" onClick={() => setPage(page - 1)}
                            disabled={page === 1}>
                            Voltar
                          </Button>
                        </div>
                        <div className="align-self-end ms-auto">
                          {page < 2 && (
                            <Button sx={{ color: 'text.main' }} variant="contained" size="large" onClick={() => setPage(2)}
                              disabled={page === 2}>
                              Próximo
                            </Button>
                          )}
                          {page === 2 && (
                            <Button sx={{ color: 'text.main' }} className="align-self-end" variant="contained" size="large" onClick={() => salvar()}>
                              Salvar
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                    :
                    <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                      <div className="spinner-border text-primary" role="status"></div>
                    </div>}
                </div>
              </Typography>
            </>
          </Box>
        </ThemeProvider>
      </Modal>
    </>
  );
};

export default ModalClasse;
