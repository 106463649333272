
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
// users
import { URL } from '../../../variables';
import { maskNumber, maskNumberBr } from '../../../Auxiliar/Masks';
import { FaTrash } from 'react-icons/fa';
import SweetAlert from 'react-bootstrap-sweetalert';

class SolicitarColetaSimplificada extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/estoque_coleta_transporte',
            loading_screen: false,

            PontosColetas: [],
            Estoque: [],
            Materiais: [],
            Responsaveis: [],
            Todos_Responsaveis: [],
            MateriaisSelecionados: [],
            MateriaisIseridos: [],
            MaterialSelecionado: {},
            EstoqueSelecionado: {},

            Descricao:'',
            UnidadePeso: '',
            UnidadeVolume: '',
            Material: '',
            Peso: '',
            Volume: '',
            Peso_kg: '',
            Volume_lt: '',
            NomeMaterial: '',
            PontoColeta: '',
            DataAtual: '',
            DataSolicitacao: '',
            loading_save: false,
            msgErro: '',
            show_warning: false,
            validated: false,
            required: false,
            Responsavel_id:this.props.user.id,
        };
    }


    get_data() {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_dados_solicitar_coleta`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    let ponto = [];
                    let usuario =this.props.user;
                    for (let i= 0; i < resp.pontos_coleta.length; i++){
                        for (let j= 0; j < resp.pontos_coleta[i].pessoas.length; j++){
                            if(resp.pontos_coleta[i].pessoas[j].id == usuario.id || resp.pontos_coleta.responsavel_id == usuario.id){
                                ponto.push(resp.pontos_coleta[i]);
                                j = resp.pontos_coleta[i].pessoas.length;
                            }
                        }
                    }
                    console.log(ponto)
                    this.setState({
                        loading_screen: false,
                        PontosColetas:ponto,
                        Todos_Responsaveis: resp.responsaveis,
                        Estoque: resp.estoque
                    });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        }).catch((err) => {
            this.setState({ redirect: true });

            console.log(err);
            // this.props.mudarLoadingHome(false);
        });
    }



    salvar() {

        if (this.state.MaterialSelecionado.id != undefined) {
            this.setState({ show_warning: true, loading_save:true, msgErro:'' });
        }else{
        this.setState({ loading_save: true, msgErro: '', show_warning: false});
        let peso =0;
        for(let i  in this.state.MateriaisSelecionados){
            peso += parseFloat(this.state.MateriaisSelecionados[i].peso_kg);
        };
        let volume =0;
        for(let i  in this.state.MateriaisSelecionados){
            volume += parseFloat(this.state.MateriaisSelecionados[i].volume_lt);
        };
        fetch(`${URL}api/store_solicitar_coleta`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                data: this.state.DataSolicitacao,
                ponto_coleta_id: this.state.PontoColeta,
                responsavel_id: this.state.Responsavel_id,
                total_kg:peso ,
                total_lt:volume ,
                status:" Aguardando Despacho",
                descricao: this.state.Descricao,
                solicitar_coleta_residuo_rejeito: this.state.MateriaisSelecionados

            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);
                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }

            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
        }
    }



    componentDidMount() {
        
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        this.setState({
            DataAtual: ano + '-' + mes + '-' + dia,
            DataSolicitacao: ano + '-' + mes + '-' + dia
        })
        this.get_data();
    }



    render() {

        console.log(this.props.user);
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Solicitação simplificada</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Registre uma nova solicitação de coleta</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Data da solicitação <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.DataSolicitacao} type="date" style={{maxWidth:168}} min={this.state.DataAtual} onChange={(e) => {
                                                    if (e.target.value < this.state.DataAtual) {
                                                        e.target.value = this.state.DataAtual
                                                    }
                                                    this.setState({ DataSolicitacao: e.target.value });
                                                }} />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Ponto de coleta<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.PontoColeta} className="form-control"
                                                    onChange={(e) => {
                                                        let pontos_estoque_sel = this.state.Estoque.filter(item => item.ponto_coleta_id == e.target.value);
                                                        let ponto_verif = pontos_estoque_sel.filter(item => item.peso_total != 0 && item.volume_total != 0);
                                                        let materiais = ponto_verif.map(item => item.material);
                                                        console.log(materiais);
                                                        if (e.target.value != '') {
                                                            let [autorizados] = this.state.PontosColetas.filter((item)=> item.id == e.target.value).map(item => item.pessoas);
                                                            let responsaveis = [];
                                                            for(let i = 0; i < autorizados.length; i++){
                                                                for(let j = 0; j < this.state.Todos_Responsaveis.length; j++){
                                                                    if(autorizados[i].user_id == this.state.Todos_Responsaveis[j].id){
                                                                        responsaveis.push(this.state.Todos_Responsaveis[j]);
                                                                         j = this.state.Todos_Responsaveis.length
                                                                    } 
                                                                }
                                                            }
                                                            this.setState({
                                                                required: false,
                                                                validated: false,
                                                                MaterialSelecionado: {},
                                                                MateriaisSelecionados:[],
                                                                Material: '',
                                                                PontoColeta: e.target.value,
                                                                Materiais: materiais,
                                                                Volume: '',
                                                                Peso: '',
                                                                Responsaveis:responsaveis
                                                            })
                                                        } else {
                                                            this.setState({
                                                                required: false,
                                                                validated: false,
                                                                MaterialSelecionado: {},
                                                                MateriaisSelecionados:[],
                                                                Material: '',
                                                                PontoColeta: e.target.value,
                                                                Materiais: [],
                                                                Volume: '',
                                                                Peso: '',
                                                                Responsaveis:[]
                                                            })
                                                        }

                                                    }}>
                                                    <option value={''}>Selecione uma opção</option>
                                                    {this.state.PontosColetas.map((item, id) => (<option key={id} value={item.id}>{item.nome_fantasia}</option>))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" >Materiais <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.Material} className="form-control" onChange={(e) => {
                                                    let verificador = false;
                                                    for(let i= 0; i< this.state.MateriaisSelecionados.length;i++){
                                                        if(e.target.value == this.state.MateriaisSelecionados[i].residuo_rejeito_id){
                                                             verificador = true;
                                                        }
                                                    }
                                                    if(verificador == true){
                                                        this.setState({
                                                            required: false,
                                                            validated: false,
                                                            Material: '',
                                                            EstoqueSelecionado: {},
                                                            MaterialSelecionado: {},
                                                            Volume: '',
                                                            Peso: ''
                                                        })
                                                    }else{
                                                    if (e.target.value != '') {
                                                        let material_selecionado = this.state.Materiais.filter(item => item.id == e.target.value);
                                                        let estoque = this.state.Estoque.filter(item => item.ponto_coleta_id == this.state.PontoColeta && item.material_id == material_selecionado[0].id);
                                                        console.log(material_selecionado)
                                                        this.setState({
                                                            required: false,
                                                            validated: false,
                                                            Material: e.target.value,
                                                            EstoqueSelecionado: estoque,
                                                            MaterialSelecionado: material_selecionado[0],
                                                            Volume: '',
                                                            Peso: ''
                                                        })
                                                    } else {
                                                        this.setState({
                                                            required: false,
                                                            validated: false,
                                                            Material: '',
                                                            EstoqueSelecionado: {},
                                                            MaterialSelecionado: {},
                                                            Volume: '',
                                                            Peso: ''
                                                        })
                                                    }
                                                }

                                                }}>
                                                    <option value={''}>Selecione um ou mais materias</option>
                                                    {this.state.Materiais.map((item, id) => (<option key={id} value={item.id}>{item.nome}</option>))}
                                                </select>
                                            </div>
                                        </div>

                                        {/* se material existir */}
                                        {this.state.MaterialSelecionado.id != undefined && <>
                                            <hr />
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'red', textTransform: 'uppercase' }}>{this.state.MaterialSelecionado.nome}</label>
                                                <div className="form-group  row">
                                                    <div className=" col-sm-12">
                                                        {/* se medida principal  em peso */}
                                                        {this.state.MaterialSelecionado.unidade_principal.tipo == 'Peso' &&
                                                            <div className="row">
                                                                <div className="col-md-6 col-12">
                                                                    <label className="col-sm-12 col-form-label">Peso<b style={{ color: 'red' }}>*</b></label>
                                                                    <div className="input-group mb-3">
                                                                        <span className="input-group-text" id="basic-addon3">Disponível: {this.state.EstoqueSelecionado[0].peso_disponivel + ' ' + this.state.EstoqueSelecionado[0].material.unidade_principal.sigla}</span>
                                                                        <input className="form-control " id="peso" placeholder="peso do material" type="text" value={this.state.Peso} onChange={(e) => {
                                                                            let equivalencia = this.state.MaterialSelecionado.equivalencia;
                                                                            this.setState({
                                                                                required: false,
                                                                                validated: false,
                                                                                Volume: equivalencia * maskNumber(e.target.value),
                                                                                Peso: maskNumber(e.target.value),
                                                                                UnidadePeso: this.state.EstoqueSelecionado[0].material.unidade_principal.sigla,
                                                                                UnidadeVolume: this.state.EstoqueSelecionado[0].material.unidade_secundaria.sigla,
                                                                                Peso_kg:maskNumber(e.target.value)*this.state.EstoqueSelecionado[0].material.unidade_principal.equivalencia,
                                                                                Volume_lt:(equivalencia * e.target.value)*this.state.EstoqueSelecionado[0].material.unidade_secundaria.equivalencia

                                                                            })
                                                                        }} />
                                                                    </div>
                                                                    {this.state.validated == true && <b style={{ color: 'red', fontSize: '12px' }}>O peso do material não pode ser maior que o disponível</b>}
                                                                    {this.state.required == true && <b style={{ color: 'red', fontSize: '12px' }}>O campo Peso é obrigatório</b>}
                                                                </div>
                                                                <div className="col-md-6 col-12">
                                                                    <label className="col-sm-12 col-form-label">Volume<b style={{ color: 'red' }}>*</b></label>
                                                                    <div className="input-group mb-3">
                                                                        <span className="input-group-text" id="basic-addon3">Disponível: {this.state.EstoqueSelecionado[0].volume_disponivel + ' ' + this.state.EstoqueSelecionado[0].material.unidade_secundaria.sigla}</span>
                                                                        <input className="form-control" id="volume" disabled placeholder="volume do material" type="text" onChange={(e) => this.setState({ Volume: e.target.value })} value={this.state.Volume} />
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        {/* se medida principar for em volume */}
                                                        {this.state.MaterialSelecionado.unidade_principal.tipo == 'Volume' &&
                                                            <div className="row">
                                                                <div className="col-md-6 col-12">
                                                                    <label className="col-sm-12 col-form-label">Volume<b style={{ color: 'red' }}>*</b></label>
                                                                    <div className="input-group mb-3">
                                                                        <span className="input-group-text" id="basic-addon3">Disponível: {this.state.EstoqueSelecionado[0].volume_disponivel + ' ' + this.state.EstoqueSelecionado[0].material.unidade_principal.sigla}</span>
                                                                        <input className="form-control" id="volume" placeholder="volume do material" type="text" value={this.state.Volume} onChange={(e) => {
                                                                            let equivalencia = this.state.MaterialSelecionado.equivalencia;
                                                                            this.setState({
                                                                                required: false,
                                                                                validated: false,
                                                                                Peso: equivalencia * maskNumber(e.target.value),
                                                                                Volume: maskNumber(e.target.value),
                                                                                UnidadePeso: this.state.EstoqueSelecionado[0].material.unidade_secundaria.sigla,
                                                                                UnidadeVolume: this.state.EstoqueSelecionado[0].material.unidade_principal.sigla,
                                                                                Volume_lt:maskNumber(e.target.value)*this.state.EstoqueSelecionado[0].material.unidade_principal.equivalencia,
                                                                                Peso_kg:(equivalencia * e.target.value)*this.state.EstoqueSelecionado[0].material.unidade_secundaria.equivalencia
                                                                            })
                                                                        }} />
                                                                    </div>
                                                                    {this.state.validated == true && <b style={{ color: 'red', fontSize: '12px' }}>O volume do material não pode ser maior que o disponível</b>}
                                                                    {this.state.required == true && <b style={{ color: 'red', fontSize: '12px' }}>O campo Volume é obrigatório</b>}
                                                                </div>
                                                                <div className="col-md-6 col-12">
                                                                    <label className="col-sm-12 col-form-label">Peso<b style={{ color: 'red' }}>*</b></label>
                                                                    <div className="input-group mb-3">
                                                                        <span className="input-group-text" id="basic-addon3">Disponível: {this.state.EstoqueSelecionado[0].peso_disponivel + ' ' + this.state.EstoqueSelecionado[0].material.unidade_secundaria.sigla}</span>
                                                                        <input className="form-control" id="peso" disabled placeholder="peso do material" type="text" onChange={(e) => this.setState({ Peso: e.target.value })} value={this.state.Peso} />
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                            
                                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                            <button type="button" className="btn btn-success" style={{ backgorundColor: '#f2f2f2' }} onClick={() => {
                                                                if (this.state.MaterialSelecionado.unidade_principal.tipo == 'Peso' && parseFloat(this.state.Peso) > parseFloat(this.state.EstoqueSelecionado[0].peso_disponivel) ||
                                                                this.state.MaterialSelecionado.unidade_principal.tipo == 'Volume' && parseFloat(this.state.Volume) > parseFloat(this.state.EstoqueSelecionado[0].volume_disponivel)) 
                                                                {
                                                                    this.setState({ validated: true });
                                                                } else {
                                                                    if (this.state.Peso == '' || this.state.Volume == '') {
                                                                        this.setState({ required: true });
                                                                    } else {
                                                                        let novo_material_inserido = this.state.MaterialSelecionado;
                                                                        let material_inserido = [...this.state.MateriaisIseridos];
                                                                        material_inserido.push(novo_material_inserido);

                                                                        let novo_material = { 'residuo_rejeito_id': this.state.Material, 'nome': this.state.MaterialSelecionado.nome, 'unidade_peso': this.state.UnidadePeso, 'unidade_volume': this.state.UnidadeVolume, 'peso': this.state.Peso, 'volume': this.state.Volume, 'peso_kg': this.state.Peso_kg, 'volume_lt': this.state.Volume_lt };
                                                                        let material = [...this.state.MateriaisSelecionados];
                                                                        material.push(novo_material);
                                                                        this.setState({
                                                                            Material: '',
                                                                            MaterialSelecionado: '',
                                                                            MateriaisSelecionados: material,
                                                                            MateriaisIseridos: material_inserido
                                                                        });
                                                                        console.log(this.state.MateriaisSelecionados);
                                                                        console.log(this.state.EstoqueSelecionado[0].peso_disponivel);
                                                                    }
                                                                }

                                                            }
                                                            }>Inserir</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        }
                                        {this.state.MateriaisSelecionados.length != 0 && <>
                                            <hr />
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Materiais selecionados:</label>

                                                <table id="tech-companies-1" className="table table-striped table-hover" >
                                                    <thead>
                                                        <tr>
                                                            <th>Material</th>
                                                            <th>Peso</th>
                                                            <th>Volume</th>
                                                            <th data-priority="3">Excluir</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.MateriaisSelecionados.map((item, id) => (
                                                            <tr key={id}>
                                                                <td>{item.nome}</td>
                                                                <td>{maskNumberBr(item.peso+'')} {item.unidade_peso}</td>
                                                                <td>{maskNumberBr(item.volume+'')} {item.unidade_volume}</td>
                                                                <td>
                                                                    <button onClick={() => {
                                                                        let materiais_selecionados = [...this.state.MateriaisSelecionados];
                                                                        materiais_selecionados = materiais_selecionados.filter((item3, id) => item3.residuo_rejeito_id != item.residuo_rejeito_id);
                                                                        let material_inserido = [...this.state.MateriaisIseridos];
                                                                        material_inserido = material_inserido.filter((item2, id2) => item2.id != item.id);
                                                                        this.setState({
                                                                            MateriaisSelecionados: materiais_selecionados,
                                                                            MateriaisIseridos: material_inserido
                                                                        });
                                                                    }}
                                                                        className="btn btn-danger btn-outline btn-circle m-r-5"style={{ background: 'white', }}><FaTrash color='red' /></button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>


                                            </div>
                                            <hr />
                                        </>}
                                        <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Descrição </label>
                                                <div className="col-sm-12">
                                                    <textarea className="form-control" placeholder="Descrição" value={this.state.Descricao} type="text" onChange={(e) => { this.setState({ Descricao: e.target.value }) }} />
                                                </div>
                                            </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" >Responsável <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.Responsavel_id} className="form-control" disabled={this.state.PontoColeta == ''} id="cameras" onChange={(e) => {
                                                    this.setState({ Responsavel_id: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione um responsável</option>
                                                    {this.state.Responsaveis.map((item, id) => (
                                                        <option key={id} value={item.id}>{item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                    </div>

                                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}
                                </div>
                                {this.state.loading_save == false && <div className="row mt-3 justify-content-end justify-content-sm-end">
                                
                                        <div className="col-12 col-sm-6 d-flex justify-content-end justify-content-sm-end  align-items-end"
                                            style={{ marginBottom: '0.5rem' }}>
                                            <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                                style={{ backgorundColor: '#f2f2f2' }}>Salvar solicitação</button>
                                        </div>
                                    </div>}
                                    {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div></div>}
                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}

                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => { this.setState({MaterialSelecionado:{},Material:'' },()=> this.salvar() ) }}
                                onCancel={() => { this.setState({ show_warning: false }); }}
                                show={this.state.show_warning}
                                confirmBtnText='Sim, desejo salvar '
                                cancelBtnText='Cancelar'
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                showCancel={true}
                            >
                                Existe um material selecionado que não foi inserido, deseja salvar mesmo assim ?
                                </SweetAlert>
                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        user: state.AppReducer.user,
    }
);

export default connect(mapsStateToProps, { logout })(SolicitarColetaSimplificada);


