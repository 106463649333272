import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { URL } from '../../../variables'
import EditarDespacho from './EditarDespacho'

const EditData = () => {
  const [data, setData] = React.useState('')
  const params = useParams()
  const token = useSelector(state => state.AppReducer.token)

  React.useEffect(() => {
    console.log('error', params, data)
    getData()
  }, [])

  const getData = () => {
    //If type from initial screen === Destino
    if (params.type === 'externo') {
      fetch(`${URL}api/get_editar_destinacao/${params.id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      }).then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          // console.log(resp);
          if (resp.message === "Unauthenticated.") {
            localStorage.removeItem('token'); localStorage.removeItem('user');
            return;
          }
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = '';
            for (let i = 0; i < errors.length; i++) {
              if (i !== errors.length - 1)
                erro += errors[i] + '\n';
              else
                erro += errors[i];
            }
            console.log(erro);
          }
          else {
            setData(resp)
          }
        } catch (err) {
          console.log(err);
        }
      }).catch((err) => {
        console.log(err);
      });
    }

    //If type from initial screen === Estoque
    else if (params.type === 'interno') {
      fetch(`${URL}api/get_editar_despacho/${params.id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      }).then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          console.log(resp);
          if (resp.message === "Unauthenticated.") {
            localStorage.removeItem('token'); localStorage.removeItem('user');
            return;
          }
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = '';
            for (let i = 0; i < errors.length; i++) {
              if (i !== errors.length - 1)
                erro += errors[i] + '\n';
              else
                erro += errors[i];
            }
            console.log(erro);
          }
          else {
            setData(resp)
          }
        } catch (err) {
          console.log(err);
        }
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  return (
    <>
      {data
        ? <EditarDespacho data={data} type={params.type} params={params} />
        : <div className='row'>
          <div className='d-flex justify-content-center p-5'>
            <div className="spinner-border text-primary" role="status"></div>
          </div>
        </div>
      }
    </>
  )
}

export default EditData