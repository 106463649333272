import React from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { MdClose } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { URL } from '../../../src/variables.js';

const style = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  border: "2px solid transparent",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  "@media(max-width: 1000px)": {
    width: "90%",
    left: "50%",
    transform: "translateX(-50%)",
  },
};

export default function CancelMTRModal({ open, handleClose, mtrId, token }) {
  const [justification, setJustification] = React.useState("");

  const handleCancelMTR = () => {
    fetch(`${URL}api/post_cancelar_mtr`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ mtrId, justification }),
    })
      .then(async (responseLog) => {
 
          let resp = await responseLog.json();
        if (resp.success != null) {
          toast.success('MTR cancelado!');
        }
        if (resp.errors != null) {
          toast.error(resp.errors);
        }

        handleClose();
      })
      .catch(error => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="d-flex justify-content-end">
              <span style={{ cursor: "pointer" }} onClick={handleClose}>
                <MdClose />
              </span>
            </div>
            <h4
              className="header-title"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              Cancelar MTR
            </h4>
            <div className="mt-4">
              <textarea
                className="form-control"
                placeholder="Justificativa"
                value={justification}
                onChange={(e) => setJustification(e.target.value)}
                rows={4}
                style={{ width: "100%" }}
              />
            </div>
            <div className="mt-2 d-flex justify-content-end">
              <button
                className="btn btn-primary btn-lg"
                onClick={handleCancelMTR}
              >
                Enviar Cancelamento
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ToastContainer
        position="bottom-right"
        toastStyle={{ border: "1px solid gray", borderRadius: 4 }}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </>
  );
}
