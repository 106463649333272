import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { BsCheckLg } from 'react-icons/bs';
import { IconButton, Step, StepLabel, Stepper, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import { URL } from '../../../variables';
import { MdClose } from 'react-icons/md';
import EditarEstadoSigor from './EditarEstadoSigor';
import SigorRelacionadas from './SigorRelacionadas';
import Theme from '../../Theme';

const style = {
  position: 'absolute',
  // top: '50%',
  left: '50%',
  transform: 'translate(0, 0, 0, 50%)',
  width: '50%',
  height: '100%',
  bgcolor: 'background.paper',
  border: '2px solid transparent',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  '@media(max-width: 1000px)': {
    width: '90%',
    left: '5%',
  },
};

const ModalEstadoFisico = (props) => {
  const [state, setState] = React.useState({
    redirect: false,
    loading: true,
    descricao: '',
    codigo: '',
    sigla: '',
    msgErro: ''
  })
  const [estados, setEstados] = React.useState({
    loading: true,
    estado_id: '',
    estados: [],
    all_estados: [],
    estados_selecionados: []
  })
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const token = useSelector((state) => state.AppReducer.token);

  React.useEffect(() => {
    if (open === true) {
      fetch(`${URL}api/get_estado_sigor/${props.id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'application/json',
        },
      })
        .then(async (responseLog) => {
          let resp = await responseLog.json();
          setState({ ...state, loading: false, descricao: resp.estado.descricao });
          fill_estados()
        })

      function fill_estados() {
        fetch(`${URL}api/fill_estados_sigor`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }).then(async (response) => {
          const resp = await response.json()
          console.log('resp', resp)
          setEstados({ ...estados, estados: resp.estados, all_estados: resp.estados, estado_id: resp.estados[0].id, loading: false })
        })
      }
    }
  }, [open]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function salvar() {
    const obj_estado = {
      codigo: state.codigo,
      unidade_id: props.id,
      descricao: state.descricao,
      estados: estados.estados_selecionados,
    };
    console.log('obj', obj_estado)
    props.storeEstado(obj_estado);
    setOpen(false);
  }

  function renderPage() {
    if (page === 1) return <EditarEstadoSigor id={props.id} state={state} setState={(e) => setState(e)} />
    if (page === 2) return <SigorRelacionadas state={estados} setState={(e) => setEstados(e)} />
  }
  return (
    <>
      <button
        className="btn btn-primary btn-outline btn-circle me-1 mb-1"
        data-tip="Inserir"
        onClick={handleOpen}
        disabled={props.disabled}
      >
        <BsCheckLg />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ThemeProvider theme={Theme}>
          <Box sx={style}>
            <>
              <IconButton onClick={handleClose}>
                <MdClose />
              </IconButton>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div className="text-center">
                  <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Cadastre estado físico</h4>
                  <p style={{ fontSize: '1rem', color: 'black' }}>Cadastre um estado físico do seu sistema</p>
                </div>
              </Typography>
              <Stepper activeStep={page - 1} alternativeLabel>
                <Step>
                  <StepLabel color='success'>Dados de Estado</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Estados Relacionados</StepLabel>
                </Step>
              </Stepper>

              {!state.loading ? <div>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {renderPage()}
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <div className="d-flex mt-3">
                    <div className="align-self-center">
                      <Button sx={{ color: 'text.main' }} variant="contained" size="large" onClick={() => setPage(page - 1)}
                        disabled={page === 1}>
                        Voltar
                      </Button>
                    </div>
                    <div className="align-self-center ms-auto">
                      {page < 2 && (
                        <Button sx={{ color: 'text.main' }} variant="contained" size="large" onClick={() => setPage(2)}
                          disabled={page === 2}>
                          Próximo
                        </Button>
                      )}
                      {page === 2 && (
                        <Button sx={{ color: 'text.main' }} className="align-self-end" variant="contained" size="large" onClick={() => salvar()}>
                          Salvar
                        </Button>
                      )}
                    </div>
                  </div>
                </Typography>
              </div>
                :
                <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                  <div className="spinner-border text-primary" role="status"></div>
                </div>}
            </>
          </Box>
        </ThemeProvider>
      </Modal>
    </>
  );
};

export default ModalEstadoFisico;
