import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { MdSearch } from 'react-icons/md';
import GerarRelatorio from './Relatorios';
import moment from 'moment';

import { URL } from '../../../variables';
import { logout } from '../../../actions/AppActions';
import SweetAlert from 'react-bootstrap-sweetalert';

class SelecionarSubmateriais extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/relatorio',
            loading_screen: true,
            loading_search: false,
            submateriais: {},
            page: 1,
            total_pages: 1,
            msgErro: '',
            show_erro: false,
            registro_to_delete: '',
            search: '',
            check_submateriais: {},

            loading_save: false,
        };
    }



    componentDidMount() {
        this.get_submateriais(this.state.page)
        this.props.setSearch(false)
    }

    componentDidUpdate() {
        if (this.props.search) {
            this.props.setSearch(false)
            this.get_submateriais(this.state.page);
        }
    }

    get_submateriais(page, search = '', busca = false) {
        if (busca == true) {
            if (this.state.loading_search == false) {
                this.setState({ loading_search: true });
            }
        }
        else if (this.state.loading_screen === false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_submateriais?page=${page}&search=${search}&dateOf=${this.props.dateOf}&dateFor=${this.props.dateFor}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    this.setState({
                        submateriais: resp.submateriais,
                        loading_screen: false,
                        page: resp.pagination.current_page,
                        total_pages: resp.pagination.last_page,
                        loading_search: false
                    });

                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }

        })
            .catch((err) => {
                this.setState({ redirect: true });
                console.log(err);
            });
    }

    //botão 'gerar relatório' - lista no console os itens selecionados 
    gerarRelatorio(item, id) {
        let submateriais_selecionados = Object.keys(this.state.check_submateriais)

        fetch(`${URL}api/gerar_relatorio`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                submateriais_selecionados
            })

        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);
                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {
                    this.setState({ ...this.state, loading_save: true })
                    this.get_submateriais(this.state.page);
                }
            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });

    }

    render() {
        return (
            <>
                {this.state.redirect == true && <Redirect to={this.state.path} />}
                {this.state.loading_screen == false && <div>
                    <div className="row mb-2">
                        <div className="col-12 col-md-6">
                            <form className="app-search mt-1" style={{ width: '100%' }} onSubmit={(e) => {
                                this.setState({ page: 1 });
                                this.get_submateriais(1, this.state.search, true)
                                e.preventDefault();
                            }}>
                                <div className="form-group mb-0" style={{ width: '100%', position: 'relative' }}>
                                    <input value={this.state.search} name="search" placeholder="Pesquisar pelo nome do sub-material..." className="form-control" style={{ width: '100%', paddingRight: 40, borderRadius: 100 }} onChange={(e) => {
                                        clearTimeout(this.pesquisarTimeout);
                                        let search = e.target.value;
                                        this.setState({ page: 1, search: search });
                                        this.pesquisarTimeout = setTimeout(() => {
                                            this.get_submateriais(1, search, true)
                                        }, 500);
                                    }} />

                                    <div style={{ width: 40, position: "absolute", right: 0, top: 0, bottom: 0, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                        <MdSearch />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className='col-12 col-md-6 d-flex justify-content-end'>
                            <Link to={<GerarRelatorio />}>
                                <button
                                    className="btn btn-primary btn-lg waves-effect waves-light mt-1"
                                    onClick={this.gerarRelatorio.bind(this)}
                                >Gerar Relatório</button>
                            </Link>
                        </div>
                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }}>
                                <thead>
                                    <tr>
                                        <th>Selecione</th>
                                        <th>Sub-material</th>
                                        <th>Resíduo/Rejeito</th>
                                        <th>Data da Separação</th>
                                    </tr>
                                </thead>
                                {/* dados da lista ----------------------------------- */}
                                <tbody>
                                    {this.state.loading_search == false && this.state.submateriais.map((item, id) => (
                                        <tr key={id}>
                                            <td><input type="checkbox" checked={this.state.check_submateriais[item.id] == true} onChange={(e) => {
                                                let check_submateriais = { ...this.state.check_submateriais }
                                                check_submateriais[item.id] = e.target.checked
                                                this.setState({ check_submateriais });
                                            }}></input></td>
                                            <td>{item.sub_material.nome}</td>
                                            <td>{item.separacao_residuo_rejeito.nome}</td>
                                            <td>{moment(item.created_at).format('DD/MM/YYYY')}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {this.state.loading_search &&
                                <div className="row" style={{ margin: 0 }}>
                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                        ></div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {this.state.submateriais.length == 0 && !this.state.loading_search && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhum sub-material encontrado...</p>
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                            <ReactPaginate
                                previousLabel={'Anterior'}
                                nextLabel={'Próxima'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.total_pages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={this.handlePageClick.bind(this)}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                                initialPage={this.state.page - 1}
                            />
                        </div>
                        {this.state.loading_save &&
                            <SweetAlert
                                success
                                title={"Sucesso"}
                                onConfirm={() => {
                                    this.setState({ ...this.state, loading_save: false })
                                    { <Redirect to={this.state.path} /> }
                                }}
                                confirmBtnText='OK'
                                confirmBtnBsStyle="success"
                            >
                                Relatório gerado com sucesso!

                            </SweetAlert>}
                    </div>

                </div>}


                {//loading
                    this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                        <div className="spinner-border text-primary" role="status"  >
                        </div>
                    </div></div>}
                {/* {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                    </div>
                </div></div>} */}

            </>

        )
    }
    //avançar e voltar página
    handlePageClick(page) {
        console.log(page.selected);
        if (page.selected + 1 != this.state.page) {
            this.setState({ page: page + 1 });
            this.get_submateriais(page.selected + 1);
        }
    }
}

const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(SelecionarSubmateriais);