import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// residuos_rejeitos
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import { MdDelete, MdInfo, MdModeEdit, MdSearch } from 'react-icons/md';
import { BsXLg, BsCheckLg } from 'react-icons/bs';
import { CircularProgress, IconButton } from '@mui/material';
import Loading from '../Loading/Loading';
import { moneyMask } from '../../../Auxiliar/Masks';
import ReactTooltip from 'react-tooltip';

class ResiduoRejeito extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: '/',
      loading_screen: true,
      residuos_rejeitos: [],
      page: 1,
      total_pages: 1,
      msgErro: '',
      show_warning: false,
      show_erro: false,
      rr_to_delete: '',
      search: '',
      loading_search: false,
      render: true,
      loading_btn: false,
      list_loading: [],
    };
  }

  componentDidMount() {
    this.get_residuos_rejeitos(this.state.page);
    this.props.setSearch(false)
  }

  componentDidUpdate() {
    if (this.props.search) {
      this.props.setSearch(false)
      this.get_residuos_rejeitos(this.state.page);
    }
  }

  get_residuos_rejeitos(page, search = '', busca = false) {
    if (busca == true) {
      if (this.state.loading_search == false) {
        this.setState({ loading_search: true });
      }
    } else if (this.state.loading_screen === false) {
      this.setState({ loading_screen: true });
    }
    fetch(`${URL}api/get_residuos_rejeitos?page=${page}&search=${search}&dateOf=${this.props.dateOf}&dateFor=${this.props.dateFor}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      },
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          console.log(resp);
          if (resp.message == 'Unauthenticated.') {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            this.props.logout();
            return;
          }
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = '';
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + '\n';
              else erro += errors[i];
            }
            console.log(erro);
            this.setState({ redirect: true });
          } else {
            this.setState({
              loading_search: false,
              residuos_rejeitos: resp.residuos_rejeitos,
              loading_screen: false,
              page: resp.pagination.current_page,
              total_pages: resp.pagination.last_page,
            });
          }
        } catch (err) {
          this.setState({ redirect: true });
          console.log(err);
        }
      })
      .catch((err) => {
        this.setState({ redirect: true });

        console.log(err);
        // this.props.mudarLoadingHome(false);
      });
  }

  delete_residuo_rejeito(id) {
    fetch(`${URL}api/delete_residuo_rejeito/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      },
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          console.log(resp);
          if (resp.message == 'Unauthenticated.') {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            // this.setState({redirect:true,path:'/'});
            this.props.logout();
            return;
          }
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = '';
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + '\n';
              else erro += errors[i];
            }
            console.log(erro);
            this.setState({
              msgErro: erro,
              show_warning: false,
              show_erro: true,
            });
          } else {
            this.setState({ show_warning: false });
            this.get_residuos_rejeitos(this.state.page);
          }
        } catch (err) {
          this.setState({ redirect: true });
          console.log(err);
        }
      })
      .catch((err) => {
        this.setState({ redirect: true });

        console.log(err);
      });
  }

  changeStatus(id, status) {
    this.changeLoading(id, true);
    fetch(`${URL}api/update_status_residuos_rejeitos`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.props.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: status, id: id }),
    }).then(async (responseLog) => {
      let resp = await responseLog.json();
      let residuos_rejeitos = this.state.residuos_rejeitos.map(item => {
        if (item.id == id) item.inativo = !item.inativo
        return item
      })
      this.setState({ residuos_rejeitos })
      this.changeLoading(id, false);
    });
  }

  changeLoading(id, bool) {
    let list_loading = { ...this.state.list_loading }
    list_loading[id] = bool
    this.setState({ list_loading })
  }

  renderRelacionados(item) {
    if (item.residuos_sigor.length === 1) {
      return (
        <div>
          {item.residuos_sigor[0].dados_residuo.nome}
        </div>
      )
    } else {
      return (
        <div className='relacionados'>
          {item.residuos_sigor[0].dados_residuo.nome}...
          <div className="hidden">
            <table className="table col-12" style={{ color: 'white' }}>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Tipo</th>
                </tr>
              </thead>
              <tbody>
                {item.residuos_sigor.map(item2 => (
                  <tr key={item2.id}>
                    <td>{item2.dados_residuo.nome}</td>
                    <td>{item2.dados_residuo.tipo}</td>
                  </tr>
                )
                )}
              </tbody>
            </table>
          </div>
        </div>
      )
    }
  }


  render() {
    return (
      <div className="row">
        {this.state.redirect == true && <Redirect to={this.state.path} />}
        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
              {this.state.loading_screen == false && (
                <div>
                  <div className="row mb-2">
                    <div className="col-12 col-md-6">
                      <form
                        className="app-search mt-1"
                        style={{ width: '100%' }}
                        onSubmit={(e) => {
                          clearTimeout(this.pesquisarTimeout);
                          this.setState({ page: 1 });
                          this.get_residuos_rejeitos(1, this.state.search, true);
                          e.preventDefault();
                        }}
                      >
                        <div className="form-group mb-0" style={{ width: '100%', position: 'relative' }}>
                          <input
                            value={this.state.search}
                            name="search"
                            placeholder="Pesquisar pelo Nome ou Tipo..."
                            className="form-control"
                            style={{ width: '100%', paddingRight: 40, borderRadius: 100 }}
                            onChange={(e) => {
                              clearTimeout(this.pesquisarTimeout);
                              let search = e.target.value;
                              this.setState({ page: 1, search: search });
                              this.pesquisarTimeout = setTimeout(() => {
                                this.get_residuos_rejeitos(1, search, true);
                              }, 500);
                            }}
                          />
                          <div style={{
                            width: 40,
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            bottom: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                            <MdSearch />
                          </div>
                        </div>
                      </form>
                    </div>
                    {this.props.user.tipo_usuario.permissoes['residuos_rejeitos'].escrita == true &&
                      <div className="col-12 col-md-6 d-flex justify-content-end">
                        <Link to="/residuos_rejeitos/criar">
                          <span className="btn btn-primary btn-lg waves-effect waves-light mt-1">
                            Adicionar resíduo/rejeito
                          </span>
                        </Link>
                      </div>
                    }
                  </div>
                  <div className="table-rep-plugin">
                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                      <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }}>
                        <thead>
                          <tr>
                            <th>Nome</th>
                            <th>Medida Principal</th>
                            <th>Situação</th>
                            <th>Saldo Financeiro Estimado</th>
                            <th data-priority="1">Relacionadas</th>
                            <th data-priority="3">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.loading_search == false && (this.state.render == true || this.state.render == false) &&
                            this.state.residuos_rejeitos.map((item, id) => {
                              return (
                                <tr key={id}>
                                  <td>{item.nome}</td>
                                  <td>{item.unidade_principal.sigla}</td>
                                  <td>{item.inativo ? 'Inativo' : 'Ativo'}</td>
                                  <td>
                                    <span className='pointer' data-tip={item.saldo_financeiro}>
                                      <ReactTooltip />
                                      {moneyMask(parseFloat(item.valor_saldo_financeiro).toFixed(2))}
                                    </span>
                                  </td>
                                  <td>{item.residuos_sigor.length > 0 ? this.renderRelacionados(item) : 'N/A'}</td>
                                  <td>
                                    {this.props.user.tipo_usuario.permissoes['residuos_rejeitos'].escrita == true &&
                                      <Link to={'/residuos_rejeitos/editar/' + item.id}>
                                        <span className="btn btn-info btn-outline btn-circle me-1 mb-1" data-tip="Editar">
                                          <ReactTooltip />
                                          <MdModeEdit />
                                        </span>
                                      </Link>
                                    }
                                    {this.props.user.tipo_usuario.permissoes['residuos_rejeitos'].exclusao == true &&
                                      <button onClick={() => {
                                        this.setState({ show_warning: true, rr_to_delete: item.id });
                                      }} className="destroy_it btn btn-danger btn-outline btn-circle me-1 mb-1" data-tip="Excluir">
                                        <ReactTooltip />
                                        <MdDelete />
                                      </button>
                                    }
                                    {this.props.user.tipo_usuario.permissoes['residuos_rejeitos'].exclusao == true && !item.inativo ? (
                                      <button className="btn btn-secondary btn-outline btn-circle me-1 mb-1" data-tip="Inativar" onClick={() => this.changeStatus(item.id, true)}>
                                        {this.state.list_loading[item.id] ?
                                          <CircularProgress color="inherit" size={16} />
                                          : <BsXLg />
                                        }
                                      </button>
                                    ) : (
                                      <button className="btn btn-secondary btn-outline btn-circle me-1 mb-1" data-tip="Ativar" onClick={() => this.changeStatus(item.id, false)}>
                                        {this.state.list_loading[item.id] ?
                                          <CircularProgress color="inherit" size={16} />
                                          : <BsCheckLg />
                                        }
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </tbody>
                      </table>
                      {this.state.loading_search == true && (
                        <div className="row" style={{ margin: 0 }}>
                          <div className="col-12 d-flex justify-content-center align-items-center">
                            <div className="spinner-border text-primary" role="status"></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {this.state.loading_search == false && this.state.residuos_rejeitos.length == 0 && (
                    <div className="row w-100">
                      <div className="col-12">
                        <p style={{ textAlign: 'center' }}>Nenhum resíduo/rejeito cadastrado</p>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                      <ReactPaginate
                        previousLabel={'Anterior'}
                        nextLabel={'Próxima'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.total_pages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick.bind(this)}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        initialPage={this.state.page - 1}
                      />
                    </div>
                  </div>
                </div>
              )}
              {this.state.loading_screen == true && (
                <div className="row">
                  <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"></div>
                  </div>
                </div>
              )}
              <SweetAlert
                warning
                title={'Atenção'}
                onConfirm={() => {
                  this.delete_residuo_rejeito(this.state.rr_to_delete);
                }}
                onCancel={() => {
                  this.setState({ show_warning: false });
                }}
                show={this.state.show_warning}
                confirmBtnText="Sim, desejo apagar"
                cancelBtnText="Cancelar"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                showCancel={true}
              >
                Ao deletar este resíduo/rejeito ele não estará mais disponível no sistema
              </SweetAlert>
              <SweetAlert
                danger
                title={'Erro'}
                onConfirm={() => {
                  this.setState({ show_erro: false });
                }}
                show={this.state.show_erro}
                confirmBtnText="OK"
                confirmBtnBsStyle="primary"
              >
                {this.state.msgErro}
              </SweetAlert>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handlePageClick(page) {
    if (page.selected + 1 != this.state.page) {
      this.setState({ page: page + 1 });
      this.get_residuos_rejeitos(page.selected + 1);
    }
  }
}
const mapsStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(ResiduoRejeito);