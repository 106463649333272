import React from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';

const SigorRelacionadas = (props) => {

  function add_residuo(id) {
    if (!props.state.residuos_selecionados.filter(item => item.id === id)[0]) {
      const residuos = props.state.residuos.filter(item => item.id === id)[0]
      const filtred = props.state.residuos.filter(item => item.id !== id)

      const obj_residuo = {
        id: residuos.id,
        descricao: residuos.nome,
        tipo: residuos.tipo,
      }

      props.setState({ ...props.state, residuos_selecionados: [...props.state.residuos_selecionados, obj_residuo], residuos: filtred, residuo_id: filtred[0].id })
    }
  }

  function remove_residuo(id) {
    const devolution = props.state.all_residuos.filter(item => item.id === id)[0]
    let residuos = [devolution, ...props.state.residuos]
    const residuos_selecionados = props.state.residuos_selecionados.filter(item => item.id !== id)
    props.setState({ ...props.state, residuos_selecionados, residuos, residuo_id: residuos[0].id })
  }

  return (
    <div>
      {!props.state.loading ? <div className="form-group row">
        <label className="col-sm-12 col-form-label">Residuos/Rejeitos Relacionais</label>
        <div className="input-group col-sm-12">
          <select value={props.state.residuo_id} className="form-control" id="cameras" onChange={(e) => props.setState({ ...props.state, residuo_id: e.target.value })}>
            {props.state.residuos.map(item => (
              <option value={item.id}>{item.nome}</option>
            ))}
          </select>
          <button className='btn btn-primary' onClick={() => add_residuo(props.state.residuo_id)}>
            <BsPlusLg />
          </button>
        </div>
      </div>
        :
        <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
          <div className="spinner-border text-primary" role="status"></div>
        </div>}

      {props.state.residuos_selecionados.length > 0 &&
        <div className="row my-3">
          <div className="col-12">
            <table className='table table-striped table-hover text-center'>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Tipo</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {props.state.residuos_selecionados.map(item2 => (
                  <tr key={item2.id}>
                    <td>{item2.descricao}</td>
                    <td>{item2.tipo}</td>
                    <td><button className="btn btn-danger btn-small btn-circle m-r-5"
                      onClick={() => remove_residuo(item2.id)}>
                      <FaTrash color='white' />
                    </button>
                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </div>}
    </div>
  )
}

export default SigorRelacionadas