import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { logout } from "../../../actions/AppActions";
import { connect } from "react-redux";

// users
import { URL } from "../../../variables";
import { maskMoneyV2, maskNumberBr } from "../../../Auxiliar/Masks";

import { MdReply } from "react-icons/md";
import { Tooltip } from "@mui/material";
import { BsInfoCircleFill } from "react-icons/bs";

class ViewDestinacaoInternaPendenciaFiscal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: "/destinacao_interna",
      loading_screen: false,
      MateriaisInseridos: [],
      EstoqueNome: "",
      TransportadoraNome: "",
      VeiculoNome: "",
      MotoristaNome: "",
      DestinoNome: "",
      Tratamento: "",
      UnidadePeso: "",
      UnidadeVolume: "",
      Peso_kg: "",
      Volume_lt: "",
      Movimentacao: "",
      ValorFinanceiro: "",
      DestinacaoId: "",
      Descricao: "",
      ChaveNota: "",
      NumeroNota: "",
      SerieNota: "",
      PesoTotal: "0",
      VolumeTotal: "0",
      ReceitaTotal: "0.00",
      DespesaTotal: "0.00",
      Saldo: "0.00",
      Situacao: "Rascunho",
      DataAgendamento: "",
      DataColeta: "",
      DataRecebimento: "",
      DataFinalizado: "",
      DataAtual: "",
      DataInicio: "",
      DataCriacao: "",
      Peso: "",
      Volume: "",
      loading_save: false,
      msgErro: "",
      show_warning: false,
    };
  }

  get_data(id) {
    if (this.state.loading_screen == false) {
      this.setState({ loading_screen: true });
    }
    fetch(`${URL}api/get_editar_destinacao_interna/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      },
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          console.log(resp);
          if (resp.message == "Unauthenticated.") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            // this.setState({redirect:true,path:'/'});
            this.props.logout();
            return;
          }
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            console.log(erro);
            this.setState({ redirect: true });
          } else {
            this.setState({
              MateriaisInseridos: resp.destinacao.materiais,
              loading_screen: false,
              DestinacaoAtual: resp.destinacao,
              Situacao: resp.destinacao.situacao,
              Descricao:
                resp.destinacao.descricao != null
                  ? resp.destinacao.descricao
                  : "-",
              DataAgendamento:
                resp.destinacao.data_agendamento != null
                  ? resp.destinacao.data_agendamento
                  : "Data não informada",
              DataInicio:
                resp.destinacao.data_inicio != null
                  ? resp.destinacao.data_inicio
                  : "Data não informada",
              DataColeta:
                resp.destinacao.data_coleta != null
                  ? resp.destinacao.data_coleta
                  : "Data não informada",
              DataRecebimento:
                resp.destinacao.data_recebimento != null
                  ? resp.destinacao.data_recebimento
                  : "Data não informada",
              DataFinalizado:
                resp.destinacao.data_finalizado != null
                  ? resp.destinacao.data_finalizado
                  : "Data não informada",
              PesoTotal: resp.destinacao.peso_total,
              VolumeTotal: resp.destinacao.volume_total,
              ReceitaTotal: resp.destinacao.receita_total,
              DespesaTotal: resp.destinacao.despesa_total,
              Saldo: resp.destinacao.saldo,
              ChaveNota:
                resp.destinacao.chave_nota != null
                  ? resp.destinacao.chave_nota
                  : "Não informada",
              NumeroNota:
                resp.destinacao.numero_nota != null
                  ? resp.destinacao.numero_nota
                  : "Não informado",
              SerieNota:
                resp.destinacao.serie_nota != null
                  ? resp.destinacao.serie_nota
                  : "Não informado",
              DataCriacao: resp.destinacao.data_criacao,
              DestinoNome: resp.destinacao.destino.fantasia,
              // TransportadoraNome: resp.destinacao.transportadora.nome_fantasia,
              TransportadoraNome:
                resp.destinacao.transportadora != null
                  ? resp.destinacao.transportadora.nome_fantasia
                  : resp.destinacao.transportadora == null &&
                    resp.destinacao.veiculo?.modelo?.nome == null
                    ? "-"
                    : "Carro próprio",

              VeiculoNome: resp.destinacao.veiculo?.modelo?.nome ?? "-",
              MotoristaNome: resp.destinacao?.motorista?.nome ?? "-",
              EstoqueNome: resp.destinacao?.estoque?.nome_fantasia ?? "-",
            });
          }
        } catch (err) {
          this.setState({ redirect: true });
          console.log(err);
        }
      })
      .catch((err) => {
        this.setState({ redirect: true });

        console.log(err);
      });
  }

  componentDidMount() {
    let data = new Date();
    let dia = String(data.getDate()).padStart(2, "0");
    let mes = String(data.getMonth() + 1).padStart(2, "0");
    let ano = data.getFullYear();
    this.setState({
      DataAtual: ano + "-" + mes + "-" + dia,
      DataSolicitacao: ano + "-" + mes + "-" + dia,
    });
    this.get_data(this.props.match.params.id);
  }

  render() {
    return (
      <div className="row">
        {this.state.redirect == true && <Redirect to={this.state.path} />}

        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
              <h4
                className="mt-0 header-title"
                style={{ fontSize: "1.5rem", color: "black" }}
              >
                Destinação
              </h4>
              <p style={{ fontSize: "1rem", color: "black" }}>
                Destinação em andamento{" "}
                <Tooltip title="Os dados podem ser alterados, pois a destinação ainda está em andamento">
                  <a
                    className="text-secondary"
                    style={{ padding: 0, margin: 0 }}
                  >
                    <BsInfoCircleFill />
                  </a>
                </Tooltip>
              </p>
              <br />

              {this.state.loading_screen == false && (
                <div>
                  {this.state.msgErro != "" && (
                    <div>
                      <div
                        className="alert alert-danger alert-dismissible fade show mb-0"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => this.setState({ msgErro: "" })}
                        ></button>
                        <p style={{ marginBottom: 0, whiteSpace: "pre" }}>
                          {this.state.msgErro}
                        </p>
                      </div>
                      <br />
                    </div>
                  )}
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          Destino:{" "}
                          <span style={{ fontWeight: "normal" }}>
                            {this.state.DestinoNome}
                          </span>
                        </label>
                      </div>
                      <hr />
                      <label
                        className="col-sm-12 col-form-label"
                        style={{ color: "black", fontSize: "1.5rem" }}
                      >
                        Dados do estoque:
                      </label>
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          Estoque:{" "}
                          <span style={{ fontWeight: "normal" }}>
                            {this.state.EstoqueNome}
                          </span>
                        </label>
                      </div>
                      {this.state.MateriaisInseridos.length > 0 && (
                        <div className="form-group row">
                          <label
                            className="col-sm-12 col-form-label"
                            style={{ color: "black" }}
                          >
                            Materiais selecionados:
                          </label>

                          <table
                            id="tech-companies-1"
                            className="table table-striped table-hover"
                          >
                            <thead>
                              <tr>
                                <th>Material</th>
                                <th>Peso</th>
                                <th>Volume</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.MateriaisInseridos.map((item) => (
                                <tr key={item.residuo_rejeito_id}>
                                  <td>{item.nome}</td>
                                  <td>
                                    {maskNumberBr(item.peso + "") +
                                      " " +
                                      (item.residuo_rejeito.unidade_principal
                                        .tipo === 'Peso'
                                        ? item.residuo_rejeito.unidade_principal
                                          .sigla
                                        : item.residuo_rejeito
                                          .unidade_secundaria.sigla)}
                                  </td>
                                  <td>
                                    {maskNumberBr(item.volume + "") +
                                      " " +
                                      (item.residuo_rejeito.unidade_principal
                                        .tipo !== 'Peso'
                                        ? item.residuo_rejeito.unidade_principal
                                          .sigla
                                        : item.residuo_rejeito
                                          .unidade_secundaria.sigla)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}

                      <div id="despacho" className="col-12">
                        <h4>
                          <strong>Dados do Despacho</strong>
                        </h4>
                        <div id="situation" className="mt-4">
                          <div className="form-group row">
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Status:
                              </label>
                              {this.state.Situacao}
                            </div>
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Data da criação:
                              </label>
                              {this.state.DataCriacao.replace(
                                /(\d*)-(\d*)-(\d*).*/,
                                "$3/$2/$1"
                              )}
                            </div>
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Data de agendamento:
                              </label>
                              {this.state.DataAgendamento.replace(
                                /(\d*)-(\d*)-(\d*).*/,
                                "$3/$2/$1"
                              )}
                            </div>
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Data de coleta:
                              </label>
                              {this.state.DataColeta.replace(
                                /(\d*)-(\d*)-(\d*).*/,
                                "$3/$2/$1"
                              )}
                            </div>
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Data de recebimento:
                              </label>
                              {this.state.DataRecebimento.replace(
                                /(\d*)-(\d*)-(\d*).*/,
                                "$3/$2/$1"
                              )}
                            </div>
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Data de finalização:
                              </label>
                              {this.state.DataFinalizado.replace(
                                /(\d*)-(\d*)-(\d*).*/,
                                "$3/$2/$1"
                              )}
                            </div>
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Peso total:
                              </label>
                              {maskNumberBr(this.state.PesoTotal)} Kg
                            </div>
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Volume total:
                              </label>
                              {maskNumberBr(this.state.VolumeTotal)} L
                            </div>
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Receita:
                              </label>
                              {maskMoneyV2(this.state.ReceitaTotal)}
                            </div>
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Despesa:
                              </label>
                              {maskMoneyV2(this.state.DespesaTotal)}
                            </div>
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Saldo:
                              </label>
                              {maskMoneyV2(this.state.Saldo)}
                            </div>
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Transportadora:
                              </label>
                              {this.state.TransportadoraNome}
                            </div>
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Veículo:
                              </label>
                              {this.state.VeiculoNome}
                            </div>
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Motorista:
                              </label>
                              {this.state.MotoristaNome}
                            </div>
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Descrição:
                              </label>
                              {this.state.Descricao}
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div id="fiscal">
                          <h4>
                            <strong>Dados Fiscais</strong>
                          </h4>
                          <div className="form-group row">
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Chave da NF-e:
                              </label>
                              {this.state.ChaveNota}
                            </div>
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Número da NF-e:
                              </label>
                              {this.state.NumeroNota}
                            </div>
                            <div className="col-12">
                              <label className="col-4 col-form-label fw-bold">
                                Série da NF-e:
                              </label>
                              {this.state.SerieNota}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.loading_save == false && (
                    <div className="row mt-3">
                      <div
                        className="col-12 col-sm-12 d-flex justify-content-center justify-content-sm-start"
                        style={{ marginBottom: "0.5rem" }}
                      >
                        <Link to="/destinacao_interna">
                          {" "}
                          <span className="btn btn-secondary btn-lg waves-effect waves-light">
                            <MdReply /> Voltar
                          </span>
                        </Link>
                      </div>
                    </div>
                  )}
                  {this.state.loading_save == true && (
                    <div className="row">
                      {" "}
                      <div
                        className="col-12 d-flex justify-content-center align-items-center"
                        style={{ marginTop: "2rem" }}
                      >
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {this.state.loading_screen == true && (
                <div className="row">
                  {" "}
                  <div
                    className="col-12 d-flex justify-content-center align-items-center"
                    style={{ marginTop: "2rem" }}
                  >
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    ></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapsStateToProps = (state) => ({
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(
  ViewDestinacaoInternaPendenciaFiscal
);
