import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { BsCheckLg } from 'react-icons/bs';
import { IconButton, Step, StepLabel, Stepper, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import { URL } from '../../../variables';
import { MdClose, MdWindow } from 'react-icons/md';
import EditarRRSigor from './EditarRRSigor';
import SigorRelacionadas from './SigorRelacionadas';
import Theme from '../../Theme';
import VerifySigor from '../../SigorRequests/VerifySigor';

const style = {
  position: 'absolute',
  // top: '50%',
  left: '50%',
  transform: 'translate(0, 0, 0, 50%)',
  width: '50%',
  height: '100%',
  bgcolor: 'background.paper',
  border: '2px solid transparent',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  '@media(max-width: 1000px)': {
    width: '90%',
    left: '5%',
  },
};

const ModalRR = (props) => {
  const [state, setState] = React.useState({
    redirect: false,
    path: '/residuos_rejeitos',
    loading: true,
    classes: [],
    periculosidades: [],
    estados: [],
    acondicionamentos: [],
    acondicionamentosSigor: [],
    destinacoes: [],
    armazenamentos: [],
    tratamentos: [],
    unidades_peso: [],
    unidades_volume: [],
    sub_materiais: [],

    submaterial: '',
    classe: '',
    sigor: '',
    periculosidade: '',
    estado: '',
    acondicionamento: '',
    destinacao: '',
    armazenamento: '',
    tratamento: '',
    unidade_peso: '',
    unidade_volume: '',
    tipo_medida_principal: '',
    tipo_movimentacao_financeira: '',
    valor_saldo: '',
    autorizacao: '',
    equivalencia: '',
    medida_principal: '',
    medida_secundaria: '',
    descricao: '',
    nome: '',
    sigla: '',
    tipo: '',
    codigoIbama: '',
    loading_save: false,
    msgErro: '',
    errors: [],
    marDensidade: '',
    marNumeroOnu: '',
    marClasseRisco: '',
    marNomeEmbarque: '',
    greCodigo: '',
    danger: false,
  })
  const [residuos, setResiduos] = React.useState({
    loading: true,
    residuo_id: '',
    residuos: [],
    all_residuos: [],
    residuos_selecionados: [],
  })
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const token = useSelector((state) => state.AppReducer.token);

  React.useEffect(() => {
    if (open === true) {
      fetch(`${URL}api/get_data_residuos_rejeitos_sigor/${props.id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
          // 'Content-Type': 'application/json',
        }
      }).then(async (responseLog) => {
        let resp = await responseLog.json();
        let danger = false
        let codigo = Array.from(resp.residuo.codigo_ibama)

        // codigo.forEach(item => {
        //   if (item === '*') {
        //     danger = true
        //   }
        // })

        setState({
          ...state,
          nome: resp.residuo.descricao,
          // sigor: resp.sigor,
          classes: resp.classes,
          periculosidades: resp.periculosidades,
          estados: resp.estados,
          acondicionamentos: resp.acondicionamentos,
          destinacoes: resp.destinacoes,
          armazenamentos: resp.armazenamentos,
          tratamentos: resp.tratamentos,
          unidades_peso: resp.unidades_peso,
          unidades_volume: resp.unidades_volume,
          codigoIbama: resp.residuo.codigo_ibama,
          loading: false,
          danger
        });
        fill_residuos()
      })

      function fill_residuos() {
        fetch(`${URL}api/fill_residuos_sigor`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }).then(async (response) => {
          const resp = await response.json()
          console.log('residuos', resp)
          setResiduos({ ...residuos, residuos: resp.residuos, all_residuos: resp.residuos, residuo_id: resp.residuos[0].id, loading: false })
        })
      }
    }
  }, [open]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function renderPage() {
    if (page === 1) return <EditarRRSigor id={props.id} state={state} setState={(e) => setState(e)} />
    if (page === 2) return <SigorRelacionadas id={props.id} state={residuos} setState={(e) => setResiduos(e)} />
  }

  function handleSave() {
    let save = VerifySigor({ validation: 'danger', state: state })
    console.log('save', save)

    if (save.status) {
      let sub_materias = state.sub_materiais.filter((item) => item.nome != null);
      const objRR = {
        residuos_id: props.id,
        submateriais: sub_materias,
        tipo: state.tipo,
        classe_id: state.classe,
        periculosidade_id: state.periculosidade,
        estado_id: state.estado,
        acondicionamento_id: state.acondicionamento,
        destinacao_id: state.destinacao,
        armazenamento_id: state.armazenamento,
        nome: state.nome,
        tratamento_id: state.tratamento,
        unidade_principal_id: state.medida_principal,
        unidade_secundaria_id: state.medida_secundaria,
        equivalencia: state.equivalencia,
        autorizacao_movimentacao: state.autorizacao === 'Sim' ? true : (state.autorizacao === 'Não' ? false : ''),
        saldo_financeiro: state.tipo_movimentacao_financeira,
        valor_saldo_financeiro: state.valor_saldo.replace('R$ ', '').replace(/\./g, '').replace(',', '.'),
        residuos: residuos.residuos_selecionados,
        danger: state.danger,
        mar_numero_onu: state.marNumeroOnu,
        mar_classe_risco: state.marClasseRisco,
        mar_nome_embarque: state.marNomeEmbarque,
        gre_codigo: state.greCodigo
      }
      props.storeResiduo(objRR)
      setOpen(false)
    } else {
      document.querySelector('.ancor').scrollTo(500, 0);
      setState({ ...state, errors: save.message })
    }
  }

  return (
    <>
      <button
        className="btn btn-primary btn-outline btn-circle me-1 mb-1"
        data-tip="Inserir"
        onClick={handleOpen}
        disabled={props.disabled}
      >
        <BsCheckLg />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ThemeProvider theme={Theme}>
          <Box sx={style} className='ancor'>
            <>
              <IconButton onClick={handleClose}>
                <MdClose />
              </IconButton>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div className="text-center">
                  <h4
                    className="mt-0 header-title"
                    style={{ fontSize: '1.5rem', color: 'black' }}
                  >
                    Cadastrar resíduo/rejeito
                  </h4>
                  <p style={{ fontSize: '1rem', color: 'black' }}>
                    Cadastre os resíduos/rejeitos do seu sistema
                  </p>
                  <br />
                </div>
              </Typography>

              <Stepper activeStep={page - 1} alternativeLabel>
                <Step>
                  <StepLabel color='success'>Dados do Resíduo/Rejeito</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Resíduos/Rejeitos Relacionados</StepLabel>
                </Step>
              </Stepper>


              {!state.loading ?
                <div>
                  <div>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {state.errors.length !== 0 && <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setState({...state, errors: [] })}></button>
                        {state.errors.map(item => (<p style={{ marginBottom: 5 }}>{item}</p>))}
                      </div>}
                      {renderPage()}
                    </Typography>
                  </div>

                  <div className="d-flex mt-3">
                    <div className="align-self-center">
                      <Button sx={{ color: 'text.main' }} variant="contained" size="large" onClick={() => setPage(page - 1)}
                        disabled={page === 1}>
                        Voltar
                      </Button>
                    </div>
                    <div className="align-self-end ms-auto">
                      {page < 2 && (
                        <Button sx={{ color: 'text.main' }} variant="contained" size="large" onClick={() => setPage(2)}
                          disabled={page === 2}>
                          Próximo
                        </Button>
                      )}
                      {page === 2 && (
                        <Button sx={{ color: 'text.main' }} className="align-self-end" variant="contained" size="large" onClick={() => handleSave()}>
                          Salvar
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                :
                <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                  <div className="spinner-border text-primary" role="status"></div>
                </div>}

            </>
          </Box>
        </ThemeProvider>
      </Modal>
    </>
  );
};

export default ModalRR;
