import React from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';

const SigorRelacionadas = (props) => {

  function add_unidade(id) {
    if (!props.state.unidades_selecionadas.filter(item => item.id === id)[0]) {
      const unidade = props.state.unidades.filter(item => item.id === id)[0]
      const unidades = props.state.unidades.filter(item => item.id !== id)

      const obj_unidade = {
        id: unidade.id,
        nome: unidade.nome,
        sigla: unidade.sigla
      }

      props.setState({ ...props.state, unidades_selecionadas: [...props.state.unidades_selecionadas, obj_unidade], unidades, unidade_id: unidades[0].id })
    }
  }

  function remove_unidade(id) {
    const unidades_selecionadas = props.state.unidades_selecionadas.filter(item => item.id !== id)
    const devolution = props.state.all_unidades.filter(item => item.id === id)[0]
    let unidades = [devolution, ...props.state.unidades]

    props.setState({ ...props.state, unidades_selecionadas, unidades, unidade_id: unidades[0].id })
  }

  return (
    <div>
      {!props.state.loading ? <div className="form-group row">
        <label className="col-sm-12 col-form-label">Unidades Relacionais</label>
        <div className="input-group col-sm-12">
          <select value={props.state.unidade_id} className="form-control" id="cameras" onChange={(e) => props.setState({ ...props.state, unidade_id: e.target.value })}>
            {props.state.unidades.map(item => (
              <>{!item.relation && <option value={item.id}>{item.nome}</option>}</>
            ))}
          </select>
          <button className='btn btn-primary' onClick={() => add_unidade(props.state.unidade_id)}>
            <BsPlusLg />
          </button>
        </div>
      </div>
        :
        <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
          <div className="spinner-border text-primary" role="status"></div>
        </div>}

      {props.state.unidades_selecionadas.length > 0 &&
        <div className="row my-3">
          <div className="col-12">
            <table className='table table-striped table-hover text-center'>
              <thead>
                <th>Nome</th>
                <th>Sigla</th>
                <th>Ações</th>
              </thead>
              <tbody>
                {props.state.unidades_selecionadas.map(item2 => (
                  <tr key={item2.id}>
                    <td>{item2.nome}</td>
                    <td>{item2.sigla}</td>
                    <td><button className="btn btn-danger btn-small btn-circle m-r-5"
                      onClick={() => remove_unidade(item2.id)}>
                      <FaTrash color='white' />
                    </button>
                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>
        </div>}
    </div>
  )
}

export default SigorRelacionadas