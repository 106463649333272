import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FaTrash } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { Redirect } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { logout } from '../../../actions/AppActions';
import { maskMoneyV2 } from '../../../Auxiliar/Masks';
import PresetButtons from '../../../Auxiliar/PresetButtons';
import { GET, PATCH, POST } from '../../../Auxiliar/Requests';
import { MdClose, MdInfo, MdPlaylistAdd } from 'react-icons/md';
import { months, years, timeUnits } from '../../../Auxiliar/options';
import { IconButton } from '@mui/material';

class CriarCompliance extends Component {
  constructor(props) {
    super(props);
    const { owner } = this.props.match.params;
    console.log('props test', this.props)
    let objectState = {
      owner: owner,
      redirect: false,
      loadingScreen: true,
      monthlyNotification: true,
      notificationError: false,
      name: '',
      ownerId: '',
      documentType: '',
      responsible: '',
      contentType: '',
      content: '',
      expirationDate: '',
      timeUnit: 'day',
      notification: '',
      file: '',
      contentList: [],
      selectOptions: [],
      notificationList: [],
      monthlyStartDate: 1,
      annualStartDate: new Date().getFullYear(),
      documentTypeOptions: ['Licença', 'Autorização', 'Certificado', 'Transporte', 'Contrato', 'Requisito Legal', 'Outros'],
      contentTypeOptions: ['Quantidade', 'Volume', 'Saldo Financeiro'],
      inputErrors: {
        content: false,
        expirationDate: false,
        notification: false,
        monthlyStartDate: false,
        annualStartDate: false,
      }
    };

    // Distributing edit data props in state
    if (this.props.editData) {
      objectState.selectOptions = this.props.selectOptions
      objectState.name = this.props.editData.name
      objectState.documentType = this.props.editData.type
      objectState.responsible = this.props.editData.responsible
      objectState.contentList = this.props.editData.contents
      // Get owner according to route
      objectState.ownerId = this.props.editData[this.getOwner(owner).id]
      objectState.loadingScreen = false
    }

    this.state = objectState
  }

  componentDidMount() {
    if (!this.props.editData) {
      this.getData()
    }
  }

  // Getting data
  getData = async () => {
    const response = await GET({ url: `get_data/${this.state.owner}` })
    if (response.error) {
      this.setState({ redirect: true })
      return
    }

    this.setState({ selectOptions: response.data, loadingScreen: false })
    console.log('response', response)
  }

  // --------------------Function to change input values
  handleChange = ({ target }) => {
    const { name, value, files } = target

    let clearInputErrors = { ...this.state.inputErrors }
    clearInputErrors[name] = false
    // Saving string for normal inputs and file for file type input
    this.setState({ [name]: files ? files[0] : value, inputErrors: clearInputErrors })
  }

  handleNotificationChange = ({ target }) => {
    const { value } = target

    // Controlling notification in case of percentage of total
    if ((value >= 0 && value <= 100) || this.state.contentType === 'Validade') {
      this.setState({ notification: value, inputErrors: { ...this.state.inputErrors, notification: false } })
    } else {
      this.setState({ notificationError: true })
    }
  }



  handleAddContent = (e) => {
    e.preventDefault()

    let { contentType, content, expirationDate, notification, monthlyNotification, monthlyStartDate, annualStartDate, inputErrors, notificationList } = this.state
    let newErrors = { ...inputErrors }
    let contentLabel = content
    let notificationLabel = ''
    let dateLabel = ''
    let unity = ''

    const dataValidation = { content, expirationDate, notification, monthlyStartDate, annualStartDate }
    Object.entries(dataValidation).forEach(([key, value]) => {
      if (contentType === 'Validade') {
        if (!value && key !== 'content' && key !== 'notification') newErrors[key] = true
        if (!value && key === 'notification' && notificationList.length === 0) newErrors[key] = true
        return
      }

      if (monthlyNotification) {
        if (!value && key !== 'expirationDate' && key !== 'annualStartDate') newErrors[key] = true
      } else {
        if (!value && key !== 'expirationDate' && key !== 'monthlyStartDate') newErrors[key] = true
      }
    })

    if (Object.values(newErrors).some(item => item === true)) {
      this.setState({ inputErrors: newErrors })
      return
    }

    if (contentType === 'Validade') {
      const [year, month, day] = expirationDate.split("-");
      dateLabel = `Válido até ${day}/${month}/${year}`
    }

    if (contentType === 'Quantidade') unity = ' KG'
    if (contentType === 'Volume') unity = ' LT'
    if (contentType === 'Validade') {
      // If the accumulator is still empty, we don't add a comma before the label, otherwise we add a comma before the label
      notificationLabel = notificationList.reduce((accumulator, item) => {
        if (accumulator === '') return item.label
        else return accumulator + ', ' + item.label
      }, '')
    } else {
      notificationLabel = `${notification}% da capacidade máxima`
    }

    contentLabel = contentType === 'Saldo Financeiro' ? maskMoneyV2(content) : content

    if (monthlyNotification && contentType !== 'Validade') {
      dateLabel = `Inicio em ${months.find(item => item.value === Number(monthlyStartDate))?.name}`
    }
    else if (!monthlyNotification && contentType !== 'Validade') {
      dateLabel = `Inicio em ${annualStartDate}`
    }

    const newContent = {
      type: contentType,
      content,
      content_label: contentLabel,
      expiration_date: expirationDate,
      date_label: dateLabel,
      notification,
      notification_label: notificationLabel,
      monthly_start_date: monthlyStartDate,
      annual_start_date: annualStartDate,
      unity,
      content_notifications: notificationList
    }

    this.setState({
      contentType: '',
      contentList: [...this.state.contentList, newContent]
    })
    this.resetForm()
  }

  resetForm = () => {
    this.setState({
      content: '',
      expirationDate: '',
      monthlyStartDate: 1,
      annualStartDate: new Date().getFullYear(),
      notification: '',
      notificationError: false,
      inputErrors: {
        content: false,
        expirationDate: false,
        notification: false,
        monthlyStartDate: false,
        annualStartDate: false
      },
      notificationList: []
    })
  }

  handleAddNotification = () => {
    let { timeUnit, notification, inputErrors, notificationList } = this.state

    if (!this.state.notification) {
      this.setState({ inputErrors: { ...inputErrors, notification: true } })
      return
    }

    const newNotification = {
      type: timeUnit,
      notify_on: notification,
      label: `${notification} ${timeUnits.find(unit => unit.value === timeUnit).label} antes`
    }

    this.setState({
      notification: '',
      notificationList: [...notificationList, newNotification],
      inputErrors: { ...inputErrors, notification: false }
    })
  }

  handleSave = async () => {
    this.setState({ loadingSave: true })

    const form = new FormData()
    form.append('type', this.state.documentType)
    form.append('name', this.state.name)
    form.append('responsible', this.state.responsible)

    if (this.state.file) {
      form.append('document', this.state.file)
    }
    // Getting the correct module id according to the url
    form.append(this.getOwner(this.state.owner).id, this.state.ownerId)

    this.state.contentList.forEach(item => {
      form.append('compliance_contents[]', JSON.stringify(item))
    })

    let response = ''

    if (this.props.editData) {
      form.append('compliance_id', this.props.editData.id)
      response = await POST({ url: `update_compliance`, body: form })
    } else {
      response = await POST({ url: `store_compliance`, body: form })
    }

    if (response.error) {
      this.setState({ loadingSave: false, msgErro: response.error })
      window.scrollTo(0, 0)
      return
    }

    this.setState({ loadingSave: false })
    this.props.history.goBack()
  }

  // Function to get the correct data according to the url
  getOwner = (owner) => {
    switch (owner) {
      case 'destinos':
        return { label: 'Destino', id: 'destiny_id' }
      case 'pontos_coleta':
        return { label: 'Ponto de coleta', id: 'collect_point_id' }
      case 'transportadoras':
        return { label: 'Transportadora', id: 'carrier_id' }
      case 'veiculos':
        return { label: 'Veículo', id: 'vehicle_id' }
      case 'motoristas':
        return { label: 'Motorista', id: 'driver_id' }
      default:
        this.setState({ redirect: true })
        return { label: '', id: '' }
    }
  }

  renderContent = () => {
    let span = ''
    let Content = null
    let Notification = null
    const { inputErrors } = this.state

    // Set the span and Content based on the contentType
    switch (this.state.contentType) {
      case 'Quantidade':
        span = 'KG'
        break
      case 'Volume':
        span = 'LT'
        break
      case 'Saldo Financeiro':
        Content = (
          <input type="text" className={`form-control ${inputErrors.content && 'is-invalid'}`} name="content" id="content" value={maskMoneyV2(this.state.content)} onChange={({ target }) => {
            let clearInputErrors = { ...this.state.inputErrors }
            clearInputErrors.content = false

            // Divide by 100 to account for cents
            this.setState({ content: target.value.replace(/\D/g, '') / 100, inputErrors: clearInputErrors })
          }} />
        )
        break
      case 'Validade':
        Content = (
          <input type="date" className={`form-control ${inputErrors.expirationDate && 'is-invalid'}`} name="expirationDate" id="expirationDate" value={this.state.expirationDate} onChange={this.handleChange} />
        )
        break
    }

    // Render based on the contentType and monthlyNotification values
    if (span) {
      Content = (
        <div className="input-group">
          <NumericFormat type='text'
            valueIsNumericString
            thousandsGroupStyle='thousand'
            thousandSeparator="."
            decimalScale={2}
            decimalSeparator=","
            name="content"
            id="content"
            placeholder={span == 'KG' ? 'peso' : 'volume'}
            onValueChange={(component) => {
              let clearInputErrors = { ...this.state.inputErrors }
              clearInputErrors.content = false

              this.setState({ content: parseInt(component.value), inputErrors: clearInputErrors })
            }}
            value={this.state.content}
            className={`form-control ${inputErrors.content && 'is-invalid'}`}
          />
          <span className="input-group-text">{span}</span>
        </div>
      )
    }

    // Saving notification input on component for avoid repetition
    Notification = (
      <div className="col-12">
        <label className='col-form-label' htmlFor="notification">Notificações <b className='error'>*</b></label>
        <div className="input-group">
          <input
            type="number"
            id='notification'
            name="notification"
            value={this.state.notification}
            onChange={this.handleNotificationChange}
            className={`form-control ${inputErrors.notification && 'is-invalid'}`}
            placeholder={this.state.contentType !== 'Validade'
              ? 'Notificar à X % do total'
              : `Notificar à X ${timeUnits.find(item => item.value === this.state.timeUnit)?.label.toLowerCase()} do vencimento`}
          />
          {/* <span className="input-group-text">{this.state.contentType !== 'Validade' ? '%' : 'Dias'}</span> */}
          {this.state.contentType === 'Validade' &&
            <>
              <select className="form-select" name="timeUnit" value={this.state.timeUnit} onChange={this.handleChange}>
                {timeUnits.filter(item => item.value !== 'minute' && item.value !== 'hour').map(item => (
                  <option key={item.value} value={item.value}>{item.label}</option>
                ))}
              </select>
              <button className="btn btn-primary" type='button' onClick={this.handleAddNotification}><MdPlaylistAdd size={20} /></button>
            </>
          }
        </div>

        <div className="d-flex justify-content-between my-3">

          <div className='col-sm-4 col-12 ms-2 flex-column'>
            {this.state.notificationList.map(item => (
              <>
                <div key={item.type} className="d-flex justify-content-between align-items-center">
                  <span>{item.label}</span>

                  <IconButton onClick={() =>
                    this.setState({ notificationList: this.state.notificationList.filter(notification => notification !== item) })
                  }>
                    <MdClose size={20} />
                  </IconButton>
                </div>
                {this.state.notificationError && this.state.contentType !== 'Validade' &&
                  <span className='small error position-absolute'>O valor deve estar entre 0 e 100</span>
                }
              </>
            ))}
          </div>

          <div className="ms-auto">
            <button
              type="submit"
              className="btn btn-success btn-lg waves-effect waves-light"
              style={{ backgorundColor: '#f2f2f2' }}
              onClick={(e) => { e.preventDefault(); this.handleAddContent(e) }}
            >
              Inserir
            </button>
          </div>
        </div>
      </div >
    )

    // Common part for contentType and contentType !== 'Validade'
    if (this.state.contentType && this.state.contentType !== 'Validade') {
      return (
        <div className='row'>
          <div className="col-lg-6">
            <label className='col-form-label' htmlFor="content">{this.state.contentType} <b className='error'>*</b></label>
            {Content}
          </div>

          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6 d-flex justify-content-lg-center align-items-end">
                <div className="form-check">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={() => this.setState({ monthlyNotification: true, inputErrors: { ...this.state.inputErrors, annualStartDate: false } })} checked={this.state.monthlyNotification} />
                  <label className="form-check-label" htmlFor="flexRadioDefault1">
                    Controle mensal
                  </label>
                </div>
                <div className="form-check ms-2">
                  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onChange={() => this.setState({ monthlyNotification: false, inputErrors: { ...this.state.inputErrors, monthlyStartDate: false } })} checked={!this.state.monthlyNotification} />
                  <label className="form-check-label" htmlFor="flexRadioDefault2">
                    Controle anual
                  </label>
                </div>
              </div>

              <div className="col-lg-6">
                <label className='col-form-label' htmlFor="expirationDate">{this.state.monthlyNotification ? 'Mês de início' : 'Ano de ínicio'} <b className='error'>*</b></label>
                {this.state.monthlyNotification &&
                  <select className={`form-select ${inputErrors.monthlyStartDate && 'is-invalid'}`} id='monthlyStartDate' name="monthlyStartDate" value={this.state.monthlyStartDate} onChange={this.handleChange}>
                    {months.map(item => (
                      <option key={item.name} value={item.value}>{item.name}</option>
                    ))
                    }
                  </select>
                }
                {!this.state.monthlyNotification &&
                  <select className={`form-select ${inputErrors.annualStartDate && 'is-invalid'}`} id='annualStartDate' name="annualStartDate" value={this.state.annualStartDate} onChange={this.handleChange}>
                    {years.map(item => (
                      <option key={item} value={item}>{item}</option>
                    ))
                    }
                  </select>
                }
              </div>
            </div>
          </div>

          {Notification}
        </div>
      )
    }

    // Common part for contentType === 'Validade'
    if (this.state.contentType === 'Validade') {
      return (
        <div className="row">
          <div className="col-12">
            <label className='col-form-label' htmlFor="expirationDate">{this.state.contentType} <b className='error'>*</b></label>
            {Content}
          </div>

          {Notification}
        </div>
      )
    }
  }

  render() {
    return (
      <div className="row">
        {this.state.redirect && this.props.history.goBack()}
        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
              <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.props.editData ? 'Editar' : 'Criar'} documentação</h4>
              <p style={{ fontSize: '1rem', color: 'black' }}>Gerencie a sua documentação complience</p>

              {!this.state.loadingScreen ?
                <form onSubmit={(e) => { e.preventDefault(); this.handleSave() }}>
                  {this.state.msgErro && (
                    <>
                      <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => this.setState({ msgErro: '' })}
                        />
                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>
                          {this.state.msgErro}
                        </p>
                      </div>
                    </>
                  )}

                  <div className="col-12">
                    <label className='col-form-label' htmlFor="name">Nome <b className='error'>*</b></label>
                    <input type="text" className="form-control" id='name' name='name' placeholder='Nome do documento' value={this.state.name} onChange={this.handleChange} />
                  </div>

                  <div className="col-12">
                    <label className='col-form-label' htmlFor="responsible">Orgão responsável <b className='error'>*</b></label>
                    <input type="text" className="form-control" id='responsible' name="responsible" placeholder='Nome da organização' value={this.state.responsible} onChange={this.handleChange} />
                  </div>

                  <div className="col-12">
                    <label className='col-form-label' htmlFor="ownerId">{this.getOwner(this.state.owner).label} <b className='error'>*</b></label>
                    <select className='form-select' id="ownerId" name='ownerId' value={this.state.ownerId} onChange={this.handleChange}>
                      <option value=''>Selecione um {this.getOwner(this.state.owner).label.toLocaleLowerCase()}</option>

                      {this.state.selectOptions.map(item => (
                        <option key={item.id} value={item.id}>{item.key}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-12">
                    <label className='col-form-label' htmlFor="documentType">Tipo de documento <b className='error'>*</b></label>
                    <select className='form-select' id='documentType' name="documentType" value={this.state.documentType} onChange={this.handleChange}>
                      <option value=''>Selecione um tipo de documento</option>

                      {this.state.owner === 'motoristas' &&
                        <>
                          <option value={'Habilitação'}>{'Habilitação'}</option>
                          <option value={'Requisito Legal'}>{'Requisito Legal'}</option>
                          <option value={'Certificado'}>{'Certificado'}</option>
                          <option value={'Outros'}>{'Outros'}</option>
                        </>
                      }

                      {this.state.owner === 'veiculos' &&
                        <>
                          <option value={'Autorização'}>{'Autorização'}</option>
                          <option value={'Transporte'}>{'Transporte'}</option>
                          <option value={'Requisito Legal'}>{'Requisito Legal'}</option>
                          <option value={'Outros'}>{'Outros'}</option>
                        </>
                      }

                      {this.state.owner !== 'motoristas' && this.state.owner !== 'veiculos' && this.state.documentTypeOptions.map(item => (
                        <option key={item}>{item}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col-12">
                    <label className='col-form-label' htmlFor="contentType">Novo valor monitorado</label>
                    <select className='form-select' id="contentType" name="contentType" value={this.state.contentType} onChange={(e) => { this.handleChange(e); this.resetForm() }}>
                      <option value=''>N/A</option>
                      {!this.state.contentList.some(content => content.type === 'Validade') &&
                        <option value={'Validade'}>Validade</option>}

                      {this.state.owner !== 'motoristas' && this.state.owner !== 'veiculos' && this.state.contentTypeOptions.map(item => {
                        if (!this.state.contentList.some(content => content.type === item)) {
                          return (
                            <option key={item} value={item}>{item}</option>
                          )
                        }
                      })}
                    </select>
                  </div>

                  <form action={(e) => { e.preventDefault(); this.handleAddContent() }}>
                    {this.renderContent()}
                  </form>

                  <div className="col-12">
                    <label className="col-sm-12 col-form-label">Valores monitorados:</label>
                    <table id="tech-companies-1" className="table table-striped table-hover mt-2" >
                      <thead>
                        <tr>
                          <th>Tipo</th>
                          <th>Quantidade</th>
                          <th>Data</th>
                          <th>Notificar em</th>
                          <th data-priority="3">Excluir</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.contentList.length === 0 && <tr>
                          <td colSpan="5" style={{ textAlign: 'center' }}>Nenhum valor registrado</td>
                        </tr>}
                        {this.state.contentList.map((item, index) => (
                          <tr key={index}>
                            <td>{item.type}</td>
                            <td>{item.content ? item.content_label + item.unity : 'N/A'}</td>
                            <td>{item.date_label}</td>
                            <td>{item.notification_label} {item.type !== 'Validade' &&
                              <span
                                className="pointer"
                                data-tip={item.type !== 'Saldo Financeiro'
                                  ? (item.content * (item.notification / 100) + item.unity)
                                  : maskMoneyV2(item.content * (item.notification / 100))}>
                                <ReactTooltip />
                                <MdInfo color='#333' size={18} />
                              </span>}
                            </td>
                            <td>
                              <button
                                type='button'
                                className="btn btn-danger btn-outline btn-circle m-r-5"
                                style={{ background: 'white', border: '0px solid red', zIndex: '2 !important' }}
                                onClick={() => this.setState({ contentList: this.state.contentList.filter(content => content !== item) })}
                              >
                                <FaTrash color='red' />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="col-12">
                    <label className='col-form-label' htmlFor="file">Inserir documento {!this.props.editData && <b className='error'>*</b>}</label>
                    <input type="file" accept='application/pdf' className="form-control" id='file' name="file" onChange={this.handleChange} />
                  </div>

                  <div className="mt-5">
                    <PresetButtons loadingSave={this.state.loadingSave} label='Salvar compliance' />
                  </div>
                </form>
                :
                <div className='d-flex justify-content-center p-5'>
                  <div className="spinner-border text-primary" role="status"></div>
                </div>}

            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapsStateToProps = (state) => (
  {
    token: state.AppReducer.token,
  }
);

export default connect(mapsStateToProps, { logout })(CriarCompliance);