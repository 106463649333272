import React from 'react'
import { FaTrash } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { URL } from '../../../variables';
import InputMask from 'react-input-mask';
import 'react-toastify/dist/ReactToastify.css';
import { maskNumberBr, validateDecimals } from '../../../Auxiliar/Masks';
import { NumericFormat } from 'react-number-format';

const Pesagem = ({ edit, setEdit }) => {
  const [state, setState] = React.useState({
    data: [],
    pesagem: [],
    residuos: [],
    materiais: [],
    sub_inicial: [],
    materiais_inseridos: [],
    materiais_iniciais_inseridos: [],
    material_selecionado: [],

    tipo: 'Principal',
    tipo_unidade: { principal: '', secundaria: '' },
    peso: '',
    volume: '',
    idPesagem: '',
    material_selecionado: '',
    equivalencia: '',
    equivalKg: '',
    token: '',
    convert: '',
    quantidade: '',
    peso_kg: 0,
    volume_lt: 0,

    render: false,
    separacao: false,
    renderSelecionados: false,
    required: false,
    saving: false,
    error: false,
    loading: true,
  })
  const params = useParams();
  const history = useHistory();
  let id = params.id;
  state.token = useSelector(state => state.AppReducer.token);

  /* //-------------------------------------Busca de dados do despacho finalizado--------------------------------------// */
  React.useEffect(() => {
    fetch(`${URL}api/get_view_despacho_finalizada/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then(async (json) => {
        let materiais = json.despacho_finalizado.materiais
        if (edit) {
          let residuos = [...edit.residuos_selecionados];
          residuos.forEach(item => {
            let material = {
              "material_id": item.residuo_rejeito.id,
              "nome": item.residuo_rejeito.nome,
              "peso": item.peso,
              "unidade_principal": item.residuo_rejeito.unidade_principal.sigla,
              "unidade_secundaria": item.residuo_rejeito.unidade_secundaria.sigla,
              "solicitacao": item.codigo_solicitacao,
              "peso_kg": item.peso_kg,
              "volume_lt": item.volume_lt,
              "volume": item.volume,
              "inativo": item.residuo_rejeito.inativo,
            }
            state.materiais_inseridos = [...state.materiais_inseridos, material];
            state.materiais_iniciais_inseridos = [...state.materiais_iniciais_inseridos, material];
            materiais = materiais.filter((item2) => item2.nome != item.residuo_rejeito.nome);
          })
          state.renderSelecionados = true;
          console.log(edit)
          state.materiais = [materiais];
        }

        materiais = materiais.filter(item => item.residuo_rejeito.inativo != true)
        setState({
          ...state,
          materiais: materiais,
          sub_inicial: json.despacho_finalizado.materiais,
          data: json.despacho_finalizado,
          render: true,
          loading: false,
        })

      })
  }, [id]);
  {/* //--------------------------------Função para retornar dados do despacho finalizado--------------------------------// */ }
  function renderDispacho() {
    return (
      <>
        <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados do despacho:</label>
        <div className="form-group row">
          <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Situação: <span style={{ fontWeight: 'normal' }}>{state.data.situacao}</span></label></div>
        </div>
        <div className="form-group row">
          <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Peso total: <span style={{ fontWeight: 'normal' }}>{maskNumberBr(state.data.peso_total)} Kg</span></label></div>
        </div>
        <div className="form-group row">
          <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Volume total: <span style={{ fontWeight: 'normal' }}>{maskNumberBr(state.data.volume_total)} L</span></label></div>
        </div>
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">Transportadora: <span style={{ fontWeight: 'normal' }}>{state.data.transportadora == null ? 'Sem transportadora' : state.data.transportadora.nome_fantasia}</span></label>
        </div>

        {state.data.descricao != '' &&
          <div className="form-group row">
            <label className="col-sm-12 col-form-label">Observações: <span style={{ fontWeight: 'normal' }}>{state.data.descricao}</span>
            </label>
          </div>}
        {<div className="form-group row">
          <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Veículos/Motoristas selecionados:</label>
          <table id="tech-companies-1" className="table table-striped table-hover" >
            <thead>
              <tr>
                <th>Veículo</th>
                <th>Motorista</th>
                <th>Capacidade Peso</th>
                <th>Capacidade Volume</th>
              </tr>
            </thead>
            <tbody>
              {state.data.veiculos_motoristas.lenght == 0 && <tr>
                <td colSpan="5" style={{ textAlign: 'center' }}>Nenhum Veículo/Motorista registrado</td>
              </tr>}
              {state.data.veiculos_motoristas.map((item, id) => (
                <tr key={id}>
                  <td>{item.veiculo.modelo.nome}</td>
                  <td>{item.motorista.nome}</td>
                  <td>{maskNumberBr(item.veiculo.capacidade_maxima_peso + '')}  {item.veiculo.unidade_peso.sigla}</td>
                  <td>{maskNumberBr(item.veiculo.capacidade_maxima_volume + '')}  {item.veiculo.unidade_volume.sigla}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>}
      </>
    )
  }
  {/* //----------------------------------------Materiais para selecionar----------------------------------------// */ }
  function renderMateriais() {
    return (
      <>
        <div className="form-group row">
          <div className="col-xl-12 col-6 mb-xl-5">
            <label className="col-sm-12 col-form-label" >Materiais <b style={{ color: 'red' }}>*</b></label>
            <select value={''} className="form-control" onChange={(e) => {
              const selecionado = state.materiais.filter((item) => item.id == e.target.value);
              setState({
                ...state,
                required: false,
                material: e.target.value,
                material_selecionado: selecionado,
                tipo_unidade: { principal: selecionado[0].residuo_rejeito.unidade_principal.tipo, secundaria: selecionado[0].residuo_rejeito.unidade_secundaria.tipo },
                equivalKg: selecionado[0].volume_lt / selecionado[0].peso_kg,
                equivalencia: selecionado[0].residuo_rejeito.equivalencia,
                render: true,
              })
            }}>
              <option value={''}>{state.material_selecionado[0] ? state.material_selecionado[0].residuo_rejeito.nome : 'Selecione o material para pesagem'}</option>
              {state.materiais.map((item) => (<option key={item.id} value={item.id}>{item.nome}</option>))}
            </select>
          </div>
          {/* //---------------------------------------Tipo de unidade----------------------------------------// */}
          {state.material_selecionado[0] &&
            <>
              <div className="col-xl-3 col-6 mb-xl-5 mb-3">
                <label className="col-form-label" >Unidade de medida <b style={{ color: 'red' }}>*</b></label>
                <select value={state.tipo} className="form-control" onChange={(e) => {
                  setState({
                    ...state,
                    tipo: e.target.value,
                    quantidade: state.convert,
                    convert: state.quantidade,
                    required: false,
                  })
                }}>
                  <option value={'Principal'}>{state.material_selecionado[0].residuo_rejeito.unidade_principal.nome}</option>
                  <option value={'Secundaria'}>{state.material_selecionado[0].residuo_rejeito.unidade_secundaria.nome}</option>
                </select>
              </div>
              {/* //----------------------------------------Tabela de valores----------------------------------------// */}
              <div className="col-xl-9 col-12 pt-1">
                <table className='table table-striped text-center'>
                  <thead>
                    <tr>
                      <td>Material</td>
                      <td>Peso</td>
                      <td>Volume</td>
                      <td>Peso Kg</td>
                      <td>Volume L</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{state.material_selecionado[0].nome}</td>
                      <td>{maskNumberBr(state.material_selecionado[0].peso) + ' ' + state.material_selecionado[0].residuo_rejeito.unidade_principal.sigla}</td>
                      <td>{maskNumberBr(state.material_selecionado[0].volume)} {state.material_selecionado[0].residuo_rejeito.unidade_secundaria.sigla}</td>
                      <td>{maskNumberBr(state.material_selecionado[0].peso_kg)} KG</td>
                      <td>{maskNumberBr(state.material_selecionado[0].volume_lt)} L</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* //-------------------------------------Campo de unidade principal---------------------------------------// */}
              <div className="row">
                <label className="col-form-label">Confirme o {state.tipo == 'Principal' ? state.tipo_unidade.principal : state.tipo_unidade.secundaria} recebido da entrega de {state.material_selecionado[0].nome}</label>
                <div className="col-6">
                  <label className="col-sm-12 col-form-label">{state.tipo == 'Principal' ? state.tipo_unidade.principal : state.tipo_unidade.secundaria}<b style={{ color: 'red' }}>*</b></label>
                  <div className="input-group mb-3">
                    <NumericFormat type='text' className="form-control"
                      thousandsGroupStyle='thousand'
                      valueIsNumericString
                      thousandSeparator="."
                      decimalScale={2}
                      decimalSeparator=","
                      placeholder={state.tipo == 'Principal' ? state.tipo_unidade.principal : state.tipo_unidade.secundaria}
                      onValueChange={(values, e) => {
                        atualizaPeso(values.value)
                      }}
                      value={state.quantidade} />
                    <span className="input-group-text" id="basic-addon3">{tipoPeso('Pri')}</span>
                  </div>
                  {state.error && <p className='text-danger small'>Digite um valor válido</p>}
                </div>
                {/* //-------------------------------------Campo de unidade secundaria-------------------------------------// */}
                <div className='col-6'>
                  <label className="col-sm-12 col-form-label">{state.tipo == 'Principal' ? state.tipo_unidade.secundaria : state.tipo_unidade.principal}</label>
                  <div className="input-group mb-3">
                    <NumericFormat className="form-control " id="peso"
                      thousandsGroupStyle='thousand'
                      valueIsNumericString
                      thousandSeparator="."
                      decimalScale={2}
                      decimalSeparator=","
                      placeholder={state.tipo == 'Principal' ? state.tipo_unidade.secundaria : state.tipo_unidade.principal}
                      type="text" value={state.convert} disabled />
                    <span className="input-group-text" id="basic-addon3">{tipoPeso('Sec')}</span>
                  </div>
                </div>
              </div>
              {/* //-------------------------------------------------Botão--------------------------------------------------// */}
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button type="button" className="btn btn-success" style={{ backgorundColor: '#f2f2f2' }} onClick={() => {
                  if (state.quantidade == '' || state.quantidade < 0) {
                    setState({ ...state, required: true });
                  }
                  else {
                    let quant;
                    let nums = Array.from(state.quantidade)
                    state.tipo == "Principal" ? quant = state.quantidade : quant = state.quantidade / state.equivalencia;
                    if (nums[0] == '.' || nums[nums.length - 1] == '.') {
                      setState({ ...state, error: true })
                    } else {
                      let material_selecionado = {
                        "material_id": state.material_selecionado[0].residuo_rejeito.id,
                        "nome": state.material_selecionado[0].nome,
                        "peso": quant,
                        "unidade_principal": state.material_selecionado[0].residuo_rejeito.unidade_principal.sigla, "unidade_secundaria": state.material_selecionado[0].residuo_rejeito.unidade_secundaria.sigla,
                        "solicitacao": state.material_selecionado[0].solicitacao_id,
                        "peso_kg": state.peso_kg,
                        "volume_lt": state.volume_lt,
                        "volume": quant * state.material_selecionado[0].residuo_rejeito.equivalencia,
                      }
                      setState({
                        ...state,
                        materiais: state.materiais.filter((item) => item.nome != material_selecionado.nome),
                        materiais_inseridos: [...state.materiais_inseridos, material_selecionado],
                        material_selecionado: [],
                        quantidade: quant,
                        quantidade: '',
                        convert: '',
                        renderSelecionados: true,
                      })
                    }
                  }
                }}>Inserir</button>
              </div>
            </>}
        </div>
      </>
    )
  }
  {/* //--------------------------------Função para retornar sigla principal e secundaria--------------------------------// */ }
  function tipoPeso(value) {
    if (value == 'Pri') {
      if (state.tipo == 'Principal') {
        return state.material_selecionado[0].residuo_rejeito.unidade_principal.sigla
      } if (state.tipo == 'Secundaria') {
        return state.material_selecionado[0].residuo_rejeito.unidade_secundaria.sigla
      }
    } else if (value == 'Sec') {
      if (state.tipo == 'Principal') {
        return state.material_selecionado[0].residuo_rejeito.unidade_secundaria.sigla
      } if (state.tipo == 'Secundaria') {
        return state.material_selecionado[0].residuo_rejeito.unidade_principal.sigla
      }
    }
  }
  {/* //-----------------------------------Função para retornar materiais selecionados-----------------------------------// */ }
  function renderSelecionados() {
    return (
      <div className='m-3 form-group row'>
        {state.materiais_inseridos && state.required == false && <>
          <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Materiais Pesados:</label>
          <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }} >
            <thead className='table-group-divider'>
              <tr>
                <th>Nome</th>
                <th>Peso</th>
                <th>Volume</th>
                <th>Peso KG</th>
                <th>Volume LT</th>
                <th data-priority="3">Excluir</th>
              </tr>
            </thead>
            <tbody>
              {state.materiais_inseridos.map((item, id) => (
                <tr key={id}>
                  <td>{item.nome}</td>
                  <td>{maskNumberBr(item.peso) + ''} {item.unidade_principal}</td>
                  <td>{maskNumberBr(item.volume) + ''} {item.unidade_secundaria}</td>
                  <td>{maskNumberBr(item.peso_kg) + ''} Kg</td>
                  <td>{maskNumberBr(item.volume_lt) + ''} L</td>
                  <td>
                    <button onClick={() => {
                      let materiais = [...state.materiais];
                      let devolucao = state.sub_inicial.filter((item3) => item3.nome == item.nome);
                      materiais.push(devolucao[0]);
                      let materiais_inseridos = state.materiais_inseridos.filter((item2) => item2.nome != item.nome);
                      if (!materiais_inseridos[0]) state.renderSelecionados = false;

                      setState({
                        ...state,
                        material: '',
                        materiais: materiais,
                        materiais_inseridos: materiais_inseridos,
                        quantidade: '',
                        convert: '',
                      })
                    }}
                      className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white', }}><FaTrash color='red' /></button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>}
        <div className='d-grid gap-2 d-md-flex justify-content-md-end'>
          <button className='btn btn-success' disabled={state.saving} onClick={() => edit ? storePesagem('update') : storePesagem('store')}>Salvar</button>
        </div>
      </div>
    )
  }
  {/* //-------------------------Função para converter peso de unidades principais e secundarias-------------------------// */ }

  function atualizaPeso(value) {
    let validatedValue = value;
    if (state.tipo == 'Principal') {
      let conta = validatedValue * state.material_selecionado[0].residuo_rejeito.equivalencia
      setState({
        ...state,
        required: false,
        error: false,
        quantidade: validatedValue,
        peso_kg: validatedValue * state.material_selecionado[0].residuo_rejeito.unidade_principal.equivalencia,
        volume_lt: validatedValue * state.material_selecionado[0].residuo_rejeito.equivalencia,
        convert: conta,
        error: false,
      })
    } else {
      setState({
        ...state,
        required: false,
        error: false,
        quantidade: validatedValue,
        convert: validatedValue / state.material_selecionado[0].residuo_rejeito.equivalencia,
        peso_kg: validatedValue / state.equivalKg,
        volume_lt: validatedValue * state.material_selecionado[0].residuo_rejeito.unidade_secundaria.equivalencia,
        error: false,
      })
    }
  }
  {/* //-------------------------------------Função para criar ou atualizar pesagem--------------------------------------// */ }
  async function storePesagem(tipo) {
    setState({ ...state, saving: true });
    toast.promise(async () => {
      if (!edit) {
        toast.loading('Criando Pesagem ', {
          toastId: 'toastid',
        });
        const resp = await fetch(`${URL}api/store_pesagem/${id}`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Authorization': `Bearer ${state.token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(id)
        })
        const json = await resp.json();
        state.idPesagem = json.pesagem.id
        toast.update('toastid', { render: "Pesagem criada com Sucesso!", type: "success", isLoading: false, autoClose: 1000 });
      }
      state.materiais_inseridos.forEach(item => {
        toast.loading('Registrando ' + item.nome, {
          toastId: 'toastid' + item.material_id,
        })
      });

      let pesagem_id;
      let meth;
      { edit ? pesagem_id = edit.pesagem_id : pesagem_id = state.idPesagem }
      { edit ? meth = 'PATCH' : meth = 'POST' }
      const store = { pesagem_id: pesagem_id, residuos: state.materiais_inseridos, residuos_iniciais: state.materiais_iniciais_inseridos };

      await fetch(`${URL}api/${tipo}_residuo_pesagem`, {
        method: (meth),
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${state.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(store)
      })
      setState({ ...state, loading_save: true })
      state.materiais_inseridos.forEach(item => {
        toast.update('toastid' + item.material_id, { render: "Sucesso!", type: "success", isLoading: false, autoClose: 1000 });
      })
      setTimeout(() => history.push('/pesagens'), 500);
    }, {
      //pending: "Carregando registros",
      //success: "Registros salvos com sucesso",
      error: "Falha ao salvar registros",
    });
  }
  {/* //---------------------------------------Retornando tudo de forma organizada---------------------------------------// */ }
  return (
    <>
      <div className='card'>
        {state.loading && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={
          { marginTop: '2rem' }}><div className="spinner-border text-primary" role="status"  ></div></div></div>}
        <div className='card-body'>
          {state.render && renderDispacho()}
          {state.render && renderMateriais()}
          {state.renderSelecionados && renderSelecionados()}
        </div>

        <ToastContainer
          position="bottom-right"
          toastStyle={{ border: '1px solid gray', borderRadius: 4 }}
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnHover
        />
      </div>
    </>
  )
}
export default Pesagem