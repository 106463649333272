import React from 'react'
import PontosColeta from './PontosColeta';
import Filter from '../../../Auxiliar/Filter';
import Compliances from '../Compliance/Compliances';

const SelecionarPontosColeta = () => {
  const [search, setSearch] = React.useState(false)
  const [dateOf, setDateOf] = React.useState('')
  const [dateFor, setDateFor] = React.useState('')
  const [options, setOptions] = React.useState({
    pontos_coleta: { value: true, label: 'Pontos de Coleta', checked: true },
    compliances: { value: false, label: 'Compliances', checked: false },
  })

  return (
    <div className="card">
      <div className="card-body" id="card">
        <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Pontos de Coleta</h4>
        <Filter setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setSearch} />
        <div>
          <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados os pontos de coleta do sistema</p>
        </div>
        <div className="row">
          {options.pontos_coleta.value &&
            <PontosColeta dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />}
          {options.compliances.value &&
            <Compliances dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} owner={'pontos_coleta'} searchFor='collect_point_id' />}
        </div>
      </div>
    </div>
  )
}

export default SelecionarPontosColeta