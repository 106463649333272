
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import { URL } from '../../../variables';
import { maskNumber, maskNumberBr, maskPrice } from '../../../Auxiliar/Masks';

import { MdReply } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import jsPDF from 'jspdf';
import SweetAlert from 'react-bootstrap-sweetalert';
import { NumericFormat } from 'react-number-format';


class CriarDestinacao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/destinacao',
            loading_screen: false,
            ResiduosRejeitos: [],
            Destinos: [],
            DestinoSelecionado: [],
            Solicitacoes: [],
            SolicitacoesSelecionadas: [],
            MateriaisSolicitacao: [],
            Destinacoes: [],
            Tratamentos: [],
            MateriaisInseridos: [],
            Transportadoras: [],
            Veiculos: [],
            Motoristas: [],
            PontosSolic: [],

            Materialselecionado: {},
            SolicitacaoSelecionada: {},
            pdf: false,
            ChaveNota: '',
            NumeroNota: '',
            SerieNota: '',
            DestinacaoId: '',
            Situacao: 'Rascunho',
            Descricao: '',
            Tratamento: '',
            ValorFinanceiro: '',
            Movimentacao: '',
            Outros: false,
            Peso: '',
            Volume: '',
            MaterialSolicitacao: '',
            Solicitacao: '',
            Destino: '',
            DataAtual: '',
            DataSolicitacao: '',
            DataAgendamento: '',
            DataInicio: '',
            PesoTotal: '0',
            VolumeTotal: '0',
            ReceitaTotal: '0.00',
            DespesaTotal: '0.00',
            Saldo: '0.00',
            TransportadoraId: '',
            MotoristaId: '',
            VeiculoId: '',
            loading_save: false,
            msgErro: '',
            show_warning: false,
            show_warning_inserir: false,

            destinoCnpj: '',
            unidadeDestino: '',
            transportadoraCnpj: '',
            unidadeTransportadora: '',
            sigorCodigos: [],
            genMtr: false,
            destinacaoId: '',
            DestinosSigor: [],
            TransportadorasSigor: [],
            MaterialSolicitacaoSigor: [],
            sigorDate: new Date().getTime(),
        };
    }


    get_data() {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_dados_destinacao`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log('resp', resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    let pontos_solic = resp.solicitacoes.map(item => item.ponto_coleta)
                    let DestinosSigor = resp.destinos.filter(item => item.unidade_sigor)
                    let TransportadorasSigor = resp.transportadoras.filter(item => item.unidade_sigor)
                    this.setState({
                        loading_screen: false,
                        Destinos: resp.destinos,
                        Solicitacoes: resp.solicitacoes,
                        Destinacoes: resp.destinacoes,
                        ResiduosRejeitos: resp.materiais,
                        Tratamentos: resp.tratamentos,
                        Transportadoras: resp.transportadoras,
                        Veiculos: resp.veiculos,
                        Motoristas: resp.motoristas,
                        PontosSolic: pontos_solic,
                        DestinosSigor,
                        TransportadorasSigor,
                    });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        }).catch((err) => {
            this.setState({ redirect: true });

            console.log(err);
        });
    }



    salvar() {
        if (this.state.Materialselecionado.length > 0) {

            this.setState({ show_warning_inserir: true, loading_save: true, msgErro: '' })
        } else {
            this.setState({ show_warning_inserir: false, loading_save: true, msgErro: '' });
            fetch(`${URL}api/store_destinacao`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${this.props.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    solicitacao_id: this.state.Solicitacao,
                    destino_id: this.state.Destino,
                    data_criacao: this.state.DataAtual,
                    data_solicitacao: this.state.DataSolicitacao,
                    data_agendamento: this.state.DataAgendamento,
                    data_inicio: this.state.DataInicio,
                    peso_total: this.state.PesoTotal,
                    volume_total: this.state.VolumeTotal,
                    receita_total: this.state.ReceitaTotal,
                    despesa_total: this.state.DespesaTotal,
                    saldo: this.state.Saldo,
                    motorista_id: this.state.MotoristaId,
                    veiculo_id: this.state.VeiculoId,
                    descricao: this.state.Descricao,
                    situacao: this.state.Situacao,
                    transportadora_id: this.state.TransportadoraId,
                    materiais_coletados: this.state.MateriaisInseridos,
                    chave_nota: this.state.ChaveNota,
                    numero_nota: this.state.NumeroNota,
                    serie_nota: this.state.SerieNota,
                })
            }).then(async (responseLog) => {
                try {
                    let resp = await responseLog.json();
                    if (resp.errors != null || resp.error != null) {
                        let errors = Object.values(resp.errors);
                        let erro = '';
                        for (let i = 0; i < errors.length; i++) {
                            if (i != errors.length - 1)
                                erro += errors[i] + '\n';
                            else
                                erro += errors[i];
                        }
                        console.log(erro);
                        window.scrollTo(500, 0);
                        this.setState({ loading_save: false, msgErro: erro });
                    }
                    else {
                        if (this.state.genMtr) {
                            this.genSigorToken()
                            this.setState({ destinacaoId: resp.destinacao_id })
                        } else {
                            this.setState({ loading_save: false, redirect: true });
                        }
                    }

                } catch (err) {
                    console.log(err);
                    this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

                }

            })
                .catch((err) => {
                    console.log(err);
                    this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
                });
        }

    }

    genSigorToken() {
        fetch(`https://mtrr.cetesb.sp.gov.br/apiws/rest/gettoken`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                cpfCnpj: '31977663850',
                senha: 'Verdaz123',
                unidade: '59988'
            })
        }).then(async (response) => {
            let resp = await response.json()
            this.getMtr(resp.objetoResposta)
            // this.setState({ sigorToken: resp.objetoResposta })
        })
    }

    getMtr(token) {
        let motorista = this.state.Motoristas.filter(item => item.id === this.state.MotoristaId)[0]
        let veiculo = this.state.Veiculos.filter(item => item.id === this.state.VeiculoId)[0]
        console.log('data', this.state.sigorDate, veiculo)
        fetch(`https://mtrr.cetesb.sp.gov.br/apiws/rest/salvarManifestoLote`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': token,
            },
            body: JSON.stringify([{
                nomeResponsavel: this.state.SolicitacaoSelecionada[0].responsavel.name,
                dataExpedicao: '9999999999999',
                placaVeiculo: veiculo.placa,
                nomeMotorista: motorista.nome,
                observacoes: this.state.Descricao,
                transportador: {
                    unidade: this.state.unidadeTransportadora,
                    cpfCnpj: this.state.transportadoraCnpj
                },
                destinador: {
                    unidade: this.state.unidadeDestino,
                    cpfCnpj: this.state.destinoCnpj
                },
                listaManifestoResiduos: [...this.state.sigorCodigos]
            }]
            )
        }).then(async (response) => {
            let resp = await response.json()
            resp.respostaApiwsManifestoDTO.forEach(mtr => {
                switch (mtr.codigoGerado) {
                    case 0:
                        this.salvarManifesto(mtr)
                        break;

                    case '005':
                        this.setState({ sigorError: [...this.state.sigorError, 'A Data de expedição não pode ser anterior a data atual!'] })
                        break;

                    case '007':
                        this.setState({ sigorError: [...this.state.sigorError, 'Para MTR de AT, permite apenas um resíduo!'] })
                        break;

                    default:
                        this.setState({ sigorError: [...this.state.sigorError, mtr.restResponseMensagem] })
                }
            })
        })
    }

    salvarManifesto(mtr) {
        fetch(`${URL}api/store_sigor_mtr`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ codigo_manifesto: mtr.manifestoNumeroNacional, destinacao_id: this.state.destinacaoId })
        }).then(async (response) => {
            const resp = response.json()
            //Tratar erros
            this.setState({ loading_save: false, redirect: true });
        })
    }

    componentDidMount() {
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        this.setState({
            DataAtual: ano + '-' + mes + '-' + dia,
            DataSolicitacao: ano + '-' + mes + '-' + dia
        })
        this.get_data();
    }

    render() {
        return (
            <div className="row " >
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">

                            <div className="row mb-3">
                                <div className="col-6">
                                    <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Destinação</h4>
                                    <p style={{ fontSize: '1rem', color: 'black' }}>Adicione uma nova destinação</p>
                                </div>
                                <div className="col-6 m-auto">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" value={this.state.genMtr} onChange={() => this.setState({ genMtr: !this.state.genMtr })} />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Gerar MTR ao salvar</label>
                                    </div>
                                </div>
                            </div>
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Destino<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.Destino} className="form-control"
                                                    onChange={(e) => {
                                                        if (e.target.value == '') {
                                                            this.setState({
                                                                SolicitacoesSelecionadas: [],
                                                                Destino: '',
                                                                Solicitacao: '',
                                                                DestinoSelecionado: [],
                                                                SolicitacaoSelecionada: {},
                                                                MaterialSolicitacao: {},
                                                                Peso: '',
                                                                Volume: '',
                                                                ValorFinanceiro: '',
                                                                Movimentacao: '',
                                                                Outros: false,
                                                                DestinacaoId: '',
                                                                Materialselecionado: {},
                                                                Tratamento: '',
                                                                MateriaisInseridos: []
                                                            })
                                                        }
                                                        else {
                                                            let destino_sel = this.state.Destinos.filter(d => d.id == e.target.value);
                                                            let sol_sel = [];
                                                            for (let i = 0; i < this.state.Solicitacoes.length; i++) {
                                                                for (let j = 0; j < this.state.Solicitacoes[i].material.length; j++) {
                                                                    if (destino_sel[0].residuos_rejeitos.length > 0) {
                                                                        for (let k = 0; k < destino_sel[0].residuos_rejeitos.length; k++) {
                                                                            if (this.state.Solicitacoes[i].material[j].residuo_rejeito_id == destino_sel[0].residuos_rejeitos[k].id) {

                                                                                sol_sel.push(this.state.Solicitacoes[i]);
                                                                                k = destino_sel[0].residuos_rejeitos.length;
                                                                                j = this.state.Solicitacoes[i].material.length;
                                                                            }

                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            this.setState({
                                                                SolicitacoesSelecionadas: sol_sel,
                                                                Destino: e.target.value,
                                                                Solicitacao: '',
                                                                DestinoSelecionado: destino_sel,
                                                                SolicitacaoSelecionada: {},
                                                                MaterialSolicitacao: {},
                                                                Peso: '',
                                                                Volume: '',
                                                                Movimentacao: '',
                                                                Outros: false,
                                                                ValorFinanceiro: '',
                                                                DestinacaoId: '',
                                                                Materialselecionado: {},
                                                                Tratamento: '',
                                                                MateriaisInseridos: [],
                                                                unidadeDestino: destino_sel[0].unidade_sigor ? destino_sel[0].unidade_sigor.num : '',
                                                                destinoCnpj: destino_sel[0].cnpj.replace(/\D/g, '')
                                                            })
                                                        }
                                                    }}>
                                                    <option value={''}>Selecione um destino</option>
                                                    {this.state.genMtr
                                                        ?
                                                        this.state.DestinosSigor.map((item, id) => (<option key={id} value={item.id}>{item.fantasia}</option>))
                                                        :
                                                        this.state.Destinos.map((item, id) => (<option key={id} value={item.id}>{item.fantasia}</option>))}
                                                </select>
                                            </div>
                                        </div>

                                        {this.state.SolicitacoesSelecionadas.length > 0 && <><hr />
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados de solicitação em aberto:</label>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Solicitações<b style={{ color: 'red' }}>*</b></label>
                                                <div className="col-sm-12">
                                                    <select value={this.state.Solicitacao} className="form-control"
                                                        onChange={(e) => {
                                                            let verificador = false;
                                                            for (let i = 0; i < this.state.MateriaisInseridos.length; i++) {
                                                                if (e.target.value == this.state.MateriaisInseridos.residuo_rejeito_id) {
                                                                    verificador = true;
                                                                }
                                                            }
                                                            if (e.target.value == '' || verificador == true) {
                                                                this.setState({
                                                                    SolicitacaoSelecionada: {},
                                                                    MateriaisSolicitacao: [],
                                                                    Solicitacao: '',
                                                                    MaterialSolicitacao: {},
                                                                    Peso: '',
                                                                    Volume: '',
                                                                    Movimentacao: '',
                                                                    Outros: false,
                                                                    ValorFinanceiro: '',
                                                                    SolicitacaoSelecionada: {},
                                                                    DestinacaoId: '',
                                                                    Materialselecionado: {},
                                                                    Tratamento: '',
                                                                    MateriaisInseridos: []
                                                                });
                                                            }
                                                            else {
                                                                let solicitacao = this.state.SolicitacoesSelecionadas.filter(item => item.id == e.target.value);
                                                                let materiais = solicitacao[0].material.filter(item => item.peso != 0);

                                                                let MaterialSolicitacaoSigor = materiais.filter(item => item.residuo_rejeito.sigor_acondicionamento_codigo && item.residuo_rejeito.sigor_classe_codigo && item.residuo_rejeito.sigor_estado_codigo && item.residuo_rejeito.sigor_tratamento_codigo && item.residuo_rejeito.sigor_unidade_codigo)

                                                                console.log('materiais', materiais, MaterialSolicitacaoSigor)

                                                                this.setState({
                                                                    SolicitacaoSelecionada: solicitacao,
                                                                    MateriaisSolicitacao: materiais,
                                                                    Solicitacao: e.target.value,
                                                                    MaterialSolicitacao: {},
                                                                    Peso: '',
                                                                    Volume: '',
                                                                    Movimentacao: '',
                                                                    Outros: false,
                                                                    ValorFinanceiro: '',
                                                                    DestinacaoId: '',
                                                                    Materialselecionado: {},
                                                                    Tratamento: '',
                                                                    MateriaisInseridos: [],
                                                                    MaterialSolicitacaoSigor,
                                                                });
                                                            }
                                                        }}>
                                                        <option value={''}>Selecione uma opção</option>
                                                        {this.state.SolicitacoesSelecionadas.map((item, id) => (<option key={id} value={item.id}> {item.ponto_coleta.nome_fantasia} - {item.codigo_solicitacao}</option>))}
                                                    </select>
                                                </div>
                                            </div>
                                            <br />

                                            {this.state.SolicitacaoSelecionada.length > 0 && <>
                                                <div className="form-group row">
                                                    <p className="col-sm-6 "> <b className="col-sm-4 ">Data da solicitação: </b> {this.state.SolicitacaoSelecionada[0].data.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </p>
                                                </div>
                                                <div className="form-group row">
                                                    <p className="col-sm-6 "><b className="col-sm-4 " >Ponto de coleta:  </b> {this.state.SolicitacaoSelecionada[0].ponto_coleta.nome_fantasia}</p>
                                                </div>
                                                <div className="form-group row">
                                                    <p className="col-sm-6 "><b className="col-sm-4 " >Responsável: </b>{this.state.SolicitacaoSelecionada[0].responsavel.name}</p>
                                                </div>
                                                <div className="form-group row">
                                                    <label className="col-sm-12 col-form-label">Material<b style={{ color: 'red' }}>*</b></label>
                                                    <div className="col-sm-12">
                                                        <select value={this.state.MaterialSolicitacao} className="form-control"
                                                            onChange={(e) => {
                                                                let verificador = false;
                                                                for (let i = 0; i < this.state.MateriaisInseridos.length; i++) {
                                                                    if (e.target.value == this.state.MateriaisInseridos[i].residuo_rejeito_id) {
                                                                        verificador = true
                                                                    }
                                                                }
                                                                if (e.target.value == '' || verificador == true) {
                                                                    this.setState({
                                                                        MaterialSolicitacao: '',
                                                                        Materialselecionado: {},
                                                                        Peso: '',
                                                                        Movimentacao: '',
                                                                        Outros: false,
                                                                        ValorFinanceiro: '',
                                                                        Volume: '',
                                                                        DestinacaoId: '',
                                                                        Tratamento: ''
                                                                    })
                                                                } else {
                                                                    let mat_sel = this.state.MateriaisSolicitacao.filter(item => item.residuo_rejeito_id == e.target.value);
                                                                    this.setState({
                                                                        MaterialSolicitacao: e.target.value,
                                                                        Materialselecionado: mat_sel,
                                                                        Peso: '',
                                                                        Movimentacao: mat_sel[0].residuo_rejeito.saldo_financeiro,
                                                                        ValorFinanceiro: '',
                                                                        Volume: '',
                                                                        DestinacaoId: mat_sel[0].residuo_rejeito.destinacao_id,
                                                                        Tratamento: mat_sel[0].residuo_rejeito.tratamento_id
                                                                    })
                                                                }
                                                            }}>
                                                            <option value={''}>Selecione uma opção</option>
                                                            {this.state.genMtr
                                                                ?
                                                                this.state.MaterialSolicitacaoSigor.map((item, id) => (<option key={id} value={item.residuo_rejeito_id}>{item.residuo_rejeito.nome}</option>))
                                                                :
                                                                this.state.MateriaisSolicitacao.map((item, id) => (<option key={id} value={item.residuo_rejeito_id}>{item.residuo_rejeito.nome}</option>))}
                                                        </select>
                                                    </div>
                                                </div>
                                                {this.state.Materialselecionado.length > 0 && <>

                                                    {this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo == 'Peso' &&
                                                        <div className="row">
                                                            <div className="col-md-6 col-12">
                                                                <label className="col-sm-12 col-form-label">Peso<b style={{ color: 'red' }}>*</b></label>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text" id="basic-addon3">Solicitado: {maskNumberBr(this.state.Materialselecionado[0].peso)} {this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.sigla}</span>
                                                                    <NumericFormat className="form-control " id="peso"
                                                                        placeholder="peso atendido" type="text" value={this.state.Peso}
                                                                        valueIsNumericString
                                                                        thousandsGroupStyle='thousand'
                                                                        thousandSeparator="."
                                                                        decimalScale={2}
                                                                        decimalSeparator=","
                                                                        onValueChange={(values, e) => {
                                                                            if (values.value == '') {
                                                                                this.setState({
                                                                                    Peso: values.value,
                                                                                    ValorFinanceiro: '',
                                                                                    Volume: ''
                                                                                })
                                                                            } else {
                                                                                let valor = parseFloat(values.value) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.valor_saldo_financeiro);
                                                                                this.setState({
                                                                                    Peso: values.value,
                                                                                    ValorFinanceiro: valor.toFixed(2),
                                                                                    Volume: parseFloat(values.value) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.equivalencia),
                                                                                    validated: false,
                                                                                    required: false
                                                                                })
                                                                            }
                                                                        }} />
                                                                </div>
                                                                {this.state.validated == true && <b style={{ color: 'red', fontSize: '12px' }}>O peso atendido não pode ser maior que o solicitado</b>}
                                                                {this.state.required == true && <b style={{ color: 'red', fontSize: '12px' }}>O campo Peso é obrigatório</b>}
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <label className="col-sm-12 col-form-label">Volume<b style={{ color: 'red' }}>*</b></label>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text" id="basic-addon3">Solicitado: {maskNumberBr(this.state.Materialselecionado[0].volume)} {this.state.Materialselecionado[0].residuo_rejeito.unidade_secundaria.sigla} </span>
                                                                    <NumericFormat className="form-control" id="volume" disabled
                                                                        placeholder="volume atendido" type="text"
                                                                        valueIsNumericString
                                                                        thousandsGroupStyle='thousand'
                                                                        thousandSeparator="."
                                                                        decimalScale={2}
                                                                        decimalSeparator=","
                                                                        value={this.state.Volume} />
                                                                </div>
                                                            </div>
                                                        </div>}

                                                    {this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo !== 'Peso' &&
                                                        <div className="row">
                                                            <div className="col-md-6 col-12">
                                                                <label className="col-sm-12 col-form-label">{this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo}<b style={{ color: 'red' }}>*</b></label>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text" id="basic-addon3">Solicitado: {maskNumberBr(this.state.Materialselecionado[0].volume)} {this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.sigla} </span>
                                                                    <NumericFormat className="form-control" id="volume"
                                                                        placeholder={`${this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo.toLowerCase()} ${this.state.Materialselecionado[0].unidade_principal.tipo === 'Volume' ? 'atendido' : 'atendida'}`}
                                                                        type="text"
                                                                        valueIsNumericString
                                                                        thousandsGroupStyle='thousand'
                                                                        thousandSeparator="."
                                                                        decimalScale={2}
                                                                        decimalSeparator=","
                                                                        value={this.state.Volume}
                                                                        onValueChange={(values, e) => {
                                                                            if (values.value == '') {
                                                                                this.setState({
                                                                                    Volume: values.value,
                                                                                    ValorFinanceiro: '',
                                                                                    Peso: ''
                                                                                })
                                                                            } else {
                                                                                let valor = parseFloat(values.value) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.valor_saldo_financeiro);
                                                                                this.setState({
                                                                                    Volume: values.value,
                                                                                    ValorFinanceiro: valor.toFixed(2),
                                                                                    Peso: parseFloat(values.value) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.equivalencia),
                                                                                    validated: false,
                                                                                    required: false
                                                                                })
                                                                            }
                                                                        }} />
                                                                </div>
                                                                {this.state.validated == true && <b style={{ color: 'red', fontSize: '12px' }}>O volume atendido não pode ser maior que o solicitado</b>}
                                                                {this.state.required == true && <b style={{ color: 'red', fontSize: '12px' }}>O campo Volume é obrigatório</b>}
                                                            </div>
                                                            <div className="col-md-6 col-12">
                                                                <label className="col-sm-12 col-form-label">Peso<b style={{ color: 'red' }}>*</b></label>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text" id="basic-addon3">Solicitado: {maskNumberBr(this.state.Materialselecionado[0].peso)} {this.state.Materialselecionado[0].residuo_rejeito.unidade_secundaria.sigla}</span>
                                                                    <NumericFormat className="form-control " id="peso"
                                                                        disabled placeholder="peso atendido" type="text"
                                                                        valueIsNumericString
                                                                        thousandsGroupStyle='thousand'
                                                                        thousandSeparator="."
                                                                        decimalScale={2}
                                                                        decimalSeparator=","
                                                                        value={this.state.Peso} />
                                                                </div>
                                                            </div>
                                                        </div>}

                                                    <div className="row">
                                                        <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Tipo de movimentação<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="input-group mb-3">
                                                                <select value={this.state.Movimentacao} className="form-control"
                                                                    onChange={(e) => {
                                                                        if (e.target.value == 'Outros') {
                                                                            this.setState({
                                                                                Outros: true,
                                                                                Movimentacao: 'Não Aplicável'
                                                                            })
                                                                        } else {
                                                                            this.setState({
                                                                                Movimentacao: e.target.value,
                                                                            })
                                                                        }
                                                                    }}>
                                                                    <option value={'Outros'}>Outros</option>
                                                                    <option value={'Receita'}>Receita</option>
                                                                    <option value={'Despesa'}>Despesa</option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                        {this.state.Outros == true &&
                                                            <div className="col-md-6 col-12">
                                                                <label className="col-sm-12 col-form-label">Outros<b style={{ color: 'red' }}>*</b></label>
                                                                <div className="input-group mb-3">
                                                                    <select value={this.state.Movimentacao} className="form-control"
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                Movimentacao: e.target.value
                                                                            })
                                                                        }}>
                                                                        <option value={'Não Aplicável'}>Não Aplicável</option>
                                                                        <option value={'Doação'}>Doação</option>
                                                                        <option value={'Despesa Evitada'}>Despesa Evitada</option>
                                                                    </select>
                                                                </div>
                                                            </div>}
                                                        {this.state.Outros == false && <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Valor financeiro<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="input-group mb-3">
                                                                <NumericFormat className="form-control"
                                                                    placeholder="R$ 99,99" type="text"
                                                                    prefix={'R$ '}
                                                                    thousandsGroupStyle='thousand'
                                                                    thousandSeparator="."
                                                                    decimalScale={2}
                                                                    decimalSeparator=","
                                                                    valueIsNumericString
                                                                    onValueChange={(values, e) =>
                                                                        this.setState({ ValorFinanceiro: values.value })}
                                                                    value={this.state.ValorFinanceiro} />
                                                            </div>
                                                        </div>}
                                                        {this.state.Outros == true && <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Valor financeiro<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="input-group mb-3">
                                                                <NumericFormat className="form-control"
                                                                    disabled placeholder="R$ 99,99" type="text"
                                                                    prefix={'R$ '}
                                                                    thousandsGroupStyle='thousand'
                                                                    thousandSeparator="."
                                                                    decimalScale={2}
                                                                    decimalSeparator=","
                                                                    valueIsNumericString
                                                                    onValueChange={(values, e) =>
                                                                        this.setState({ ValorFinanceiro: values.value })}
                                                                    value={maskPrice('0.00')} />
                                                            </div>
                                                        </div>}
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Destinação<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="input-group mb-3">
                                                                <select value={this.state.DestinacaoId} className="form-control"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            DestinacaoId: e.target.value
                                                                        })
                                                                    }}>
                                                                    <option value={''}>selecione uma opção</option>
                                                                    {this.state.Destinacoes.map((item, id) => (<option key={id} value={item.id}>{'[' + item.codigo + '] ' + item.descricao}</option>))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 col-12">
                                                            <label className="col-sm-12 col-form-label">Tratamento<b style={{ color: 'red' }}>*</b></label>
                                                            <div className="input-group mb-3">
                                                                <select disabled={this.state.genMtr ? true : false} value={this.state.Tratamento} className="form-control"
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            Tratamento: e.target.value
                                                                        })
                                                                    }}>
                                                                    <option value={''}>selecione uma opção</option>
                                                                    {this.state.Tratamentos.map((item, id) => (<option key={id} value={item.id}>{'[' + item.codigo + '] ' + item.descricao}</option>))}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                        <button type="button" className="btn btn-success" style={{ backgorundColor: '#f2f2f2' }} onClick={() => {
                                                            let receita;
                                                            let despesa;
                                                            let saldo;
                                                            let valor = this.state.ValorFinanceiro;
                                                            if (this.state.Movimentacao == 'Receita') {
                                                                saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2)) + parseFloat(parseFloat(valor).toFixed(2));
                                                                receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2)) + parseFloat(parseFloat(valor).toFixed(2));
                                                                despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2));
                                                            } else if (this.state.Movimentacao == 'Despesa') {
                                                                despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2)) + parseFloat(parseFloat(valor).toFixed(2));
                                                                saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2)) - parseFloat(parseFloat(valor).toFixed(2));
                                                                receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2));
                                                            } else {
                                                                despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2));
                                                                saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2));
                                                                receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2));
                                                            }

                                                            if (this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo === 'Peso' && this.state.Peso == '' ||
                                                                this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo !== 'Peso' && this.state.Volume == '') {
                                                                this.setState({ required: true })
                                                            } else if (this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo === 'Peso' && parseFloat(this.state.Peso) > parseFloat(this.state.Materialselecionado[0].peso) ||
                                                                this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo !== 'Peso' && parseFloat(this.state.Volume) > parseFloat(this.state.Materialselecionado[0].volume)) {
                                                                this.setState({ validated: true })
                                                            } else {
                                                                let peso_kg;
                                                                let volume_lt;
                                                                if (this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.tipo === 'Peso') {
                                                                    peso_kg = parseFloat(this.state.Peso) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.equivalencia);
                                                                    volume_lt = parseFloat(this.state.Volume) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.unidade_secundaria.equivalencia);
                                                                } else {
                                                                    peso_kg = parseFloat(this.state.Peso) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.unidade_secundaria.equivalencia);
                                                                    volume_lt = parseFloat(this.state.Volume) * parseFloat(this.state.Materialselecionado[0].residuo_rejeito.unidade_principal.equivalencia);
                                                                }
                                                                let rr = { ...this.state.Materialselecionado[0].residuo_rejeito }
                                                                let codigos = {
                                                                    marQuantidade: Number(this.state.Peso),
                                                                    resCodigoIbama: rr.sigor_residuo_codigo.residuo_rejeito_codigo,
                                                                    uniCodigo: rr.sigor_unidade_codigo.unidade_codigo,
                                                                    traCodigo: rr.sigor_tratamento_codigo.tratamento_codigo,
                                                                    tieCodigo: rr.sigor_estado_codigo.estado_codigo,
                                                                    tiaCodigo: rr.sigor_acondicionamento_codigo.acondicionamento_codigo,
                                                                    claCodigo: rr.sigor_classe_codigo.classe_codigo,
                                                                    marNumeroONU: rr.sigor_residuo_codigo.sigor_codigo_risco.mar_numero_onu,
                                                                    marClasseRisco: rr.sigor_residuo_codigo.sigor_codigo_risco.mar_codigo_risco,
                                                                    marNomeEmbarque: rr.sigor_residuo_codigo.sigor_codigo_risco.mar_nome_embarque,
                                                                    greCodigo: rr.sigor_residuo_codigo.sigor_codigo_risco.gre_codigo
                                                                }

                                                                let materiais = [...this.state.MateriaisInseridos];
                                                                let new_material = {
                                                                    "residuo_rejeito_id": this.state.MaterialSolicitacao, "nome": this.state.Materialselecionado[0].residuo_rejeito.nome, "peso": this.state.Peso, "volume": this.state.Volume, "peso_kg": peso_kg, "volume_lt": volume_lt, "movimentacao": this.state.Movimentacao,
                                                                    "valor": this.state.ValorFinanceiro, "destinacao_disposicao_id": this.state.DestinacaoId, "tratamento_id": this.state.Tratamento
                                                                };
                                                                materiais.push(new_material);
                                                                this.setState({
                                                                    PesoTotal: parseFloat(this.state.PesoTotal) + peso_kg,
                                                                    VolumeTotal: parseFloat(this.state.VolumeTotal) + volume_lt,
                                                                    MateriaisInseridos: materiais,
                                                                    ReceitaTotal: receita.toFixed(2).toString(),
                                                                    DespesaTotal: despesa.toFixed(2).toString(),
                                                                    Saldo: saldo.toFixed(2).toString(),
                                                                    MaterialSolicitacao: '',
                                                                    Materialselecionado: {},
                                                                    Peso: '',
                                                                    Movimentacao: '',
                                                                    Outros: false,
                                                                    ValorFinanceiro: '',
                                                                    Volume: '',
                                                                    DestinacaoId: '',
                                                                    Tratamento: '',
                                                                    sigorCodigos: [...this.state.sigorCodigos, codigos]
                                                                })
                                                            }
                                                        }
                                                        }>Inserir</button>
                                                    </div>
                                                </>}
                                            </>}
                                        </>}
                                        <hr />

                                        {this.state.MateriaisInseridos.length > 0 &&
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Materiais selecionados:</label>

                                                <table id="tech-companies-1" className="table table-striped table-hover" >
                                                    <thead>
                                                        <tr>
                                                            <th>Material</th>
                                                            <th>Peso</th>
                                                            <th>Volume</th>
                                                            <th data-priority="3">Excluir</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.MateriaisInseridos.map((item) => (
                                                            <tr key={item.residuo_rejeito_id}>
                                                                <td>{item.nome}</td>
                                                                <td>{maskNumberBr(item.peso + '') + ' ' + (item.residuo_rejeito.unidade_principal.tipo === 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla)}</td>
                                                                <td>{maskNumberBr(item.volume + '') + ' ' + (item.residuo_rejeito.unidade_principal.tipo !== 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla)}</td>
                                                                <td>
                                                                    <button className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white', border: '0px solid red' }}><FaTrash color='red' onClick={() => {

                                                                        let receita = this.state.ReceitaTotal
                                                                        let despesa = this.state.DespesaTotal
                                                                        let valor = item.valor;
                                                                        let saldo;

                                                                        if (item.movimentacao == 'Receita') {
                                                                            receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2)) - parseFloat(parseFloat(item.valor).toFixed(2));
                                                                            despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2));
                                                                            saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2)) - parseFloat(parseFloat(item.valor).toFixed(2));
                                                                        } else if (item.movimentacao == 'Despesa') {
                                                                            receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2));
                                                                            despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2)) - parseFloat(parseFloat(item.valor).toFixed(2));
                                                                            saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2)) + parseFloat(parseFloat(item.valor).toFixed(2));
                                                                        } else {
                                                                            despesa = parseFloat(parseFloat(this.state.DespesaTotal).toFixed(2));
                                                                            saldo = parseFloat(parseFloat(this.state.Saldo).toFixed(2));
                                                                            receita = parseFloat(parseFloat(this.state.ReceitaTotal).toFixed(2));
                                                                        }


                                                                        let materias_inseridos = this.state.MateriaisInseridos.filter((item2) => item2.residuo_rejeito_id != item.residuo_rejeito_id);
                                                                        let material = this.state.ResiduosRejeitos.filter(item3 => item3.id == item.residuo_rejeito_id);
                                                                        let peso_kg;
                                                                        let volume_lt;
                                                                        if (material[0].unidade_principal.tipo == 'Peso') {
                                                                            peso_kg = parseFloat(item.peso) * parseFloat(material[0].unidade_principal.equivalencia);
                                                                            volume_lt = parseFloat(item.volume) * parseFloat(material[0].unidade_secundaria.equivalencia);
                                                                        } else {
                                                                            peso_kg = parseFloat(item.peso) * parseFloat(material[0].unidade_secundaria.equivalencia);
                                                                            volume_lt = parseFloat(item.volume) * parseFloat(material[0].unidade_principal.equivalencia);
                                                                        }

                                                                        this.setState({
                                                                            PesoTotal: parseFloat(this.state.PesoTotal) - parseFloat(peso_kg),
                                                                            VolumeTotal: parseFloat(this.state.VolumeTotal) - parseFloat(volume_lt),
                                                                            ReceitaTotal: parseFloat(receita).toFixed(2).toString(),
                                                                            DespesaTotal: parseFloat(despesa).toFixed(2).toString(),
                                                                            Saldo: parseFloat(saldo).toFixed(2).toString(),
                                                                            MateriaisInseridos: materias_inseridos
                                                                        })
                                                                    }} />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>}

                                        <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados do despacho:</label>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Situação: </label>{this.state.Situacao}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"> <label className="col-sm-4 col-form-label">Data da criação: </label>{this.state.DataAtual.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Agendado para: </label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.DataAgendamento} type="date" style={{ maxWidth: 168 }} min={this.state.DataAtual} onChange={(e) => {
                                                    if (e.target.value != '' && this.state.DataInicio == '' && e.target.value >= this.state.DataAtual) {
                                                        this.setState({ Situacao: 'Agendado', DataAgendamento: e.target.value });
                                                    } else if (e.target.value != '' && this.state.DataInicio == '' && e.target.value < this.state.DataAtual) {
                                                        this.setState({ Situacao: 'Agendado', DataAgendamento: this.state.DataAtual });
                                                    }
                                                    else if (e.target.value != '' && this.state.DataInicio != '' && e.target.value < this.state.DataAtual) {
                                                        this.setState({ DataAgendamento: this.state.DataAtual });
                                                    }
                                                    else if (e.target.value != '' && this.state.DataInicio != '' && e.target.value >= this.state.DataAtual) {
                                                        this.setState({ DataAgendamento: e.target.value });
                                                    }
                                                    else if (e.target.value == '' && this.state.DataInicio == '') {
                                                        this.setState({ Situacao: 'Rascunho', DataAgendamento: e.target.value });
                                                    } else if (e.target.value == '' && this.state.DataInicio != '') {
                                                        this.setState({ DataAgendamento: e.target.value });
                                                    }

                                                }} />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Iniciado em:</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" value={this.state.DataInicio} type="date" style={{ maxWidth: 168 }} min={this.state.DataAtual} max={this.state.DataAtual} onChange={(e) => {
                                                    let date = new Date(e.target.value).getTime()
                                                    this.setState({ sigorDate: date })
                                                    if (e.target.value != '' && e.target.value != this.state.DataAtual) {
                                                        this.setState({ DataInicio: this.state.DataAtual })
                                                    }
                                                    if (e.target.value != '' && e.target.value == this.state.DataAtual) {
                                                        this.setState({ Situacao: 'Iniciado', DataInicio: e.target.value })
                                                    }
                                                    if (e.target.value == '' && this.state.DataAgendamento != '') {
                                                        this.setState({ Situacao: 'Agendado', DataInicio: '' })
                                                    }
                                                    if (e.target.value == '' && this.state.DataAgendamento == '') {
                                                        this.setState({ Situacao: 'Rascunho', DataInicio: '' })
                                                    }
                                                }} />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Peso total: </label>{maskNumberBr(this.state.PesoTotal)} Kg</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Volume total: </label>{maskNumberBr(this.state.VolumeTotal)} L</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Receita: </label> R$ {this.state.ReceitaTotal.toString().replace('.', ',')}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Despesas: </label> R$ {this.state.DespesaTotal.toString().replace('.', ',')}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Saldo: </label> R$ {this.state.Saldo.toString().replace('.', ',')}</div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Transportadora<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.TransportadoraId} className="form-control"
                                                    onChange={(e) => {
                                                        const transp_selecionada = this.state.Transportadoras.filter(item => item.id === e.target.value)
                                                        this.setState({
                                                            TransportadoraId: e.target.value,
                                                            VeiculoId: "",
                                                            MotoristaId: '',
                                                            unidadeTransportadora: transp_selecionada[0].unidade_sigor ? transp_selecionada[0].unidade_sigor.num : '',
                                                            transportadoraCnpj: transp_selecionada[0].cnpj.replace(/\D/g, '')
                                                        });
                                                    }}>
                                                    <option value={''}>Sem transportadora</option>
                                                    {this.state.genMtr
                                                        ?
                                                        this.state.TransportadorasSigor.map((item, id) => (<option key={id} value={item.id}>{item.nome_fantasia}</option>))
                                                        :
                                                        this.state.Transportadoras.map((item, id) => (<option key={id} value={item.id}>{item.nome_fantasia}</option>))}


                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Veículo<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.VeiculoId} className="form-control"
                                                    onChange={(e) => {
                                                        this.proprio = false;
                                                        for (let i = 0; i < this.state.Veiculos.length; i++) {
                                                            if (this.state.Veiculos[i].id == e.target.value) {
                                                                if (this.state.Veiculos[i].transportadora_id == null) {
                                                                    this.proprio = true;
                                                                    break;
                                                                }
                                                            }
                                                        }
                                                        this.setState({ VeiculoId: e.target.value, MotoristaId: '' }, () => {

                                                        })
                                                    }}>
                                                    <option value={''}>Selecione uma opção</option>
                                                    {this.state.Veiculos.filter((item, id) => {
                                                        if (item.transportadora_id == null && this.state.TransportadoraId == '') return true;
                                                        else if (item.transportadora_id == this.state.TransportadoraId) return true;
                                                        else return false;
                                                    }).map((item, id) => {
                                                        return (<option key={id} value={item.id}>{item.modelo.nome}</option>)
                                                    })}
                                                </select>
                                                {/* <select value={this.state.VeiculoId} className="form-control"
                                                    onChange={(e) => { this.setState({ VeiculoId: e.target.value }) }}>
                                                    <option value={''}>Selecione uma opção</option>
                                                    {this.state.Veiculos.map((item, id) => (<option key={id} value={item.id}>{item.modelo.nome}</option>))}
                                                </select> */}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Motorista<b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.MotoristaId} className="form-control"
                                                    onChange={(e) => { this.setState({ MotoristaId: e.target.value }) }}>
                                                    <option value={''}>Selecione uma opção</option>
                                                    {this.state.VeiculoId != '' && this.state.Motoristas.filter((item, id) => {
                                                        if (this.proprio == true) {
                                                            if (item.veiculo_id == this.state.VeiculoId) {
                                                                return true;
                                                            }
                                                            else {
                                                                return false;
                                                            }
                                                        }
                                                        else {
                                                            if (item.transportadora_id == this.state.TransportadoraId) return true;
                                                            else return false;
                                                        }

                                                    }).map((item, id) => (<option key={id} value={item.id}>{item.nome}</option>))}
                                                </select>
                                                {/* <select value={this.state.MotoristaId} className="form-control"
                                                    onChange={(e) => { this.setState({ MotoristaId: e.target.value }) }}>
                                                    <option value={''}>Selecione uma opção</option>
                                                    {this.state.Motoristas.map((item, id) => (<option key={id} value={item.id}>{item.nome}</option>))}
                                                </select> */}
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Observações </label>
                                            <div className="col-sm-12">
                                                <textarea className="form-control" placeholder="Observações" value={this.state.Descricao} type="text" onChange={(e) => { this.setState({ Descricao: e.target.value }) }} />
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Chave da NF-e</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Chave de acesso da nota fiscal" value={this.state.ChaveNota} type="text" onChange={(e) => { this.setState({ ChaveNota: e.target.value }) }} />
                                            </div>
                                        </div>
                                        <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-sm-12 col-form-label">Número da NF-e </label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Número da nota fiscal" type="text" onChange={(e) => this.setState({ NumeroNota: e.target.value })} value={this.state.NumeroNota} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-sm-12 col-form-label">Série da NF-e </label>
                                                        <div className="col-sm-12">
                                                            <input className="form-control" placeholder="Série da nota fiscal" type="text" onChange={(e) => this.setState({ SerieNota: e.target.value })} value={this.state.SerieNota} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-4 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/destinacao"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-8 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2', marginLeft: '5px' }}>Salvar destinação</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}
                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}
                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => { this.setState({ show_warning: false }); }}
                                show={this.state.show_warning}
                                confirmBtnText='     OK     '
                                confirmBtnBsStyle="success"
                            >
                                Para gerar o MTR você deve preencher todos os campos que são obrigatórios.
                            </SweetAlert>

                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => { this.setState({ Materialselecionado: {} }, () => this.salvar()) }}
                                onCancel={() => { this.setState({ show_warning_inserir: false }); }}
                                show={this.state.show_warning_inserir}
                                confirmBtnText='Sim, desejo salvar '
                                cancelBtnText='Cancelar'
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                showCancel={true}
                            >
                                Existe um material selecionado que não foi inserido, deseja salvar mesmo assim ?
                            </SweetAlert>
                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(CriarDestinacao);


