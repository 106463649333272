
import React, { Component } from 'react';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import { maskNumber, maskPrice } from '../../../Auxiliar/Masks';
import { MdAdd, MdReply } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import { Button, IconButton, MenuItem, Tooltip, Zoom } from '@mui/material';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { NumericFormat } from 'react-number-format';
import GetToken from '../../SigorRequests/GetToken';
import GetRRClass from '../../SigorRequests/GetRRClass';
import GetAcondicionamentos from '../../SigorRequests/GetAcondicionamentos';

class CriarRR extends Component {

    constructor(props) {
        super(props);
        this.state = {
            display: false,
            openInfo: false,
            loading:true
        }
    }

    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }

    componentDidMount() {
        this.getData()
    }

    async getData() {
        this.setState({  loading:true})
        console.log('props state', this.props)
        const estados = this.props.state.estados
        const tratamentos = this.props.state.tratamentos
        const unidadesPeso = this.props.state.unidades_peso
        const unidadesVol = this.props.state.unidades_volume
        const token = await GetToken({ token: this.props.token })
        console.log('TOKEN')
        console.log(token)
        let classes = (await GetRRClass({ token: token, codigoIbama: this.props.state.codigoIbama, classes: this.props.state.classes }))
        let all_classes=classes.all_classes;
        classes=classes.classes;
        let error='O resíduo selecionado não possui nenhuma classe aprovada. Aceita alguma das classes a seguir para continuar o cadastro: ';
        all_classes.map((item,id)=>{
            error+=item.claDescricao+ (id==all_classes.length-1?'':', ');
        })

        console.log('classes all', this.props.state.classes)
        // classes.forEach(item => { item.classes_sigor.forEach(item2 => classes.push(item2.dados_classe)) })
        // unidadesVol.forEach(item => { item.unidades_sigor.forEach(item2 => unidadesVol.push(item2.dados_unidade)) })
        // unidadesPeso.forEach(item => { item.unidades_sigor.forEach(item2 => unidadesPeso.push(item2.dados_unidade)) })

        // tratamentos.forEach(item => {
        //     item.tratamentos_sigor.forEach(item2 => { tratamentos.push(item2.dados_tratamento) })
        // })

        // estados.forEach(item => {
        //     item.estados_sigor.forEach(item2 => { estados.push({ ...item2.dados_estado, sigor_estado_id: item2.sigor_estado_id }) })
        // })


        this.setState({loading:false})
        this.props.setState({  ...this.props.state, errors:classes.length==0?[error]:[], tokenSigor: token, unidades_peso: unidadesPeso, unidades_volume: unidadesVol, classes, estados, tratamentos })
    }

    async getAcondicionamentos(id) {
        let estado = this.props.state.estados.filter(item => item.id === id)[0]
        let aconds = await GetAcondicionamentos({ token: this.props.state.tokenSigor, estado: estado, acondicionamentos: this.props.state.acondicionamentos })
        console.log('aconds', aconds, estado)
        // aconds.aconds.forEach(item => { item.acondicionamentos_sigor && item.acondicionamentos_sigor.forEach(item2 => aconds.aconds.push(item2.dados_acondicionamento)) })

        if (aconds.status) {
            this.props.setState({ ...this.props.state, acondicionamentosSigor: aconds.aconds })
        } else {
            window.scrollTo(500, 0);
            this.props.setState({ ...this.props.state, msgErro: aconds.message, estado: '', acondicionamentosSigor: [] })
        }
    }

    render() {
        return (
            <div className="row">
                <div className="col-12">
                    {this.state.loading == false && <div>
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Nome <b style={{ color: 'red' }}>*</b>
                                    </label>
                                    <div className="col-sm-12">
                                        <input className="form-control" placeholder="Nome do resíduo/rejeito" type="text" onChange={(e) => this.props.setState({ ...this.props.state, nome: e.target.value })} value={this.props.state.nome} disabled />
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Tipo <b style={{ color: 'red' }}>*</b></label>
                                    <div className="col-sm-12">
                                        <select value={this.props.state.tipo} className="form-control" id="cameras" onChange={(e) => {
                                            this.props.setState({ ...this.props.state, tipo: e.target.value })
                                        }}>
                                            <option value={''}>Selecione um tipo</option>
                                            <option value={'Resíduo'}>Resíduo</option>
                                            <option value={'Rejeito'}>Rejeito</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Classe <b style={{ color: 'red' }}>*</b></label>
                                    <div className="col-sm-12 position-relative">
                                        <select onClick={() => this.setState({ display: !this.state.display })} value={this.props.state.classe} className="form-control" id="cameras" onChange={(e) => {
                                            if (!this.props.state.danger) {
                                                const classe = this.props.state.classes.filter(item => item.id === e.target.value)[0]
                                                let danger = false

                                                if (this.props.state.codigoIbama === '200132' || classe.sigor_codigos.classe_codigo === 41 || classe.sigor_codigos.classe_codigo === 32 || classe.sigor_codigos.classe_codigo === 33 || classe.sigor_codigos.classe_codigo === 34 || classe.sigor_codigos.classe_codigo === 35 || classe.sigor_codigos.classe_codigo === 21 || classe.sigor_codigos.classe_codigo === 22 || classe.sigor_codigos.classe_codigo === 23 || classe.sigor_codigos.classe_codigo === 24 || classe.sigor_codigos.classe_codigo === 25) {
                                                    danger = true
                                                }
                                                this.props.setState({ ...this.props.state, classe: e.target.value, danger })
                                            } else {
                                                this.props.setState({ ...this.props.state, classe: e.target.value })
                                            }
                                        }}>
                                            <option value={''}>Selecione uma classe</option>

                                            {this.props.state.classes.map((item, id) => {
                                                let disabled = false
                                                let bgColor = ''
                                                let color = ''

                                                if (item.hasPendding) {
                                                    bgColor = '#f44336'
                                                    color = '#ffffff'
                                                    disabled = true
                                                }
                                                return (
                                                    <option key={id} style={{ backgroundColor: bgColor, color: color }} disabled={disabled} value={item.id}>
                                                        {item.nome}
                                                        {item.hasPendding && '  -  Necessita aprovação entre pendentes'}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>

                                    {this.props.state.danger &&
                                        <>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">marNumeroOnu <b style={{ color: 'red' }}>*</b>
                                                </label>
                                                <div className="col-sm-12">
                                                    <input type='number' className="form-control" placeholder="Número ONU" onChange={(e) => this.props.setState({ ...this.props.state, marNumeroOnu: e.target.value })} value={this.props.state.marNumeroOnu} />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">marClasseRisco <b style={{ color: 'red' }}>*</b>
                                                </label>
                                                <div className="col-sm-12">
                                                    <input className="form-control" placeholder="Classe de risco" type='number' onChange={(e) => this.props.setState({ ...this.props.state, marClasseRisco: e.target.value })} value={this.props.state.marClasseRisco} />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">marNomeEmbarque <b style={{ color: 'red' }}>*</b>
                                                </label>
                                                <div className="col-sm-12">
                                                    <input className="form-control" placeholder="Nome do embarque" type="text" onChange={(e) => this.props.setState({ ...this.props.state, marNomeEmbarque: e.target.value })} value={this.props.state.marNomeEmbarque} />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-12 d-flex col-form-label">greCodigo <b className='ms-1' style={{ color: 'red' }}>*</b>

                                                    <Tooltip title="Este campo tem que ser preenchido com valores de 1 a 4" placement='right' arrow open={this.state.openInfo}>
                                                        <IconButton size='small'>
                                                            <BsFillInfoCircleFill />
                                                        </IconButton>
                                                    </Tooltip>
                                                </label>

                                                <div className="col-sm-12">
                                                    <input className="form-control" placeholder="gre Codigo" type='number' onChange={(e) => {
                                                        if ((e.target.value > 0 && e.target.value <= 4) || e.target.value === '') {
                                                            console.log('val', e.target.value)
                                                            this.props.setState({ ...this.props.state, greCodigo: e.target.value })
                                                            this.setState({ openInfo: false })
                                                        } else {
                                                            this.setState({ openInfo: true })
                                                        }
                                                    }} value={this.props.state.greCodigo} />
                                                </div>
                                            </div>
                                        </>}
                                    {/* <div className={this.state.display ? 'd-absolute' : 'd-none'}>
                                        <Tooltip TransitionComponent={Zoom} title="Add">
                                            <Button>Teste</Button>
                                        </Tooltip>
                                    </div> */}
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Periculosidade </label>
                                    <div className="col-sm-12">
                                        <select value={this.props.state.periculosidade} className="form-control" id="cameras" onChange={(e) => {
                                            this.props.setState({ ...this.props.state, periculosidade: e.target.value })
                                        }}>
                                            <option value={''}>Selecione uma periculosidade</option>
                                            {this.props.state.periculosidades.map((item, id) => (
                                                <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Estado físico <b style={{ color: 'red' }}>*</b></label>
                                    <div className="col-sm-12">
                                        <select value={this.props.state.estado} className="form-control" id="cameras" onChange={(e) => {
                                            let estado = this.props.state.estados.filter(item => item.id === e.target.value)[0]
                                            this.getAcondicionamentos(estado.relation ? estado.relation.sigor_estado_id : estado.id)
                                            this.props.setState({ ...this.props.state, estado: e.target.value })
                                        }}>
                                            <option value={''}>Selecione um estado físico</option>
                                            {this.props.state.estados.map((item, id) => (
                                                <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Acondicionamento <b style={{ color: 'red' }}>*</b></label>
                                    <div className="col-sm-12">
                                        <select value={this.props.state.acondicionamento} className="form-control" id="cameras" onChange={(e) => {
                                            this.props.setState({ ...this.props.state, acondicionamento: e.target.value })
                                        }}>
                                            <option value={''}>Selecione um acondicionamento</option>
                                            {this.props.state.acondicionamentosSigor.map((item, id) => {
                                                let disabled = false
                                                let bgColor = ''
                                                let color = ''

                                                if (item.hasPendding) {
                                                    bgColor = '#f44336'
                                                    color = '#ffffff'
                                                    disabled = true
                                                }
                                                return (
                                                    <option key={id} style={{ backgroundColor: bgColor, color: color }} disabled={disabled} value={item.id}>[ {item.codigo} ]
                                                        {item.descricao}
                                                        {item.hasPendding && '  -  Necessita aprovação entre pendentes'}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Destinação/Disposição <b style={{ color: 'red' }}>*</b></label>
                                    <div className="col-sm-12">
                                        <select value={this.props.state.destinacao} className="form-control" id="cameras" onChange={(e) => {
                                            this.props.setState({ ...this.props.state, destinacao: e.target.value })
                                        }}>
                                            <option value={''}>Selecione uma destinação</option>
                                            {this.props.state.destinacoes.map((item, id) => (
                                                <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Armazenamento temporário <b style={{ color: 'red' }}>*</b></label>
                                    <div className="col-sm-12">
                                        <select value={this.props.state.armazenamento} className="form-control" id="cameras" onChange={(e) => {
                                            this.props.setState({ ...this.props.state, armazenamento: e.target.value })
                                        }}>
                                            <option value={''}>Selecione um armazenamento temporário</option>
                                            {this.props.state.armazenamentos.map((item, id) => (
                                                <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Tratamento <b style={{ color: 'red' }}>*</b></label>
                                    <div className="col-sm-12">
                                        <select value={this.props.state.tratamento} className="form-control" id="cameras" onChange={(e) => {
                                            this.props.setState({ ...this.props.state, tratamento: e.target.value })
                                        }}>
                                            <option value={''}>Selecione um tratamento</option>
                                            {this.props.state.tratamentos.map((item, id) => (
                                                <option key={id} value={item.id}>[ {item.codigo} ] {item.descricao}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Sub-material </label>
                                    <div className="input-group col-sm-12">
                                        <input type="text" className="form-control" onChange={(e) => this.props.setState({ ...this.props.state, submaterial: e.target.value })} value={this.props.state.submaterial} placeholder="Sub-material" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                        <button className="btn btn-outline-success" type="button" id="button-addon2" onClick={() => {
                                            if (this.props.state.submaterial != '') {
                                                let sub_material = this.props.state.sub_materiais
                                                console.log('sub', this.props.state);
                                                sub_material.push({ "nome": this.props.state.submaterial });
                                                this.props.setState({
                                                    ...this.props.state,
                                                    sub_materiais: sub_material,
                                                    submaterial: '',
                                                });
                                            }
                                        }} ><MdAdd /> </button>
                                    </div>
                                </div>
                                {this.props.state.sub_materiais.length != 0 &&
                                    <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">Sub materiais selecionados:</label>
                                        {this.props.state.sub_materiais.map((item, index) => (
                                            <div key={index} className="col-sm-6 col-12 col-md-4 col-lg-3" style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                border: '1px solid gray',
                                                borderRadius: 10,
                                                marginLeft: 10,
                                                marginTop: 10
                                            }}>
                                                <span style={{ width: '120px' }} >{item.nome}</span>
                                                <button onClick={() => {
                                                    let submateriais_selecionados = this.props.state.sub_materiais;
                                                    delete (submateriais_selecionados[index]);
                                                    this.props.setState({ ...this.props.state, sub_materiais: submateriais_selecionados });
                                                }}
                                                    className="btn btn-danger btn-outline btn-circle me-1" style={{ background: 'white', marginTop: '4px', border: '0px solid red' }}><FaTrash color='red' /></button>
                                            </div>
                                        ))}
                                    </div>}

                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <label className="col-form-label">Tipo da medida principal <b style={{ color: 'red' }}>*</b></label>
                                                <select value={this.props.state.tipo_medida_principal} className="form-control" id="cameras" onChange={(e) => {
                                                    this.props.setState({ ...this.props.state, tipo_medida_principal: e.target.value, medida_principal: '', medida_secundaria: '' })
                                                }}>
                                                    <option value={''}>Selecione um tipo para a medida principal</option>
                                                    <option value={'Peso'}>Peso</option>
                                                    <option value={'Volume'}>Volume</option>
                                                </select>
                                            </div>

                                            <div className="col-md-6 col-12 ">
                                                <label className="col-form-label">Medida principal <b style={{ color: 'red' }}>*</b></label>
                                                <select value={this.props.state.medida_principal} className="form-control" id="cameras" onChange={(e) => {
                                                    this.props.setState({ ...this.props.state, medida_principal: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione a medida principal</option>
                                                    {this.props.state.tipo_medida_principal == 'Peso' && this.props.state.unidades_peso.map((item, id) => (
                                                        <option key={id} value={item.id}>{item.nome} - {item.sigla}</option>
                                                    ))}

                                                    {this.props.state.tipo_medida_principal == 'Volume' && this.props.state.unidades_volume.map((item, id) => (
                                                        <option key={id} value={item.id}>{item.nome} - {item.sigla}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="col-md-6 col-12">
                                                <label className="col-form-label">Em {this.props.state.tipo_medida_principal == 'Peso' ? 'volume' : 'peso'}, corresponde à (equivalência): <b style={{ color: 'red' }}>*</b></label>
                                                <NumericFormat type='text' className="form-control"
                                                    valueIsNumericString
                                                    thousandsGroupStyle='thousand'
                                                    thousandSeparator="."
                                                    decimalScale={2}
                                                    decimalSeparator=","
                                                    placeholder={"Equivalência em " + (this.props.state.tipo_medida_principal == 'Peso' ? 'volume' : 'peso')}
                                                    onValueChange={(values, sourceInfo) => {
                                                        this.props.setState({ ...this.props.state, equivalencia: values.value })
                                                    }}
                                                    value={this.props.state.equivalencia} />
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Descrição <b style={{ color: 'red' }}></b></label>
                                                <div className="col-sm-12">
                                                    <textarea className="form-control" placeholder="Descrição do resíduo/rejeito" type="text" onChange={(e) => this.props.setState({ ...this.props.state, descricao: e.target.value })} value={this.props.state.descricao} disabled></textarea>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12" style={{ alignSelf: 'flex-end' }}>
                                                <label className="col-form-label">Medida secundária ({this.props.state.tipo_medida_principal == 'Peso' ? 'volume' : 'peso'}): <b style={{ color: 'red' }}>*</b></label>
                                                <select value={this.props.state.medida_secundaria} className="form-control" id="cameras" onChange={(e) => {
                                                    this.props.setState({ ...this.props.state, medida_secundaria: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione a medida secundária</option>
                                                    {this.props.state.tipo_medida_principal == 'Volume' && this.props.state.unidades_peso.map((item, id) => (
                                                        <option key={id} value={item.id}>{item.nome} - {item.sigla}</option>
                                                    ))}

                                                    {this.props.state.tipo_medida_principal == 'Peso' && this.props.state.unidades_volume.map((item, id) => (
                                                        <option key={id} value={item.id}>{item.nome} - {item.sigla}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Requer autorização de movimentação? <b style={{ color: 'red' }}>*</b></label>
                                    <div className="col-sm-12">
                                        <select value={this.props.state.autorizacao} className="form-control" id="cameras" onChange={(e) => {
                                            this.props.setState({ ...this.props.state, autorizacao: e.target.value })
                                        }}>
                                            <option value={''}>Selecione</option>
                                            <option value={'Sim'}>Sim</option>
                                            <option value={'Não'}>Não</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Tipo de movimentação financeira <b style={{ color: 'red' }}>*</b></label>
                                    <div className="col-sm-12">
                                        <select value={this.props.state.tipo_movimentacao_financeira} className="form-control" id="cameras" onChange={(e) => {
                                            this.props.setState({ ...this.props.state, tipo_movimentacao_financeira: e.target.value })
                                        }}>
                                            <option value={''}>Selecione o tipo de movimentação financeira</option>
                                            <option value={'Receita'}>Receita</option>
                                            <option value={'Despesa'}>Despesa</option>
                                            <option value={'Despesa Evitada'}>Despesa Evitada</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 col-form-label">Valor de saldo financeiro estimado <b style={{ color: 'red' }}>*</b></label>
                                    <div className="col-sm-12">
                                        <NumericFormat className="form-control" placeholder="Valor de saldo financeiro estimado"
                                            type="text"
                                            prefix={'R$ '}
                                            thousandsGroupStyle='thousand'
                                            thousandSeparator="."
                                            decimalScale={2}
                                            decimalSeparator=","
                                            valueIsNumericString
                                            onValueChange={(values, e) => this.props.setState({ ...this.props.state, valor_saldo: values.value })}
                                            value={this.props.state.valor_saldo} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                    {this.state.loading && <div className="row">
                  {' '}
                  <div
                    className="col-12 d-flex justify-content-center align-items-center"
                    style={{ marginTop: '2rem' }}
                  >
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    ></div>
                  </div>
                </div>}
                </div>
            </div>
        );
    }
}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(CriarRR);


