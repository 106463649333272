import React, { Component } from "react";
import { MdSearch, MdVisibility } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import Tooltip from "react-tooltip";

import { logout } from "../../../actions/AppActions";
import { maskDate, maskMoneyV2, maskNumberBr } from "../../../Auxiliar/Masks";
import { GET } from "../../../Auxiliar/Requests";
import { NAME, URL } from "../../../variables";
import { handleStatus } from "../../../Auxiliar/BlueenOpen";

class Agendamentos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: true,
      path: "/agendamentos",
      loading_screen: false, //alterar para true
      page: 1,
      total_pages: 1,
      msgErro: "",
      search: "",
      dateOf: "",
      dateFor: "",
      reload: false,
      related: [],
      active_loading: [],
      status: [],
      collect_solicitations: []
    };
    this.timeout = null
  }

  componentDidMount() {
    this.getData(this.state.page)
  }

  componentDidUpdate() {
    if (this.props.allow) this.getData(this.state.page)
  }

  async getData(page) {
    this.props.setAllow(false)
    this.setState({ loading: true })

    const status = this.props.status ? this.props.status.int : 1
    const response = await GET({
      url: `auth/blueen/solicitation/index?status=${status}&dateFor=${this.props.dateFor}
      &dateOf=${this.props.dateOf}&connection=${NAME}&page=${page}`, blueenOpen: true
    })

    this.setState({ collect_solicitations: response.collect_solicitations, total_pages: response.pagination.last_page, loading: false })
  }

  handleSearch({ value }) {
    clearTimeout(this.timeout);
    this.setState({ search: value })
    this.timeout = setTimeout(() => this.props.setAllow(true), 750);
  }

  render() {
    return (
      <>
        {this.state.redirect === true && <Redirect to={this.state.path} />}
        <div>
          <div className="table-rep-plugin">
            <div
              className="table-responsive mb-0"
              data-pattern="priority-columns"
            >
              {!this.state.loading ?
                <table
                  id="tech-companies-1"
                  className="table table-striped table-hover"
                  style={{ textAlign: "center" }}
                >
                  <thead>
                    <tr>
                      <th>Data de Criação</th>
                      <th>Peso Total (kg)</th>
                      <th>Saldo (R$)</th>
                      <th>Qtd. de Resíduos</th>
                      <th>Destino</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  {/* dados da lista ----------------------------------- */}
                  <tbody>
                    {this.state.collect_solicitations.map(item => (
                      <tr key={item.blueen_id}>
                        <td>{item.created_date}</td>
                        <td>{maskNumberBr(item.total_volume)}</td>
                        <td>{maskMoneyV2(item.balance)}</td>
                        <td>{item.count_waste_rejects}</td>
                        <td>{item.responsible}</td>
                        <td>{handleStatus(item.status)}</td>
                        {item.action_url &&<td>
                          <Link to={item.action_url}>
                            <span
                              className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                              data-tip="Ver"
                            >
                              <Tooltip />
                              <MdVisibility style={{ color: "white" }} />
                            </span>
                          </Link>
                        </td>}
                      </tr>
                    ))}
                  </tbody>
                </table>
                :
                <div className="d-flex justify-content-center my-3">
                  <div className="spinner-border text-primary" role="status"></div>
                </div>
              }
            </div>
          </div>

          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <ReactPaginate
                previousLabel={"Anterior"}
                nextLabel={"Próxima"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={this.handlePageClick.bind(this)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                initialPage={this.state.page - 1}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
  handlePageClick(page) {
    if (page.selected + 1 !== this.state.page) {
      this.setState({ page: page.selected + 1 });
      this.getData(page.selected + 1)
    }
  }
}

const mapsStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(Agendamentos);
