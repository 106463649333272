import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Home from "./Home/Home";
import Login from "./Auth/Login";
import { useSelector } from 'react-redux'
import NewPassword from "./Auth/NewPassword/NewPassword";
import AgendarSolicitacao from "./Home/ColetaTrasporte/SolicitarColeta/AgendarSolicitacao/AgendarSolicitacao";


const PrivateRoute = ({ component: Component, ...rest }) => {
    // const stream = useSelector(state => state.AppReducer.stream);
    const token = useSelector(state => state.AppReducer.token)

    return (
        <Route
            {...rest}
            render={props =>
                token != null ? (
                    <Component {...props}>
                    </Component>
                ) : (
                    <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
                )
            }
        />)
};

const LoginRoute = ({ component: Component, ...rest }) => {
    const token = useSelector(state => state.AppReducer.token)
    return (
        <Route
            {...rest}
            render={props =>
                token == null ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: "/", state: { from: props.location } }} />
                )
            }
        >

        </Route>
    );
};

const OtherRoute = ({ component: Component, path_name, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
        }
    />
);

const Routes = () => {
    const token = useSelector(state => state.AppReducer.token)
    return (
        <BrowserRouter>
            <Switch>
                <LoginRoute path="/login" component={(props) => <Login {...props} />} />
                <Route path="/novasenha" component={(props) => <NewPassword {...props} />} />
                <Route path="/agendarsolicitacao" component={(props) => <AgendarSolicitacao {...props} />} />
                <PrivateRoute path="/" component={(props) => <Home {...props} />} />

                <OtherRoute path="*" path_name='/' />
            </Switch>
        </BrowserRouter>
    );
};

export default Routes;