import React from 'react';
import { MdDelete } from 'react-icons/md';
import ViewMtr from './ViewMtr';
import ReactTooltip from 'react-tooltip';

const ActiveMtrs = (props) => {

  return (
    <div className="col-12 mt-2">
      <table className='table table-striped table-hover text-center'>
        <thead>
          <tr>
            <td>Manifesto</td>
            <td>Destinador</td>
            <td>Transportador</td>
            <td>Data de Expedição</td>
            <td>Código de Destinação</td>
            <td>Ações</td>
          </tr>
        </thead>
        {props.state[props.type].length > 0 &&
          <tbody>
            {props.state[props.type].map(item => (
              <tr key={item.id}>
                <td>{item.codigo_manifesto}</td>
                <td>{item.destinacao[0].destino.fantasia}</td>
                <td>{item.destinacao[0].transportadora.nome_fantasia}</td>
                <td>{item.destinacao[0].data_criacao.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')}</td>
                <td>{item.destinacao[0]?.codigo_destinacao}</td>
                <td style={{ whiteSpace: 'nowrap' }}>
                  <ViewMtr getMtr={(e) => props.getMtr(e)} item={item} state={props.state} />

                  <span onClick={() => props.type === 'active' ? props.setState({ ...props.state, showWarningCancel: true, mtrToCancel: item.codigo_manifesto }) : props.setState({ ...props.state, showWarningDelete: true, mtrToDelete: item.id })} className="btn btn-danger btn-outline btn-circle me-1 mb-1"
                    data-tip="Cancelar"
                  ><ReactTooltip /><MdDelete style={{ color: 'white' }} /></span>
                </td>
              </tr>
            )
            )}
          </tbody>}
      </table >
      {props.state[props.type].length === 0 &&
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <p>Sem registros no momento</p>
          </div>
        </div>}
    </div >
  )
}

export default ActiveMtrs