import { CircularProgress } from "@mui/material";
import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { BsCheckLg, BsXLg } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { MdDelete, MdModeEdit, MdSearch, MdVisibility } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import Tooltip from "react-tooltip";

import { logout } from "../../../actions/AppActions";
import { NAME, URL, URLopen } from "../../../variables";

class RelacionamentosExternos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: true,
      path: "/relacionamentos_externos",
      loading_screen: true,
      page: 1,
      total_pages: 1,
      msgErro: "",
      search: "",
      dateOf: "",
      dateFor: "",
      reload: false,
      related: [],
      active_loading: [],
      status: [],
      show_warning: false,
      show_erro: false,
      show_delete_success: false,
      relationship_to_delete: "",
    };
  }

  componentDidMount() {
    this.get_relationships(this.state.page);
  }

  componentDidUpdate() {
    if (this.props.search) {
      this.props.setSearch(false);
      this.get_relationships(this.state.page);
    }
  }

  get_relationships(page, search = "", busca = false) {
    if (this.state.loading_screen === false) {
      this.setState({ loading_screen: true });
    }
    fetch(
      `${URLopen}api/auth/blueen/relationship/index?page=${page}&search=${search}&dateOf=${this.props.dateOf}&dateFor=${this.props.dateFor}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          connection: NAME,
        }),
      }
    )
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            console.log(erro);
            this.setState({ loading_screen: false });
          } else {
            this.setState({
              loading_screen: false,
              related: resp.users,
              page: resp.pagination.current_page,
              total_pages: resp.pagination.last_page,
            });
          }
        } catch (err) {
          this.setState({ loading_screen: false });
          console.log(err);
        }
      })
      .catch((err) => {
        this.setState({ loading_screen: false });
        console.log(err);
      });
  }

  changeStatus(id, status) {
    return
    //OLD VERSION \/

    // this.changeLoading(id, true);
    // fetch(`${URLopen}api/auth/update_status_relationship`, {
    //   method: "PATCH",
    //   headers: {
    //     Accept: "application/json",
    //     // Authorization: `Bearer ${this.props.token}`,
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({ status: status, id: id }),
    // }).then(async (responseLog) => {
    //   let resp = await responseLog.json();
    //   let related = this.state.related.map((item) => {
    //     if (item.id == resp.user.blueen_open_user_id)
    //       item.status = resp.user.status;
    //     return item;
    //   });
    //   this.setState({ related });

    //   this.changeLoading(id, false);
    // });
  }

  changeLoading(id, bool) {
    let active_loading = { ...this.state.active_loading };
    active_loading[id] = bool;
    this.setState({ active_loading });
  }

  delete_relationship(id) {
    this.setState({ loading_screen: true });
    fetch(`${URLopen}api/auth/blueen/relationship/destroy`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: id,
        connection: NAME,
        organization_user_id: this.props.user.id,
        organization_user_nome: this.props.user.name,
        organization_user_email: this.props.user.email,
      }),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.message == "Unauthenticated.") {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            this.props.logout();
            return;
          }
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = "";
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + "\n";
              else erro += errors[i];
            }
            console.log(erro);
            this.setState({
              redirect: true,
              loading_screen: false,
              show_warning: false,
              show_erro: true,
              msgErro: erro,
            });
          } else {
            this.setState({
              loading_screen: false,
              show_warning: false,
              show_delete_success: true,
            });
            this.get_relationships(this.state.page);
          }
        } catch (err) {
          this.setState({
            redirect: true,
            loading_screen: false,
            show_warning: false,
            show_erro: true,
            msgErro: "Erro ao pegar resposta do servidor.",
          });
          console.log(err);
        }
      })
      .catch((err) => {
        this.setState({
          redirect: true,
          loading_screen: false,
          show_warning: false,
          show_erro: true,
          msgErro:
            "Erro ao pegar resposta do servidor. Você está conectado a internet?",
        });
        console.log(err);
      });
  }

  render() {
    return (
      <>
        {this.state.redirect == true && <Redirect to={this.state.path} />}
        {this.state.loading_screen == false && (
          <div>
            <div className="row mb-2">
              <div className="col-12 col-md-6">
                <form
                  className="app-search mt-1"
                  style={{ width: "100%" }}
                  onSubmit={(e) => {
                    clearTimeout(this.pesquisarTimeout);
                    this.setState({ page: 1 });
                    this.get_relationships(1, this.state.search, true);
                    e.preventDefault();
                  }}
                >
                  <div
                    className="form-group mb-0"
                    style={{ width: "100%", position: "relative" }}
                  >
                    <input
                      value={this.state.search}
                      name="search"
                      placeholder="Pesquisar pelo nome, status ou tipo de relação (interna ou externa)..."
                      className="form-control"
                      style={{
                        width: "100%",
                        paddingRight: 40,
                        borderRadius: 100,
                      }}
                      onChange={(e) => {
                        console.log(this.props.dateOf);
                        clearTimeout(this.pesquisarTimeout);
                        let search = e.target.value;
                        this.setState({ page: 1, search: search });
                        this.pesquisarTimeout = setTimeout(() => {
                          this.get_relationships(1, search, true);
                        }, 500);
                      }}
                    />
                    <div
                      style={{
                        width: 40,
                        position: "absolute",
                        right: 0,
                        top: 0,
                        bottom: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MdSearch />
                    </div>
                  </div>
                </form>
              </div>

              <div className="col-12 col-md-6 d-flex justify-content-end">
                <Link to="/relacionamentos_externos/convidar">
                  <span className="btn btn-primary btn-lg waves-effect waves-light mt-1">
                    Criar Relacionamento
                  </span>
                </Link>
              </div>
            </div>
            <div className="table-rep-plugin">
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <table
                  id="tech-companies-1"
                  className="table table-striped table-hover"
                  style={{ textAlign: "center" }}
                >
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>E-mail</th>
                      <th>Status</th>
                      <th>Tipo usuário</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  {/* dados da lista ----------------------------------- */}

                  <tbody>
                    {this.state.related.map((item, id) => (
                      <tr key={id}>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.status}</td>
                        <td>{item.relation}</td>

                        <td>
                          <Link
                            to={"/relacionamentos_externos/view/" + item.id}
                          >
                            <span
                              className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                              data-tip="Ver"
                            >
                              <Tooltip />
                              <MdVisibility style={{ color: "white" }} />
                            </span>
                          </Link>
                          {item.status == "Pendente" && (
                            <span
                              onClick={() =>
                                this.setState({
                                  show_warning: true,
                                  relationship_to_delete: item.id,
                                })
                              }
                              className="destroy_it btn btn-danger btn-outline btn-circle me-1 mb-1"
                              data-tip="Excluir"
                            >
                              <Tooltip />
                              <MdDelete />
                            </span>
                          )}
                          {item.status == "Ativo" && (
                            <button
                              disabled
                              className="btn btn-secondary btn-outline btn-circle me-1 mb-1"
                              data-tip="Inativar"
                              onClick={() => this.changeStatus(item.id, true)}
                            >
                              {this.state.active_loading[item.id] ? (
                                <CircularProgress color="inherit" size={16} />
                              ) : (
                                <BsXLg />
                              )}
                            </button>
                          )}
                          {item.status == "Inativo" && (
                            <button
                              className="btn btn-secondary btn-outline btn-circle me-1 mb-1"
                              data-tip="Ativar"
                              onClick={() => this.changeStatus(item.id, false)}
                            >
                              {this.state.active_loading[item.id] ? (
                                <CircularProgress color="inherit" size={16} />
                              ) : (
                                <BsCheckLg />
                              )}
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {this.state.related.length == 0 && (
              <div className="row w-100">
                <div className="col-12">
                  <p style={{ textAlign: "center" }}>
                    Nenhum relacionamento encontrado...
                  </p>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12 d-flex justify-content-end">
                <ReactPaginate
                  previousLabel={"Anterior"}
                  nextLabel={"Próxima"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.total_pages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick.bind(this)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  initialPage={this.state.page - 1}
                />
              </div>
            </div>
          </div>
        )}

        {
          //loading
          this.state.loading_screen == true && (
            <div className="row">
              {" "}
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ marginTop: "2rem" }}
              >
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              </div>
            </div>
          )
        }
        <SweetAlert
          warning
          title={"Atenção"}
          onConfirm={() => {
            this.delete_relationship(this.state.relationship_to_delete);
          }}
          onCancel={() => {
            this.setState({ show_warning: false });
          }}
          show={this.state.show_warning}
          confirmBtnText="Sim, desejo apagar"
          cancelBtnText="Cancelar"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          showCancel={true}
        >
          Deseja mesmo deletar esse relacionamento? Essa ação é irreversível.
        </SweetAlert>
        <SweetAlert
          danger
          title={"Erro"}
          onConfirm={() => {
            this.setState({ show_erro: false });
          }}
          show={this.state.show_erro}
          confirmBtnText="OK"
          confirmBtnBsStyle="primary"
        >
          {this.state.msgErro}
        </SweetAlert>
        <SweetAlert
          success
          title={"Sucesso"}
          onConfirm={() => {
            this.setState({ show_delete_success: false });
          }}
          show={this.state.show_delete_success}
          confirmBtnText="OK"
          confirmBtnBsStyle="primary"
        >
          O relacionamento foi excluído com sucesso!
        </SweetAlert>
      </>
    );
  }
  handlePageClick(page) {
    if (page.selected + 1 != this.state.page) {
      this.setState({ page: page + 1 });
      this.get_relationships(page.selected + 1);
    }
  }
}

const mapsStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(RelacionamentosExternos);
