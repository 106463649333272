import React from "react";
import Agendamentos from "./Agendamentos";
import Filter from "../../../Auxiliar/Filter";

const SelecionarJsAgendamentos = () => {
  const [search, setSearch] = React.useState(false);
  const [dateOf, setDateOf] = React.useState("");
  const [dateFor, setDateFor] = React.useState("");
  const [options, setOptions] = React.useState({
    pending: { value: true, label: "Pendente", int: 1, checked: true },
    accepted: { value: false, label: "Aceito", int: 2, checked: false },
    finished: { value: false, label: "Finalizado", int: 3, checked: false },
    canceled: { value: false, label: "Cancelado", int: 0, checked: false },
  });

  const getStatus = () => {
    return Object.values(options).find((item) => item.value === true);
  };

  return (
    <div className="card">
      <div className="card-body" id="card">
        <h4
          className="mt-0 header-title"
          style={{ fontSize: "1.5rem", color: "black" }}
        >
          Agendamentos
        </h4>
        <Filter
          setDateOf={setDateOf}
          setDateFor={setDateFor}
          options={options}
          setOptions={setOptions}
          setSearch={setSearch}
        />
        <div>
          <p style={{ fontSize: "1rem", color: "black" }}>
            Aqui são listadas todas as destinações internas que estão
            interagindo com a Blueen Open.
          </p>
        </div>
        <div className="row">
          <Agendamentos
            dateOf={dateOf}
            dateFor={dateFor}
            allow={search}
            setAllow={setSearch}
            status={getStatus()}
          />
        </div>
      </div>
    </div>
  );
};

export default SelecionarJsAgendamentos;
