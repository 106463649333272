
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// Registros
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdSearch, MdVisibility } from 'react-icons/md';
import { maskNumberBr, maskPrice } from '../../../Auxiliar/Masks';
import { BsFillCircleFill } from 'react-icons/bs';

class DestinacaoGeral extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            Destinacao: {},
            dados_contato: [],
            page: 1,
            total_pages: 1,
            msgErro: '',
            show_warning: false,
            show_erro: false,
            destinacao_to_delete: '',
            search: ''

        };
    }

    componentDidMount() {
        this.get_Destinacao(this.state.page);
        this.props.setSearch(false)
    }

    componentDidUpdate(prevProps) {
        if (this.props.search) {
            this.props.setSearch(false)
            this.get_Destinacao(this.state.page);
        }

        if (prevProps.notifications !== this.props.notifications) {
            this.get_Destinacao(this.state.page);
            this.props.setSearch(false)
        }
    }


    get_Destinacao(page, search = '', busca = false) {
        // if (busca == true) {
        //     if (this.state.loading_search == false) {
        //         this.setState({ loading_search: true });
        //     }
        // }
        // else if (this.state.loading_screen === false) {
        this.setState({ loading_screen: true });
        // }
        fetch(`${URL}api/get_destinacao_interna?page=${page}&search=${search}&dateOf=${this.props.dateOf}&dateFor=${this.props.dateFor}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            }
        }).then(async (responseLog) => {
            try { 
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    this.setState({
                        Destinacao: resp.destinacao,
                        loading_screen: false,
                        page: resp.pagination.current_page,
                        total_pages: resp.pagination.last_page,
                        loading_search: false
                    });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false); 
            });
    }


    delete_destinacao_interna(id) {
        fetch(`${URL}api/delete_destinacao_interna/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true });
                }
                else {
                    this.setState({ show_warning: false });
                    this.get_Destinacao(this.state.page);
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        })
            .catch((err) => {
                this.setState({ redirect: true });
                console.log(err);
            });
    }


    render() {
        return (
            <>
                {this.state.redirect == true && <Redirect to={this.state.path} />}
                {this.state.loading_screen == false && <div>
                    <div className="row mb-2">
                        <div className="col-12 col-md-6">
                            <form className="app-search mt-1" style={{ width: '100%' }} onSubmit={(e) => {
                                this.setState({ page: 1 });
                                this.get_Destinacao(1, this.state.search, true)
                                e.preventDefault();
                            }}>
                                <div className="form-group mb-0" style={{ width: '100%', position: 'relative' }}>
                                    <input value={this.state.search} name="search" placeholder="Pesquisar pelo destino..." className="form-control" style={{ width: '100%', paddingRight: 40, borderRadius: 100 }} onChange={(e) => {
                                        clearTimeout(this.pesquisarTimeout);
                                        let search = e.target.value;
                                        this.setState({ page: 1, search: search });
                                        this.pesquisarTimeout = setTimeout(() => {
                                            this.get_Destinacao(1, search, true)
                                        }, 500);
                                    }} />
                                    <div style={{ width: 40, position: "absolute", right: 0, top: 0, bottom: 0, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                        <MdSearch />

                                    </div>

                                </div>
                            </form>
                        </div>

                        {this.props.user.tipo_usuario.permissoes['destinacao_interna'].escrita == true && <div className="col-12 col-md-6 d-flex justify-content-end">
                            <Link to="/destinacao_interna/criar"><span
                                className="btn btn-primary btn-lg waves-effect waves-light mt-1" >Adicionar destinação</span></Link>
                        </div>}
                    </div>
                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Destino</th>
                                        <th>Peso atendido</th>
                                        <th>Saldo (R$)</th>
                                        <th>Data de início</th>
                                        <th>Status</th>
                                        <th>Status MTR</th>
                                        <th data-priority="3">Ações</th>
                                    </tr>
                                </thead>
                                {/* dados da lista ----------------------------------- */}
                                <tbody>
                                    {this.state.loading_search == false && this.state.Destinacao.map((item, id) => (
                                        <tr key={id}>

                                            <td>{!item.destinacao_interna_intermediarias?.visualized ? <BsFillCircleFill size={10} className="mx-2" color="#f8333c" /> : ''}</td>
                                            <td>{item.destino.fantasia}</td>
                                            <td>{maskNumberBr(item.peso_total + '')} Kg</td>
                                            <td>R${maskNumberBr(parseFloat(item.saldo).toFixed(2))}</td>
                                            <td>{item.data_coleta != undefined && item.data_coleta != null ? item.data_coleta.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1') : 'Não iniciado'}</td>
                                            <td>{item.destinacao_interna_intermediarias?.situacao || ""}</td>
                                            <td>{item.status_mtr? item.status_mtr:""}</td>
                                            <td>
                                                {this.props.user.tipo_usuario.permissoes['destinacao_interna'].escrita == true && <Link to={'/destinacao_interna/view/' + item.id}><span className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                                                    data-tip="Ver"
                                                ><Tooltip /><MdVisibility style={{ color: 'white' }} /></span></Link>}
                                                {this.props.user.tipo_usuario.permissoes['destinacao_interna'].exclusao == true && item.destinacao_interna_intermediarias?.situacao!='Cancelada' &&  <button onClick={() => { this.setState({ show_warning: true, destinacao_to_delete: item.id }); }}
                                                    className="destroy_it btn btn-danger btn-outline btn-circle me-1 mb-1" data-tip="Excluir"><Tooltip /><MdDelete />
                                                </button>}
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>

                        </div>
                    </div>
                    {this.state.Destinacao.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma destinação cadastrada</p>

                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                            <ReactPaginate
                                previousLabel={'Anterior'}
                                nextLabel={'Próxima'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.total_pages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={this.handlePageClick.bind(this)}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                                initialPage={this.state.page - 1}
                            />
                        </div>
                    </div>



                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                </div>}

                {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                    </div>
                </div></div>}
                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => { this.delete_destinacao_interna(this.state.destinacao_to_delete); }}
                    onCancel={() => { this.setState({ show_warning: false }); }}
                    show={this.state.show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao deletar esta destinacao ela não estará mais disponível no sistema

                </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro"}
                    onConfirm={() => {
                        this.setState({ show_erro: false });
                    }}
                    show={this.state.show_erro}
                    confirmBtnText='OK'
                    confirmBtnBsStyle="primary"

                >
                    {this.state.msgErro}

                </SweetAlert>
            </>
        );
    }
    handlePageClick(page) {
        console.log(page.selected);
        if (page.selected + 1 != this.state.page) {
            this.setState({ page: page + 1 });
            this.get_Destinacao(page.selected + 1);

        }
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
        notifications: state.AppReducer.notifications,
    }
);

export default connect(mapsStateToProps, { logout })(DestinacaoGeral);
