import React from "react";
import { NAME } from "../../variables";
import SmoothModal from "./SmoothModal";
import { PATCH, POST } from "../../Auxiliar/Requests";
import SweetAlert from "react-bootstrap-sweetalert";
import { maskDate, moneyMask2 } from "../../Auxiliar/Masks";
import { Alert, Chip, CircularProgress } from "@mui/material";

export default function DadosFiscaisModal2({ data, invoiceType }) {
  const [msgError, setMsgError] = React.useState('')
  const [close, setClose] = React.useState(false)
  const [loadingSave, setLoadingSave] = React.useState(false)
  const [showWarning, setShowWarning] = React.useState(false)
  const [showWarning2, setShowWarning2] = React.useState(false)

  const [fiscalData, setFiscalData] = React.useState({
    chave: "",
    numero: "",
    serie: "",
    AnexoFiscal: null,
    NameAnexo: null,
  });

  //-------------------------*-------------------------
  const checkDestinoId = () => data.some(item => data.find(anotherItem => item.id !== anotherItem.id))

  //-------------------------*-------------------------
  const handleClose = () => {
    setMsgError('')
    setFiscalData({ ...fiscalData, chave: "", numero: "", serie: "", AnexoFiscal: "" })
  }

  //-------------------------*-------------------------
  const handleValidate = async () => {
    if (data.lenght === 0) {
      setShowWarning(true)
      return
    }

    if (checkDestinoId()) {
      setShowWarning2(true)
      return
    }

    handleSave()
  }

  //-------------------------*-------------------------
  const handleSave = async () => {
    setMsgError('')
    setShowWarning2(false)
    setLoadingSave(true)

    const ids = invoiceType === 'despacho' ? 'dispatches_ids' : 'collect_destinations_ids'
    const response = await POST({
      url: 'store_invoice', body: JSON.stringify({
        key: fiscalData.chave,
        serie: fiscalData.serie,
        number: fiscalData.numero,
        document: fiscalData.AnexoFiscal?.split(',')[1] ?? null,
        [ids]: data.map(item => item.id),
        connection: NAME,
      })
    })

    setLoadingSave(false)

    if (response.error) {
      setMsgError(response.message ?? response.error)
      return
    }

    setClose(!close)
  }

  //-------------------------*-------------------------
  const handleChange = ({ target }) => {
    const { name, value } = target

    setMsgError('')
    setFiscalData({ ...fiscalData, [name]: value })
  }

  //-------------------------*-------------------------
  const getFileBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFiscalData({ ...fiscalData, AnexoFiscal: reader.result, NameAnexo: file.name })
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  return (
    <SmoothModal
      close={close}
      buttonLabel='Atribuir Nota-Fiscal'
      onCloseCallback={handleClose}>
      {msgError && msgError.map(error => (
        <Alert key={error} className="mb-2" severity="error">{error}</Alert>)
      )}
      <section id="fiscal data">
        <label className="col-sm-12 col-form-label" style={{ color: "black", fontSize: "1.5rem" }}>
          Dados fiscais
        </label>
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">
            Chave da NF-e <b style={{ color: "red" }}>*</b>
          </label>
          <div className="col-sm-12">
            <input
              className="form-control"
              placeholder="Chave de acesso da nota fiscal"
              name='chave'
              value={fiscalData.chave}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">
            Número da NF-e <b style={{ color: "red" }}>*</b>
          </label>
          <div className="col-sm-12">
            <input
              className="form-control"
              placeholder="Número da nota fiscal"
              name='numero'
              value={fiscalData.numero}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-12 col-form-label">
            Série da NF-e <b style={{ color: "red" }}>*</b>
          </label>
          <div className="col-sm-12">
            <input
              className="form-control"
              placeholder="Série da nota fiscal"
              name='serie'
              value={fiscalData.serie}
              onChange={handleChange}
            />
          </div>
        </div>
        <label className="input-group mt-3" htmlFor='pdf-input' style={{ cursor: 'pointer' }}>
          <div className="input-group-prepend">
            <span className="input-group-text">Procurar...</span>
          </div>
          <div className="form-control" >
            Clique aqui para adicionar um anexo fiscal.
          </div>
        </label>
        <input
          id='pdf-input'
          className="d-none"
          type="file"
          accept="application/pdf,application/vnd.ms-excel"
          onChange={(e) => {
            getFileBase64(e.target.files[0]);
          }}
        />
        {fiscalData.AnexoFiscal && (
          <p className='mt-2'>
            Prévia do AnexoFiscal atual:{" "}
            <a href={fiscalData.AnexoFiscal} target='_blank'>{fiscalData.NameAnexo !== null ? fiscalData.NameAnexo : fiscalData.AnexoFiscal}</a>
          </p>
        )}
        <div className="col-12 d-flex justify-content-end mt-3" style={{ marginBottom: "0.5rem" }}>
          <button
            type="button"
            className="btn btn-success btn-lg waves-effect waves-light"
            disabled={loadingSave}
            onClick={handleValidate}
            style={{ minWidth: 212, minHeight: 48 }}>
            {loadingSave
              ? <div className="d-flex align-items-center justify-content-center">
                <CircularProgress color="text" size={25} />
              </div>
              : 'Salvar dados fiscais'}
          </button>
        </div>
      </section>
      <hr />
      <section>
        <div className="mt-3">
          <ul className="list-group list-group-light">
            {data.map((item, id) => (
              <li key={item.id} className="list-group-item d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="ms-3">
                    {item.collect_points.map(collectPoint => (
                      <p className="fw-bold mb-1" key={collectPoint.id + item.id}>{collectPoint.name}</p>
                    ))}
                    <p className="text-muted mb-0">
                      {item.quantity} |{" "}
                      {moneyMask2(item.balance)} |{" "}
                      {maskDate(item.created_at)}
                    </p>
                  </div>
                </div>
                <Chip
                  label={item.status}
                  color={item.status === 'Finalizada' ? "warning" : "info"}
                  size="small"
                />
              </li>
            ))}
          </ul>
        </div>

        <SweetAlert
          danger
          title={"Ops..."}
          onConfirm={() => {
            setShowWarning(false);
          }}
          show={showWarning}
          confirmBtnText="Entendi"
          confirmBtnBsStyle="primary"
        >
          Selecione pelo menos uma destinação para inserir seus dados fiscais
        </SweetAlert>

        <SweetAlert
          warning
          title={"Atenção"}
          onConfirm={() => {
            handleSave();
          }}
          onCancel={() => {
            setShowWarning2(false);
          }}
          show={showWarning2}
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="error"
        >
          Existem destinos diferentes selecionados para uma mesma nota fiscal,
          deseja continuar mesmo assim?
        </SweetAlert>

      </section>
    </SmoothModal>
  );
}
