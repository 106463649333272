import React from 'react'
import { maskCnpj } from '../../../Auxiliar/Masks'

const ComplementoSigor = (props) => {
  return (
    <div className="form-group  row">
      <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Dados Sigor:</label>

      <div className=" col-sm-12">
        <div className="col-md-12 col-12">
          <label className="col-sm-12 col-form-label">CNPJ <b style={{ color: 'red' }}>*</b></label>
          <div className="col-sm-12">
            <input className="form-control" id="numero" placeholder="CNPJ de acesso ao sigor" type="text" onChange={(e) => props.setState({ cnpjSigor: maskCnpj(e.target.value) })} value={props.state.cnpjSigor} />
          </div>  
        </div>
        <div className="row">
          <div className="col-md-8 col-12">
            <label className="col-sm-12 col-form-label">Unidade <b style={{ color: 'red' }}>*</b></label>
            <div className="col-sm-12">
              <input className="form-control" id="rua" placeholder="Unidade sigor" type="text" onChange={(e) => props.setState({ unidade_sigor: e.target.value })} value={props.state.unidade_sigor} />
            </div>
          </div>
          <div className="col-md-4 col-12">
            <label className="col-sm-12 col-form-label">Senha <b style={{ color: 'red' }}>*</b></label>
            <div className="col-sm-12">
              <input className="form-control" id="numero" placeholder="Senha de acesso sigor" type="text" onChange={(e) => props.setState({ senha_sigor: e.target.value })} value={props.state.senha_sigor} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComplementoSigor