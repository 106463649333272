
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';

import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdReply, MdVisibility } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import SweetAlert from 'react-bootstrap-sweetalert';
import Default from '../../../assets/images/default.png';

class ViewPessoa extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/despacho',
            loading_screen: true,
            pessoa: {},
            loading_save: false,
            msgErro: '',
            show_warning: false,
            destinos: [],
            transportadoras: [],
            estoques: [],
            pontos_coleta: [],
            responsavel_estoque: [],
            responsavel_ponto_coleta: [],
            registros_entrada_coleta: [],
            solicitar_coleta: [],
            modulos_visiveis: [],
            registros_entrada_interna: [],
            acesso: {}
        };
    }



    get_pessoa(id) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_pessoa_view/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {


                    this.setState({
                        pessoa: resp.pessoa,
                        loading_screen: false,
                        destinos: resp.destinos,
                        transportadoras: resp.transportadoras,
                        estoques: resp.estoques,
                        pontos_coleta: resp.pontos_coleta,
                        responsavel_estoque: resp.responsavel_estoque,
                        responsavel_ponto_coleta: resp.responsavel_ponto_coleta,
                        registros_entrada_coleta: resp.registros_entrada_coleta,
                        solicitar_coleta: resp.solicitar_coleta,
                        acesso: resp.modulos_visiveis,
                        registros_entrada_interna: resp.registros_entrada_interna,

                    });
                    console.log('pessoa', this.state.pessoa.tipo_usuario)

                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        }).catch((err) => {
            this.setState({ redirect: true });

            console.log(err);
        });
    }





    componentDidMount() {


        this.get_pessoa(this.props.match.params.id);
    }

    checkExists() {
        return this.state.registros_entrada_interna.length == 0 && this.state.destinos.length == 0 && this.state.transportadoras.length == 0 && this.state.responsavel_estoque.length == 0 && this.state.responsavel_ponto_coleta.length == 0 && this.state.solicitar_coleta.length == 0 && this.state.estoques.length == 0 && this.state.pontos_coleta.length == 0;
    }



    render() {

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Usuário</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Dados do Usuário</p>

                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">

                                        <div className="form-group row d-flex justify-content-center">
                                            <img style={{ height: 200, width: 200, objectFit: 'cover', border: '1px solid #f2f2f2', borderRadius: 100 }} src={this.state.pessoa.user_dado.imagem == null ? Default : URL + this.state.pessoa.user_dado.imagem} />
                                        </div>
                                        <hr />
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ fontSize: "17px" }}>Nome: <span style={{ fontWeight: 'normal' }}>{this.state.pessoa.name}</span></label>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ fontSize: "17px" }}>E-mail: <span style={{ fontWeight: 'normal' }}>{this.state.pessoa.email}</span></label>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ fontSize: "17px" }}>Tipo de Usuário: <span style={{ fontWeight: 'normal' }}>{this.state.pessoa.user_dado.acesso ? this.state.pessoa.tipo_usuario.nome : 'Não possui acesso ao sistema'}</span></label>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ fontSize: "17px" }}>Telefone 1: <span style={{ fontWeight: 'normal' }}>{this.state.pessoa.user_dado.telefone ?? '-'}</span></label>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ fontSize: "17px" }}>Telefone 2: <span style={{ fontWeight: 'normal' }}>{this.state.pessoa.user_dado.segundo_telefone ?? '-'}</span></label>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" style={{ fontSize: "17px" }}>CPF: <span style={{ fontWeight: 'normal' }}>{this.state.pessoa.user_dado.cpf ?? '-'}</span></label>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12 col-md-6 col-lg-4">

                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label" >Usuário pode ser Contato de um Destino? <b style={{ color: 'red' }}></b></label>

                                                    <div className="col-sm-12 d-flex align-items-center">
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked" style={{ marginRight: 10 }}>Não</label>

                                                        <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={this.state.acesso['ContatoDestino']} readOnly />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Sim</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-4">

                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label" >Usuário pode ser Responsável por um Ponto de Coleta? <b style={{ color: 'red' }}></b></label>

                                                    <div className="col-sm-12 d-flex align-items-center">
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked" style={{ marginRight: 10 }}>Não</label>

                                                        <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={this.state.acesso['ResponsavelPontoColeta']} readOnly />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Sim</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-4">

                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label" >Usuário pode ser uma Pessoa Autorizada em um Ponto de Coleta? <b style={{ color: 'red' }}></b></label>

                                                    <div className="col-sm-12 d-flex align-items-center">
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked" style={{ marginRight: 10 }}>Não</label>

                                                        <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={this.state.acesso['PessoaAutorizadaPontoColeta']} readOnly />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Sim</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-4">

                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label" >Usuário pode ser Responsável por um Estoque? <b style={{ color: 'red' }}></b></label>

                                                    <div className="col-sm-12 d-flex align-items-center">
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked" style={{ marginRight: 10 }}>Não</label>

                                                        <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={this.state.acesso['ResponsavelEstoque']} readOnly />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Sim</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-4">

                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label" >Usuário pode ser uma Pessoa Autorizada em um Estoque? <b style={{ color: 'red' }}></b></label>

                                                    <div className="col-sm-12 d-flex align-items-center">
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked" style={{ marginRight: 10 }}>Não</label>

                                                        <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={this.state.acesso['PessoaAutorizadaEstoque']} readOnly />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Sim</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-4">

                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label" >Usuário pode ser Responsável em uma Transportadora? <b style={{ color: 'red' }}></b></label>

                                                    <div className="col-sm-12 d-flex align-items-center">
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked" style={{ marginRight: 10 }}>Não</label>

                                                        <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={this.state.acesso['ResponsavelTransportadora']} readOnly />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Sim</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-4">

                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label" >Usuário pode ser Responsável em um Registro de Entrada (Coleta e Transporte)? <b style={{ color: 'red' }}></b></label>

                                                    <div className="col-sm-12 d-flex align-items-center">
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked" style={{ marginRight: 10 }}>Não</label>

                                                        <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={this.state.acesso['ResponsavelRegistroEntradaCT']} readOnly />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Sim</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-12 col-md-6 col-lg-4">

                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label" >Usuário pode ser Responsável em uma Solicitação de Coleta? <b style={{ color: 'red' }}></b></label>

                                                    <div className="col-sm-12 d-flex align-items-center">
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked" style={{ marginRight: 10 }}>Não</label>

                                                        <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={this.state.acesso['ResponsavelSolicitacaoColeta']} readOnly />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Sim</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 col-md-6 col-lg-4">

                                                <div className="form-group row">
                                                    <label className="col-12 col-form-label" >Usuário pode ser Responsável em um Registro de Entrada (Gestão Interna)? <b style={{ color: 'red' }}></b></label>

                                                    <div className="col-sm-12 d-flex align-items-center">
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked" style={{ marginRight: 10 }}>Não</label>

                                                        <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                                                            <input readOnly className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked={this.state.acesso['ResponsavelRegistroEntradaGI']} />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Sim</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <br />
                                        <hr />
                                        <br />

                                        <div className="table-rep-plugin ">
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }}>
                                                    <thead>
                                                        <tr>

                                                            <th>Tipo</th>
                                                            <th>Relacionamento</th>
                                                            <th>Identificação</th>

                                                            {/* <th data-priority="1">Telefone</th> */}

                                                            <th data-priority="3">Ações</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.destinos.map((item, id) => (

                                                            <tr key={id}>

                                                                <td>Destino</td>
                                                                <td>Contato</td>

                                                                <td>{item.fantasia}</td>

                                                                <td>
                                                                    <Link to={'/destinos/editar/' + item.id}><span className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                                                                        data-tip="Ver"
                                                                    ><Tooltip /><MdVisibility style={{ color: 'white' }} /></span></Link>

                                                                </td>

                                                            </tr>
                                                        ))}
                                                        {this.state.transportadoras.map((item, id) => (

                                                            <tr key={id}>

                                                                <td>Transportadora</td>
                                                                <td>Responsável</td>

                                                                <td>{item.nome_fantasia}</td>

                                                                <td>
                                                                    <Link to={'/transportadoras/editar/' + item.id}><span className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                                                                        data-tip="Ver"
                                                                    ><Tooltip /><MdVisibility style={{ color: 'white' }} /></span></Link>

                                                                </td>

                                                            </tr>
                                                        ))}

                                                        {this.state.responsavel_estoque.map((item, id) => (

                                                            <tr key={id}>

                                                                <td>Estoque</td>
                                                                <td>Responsável</td>

                                                                <td>{item.nome_fantasia}</td>

                                                                <td>
                                                                    <Link to={'/estoques/editar/' + item.id}><span className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                                                                        data-tip="Ver"
                                                                    ><Tooltip /><MdVisibility style={{ color: 'white' }} /></span></Link>

                                                                </td>

                                                            </tr>
                                                        ))}
                                                        {this.state.responsavel_ponto_coleta.map((item, id) => (

                                                            <tr key={id}>

                                                                <td>Ponto Coleta</td>
                                                                <td>Responsável</td>

                                                                <td>{item.nome_fantasia}</td>

                                                                <td>
                                                                    <Link to={'/pontos_coleta/editar/' + item.id}><span className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                                                                        data-tip="Ver"
                                                                    ><Tooltip /><MdVisibility style={{ color: 'white' }} /></span></Link>

                                                                </td>

                                                            </tr>
                                                        ))}

                                                        {this.state.registros_entrada_coleta.map((item, id) => (

                                                            <tr key={id}>

                                                                <td>Registro Entrada Coleta Transporte</td>
                                                                <td>Responsável</td>

                                                                <td>{item.pontos_coleta.nome_fantasia} - Entrada</td>

                                                                <td>
                                                                    <Link to={'/entrada_coleta/editar/' + item.id}><span className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                                                                        data-tip="Ver"
                                                                    ><Tooltip /><MdVisibility style={{ color: 'white' }} /></span></Link>

                                                                </td>

                                                            </tr>
                                                        ))}
                                                        {this.state.registros_entrada_interna.map((item, id) => (

                                                            <tr key={id}>

                                                                <td>Registro Entrada Gestão Interna</td>
                                                                <td>Responsável</td>

                                                                <td>{item.estoque.nome_fantasia} - Entrada</td>

                                                                <td>
                                                                    <Link to={'/registro_gestao_interna/editar/' + item.id}><span className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                                                                        data-tip="Ver"
                                                                    ><Tooltip /><MdVisibility style={{ color: 'white' }} /></span></Link>

                                                                </td>

                                                            </tr>
                                                        ))}

                                                        {this.state.solicitar_coleta.map((item, id) => (

                                                            <tr key={id}>

                                                                <td>Solicitação de Coleta</td>
                                                                <td>Responsável</td>

                                                                <td>{item.ponto_coleta.nome_fantasia} - Solicitação</td>

                                                                <td>
                                                                    <Link to={item.status == 'Em Andamento' ? '/solicitar_coleta/view/' + item.id : '/solicitar_coleta/editar/' + item.id}><span className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                                                                        data-tip="vrt"
                                                                    ><Tooltip /><MdVisibility style={{ color: 'white' }} /></span></Link>

                                                                </td>

                                                            </tr>
                                                        ))}


                                                        {this.state.estoques.map((item, id) => (

                                                            <tr key={id}>

                                                                <td>Estoque</td>
                                                                <td>Pessoa Autorizada</td>

                                                                <td>{item.nome_fantasia}</td>

                                                                <td>
                                                                    <Link to={'/estoques/editar/' + item.id}><span className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                                                                        data-tip="Ver"
                                                                    ><Tooltip /><MdVisibility style={{ color: 'white' }} /></span></Link>

                                                                </td>

                                                            </tr>
                                                        ))}
                                                        {this.state.pontos_coleta.map((item, id) => (

                                                            <tr key={id}>

                                                                <td>Ponto Coleta</td>
                                                                <td>Pessoa Autorizada</td>

                                                                <td>{item.nome_fantasia}</td>

                                                                <td>
                                                                    <Link to={'/pontos_coleta/editar/' + item.id}><span className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                                                                        data-tip="Ver"
                                                                    ><Tooltip /><MdVisibility style={{ color: 'white' }} /></span></Link>

                                                                </td>

                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>

                                        {this.checkExists() && <div className="row w-100">
                                            <div className="col-12">
                                                <p style={{ textAlign: 'center' }}>Nenhum relacionamento encontrado</p>

                                            </div>
                                        </div>}


                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/pessoas"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>


                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}
                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}

                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(ViewPessoa);


