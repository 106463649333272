import React, { Component, useCallback, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap";
import { withRouter, Redirect } from "react-router-dom";
import { logout } from "./actions/AppActions";
import { connect, useDispatch, useSelector } from "react-redux";

// users
import user4 from "./assets/images/default.png";
import { MdExitToApp, MdLockOutline } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { URL } from "./variables";
import { useHistory } from 'react-router-dom'
import { DCWebSocket } from "./actions/WebsocketActions";

const ProfileMenu = (props) => {
  const dispatch = useDispatch();
  const history = useHistory()
  const [menu, setMenu] = useState(false);
  const toggle = useCallback(() => {
    console.log("toogle");
    setMenu(!menu);
  }, [menu]);

  const user = useSelector((store) => store.AppReducer.user);
  const logout_ = useCallback(() => {

    try{
      DCWebSocket();
    }
    catch(err){
      console.log(err)
    }

    console.log("logout");

    localStorage.removeItem("token");
    localStorage.removeItem("user");

    dispatch(logout());
  }, []);

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
          <img className="rounded-circle header-profile-user" src={user.user_dado.imagem == null ? user4 : URL + user.user_dado.imagem} height="50" width={"50"} alt="Header Avatar" style={{ objectFit: "cover" }} />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem  onClick={() => history.push(`/pessoas/editar/${user.id}`)} style={{ cursor: "pointer", alignItems: "center", display: "flex" }}>
            <FaRegUser style={{ marginRight: 5 }} />Editar Perfil
          </DropdownItem>

          <DropdownItem  onClick={() => history.push(`/perfil/${user.id}`)} style={{ cursor: "pointer", alignItems: "center", display: "flex" }}>
            <MdLockOutline style={{ marginRight: 5 }} />Mudar senha
          </DropdownItem>
 
          <DropdownItem  onClick={logout_} style={{ cursor: "pointer", alignItems: "center", display: "flex" }}>
            <MdExitToApp style={{ marginRight: 5 }} />Sair
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(ProfileMenu);
