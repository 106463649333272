import React from "react";
import ConvidarExterno from "./ConvidarExterno";
import Filter from "../../../../Auxiliar/Filter";

import BlueenOpenModal from "../BlueenOpenModal";
import ConvidarExistente from "./ConvidarExistente";

const ConvidarPessoa = () => {
  const [search, setSearch] = React.useState(false);
  const [dateOf, setDateOf] = React.useState("");
  const [dateFor, setDateFor] = React.useState("");
  const [options, setOptions] = React.useState({
    convidarExterno: {
      value: false,
      label: "Convidar Externo",
      checked: false,
    },
    convidarInterno: {
      value: true,
      label: "Convidar Interno",
      checked: true,
    },
  });

  return (
    <div className="card">
      <div className="card-body" id="card">
        <div className="d-flex align-items-start">
          <h4
            className="mt-0 header-title"
            style={{ fontSize: "1.5rem", color: "black" }}
          >
            Cadastro de relacionamento
          </h4>
        </div>

        <div className="row ms-1 my-3 pb-3">
          <div className="form-check col-12 col-sm-4">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              checked={options.convidarInterno.checked}
              onChange={() =>
                setOptions({
                  ...options,
                  convidarExterno: { checked: false, value: false },
                  convidarInterno: { checked: true, value: true },
                })
              }
            />
            <label className="form-check-label" htmlFor="flexRadioDefault2">
              Relacionar com organização/usuário já conhecido
            </label>
          </div>
          <div className="form-check col-12 col-sm-4">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              disabled
              checked={options.convidarExterno.checked}
              onChange={() =>
                setOptions({
                  ...options,
                  convidarExterno: { checked: true, value: true },
                  convidarInterno: { checked: false, value: false },
                })
              }
            />
            <label className="form-check-label" htmlFor="flexRadioDefault1">
              Relacionar com organização/usuário desconhecido{" "}
            </label>
          </div>
        </div>

        {/* {options.convidarExterno.value && (
          <div>
            <p style={{ fontSize: "1rem", color: "black" }}>
              Preencha os dados abaixo para convidar via e-mail uma nova
              empresa. Essa empresa convidada terá acesso ao sistema Blueen
              Open,
              <BlueenOpenModal />
            </p>
          </div>
        )}
        {options.convidarInterno.value && (
          <div>
            <p style={{ fontSize: "1rem", color: "black" }}>
              Convide um usuário para a Blueen Open que já existe no ambiente
              padrão da Blueen.
            </p>
          </div>
        )} */}
        <div className="row">
          {options.convidarExterno.value && (
            <ConvidarExterno search={search} setSearch={setSearch} />
          )}
          {options.convidarInterno.value && (
            <ConvidarExistente search={search} setSearch={setSearch} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ConvidarPessoa;
