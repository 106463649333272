import React from 'react'
import { useSelector } from 'react-redux'
import { URL } from '../../../variables'
import UsuarioModal from '../UsuarioModal/UsuarioModal'

const ComplementoTransportadora = ({ form, setForm }) => {
  const [state, setState] = React.useState({
    responsaveis: [],
  })
  const token = useSelector(state => state.AppReducer.token)

  React.useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    fetch(`${URL}api/get_dados_transportadora`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      }
    }).then(async (responseLog) => {
      try {
        let resp = await responseLog.json();
        console.log(resp);
        if (resp.message == "Unauthenticated.") {
          localStorage.removeItem('token'); localStorage.removeItem('user');
          return;
        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);
          setState({ redirect: true });
        }
        else {
          setState({
            responsaveis: resp.responsaveis,
          });
          setForm({ ...form, screen_transportadora: true })
        }
      } catch (err) {
        setState({ redirect: true });
        console.log(err);
      }
    })
      .catch((err) => {
        setState({ redirect: true });

        console.log(err);
      });
  }


  return (
    <>
      {form.screen_transportadora && <div>
        <div className="form-group row">
          <h4 className="mt-5 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Transportadora</h4>
          <label className="col-sm-12 col-form-label">Responsável <b style={{ color: 'red' }}>*</b></label>
          <div className="col-sm-12">
            <div className="input-group">
              {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
              <select value={form.responsavel_id_transportadora} className="form-control" id="cameras" onChange={(e) => {
                setForm({ ...form, responsavel_id_transportadora: e.target.value })
              }}>
                <option value={''}>Selecione um responsável</option>

                {state.responsaveis.map((item, id) => (
                  <option key={id} value={item.id}>{item.name}</option>
                ))}

              </select>
              <UsuarioModal reload={getData} />
            </div>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-12 col-form-label">Licença <b style={{ color: 'red' }}></b></label>
          <div className="col-sm-12">
            <input className="form-control" placeholder="Licença" type="text" onChange={(e) => setForm({ ...form, licenca: e.target.value })} value={form.licenca} />

          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-12 col-form-label">Descrição</label>
          <div className="col-sm-12">
            <textarea className="form-control" placeholder="Descrição " type="text" onChange={(e) => setForm({ ...form, descricao: e.target.value })} value={form.descricao}></textarea>

          </div>
        </div>
      </div>}
    </>
  )
}

export default ComplementoTransportadora