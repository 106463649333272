import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import { BsPlusLg } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';

// users
import { URL } from '../../../variables';
import { MdReply } from 'react-icons/md';
import { maskNumber } from '../../../Auxiliar/Masks';
import { NumericFormat } from 'react-number-format';

class EditarUnidade extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/unidades',
            loading_screen: false,
            roles: [],
            nome: '',
            sigla: '',
            tipo: 'Peso',
            equivalencia: '',
            loading_save: false,
            msgErro: '',
            e_sigor: false,
            unidade_id: '',
            unidades: [],
            unidades_selecionadas: [],
        };
    }

    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/update_unidades`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                nome: this.state.nome,
                sigla: this.state.sigla,
                tipo: this.state.tipo,
                equivalencia: this.state.equivalencia,
                unidade_id: this.props.match.params.id,
                e_sigor: this.state.e_sigor,
                unidades_selecionadas: this.state.unidades_selecionadas,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i !== errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }
            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });
            }
        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_unidade(this.props.match.params.id);
        // this.fill_unidades();
    }

    get_unidade(id) {
        if (this.state.loading_screen === false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_unidade/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message === "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i !== errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({
                        loading_screen: false, tipo: resp.unidade.tipo,
                        nome: resp.unidade.nome,
                        sigla: resp.unidade.sigla,
                        equivalencia: resp.unidade.equivalencia,
                        e_sigor: resp.unidade.e_sigor,
                        unidade_id: id,
                        unidades_selecionadas: resp.unidade.unidades_sigor,
                        unidades: resp.unidades,
                    });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        })
            .catch((err) => {
                this.setState({ redirect: true });
                console.log(err);
            });
    }

    fill_unidades() {
        fetch(`${URL}api/fill_unidades_sigor`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.props.token}`
            }
        }).then(async (response) => {
            const resp = await response.json()
            console.log('teste2', this.state.unidades)
            this.setState({ unidades: resp.unidades, unidade_id: resp.unidades[0].id })
        })
    }

    add_unidade(id) {
        if (!this.state.unidades_selecionadas.filter(item => item.dados_unidade.id === id)[0]) {
            const unidade = this.state.unidades.filter(item => item.id === id)[0]
            console.log('u', unidade)

            const obj_unidade = {
                id: unidade.id,
                nome: unidade.nome,
                sigla: unidade.sigla
            }

            this.setState({ unidades_selecionadas: [...this.state.unidades_selecionadas, { dados_unidade: obj_unidade }] })
        }
    }

    remove_unidade(id) {
        const unidades_selecionadas = this.state.unidades_selecionadas.filter(item => item.dados_unidade.id !== id)
        this.setState({ unidades_selecionadas })
    }


    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }

    render() {
        console.log('unidade', this.state.unidades)
        return (
            <div className="row">
                {this.state.redirect === true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">

                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar Unidade</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um unidade do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {this.state.e_sigor == false && <input className="form-control" placeholder="Nome da unidade" type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} />}
                                                {this.state.e_sigor == true && <input className="form-control" placeholder="Nome da unidade" type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} disabled />}
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Sigla <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {this.state.e_sigor == false && <input className="form-control" placeholder="Sigla da unidade" type="text" onChange={(e) => this.setState({ sigla: e.target.value })} value={this.state.sigla} />}
                                                {this.state.e_sigor == true && <input className="form-control" placeholder="Sigla da unidade" type="text" onChange={(e) => this.setState({ sigla: e.target.value })} value={this.state.sigla} disabled />}
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Tipo <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.tipo} className="form-control" id="cameras" onChange={this.change_tipo.bind(this)}>
                                                    <option value={'Peso'}>{'Peso'}</option>
                                                    <option value={'Volume'}>{'Volume'}</option>
                                                    <option value={'Unidade'}>{'Unidade'}</option>

                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Em {this.state.tipo == 'Peso' ? 'quilogramas' : 'litros'}, corresponde à (equivalência): <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <NumericFormat type='text' className="form-control"
                                                    thousandsGroupStyle='thousand'
                                                    valueIsNumericString
                                                    thousandSeparator="."
                                                    decimalScale={2}
                                                    decimalSeparator=","
                                                    placeholder={"Equivalência em " + (this.state.tipo == 'Peso' ? 'Quilogramas' : 'Litros')}
                                                    onValueChange={(values, sourceInfo) => {
                                                        console.log(values)
                                                        this.setState({ equivalencia: values.value })
                                                    }}
                                                    value={this.state.equivalencia} />
                                            </div>
                                        </div>

                                        {this.state.e_sigor && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Unidades Relacionadas:</label>
                                            <div className="input-group col-sm-12">
                                                <select value={this.state.unidade_id} className="form-control" id="cameras" onChange={(e) => this.setState({ unidade_id: e.target.value })}>
                                                    {this.state.unidades.map(item => (
                                                        <>
                                                            {!item.relation &&
                                                                <option key={item.id} value={item.id}>{item.nome}</option>}
                                                        </>
                                                    ))}
                                                </select>
                                                <button className='btn btn-primary' onClick={() => this.add_unidade(this.state.unidade_id)}>
                                                    <BsPlusLg />
                                                </button>
                                            </div>
                                        </div>}

                                        {this.state.unidades_selecionadas.length > 0 &&
                                            <div className="row my-3">
                                                <div className="col-12">
                                                    <table className='table table-striped table-hover text-center'>
                                                        <thead>
                                                            <tr>
                                                                <th>Nome</th>
                                                                <th>Sigla</th>
                                                                <th>Ações</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.unidades_selecionadas.map(item2 => (
                                                                <tr key={item2.dados_unidade.id}>
                                                                    <td>{item2.dados_unidade.nome}</td>
                                                                    <td>{item2.dados_unidade.sigla}</td>
                                                                    <td><button className="btn btn-danger btn-small btn-circle m-r-5"
                                                                        onClick={() => this.remove_unidade(item2.dados_unidade.id)}>
                                                                        <FaTrash color='white' />
                                                                    </button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>}
                                    </div>
                                </div>

                                {this.state.loading_save === false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/unidades"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar unidade</button>
                                    </div>
                                </div>}
                                {this.state.loading_save === true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen === true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(EditarUnidade);


