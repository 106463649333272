import React from 'react'
import { URL } from '../../variables'
import { MdSave } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CircularProgress } from '@mui/material';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io'
import { renderToast, ToastContent } from '../../Auxiliar/Alerts'

const Perfil = () => {
 const [error, setError] = React.useState({ type: '', message: '' })
 const [loading, setLoading] = React.useState(false)
 const [password, setPassword] = React.useState({
  older: { value: '', visible: false, error: '' },
  newer: { value: '', visible: false, error: '' },
  confirm: { value: '', visible: false, error: '' },
 })
 const history = useHistory()
 const token = useSelector(state => state.AppReducer.token)

 let timeout
 React.useEffect(() => {
  clearTimeout(timeout)
  if (error.message) { renderToast({ type: error.type, error: error.message }); timeout = setTimeout(() => setError({ type: '', message: '' }), 1500) }
 }, [error.message])


 const handleSave = (e) => {
  e.preventDefault(); setLoading(true)

  if (!password.older.value) { setError({ type: 'error', message: 'Digite a sua senha atual para prosseguir!' }); setLoading(false) }
  else {
   if (password.newer.value === password.confirm.value) {
    fetch(`${URL}api/change_password`, {
     method: 'POST',
     headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
     },
     body: JSON.stringify({ password: password.older.value, new_password: password.newer.value })
    }).then(async (response) => {
     const resp = await response.json()
     if (resp) setLoading(false)
     console.log('resp', resp)

     if (resp.status) { setError({ type: 'success', message: resp.message }); setTimeout(() => history.push('/'), 1500) }
     else setError({ type: 'error', message: resp.message })
    })
   } else {
    setError({ type: 'error', message: 'Falha na validação, senhas diferentes!' }); setLoading(false)
   }
  }
 }

 const handleChange = (item, value) => { setPassword({ ...password, [item]: { ...password[item], error: '', value } }) }

 const changeVisibility = (item) => { setPassword({ ...password, [item]: { ...password[item], visible: !password[item].visible } }) }

 const verifyPassword = (item) => {
  if (Array.from(password[item].value).length < 6) {
   setPassword({ ...password, [item]: { ...password[item], error: 'Sua senha tem que ter ao menos 6 caracteres' } })
  }
 }


 return (
  <div className="card">
   <div className="card-body">
    <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar senha</h4>
    <p style={{ fontSize: '1rem', color: 'black' }}>Edite a sua senha de login</p>

    <form className="form-group row mt-4" onSubmit={(e) => { handleSave(e) }}>
     <label className="col-sm-12 col-form-label" style={{ color: 'black' }} htmlFor='older'>Senha atual<b style={{ color: 'red' }}>*</b></label>
     <div className="col-sm-12 input-group" >
      <input className={`form-control ${password.older.error && 'is-invalid'}`} placeholder="Senha" id='older' type={password.older.visible ? 'text' : 'password'} value={password.older.value} onChange={({ target }) => handleChange('older', target.value)} />
      <div className='bg-primary p-2 pointer rounded-end'>{password.older.visible
       ? <IoMdEyeOff onClick={() => changeVisibility('older')} size={20} color='#FFF' />
       : <IoMdEye onClick={() => changeVisibility('older')} size={20} color='#FFF' />}
      </div>
     </div>

     <label className="col-sm-12 col-form-label mt-5" style={{ color: 'black' }} htmlFor='newer'>Nova senha<b style={{ color: 'red' }}>*</b></label>
     <div className="col-sm-12 input-group">
      <input className={`form-control ${password.newer.error && 'is-invalid'}`} id='newer' placeholder="Senha" type={password.newer.visible ? 'text' : 'password'} value={password.newer.value}
       onChange={({ target }) => handleChange('newer', target.value)} onBlur={() => verifyPassword('newer')} />

      <div className='bg-primary p-2 pointer rounded-end'>{password.newer.visible
       ? <IoMdEyeOff onClick={() => changeVisibility('newer')} size={20} color='#FFF' />
       : <IoMdEye onClick={() => changeVisibility('newer')} size={20} color='#FFF' />}
      </div>
      <div className="invalid-feedback">{password.newer.error}</div>
     </div>

     <label className="col-sm-12 col-form-label" style={{ color: 'black' }} htmlFor='confirm'>Confirmar senha<b style={{ color: 'red' }}>*</b></label>
     <div className="col-sm-12 input-group">
      <input className={`form-control ${password.confirm.error && 'is-invalid'}`} id='confirm' placeholder="Senha" type={password.confirm.visible ? 'text' : 'password'} value={password.confirm.value}
       onChange={({ target }) => handleChange('confirm', target.value)} onBlur={() => verifyPassword('confirm')} />

      <div className='bg-primary p-2 pointer rounded-end'>{password.confirm.visible
       ? <IoMdEyeOff onClick={() => changeVisibility('confirm')} size={20} color='#FFF' />
       : <IoMdEye onClick={() => changeVisibility('confirm')} size={20} color='#FFF' />}
      </div>
      <div className="invalid-feedback">{password.confirm.error}</div>
     </div>

     <div className="d-flex">
      <button type="submit" className="btn btn-success mt-3 waves-effect waves-light ms-auto"
       disabled={(loading ? true : false) || ((!password.newer.value || password.newer.error) || (!password.confirm.value || password.confirm.error))}>
       Salvar {loading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : <MdSave size={18} />}
      </button>
     </div>
    </form>
   </div>
   <ToastContent />
  </div>
 )
}

export default Perfil