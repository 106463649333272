
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import { maskCep, maskCnpj, maskNumber, maskNumberBr, maskPrice } from '../../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdReply } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import SweetAlert from 'react-bootstrap-sweetalert';
import Destinacao from './Destinacao';

class ViewDestinacaoColetaFinalizada extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/destinacao',
            loading_screen: false,
            ResiduosRejeitos: [],
            Destinos: [],
            DestinoSelecionado: [],
            Solicitacoes: [],
            SolicitacoesSelecionadas: [],
            MateriaisSolicitacao: [],
            Destinacoes: [],
            Tratamentos: [],
            MateriaisInseridos: [],
            Transportadoras: [],
            Veiculos: [],
            Motoristas: [],

            Materialselecionado: {},
            SolicitacaoSelecionada: {},

            ChaveNota: '',
            NumeroNota: '',
            SerieNota: '',
            TransportadoraId: '',
            DestinacaoId: '',
            Situacao: 'Rascunho',
            Descricao: '',
            Tratamento: '',
            ValorFinanceiro: '',
            Movimentacao: '',
            Peso: '',
            Volume: '',
            MaterialSolicitacao: '',
            Solicitacao: '',
            Destino: '',
            DataAtual: '',
            DataSolicitacao: '',
            DataAgendamento: '',
            DataInicio: '',
            DataFinalizado: '',
            DataCriacao: '',
            PesoTotal: '0',
            VolumeTotal: '0',
            ReceitaTotal: '0.00',
            DespesaTotal: '0.00',
            Saldo: '0.00',
            MotoristaId: '',
            VeiculoId: '',
            loading_save: false,
            msgErro: '',
            show_warning: false,

        };
    }


    get_data(id) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_view_destinacao_finalizada/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    console.log('resp', resp)

                    this.setState({
                        loading_screen: false,
                        Destinos: resp.destinos,
                        Solicitacoes: resp.solicitacoes,
                        Destinacoes: resp.destinacoes,
                        ResiduosRejeitos: resp.materiais,
                        Tratamentos: resp.tratamentos,
                        Transportadoras: resp.transportadoras,
                        Veiculos: resp.veiculos,
                        Motoristas: resp.motoristas,

                        // TransportadoraId: resp.destinacao.transportadora.nome_fantasia,
                        TransportadoraNome: resp.destinacao.transportadora != null ? resp.destinacao.transportadora.nome_fantasia : 'Carro próprio',

                        Situacao: resp.destinacao.situacao,
                        Descricao: resp.destinacao.descricao != null ? resp.destinacao.descricao : '',
                        Solicitacao: resp.destinacao.solicitacao_id,
                        Destino: resp.destinacao.destino.fantasia,
                        DataSolicitacao: resp.destinacao.data_solicitacao,
                        DataAgendamento: resp.destinacao.data_agendamento != null ? resp.destinacao.data_agendamento : '',
                        DataInicio: resp.destinacao.data_inicio != null ? resp.destinacao.data_inicio : 'Finzalizado sem iniciar',
                        PesoTotal: resp.destinacao.peso_total,
                        VolumeTotal: resp.destinacao.volume_total,
                        ReceitaTotal: resp.destinacao.receita_total,
                        DespesaTotal: resp.destinacao.despesa_total,
                        Saldo: resp.destinacao.saldo,
                        veiculos_motoristas: resp.destinacao.veiculos_motoristas,
                        MateriaisInseridos: resp.destinacao.materiais,
                        ChaveNota: resp.destinacao.chave_nota != null ? resp.destinacao.chave_nota : '',
                        NumeroNota: resp.destinacao.numero_nota != null ? resp.destinacao.numero_nota : '',
                        SerieNota: resp.destinacao.serie_nota != null ? resp.destinacao.serie_nota : '',
                        SolicitacaoSelecionada: [resp.destinacao.solicitacao_coleta],
                        DestinoSelecionado: resp.destinacao.destino,
                        DataCriacao: resp.destinacao.data_criacao,
                    });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }
        }).catch((err) => {
            this.setState({ redirect: true });

            console.log(err);
        });
    }


    finalizar() {

        fetch(`${URL}api/finalizar_destinacao`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                destinacao_coleta_id: this.props.match.params.id,
                solicitacao_id: this.state.Solicitacao,
                destino_id: this.state.Destino,
                data_criacao: this.state.DataAtual,
                data_solicitacao: this.state.DataSolicitacao,
                data_agendamento: this.state.DataAgendamento,
                data_inicio: this.state.DataInicio,
                data_finalizado: this.state.DataFinalizado,
                peso_total: this.state.PesoTotal,
                volume_total: this.state.VolumeTotal,
                receita_total: this.state.ReceitaTotal,
                despesa_total: this.state.DespesaTotal,
                saldo: this.state.Saldo,
                motorista_id: this.state.MotoristaId,
                veiculo_id: this.state.VeiculoId,
                descricao: this.state.Descricao,
                situacao: this.state.Situacao,
                transportadora_id: this.state.TransportadoraId,
                materiais_coletados: this.state.MateriaisInseridos,
                chave_nota: this.state.ChaveNota,
                numero_nota: this.state.NumeroNota,
                serie_nota: this.state.SerieNota,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);
                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }

            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });

    }



    componentDidMount() {
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        this.setState({
            DataAtual: ano + '-' + mes + '-' + dia,
            DataSolicitacao: ano + '-' + mes + '-' + dia
        })
        this.get_data(this.props.match.params.id);
    }



    render() {

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Destinação</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Destinação finalizada</p>
                            <br />

                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Destino: <span style={{ fontWeight: 'normal' }}>{this.state.Destino}</span></label>
                                        </div>

                                        {this.state.SolicitacaoSelecionada.length > 0 && <><hr />
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados de solicitação em aberto:</label>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">Solicitação de coleta: <span style={{ fontWeight: 'normal' }}>{this.state.SolicitacaoSelecionada[0].ponto_coleta.nome_fantasia}-{this.state.SolicitacaoSelecionada.descricao}</span></label>
                                            </div>
                                            <br />

                                            {this.state.SolicitacaoSelecionada.length > 0 && <>
                                                <div className="form-group row">
                                                    <p className="col-sm-6 "> <b className="col-sm-4 ">Data da solicitação: </b> {this.state.SolicitacaoSelecionada[0].data.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </p>
                                                </div>
                                                <div className="form-group row">
                                                    <p className="col-sm-6 "><b className="col-sm-4 " >Ponto de coleta:  </b> {this.state.SolicitacaoSelecionada[0].ponto_coleta.nome_fantasia}</p>
                                                </div>
                                                <div className="form-group row">
                                                    <p className="col-sm-6 "><b className="col-sm-4 " >Responsável: </b>{this.state.SolicitacaoSelecionada[0].responsavel.name}</p>
                                                </div>

                                            </>}
                                        </>}
                                        <hr />

                                        {this.state.MateriaisInseridos.length > 0 &&
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Materiais selecionados:</label>

                                                <table id="tech-companies-1" className="table table-striped table-hover" >
                                                    <thead>
                                                        <tr>
                                                            <th>Material</th>
                                                            <th>Peso</th>
                                                            <th>Volume</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.MateriaisInseridos.map((item) => (
                                                            <tr key={item.residuo_rejeito_id}>
                                                                <td>{item.nome}</td>
                                                                <td>{maskNumberBr(item.peso + '') + ' '+(item.residuo_rejeito.unidade_principal.tipo=='Peso'?item.residuo_rejeito.unidade_principal.sigla:item.residuo_rejeito.unidade_secundaria.sigla)}</td>
                                                                <td>{maskNumberBr(item.volume + '')+ ' '+(item.residuo_rejeito.unidade_principal.tipo=='Volume'?item.residuo_rejeito.unidade_principal.sigla:item.residuo_rejeito.unidade_secundaria.sigla)}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>}

                                        <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados do despacho:</label>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Situação: </label>{this.state.Situacao}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"> <label className="col-sm-4 col-form-label">Data da criação: </label>{this.state.DataCriacao.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"> <label className="col-sm-4 col-form-label">Agendado para: </label>{this.state.DataAgendamento.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"> <label className="col-sm-4 col-form-label">Iniciado em: </label>{this.state.DataInicio.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"> <label className="col-sm-4 col-form-label">Finalizado em: </label>{this.state.DataCriacao.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} </div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Peso total: </label>{maskNumberBr(this.state.PesoTotal)} Kg</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Volume total: </label>{maskNumberBr(this.state.VolumeTotal)} L</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Receita: </label> {maskPrice(this.state.ReceitaTotal)}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Despesas: </label> {maskPrice(this.state.DespesaTotal)}</div>
                                        </div>
                                        <div className="form-group row">
                                            <div className="col-sm-12"><label className="col-sm-4 col-form-label" >Saldo: </label> R$ {this.state.Saldo.replace('.', ',')}</div>
                                        </div>

                                        <div className="row">
                                            <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Veículos/Motoristas selecionados:</label>

                                            <table id="tech-companies-1" className="table table-striped table-hover" >
                                                <thead>
                                                    <tr>
                                                        <th>Veículo</th>
                                                        <th>Motorista</th>
                                                        <th>Capacidade Peso</th>
                                                        <th>Capacidade Volume</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.veiculos_motoristas?.map((item, id) => (
                                                        <tr key={id}>
                                                            <td>{item.veiculo.modelo.nome}</td>
                                                            <td>{item.motorista.nome}</td>

                                                            <td>{maskNumberBr(item.veiculo.capacidade_maxima_peso)}  {item.veiculo.unidade_peso.sigla}</td>
                                                            <td>{maskNumberBr(item.veiculo.capacidade_maxima_volume)}  {item.veiculo.unidade_volume.sigla}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        {this.state.Descricao != '' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Observações:  <span style={{ fontWeight: 'normal' }}>{this.state.Descricao}</span> </label>
                                        </div>}
                                        {this.state.ChaveNota != '' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Chave da NF-e:  <span style={{ fontWeight: 'normal' }}>{this.state.ChaveNota}</span></label>
                                        </div>}
                                        {this.state.NumeroNota != '' && <div className="form-group  row">
                                            <div className=" col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-8 col-12">
                                                        <label className="col-sm-12 col-form-label">Número da NF-e:  <span style={{ fontWeight: 'normal' }}>{this.state.NumeroNota}</span> </label>
                                                    </div>
                                                    <div className="col-md-4 col-12">
                                                        <label className="col-sm-12 col-form-label">Série da NF-e:  <span style={{ fontWeight: 'normal' }}>{this.state.SerieNota}</span> </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}

                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/destinacao"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}
                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}

                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(ViewDestinacaoColetaFinalizada);


