import React from 'react'
import { GET } from '../../../Auxiliar/Requests'
import { Divider, IconButton, Tooltip } from '@mui/material'
import { maskDate } from '../../../Auxiliar/Masks'
import { renderToast } from '../../../Auxiliar/Alerts'
import { StatusColeta } from '../../../Configs/Status'
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md"
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const containerHeight = window.innerHeight * 0.59
const cardHeight = 125
const cardByRendering = Math.floor(containerHeight / cardHeight * 1.25)

const CardList = ({ reload, dateFor, dateOf, options }) => {
  const history = useHistory()

  const [data, setData] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [newContent, setNewContent] = React.useState(null)
  const [pagination, setPagination] = React.useState({ current_page: 1, last_page: 10 })

  const waitingFetch = React.useRef(false);
  const containerRef = React.useRef(null);
  const lastScrollTop = React.useRef(0);

  const endPoint = options.dispatches.value ? 'despacho' : 'destinacao'

  //-------------------------*-------------------------
  React.useEffect(() => {
    if (reload) {
      getData()
    }
  }, [reload])

  //-------------------------*-------------------------
  React.useEffect(() => {
    if (!containerRef.current) {
      return
    }

    lastScrollTop.current = containerRef.current.scrollTop;
    containerRef.current.addEventListener('scroll', handleScroll);

    return () => {
      containerRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, [waitingFetch.current, containerRef.current, pagination]);

  //-------------------------*-------------------------
  const getData = async (page = 1, currentData = {}) => {
    setLoading(true)
    const response = await GET({ url: `${endPoint}/kanban?page=${page}&perColumn=${cardByRendering}&dateFor=${dateFor}&dateOf=${dateOf}` })
    setLoading(false)

    if (!response || !response.data) {
      renderToast({ type: 'error', error: 'Houve um erro ao carregar os registros' })
      // history.goBack()
      return
    }

    let renderedData = renderData([...Object.values(currentData).flat(), ...response.data,...response.solicitacoes])

    setNewContent(filterContentAlert(currentData))
    setData(filterKanbanGrid(renderedData))
    setPagination(response.pagination)
  }

  //-------------------------*-------------------------
  const handleScroll = () => {
    const container = containerRef.current
    const { current_page, last_page } = pagination

    if (container.scrollTop === lastScrollTop.current) {
      return
    }

    if (container.offsetHeight + container.scrollTop >= container.scrollHeight * 0.8
      && !waitingFetch.current && current_page < last_page) {
        console.log(pagination)
      getData(current_page + 1, data)
      waitingFetch.current = true
      setTimeout(() => {
        waitingFetch.current = false
      }, 500)
    }
  };

  //-------------------------*-------------------------
  const renderData = (dataArray) => dataArray.length >= 50 ? dataArray.slice(0, 50) : dataArray

  //-------------------------*-------------------------
  // Receiving array of collection point or material names
  const getLabel = (array, length) => {
    let label = ''
    let fullLabel = ''

    if (array.length === 1) {
      fullLabel = array[0].name ?? array[0]
    } else {
      fullLabel = array.map(item => {
        if (item.name) {
          return item.name
        }

        return item
      }).join(', ')
    }

    label = fullLabel.length > length ? fullLabel.slice(0, length) + '...' : fullLabel

    return (
      <span>
        {fullLabel.length > length
          ?
          <Tooltip title={fullLabel} arrow placement='right'>
            <span>{label}</span>
          </Tooltip>
          :
          label}
      </span>
    )
  }

  //-------------------------*-------------------------
  const filterKanbanGrid = (data) => {
    const groupedData = data.reduce((grouped, item) => {
      const status = StatusColeta.getKeyByValue(item.status);

      if (!grouped[status]) {
        grouped[status] = [];
      }

      grouped[status].push(item);
      return grouped;
    }, {});

    // Make sure to add an empty array for each missing status
    Object.values(StatusColeta.statusMapping).forEach(status => {
      if (!groupedData[status]) {
        groupedData[status] = [];
      }
    });
    console.log(groupedData)
    return groupedData;
  }

  //-------------------------*-------------------------
  const filterContentAlert = (dataObject) => {
    return Object.entries(dataObject).reduce((grouped, [key, item]) => {
      if (!grouped[key]) {
        grouped[key] = null;
      }

      grouped[key] = item.length

      return grouped
    }, {})
  }

  //-------------------------*-------------------------
  const handleRedirect = (status, id) => {
    if(id=='1e48295a-522b-4bf3-862a-71666a13342a'){
      console.log(status)
    }
    const path = endPoint === 'despacho' ? StatusColeta.getDispatchRoute(status) : StatusColeta.getDestinationRoute(status)
    if(status=='Aguardando Despacho'){
      // return history.push(`/solicitar_coleta/editar/${id}`)
      return history.push("/despacho/criar?collect="+id);
    }
    else if(path.includes('view')){
      return history.push(`/coleta_despacho/${path}/${id}`)
    }
    else{
      return history.push(`/${path}/${id}`)
    }
  }

  //-------------------------*-------------------------
  const renderColumn = (status, data, index) => {
    const bgColor = StatusColeta.hexColorMapping[status]

    return (
      <div className="col-6 col-md-4 col-lg-3 col-xl-2 h-100 rounded me-2" key={index} style={{ backgroundColor: '#EFF3F8', minHeight: '100%' }}>
        <div style={{ backgroundColor: bgColor, borderRadius: '5px 5px 0 0' }}>
          <p className='text-center text-uppercase text-white py-2 m-0'>{StatusColeta.nameMapping[status]}</p>
        </div>

        {data.map((item, index) => (
          <div key={item.id}>
            {index === newContent[status] && <Divider className='small-text'>NOVO</Divider>}

            <div className='mb-2 mx-2 py-2' onClick={() => handleRedirect(item.status, item.id)}>
              <div className="d-flex bg-white rounded shadow-hvr pointer" style={{ minHeight: cardHeight,wordBreak:'break-all' }}>
                <div style={{ width: 5, minHeight: '100%', backgroundColor: bgColor }} />
                <div className="m-2">
                  <p className='m-0 p-0'><strong>{item.codigo}</strong></p>
                  <p className='small-text'>{maskDate(item.created_at)} - {item.finished_at ? maskDate(item.finished_at) : '__/__/__'}</p>
                  <p className='small-text'>{item.quantity} - {getLabel(item.materials, 12)}</p>
                  <p className='small-text'>{getLabel(item.collect_points, 18)}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="row container-fluid">
      <div className="position-relative p-0 mx-2" style={{ height: containerHeight }}>
        {Object.values(data).length !== 0 && (
          <>
            <IconButton
              className='position-absolute top-50 start-0 translate-middle z-index'
              onClick={() => containerRef.current.scrollLeft = 0}>
              <MdArrowBackIosNew />
            </IconButton>

            <IconButton
              className='position-absolute top-50 start-100 translate-middle z-index'
              onClick={() => containerRef.current.scrollLeft = containerRef.current.scrollWidth}>
              <MdArrowForwardIos />
            </IconButton>

            <div className="row scroll h-100" ref={containerRef}>
              <div className={`d-flex ${loading ? 'disabled' : 'enabled'}`}>
                {Object.entries(data).map(([status, statusGroup], index) =>
                  renderColumn(status, statusGroup, index)
                )}
              </div>

            </div>

            {loading && (
              <div className="position-absolute top-50 start-50 translate-middle">
                <div className="spinner-border text-primary" role="status" />
              </div>
            )}
          </>
        )}

        {loading && Object.values(data).length === 0 && (
          <div className="col-12 d-flex justify-content-center align-items-center">
            <div className="spinner-border text-primary" role="status"></div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CardList