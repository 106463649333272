export function handleStatus(statusNum) {
    let bgColor = "transparent";
    let statusString = "";
  
    if (statusNum == 0) {
      bgColor = "#dc3545";
      statusString = "Cancelado";
    }
    if (statusNum == 1) {
      bgColor = "#ffc107";
      statusString = "Pendente";
    }
    if (statusNum == 2) {
      bgColor = "#2E8EB8";
      statusString = "Aceito";
    }
    if (statusNum == 3) {
      bgColor = "#73B054";
      statusString = "Finalizado";
    }
  
    return (
      <span
        style={{
          backgroundColor: bgColor,
          paddingLeft: "0.4rem",
          paddingRight: "0.4rem",
          color: "#FFF",
  
          paddingTop: "0.2rem",
          paddingBottom: "0.2em",
  
          borderRadius: "10px",
        }}
      >
        {statusString}
      </span>
    );
  }