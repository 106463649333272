
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import { maskNumber } from '../../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdReply  } from 'react-icons/md';

class CriarDestinacaoDisposicao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/destinacao_disposicao',
            loading_screen: false,
            roles: [],
            codigo: '',
            descricao:'',
            tipo:'Destinação',
            loading_save: false,
            msgErro:''
        };
    }



    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/store_destinacao_disposicao`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                codigo: this.state.codigo,
                tipo: this.state.tipo,
                descricao: this.state.descricao,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false,redirect:true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {

    }

   



    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Cadastrar destinação/disposição</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Cadastre as destinações e disposições do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro!='' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={()=>this.setState({msgErro:''})}></button>


                                        <p style={{marginBottom:0,whiteSpace:'pre'}}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Código <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Código da destinação/disposição" type="text" onChange={(e) => this.setState({ codigo: e.target.value })} value={this.state.codigo} />

                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Tipo <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.tipo} className="form-control" id="cameras" onChange={this.change_tipo.bind(this)}>
                                                    <option  value={'Destinação'}>{'Destinação'}</option>
                                                    <option  value={'Disposição'}>{'Disposição'}</option>

                                                </select>
                                            </div>
                                        </div>


                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Descrição <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <textarea className="form-control" placeholder="Descrição da destinação/disposição" type="text" onChange={(e) => this.setState({ descricao: e.target.value })} value={this.state.descricao}></textarea>

                                            </div>
                                        </div>
                                      
                                    </div>

                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/destinacao_disposicao"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar destinação/disposição</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(CriarDestinacaoDisposicao);


