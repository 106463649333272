export function handleStatus(statusNum) {
    let statusString = "";

    if (statusNum == 0) statusString = "Cancelado";
    if (statusNum == 1) statusString = "Pendente";
    if (statusNum == 2) statusString = "Aceito";
    if (statusNum == 3) statusString = "Finalizado";

    return statusString;
}
