
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import  Tabs  from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Geral from './DestinacaoGeral';
import Finalizados from './DestinacaoFinalizadas';

// Registros
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdSearch } from 'react-icons/md';

class Destinacao extends Component {

    render() {
        return (
            <div className="row">

                < div className="col-12" >
                    <div className="card">
                        <div className="card-body" id="card">

                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Destinação</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listadas as destinações dos materiais</p>
                            <br />
                            <br />
                            <Tabs  transition={false}  defaultActiveKey="Pendente" id="uncontrolled-tab-example">
                              
                                    <Tab eventKey="Pendente" title="Pendentes" >
                                        <Geral/>
                                         </Tab>
                                    <Tab eventKey="Finalizados" title="Finalizados"> 
                                    <Finalizados/>
                                     </Tab>
                                    </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(Destinacao);


