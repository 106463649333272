import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { BsFillPersonPlusFill } from 'react-icons/bs';
import { IconButton, Step, StepLabel, Stepper, ThemeProvider } from '@mui/material';
import Usuario from './Usuario';
import Permissoes from './Permissoes';
import { useSelector } from 'react-redux';
import { THIS_URL, URL } from '../../../variables';
import { MdClose } from 'react-icons/md';
import Theme from '../../Theme';
import { renderToast, ToastContent } from '../../../Auxiliar/Alerts';

const style = {
  position: 'absolute',
  // top: '50%',
  left: '50%',
  transform: 'translate(0, 0, 0, 50%)',
  width: '50%',
  height: '100%',
  bgcolor: 'background.paper',
  border: '2px solid transparent',
  boxShadow: 24,
  p: 4,
  // display: 'flex',
  // flexDirection: 'column',
  overflowY: 'auto',
  '@media(max-width: 1000px)': {
    width: '90%',
    left: '5%',
  },
};

const UsuarioModal = (props) => {
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [state, setState] = React.useState({
    redirect: false,
    path: '/pessoas',
    loading_screen: true,
    roles: [],
    name: { value: '', error: false },
    email: { value: '', error: false },
    imagem: '',
    imagem_path: '',
    cpf: '',
    telefone: '',
    segundo_telefone: '',
    // password: { value: '', error: false },
    // confirm_password: { value: '', error: false },
    tipo: { value: '', error: false },
    loading_save: false,
    msgErro: '',
    acessoSistema: false,
    token: '',
    error1Step: false,
    error2Step: false,

    acesso: {
      ContatoDestino: true,
      ResponsavelPontoColeta: true,
      PessoaAutorizadaPontoColeta: true,
      ResponsavelEstoque: true,
      PessoaAutorizadaEstoque: true,
      ResponsavelTransportadora: true,
      ResponsavelRegistroEntradaCT: true,
      ResponsavelSolicitacaoColeta: true,
      ResponsavelRegistroEntradaGI: true,
      NotificacaoIndividual: false,
    },
    tipos: [],
  });
  state.token = useSelector((state) => state.AppReducer.token);
  const [height, setHeight] = React.useState(0)
  const [rerender, setRerender] = React.useState(0)
  const content = React.useRef()
  const modalRef = React.useRef()

  React.useLayoutEffect(() => {
    if (open) {
      if (content.current !== undefined && content.current !== null) setHeight(content.current.clientHeight)
      else setRerender((item) => item + 1); content.current = document.getElementById('ancor')

      // setHeight(content.current.clientHeight)
    }
  }, [open, rerender, content.current?.clientHeight])


  React.useEffect(() => {
    fetch(`${URL}api/get_pessoa_tipos`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      },
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          console.log(resp);
          if (resp.message === 'Unauthenticated.') {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            return;
          }
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = '';
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + '\n';
              else erro += errors[i];
            }
            console.log(erro);
            setState({ ...state, redirect: true });
          } else {
            setState({ ...state, loading_screen: false, tipos: resp.tipos });
          }
        } catch (err) {
          setState({ ...state, redirect: true });
          console.log(err);
        }
      })
      .catch((err) => {
        setState({ ...state, redirect: true });
        console.log(err);
      });
  }, []);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function renderPessoa() {
    if (page === 1) {
      return <Usuario state={state} setState={(e) => setState(e)} />;
    } else {
      return <Permissoes state={state} setState={(e) => setState(e)} contentRef={content} token={state.token} />;
    }
  }

  function validate() {
    let state2 = { ...state };

    if (page === 1) {
      let keys = Object.keys(state);
      keys = keys.filter((item) => item === 'name' || item === 'email');
      keys.forEach((item) => {
        if (state2[item].value === '') {
          state2[item].error = true;
          state2.error1Step = true;
        }
      });

      setState(state2);
      if (!state2.error1Step) setPage(page + 1); content.current = undefined;
    } else if (page === 2) {
      if (state.acessoSistema) {
        let keys = Object.keys(state);
        // let state2 = { ...state }
        keys = keys.filter(
          (item) =>
            // item === 'password' || item === 'confirm_password' ||
            item === 'tipo',
        );
        keys.forEach((item) => {
          if (state2[item].value === '') {
            state2[item].error = true;
            state2.error2Step = true;
          }
        });

        setState(state2);
        if (!state2.error2Step) salvar();
      } else {
        setState(state2);
        salvar();
      }
    }
  }

  function salvar() {
    setState({ ...state, loading_save: true, msgErro: '' });
    let form = new FormData();

    form.append('imagem', state.imagem);
    form.append('name', state.name.value);
    form.append('email', state.email.value);
    form.append('cpf', state.cpf);
    form.append('telefone', state.telefone);
    form.append('segundo_telefone', state.segundo_telefone);
    form.append('acesso', state.acessoSistema);
    form.append('acessos_modulos', JSON.stringify(state.acesso));
    // form.append('password', state.password.value);
    // form.append('password_confirmation', state.confirm_password.value);
    form.append('tipo', state.tipo.value);
    if (props.ponto_id) {
      form.append('ponto_id', props.ponto_id);
    }
    if (props.estoque_id) {
      form.append('estoque_id', props.estoque_id);
    }

    if (state.acessoSistema == true) {
      form.append('url', THIS_URL)
    }

    fetch(`${URL}api/store_pessoa`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      },
      body: form,
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = '';
            for (let i = 0; i < errors.length; i++) {
              if (i !== errors.length - 1) erro += errors[i] + '\n';
              else erro += errors[i];
            }

            setState({ ...state, loading_save: false, msgErro: erro });
            renderToast({ type: 'error', error: erro })
          } else {
            setState({ ...state, loading_save: false });
            if (props.id) {
              props.reload(props.id);
            } else {
              props.reload();
            }
            handleClose();
          }
        } catch (err) {
          console.log(err);
          setState({
            ...state,
            loading_save: false,
            msgErro: 'Erro ao pegar resposta do servidor',
          });
        }
      })
  }

  return (
    <>
      <button
        onClick={handleOpen}
        disabled={props.disabled}
        className="btn btn-primary"
      >
        <BsFillPersonPlusFill />
      </button>
      <Modal
        ref={modalRef}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ThemeProvider theme={Theme}>
          <Box sx={style}>
            {!state.loading_save ? (
              <>
                <div id='ancor' ref={content}>
                  <IconButton onClick={handleClose}>
                    <MdClose />
                  </IconButton>
                  <Typography id="modal-modal-title" variant="h6" component="h2">
                    <div className="text-center">
                      <h4
                        className="mt-0 header-title"
                        style={{ fontSize: '1.5rem', color: 'black' }}
                      >
                        Cadastro Rápido
                      </h4>
                      <p style={{ fontSize: '1rem', color: 'black' }}>
                        Cadastre as pessoas do seu sistema
                      </p>
                    </div>
                    <Stepper activeStep={page - 1} alternativeLabel>
                      <Step>
                        <StepLabel>Dados do Usuário</StepLabel>
                      </Step>
                      <Step>
                        <StepLabel>Permissões</StepLabel>
                      </Step>
                    </Stepper>
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {renderPessoa()}
                  </Typography>
                </div>
                {<div className="d-flex align-items-end" style={
                  height > (modalRef?.current?.clientHeight * 0.8) ? {} : { height: `calc(100% - ${height}px)` }}>
                  <Button sx={{ color: 'text.main' }} variant="contained" size="large"
                    onClick={() => { setPage(page - 1); content.current = undefined }} disabled={page === 1}>
                    Voltar
                  </Button>

                  <div className="ms-auto">
                    {page < 2 && (
                      <Button sx={{ color: 'text.main' }} variant="contained" size="large" onClick={() => validate()} disabled={page === 2}>
                        Próximo
                      </Button>
                    )}
                    {page === 2 && (
                      <Button sx={{ color: 'text.main' }} variant="contained" size="large" onClick={() => validate()}>
                        Salvar
                      </Button>
                    )}
                  </div>
                </div>}
              </>
            ) : (
              <div className="row">
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: '2rem' }}
                >
                  <div
                    className="spinner-border text-primary"
                    role="status"
                  ></div>
                </div>
              </div>
            )}
            <ToastContent />
          </Box>
        </ThemeProvider>
      </Modal>
    </>
  );
};

export default UsuarioModal;
