import React, { Component } from "react";
import { MdOutlineCheck, MdVisibility } from "react-icons/md";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import Tooltip from "react-tooltip";

import { logout } from "../../../actions/AppActions";
import { NAME, URLadmin } from "../../../variables";

class RelacionamentosInternos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: "/relacionamentos_internos",
      loading_screen: true,
      page: 1,
      total_pages: 1,
      related: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async (page) => {
    fetch(
      `${URLadmin}api/auth/admin/internal_relationship/index?connection=${NAME}&page=${page}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    ).then(async (responseLog) => {
      try {
        let response = await responseLog.json();
        if (response.response) {
          this.setState({
            related: response.internalRelationships,
            loading_screen: false,
          });
        }
        return;
      } catch (err) {
        console.error(err);
      }
    });
  };

  checkOperation(department) {
    if (department === "pickupPoint") return "Ponto de Coleta";
    if (department === "destination") return "Destino";
    return "";
  }

  render() {
    return (
      <>
        {this.state.redirect === true && <Redirect to={this.state.path} />}
        {this.state.loading_screen === false && (
          <div>
            <div className="row mb-2">
              <div className="col-12 d-flex justify-content-end">
                <Link to="/relacionamentos_internos/convidar">
                  <span className="btn btn-primary btn-lg waves-effect waves-light mt-1">
                    Convidar Organização
                  </span>
                </Link>
              </div>
            </div>
            {this.state.related.length > 0 ? (
              <div className="table-rep-plugin">
                <div
                  className="table-responsive mb-0"
                  data-pattern="priority-columns"
                >
                  <table
                    id="tech-companies-1"
                    className="table table-striped table-hover"
                    style={{ textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th>Ponto de Coleta</th>
                        <th>Destino</th>
                        <th>Status</th>
                        <th>Sua atuação</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.related.map((item, id) => (
                        <tr key={id}>
                          <td>{item.pickupPoint.company_name}</td>
                          <td>{item.destination.company_name}</td>
                          <td>{item.isActive ? "Ativo" : "Pendente"}</td>
                          <td>
                            {this.checkOperation(item.yourOrganizationIs)}
                          </td>
                          <td>
                            {item.yourOrganizationIs === "destination" &&
                              !item.isActive && (
                                <Link
                                  to={
                                    "/relacionamentos_internos/aceitar/" +
                                    item.id
                                  }
                                >
                                  <span
                                    className="btn btn-success btn-outline btn-circle me-1 mb-1"
                                    data-tip="Aceitar Convite"
                                  >
                                    <Tooltip />
                                    <MdOutlineCheck
                                      style={{ color: "white" }}
                                    />
                                  </span>
                                </Link>
                              )}
                            <Link
                              to={"/relacionamentos_internos/view/" + item.id}
                            >
                              <span
                                className="btn btn-warning btn-outline btn-circle me-1 mb-1"
                                data-tip="Ver"
                              >
                                <Tooltip />
                                <MdVisibility style={{ color: "white" }} />
                              </span>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="w-100 mt-3">
                <div className="alert alert-info" role="alert">
                  Nenhum relacionamento encontrado...
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-12 d-flex justify-content-end">
                <ReactPaginate
                  previousLabel={"Anterior"}
                  nextLabel={"Próxima"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.state.total_pages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick.bind(this)}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  initialPage={this.state.page - 1}
                />
              </div>
            </div>
          </div>
        )}

        {
          //loading
          this.state.loading_screen == true && (
            <div className="row">
              {" "}
              <div
                className="col-12 d-flex justify-content-center align-items-center"
                style={{ marginTop: "2rem" }}
              >
                <div
                  className="spinner-border text-primary"
                  role="status"
                ></div>
              </div>
            </div>
          )
        }
      </>
    );
  }
  handlePageClick(page) {
    if (page.selected + 1 != this.state.page) {
      this.setState({ page: page + 1 });
      this.getData(page.selected + 1);
    }
  }
}

const mapsStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(RelacionamentosInternos);
