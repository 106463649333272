import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../../../actions/AppActions";
import { URL, URL_MOCK, SIGOR, SINIR } from "../../../variables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

async function fazerRequst(
    method,
    baseURL,
    endpoint,
    { body, onSuccess, onError, token }
  ) {
    try {
      const url = `${baseURL}${endpoint}`;
      const response = await fetch(url, {
        method,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: method === "POST" ? JSON.stringify(body) : undefined,
      });
  
      const result = await response.json();
  
      if (response.ok) {
        onSuccess(result);
        toast.success("Success message here");
      } else {
        onError(result);
        toast.error("Error message here");
      }
    } catch (error) {
      onError(error);
      toast.error("Network error message here");
    }
  }

  class Atualizacoes extends Component {
    constructor(props) {
      super(props);
      this.state = {
        messages: [],
        ultimaSincronizacaoSigor: null,
      };
    }
    sincronizarSigor = () => {
      fazerRequst("GET", URL, SIGOR, {
        onSuccess: (result) => {
          this.setState({ messages: result.mensagens });
          toast.success("Synchronization SIGOR successful");
        },
        onError: () =>
          toast.error(`Erro na sincronização SIGOR`, {
            autoClose: 5000,
          }),
          token: `${this.props.token}`
      });
    };
  
    sincronizarSinir = () => {
      this.setState({ messages: [] });
      fazerRequst("GET", URL, SINIR, {
        onSuccess: (result) => {
          this.setState({ messages: result.mensagens });
          toast.success("Synchronization SINIR successful");
        },
        onError: () =>
          toast.error(`Erro na sincronização SINIR`, {
            autoClose: 5000,
          }),
          token: `${this.props.token}`
      });
    };
    renderMessages() {
      return this.state.messages.map((message, index) => (
        <li key={index}>{message}</li>
      ));
    }
    render() {
      return (
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body" id="card">
                <h4
                  className="mt-0 header-title"
                  style={{ fontSize: "1.5rem", color: "black" }}
                >
                  Atualizações SIGOR/SINIR
                </h4>
                <p style={{ fontSize: "1rem", color: "black" }}>
                  Atualize os dados da SIGOR e SINIR do seu sistema
                </p>
                <div style={{ fontSize: "1rem", color: "black" }}>
                  <ul>
                    <li>Classes</li>
                    <li>Unidades</li>
                    <li>Tratamentos</li>
                    <li>Estados Físicos</li>
                    <li>Acondicionamentos</li>
                    <li>Resíduos</li>
                  </ul>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "1.0rem" }}>
                    <button
                      type="button"
                      onClick={this.sincronizarSigor}
                      className="btn btn-primary btn-lg waves-effect waves-light"
                    >
                      Sincronizar SIGOR
                    </button>
                  </div>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <button
                      type="button"
                      onClick={this.sincronizarSinir}
                      className="btn btn-primary btn-lg waves-effect waves-light"
                    >
                      Sincronizar SINIR
                    </button>
                  </div>
  
                  <div></div>
                </div>
                <p style={{ fontSize: "1rem", color: "black" }}>Messages:</p>
                <ul>{this.renderMessages()}</ul>
                <ToastContainer
                  position="bottom-right"
                  toastStyle={{ border: "1px solid gray", borderRadius: 4 }}
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={true}
                  closeOnClick
                  rtl={true}
                  pauseOnHover
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(Atualizacoes);