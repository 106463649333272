
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import { MdReply } from 'react-icons/md';
import { NumericFormat } from 'react-number-format';

class EditarVeiculo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/veiculos',
            loading_screen: false,
            tipos: [],

            marcas: [],
            modelos: [],
            transportadoras: [],
            transportadora_id: '',
            unidades_peso: [],
            unidades_volume: [],
            tipo_id: '',

            marca_id: '',
            modelo_id: '',
            unidade_peso_id: '',
            unidade_volume_id: '',
            conservacoes: [],

            placa: '',
            propriedade: 'Próprio',
            ano: moment().format('YYYY'),
            registro_antt: '',
            conservacao: '',
            descricao: '',
            capacidade_maxima_peso: '',
            capacidade_maxima_volume: '',
            loading_save: false,
            msgErro: ''
        };
    }



    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/update_veiculos`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                tipo_id: this.state.tipo_id,

                marca_id: this.state.marca_id,
                modelo_id: this.state.modelo_id,
                unidade_peso_id: this.state.unidade_peso_id,
                unidade_volume_id: this.state.unidade_volume_id,
                transportadora_id: this.state.transportadora_id,

                placa: this.state.placa,
                propriedade: this.state.propriedade,
                ano: this.state.ano,
                registro_antt: this.state.registro_antt,
                conservacao: this.state.conservacao,
                descricao: this.state.descricao,
                capacidade_maxima_peso: this.state.capacidade_maxima_peso,
                capacidade_maxima_volume: this.state.capacidade_maxima_volume,
                veiculo_id: this.props.match.params.id,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {

        this.get_rr(this.props.match.params.id);
    }

    get_rr(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_veiculo/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);                    
                    let modelos = [];
                    for (let i = 0; i < resp.marcas.length; i++) {
                        if (resp.marcas[i].id == resp.veiculo.marca_id) {
                            modelos = resp.marcas[i].modelos;
                            break;

                        }
                    }
                    console.log('resp', resp)
                    modelos = modelos.filter(item => item.inativo != true)
                    this.setState({
                        loading_screen: false,
                        marcas: resp.marcas,
                        tipos: resp.tipos,
                        conservacoes: resp.conservacoes,

                        unidades_peso: resp.unidades_peso,
                        unidades_volume: resp.unidades_volume,
                        tipo_id: resp.veiculo.tipo_id,
                        modelos,
                        modelo_selecionado: resp.veiculo.modelo.nome,
                        marca_id: resp.veiculo.marca_id,
                        modelo_id: resp.veiculo.modelo_id,
                        unidade_peso_id: resp.veiculo.unidade_peso_id,
                        unidade_volume_id: resp.veiculo.unidade_volume_id,
                        transportadoras: resp.transportadoras,
                        transportadora_id: resp.veiculo.transportadora_id == null ? '' : resp.veiculo.transportadora_id,

                        placa: resp.veiculo.placa,
                        propriedade: resp.veiculo.propriedade,
                        ano: resp.veiculo.ano,
                        registro_antt: resp.veiculo.registro_antt,
                        conservacao: resp.veiculo.conservacao_id,
                        descricao: resp.veiculo.descricao,
                        capacidade_maxima_peso: resp.veiculo.capacidade_maxima_peso,
                        capacidade_maxima_volume: resp.veiculo.capacidade_maxima_volume,
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }




    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }


    render() {
        let anos = [];
        for (let i = parseInt(moment().format('YYYY')); i >= 1990; i--) {
            anos.push(i);
        }
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar veículo</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um veículo do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Placa <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" maxLength={7} placeholder="Placa do veículo" type="text" onChange={(e) => this.setState({ placa: e.target.value })} value={this.state.placa} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Tipo de veículo <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.tipo_id} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ tipo_id: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione um tipo veículo</option>

                                                    {this.state.tipos.map((item, id) => (
                                                        <option key={id} value={item.id}>{item.nome}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Propriedade <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.propriedade} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ propriedade: e.target.value })
                                                }}>
                                                    <option value={'Próprio'}>Próprio</option>
                                                    <option value={'Terceiro'}>Terceiro</option>



                                                </select>
                                            </div>
                                        </div>
                                        {this.state.propriedade == 'Terceiro' && <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Transportadora <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.transportadora_id} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ transportadora_id: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione uma transportadora</option>

                                                    {this.state.transportadoras.map((item, id) => (
                                                        <option key={id} value={item.id}>{item.nome_fantasia}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>}


                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Marca <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.marca_id} className="form-control" id="cameras" onChange={(e) => {
                                                    let modelos = [];
                                                    for (let i = 0; i < this.state.marcas.length; i++) {
                                                        if (this.state.marcas[i].id == e.target.value) {
                                                            modelos = this.state.marcas[i].modelos;
                                                            break;

                                                        }
                                                    }
                                                    modelos = modelos.filter(item => item.inativo != true)
                                                    this.setState({ marca_id: e.target.value, modelo_id: '', modelos })
                                                }}>
                                                    <option value={''}>Selecione uma marca</option>

                                                    {this.state.marcas.map((item, id) => (
                                                        <option key={id} value={item.id}>{item.nome}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Modelo <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.modelo_id} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ modelo_id: e.target.value })
                                                }}>
                                                    <option value=''>Selecione um modelo</option>
                                                    {this.state.modelos.map((item, id) => (
                                                        <option key={id} value={item.id}>{item.nome}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Ano <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.ano} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ ano: e.target.value })
                                                }}>

                                                    {anos.map((item, id) => (
                                                        <option key={id} value={item}>{item}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Registro ANTT</label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Registro ANTT" type="text" onChange={(e) => this.setState({ registro_antt: e.target.value })} value={this.state.registro_antt} />

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Conservação <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                <select value={this.state.conservacao} className="form-control" id="cameras" onChange={(e) => {
                                                    this.setState({ conservacao: e.target.value })
                                                }}>
                                                    <option value={''}>Selecione a conservação do veículo</option>

                                                    {this.state.conservacoes.map((item, id) => (
                                                        <option key={id} value={item.id}>{item.nome}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>


                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Descrição</label>
                                            <div className="col-sm-12">
                                                <textarea className="form-control" placeholder="Descrição " type="text" onChange={(e) => this.setState({ descricao: e.target.value })} value={this.state.descricao}></textarea>

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <label className="col-form-label">Valor da capacidade máxima de peso <b style={{ color: 'red' }}>*</b></label>
                                                        <NumericFormat type='text' className="form-control"
                                                            valueIsNumericString
                                                            thousandsGroupStyle='thousand'
                                                            thousandSeparator="."
                                                            decimalScale={2}
                                                            decimalSeparator=","
                                                            placeholder={'Valor'}
                                                            onValueChange={(values, sourceInfo) => {
                                                                this.setState({ capacidade_maxima_peso: values.value })
                                                            }}
                                                            value={this.state.capacidade_maxima_peso} />
                                                    </div>

                                                    <div className="col-md-6 col-12 ">

                                                        <label className="col-form-label">Unidade da capacidade máxima de peso <b style={{ color: 'red' }}>*</b></label>

                                                        {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                        <select value={this.state.unidade_peso_id} className="form-control" id="cameras" onChange={(e) => {
                                                            this.setState({ unidade_peso_id: e.target.value })
                                                        }}>
                                                            <option value={''}>Selecione uma unidade de peso</option>
                                                            {this.state.unidades_peso.map((item, id) => (
                                                                <option key={id} value={item.id}>{item.nome} - {item.sigla}</option>
                                                            ))}


                                                        </select>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div className="form-group row">
                                            <div className="col-sm-12">
                                                <div className="row">
                                                    <div className="col-md-6 col-12">
                                                        <label className="col-form-label">Valor da capacidade máxima de volume <b style={{ color: 'red' }}>*</b></label>
                                                        <NumericFormat type='text' className="form-control"
                                                            valueIsNumericString
                                                            thousandsGroupStyle='thousand'
                                                            thousandSeparator="."
                                                            decimalScale={3}
                                                            decimalSeparator=","
                                                            placeholder={'Valor'}
                                                            onValueChange={(values, sourceInfo) => {
                                                                this.setState({ capacidade_maxima_volume: values.value })
                                                            }}
                                                            value={this.state.capacidade_maxima_volume} />
                                                    </div>

                                                    <div className="col-md-6 col-12 ">

                                                        <label className="col-form-label">Unidade da capacidade máxima de volume <b style={{ color: 'red' }}>*</b></label>

                                                        {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                        <select value={this.state.unidade_volume_id} className="form-control" id="cameras" onChange={(e) => {
                                                            this.setState({ unidade_volume_id: e.target.value })
                                                        }}>
                                                            <option value={''}>Selecione uma unidade de peso</option>
                                                            {this.state.unidades_volume.map((item, id) => (
                                                                <option key={id} value={item.id}>{item.nome} - {item.sigla}</option>
                                                            ))}


                                                        </select>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>



                                    </div>



                                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}
                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/veiculos"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar veículo</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(EditarVeiculo);


