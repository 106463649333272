import React, { Component } from 'react'
import { GET } from '../../Auxiliar/Requests';
import { Tooltip } from '@mui/material';
import { BsInfoCircleFill } from 'react-icons/bs';

class VerRelatorioRI extends Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    this.state = {
      loadingScreen: true,
      relatorioId: id,
      relatorioInfo: []
    };
  }

  componentDidMount() {
    this.getData()
  }

  async getData() {
    const response = await GET({ url: `get_superadmin_relatorio/${this.state.relatorioId}` })
    if (response.error) {
      console.log('error', response)
      this.setState({ redirect: true })
      return
    }
    this.setState({ relatorioInfo: response.relatorio, loadingScreen: false })
  }

  render() {
    const relatorio = this.state.relatorioInfo
    return (
      <div className="row">
        <div className="col-12">
          {!this.state.loadingScreen && <div className="card">
              <div className="card-body" id="card">
                {/* <h4 className="mt-0 mb-4 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Visualizar Relatório</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}><b>Nome: </b>{relatorio.name}</p>
                            <p style={{ fontSize: '1rem', color: 'black' }}><b>Categoria: </b>{relatorio.tag_name}</p>
                            <p style={{ fontSize: '1rem', color: 'black' }}>
                                <Tooltip title={<span style={{ fontSize: '0.85rem' }}>Caso esteja com problemas para visualizar a página, cole o link em seu navegador</span>}>
                                    <a className='text-secondary' style={{ padding: 0, margin: 0 }}>
                                        <BsInfoCircleFill size={18} />
                                    </a>
                                </Tooltip>
                                <b>{" "}Link: </b>{relatorio.link}</p> */}
                <iframe
                  src={relatorio.link}
                  title={relatorio.name}
                  style={{
                    width: '100%',
                    height: '100vh',
                    border: 'none',
                  }}
                ></iframe>
              </div>
            </div>}
          {this.state.loadingScreen && <div className="d-flex justify-content-center my-3">
              <div className="spinner-border text-primary" role="status"></div>
</div>}
                </div>
            </div>
          )
  }
}

export default VerRelatorioRI;
