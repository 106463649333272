import React, { Component } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// acondicionamentos
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdSearch } from 'react-icons/md';
import { CircularProgress } from '@mui/material';
import ModalAcondicionamento from './ModalAcondicionamento';
import { toast, ToastContainer } from 'react-toastify';

class AcondicionamentoSigor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: '/',
      loading_screen: true,
      acondicionamentos: [],
      page: 1,
      total_pages: 1,
      msgErro: '',
      show_warning: false,
      show_erro: false,
      acondicionamento_to_delete: '',
      search: '',
      loading_search: false,
      list_loading: [],
    };
  }

  componentDidMount() {
    this.get_acondicionamentos(this.state.page);
    this.props.setSearch(false)
  }

  componentDidUpdate() {
    if (this.props.search) {
      this.props.setSearch(false)
      this.get_acondicionamentos(this.state.page);
    }
  }

  componentDidCatch() {
    this.get_acondicionamentos(this.state.page);
  }

  changeLoading(id, bool) {
    let list_loading = { ...this.state.list_loading };
    list_loading[id] = bool;
    this.setState({ list_loading });
  }

  get_acondicionamentos(page, search = '', busca = false) {
    if (busca == true) {
      if (this.state.loading_search == false) {
        this.setState({ loading_search: true });
      }
    } else if (this.state.loading_screen === false) {
      this.setState({ loading_screen: true });
    }
    fetch(
      `${URL}api/get_acondicionamentos_sigor?page=${page}&search=${search}&dateOf=${this.props.dateOf}&dateFor=${this.props.dateFor}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${this.props.token}`,
          // 'Content-Type': 'application/json',
        },
      },
    )
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          console.log(resp);
          if (resp.message == 'Unauthenticated.') {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            this.props.logout();
            return;
          }
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = '';
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + '\n';
              else erro += errors[i];
            }
            console.log(erro);
            this.setState({ redirect: true });
          } else {
            this.setState({
              loading_search: false,
              acondicionamentos: resp.acondicionamentos,
              loading_screen: false,
              page: resp.pagination.current_page,
              total_pages: resp.pagination.last_page,
            });
          }
        } catch (err) {
          this.setState({ redirect: true });
          console.log(err);
        }
      })
      .catch((err) => {
        this.setState({ redirect: true });

        console.log(err);
        // this.props.mudarLoadingHome(false);
      });
  }

  storeAcondicionamento(obj_acondicionamento) {
    this.changeLoading(obj_acondicionamento.unidade_id, true);
    this.setState({ loading_save: true, msgErro: '' });
    fetch(`${URL}api/store_acondicionamentos_sigor`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.props.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj_acondicionamento),
    })
      .then(async (responseLog) => {
        try {
          let resp = await responseLog.json();
          if (resp.errors != null || resp.error != null) {
            let errors = Object.values(resp.errors);
            let erro = '';
            for (let i = 0; i < errors.length; i++) {
              if (i != errors.length - 1) erro += errors[i] + '\n';
              else erro += errors[i];
            }
            console.log(erro);
            window.scrollTo(500, 0);

            this.setState({ loading_save: false, msgErro: erro });
          } else {
            let acondicionamentos = this.state.acondicionamentos.filter(
              (item) => item.id !== obj_acondicionamento.unidade_id,
            );

            this.setState({ acondicionamentos });
            toast.success(
              `${obj_acondicionamento.descricao} cadastrado com sucesso!`,
            );
          }
        } catch (err) {
          console.log(err);
          this.setState({
            loading_save: false,
            msgErro: 'Erro ao pegar resposta do servidor',
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading_save: false,
          msgErro:
            'Erro ao pegar resposta do servidor. Você está conectado a internet?',
        });
      })
      .finally(() =>
        this.changeLoading(obj_acondicionamento.unidade_id, false),
      );
  }

  deleteAcondicionamento(id) {
    fetch(`${URL}api/delete_acondicionamento_sigor/${id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${this.props.token}`,
        // 'Content-Type': 'application/json',
      },
    }).then(async (responseLog) => {
      let resp = await responseLog.json();
      console.log(resp);
      this.setState({ show_warning: false });
      this.get_acondicionamentos(this.state.page);
    });
  }

  render() {
    console.log(this.state.total_pages);
    return (
      <div className="row">
        {this.state.msgErro != '' && (
          <div>
            <div
              className="alert alert-danger alert-dismissible fade show mb-0"
              role="alert"
            >
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => this.setState({ msgErro: '' })}
              ></button>

              <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>
                {this.state.msgErro}
              </p>
            </div>
            <br />
          </div>
        )}
        {this.state.redirect == true && <Redirect to={this.state.path} />}
        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
              {/* <hr /> */}
              {this.state.loading_screen == false && (
                <div>
                  <div className="row mb-2">
                    <div className="col-12 col-md-6">
                      <form
                        className="app-search mt-1"
                        style={{ width: '100%' }}
                        onSubmit={(e) => {
                          clearTimeout(this.pesquisarTimeout);
                          this.setState({ page: 1 });
                          this.get_acondicionamentos(
                            1,
                            this.state.search,
                            true,
                          );
                          e.preventDefault();
                        }}
                      >
                        <div
                          className="form-group mb-0"
                          style={{ width: '100%', position: 'relative' }}
                        >
                          <input
                            value={this.state.search}
                            name="search"
                            placeholder="Pesquisar pelo Código ou Descrição..."
                            className="form-control"
                            style={{
                              width: '100%',
                              paddingRight: 40,
                              borderRadius: 100,
                            }}
                            onChange={(e) => {
                              clearTimeout(this.pesquisarTimeout);
                              let search = e.target.value;
                              this.setState({ page: 1, search: search });
                              this.pesquisarTimeout = setTimeout(() => {
                                this.get_acondicionamentos(1, search, true);
                              }, 500);
                            }}
                          />
                          <div
                            style={{
                              width: 40,
                              position: 'absolute',
                              right: 0,
                              top: 0,
                              bottom: 0,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <MdSearch />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <table
                        id="tech-companies-1"
                        className="table table-striped table-hover"
                        style={{ textAlign: 'center' }}
                      >
                        <thead>
                          <tr>
                            <th>Descrição</th>

                            <th data-priority="1">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.loading_search == false &&
                            this.state.acondicionamentos.map((item, id) => (
                              <tr key={id}>
                                <td
                                  style={{
                                    maxWidth: '50px',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {item.descricao}
                                </td>

                                {this.props.user.tipo_usuario.permissoes[
                                  'acondicionamentos'
                                ].escrita == true && (
                                    <td>
                                      {this.state.list_loading[item.id] ? (
                                        <span
                                          className="btn btn-primary btn-outline btn-circle me-1 mb-1"
                                          data-tip="Inserindo"
                                        >
                                          <CircularProgress
                                            color="inherit"
                                            size={16}
                                          />
                                        </span>
                                      ) : (
                                        <ModalAcondicionamento
                                          id={item.id}
                                          setState={(e) => this.setState(e)}
                                          storeAcondicionamento={(e) =>
                                            this.storeAcondicionamento(e)
                                          }
                                        />
                                        //   <span
                                        //     className="btn btn-primary btn-outline btn-circle me-1 mb-1"
                                        //     data-tip="Inserir"
                                        //     onClick={() =>
                                        //       this.storeEstado(item.id)
                                        //     }
                                        //   >
                                        //     <BsCheckLg />
                                        //   </span>
                                      )}
                                      <button
                                        className="btn btn-danger btn-outline btn-circle me-1 mb-1"
                                        data-tip="Deletar"
                                        onClick={() =>
                                          this.setState({
                                            acondicionamento_to_delete: item.id,
                                            show_warning: true,
                                          })
                                        }
                                      >
                                        <Tooltip />
                                        <MdDelete />
                                      </button>
                                    </td>
                                  )}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      {this.state.loading_search == true && (
                        <div className="row" style={{ margin: 0 }}>
                          {' '}
                          <div className="col-12 d-flex justify-content-center align-items-center">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            ></div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {this.state.loading_search == false &&
                    this.state.acondicionamentos.length == 0 && (
                      <div className="row w-100">
                        <div className="col-12">
                          <p style={{ textAlign: 'center' }}>
                            Nenhum acondicionamento cadastrado
                          </p>
                        </div>
                      </div>
                    )}
                  <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                      <ReactPaginate
                        previousLabel={'Anterior'}
                        nextLabel={'Próxima'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.total_pages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={this.handlePageClick.bind(this)}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        initialPage={this.state.page - 1}
                      />

                      <ToastContainer
                        position="bottom-right"
                        toastStyle={{
                          border: '1px solid gray',
                          borderRadius: 4,
                        }}
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        pauseOnHover
                      />
                    </div>
                  </div>

                  {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}
                </div>
              )}

              {this.state.loading_screen == true && (
                <div className="row">
                  {' '}
                  <div
                    className="col-12 d-flex justify-content-center align-items-center"
                    style={{ marginTop: '2rem' }}
                  >
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    ></div>
                  </div>
                </div>
              )}
              <SweetAlert
                warning
                title={'Atenção'}
                onConfirm={() => {
                  this.deleteAcondicionamento(
                    this.state.acondicionamento_to_delete,
                  );
                }}
                onCancel={() => {
                  this.setState({ show_warning: false });
                }}
                show={this.state.show_warning}
                confirmBtnText="Sim, desejo apagar"
                cancelBtnText="Cancelar"
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                showCancel={true}
              >
                Ao deletar este acondicionamento ele não estará mais disponível
                no sistema
              </SweetAlert>
              <SweetAlert
                danger
                title={'Erro'}
                onConfirm={() => {
                  this.setState({ show_erro: false });
                }}
                show={this.state.show_erro}
                confirmBtnText="OK"
                confirmBtnBsStyle="primary"
              >
                {this.state.msgErro}
              </SweetAlert>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handlePageClick(page) {
    console.log(page.selected);
    if (page.selected + 1 != this.state.page) {
      this.setState({ page: page + 1 });
      this.get_acondicionamentos(page.selected + 1);
    }
  }
}
const mapsStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(AcondicionamentoSigor);
