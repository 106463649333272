import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { MdClose } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { URL } from '../../../src/variables.js';
import { Button } from '@mui/material';
import UsuarioModalCDF from '../Cadastros/UsuarioModal/UsuarioModalCDF.js';

const style = {
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  border: "2px solid transparent",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  "@media(max-width: 1000px)": {
    width: "90%",
    left: "50%",
    transform: "translateX(-50%)",
  },
};

export default function ProcessCDFModal({ open, handleClose, mtr, token }) {
  const [selectedResponsavel, setSelectedResponsavel] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [responsaveis, setResponsaveis] = useState([]);
  const [isUsuarioModalOpen, setIsUsuarioModalOpen] = useState(false);

  useEffect(() => {
    if (open) {
      get_data();
    }
  }, [open]);

  useEffect(() => {
    const message = localStorage.getItem('toastMessage');
    if (message) {
      const type = localStorage.getItem('toastType');
      if (type === 'success') {
        toast.success(message);
      } else {
        toast.error(message);
      }
      localStorage.removeItem('toastMessage');
      localStorage.removeItem('toastType');
    }
  }, []);

  const get_data = async () => {
    setLoadingScreen(true); 
    try {
      const response = await fetch(`${URL}api/get_dados_transportadora`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      });
      const resp = await response.json();
      
      if (resp.message === "Unauthenticated.") {
        localStorage.removeItem('token'); 
        localStorage.removeItem('user');
        return;
      }
      if (resp.errors) {
        const erro = Object.values(resp.errors).join('\n');
        console.log(erro);
      } else {
        setResponsaveis(resp.responsaveis);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingScreen(false); 
    }
  };

  const handleProcessCDF = () => {
     const mtrs = [mtr];   
    fetch(`${URL}api/post_emitir_Cdf_por_CodigoManifesto`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({ listaManifesto: mtrs, responsavelId: selectedResponsavel }),
    })
    .then(async (response) => {
      let resp =  await response.json();
       
      if (response.ok) {
        if(resp.success.includes("Sigor") ||resp.success.includes("Sinir")  )
          toast.success(resp.success);


        if(resp.errors.includes("Sigor") ||resp.errors.includes("Sinir")  )
          toast.error(resp.errors);
 
      }
     
    }) 
    .catch(error => {
      localStorage.setItem('toastMessage', error.message);
      localStorage.setItem('toastType', 'error');
      window.location.reload();
    });
    handleClose();
  };

  const handleCloseUsuarioModal = () => {
    setIsUsuarioModalOpen(false);
  };

  const reloadResponsaveis = () => {
    get_data(); // Atualiza a lista de responsáveis
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="d-flex justify-content-end">
              <span style={{ cursor: "pointer" }} onClick={handleClose}>
                <MdClose />
              </span>
            </div>
            <h4 className="header-title" style={{ fontSize: "1.5rem", color: "black" }}>
              Processar/Reprocessar CDF
            </h4>
            <div className="form-group row mt-4">
              <label className="col-sm-12 col-form-label">
                Responsável <b style={{ color: 'red' }}>*</b>
              </label>
              <div className="col-sm-12 d-flex align-items-center">
                <select
                  value={selectedResponsavel}
                  className="form-control"
                  onChange={(e) => setSelectedResponsavel(e.target.value)}
                >
                  <option value=''>Selecione um responsável</option>
                  {responsaveis.map((responsavel) => (
                    <option key={responsavel.id} value={responsavel.id}>
                      {responsavel.name}
                    </option>
                  ))}
                </select>
                <UsuarioModalCDF
                  open={isUsuarioModalOpen}
                  handleClose={handleCloseUsuarioModal}
                  reloadResponsaveis={reloadResponsaveis}
                />
              </div>
            </div>
            <div className="mt-2 d-flex justify-content-end">
              <Button variant="contained" color="primary" onClick={handleProcessCDF} disabled={!selectedResponsavel}>
                Gerar CDF
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ToastContainer
        position="bottom-right"
        toastStyle={{ border: "1px solid gray", borderRadius: 4 }}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </>
  );
}
