import React from 'react';
import { CircularProgress } from '@mui/material';
import { MdSearch } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { URL } from '../../../variables';
import ControlMtr from './ControlMtr';
import ReactPaginate from 'react-paginate';
import SweetAlert from 'react-bootstrap-sweetalert';
import GetToken from '../../SigorRequests/GetToken';
import { toast, ToastContainer } from 'react-toastify';
import InfoModal from './InfoMtr';
import Filter from '../../../Auxiliar/Filter';

const Mtrs = () => {
  const [state, setState] = React.useState({
    active: [],
    canceled: [],
    codigoManifesto: '',
    pdf: '',
    pagination: '',
    pageNumber: 1,
    loading: true,
    loadingSearch: false,
    reload: false,
    search: '',
    showWarningCancel: false,
    loadingCancel: false,
    mtrToCancel: '',
    showWarningDelete: false,
    loadingDelete: false,
    mtrToDelete: '',
  })
  const [sigorToken, setSigorToken] = React.useState('');
  const token = useSelector(state => state.AppReducer.token);
  const user = useSelector(state => state.AppReducer.user);
  const [loading, setLoading] = React.useState(true)
  const [search, setSearch] = React.useState(true)
  const [dateOf, setDateOf] = React.useState('')
  const [dateFor, setDateFor] = React.useState('')
  const [options, setOptions] = React.useState({
    manifesto: { value: true, label: 'Manifestos Ativos', checked: true },
    manifestoCancelado: { value: false, label: 'Manifestos Cancelados', checked: false },
  })

  React.useEffect(() => {
    if (search) getData(); setSearch(false)
  }, [state.pageNumber, state.search, search]);

  const getData = async () => {
    setLoading(true)
    fetch(`${URL}api/get_mtrs_sigor?page=${state.pageNumber}&search=${state.search}&dateOf=${dateOf}&dateFor=${dateFor}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (response) => {
        const resp = await response.json();
        console.log('resp1', resp)
        // let active = resp.mtrs.filter(item => item.active === null)
        let active = resp.mtrs.filter(item => item.active === true)
        let canceled = resp.mtrs.filter(item => item.active === false)

        setState({ ...state, loading: false, loadingSearch: false, active: active, canceled: canceled, pagination: resp.pagination })
        setLoading(false)
      })
  }

  React.useEffect(() => {
    if (!sigorToken) {
      getToken()
    }

    async function getToken() {
      let sigorToken = await GetToken({ token: token })
      setSigorToken(sigorToken)
    }
  }, [])

  let timer
  function handleSearch(e) {
    clearTimeout(timer);
    timer = setTimeout(() => { setState({ ...state, search: e.target.value, loadingSearch: true }) }, 750);
  }


  function getMtr(cod) {
    return fetch(`https://mtrr.cetesb.sp.gov.br/apiws/rest/downloadManifesto/${cod}`, {
      method: 'POST',
      headers: {
        Accept: 'application/pdf',
        'Authorization': sigorToken,
        'Content-Type': 'application/pdf',
      }
    })
      .then(async (response) => {
        const resp = await response.blob()
        if (resp.size === 0) {
          toast.error('Sistema de MTRs fora do ar no momento, tente novamente mais tarde')
          return false
        } else {
          setState({ ...state, pdf: window.URL.createObjectURL(resp) })
          console.log('resp', resp)
          return true
        }
      })
  }


  function cancelMtr(cod) {
    fetch(`${URL}api/cancel_mtr_sigor`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ manNumero: cod, sigorToken: sigorToken })
    })
      .then(async (response) => {
        const resp = await response.json()
        if (!resp.erro) {
          setSearch(true)
          setState({ ...state, showWarningCancel: false })
        }
      })
  }

  function deleteMtr(id) {
    fetch(`${URL}api/delete_mtr_sigor/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      },
    })
      .then(async (response) => {
        const resp = await response.json()
        if (!resp.erro) {
          setState({ ...state, reload: !state.reload, showWarningDelete: false })
        }
      })
  }

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Manifestos</h4>
        <div className="d-flex">
          <Filter setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setSearch} />
          <InfoModal />
        </div>
        <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados os manifestos gerados</p>
        {!loading ? <div className="row">
          <div className="col-6">
            <div className="form-group mb-0" style={{ width: '100%', position: 'relative' }}>
              <input name="search" placeholder="Pesquisar pelo número ou data do manifesto" className="form-control" style={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', paddingRight: 40, borderRadius: 100 }} onChange={(e) => { handleSearch(e) }} />
              <div style={{ width: 40, position: "absolute", right: 0, top: 0, bottom: 0, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                {state.loadingSearch ? <CircularProgress color='success' size={15} /> : <MdSearch />}
              </div>
            </div>
          </div>

          <div className="row">
            {options.manifesto.value &&
              <ControlMtr state={state} setState={(e) => setState(e)} type='active' getMtr={(e) => getMtr(e)} />}
            {options.manifestoCancelado.value &&
              <ControlMtr state={state} setState={(e) => setState(e)} type='canceled' getMtr={(e) => getMtr(e)} />}
          </div>

          <div className="row">
            <div className="col-12 d-flex justify-content-end">
              <ReactPaginate previousLabel={'Anterior'} nextLabel={'Próxima'} breakLabel={'...'} breakClassName={'break-me'} pageCount={Math.ceil(state.pagination.total_pages / state.pagination.per_page)} marginPagesDisplayed={2} pageRangeDisplayed={2} onPageChange={(e) => setState({ ...state, pageNumber: e })} containerClassName={'pagination'} subContainerClassName={'pages pagination'} activeClassName={'active'} initialPage={state.pageNumber} />
            </div>
          </div>

          <SweetAlert warning title={'Atenção'} onConfirm={() => { cancelMtr(state.mtrToCancel) }} onCancel={() => { setState({ ...state, showWarningCancel: false }) }} show={state.showWarningCancel} confirmBtnText="Sim, desejo cancelar" cancelBtnText="Cancelar" confirmBtnBsStyle="success" cancelBtnBsStyle="danger" showCancel={true}>
            Ao cancelar este manifesto ele estará disponível na aba "Cancelados"
          </SweetAlert>

          <SweetAlert warning title={'Atenção'} onConfirm={() => { deleteMtr(state.mtrToDelete) }} onCancel={() => { setState({ ...state, showWarningDelete: false }) }} show={state.showWarningDelete} confirmBtnText="Sim, desejo apagar" cancelBtnText="Cancelar" confirmBtnBsStyle="success" cancelBtnBsStyle="danger" showCancel={true}>
            Ao deletar este manifesto ele não estará mais disponível no sistema
          </SweetAlert>

        </div> : <div className="d-flex justify-content-center p-5"><CircularProgress color='success' /></div>}
      </div>
      <ToastContainer
        position="bottom-right"
        toastStyle={{ border: '1px solid gray', borderRadius: 4 }}
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </div >
  )
}

export default Mtrs