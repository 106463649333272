
import React from 'react';
import HorariosFuncionamento from './HorariosFuncionamento';
import Filter from '../../../Auxiliar/Filter';

const SelecionarJsHorariosFuncionamento = () => {
    const [search, setSearch] = React.useState(false)
    const [options, setOptions] = React.useState({
        horariosFuncionamento: { value: true, label: 'Horários de Funcionamento', checked: true },
    })

    return (
        <div className="card">
            <div className="card-body" id="card">
                <div className="d-flex align-items-start">
                    <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Horarios de Funcionamento</h4>
                </div>
                <div>
                    <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são salvas as informações sobre a disposição da empresa para a retirada de materiais</p>
                </div>
                <div className="row">
                    {options.horariosFuncionamento.value &&
                        <HorariosFuncionamento search={search} setSearch={setSearch} />}
                </div>
            </div>
        </div>
    )
}

export default SelecionarJsHorariosFuncionamento
