const compliances = [
  { path: 'destinos', label: 'Destinos', owner: 'destiny_id' },
  { path: 'motoristas', label: 'Motoristas', owner: 'driver_id' },
  { path: 'pontos_coleta', label: 'Pontos de Coleta', owner: 'collect_point_id' },
  { path: 'transportadoras', label: 'Transportadoras', owner: 'carrier_id' },
  { path: 'veiculos', label: 'Veículos', owner: 'vehicle_id' },
]

const Paths = [
  'destinos', 'mototristas', 'pontos_coleta', 'transportadoras', 'veiculos'
]

const Owner = {
  Destiny: 0,


}

export {
  compliances
}