
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import { BsPlusLg } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';

// users
import { URL } from '../../../variables';
import { MdReply } from 'react-icons/md';

class EditarClasse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/classes',
            loading_screen: false,
            nome: '',
            loading_save: false,
            msgErro: '',
            e_sigor: false,
            classe_id: '',
            classes: [],
            classes_selecionadas: [],
        };
    }



    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/update_classes`, {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                nome: this.state.nome,
                classe_id: this.props.match.params.id,
                e_sigor: this.state.e_sigor,
                classes_selecionadas: this.state.classes_selecionadas
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i !== errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_classe(this.props.match.params.id);
        // this.fill_classes();
    }

    get_classe(id) {

        if (this.state.loading_screen === false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_classe/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message === "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i !== errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    console.log('resp', resp)
                    this.setState({
                        loading_screen: false,
                        nome: resp.classe.nome,
                        e_sigor: resp.classe.e_sigor,
                        classes_selecionadas: resp.classe.classes_sigor,
                        classes: resp.classes,
                        classe_id: resp.classes[0].id
                    });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    fill_classes() {
        fetch(`${URL}api/fill_classes_sigor`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.props.token}`
            }
        }).then(async (response) => {
            const resp = await response.json()
            this.setState({ classes: resp.classes, classe_id: resp.classes[0].id })
        })
    }

    add_classe(id) {
        if (!this.state.classes_selecionadas.filter(item => item.dados_classe.id === id)[0]) {
            const classe = this.state.classes.filter(item => item.id === id)[0]

            const obj_classe = {
                id: classe.id,
                nome: classe.nome,
                sigla: classe.sigla
            }

            this.setState({ classes_selecionadas: [...this.state.classes_selecionadas, { dados_classe: obj_classe }] })
        }
    }

    remove_classe(id) {
        const classes_selecionadas = this.state.classes_selecionadas.filter(item => item.dados_classe.id !== id)
        this.setState({ classes_selecionadas })
    }

    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect === true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar classe de resíduo</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite um classe de resíduo do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen === false && <div>
                                {this.state.msgErro !== '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Nome <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <input className="form-control" placeholder="Nome da classe" type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} />

                                            </div>
                                        </div>
                                    </div>

                                    {this.state.e_sigor && <div className="form-group row">
                                        <label className="col-sm-12 col-form-label">Classes Relacionadas:</label>
                                        <div className="input-group col-sm-12">
                                            <select value={this.state.classe_id} className="form-control" id="cameras" onChange={(e) => this.setState({ classe_id: e.target.value })}>
                                                {this.state.classes.map(item => (
                                                    <>
                                                        {!item.relation &&
                                                            <option key={item.id} value={item.id}>{item.nome}</option>}
                                                    </>
                                                ))}
                                            </select>
                                            <button className='btn btn-primary' onClick={() => this.add_classe(this.state.classe_id)}>
                                                <BsPlusLg />
                                            </button>
                                        </div>
                                    </div>}

                                    {this.state.classes_selecionadas.length > 0 &&
                                        <div className="row my-3">
                                            <div className="col-12">
                                                <table className='table table-striped table-hover text-center'>
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Ações</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.classes_selecionadas.map(item2 => (
                                                            <tr key={item2.dados_classe.id}>
                                                                <td>{item2.dados_classe.nome}</td>
                                                                <td><button className="btn btn-danger btn-small btn-circle m-r-5"
                                                                    onClick={() => this.remove_classe(item2.dados_classe.id)}>
                                                                    <FaTrash color='white' />
                                                                </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>}

                                </div>

                                {this.state.loading_save === false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/classes"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar classe</button>
                                    </div>
                                </div>}
                                {this.state.loading_save === true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen === true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(EditarClasse);


