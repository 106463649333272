
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import { URL } from '../../../variables';
import { MdReply } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';
import { NumericFormat } from 'react-number-format';

class ViewSeparacao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/separacao',
            loading_screen: false,
            required: false,

            sub_materiais: [],
            sub_inicial: [],
            sub_materiais_inseridos: [],
            sub_material_selecionado: [],
            material: [],
            despacho: [],
            pesagem: [],
            unidade: '',

            quantidade: '',
            estoque_id: '',
            despacho_id: '',
            sub_material: '',
            search: '',
            DataAtual: '',
            Codigo: '',
            DataSolicitacao: '',
            tipo: 'Peso',

            loading_save: false,
            msgErro: ''
        };
    }



    salvar() {
        console.log(JSON.stringify({ dados: this.state.sub_materiais_inseridos }))
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/store_separacao`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                residuo_rejeito_id: this.state.material.id,
                pesagem_id: this.state.pesagem.id,
                separacao: this.state.sub_materiais_inseridos
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        let data = new Date();
        let dia = String(data.getDate()).padStart(2, '0');
        let mes = String(data.getMonth() + 1).padStart(2, '0');
        let ano = data.getFullYear();
        this.setState({
            DataAtual: ano + '-' + mes + '-' + dia,
        });
        this.get_data(this.props.match.params.id);
    }


    get_data(id) {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_view_separacao/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    this.setState({
                        sub_inicial: resp.material.pesagem_residuo_rejeito.residuo_rejeito.sub_material,
                        sub_materiais: resp.material.pesagem_residuo_rejeito.residuo_rejeito.sub_material,
                        material: resp.material.pesagem_residuo_rejeito.residuo_rejeito,
                        despacho: resp.material.despacho_finalizado,
                        estoque_id: resp.material.despacho_finalizado.estoque_id,
                        pesagem: resp.material,
                        loading_screen: false,

                    });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }

        })
            .catch((err) => {
                this.setState({ redirect: true });
                console.log(err);
            });
    }

    validateDecimals = function (e) {
        var t = e.target.value;
        e.target.value = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
        console.log(e.target.value)
    }



    render() {

        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Separação</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>separação os materias para triagem</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Material: <span style={{ fontWeight: 'normal' }}>{this.state.material.nome}</span></label>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label">Código do despacho: <span style={{ fontWeight: 'normal' }}>{this.state.despacho.codigo}</span></label>
                                        </div>

                                        <div className="form-group row">
                                            <label className="col-sm-12 col-form-label" >Sub-materiais <b style={{ color: 'red' }}>*</b></label>
                                            <div className="col-sm-12">
                                                <select value={this.state.sub_material} className="form-control" onChange={(e) => {
                                                    console.log(this.state.sub_material_selecionado);
                                                    this.setState({
                                                        required: false,
                                                        sub_material: e.target.value,
                                                        sub_material_selecionado: this.state.sub_materiais.filter((item) => item.id == e.target.value)
                                                    })
                                                }}>
                                                    <option value={''}>Selecione um ou mais sub-materiais </option>
                                                    {this.state.sub_materiais.map((item, id) => (<option key={id} value={item.id}>{item.nome}</option>))}
                                                </select>
                                            </div>
                                        </div>

                                        {/* se material existir */}
                                        {(this.state.sub_material_selecionado.length > 0 && this.state.sub_material != '') && <>
                                            <hr />

                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'red', textTransform: 'uppercase' }}>{this.state.sub_material_selecionado[0].nome}</label>
                                                <div className="form-group  row">
                                                    <div className=" col-sm-12">

                                                        <div className="row">

                                                            <div className="col-md-6 col-12">
                                                                <label className="col-sm-12 col-form-label" >Tipo <b style={{ color: 'red' }}>*</b></label>
                                                                <div className="col-sm-12">
                                                                    <select value={this.state.tipo} className="form-control" onChange={(e) => {
                                                                        this.setState({
                                                                            tipo: e.target.value,
                                                                            required: false,
                                                                        })
                                                                    }}>
                                                                        <option value={'Peso'}>Peso</option>
                                                                        <option value={'Volume'}>Volume</option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6 col-12" >
                                                                <label className="col-sm-12 col-form-label">{this.state.tipo}<b style={{ color: 'red' }}>*</b></label>
                                                                <div className="input-group mb-3">
                                                                    <NumericFormat type='text' className="form-control" id="peso"
                                                                        valueIsNumericString
                                                                        thousandsGroupStyle='thousand'
                                                                        thousandSeparator="."
                                                                        decimalScale={2}
                                                                        decimalSeparator=","
                                                                        placeholder={this.state.tipo == 'Peso' ? 'peso do material' : 'volume do material'}
                                                                        onValueChange={(values, sourceInfo) => {
                                                                            this.setState({
                                                                                required: false,
                                                                                quantidade: values.value,
                                                                            })
                                                                        }}
                                                                        value={this.state.quantidade} />
                                                                    <span className="input-group-text" id="basic-addon3">{this.state.tipo == 'Peso' ? 'Kg' : 'Lt'}</span>
                                                                </div>
                                                                {this.state.required == true && <b style={{ color: 'red', fontSize: '12px', marginLeft: '10px' }}>O campo {this.state.tipo} é obrigatório</b>}

                                                            </div>
                                                        </div>


                                                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                            <button type="button" className="btn btn-success" style={{ backgorundColor: '#f2f2f2' }} onClick={() => {
                                                                if (this.state.quantidade == '' || this.state.quantidade == 0) {
                                                                    this.setState({ required: true });
                                                                }
                                                                else {
                                                                    let materiais_inseridos = [...this.state.sub_materiais_inseridos];
                                                                    let tipo = this.state.tipo == "Peso" ? 'kg' : 'lt';
                                                                    let material_selecionado = {
                                                                        "sub_material_id": this.state.sub_material,
                                                                        "nome": this.state.sub_material_selecionado[0].nome,
                                                                        "quantidade": this.state.quantidade.replace(/\./g, '').replace(',', '.'),
                                                                        "unidade": tipo,
                                                                    }
                                                                    console.log(material_selecionado);
                                                                    materiais_inseridos.push(material_selecionado);

                                                                    this.setState({
                                                                        sub_materiais: this.state.sub_materiais.filter((item) => item.nome != material_selecionado.nome),
                                                                        sub_materiais_inseridos: materiais_inseridos,
                                                                        sub_material_selecionado: [],
                                                                        quantidade: '',
                                                                    })
                                                                }
                                                            }
                                                            }>Inserir</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        }
                                        {this.state.sub_materiais_inseridos.length > 0 && <>
                                            <hr />
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Sub-materiais selecionados:</label>

                                                <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }} >
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Quantidade</th>
                                                            <th>Unidade de medida</th>
                                                            <th data-priority="3">Excluir</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.sub_materiais_inseridos.map((item, id) => (
                                                            <tr key={id}>
                                                                <td>{item.nome}</td>
                                                                <td>{item.quantidade} </td>
                                                                <td>{item.unidade}</td>
                                                                <td>
                                                                    <button onClick={() => {
                                                                        let sub_materiais = [...this.state.sub_materiais];
                                                                        let devolucao = this.state.sub_inicial.filter((item3) => item3.nome == item.nome);
                                                                        console.log(this.state.sub_inicial);
                                                                        console.log(item.nome);
                                                                        console.log(devolucao);
                                                                        sub_materiais.push(devolucao[0]);
                                                                        console.log(sub_materiais);
                                                                        let sub_materiais_inseridos = this.state.sub_materiais_inseridos.filter((item2) => item2.nome != item.nome)
                                                                        this.setState({
                                                                            sub_material: '',
                                                                            sub_materiais: sub_materiais,
                                                                            sub_materiais_inseridos: sub_materiais_inseridos
                                                                        })

                                                                    }}
                                                                        className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white', }}><FaTrash color='red' /></button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <hr />
                                        </>}

                                    </div>
                                </div>

                                {this.state.loading_save == false && <div className="row mt-3">
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <Link to="/separacao"> <span
                                            className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply /> Voltar</span></Link>
                                    </div>
                                    <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                        style={{ marginBottom: '0.5rem' }}>
                                        <button type="button" onClick={() => this.salvar()} className="btn btn-success btn-lg waves-effect waves-light"
                                            style={{ backgorundColor: '#f2f2f2' }}>Salvar separação</button>
                                    </div>
                                </div>}
                                {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                    </div>
                                </div></div>}

                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(ViewSeparacao);


