import React, { Component } from 'react';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

class EditarTratamentoSigor extends Component {

  render() {
    return (
      <div className="row">
        <div>
          <div className="row">
            <div className="col-12">
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">Código <b style={{ color: 'red' }}>*</b></label>
                <div className="col-sm-12">
                  <input className="form-control" placeholder="Código do tratamento" type="text" onChange={(e) =>
                    this.props.setState({...this.props.state, codigo: e.target.value })} value={this.props.state.codigo} />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">Descrição <b style={{ color: 'red' }}>*</b></label>
                <div className="col-sm-12">
                  <textarea className="form-control" placeholder="Descrição do tratamento" type="text" onChange={(e) =>
                    this.props.setState({...this.props.state, descricao: e.target.value })} value={this.props.state.descricao} disabled>
                  </textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapsStateToProps = (state) => ({
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(EditarTratamentoSigor);
