import React, { Component } from 'react'
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { BsFillQuestionCircleFill, BsSave } from 'react-icons/bs'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import validator from 'validator'
import BG from '../../../../assets/images/verdaz.png'
import { LOGO, URL } from '../../../../variables';
import { LOGOB64 } from './img';

export class AgendarSolicitacao extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingSave: false,
            loadingSearch: false,
            page: 0,
            msgErro: '',

            email: '',
            codigo: '',
            data: '',
            horario: '',
            coleta: {},
            pontoColeta: {},
            responsavelColeta: {},
            materialColeta: [],
            diasFunc: [],
            diasSemana: [],
            horariosDia: [],
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        switch (this.state.page) {
            case 0:
                if (this.state.email === '' || this.state.codigo === '') {
                    this.setState({ msgErro: 'Preencha os campos corretamente.' })
                    return;
                }
                if (!validator.isEmail(this.state.email)) {
                    this.setState({ msgErro: 'Formato de e-mail inválido.' })
                    return;
                }
                if (this.state.codigo.length !== 6) {
                    this.setState({ msgErro: 'O código possui 6 caracteres' })
                    return;
                } else {

                    this.setState({ loadingSave: true });

                    fetch(`${URL}api/auth/get_responsavel_solicitacao`, {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            email: this.state.email,
                            codigo: this.state.codigo
                        })
                    }).then(async (responseLog) => {
                        try {
                            let resp = await responseLog.json();
                            if (resp.errors != null || resp.error != null) {
                                let errors = Object.values(resp.errors);
                                let erro = '';
                                for (let i = 0; i < errors.length; i++) {
                                    if (i != errors.length - 1)
                                        erro += errors[i] + '\n';
                                    else
                                        erro += errors[i];
                                }
                                window.scrollTo(500, 0);

                                this.setState({ loadingSave: false, msgErro: erro });
                            }
                            else {
                                this.setState({ loadingSave: false, msgErro: '', page: 1, coleta: resp.coleta, pontoColeta: resp.ponto, diasFunc: resp.dias, responsavelColeta: resp.responsavel, materialColeta: resp.materiais });
                                for (let i = 0; i < this.state.diasFunc.length; i++) {
                                    if (this.state.diasFunc[i].dia === 'dom') {
                                        this.state.diasSemana.push(0)
                                    }
                                    if (this.state.diasFunc[i].dia === 'seg') {
                                        this.state.diasSemana.push(1)
                                    }
                                    if (this.state.diasFunc[i].dia === 'ter') {
                                        this.state.diasSemana.push(2)
                                    }
                                    if (this.state.diasFunc[i].dia === 'qua') {
                                        this.state.diasSemana.push(3)
                                    }
                                    if (this.state.diasFunc[i].dia === 'qui') {
                                        this.state.diasSemana.push(4)
                                    }
                                    if (this.state.diasFunc[i].dia === 'sex') {
                                        this.state.diasSemana.push(5)
                                    }
                                    if (this.state.diasFunc[i].dia === 'sab') {
                                        this.state.diasSemana.push(6)
                                    }
                                }
                            }
                        } catch (err) {
                            console.log(err);
                            this.setState({ loadingSave: false, msgErro: 'Erro ao pegar resposta do servidor' });
                        }
                    })
                        .catch((err) => {
                            console.log(err);
                            this.setState({ loadingSave: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
                        });
                }
                break;

            case 1:
                if (this.state.data === '') {
                    this.setState({ msgErro: "Selecione uma data." })
                    return;
                }
                if (this.state.horario === '') {
                    this.setState({ msgErro: "Selecione um horário." })
                    return;
                }
                else {
                    console.log(this.props.logo)
                    this.setState({ loadingSave: false, msgErro: '', page: 2 })
                    return;
                }
                break;
            default:
                console.log("nope")
                break;
        }

    }

    handleDate(e) {
        e.preventDefault();
        if (e.target.value == '') {
            this.setState({ data: e.target.value, msgErro: 'Campo em branco' })
        } else {
            let day = new Date(e.target.value).getUTCDay();
            if (!this.state.diasSemana.includes(day)) {
                this.setState({ data: '', msgErro: 'O Ponto de Coleta não funciona nesse dia da semana.', horariosDia: [] })
            } else {
                if (e.target.value < this.state.coleta.data) {
                    this.setState({ data: '', msgErro: '', horariosDia: [] })
                } else {
                    this.setState({ loadingSearch: true, data: e.target.value, msgErro: '' })

                    fetch(`${URL}api/auth/get_horarios_funcionamento/${this.state.pontoColeta.id}/${day}`, {
                        method: 'GET',
                        headers: {
                            Accept: 'application/json',
                        },
                    })
                        .then(async (responseLog) => {
                            try {
                                let resp = await responseLog.json();
                                if (resp.errors != null || resp.error != null) {
                                    let errors = Object.values(resp.errors);
                                    let erro = '';
                                    for (let i = 0; i < errors.length; i++) {
                                        if (i != errors.length - 1) erro += errors[i] + '\n';
                                        else erro += errors[i];
                                    }
                                    // console.log(erro);
                                    this.setState({ loadingSearch: false, msgErro: erro })
                                } else {
                                    this.setState({ horariosDia: resp.horarios, loadingSearch: false, horario: '' })
                                }
                            } catch (err) {
                                this.setState({ loadingSearch: false, msgErro: 'Erro ao pegar resposta do servidor.' })
                                console.log(err);
                            }
                        })
                        .catch((err) => {
                            this.setState({ loadingSearch: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' })
                            console.log(err);
                        });
                }
            }
        }
    }

    pdf() {
        const doc = new jsPDF();
        var width = doc.internal.pageSize.getWidth()

        // let img = new Image();
        // img.src = this.props.logo
        // Header PDF
        doc.addImage(LOGOB64, 'png', 10, 5, 27, 15)
        doc.setFontSize(12).setFont('Helvetica', 'normal')
        doc.text(`| ${this.state.coleta.codigo_solicitacao} |`, width - 10, 15, { align: 'right' });
        doc.setDrawColor('#198754').line(5, 25, width - 5, 25, 'FD')

        // Body (BOLD) PDF
        doc.setFontSize(20).setFont('Helvetica', 'bold').text('RESUMO DO AGENDAMENTO DA COLETA', width / 2, 40, { align: 'center' });
        doc.setFontSize(16).text(`Informações sobre o ponto de coleta`, width / 2, 65, { align: 'center' }).setFontSize(14);
        doc.line(70, 75, 70, 118)
        doc.text(25, 80, `Nome`);
        doc.text(25, 92, `Endereço`);
        doc.text(25, 104, `Cidade`);
        doc.text(25, 116, `CEP`);

        doc.setFontSize(16).text(`Responsável pelo ponto de coleta`, width / 2, 132, { align: 'center' }).setFontSize(14);
        doc.line(70, 139, 70, 170)
        doc.text(25, 144, `Nome`);
        doc.text(25, 156, `Telefone`);
        doc.text(25, 168, `E-mail`);

        doc.setFontSize(16).text(`Informações sobre a retirada`, width / 2, 184, { align: 'center' }).setFontSize(14);
        doc.text(25, 196, `Data retirada`);
        doc.text(25, 208, `Hora retirada`);
        doc.text(25, 220, `Materiais`);

        // Body (NORMAL) PDF
        doc.setFont('Helvetica', 'normal').setFontSize(14)
        doc.text(80, 80, `${this.state.pontoColeta.nome_fantasia}`)
        doc.text(80, 92, `${this.state.pontoColeta.logradouro}, Nº ${this.state.pontoColeta.numero} - ${this.state.pontoColeta.bairro}`)
        doc.text(80, 104, `${this.state.pontoColeta.cidade}, ${this.state.pontoColeta.estado}`)
        doc.text(80, 116, `${this.state.pontoColeta.cep}`)

        doc.text(80, 144, `${this.state.responsavelColeta.name}`)
        doc.text(80, 156, `${this.state.responsavelColeta.user_dado.telefone}`)
        doc.text(80, 168, `${this.state.responsavelColeta.email}`)

        doc.text(80, 196, `${this.state.data.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')}`)
        doc.text(80, 208, `Entre ${this.state.horario.hora_inicio} e ${this.state.horario.hora_fim}`)
        this.state.materialColeta.map((item, id) => {
            doc.text(80, 220 + (id * 8), `• ${item.residuo_rejeito[0].nome} - ${item.peso} ${item.residuo_rejeito[0].unidade_principal.sigla}`)
            doc.line(70, 191, 70, 220 + (id * 8) + 2)
        })
        
        // Footer PDF
        doc.line(5, 275, width - 5, 275)
        let year = new Date();
        doc.setFontSize(10).setTextColor('#198754');
        doc.text(`© Blueen, ${year.getFullYear()} `, width / 2, 285, { align: 'center' })//posicionar no fim de cada página (n vai ocorrer de haver mais que uma página, mas é bom previnir)

        // Save PDF
        doc.save(`resumo_agendamento_${this.state.coleta.codigo_solicitacao}.pdf`);
        window.open(doc.output('bloburl'));
    }

    render() {
        return (
            <div className="col-12 d-block" style={{ overflow: 'hidden', backgroundImage: `url(${BG})`, backgroundSize: 'cover', padding: 0, margin: 0, height: '100vh' }}>
                <div className="row" style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="col-10 col-sm-8 col-md-6 col-xl-4">
                        <div className="card" style={{ margin: 0 }}>
                            <div className="card-body" style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                                <div className="text-center">
                                    <a className="logo"><img src={this.props.logo}
                                        style={{
                                            objectFit: 'contain',
                                            height: '100px',
                                            width: '90%'
                                        }} height="60" alt="logo" /></a>
                                </div>
                                <div style={{ padding: '1rem', paddingBottom: 0 }}>
                                    <form className="form-horizontal m-t-30" onSubmit={(e) => this.handleSubmit(e)}>
                                        {this.state.page == 0 && <div>
                                            <h4 className="font-18 m-b-5 text-center header-title">Confirme seu e-mail</h4>
                                            <p className="text-muted text-center" >Apenas por segurança, confirme seu e-mail o código de autenticação
                                                <BsFillQuestionCircleFill
                                                    style={{ padding: '2px' }}
                                                    data-tip="No e-mail que você recebeu, o código é encontrado acima do botão 'Atender Solicitação'" />
                                                <ReactTooltip place="bottom" effect="float" />
                                            </p>
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label" style={{ paddingLeft: 0, paddingRight: 0 }}>E-mail <b style={{ color: 'red' }}>*</b></label>
                                                <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                    <input id="email" type="text"
                                                        className="form-control  " name="email"
                                                        autoComplete="email" autoFocus
                                                        value={this.state.email}
                                                        onChange={(e) => this.setState({ email: e.target.value })} />
                                                </div>
                                                <label className="col-sm-12 col-form-label" style={{ paddingLeft: 0, paddingRight: 0 }}>Código <b style={{ color: 'red' }}>*</b></label>
                                                <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                    <input id="email" type="text"
                                                        className="form-control  " name="email"
                                                        autoComplete="email" autoFocus
                                                        value={this.state.codigo}
                                                        onChange={(e) => this.setState({ codigo: e.target.value })} />
                                                </div>
                                            </div>
                                        </div>}
                                        {this.state.page == 1 && <div>
                                            <h4 className="font-18 m-b-5 text-center header-title">Agendar Coleta</h4>
                                            <p className="text-muted text-center" >Selecione o dia para a Coleta do dia {this.state.coleta.data.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')}.
                                                <br />Ponto de Coleta: {this.state.pontoColeta.nome_fantasia}</p>
                                            <label className="col-sm-12 col-form-label" style={{ paddingLeft: 0, paddingRight: 0 }}>Data <b style={{ color: 'red' }}>*</b></label>
                                            <div className='form-group row'>
                                                <div className="col-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                                    <input id='date' type="date"
                                                        min={this.state.coleta.data}
                                                        autoComplete="date" autoFocus
                                                        className="form-control" name="date"
                                                        value={this.state.data}
                                                        onChange={(e) => {
                                                            this.handleDate(e);
                                                        }} />
                                                </div>
                                            </div>
                                            {this.state.loadingSearch &&
                                                <div className="row mt-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <div className="spinner-border text-primary" role="status"></div></div>
                                            }
                                            {this.state.horariosDia.length > 0 && !this.state.loadingSearch &&
                                                <div className='form-group row'>
                                                    <label className="col-sm-12 col-form-label" style={{ paddingLeft: 0, paddingRight: 0 }}>Horários Disponíveis <b style={{ color: 'red' }}>*</b></label>
                                                    {this.state.horariosDia.map((item, id) => (
                                                        <div key={id} className='col-12 col-sm-6 mt-3 m-auto'>
                                                            <div className='card text-center'>
                                                                <div className='card-body'>
                                                                    <h5 className='card-title'>{item.hora_inicio} - {item.hora_fim}</h5>
                                                                    {this.state.horario.id != item.id && <button onClick={() => {
                                                                        this.setState({ horario: item, msgErro: '' })
                                                                    }
                                                                    } className='btn btn-primary'>Selecionar</button>}
                                                                    {this.state.horario.id == item.id && <button className='btn btn-primary' disabled>
                                                                        Selecionado
                                                                    </button>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>}
                                        </div>}
                                        <div className="form-group row ">
                                            <div className="col-sm-12" style={{ padding: 0 }}>
                                                <span className="invalid-feedback" style={{ display: 'flex', justifyContent: 'center' }} role="alert">
                                                    <strong style={{ textAlign: 'center', fontSize: '0.8rem' }}>{this.state.msgErro}</strong>
                                                </span>
                                            </div>
                                        </div>
                                        <div className='form-group row'>
                                            <div className="col-sm-12 mt-3 text-right" style={{ padding: 0 }}>
                                                {!this.state.loadingSave && this.state.page < 2 && <div>
                                                    <button className="btn btn-primary w-md waves-effect waves-light" style={{ width: '100%', marginTop: '15px', height: '3rem', }} type="submit">Continuar</button>
                                                </div>}
                                                {this.state.loadingSave &&
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> <div className="spinner-border text-primary" role="status"></div></div>
                                                }
                                            </div>
                                        </div>
                                    </form>
                                    {this.state.page == 2 && <div className='m-t-30'>
                                        <h4 className='font-18 text-center header-title'>Agendamento realizado com sucesso!</h4>
                                        <p className="text-muted text-center mb-3" >Confira abaixo o resumo geral dessa solicitação de coleta.</p>
                                        <ul>
                                            <li><span style={{ fontWeight: 'bold' }}>Ponto de Coleta: </span>{this.state.pontoColeta.nome_fantasia}</li>
                                            <li><span style={{ fontWeight: 'bold' }}>Endereço: </span>{this.state.pontoColeta.logradouro}, Nº {this.state.pontoColeta.numero} - {this.state.pontoColeta.bairro}</li>
                                            <li><span style={{ fontWeight: 'bold' }}>Cidade: </span>{this.state.pontoColeta.cidade}, {this.state.pontoColeta.estado} - CEP: {this.state.pontoColeta.cep}</li>
                                            <li><span style={{ fontWeight: 'bold' }}>Responsável: </span>{this.state.responsavelColeta.name}</li>
                                            <li><span style={{ fontWeight: 'bold' }}>Telefone do Responsável: </span>{this.state.responsavelColeta.user_dado.telefone}</li>
                                            <li>{this.state.materialColeta.length <= 1 ? <span style={{ fontWeight: 'bold' }}>Material</span> : <span style={{ fontWeight: 'bold' }}>Materiais</span>} <ul>{this.state.materialColeta.map((item, id) => (
                                                <li key={id}>{item.residuo_rejeito[0].nome} - {item.peso} {item.residuo_rejeito[0].unidade_principal.sigla}</li>
                                            ))
                                            }</ul></li>
                                            <li><span style={{ fontWeight: 'bold' }}>Data da Retirada: </span>{this.state.data.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')}</li>
                                            <li><span style={{ fontWeight: 'bold' }}>Horário da Retirada: </span>{this.state.horario.hora_inicio} - {this.state.horario.hora_fim}</li>
                                        </ul>
                                        <button className='btn btn-primary my-3' style={{ width: '100%', marginTop: '15px', height: '3rem', }} type='button' onClick={(e) => {
                                            this.pdf()
                                        }}>Gerar PDF  <BsSave /></button>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}

const mapsStateToProps = (state) => (
    {
        logo: state.AppReducer.logo,
    }
);

export default connect(mapsStateToProps)(AgendarSolicitacao)