import React from 'react'
import { MdSearch } from 'react-icons/md';

const SearchInput = ({ setSearch, placeholder, mask }) => {
  const [searchMask, setSearchMask] = React.useState('')
  const timerRef = React.useRef(null)

  const handleSearch = ({ target }) => {
    setSearchMask(mask(target.value))

    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setSearch(target.value)
    }, 750);
  }

  return (
    <form className="app-search mt-1" style={{ width: '100%' }}>
      <div className="form-group mb-0" style={{ width: '100%', position: 'relative' }}>
        <input
          value={searchMask}
          name="search"
          placeholder={placeholder}
          className="form-control"
          style={{ width: '100%', paddingRight: 40, borderRadius: 100 }}
          onChange={handleSearch} />

        <div style={{ width: 40, position: "absolute", right: 0, top: 0, bottom: 0, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
          <MdSearch />
        </div>

      </div>
    </form>
  )
}

SearchInput.defaultProps = {
  setSearch: () => console.log('setState'),
  mask: (value) => value,
  placeholder: 'Pesquisar...'
}

export default SearchInput