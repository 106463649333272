import React, { useEffect, useState } from 'react'
import { URL } from '../../../variables'

const Permissoes = ({ state, setState, contentRef, token }) => {

  const [permissaoNotificacao, setPermissaoNotificacao] = useState(false);

  useEffect(()=>{
    changeTipo(state.tipo.value)
  }, [state.tipo.value])

  function changeTipo(id){
    fetch(`${URL}api/get_tipo/${id}`, {
      method: 'GET',
      headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
          // 'Content-Type': 'application/json',
      }
    }).then(async (responseLog) => {
        let resp = await responseLog.json()
        let item = resp.permissoes_notificacoes?.["destinacaointerna.ativar_notificacoes"]
        if (item?.has_access) {
            setPermissaoNotificacao(item?.has_access)
          }else{
          setPermissaoNotificacao(false)
        }
    }).catch(() => setPermissaoNotificacao(false))    
  }

  return (
    <div className="rowe">
      <div className="form-group row">
        <label className="col-12 col-form-label" >Terá acesso ao sistema? <b style={{ color: 'red' }}></b></label>

        <div className="col-sm-12 d-flex align-items-center">
          <label className="form-check-label" htmlFor="acesso" style={{ marginRight: 10 }}>Não</label>

          <div className="form-check form-switch col-12 col-md-4 col-lg-4">

            <input className="form-check-input" type="checkbox" id="acesso" checked={state.acessoSistema} onChange={(e) => {
              contentRef.current = undefined
              setState({ ...state, acessoSistema: e.target.checked, password: { value: '', error: false }, confirm_password: { value: '', error: false }, tipo: { value: '', error: false } });
            }} />
            <label className="form-check-label" htmlFor="acesso">Sim</label>
          </div>
        </div>
      </div>

      <div hidden={state.acessoSistema == false}>
        <hr />


        {/* <div className="form-group row">
          <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Senha <b style={{ color: 'red' }}>*</b></label>

          <div className="col-sm-12">
            <input className={`form-control ${state.password.error && 'is-invalid'}`} placeholder="Senha" type="password" value={state.password.value} onChange={(e) => setState({ ...state, password: { value: e.target.value, error: false }, error2Step: false, confirm_password: { ...state.confirm_password, error: false } })} />

          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Confirmar senha <b style={{ color: 'red' }}>*</b></label>

          <div className="col-sm-12">
            <input className={`form-control ${state.confirm_password.error && 'is-invalid'}`} placeholder="Confirmar senha" value={state.confirm_password.value} type="password" onChange={(e) => setState({ ...state, confirm_password: { value: e.target.value, error: false }, error2Step: false, password: { ...state.password, error: false } })} />

          </div>
        </div> */}

        <div className="form-group row">
          <label className="col-sm-12 col-form-label">Tipo <b style={{ color: 'red' }}>*</b></label>
          <div className="col-sm-12">
            {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
            <select value={state.tipo.value} className={`form-control ${state.tipo.error && 'is-invalid'}`} id="cameras" onChange={(e) => setState({ ...state, tipo: { value: e.target.value, error: false }, error2Step: false })}>
              <option value={''}>{'Selecione um tipo de usuário'}</option>
              {state.tipos.map((item, id) => (
                <option key={id} value={item.id}>{item.nome}</option>

              ))}


            </select>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-12 col-md-6 col-lg-4">

            <div className="form-group row">
              <label className="col-12 col-form-label" >Usuário pode ser contato de um destino? <b style={{ color: 'red' }}></b></label>

              <div className="col-sm-12 d-flex align-items-center">
                <label className="form-check-label" htmlFor="contatodestino" style={{ marginRight: 10 }}>Não</label>

                <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                  <input className="form-check-input" type="checkbox" id="contatodestino" checked={state.acesso['ContatoDestino']} onChange={(e) => {
                    let acesso = { ...state.acesso };
                    acesso['ContatoDestino'] = e.target.checked;
                    setState({ ...state, acesso: acesso });
                  }} />
                  <label className="form-check-label" htmlFor="contatodestino">Sim</label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">

            <div className="form-group row">
              <label className="col-12 col-form-label" >Usuário pode ser responsável por um ponto de coleta? <b style={{ color: 'red' }}></b></label>

              <div className="col-sm-12 d-flex align-items-center">
                <label className="form-check-label" htmlFor="responsavelpontocoleta" style={{ marginRight: 10 }}>Não</label>

                <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                  <input className="form-check-input" type="checkbox" id="responsavelpontocoleta" checked={state.acesso['ResponsavelPontoColeta']} onChange={(e) => {
                    let acesso = { ...state.acesso };
                    if (e.target.checked == true) {
                      acesso['PessoaAutorizadaPontoColeta'] = e.target.checked;
                    }
                    acesso['ResponsavelPontoColeta'] = e.target.checked;
                    setState({ ...state, acesso: acesso });
                  }} />
                  <label className="form-check-label" htmlFor="responsavelpontocoleta">Sim</label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">

            <div className="form-group row">
              <label className="col-12 col-form-label" >Usuário pode ser uma pessoa autorizada em um ponto de coleta? <b style={{ color: 'red' }}></b></label>

              <div className="col-sm-12 d-flex align-items-center">
                <label className="form-check-label" htmlFor="autorizadopontocoleta" style={{ marginRight: 10 }}>Não</label>

                <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                  <input className="form-check-input" type="checkbox" id="autorizadopontocoleta" checked={state.acesso['PessoaAutorizadaPontoColeta']} onChange={(e) => {
                    let acesso = { ...state.acesso };
                    if (acesso['ResponsavelPontoColeta'] == true) {
                      return false;
                    } else {

                      acesso['PessoaAutorizadaPontoColeta'] = e.target.checked;
                      setState({ ...state, acesso: acesso });
                    }
                  }} />
                  <label className="form-check-label" htmlFor="autorizadopontocoleta">Sim</label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">

            <div className="form-group row">
              <label className="col-12 col-form-label" >Usuário pode ser responsável por um estoque? <b style={{ color: 'red' }}></b></label>

              <div className="col-sm-12 d-flex align-items-center">
                <label className="form-check-label" htmlFor="responsavelestoque" style={{ marginRight: 10 }}>Não</label>

                <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                  <input className="form-check-input" type="checkbox" id="responsavelestoque" checked={state.acesso['ResponsavelEstoque']} onChange={(e) => {
                    let acesso = { ...state.acesso };
                    if (e.target.checked == true) {
                      acesso['PessoaAutorizadaEstoque'] = e.target.checked;
                    }
                    acesso['ResponsavelEstoque'] = e.target.checked;
                    setState({ ...state, acesso: acesso });
                  }} />
                  <label className="form-check-label" htmlFor="responsavelestoque">Sim</label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">

            <div className="form-group row">
              <label className="col-12 col-form-label" >Usuário pode ser uma pessoa autorizada em um estoque? <b style={{ color: 'red' }}></b></label>

              <div className="col-sm-12 d-flex align-items-center">
                <label className="form-check-label" htmlFor="autorizadoestoque" style={{ marginRight: 10 }}>Não</label>

                <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                  <input className="form-check-input" type="checkbox" id="autorizadoestoque" checked={state.acesso['PessoaAutorizadaEstoque']} onChange={(e) => {
                    let acesso = { ...state.acesso };
                    if (acesso['ResponsavelEstoque'] == true) {
                      return false;
                    } else {
                      acesso['PessoaAutorizadaEstoque'] = e.target.checked;
                    }
                    console.log(acesso)
                    setState({ ...state, acesso: acesso });
                  }} />
                  <label className="form-check-label" htmlFor="autorizadoestoque">Sim</label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">

            <div className="form-group row">
              <label className="col-12 col-form-label" >Usuário pode ser responsável em uma transportadora? <b style={{ color: 'red' }}></b></label>

              <div className="col-sm-12 d-flex align-items-center">
                <label className="form-check-label" htmlFor="responsaveltransportadora" style={{ marginRight: 10 }}>Não</label>

                <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                  <input className="form-check-input" type="checkbox" id="responsaveltransportadora" checked={state.acesso['ResponsavelTransportadora']} onChange={(e) => {
                    let acesso = { ...state.acesso };
                    acesso['ResponsavelTransportadora'] = e.target.checked;
                    setState({ ...state, acesso: acesso });
                  }} />
                  <label className="form-check-label" htmlFor="responsaveltransportadora">Sim</label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">

            <div className="form-group row">
              <label className="col-12 col-form-label" >Usuário pode ser responsável em um registro de entrada (coleta e transporte)? <b style={{ color: 'red' }}></b></label>

              <div className="col-sm-12 d-flex align-items-center">
                <label className="form-check-label" htmlFor="responsavelentradacoleta" style={{ marginRight: 10 }}>Não</label>

                <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                  <input className="form-check-input" type="checkbox" id="responsavelentradacoleta" checked={state.acesso['ResponsavelRegistroEntradaCT']} onChange={(e) => {
                    let acesso = { ...state.acesso };
                    acesso['ResponsavelRegistroEntradaCT'] = e.target.checked;
                    setState({ ...state, acesso: acesso });
                  }} />
                  <label className="form-check-label" htmlFor="responsavelentradacoleta">Sim</label>
                </div>
              </div>
            </div>
          </div>


          <div className="col-12 col-md-6 col-lg-4">

            <div className="form-group row">
              <label className="col-12 col-form-label" >Usuário pode ser responsável em uma solicitação de coleta? <b style={{ color: 'red' }}></b></label>

              <div className="col-sm-12 d-flex align-items-center">
                <label className="form-check-label" htmlFor="responsavelsolicitacaocoleta" style={{ marginRight: 10 }}>Não</label>

                <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                  <input className="form-check-input" type="checkbox" id="responsavelsolicitacaocoleta" checked={state.acesso['ResponsavelSolicitacaoColeta']} onChange={(e) => {
                    let acesso = { ...state.acesso };
                    acesso['ResponsavelSolicitacaoColeta'] = e.target.checked;
                    setState({ ...state, acesso: acesso });
                  }} />
                  <label className="form-check-label" htmlFor="responsavelsolicitacaocoleta">Sim</label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 col-lg-4">

            <div className="form-group row">
              <label className="col-12 col-form-label" >Usuário pode ser responsável em um registro de entrada (gestão interna)? <b style={{ color: 'red' }}></b></label>

              <div className="col-sm-12 d-flex align-items-center">
                <label className="form-check-label" htmlFor="responsavelentradainterna" style={{ marginRight: 10 }}>Não</label>

                <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                  <input className="form-check-input" type="checkbox" id="responsavelentradainterna" checked={state.acesso['ResponsavelRegistroEntradaGI']} onChange={(e) => {
                    let acesso = { ...state.acesso };
                    acesso['ResponsavelRegistroEntradaGI'] = e.target.checked;
                    setState({ ...state, acesso: acesso });
                  }} />
                  <label className="form-check-label" htmlFor="responsavelentradainterna">Sim</label>
                </div>
              </div>
            </div>
          </div>

          {permissaoNotificacao && <div className="col-12 col-md-6 col-lg-4">
            <div className="form-group row">
              <label className="col-12 col-form-label" >Usuário recebe notificações de todo sistema ou apenas as direcionadas à ele? <b style={{ color: 'red' }}></b></label>

              <div className="col-sm-12 d-flex align-items-center">
                <label className="form-check-label" htmlFor="notificacaoIndividual" style={{ marginRight: 10 }}>Geral</label>

                <div className="form-check form-switch col-12 col-md-4 col-lg-4">

                  <input className="form-check-input" type="checkbox" id="notificacaoIndividual" checked={state.acesso['NotificacaoIndividual']} onChange={(e) => {
                    let acesso = { ...state.acesso };
                    acesso['NotificacaoIndividual'] = e.target.checked;
                    setState({ ...state, acesso: acesso });
                  }} />
                  <label className="form-check-label" htmlFor="notificacaoIndividual">Apenas para ele</label>
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default Permissoes