import React from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { URL } from '../../../variables';
import Pesagem from './Pesagem';

const EditPesagem = () => {
  const [state, setState] = React.useState({
    pesagem_id: '',
    residuos: [],
    token: '',
  })
  const params = useParams();
  let id = params.id;
  state.token = useSelector(state => state.AppReducer.token);

  React.useEffect(() => {
    fetch(`${URL}api/get_pesagem_residuos/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        console.log('json', json)
        setState({
          ...state,
          pesagem_id: json.pesagem.id,
          residuos_selecionados: json.residuos_selecionados,
          residuos: json.residuos,
        })
      })
  }, [])

  return (
    <div>
      {state.pesagem_id && <Pesagem edit={state} setEdit={setState} />}
    </div>
  )
}

export default EditPesagem