import React from 'react';
import { GET } from '../../../Auxiliar/Requests';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';

import { ArcherContainer, ArcherElement } from 'react-archer';

import { Box, Card, Typography } from '@mui/material';
import { MdReply } from 'react-icons/md';

const Stage = {
  CollectionRequest: 0,
  Dispatch: 1,
  Receipt: 2,
  Finish: 3,
};

const getStageName = (stage) =>
  ({
    [Stage.CollectionRequest]: 'Solicitação de Coleta',
    [Stage.Dispatch]: 'Despacho/Destinação',
    [Stage.Receipt]: 'Recebimento',
    [Stage.Finish]: 'Recebimento Finalizado',
  }[stage]);

const Rastreabilidade = () => {
  const history = useHistory();
  const { id } = useParams();
  const archerContainerRef = React.useRef(null);

  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [selectedMaterial, setSelectedMaterial] = React.useState(0);

  React.useEffect(() => {
    getData();
  }, []);

  //-------------------------*-------------------------
  const getData = async () => {
    const response = await GET({ url: `rastreabilidade/${id}` });

    if (!response) {
      console.log('error');
      history.push('/acompanhar_etapas');
      return;
    }

    setData(response.data);
    setLoading(false);
  };

  return (
    <div className="card">
      <div className="card-body" id="card">
        {!loading && data && (
          <>
            <h1 className="mb-4 header-title" style={{ fontSize: '1.5rem' }}>
              Rastreabilidade
            </h1>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label">Materiais</label>
              <div className="col-sm-12">
                <select
                  value={selectedMaterial}
                  className="form-control"
                  onChange={(e) => setSelectedMaterial(Number(e.target.value))}
                >
                  <option value={0}>Selecione um material</option>
                  {data.map((item, i) => (
                    <option key={i} value={i + 1}>
                      {item.root.waste}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {selectedMaterial > 0 && (
              <div
                className="my-3"
                style={{ overflow: 'scroll' }}
                onScroll={() => archerContainerRef?.current?.refreshScreen()}
              >
                <ArcherContainer strokeColor="black" ref={archerContainerRef}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 8,
                    }}
                  >
                    {data[selectedMaterial - 1].tree.map((level, index) => (
                      <Box
                        key={index}
                        sx={{
                          padding: 8,
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 8,
                        }}
                      >
                        {level.map((item) => (
                          <ArcherElement
                            key={item.id}
                            id={item.id}
                            relations={item.childrenIds.map((childId) => ({
                              targetId: childId,
                              targetAnchor: 'left',
                              sourceAnchor: 'right',
                              style: { strokeColor: 'black', strokeWidth: 2 },
                            }))}
                          >
                            <Card
                              sx={{
                                padding: 2,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: 14,
                                  fontWeight: 'bold',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {getStageName(Number(item.item.stage))}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  whiteSpace: 'nowrap',
                                  mb: 1,
                                }}
                              >
                                {item.item.documentId}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  whiteSpace: 'nowrap',
                                  mb: 1,
                                }}
                              >
                                Item: {item.item.documentItemId}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  whiteSpace: 'nowrap',
                                  mb: 1,
                                }}
                              >
                                Ponto de Coleta: {item.location}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  whiteSpace: 'nowrap',
                                  mb: 1,
                                }}
                              >
                                Resíduo/Rejeito: {item.waste}
                              </Typography>
                              <Typography variant="body1">
                                Quantidade: {item.item.amount}
                              </Typography>
                            </Card>
                          </ArcherElement>
                        ))}
                      </Box>
                    ))}
                  </Box>
                </ArcherContainer>
              </div>
            )}
            <div className="d-flex justify-content-between mt-3">
              <button
                type="button"
                className="btn btn-secondary btn-lg waves-effect waves-light"
                onClick={() => history.goBack()}
              >
                <MdReply /> Voltar
              </button>
            </div>
          </>
        )}

        {loading && (
          <div className="col-12 d-flex justify-content-center align-items-center py-5">
            <div className="spinner-border text-primary" role="status"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Rastreabilidade;
