import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import { URL } from '../../../variables';
import moment from 'moment';
import { MdReply } from 'react-icons/md';
import { Alert } from '@mui/material';

function ViewRelatorio() {

  const [state, setState] = React.useState({
    relatorio: {},
    submateriais: [],
    token: '',
    loading: true,
  })
  const params = useParams();
  let id = params.id;
  state.token = useSelector(state => state.AppReducer.token);

  useEffect(() => {
    fetch(`${URL}api/get_view_relatorio/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Authorization': `Bearer ${state.token}`,
        // 'Content-Type': 'application/json',
      }
    })
      .then(async (result) => {
        const json = await result.json();
        return json;
      })
      .then((json) => {
        console.log('json', json)
        let submateriais_iguais = [];
        json.submateriais.map((item, id) => {
          submateriais_iguais.filter((item2, id2) => item2.sub_material.nome.replace(/ /g, '').toLowerCase() == item.sub_material.nome.replace(/ /g, '').toLowerCase())
        })
        setState({
          ...state,
          relatorio: json.relatorio,
          submateriais: json.submateriais,
          loading: false,
        })
      })
  }, [])



  return (
    <div className='row'>
      {state.loading == false && <div>
        <div className='col-12'>
          <div className='card'>
            <div className='card-body'>
              <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Relatório de Submateriais</h4>
              <hr />

              <label className="col-sm-12 col-form-label" style={{ color: 'black', fontSize: '1.5rem' }}>Dados do relatório:</label>
              <div className="form-group row">
                <div className="col-sm-12"><label className="col-sm-12 col-form-label" >Código do Relatório: <span style={{ fontWeight: 'normal' }}>{state.relatorio.codigo_relatorio}</span></label></div>
              </div>
              <div className="form-group row">
                <div className="col-sm-12"><label className="col-sm-12 col-form-label" >Data de Expedição: <span style={{ fontWeight: 'normal' }}>{moment(state.relatorio.created_at).format('DD/MM/YYYY')}</span></label></div>
              </div>

              {state.submateriais.length == 0 && <Alert className='my-3' severity='info'>Esse relatório não possui sub-materiais adicionados</Alert>}

              {state.submateriais.length != 0 && <div className="table-rep-plugin">
                <div className="table-responsive mb-0" data-pattern="priority-columns">
                  <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Submateriais selecionados:</label>
                  <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }}>
                    <thead>
                      <tr>
                        <th>Sub-material</th>
                        <th>Peso Total</th>
                        <th>Unidade de Medida</th>
                        <th>Quantidade</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.loading == false && state.submateriais.map((item, id) => (
                        <tr key={id}>
                          <td>{item.sub_material.nome}</td>
                          <td>{item.quantidade}</td>
                          <td>{item.unidade.sigla}</td>
                          <td>teste</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>}

              <div className="d-grid gap-2 d-md-flex justify-content-md-start">
                <Link to={'/relatorio'}>
                  <button type="button" className="btn btn-secondary btn-lg waves-effect waves-light"><MdReply />Voltar</button>
                </Link>
              </div>

            </div>
          </div>
        </div>
      </div>}
      {state.loading == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
        <div className="spinner-border text-primary" role="status"  >
        </div>
      </div></div>}
    </div>
  )
}

export default ViewRelatorio