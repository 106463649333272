import React, { Component } from 'react'
import { MdInfo, MdReply } from 'react-icons/md';
import { URL } from '../../../variables';
import { GET } from '../../../Auxiliar/Requests';
import { Link, Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { maskMoneyV2 } from '../../../Auxiliar/Masks';
import ReactTooltip from 'react-tooltip';
import { months, timeUnits } from '../../../Auxiliar/options';

class VerCompliance extends Component {
  constructor(props) {
    super(props);
    const { owner, id } = this.props.match.params;
    this.state = {
      owner: owner,
      ownerId: id,
      compliance: '',
      loadingScreen: true,
      redirect: false
    };
  }

  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    const response = await GET({ url: `view_compliance/${this.state.owner}/${this.state.ownerId}` })
    if (response.error) {
      console.log('error', response)
      this.setState({ redirect: true })
      return
    }

    const contentList = response.compliance.contents.map(item => {
      let unity = ''
      let dateLabel = ''
      let notificationLabel = ''

      if (item.type === 'Quantidade') unity = ' KG'
      if (item.type === 'Volume') unity = ' LT'
      if (item.type === 'Validade') {
        // If the accumulator is still empty, we don't add a comma before the label, otherwise we add a comma before the label
        notificationLabel = item?.notifications.reduce((accumulator, notification) => {
          if (accumulator === '') return `${notification.notify_on} ${timeUnits.find(unit => unit.value === notification.type).label} antes`
          else return `${accumulator}, ${notification.notify_on} ${timeUnits.find(unit => unit.value === notification.type).label} antes`
        }, '')
      } else {
        notificationLabel = `${item.notification}% da capacidade máxima`
      }

      if (item.type === 'Validade') {
        const [year, month, day] = item.expiration_date.split("-");
        dateLabel = `Válido até ${day}/${month}/${year}`
      }

      if (item.monthly_start_date && item.type !== 'Validade') {
        dateLabel = `Inicio em ${months.find(month => month.value === item.monthly_start_date)?.name}`
      }
      else if (item.annual_start_date && item.type !== 'Validade') {
        dateLabel = `Inicio em ${item.annual_start_date}`
      }

      return {
        ...item,
        unity,
        notification_label: notificationLabel,
        content_label: item.type === 'Saldo Financeiro' ? maskMoneyV2(item.content) : item.content,
        date_label: dateLabel
      }
    })
    this.setState({ compliance: { ...response.compliance, contentList }, loadingScreen: false })
  }

  // Function to get the correct data according to the url
  getOwner = () => {
    switch (this.state.owner) {
      case 'destinos':
        return 'Destino'
      case 'pontos_coleta':
        return 'Ponto de coleta'
      case 'transportadoras':
        return 'Transportadora'
      case 'veiculos':
        return 'Veículo'
      case 'motoristas':
        return 'Motorista'
      default:
        this.setState({ redirect: true })
        return
    }
  }

  render() {
    return (
      <div className="row">
        {this.state.redirect && this.props.history.goBack()}
        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
              <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Visualizar documentação</h4>
              <p style={{ fontSize: '1rem', color: 'black' }}>Gerencie a sua documentação complience</p>

              {!this.state.loadingScreen ?
                <section>
                  <div className="col-12">
                    <label className='col-form-label' htmlFor="name">Nome: <span style={{ fontWeight: 400 }}>{this.state.compliance.name}</span></label>
                  </div>

                  <div className="col-12">
                    <label className='col-form-label' htmlFor="name">Tipo: <span style={{ fontWeight: 400 }}>{this.state.compliance.type}</span></label>
                  </div>

                  <div className="col-12">
                    <label className='col-form-label' htmlFor="name">{this.getOwner() + ': '}
                      <span style={{ fontWeight: 400 }}>{this.state.compliance[this.state.owner].name}</span>
                    </label>
                  </div>

                  <div className="col-12">
                    <label className='col-form-label' htmlFor="name">Responsável: <span style={{ fontWeight: 400 }}>{this.state.compliance.responsible}</span></label>
                  </div>

                  <div className="col-12">
                    <label className="col-sm-12 col-form-label">Valores monitorados:</label>
                    <table id="tech-companies-1" className="table table-striped table-hover mt-2" >
                      <thead>
                        <tr>
                          <th>Tipo</th>
                          <th>Quantidade</th>
                          <th>Data</th>
                          <th>Notificar em</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.compliance.contents.length === 0 && <tr>
                          <td colSpan="5" style={{ textAlign: 'center' }}>Nenhum valor registrado</td>
                        </tr>}
                        {this.state.compliance.contentList.map((item, index) => (
                          <tr key={index}>
                            <td>{item.type}</td>
                            <td>{item.content ? item.content_label + item.unity : 'N/A'}</td>
                            <td>{item.date_label}</td>
                            <td>{item.notification_label} {item.type !== 'Validade' &&
                              <span
                                className="pointer"
                                data-tip={item.type !== 'Saldo Financeiro'
                                  ? (item.content * (item.notification / 100) + item.unity)
                                  : maskMoneyV2(item.content * (item.notification / 100))}>
                                <ReactTooltip />
                                <MdInfo color='#333' size={18} />
                              </span>}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="col-12">
                    <label className='col-form-label' htmlFor="name">Anexo: </label>
                    <div className="pdf-view">
                      <iframe className='w-100 h-100' src={`${URL}storage/${this.state.compliance.document}`}></iframe>
                    </div>
                  </div>

                  <div className="col-12 mt-5">
                    <button
                      className="btn btn-secondary btn-lg waves-effect waves-light"
                      onClick={() => this.props.history.goBack()}>
                      <MdReply /> Voltar
                    </button>
                  </div>
                </section>
                :
                <div className='d-flex justify-content-center p-5'>
                  <div className="spinner-border text-primary" role="status"></div>
                </div>}

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default VerCompliance
