
import React from 'react';
import ListaRelatoriosGestaoInterna from './ListaRelatoriosGestaoInterna';

const SelecionarJsRelatoriosInterna = () => {
    const [search, setSearch] = React.useState(false)
    const [dateOf, setDateOf] = React.useState('')
    const [dateFor, setDateFor] = React.useState('')

    return (
        <div className="card">
            <div className="card-body" id="card">
                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Dashboard</h4>
                <div>
                    <p style={{ fontSize: '1rem', color: 'black' }}>Lista de relatórios e indicadores disponíveis para melhor gestão interna</p>
                </div>
                <div className="row">
                    <ListaRelatoriosGestaoInterna dateOf={dateOf} dateFor={dateFor} search={search} setSearch={setSearch} />
                </div>
            </div>
        </div>
    )
}

export default SelecionarJsRelatoriosInterna
