import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { BsCheckLg } from 'react-icons/bs';
import { IconButton, Step, StepLabel, Stepper, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import { URL } from '../../../variables';
import { MdClose } from 'react-icons/md';
import EditarTratamentoSigor from './EditarTratamentoSigor';
import SigorRelacionadas from './SigorRelacionadas';
import Theme from '../../Theme';

const style = {
  position: 'relative',
  // top: '50%',
  left: '50%',
  transform: 'translate(0, 0, 0, 50%)',
  width: '50%',
  height: '100vh',
  bgcolor: 'background.paper',
  border: '2px solid transparent',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  '@media(max-width: 1000px)': {
    width: '90%',
    left: '5%',
  },
};

const ModalTratamento = (props) => {
  const [state, setState] = React.useState({
    loading: true,
    codigo: '',
    descricao: '',
    msgErro: '',
  })
  const [tratamentos, setTratamentos] = React.useState({
    loading: true,
    tratamento_id: '',
    tratamentos: [],
    all_tratamentos: [],
    tratamentos_selecionados: [],
  })
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const token = useSelector((state) => state.AppReducer.token);

  React.useEffect(() => {
    if (open === true) {
      fetch(`${URL}api/get_tratamento_sigor/${props.id}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'application/json',
        },
      })
        .then(async (responseLog) => {
          let resp = await responseLog.json();
          console.log(resp)
          setState({ ...state, loading: false, descricao: resp.tratamento.descricao });
          fill_tratamentos()
        })

      function fill_tratamentos() {
        fetch(`${URL}api/fill_tratamentos_sigor`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }).then(async (response) => {
          const resp = await response.json()
          console.log('teste2', tratamentos)
          setTratamentos({ ...tratamentos, tratamentos: resp.tratamentos, all_tratamentos: resp.tratamentos, tratamento_id: resp.tratamentos[0].id, loading: false })
        })
      }
    }
  }, [open]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function salvar() {
    const obj_tratamento = {
      codigo: state.codigo,
      tratamento_id: props.id,
      descricao: state.descricao,
      tratamentos: tratamentos.tratamentos_selecionados
    };

    props.storeTratamento(obj_tratamento);
    setOpen(false);
  }

  function renderPage() {
    if (page === 1) return <EditarTratamentoSigor id={props.id} state={state} setState={(e) => setState(e)} />
    if (page === 2) return <SigorRelacionadas id={props.id} state={tratamentos} setState={(e) => setTratamentos(e)} />
  }

  return (
    <>
      <button className="btn btn-primary btn-outline btn-circle me-1 mb-1" data-tip="Inserir" onClick={handleOpen} disabled={props.disabled}>
        <BsCheckLg />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ThemeProvider theme={Theme}>
          <Box sx={style}>
            <>
              <IconButton onClick={handleClose}>
                <MdClose />
              </IconButton>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <div className="text-center">
                  <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Cadastrar tratamento</h4>
                  <p style={{ fontSize: '1rem', color: 'black' }}>Cadastre um tratamento do seu sistema</p>
                </div>
              </Typography>

              <Stepper activeStep={page - 1} alternativeLabel>
                <Step>
                  <StepLabel color='success'>Dados de Tratamento</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Tratamentos Relacionados</StepLabel>
                </Step>
              </Stepper>

              {!state.loading ?
                <div>
                  <div>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      {renderPage()}
                    </Typography>
                  </div>

                  <div className="d-flex mt-3">
                    <div className="align-self-center">
                      <Button sx={{ color: 'text.main' }} variant="contained" size="large" onClick={() => setPage(page - 1)}
                        disabled={page === 1}>
                        Voltar
                      </Button>
                    </div>
                    <div className="align-self-end ms-auto">
                      {page < 2 && (
                        <Button sx={{ color: 'text.main' }} variant="contained" size="large" onClick={() => setPage(2)}
                          disabled={page === 2}>
                          Próximo
                        </Button>
                      )}
                      {page === 2 && (
                        <Button sx={{ color: 'text.main' }} className="align-self-end" variant="contained" size="large" onClick={() => salvar()}>
                          Salvar
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                :
                <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                  <div className="spinner-border text-primary" role="status"></div>
                </div>}

              {/* <div style={{ display: 'flex', flexBasis: 1, alignItems: 'flex-end', height: '100%' }}>
              <p>teste</p>
            </div> */}
            </>
          </Box>
        </ThemeProvider>
      </Modal>
    </>
  );
};

export default ModalTratamento;
