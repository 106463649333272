import React, { Component } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import { MdReply } from 'react-icons/md';
import { maskNumber } from '../../../Auxiliar/Masks';

class EditarAcondicionamentoSigor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      path: '/acondicionamentos',
      loading_screen: false,
      codigo: '',
      descricao: '',

      loading_save: false,
      msgErro: '',
    };
  }

  render() {
    return (
      <div className="row">
          <div>
            <div className="row">
              <div className="col-12">
                <div className="form-group row">
                  <label className="col-sm-12 col-form-label">Código <b style={{ color: 'red' }}>*</b></label>
                  <div className="col-sm-12">
                    <input className="form-control" placeholder="Código do acondicionamento" type="text" onChange={(e) =>
                    this.props.setState({...this.props.state, codigo: e.target.value })} value={this.props.state.codigo}/>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-12 col-form-label">Descrição <b style={{ color: 'red' }}>*</b></label>
                  <div className="col-sm-12">
                    <textarea className="form-control" placeholder="Descrição do acondicionamento" type="text"
                    onChange={(e) => this.props.setState({...this.props.state, descricao: e.target.value })} value={this.props.state.descricao} disabled>                     
                    </textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}
const mapsStateToProps = (state) => ({
  token: state.AppReducer.token,
});

export default connect(mapsStateToProps, { logout })(
  EditarAcondicionamentoSigor,
);
