import React, { useEffect } from 'react'
import ListData from './ListData';
import { BsGear } from "react-icons/bs";
import { useHistory } from 'react-router-dom'
import Filter from '../../../Auxiliar/Filter'
import { TbTruckDelivery } from "react-icons/tb";
import OptionsButton from '../../../Auxiliar/OptionsButton';
import { GrDocumentText, GrDocumentPdf, GrDocumentDownload } from "react-icons/gr";
import SearchInput from '../../../Auxiliar/SearchInput';
import DadosFiscaisModal2 from '../../Modais/DadosFiscaisModal2';
import { useSelector } from 'react-redux';
import { URL } from '../../../variables';
import ProcessCDFLoteModal from '../../Modais/GerarCDFLoteModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {  Link } from 'react-router-dom';

const statusDepacho = {
  statusType: {
    multiCheck: true,type:'despacho', label: 'Status do Processo', data: [
      { value: false, label: 'Em Rascunho' },
      { value: false, label: 'Agendado' },
      { value: false, label: 'Andamento' },
      { value: false, label: 'Retirado' },
      { value: false, label: 'Recebido' },
      { value: false, label: 'Finalizado' },
    ],
  },
}

const statusSolicitacao = {
  statusType: {
    multiCheck: true,type:'solicitacao',  label: 'Status do Processo', data: [
      { value: false, label: 'Aguardando Despacho'  },
      { value: false, label: 'Em Andamento' },
      { value: false, label: 'Finalizada' },
    ],
  },
}

const filterOptionsArray = {
  dataType: {
    multiCheck: false, label: 'Tipo do Registro', data: [
      { value: true, label: 'Solicitações' },
      { value: false, label: 'Despachos' },
      { value: false, label: 'Destinações' },
    ],
  },
  statusInvoice: {
    multiCheck: true, label: 'Status NFE', data: [
      { value: false, label: 'Pendente' },
      { value: false, label: 'NFE Vinculada' },
    ],
  },
  statusType: statusSolicitacao.statusType,
  statusMtr: {
    multiCheck: true, label: 'Status MTR', data: [
      { value: false, label: 'Todos' },
      { value: false, label: 'Em Aberto' },
      { value: false, label: 'Com CDF' },
      { value: false, label: 'Sem CDF' },
    ],
  },
}



const AcompanharEtapas = () => {
  let permissions = useSelector(state => state.AppReducer.user.tipo_usuario.permissoes)

  const history = useHistory()

  const [reload, setReload] = React.useState(true)
  const [search, setSearch] = React.useState('')
  const [dateOf, setDateOf] = React.useState('')
  const [dateFor, setDateFor] = React.useState('')
  const [options, setOptions] = React.useState(filterOptionsArray)
  const [checkBoxData, setCheckBoxData] = React.useState([])
  const [checkBoxType, setCheckBoxType] = React.useState('')
  const [selectCollects,setSelectCollects] = React.useState({});
  const [showCheckBox,setShowCheckBox] = React.useState(false);
  const [allowSelect, setAllowSelect] = React.useState(false)
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [isModalOpen, setModalOpen] = React.useState(false);

  //-------------------------*-------------------------
  const getEndPoint = () => options.dataType.data[0].value ? 'despacho' : (options.dataType.data[1].value ?'destinacao':'solicitacoes_coleta')
  console.log(options)
  const toggleIncludeNf = () => setCheckBoxType(checkBoxType === 'nf' ? '' : 'nf')
  //const toggleIncludeMtr = () => setAllowSelect(true)
  const toggleIncludeCdf = () => setAllowSelect(true)
  const token = useSelector((store) => store.AppReducer.token);
 //-------------------------*-------------------------
  const filterDataBy = (dataItem, arrayParams) => {
    if (!dataItem) {
      return
    }
    const filteredItem = Object.fromEntries(
      Object.entries(dataItem).filter(([key, value]) => arrayParams.includes(key))
    );

    return filteredItem
  }

//-------------------------*-------------------------
  const checkBoxCallback = React.useCallback((dataItem) => {
    setCheckBoxData((oldState) => {
      if (oldState.find(item => item.id === dataItem.id)) {
        return oldState.filter(item => item.id !== dataItem.id)
      } else {
        return [...oldState, filterDataBy(dataItem, ['id', 'status', 'created_at', 'quantity', 'balance', 'collect_points'])];
      }
    })
  }, [setCheckBoxData]);

  console.log(permissions)
  console.log(getEndPoint())

  useEffect(()=>{
    console.log(options.statusType.type)
    console.log(options.dataType)

    if(options.statusType.type!='solicitacao' && options.dataType.data[0].value==true){
      let options_={...options};
      options_.statusType=statusSolicitacao.statusType;
      setOptions(options_);
    }
    else if(options.statusType.type=='solicitacao' && (options.dataType.data[1].value==true || options.dataType.data[2].value==true)){
      let options_={...options};
      options_.statusType=statusDepacho.statusType;
      setOptions(options_);
    }
  },[options])

  const enviarSelecionados = async () => {
    if (!dateOf || !dateFor) {
      toast.error('Preencha o campo data no filtro');
    }else if(selectedItems.length === 0){
        toast.error('Selecione pelo menos um item');
    }else{
      setModalOpen(true); 
    }
    // if (options.dataType.data[1].value) {

    //   var listaDestinacaoIds = {
    //     "listaDestinacaoIds": selectedItems
    //   };
    //   await fetch(`${URL}api/sigor/post_processar_MTR_por_lista_Destinacao_Externa_id`, {
    //     method: 'POST',
    //     headers: {
    //       'Authorization': `Bearer ${token}`,
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(listaDestinacaoIds)
    //   })
    //   .then(response => response.json())
    //   .then(data => console.log('Dados recebidos:', data))
    //   .catch(error => console.error('Erro ao enviar dados:', error));
      
    //   window.location.reload();
      
    // } else {
    //   var listaDespachoIds = {
    //     "listaDespachoIds": selectedItems
    //   };

    //   await fetch(`${URL}api/sigor/post_processar_MTR_por_lista_despachoid`, {
    //     method: 'POST',
    //     headers: {
    //       'Authorization': `Bearer ${token}`,
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(listaDespachoIds)
    //   })
    //   .then(response => response.json())
    //   .then(data => console.log('Dados recebidos:', data))
    //   .catch(error => console.error('Erro ao enviar dados:', error));
      
    //   window.location.reload();
    // }
  };

  const closeModal = () => {
    setModalOpen(false); 
  };
  
  return (
    <div className="card">
      <div className="card-body" id="card">
        <div className="d-flex justify-content-between align-items-stretch">
          <div style={{width:'100%'}}>
            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Acompanhamento - Coleta e Transporte</h4>
            <Filter setDateOf={setDateOf} setDateFor={setDateFor} options={options} setOptions={setOptions} setSearch={setReload} />
            <p style={{ fontSize: '1rem', color: 'black' }}>Listando solicitações gerais do sistema</p>
            <div className='row'>
              <div className='col-12 col-md-6 d-flex align-items-center'>

              <SearchInput setSearch={setSearch} placeholder='Pesquisar pelo Ponto de coleta ou Código...' />
              </div>
              <div className='col-12 col-md-6 d-flex justify-content-end'>

              {permissions['realizar_despacho']?.escrita == true && showCheckBox  && <div className="">
                  <Link to={"/despacho/criar?collect="+Object.values(selectCollects).filter((item)=>item!=undefined).map((item)=>item.id).join(',')}><span
                      className="btn btn-primary btn-lg waves-effect waves-light mt-1" >Adicionar despacho</span></Link>
              </div>}
              </div>

            </div>
          </div>

          <div className="d-flex justify-content-between flex-column">
            <div className="ms-auto">
              <OptionsButton
                Icon={<BsGear size={30} />}
                items={[
                  { label: 'Incluir uma ou mais NF', icon: <GrDocumentText />, action: toggleIncludeNf,
                disabled:getEndPoint()=='solicitacoes_coleta'
                },
                  //{ label: 'Incluir um ou mais MTR', icon: <GrDocumentPdf />, action: toggleIncludeMtr,disabled:getEndPoint()=='solicitacoes_coleta' },
                  { label: 'Incluir um ou mais CDF', icon: <GrDocumentDownload />, action: toggleIncludeCdf,disabled:getEndPoint()=='solicitacoes_coleta' },
                  {
                    label: `Incluir nova solicitação`, icon: <TbTruckDelivery size={20} />,
                    disabled: !permissions['solicitacoes_coleta'].escrita, action: () => history.push(`/solicitar_coleta/criar`)
                  },
                  {
                    label:showCheckBox? 'Ocultar seleção': `Incluir despacho/destinação`, icon: <TbTruckDelivery size={20} />,
                    // disabled: (!permissions[getEndPoint() === 'despacho' ? 'realizar_despacho' : 'destinacao'].escrita) || getEndPoint()!='solicitacoes_coleta',
                     action: () => {
                      // history.push(`/despacho/criar`)
                      if(showCheckBox==false){
                        let options_ = {...options};
                        options_.dataType.data[0].value=true;
                        options_.dataType.data[1].value=false;
                        options_.dataType.data[2].value=false;
                        options_.statusType=statusSolicitacao.statusType;
                        options_.statusType.data[0].value=true;

                        setOptions(options_)
                        setShowCheckBox(true);
                        setReload('reload');
                      }
                      else{
                        setSelectCollects({});
                        setShowCheckBox(false);
                      }
                      
                    }
                  }
                  
                ]}
              />
            </div>
            {checkBoxType === 'nf' && <DadosFiscaisModal2 data={checkBoxData} invoiceType={getEndPoint()} />}
          </div>
        </div>
        <div>
          {Object.values(selectCollects).filter((item)=>item!=undefined).length>0 && <p style={{marginBottom:10,marginTop:10}}><b>Solicitações selecionadas:</b> {Object.values(selectCollects).filter((item)=>item!=undefined).map((item)=>item.codigo).join(', ')}</p>}
        </div>
        <div className="row">
          <ListData showCheckBox={showCheckBox} selectCollects={selectCollects} setSelectCollects={setSelectCollects} allowSelect={allowSelect} dateOf={dateOf} dateFor={dateFor} search={search} reload={reload} options={options} checkBoxType={checkBoxType} checkBoxFunction={checkBoxCallback} permissions={permissions} selectedItems={selectedItems} setSelectedItems={setSelectedItems} token={token} filterOptionsArray={filterOptionsArray} />
        </div>
        {allowSelect && <button className="btn btn-primary" onClick={enviarSelecionados}>Enviar</button>}
      </div>
      <ProcessCDFLoteModal
        open={isModalOpen}
        handleClose={closeModal}
        selectedItems={selectedItems}
        token={token}
      />
    </div>
  )
}

export default AcompanharEtapas