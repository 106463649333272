import React from 'react'
import Label from '../../../Auxiliar/Label'
import { MdClose, MdReply } from 'react-icons/md'
import { PATCH } from '../../../Auxiliar/Requests'
import SmoothModal from '../../Modais/SmoothModal'
import { Alert, CircularProgress, IconButton } from '@mui/material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const FinishDestinationModal = ({ data }) => {
  const history = useHistory()
  const [msgError, setMsgError] = React.useState('')
  const [close, setClose] = React.useState(false)
  const [loadingSave, setLoadingSave] = React.useState(false)
  const [form, setForm] = React.useState({
    scheduledTo: '',
    startedAt: '',
    finishedAt: '',
  })

  React.useEffect(() => {
    const { scheduled_to = '', started_at = '', finished_at = '' } = data
    setForm({ scheduledTo: scheduled_to, startedAt: started_at, finishedAt: finished_at })
  }, [])

  //-------------------------*-------------------------
  const handleClose = () => {
    setMsgError('')
    setForm({ scheduledTo: '', startedAt: '', finishedAt: '' })
  }
  //-------------------------*-------------------------
  const handleSave = async () => {
    setLoadingSave(true)
    const response = await PATCH({ url: `destinacao/finish`, body: JSON.stringify({ ...form, id: data.id }) })
    setLoadingSave(false)
    console.log('repsonsw easfdasdas', response)
    if (response.error) {
      setMsgError(response.error)
      return
    }

    setClose(!close)
    history.push('/acompanhar_etapas')
  }

  //-------------------------*-------------------------
  const handleChange = ({ target }) => {
    const { name, value } = target

    setMsgError('')
    setForm({ ...form, [name]: value })
  }

  return (
    <SmoothModal
      close={close}
      buttonLabel='Finalizar destinação'
      onCloseCallback={handleClose}>
      <div className='d-flex h-100 justify-content-between flex-column'>
        <div>
          <IconButton className='mb-2' onClick={() => setClose(!close)}>
            <MdClose />
          </IconButton>
          {msgError && msgError.map(error => (
            <Alert key={error} className="mb-2" severity="error">{error}</Alert>)
          )}
          <div className="col-12">
            <Label title='Agendado Para' spaced={false} />
            <input
              type='date'
              className="form-control mb-3"
              name='scheduledTo'
              value={form.scheduledTo}
              onChange={handleChange}
            />
          </div>

          <div className="col-12">
            <Label title='Iniciado em' spaced={false} />
            <input
              type='date'
              className="form-control mb-3"
              name='startedAt'
              value={form.startedAt}
              onChange={handleChange}
            />
          </div>

          <div className="col-12">
            <Label title='Finalizado em' spaced={false} />
            <input
              type='date'
              className="form-control mb-3"
              name='finishedAt'
              value={form.finishedAt}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <button
            type="button"
            className="btn btn-secondary btn-lg waves-effect waves-light"
            onClick={() => setClose(!close)}>
            <MdReply /> Voltar
          </button>
          <button
            type="button"
            className="btn btn-success btn-lg waves-effect waves-light"
            disabled={loadingSave}
            onClick={handleSave}
            style={{ minWidth: 212, minHeight: 48 }}>
            {loadingSave
              ? <div className="d-flex align-items-center justify-content-center">
                <CircularProgress color="text" size={25} />
              </div>
              : `${form.finishedAt ? 'Finalizar' : 'Salvar'} destinação`}
          </button>
        </div>
      </div>
    </SmoothModal >
  )
}

export default FinishDestinationModal